import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function NATIcon({
  color,
  x,
  y,
  width = 24,
  height = 24,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.174 5.651c0-.75.61-1.36 1.36-1.36a7.712 7.712 0 0 1 7.591 6.354c.04-.003.08-.005.12-.005h2.61l-.852-.852a1.36 1.36 0 1 1 1.924-1.924l3.175 3.174a1.36 1.36 0 0 1 0 1.924l-3.175 3.175a1.36 1.36 0 1 1-1.924-1.924l.852-.852h-2.61c-.04 0-.08-.002-.12-.005a7.711 7.711 0 0 1-7.59 6.354 1.36 1.36 0 1 1 0-2.721 4.988 4.988 0 0 0 0-9.977 1.36 1.36 0 0 1-1.36-1.36Zm1.904 7.71a2.72 2.72 0 1 1 0-2.72h1.724a1.36 1.36 0 1 1 0 2.72H7.078Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default NATIcon;
