import React, { FC, useMemo } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import { ChangeConnectionsFields, FieldsType } from "../../types";
import ZPortMenu from "./components/ZPortMenu/ZPortMenu";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import { CONNECTIONS_DEFAULTS_FIELDS } from "../../consts";
import FormItemRouter from "./components/FormItemRouter";
import ConnectionControls from "./components/common/ConnectionControls";

import styles from "./ZPortBlock.module.scss";

type Props = {
  className: string;
  fields: Array<FieldsType>;
  onChange: ChangeConnectionsFields;
  onDelete: (idx: number) => void;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
  withIP?: boolean;
  withoutType?: boolean;
};

const ZPortBlock: FC<Props> = ({
  className,
  fields,
  onChange,
  onDelete,
  isEdit,
  errors,
  handleChangeBW,
  withIP,
  withoutType,
}) => {
  const {
    portType,
    setIsPortTypeDisabled,
    selectedConnections = [],
  } = useConnectionConfiguratorContext();

  const initialConnection = useMemo(() => {
    const lastInnerType = fields[fields.length - 1].innerType;
    return CONNECTIONS_DEFAULTS_FIELDS[lastInnerType];
  }, [fields]);

  // Change innerType for the last connection
  const handleChangeInnerType = (innerType: string): void => {
    const lastIdx = fields.length - 1;
    onChange(lastIdx, CONNECTIONS_DEFAULTS_FIELDS[innerType]);
  };

  //Change connection's field
  const handleChangeField = (idx: number, field: string, value: any): void => {
    const newFields = { ...fields[idx], [field]: value };
    onChange(idx, newFields);
  };

  // Add new connection
  const handleAdd = () => {
    setIsPortTypeDisabled(true);
    const lastIdx = fields.length;
    onChange(lastIdx, initialConnection);
  };

  const containerClasses = classNames(className, styles.wrapper);

  const disabled = useMemo(() => {
    return (
      isEdit &&
      (portType.key === "azPorts" ||
        fields.length === selectedConnections.length)
    );
  }, [portType.key, isEdit, fields]);

  return (
    <div className={containerClasses}>
      <ZPortMenu
        selected={fields[fields.length - 1].innerType}
        onSelect={(innerType: string) => handleChangeInnerType(innerType)}
        disabled={disabled}
      />
      {fields &&
        fields.map((connectionField: FieldsType, idx: number) => {
          // Don't show first connection (it is in a port block)
          if (idx === 0) return null;
          return (
            <FormItemRouter
              key={idx + "connection"}
              type={connectionField.innerType}
              fields={connectionField}
              onChange={(field: string, value: any) =>
                handleChangeField(idx, field, value)
              }
              listLength={fields.length - 1}
              isExpandInitial={fields.length - 1 === idx}
              idx={idx}
              onDelete={() => onDelete(idx)}
              isEdit={disabled || idx < selectedConnections.length}
              errors={errors}
              handleChangeBW={handleChangeBW}
              withIP={withIP}
              withoutType={withoutType}
            />
          );
        })}
      <ConnectionControls type={portType.key} onChange={handleAdd} />
    </div>
  );
};

export default ZPortBlock;
