import React from "react";
import { FilterAndSort, PBRType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { EMPTY_RESULT } from "../../helpers/common/constantsAlias";
import { RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../systemsContext/OneSystemContext";
import NewAbstractCrudContext, {
  CrudRequestStatuses,
  CrudState,
} from "../common/NewAbstractCrudContext";
import { createContextUtils } from "../common/utils";

type IState = CrudState<PBRType> & {
  pbrList: Array<PBRType>;
  pbrListStatus: RequestStatus;
  lastFetchedTenant: string;
  selectedPBR: PBRType | undefined;
};

type IFunc = {
  fetchList: (tenant?: string, offset?: number, limit?: number) => void;
  selectPBR: (pbr: PBRType | undefined) => void;
  changePBREnable: (pbr?: any | undefined) => void;
  fetchGlobalList: (tenant?: string) => void;
  globalAdd: (pbr: PBRType, tenant?: string) => Promise<boolean>;
  globalEdit: (params: Partial<PBRType>, tenant?: string) => Promise<boolean>;
  globalDelete: (rule: PBRType) => Promise<boolean>;
  changePBREnableGlobal: (pbr?: any | undefined) => void;
  removeDeletedRule: (name: string) => void;
  add: (pbr: PBRType, tenant?: string) => Promise<boolean>;
  edit: (params: Partial<PBRType>, tenant?: string) => Promise<boolean>;
  remove: (
    rule: PBRType,
    tenant?: string,
    isGlobal?: boolean
  ) => Promise<boolean>;
};

const [Context, useContext] = createContextUtils<IState, IFunc>();

export const usePBRContext = useContext;

type Props = IOneSystemContext;

class PBRContextContainer extends NewAbstractCrudContext<
  PBRType,
  IState,
  CrudRequestStatuses,
  IFunc,
  Props
> {
  Context = Context;
  changePBREnable = async (pbr?: PBRType | undefined, isGlobal?: boolean) => {
    if (pbr) {
      await this.edit(
        { ...pbr, enable: !pbr.enable },
        pbr.tenant_name,
        isGlobal
      );
    }
  };

  removeDeletedRule = (name: string) => {
    const list = this.state.pbrList?.filter((r) => r.name !== name) || [];
    this.setState({ pbrList: list });
  };

  changePBREnableGlobal = async (pbr?: PBRType | undefined) => {
    return await this.changePBREnable(pbr, true);
  };

  selectPBR = async (pbr: PBRType | undefined) => {
    this.setState({ selectedPBR: pbr });
  };

  listReq = async (params?: FilterAndSort, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const res = await configApi.getPBRRules(tenant);
    if (res.ok) {
      const sortedResult = this.sortPBRList(res?.result || []);
      return { ...res, result: sortedResult };
    }
    return res;
  };

  fetchList = async (tenant?: string): Promise<void> => {
    const selectedTenant = tenant || this.state.lastFetchedTenant;
    if (!selectedTenant) return;
    this.setState({ pbrListStatus: setPending("Fetching") });
    const res = await this.listReq(undefined, tenant);

    if (res.ok && res.result) {
      await this.setState({
        pbrList: res.result,
        lastFetchedTenant: selectedTenant,
      });
    }
    this.setState({
      pbrListStatus: resToState(res),
      lastFetchedTenant: selectedTenant,
    });
  };

  fetchGlobalList = async (tenant?: string): Promise<void> => {
    const selectedTenant = tenant || this.state?.lastFetchedTenant;
    if (!selectedTenant) {
      this.setState({ pbrList: [] });
      return;
    }
    this.setState({ pbrListStatus: setPending("Fetching") });
    const res = await configApi.getPBRGlobalRules(selectedTenant);

    if (res.ok && res.result) {
      const sortedResult = this.sortPBRList(res?.result || []);
      this.setState({ pbrList: sortedResult });
    }
    this.setState({ pbrListStatus: resToState(res) });
  };

  add = async (params: any, tenant?: string) => {
    if (!tenant) return false;
    const { ok } = await this._addWrap(() =>
      configApi.addPBR(tenant, params as any)
    );
    return ok;
  };

  globalAdd = async (pbr: PBRType) => {
    this.setState({ addStatus: setPending("Fetching") });
    const res = await configApi.addPBRGlobal(pbr);
    if (res.ok) {
      await this.fetchGlobalList();
    }
    this.setState({ addStatus: resToState(res) });
    return res.ok;
  };

  globalEdit = async (params: Partial<PBRType>, tenant?: string) => {
    const usedTenant = params.tenant_name || tenant;
    return await this.edit(params, usedTenant, true);
  };

  globalDelete = async (rule: PBRType) => {
    return await this.remove(rule, rule.tenant_name, true);
  };

  sortPBRList = (items: Array<PBRType>): Array<PBRType> => {
    const sortedResult = items.sort(
      (prev, next) => prev.priority - next.priority || prev.id - next.id
    );
    return sortedResult;
  };

  remove = async (
    rule: PBRType,
    tenant?: string,
    isGlobal = false
  ): Promise<boolean> => {
    const selectedTenant =
      tenant || this.state.lastFetchedTenant || rule.tenant_name;
    if (!selectedTenant) return false;
    this.setState({ lastFetchedTenant: selectedTenant });
    const { ok } = await this._removeWrap(() =>
      configApi.deletePBR(selectedTenant, rule.name.toString(), isGlobal)
    );
    if (ok) await this.removeDeletedRule(rule.name);
    return ok;
  };
  edit = async (
    params: Partial<PBRType>,
    tenant?: string,
    isGlobal?: boolean
  ): Promise<boolean> => {
    const selectedTenant =
      tenant || this.state.lastFetchedTenant || params.tenant_name;
    if (!selectedTenant) return false;
    const { ok } = await this._editWrap(() =>
      configApi.editPBR(selectedTenant, params.name as string, params, isGlobal)
    );
    if (ok) {
      const listCopy = this.state.pbrList.filter(
        (rule) => rule.name !== params.name
      );
      listCopy.push(params as PBRType);
      this.setState({
        pbrList: this.sortPBRList(listCopy),
      });
    }

    return ok;
  };

  funcs = {
    fetchList: this.fetchList,
    selectPBR: this.selectPBR,
    changePBREnable: this.changePBREnable,
    fetchGlobalList: this.fetchGlobalList,
    globalAdd: this.globalAdd,
    globalEdit: this.globalEdit,
    changePBREnableGlobal: this.changePBREnableGlobal,
    globalDelete: this.globalDelete,
    removeDeletedRule: this.removeDeletedRule,
    edit: this.edit,
    remove: this.remove,
    add: this.add,
  };
}

export default withOneSystemContextProps(PBRContextContainer);
