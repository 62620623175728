import DeleteButton from "../../../../../../components/common/buttons/DeleteButton";
import EditButton from "../../../../../../components/common/buttons/EditButton";
import styles from "./Actions.module.scss";

type ActionsProps = {
  onEdit: () => void;
  onDelete: () => void;
};

export const Actions = ({ onEdit, onDelete }: ActionsProps) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Key already exists</span>
      <div className={styles.actions}>
        <EditButton
          id="edit-key-nni-button"
          onClick={onEdit}
          withLabel
          isFill
        />
        <DeleteButton
          id="delete-key-nni-button"
          onClick={onDelete}
          withLabel
          isFill
        />
      </div>
    </div>
  );
};
