import Validator from "./Validator";

export default function validateVLAN(viMTU = Infinity, portMTU = Infinity) {
  return (data: Partial<any>, initValidator?: Validator): Validator => {
    const validator = initValidator || new Validator();
    validator.checkEmpty(data.vlan_id, "vlan_id");
    validator.checkEmpty(data.physical_port_name, "physical_port_name");

    let maxMTU = Math.min(viMTU, portMTU);
    maxMTU = maxMTU === Infinity ? 20000 : maxMTU;
    validator.checkEmpty(data.mtu, "mtu");
    validator.checkNumber(data.mtu.toString(), [64, maxMTU], "mtu");

    return validator;
  };
}
