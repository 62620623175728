import { CellProps, Renderer } from "react-table";
import { IconProps } from "../../../../../helpers/types";
import { getBooleanIcon } from "../../../../../helpers/getIconFromValue";
import { Checkbox } from "../../../formComponents/Checkbox";
import Input from "../../../formComponents/Input";

export type Cell<P extends object> = Renderer<CellProps<P>>;

export function withCellValue<P extends object>(
  render: (value: any) => Cell<P>
): Cell<P> {
  return ({ cell: { value } }: CellProps<P>) => {
    return render(value);
  };
}

export type InitOptions<P> = {
  icon?: React.FC<IconProps>;
  idFormer?: (name: string) => string;
  statusSelector?: (original: P) => string | undefined;
};

type Options<P> = {
  icon?: React.FC<IconProps>;
  idFormer: (name: string) => string;
  statusSelector: (original: P) => string | undefined;
};
export class EditableCellFactory<P extends object> {
  private options: Options<P>;
  constructor(options: InitOptions<P>) {
    this.options = {
      idFormer: (f) => f,
      statusSelector: (orig: P) => ({ status: undefined, ...orig }?.status),
      ...options,
    };
  }

  input(): Cell<P> {
    // eslint-disable-next-line react/display-name, react/prop-types
    return ({ value, onChange, error }: CellProps<P>) => (
      <Input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        isFullValue
        medium
        error={error}
      />
    );
  }

  checkboxCell(): Cell<P> {
    return withCellValue<P>((value) => (
      <Checkbox isChecked={value} onChange={() => undefined} disabled />
    ));
  }

  booleanIconCell(): Cell<P> {
    return withCellValue<P>((value) => getBooleanIcon(value));
  }
}
