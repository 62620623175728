import React, { FC, useState } from "react";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./CloudResourcesContent.module.scss";
import { useCloudResources } from "./hooks";
import {
  AddNNIDialog,
  AwsTable,
  AzureTable,
  CloudPanel,
  CredentialsDialog,
  GCPTable,
} from "./components";
import Loader from "../../components/common/Loader/Loader";
import AddButton from "../../components/common/buttons/AddButton";
import { KeyButton } from "../../components/common/buttons/KeyButton";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../helpers/consts";
import { resToState } from "../../helpers/common/RequestStateHelpers";

const CloudResourcesContent: FC = () => {
  const [showAdd, setShowAdd] = useState(false);
  const [showCredentials, setShowCredentials] = useState(false);
  const [selectedCloud, setSelectedCloud] = useState(AWS_LOWERCASE);
  const {
    awsState,
    createAWSNNI,
    combinedAwsInfo,
    createAwsKey,
    deleteAWSNNI,
    deleteAWSNNIState,
    activeAwsKey,
    deleteAwsKey,
    updateAwsKey,
    getAwsKeys,
    isAwsLoading,
    isAwsRefetching,
    refetchAwsState,

    createAzureKey,
    deleteAzureKey,
    updateAzureKey,
    activeAzureKey,
    combinedAzureInfo,
    createAzureNNI,
    deleteAzureNNI,
    deleteAzureNNIState,
    getAzureKeys,
    azureNNIs,
    azureNNIOptions,
    isAzureLoading,
    isAzureRefetching,
    refetchAzureState,

    createGCPKey,
    deleteGCPKey,
    updateGCPKey,
    activeGCPKey,
    combinedGCPInfo,
    createGCPNNI,
    deleteGCPNNI,
    deleteGCPNNIState,
    getGCPKeys,
    GCPNNIs,
    GCPNNIOptions,
    isGCPLoading,
    isGCPRefetching,
    refetchGCPState,
  } = useCloudResources();
  if (isAwsLoading || isAzureLoading || isGCPLoading) return <Loader />;

  const CLOUD_TABLES = {
    [AWS_LOWERCASE]: (
      <AwsTable
        awsState={awsState}
        combinedAwsInfo={combinedAwsInfo}
        isAwsRefetching={isAwsRefetching}
        refetchAwsState={refetchAwsState}
        deleteNNI={deleteAWSNNI}
        deleteStatus={resToState(deleteAWSNNIState)}
      />
    ),
    [AZURE_LOWERCASE]: (
      <AzureTable
        azureNNIs={azureNNIs}
        combinedAzureInfo={combinedAzureInfo}
        isAzureRefetching={isAzureRefetching}
        refetchAzureState={refetchAzureState}
        deleteNNI={deleteAzureNNI}
        deleteStatus={resToState(deleteAzureNNIState)}
      />
    ),
    [GCP_LOWERCASE]: (
      <GCPTable
        gcpNNIs={GCPNNIs}
        combinedGCPInfo={combinedGCPInfo}
        isGCPRefetching={isGCPRefetching}
        refetchGCPState={refetchGCPState}
        deleteNNI={deleteGCPNNI}
        deleteStatus={resToState(deleteGCPNNIState)}
      />
    ),
  };

  return (
    <div className={styles.wrapper}>
      {showAdd && (
        <AddNNIDialog
          onClose={() => {
            setShowAdd(false);
          }}
          createAWSNNI={createAWSNNI}
          createAzureNNI={createAzureNNI}
          createGCPNNI={createGCPNNI}
          azureNNIOptions={azureNNIOptions ?? []}
          gcpNNIOptions={GCPNNIOptions ?? []}
        />
      )}
      {showCredentials && (
        <CredentialsDialog
          onClose={() => {
            getAwsKeys();
            getAzureKeys();
            getGCPKeys();
            setShowCredentials(false);
          }}
          createAwsKey={createAwsKey}
          activeAwsKey={activeAwsKey}
          deleteAwsKey={deleteAwsKey}
          updateAwsKey={updateAwsKey}
          createAzureKey={createAzureKey}
          activeAzureKey={activeAzureKey}
          deleteAzureKey={deleteAzureKey}
          updateAzureKey={updateAzureKey}
          createGCPKey={createGCPKey}
          activeGCPKey={activeGCPKey}
          deleteGCPKey={deleteGCPKey}
          updateGCPKey={updateGCPKey}
        />
      )}
      <div className={styles.actions}>
        <KeyButton
          onClick={() => {
            setShowCredentials(true);
          }}
          label="Credentials"
        />
        <AddButton
          label="Add Cloud NNI"
          onClick={() => {
            setShowAdd(true);
          }}
        />
      </div>
      <div className={styles.cloudsWrapper}>
        <CloudPanel
          item={AWS_LOWERCASE}
          total={combinedAwsInfo.total}
          onSelect={setSelectedCloud}
          selectedCloud={selectedCloud}
        />
        <CloudPanel
          item={AZURE_LOWERCASE}
          total={combinedAzureInfo.total}
          onSelect={setSelectedCloud}
          selectedCloud={selectedCloud}
        />
        <CloudPanel
          item={GCP_LOWERCASE}
          total={combinedGCPInfo.total}
          onSelect={setSelectedCloud}
          selectedCloud={selectedCloud}
        />
      </div>

      {CLOUD_TABLES[selectedCloud as keyof typeof CLOUD_TABLES]}
    </div>
  );
};

export default CloudResourcesContent;
