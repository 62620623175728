import { FC, useEffect } from "react";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useCloudsConnectivityContext } from "../CloudsConnectivityContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import styles from "./CloudCredentials.module.scss";
import Button from "../../../components/common/buttons/Button";
import { useValidation } from "../../../helpers/validators/Validator";
import validateCloudCredentials from "../../../helpers/validators/CloudCredentialsValidator";
import { classNames } from "../../../helpers/common/classNames";
import Input, { types } from "../../../components/common/formComponents/Input";
import { AZURE_LOWERCASE } from "../../../helpers/consts";

type Props = {
  type: string;
};

export type CloudCredentialsFields = {
  access_key_id?: string;
  secret_access_key?: string;
  tenant_id?: string;
  client_id?: string;
  subscription_id?: string;
  client_secret?: string;
};

const AWS_DEFAULT_FIELDS = {
  access_key_id: "",
  secret_access_key: "",
  session_token: "",
  description: "",
};

const AZURE_DEFAULT_FIELDS = {
  tenant_id: "",
  client_id: "",
  client_secret: "",
  subscription_id: "",
  description: "",
};

const fieldsData = {
  azure: {
    secretsNames: [
      "tenant_id",
      "client_id",
      "client_secret",
      "subscription_id",
    ],
    defaults: AZURE_DEFAULT_FIELDS,
    labels: ["Tenant ID", "Client ID", "Client Secret", "Subscription ID"],
    placeholders: ["", "", "", ""],
    isSecret: [false, false, true, false],
  },
  aws: {
    secretsNames: ["access_key_id", "secret_access_key"],
    defaults: AWS_DEFAULT_FIELDS,
    labels: ["Token", undefined],
    placeholders: ["Access Key", "Certificate"],
    isSecret: [true, true],
  },
};

const getDataByType = (type: string) => {
  switch (type) {
    case AZURE_LOWERCASE:
      return fieldsData.azure;
    default:
      return fieldsData.aws;
  }
};

const getSecrets = (type: string, fields: any): Array<string> => {
  switch (type) {
    case AZURE_LOWERCASE:
      return [
        fields.tenant_id,
        fields.client_id,
        fields.client_secret,
        fields.subscription_id,
      ];
    default:
      return [fields.access_key_id, fields.secret_access_key];
  }
};

const CloudCredentials: FC<Props> = ({ type }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    tokens,
    correctTokens,
    createAccessKey,
    deleteAccessKey,
    requestToUpdate,
    setRequestToUpdate,
  } = useCloudsConnectivityContext();

  const data = getDataByType(type);
  const [
    fields,
    handleFieldChange,
    resetFields,
  ] = useFormField<CloudCredentialsFields>(data.defaults);
  const [errors, validate] = useValidation<CloudCredentialsFields>(
    validateCloudCredentials,
    [fields]
  );
  const secrets = getSecrets(type, fields);

  const connectionsPlugText =
    tokens?.[type] && correctTokens?.[type] === false
      ? "The key is expired/incorrect. Please Update Cloud Credentials to Add Connections"
      : "Please Insert Cloud Credentials to Add Connections";

  const connectionsPlugStyle = classNames(
    styles.connectionsPlug,
    tokens?.[type] && correctTokens?.[type] === false && styles.orange
  );

  const handleCancelUpdate = () => {
    setRequestToUpdate(undefined);
  };

  useEffect(() => {
    if (selectedTenant) {
      resetFields(data.defaults);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (tokens && type) {
      const fields = tokens[type];
      if (fields) {
        correctTokens?.[type]
          ? resetFields(fields)
          : resetFields(data.defaults);
      }
    }
  }, [tokens, type, correctTokens]);

  const handleApply = () => {
    if (selectedTenant) {
      const { isOk } = validate();
      if (isOk) {
        if (tokens?.[type]) {
          deleteAccessKey(selectedTenant, type, true, fields);
        } else {
          createAccessKey(fields, selectedTenant, type);
        }
        if (requestToUpdate === type) setRequestToUpdate(undefined);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{"Cloud Credentials"}</label>
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.group}>
            {secrets.map((secret, idx) => {
              return (
                <Input
                  key={secret}
                  type={data.isSecret[idx] ? types.PASSWORD : types.INPUT}
                  value={secret}
                  label={data.labels[idx]}
                  name={data.secretsNames[idx]}
                  onChange={(e) => {
                    handleFieldChange(data.secretsNames[idx], e.target.value);
                  }}
                  isFullValue
                  medium
                  error={errors?.[data.secretsNames[idx]]}
                  placeholder={data.placeholders[idx]}
                />
              );
            })}
          </div>
          {requestToUpdate === type && (
            <div className={styles.btnWrapper}>
              <Button onClick={handleCancelUpdate} className={styles.btn}>
                Cancel
              </Button>
            </div>
          )}
          <div className={styles.btnWrapper}>
            <Button onClick={handleApply} isPrimaryBtn className={styles.btn}>
              Apply
            </Button>
          </div>
        </div>
      </div>
      {!correctTokens?.[type] && (
        <>
          <label className={styles.label}>{"Connections"}</label>
          <div className={connectionsPlugStyle}>
            <div className={styles.text}>{connectionsPlugText}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default CloudCredentials;
