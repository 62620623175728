import { CellProps } from "react-table";
import CloudProviderIcon from "../../../../components/icons/CloudProviderIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import LocationFillIcon from "../../../../components/icons/LocationFillIcon";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../TableHeaders.module.scss";
import { getPercentageOfTotal } from "../../../../helpers/common/numberHelpers";
import { getColorClassByPercentage } from "../../helpers";
import { AzureNNI, RegionItem } from "../../types";
import { getBandwidthStringForTable } from "../../../../helpers/getBandwidthStringForTable";
import { AZURE_LOWERCASE } from "../../../../helpers/consts";

const IconColumn = {
  Header: "Cloud Provider",
  accessor: "",
  Cell: () => {
    return (
      <CloudProviderIcon
        id={AZURE_LOWERCASE}
        color={GREY_FONT}
        height={36}
        width={36}
      />
    );
  },
};

export const azureNNITableHeader = () =>
  [
    IconColumn,
    {
      Header: "Peering Location",
      accessor: "peering_location",
      Cell: ({ cell: { value } }: CellProps<AzureNNI>) => {
        return (
          <div className={styles.region}>
            <LocationFillIcon color={GREY_FONT} />
            {value}
          </div>
        );
      },
    },
    {
      Header: "NNI Id",
      accessor: "nni_id",
    },
    {
      Header: "Primary Port",
      accessor: (row: AzureNNI) => [
        row.primary_system_name,
        row.primary_physical_port_name,
      ],
      Cell: ({ cell: { value } }: CellProps<AzureNNI>) => {
        const [systemName, portName] = value;
        if (!systemName || !portName) return "";

        return `${systemName} - ${portName}`;
      },
    },
    {
      Header: "Secondary Port",
      accessor: (row: AzureNNI) => [
        row.secondary_system_name,
        row.secondary_physical_port_name,
      ],
      Cell: ({ cell: { value } }: CellProps<AzureNNI>) => {
        const [systemName, portName] = value;
        if (!systemName || !portName) return "";

        return `${systemName} - ${portName}`;
      },
    },
    {
      Header: "Link Capacity",
      accessor: "azure_bandwidth",
      Cell: ({ cell: { value } }: CellProps<AzureNNI>) => {
        if (value === null) return "";

        return getBandwidthStringForTable(value * 1000);
      },
    },
    {
      Header: "Oversubscription Capacity",
      accessor: "azure_metadata",
      Cell: ({ cell: { value } }: CellProps<AzureNNI>) => {
        const bandwidth = value?.port_bandwidth_in_mbps;
        if (value === null) return "";

        return getBandwidthStringForTable(bandwidth * 1000);
      },
    },
    {
      Header: "Provisioned Capacity",
      accessor: (row: any) => [
        row.azure_metadata,
        row.azure_used_bandwidth,
        row.azure_bandwidth,
      ],
      Cell: ({ cell: { value } }: CellProps<AzureNNI>) => {
        // eslint-disable-next-line prefer-const
        let [metadata, usedBW, linkCapacity] = value;
        if (usedBW === null) return "";

        const bw = metadata.port_bandwidth_in_mbps * 1000;
        usedBW = usedBW * 1000;

        const usedBWLabel = getBandwidthStringForTable(usedBW);
        const percentage = getPercentageOfTotal(usedBW, bw);

        return (
          <div
            className={getColorClassByPercentage(percentage, {
              value: usedBW,
              capacity: linkCapacity * 1000,
            })}
          >
            {usedBWLabel} ({percentage}%)
          </div>
        );
      },
    },
    {
      Header: "Connections",
      accessor: "connections_count",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof AzureNNI,
  }));

export const azureRegionsTableHeader = () =>
  [
    IconColumn,
    {
      Header: "Peering Location",
      accessor: "region",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return (
          <div className={styles.region}>
            <LocationFillIcon color={GREY_FONT} />
            {value}
          </div>
        );
      },
    },
    {
      Header: "NNIs",
      accessor: "nniCount",
    },
    {
      Header: "Link Capacity",
      accessor: "totalCapacity",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        if (value === null) return "";

        return getBandwidthStringForTable(value * 1000);
      },
    },
    {
      Header: "Oversubscription Capacity",
      accessor: "oversubscriptionCapacity",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        if (value === null) return "";

        return getBandwidthStringForTable(value * 1000);
      },
    },
    {
      Header: "Provisioned Capacity",
      accessor: (row: RegionItem) => [
        row.oversubscriptionCapacity,
        row.provisionedCapacity,
      ],
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        let [bw, usedBW] = value;
        if (usedBW === null) return "";
        bw = bw * 1000;
        usedBW = usedBW * 1000;

        const usedBWLabel = getBandwidthStringForTable(usedBW);
        const percentage = getPercentageOfTotal(usedBW, bw);
        return (
          <div className={getColorClassByPercentage(percentage)}>
            {usedBWLabel} ({percentage}%)
          </div>
        );
      },
    },
    {
      Header: "Connections",
      accessor: "connections",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return value;
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof RegionItem,
  }));
