import React, { FC } from "react";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import ClockIcon from "../../../icons/ClockIcon";
import { NONE } from "./GrafanaContainerContext";
import styles from "./GrafanaPlaceholder.module.scss";
import { classNames } from "../../../../helpers/common/classNames";

const GrafanaPlaceholder: FC<{ timeRange: string; className?: string }> = ({
  timeRange,
  className,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, className, "grafana-placeholder")}
    >
      <img src="/GrafanaPlaceholderSVG.svg" className={styles.image} />
      {timeRange === NONE ? (
        <p className={styles.title}> There is no traffic for any time period</p>
      ) : (
        <>
          <p className={styles.title}>
            There is no traffic in this time period
          </p>
          <p className={styles.subtitle}>
            Try setting "{timeRange}" in Time range picker above
            <span className={styles.icon}>
              <ClockIcon color={GREY_FONT} />
            </span>
          </p>
        </>
      )}
    </div>
  );
};
export default GrafanaPlaceholder;
