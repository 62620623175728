import { VirtualInterfaceApi } from "../../../VirtualInterfacePage/types";

export const getConnections = (viList: Array<VirtualInterfaceApi>) => {
  const members: any = [];

  viList?.map((vi) => {
    return vi.members.map((member) => {
      return member.interfaces.map((int) => {
        const body = {
          ...vi,
          ...int,
          name: int.name || vi.name,
        };
        members.push(body);
      });
    });
  });

  return members;
};
