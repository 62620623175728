import React, { FC, useState } from "react";
import ButtonsGroup, {
  Tab,
} from "../../../../components/common/buttons/ButtonsGroup";
import ChartsHeader from "../../../../components/common/charts/ChartsHeader/ChartsHeader";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { classNames } from "../../../../helpers/common/classNames";
import { useApplicationsDashboardContext } from "../../ApplicationObservabilityContext";
import { getApplicationLink } from "../../common/appStatisticsHelper";
import { APPLICATION_FIELD_VALUE } from "../../consts";
import styles from "./LeftPart.module.scss";

type Props = {
  className?: string;
};

const TABS = [
  { name: "Locations", icon: undefined },
  { name: "Source IPs", icon: undefined },
  { name: "Connections", icon: undefined },
];

const LeftPart: FC<Props> = ({ className }) => {
  const [activeTab, setActiveTab] = useState<string>(TABS[0].name);
  const handleTabSelect = (tab: Tab) => {
    setActiveTab(tab.name);
  };

  return (
    <div className={classNames(className, styles.container)}>
      <ChartsHeader
        title={""}
        controls={
          <ButtonsGroup
            tabs={TABS}
            activeTab={activeTab}
            onChange={handleTabSelect}
            withIcon
          />
        }
      />
      <StatisticsChart type={activeTab} />
    </div>
  );
};

export default LeftPart;

const StatisticsChart: FC<{ type: string }> = ({ type }) => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { filters } = useApplicationsDashboardContext();

  switch (type) {
    case "Connections":
      return (
        <GrafanaGraph
          link={getApplicationLink(
            14,
            timeRange.general,
            selectedTenant,
            filters,
            APPLICATION_FIELD_VALUE
          )}
        />
      );
    case "Source IPs":
      return (
        <GrafanaGraph
          link={getApplicationLink(
            13,
            timeRange.general,
            selectedTenant,
            filters,
            APPLICATION_FIELD_VALUE
          )}
        />
      );
    case "Locations":
    default:
      return (
        <GrafanaGraph
          link={getApplicationLink(
            15,
            timeRange.general,
            selectedTenant,
            filters,
            APPLICATION_FIELD_VALUE
          )}
        />
      );
  }
};
