import React, { ReactElement } from "react";
import { PosProps } from "../../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/utils";

function CloudRouterIcon({ x, y }: PosProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="65"
      viewBox="0 0 71 65"
      fill="none"
      x={x - 25}
      y={y - 25}
    >
      <g opacity="0.6">
        <g filter="url(#filter0_d_32548_256162)">
          <path
            d="M13.3273 22.1816C13.3684 18.9898 15.7561 16.4074 18.6851 16.4074C19.3458 16.4074 20.0013 16.5421 20.6145 16.8009C21.0863 15.2365 21.9701 13.8325 23.1518 12.7879C24.5475 11.5544 26.3062 10.875 28.1042 10.875C32.3368 10.875 35.801 14.5411 35.9799 19.1212C37.3269 19.5575 38.5396 20.4391 39.4326 21.6408C40.4421 22.9993 40.998 24.713 40.998 26.4664C40.998 30.6894 37.8557 34.125 33.993 34.125H15.7224C12.566 34.125 9.99813 31.3175 9.99805 27.8665C9.99805 25.4226 11.3192 23.1952 13.3273 22.1816Z"
            fill="#59536B"
          />
        </g>
        <path
          d="M30.469 20.0788C30.4464 20.0232 30.3936 19.987 30.3349 19.987H28.7446V17.4825C28.7446 17.4005 28.6796 17.334 28.5995 17.334H26.2773C26.1972 17.334 26.1322 17.4005 26.1322 17.4825V19.987H24.5358C24.4771 19.987 24.4243 20.0232 24.4017 20.0785C24.3793 20.134 24.3915 20.1979 24.433 20.2403L27.3269 22.7033C27.3541 22.7312 27.391 22.7469 27.4296 22.7469C27.4682 22.7469 27.5051 22.7312 27.5324 22.7036L30.4377 20.2406C30.4792 20.1981 30.4917 20.1343 30.469 20.0788Z"
          fill="white"
        />
        <path
          d="M24.404 28.7462C24.4267 28.8006 24.4795 28.8361 24.5381 28.8361H26.1285V31.3974C26.1285 31.4777 26.1935 31.543 26.2736 31.543H28.5957C28.6758 31.543 28.7408 31.4777 28.7408 31.3974V28.8361H30.3373C30.3959 28.8361 30.4487 28.8006 30.4714 28.7464C30.4937 28.692 30.4816 28.6294 30.44 28.5877L27.5345 26.1728C27.5072 26.1455 27.4704 26.13 27.4318 26.13C27.3932 26.13 27.3563 26.1455 27.329 26.1725L24.4354 28.5875C24.3938 28.6291 24.3814 28.6917 24.404 28.7462Z"
          fill="white"
        />
        <path
          d="M22.9717 27.4729C23.0257 27.4503 23.061 27.3975 23.061 27.3388V25.7485H25.5995C25.6794 25.7485 25.7441 25.6835 25.7441 25.6034V23.2812C25.7441 23.2011 25.6794 23.1361 25.5995 23.1361H23.061V21.5397C23.061 21.481 23.0257 21.4282 22.9719 21.4056C22.9179 21.3832 22.8557 21.3954 22.8143 21.4369L20.3737 24.2332C20.3465 24.2605 20.3312 24.2973 20.3312 24.3359C20.3312 24.3745 20.3465 24.4114 20.3734 24.4387L22.814 27.4416C22.8554 27.4831 22.9176 27.4956 22.9717 27.4729Z"
          fill="white"
        />
        <path
          d="M31.8973 21.404C31.8432 21.4267 31.8079 21.4795 31.8079 21.5381V23.1285H29.2716C29.1918 23.1285 29.127 23.1935 29.127 23.2736V25.5957C29.127 25.6758 29.1918 25.7408 29.2716 25.7408H31.8079V27.3373C31.8079 27.3959 31.8432 27.4487 31.897 27.4714C31.9511 27.4937 32.0133 27.4816 32.0547 27.44L34.4974 24.6425C34.5246 24.6153 34.5399 24.5784 34.5399 24.5398C34.5399 24.5012 34.5246 24.4643 34.4977 24.437L32.055 21.4354C32.0136 21.3938 31.9514 21.3814 31.8973 21.404Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_32548_256162"
          x="-0.00195312"
          y="0.875"
          width="71"
          height="63.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="10" dy="10" />
          <feGaussianBlur stdDeviation="10" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.469366 0 0 0 0 0.46092 0 0 0 0 0.579167 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_32548_256162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_32548_256162"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default CloudRouterIcon;
