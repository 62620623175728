import { useState } from "react";
import ChartToppersTabs from "../../components/common/ChartToppersTabs";
import { PageHeader } from "../../components/common/PageHeader/PageHeader";
import styles from "./DDoSStatistics.module.scss";
import { TrafficType } from "../../helpers/types";
import { BPS } from "../../helpers/common/constantsAlias";
import GrafanaContainer from "../../components/common/charts/GrafanaContainer/GrafanaContainer";
import GrafanaGraph from "../../components/common/charts/GrafanaGraph";
import { useOneSystemContext } from "../../contexts/systemsContext/OneSystemContext";
import { useVRFSelectorContext } from "../../contexts/systemsContext/VRFSelectorContext";
import { classNames } from "../../helpers/common/classNames";
import { useDDoSContext } from "../../contexts/servicesContext/DDoSContext";
import { useTimerangeContext } from "../../contexts/TimerangeContext";
import grafanaLinksFactory, {
  LinkFn,
} from "../../components/common/charts/GraphanaLinksFactory";
import Button from "../../components/common/buttons/Button";

export const DDoSStatistics = () => {
  const [trafficType, setTrafficType] = useState<TrafficType>(BPS);
  const { system } = useOneSystemContext();
  const { selectedVRF: vrf } = useVRFSelectorContext();
  const { selectedDDoS, selectDDoS } = useDDoSContext();
  const { name: ipFloodName } = selectedDDoS ?? {};
  const { timeRange } = useTimerangeContext();

  if (!system || !vrf) return null;

  const linkWithData = (func: LinkFn) => {
    const data = {
      timeRange: timeRange.general,
      filters: undefined,
      vrf,
      system: system.name,
      ipflood_name: ipFloodName,
    };
    return func(data);
  };

  const commonCharts = {
    bps: [
      { link: linkWithData(grafanaLinksFactory.ddos_general_start), key: "a" },
      { link: linkWithData(grafanaLinksFactory.ddos_general_active), key: "b" },
      {
        link: linkWithData(grafanaLinksFactory.ddos_general_bps_out),
        key: "c",
      },
      { link: linkWithData(grafanaLinksFactory.ddos_general_bps_in), key: "d" },
    ],
    pps: [
      { link: linkWithData(grafanaLinksFactory.ddos_general_start), key: "a" },
      { link: linkWithData(grafanaLinksFactory.ddos_general_active), key: "b" },
      {
        link: linkWithData(grafanaLinksFactory.ddos_general_pps_out),
        key: "c",
      },
      { link: linkWithData(grafanaLinksFactory.ddos_general_pps_in), key: "d" },
    ],
  };

  const itemCharts = {
    bps: [
      { link: linkWithData(grafanaLinksFactory.ddos_item_bps1), key: "a" },
      { link: linkWithData(grafanaLinksFactory.ddos_item_bps4), key: "b" },
      { link: linkWithData(grafanaLinksFactory.ddos_item_active), key: "c" },
    ],
    pps: [
      { link: linkWithData(grafanaLinksFactory.ddos_item_pps1), key: "a" },
      { link: linkWithData(grafanaLinksFactory.ddos_item_active), key: "b" },
    ],
  };

  const charts = (ipFloodName ? itemCharts : commonCharts)[
    trafficType as "bps" | "pps"
  ];

  return (
    <div className={styles.wrapper}>
      <PageHeader
        title={`DDoS Statistics${
          selectedDDoS?.name ? ` - ${selectedDDoS.name}` : ""
        }`}
        right={[
          <ChartToppersTabs
            activeTab={trafficType}
            setTabActive={setTrafficType}
            key="chart-tabs"
          />,
        ]}
      />
      <div className={styles.inner}>
        <GrafanaContainer
          type="system"
          className={classNames(
            styles.chartsWrapper,
            ipFloodName ? styles.chartsItem : styles.chartsCommon
          )}
          placeholderClassName={styles.grafanaPlaceholder}
          skipDocCountCheck
        >
          {charts.map(({ link, key }) => (
            <div
              className={styles.chart}
              key={key}
              style={{
                gridArea: key,
              }}
            >
              <GrafanaGraph link={link} />
            </div>
          ))}
        </GrafanaContainer>
      </div>
    </div>
  );
};
