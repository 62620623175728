export const CloudIcon = () => {
  return (
    <svg
      width="82"
      height="55"
      viewBox="0 0 82 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.36741 28.1745C8.46513 27.4325 9.01398 27.0614 9.23093 26.7394C9.44789 26.4174 9.59029 25.7473 9.87509 24.407C11.1733 18.2975 16.7526 13.6908 23.4181 13.6908C23.738 13.6908 24.0574 13.7017 24.3757 13.7233C26.5221 13.8689 27.5953 13.9417 28.0951 13.6802C28.5949 13.4187 29.1062 12.5637 30.129 10.8535C31.3717 8.7758 33.0024 6.91465 34.9452 5.38812C38.5469 2.55843 43.0857 1 47.7256 1C57.6393 1 65.9196 7.92765 67.71 17.063C67.993 18.5066 68.1344 19.2284 68.4293 19.6056C68.7241 19.9829 69.3959 20.297 70.7394 20.9252C73.1315 22.0438 75.2682 23.6719 76.9601 25.6957C79.5652 28.812 81 32.743 81 36.7652C81 46.4523 72.8906 54.3333 62.9224 54.3333H15.7726C7.62702 54.3333 1.00021 47.8931 1 39.9769C1 35.2188 3.45604 30.8184 7.36741 28.1745Z"
        fill="white"
        stroke="#E2E2E8"
      />
      <rect
        x="15.9326"
        y="17.7319"
        width="54.4"
        height="36.6013"
        fill="url(#pattern0_23305_317520)"
      />
      <defs>
        <pattern
          id="pattern0_23305_317520"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        ></pattern>
      </defs>
    </svg>
  );
};
