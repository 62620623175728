import React, { useMemo } from "react";
import DropdownBasic, { DropDownBasicProps } from "../Dropdown/DropdownBasic";
import { useFetch } from "../../../helpers/hooks/useFetch";
import { PRes } from "../../../helpers/api/Api";
import { DropdownItem } from "../../../helpers/types";

type AsyncDropdownProps<T> = Omit<DropDownBasicProps<T>, "itemsList"> & {
  /**
   * Fetch dropdown list function
   */
  fetchFn: () => PRes<Array<T>>;
  /**
   * Transform api values into dropdown values function
   */
  mapValue: (value: T) => DropdownItem<T>;
  /**
   * Fetch dependency, for example,
   * to fetch port names we need to get system name first,
   * deps={useMemo(() => [systemName], [systemName])}
   */
  deps?: Array<any>;
};

export const AsyncDropdown = <T,>({
  fetchFn,
  mapValue,
  deps,
  ...dropdownProps
}: AsyncDropdownProps<T>) => {
  const { state } = useFetch({
    fetchFn,
    isEnabled: true,
    deps,
  });

  const items = useMemo(() => (state?.result || []).map(mapValue), [state]);

  return (
    <DropdownBasic
      itemsList={items}
      isLoading={state.isLoading}
      {...dropdownProps}
    />
  );
};
