import { ReactElement } from "react";

function LastFailoverIcon(): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="12" cy="12" r="12" fill="#6B55F0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9505 3.69922L15.0576 6.13093L11.9505 8.56252V7.07657V5.18529V3.69922ZM11.9967 15.4992V16.9851V18.8764V20.3625L8.90039 17.9308L11.9967 15.4992Z"
        fill="#6B55F0"
      />
      <path
        d="M12 18C15.3137 18 18 15.3137 18 12C18 10.9071 17.7078 9.88252 17.1973 9M12 6C8.68629 6 6 8.68629 6 12C6 13.0929 6.29218 14.1175 6.80269 15"
        stroke="#6B55F0"
        strokeWidth="1.4"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3627 9.33748L9.37277 10.3274L11.0434 11.998L9.37082 13.6706L10.3608 14.6606L12.0333 12.988L13.7159 14.6706L14.7059 13.6806L13.0233 11.998L14.7039 10.3174L13.7139 9.32745L12.0333 11.0081L10.3627 9.33748Z"
        fill="#6B55F0"
      />
    </svg>
  );
}

export default LastFailoverIcon;
