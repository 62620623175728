/* eslint-disable css-modules/no-unused-class */
import React, { FC } from "react";
import styles from "./WebFilteringReports.module.scss";
import { PageProps } from "../../../../helpers/types";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { WEB_FILTERING } from "../../../../helpers/navigation/entries";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import { GTimeRange } from "../../../../components/common/charts/GraphanaLinksFactory";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useUserContext } from "../../../../contexts/UserContext";

const WebFilteringReports: FC<PageProps> = ({ tabs: Tabs }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();
  const { isDemo } = useUserContext();

  const getFirstGraphLink = (
    timeRange: GTimeRange,
    tenant?: string
  ): string => {
    return `/broker/grafana/d-solo/_4-cyZ7Sz/web-filtering?orgId=1&from=${timeRange.from}&to=${timeRange.to}&theme=light&panelId=2&var-Tenant=${tenant}&refresh=30s`;
  };

  const getSecondGraphLink = (
    timeRange: GTimeRange,
    tenant?: string
  ): string => {
    return `/broker/grafana/d-solo/_4-cyZ7Sz/web-filtering?orgId=1&from=${timeRange.from}&to=${timeRange.to}&theme=light&panelId=3&var-Tenant=${tenant}&refresh=30s`;
  };

  const getThirdGraphLink = (
    timeRange: GTimeRange,
    tenant?: string
  ): string => {
    return `/broker/grafana/d-solo/_4-cyZ7Sz/web-filtering?orgId=1&from=${timeRange.from}&to=${timeRange.to}&theme=light&panelId=4&var-Tenant=${tenant}&refresh=30s`;
  };
  const getForthGraphLink = (
    timeRange: GTimeRange,
    tenant?: string
  ): string => {
    return `/broker/grafana/d-solo/_4-cyZ7Sz/web-filtering?orgId=1&from=${timeRange.from}&to=${timeRange.to}&theme=light&panelId=5&var-Tenant=${tenant}&refresh=30s`;
  };
  const getFivthGraphLink = (
    timeRange: GTimeRange,
    tenant?: string
  ): string => {
    return `/broker/grafana/d-solo/_4-cyZ7Sz/web-filtering?orgId=1&from=${timeRange.from}&to=${timeRange.to}&theme=light&panelId=6&var-Tenant=${tenant}&refresh=30s`;
  };

  return (
    <div className={styles.pageWrapper}>
      <Tabs showTimeRange tabs={[WEB_FILTERING()]} />
      <ServicesRedirectPlaceholder type="fqdn">
        <GrafanaContainer
          type={GRAFANA_TYPES.FQDN}
          className={styles.wrapper}
          ignorePlaceholder={isDemo}
        >
          <div className={styles.wrapper}>
            <div className={styles.basicBlock}>
              <div className={styles.header}>DROP</div>
              <div className={styles.header}>ALLOW</div>
              <div className={styles.statContainer}>
                <GrafanaGraph
                  link={getFirstGraphLink(timeRange.general, selectedTenant)}
                />
              </div>
              <div className={styles.statContainer}>
                <GrafanaGraph
                  link={getSecondGraphLink(timeRange.general, selectedTenant)}
                />
              </div>
              <div className={styles.statContainer}>
                <GrafanaGraph
                  link={getThirdGraphLink(timeRange.general, selectedTenant)}
                />
                <GrafanaGraph
                  link={getForthGraphLink(timeRange.general, selectedTenant)}
                />
              </div>
              <div className={styles.statContainer}>
                <GrafanaGraph
                  link={getFivthGraphLink(timeRange.general, selectedTenant)}
                />
              </div>
            </div>
          </div>
        </GrafanaContainer>
      </ServicesRedirectPlaceholder>
    </div>
  );
};

export default withLayout<PageProps>(WebFilteringReports);
