import React, { FC, useEffect, useState } from "react";
import Section from "../../../components/common/Section";
import { CreateFWRule } from "./FirewallRuleConfig";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./FQDNSection.module.scss";
import RadioGroup from "../../../components/common/formComponents/RadioGroup";
import { Checkbox } from "../../../components/common/formComponents/Checkbox";
import InputWithList from "../../../components/common/formComponents/InputWithList";
import DropdownAutocomplete from "../../../components/common/Dropdown/DropdownAutocomplete";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { classNames } from "../../../helpers/common/classNames";
import {
  handleInput,
  handleRemoveFromList,
} from "../../../helpers/common/arrayHelpers";

export const ACTIONS = [
  {
    value: "ALLOW_WITH_BLOCKED_SNI",
    label: "Allow",
    description: "block only selected",
  },
  {
    value: "ALLOW_ONLY_SELECTED_SNIS",
    label: "Block",
    description: "allow only selected",
  },
];

type PoliciesType = "Allow" | "Deny";

type Props = {
  fields: CreateFWRule;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  sniInfo: (isSNI: boolean) => void;
  className: string;
};

const FQDNSection: FC<Props> = ({
  fields,
  errors,
  onChange,
  className,
  sniInfo,
}) => {
  const {
    contentFilterCategories,
    webThreatsFilterCategories,
  } = useFirewallContext();

  const contentFilterNames = contentFilterCategories.map(
    (category) => category.name
  );
  const threatsFilterNames = webThreatsFilterCategories.map(
    (category) => category.name
  );

  const recentFilters: Array<string> = [];
  const recentThreatsFilters: Array<string> = [];

  useEffect(() => {
    contentFilterNames.forEach((filterName) => {
      if (fields.categories.includes(filterName))
        recentFilters.push(filterName);
    });
    threatsFilterNames.forEach((filterName) => {
      if (fields.categories.includes(filterName))
        recentThreatsFilters.push(filterName);
    });
  }, []);

  const [policies, setPolicies] = useState<string>(
    fields?.forwarding_policy || ACTIONS[0].value
  );
  const [allowedDomains, setAllowedDomains] = useState<Array<string>>(
    fields.allowed_domains
  );
  const [blockList, setBlockList] = useState<Array<string>>(
    fields.denied_domains
  );
  const [contentList, setContentList] = useState<Array<string>>(
    fields.categories
  );
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(
    fields?.forwarding_policy !== ACTIONS[1].value
  );

  const handleChangePolicies = (val: string): void => {
    setPolicies(val as PoliciesType);
    onChange("forwarding_policy", val);
    if (val === ACTIONS[0].value) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
    sniInfo(val === ACTIONS[1].value);
  };

  return (
    <Section title={"Web Filtering"} className={className} columnsCount={3}>
      <div className={styles.column}>
        <RadioGroup
          className={styles.radioWrapper}
          label={"Default filter action"}
          isOneRow
          options={ACTIONS}
          value={policies}
          setActiveValue={handleChangePolicies}
          radioContainerClassName={styles.radioContainer}
        />
        <DropdownAutocomplete
          id={"content_filter"}
          label={"Content Filter"}
          valuesList={contentFilterCategories?.map((filter) => filter.name)}
          placeholder="URL Block list categories"
          onChange={(val: Array<string>) =>
            handleInput(
              val,
              contentList,
              setContentList,
              "categories",
              onChange
            )
          }
          isMedium
          selected={mapStringToItem("")}
          currentValues={recentFilters}
          listClassName={styles.listWrapper}
          emptyListPlaceholder="All filters are already selected"
          className={classNames(!showFilters && styles.hiddenContent)}
        />
        <InputWithList
          label={"Allow"}
          valuesList={allowedDomains}
          placeholder="URL"
          onAdd={(val: Array<string>) =>
            handleInput(
              val,
              allowedDomains,
              setAllowedDomains,
              "allowed_domains",
              onChange
            )
          }
          onRemove={(val: string) =>
            handleRemoveFromList(
              val,
              allowedDomains,
              setAllowedDomains,
              "allowed_domains",
              onChange
            )
          }
          medium
          singleLabelClassname={styles.labelGreen}
          error={errors && errors["allowed_domains"]}
        />
      </div>
      <div
        className={classNames(
          !showFilters && styles.hiddenContent,
          styles.column
        )}
      >
        <div className={styles.checkboxWrapper}>
          <Checkbox
            containerClassName={styles.checkbox}
            isChecked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            label={"Allow only https traffic with valid SNI"}
          />
        </div>
        <DropdownAutocomplete
          id={"threats"}
          label={"Web Threats Filter"}
          valuesList={webThreatsFilterCategories?.map((filter) => filter.name)}
          placeholder="URL Block Threats"
          onChange={(val: Array<string>) =>
            handleInput(
              val,
              contentList,
              setContentList,
              "categories",
              onChange
            )
          }
          currentValues={recentThreatsFilters}
          selected={mapStringToItem("")}
          isMedium
          listClassName={styles.listWrapper}
          emptyListPlaceholder="All filters are already selected"
        />
        <InputWithList
          label={"Block"}
          valuesList={blockList}
          placeholder="URL"
          onAdd={(val: Array<string>) =>
            handleInput(
              val,
              blockList,
              setBlockList,
              "denied_domains",
              onChange
            )
          }
          onRemove={(val: string) =>
            handleRemoveFromList(
              val,
              blockList,
              setBlockList,
              "denied_domains",
              onChange
            )
          }
          medium
          singleLabelClassname={styles.labelRed}
          error={errors && errors["denied_domains"]}
        />
      </div>
    </Section>
  );
};
export default FQDNSection;
