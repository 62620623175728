import React, { FC, useEffect, useState } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import { Toggle } from "../../../../../../../components/common/Toggle";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import {
  MAX_BANDWIDTH_LIST,
  SUBSCRIPTION_PERIOD_LIST,
} from "../../../../../utils/wizardToolConsts";
import ConnectionTypeGroup from "../../common/ConnectionTypeGroup";
import FormFooter from "../../common/FormFooter/FormFooter";
import SegmentsComponent from "../../common/SegmentsComponent";
import styles from "./CloudForm.module.scss";
import VPCList from "../../common/VPCList";
import { useValidation } from "../../../../../../../helpers/validators/Validator";
import { CloudFields } from "../../utils/formConsts";
import validateCloud from "../../../../../../../helpers/validators/CloudValidator";
import FormTitle from "../../common/FormTitle/FormTitle";
import { useConnectionsContext } from "../../../../ConnectionsContext";
import VnetList from "../../common/VnetList";
import { AWSVPNBody, AzureVPNBody } from "../../../../../types";
import { azureLocations } from "../../../../../../CloudsConnectivityPage/const";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../../../../../../helpers/consts";

export type CloudFieldsProps = CloudFields & {
  max_bandwidth: string;
  subscription: string;
};

const DEFAULT_FIELDS: CloudFieldsProps = {
  connection_name: "",
  system_name: "",
  network_segments: [],
  vpc_id: "",
  region: "",
  max_bandwidth: "100Mbps",
  subscription: "Yearly",
  labels: {},
};

type Props = {};
const AddCloudForm: FC<Props> = () => {
  const { user } = useUserContext();
  const {
    activeConnectionType,
    activeConnection,
    activeLocation,
    addVPNByType,
  } = useConnectionsContext();

  const [cloudSelected, setCloudSelected] = useState(false);
  const [connectioTypeField, setConnectionTypeField] = useState<string>(
    "ipsec"
  );

  const [
    fields,
    handleFieldChange,
    resetFields,
  ] = useFormField<CloudFieldsProps>(DEFAULT_FIELDS);
  const [errors, validate] = useValidation<CloudFieldsProps>(validateCloud, [
    fields,
  ]);

  useEffect(() => {
    if (activeConnection) resetFields(activeConnection);
  }, [activeConnection]);

  useEffect(() => {
    return () => {
      resetFields(DEFAULT_FIELDS);
    };
  }, [activeConnectionType]);

  useEffect(() => {
    if (connectioTypeField === "directConnection") {
      setCloudSelected(false);
    }
  }, [connectioTypeField]);

  const getParamsByType = (): AzureVPNBody | AWSVPNBody => {
    let labels;
    switch (activeConnectionType) {
      case AZURE_LOWERCASE:
        const vnet_name = fields.vpc_id;
        const regionFromMap =
          azureLocations[fields.region as keyof typeof azureLocations];
        labels = {
          remote_type: activeConnectionType,
          vnet_name,
          region: regionFromMap,
        };
        return {
          labels,
          resource_group_name: fields.resource_group_name,
          connection_name: fields.connection_name,
          network_segments: fields.network_segments,
          vnet_name,
          region: regionFromMap,
        } as AzureVPNBody;
      default:
        labels = {
          remote_type: activeConnectionType,
          vpc_id: fields.vpc_id,
          regigon: fields.region,
        };
        return {
          ...fields,
          labels,
        } as AWSVPNBody;
    }
  };

  const handleAddConnection = () => {
    const { isOk } = validate();

    if (isOk && activeConnectionType && activeLocation) {
      const newFields = getParamsByType();
      addVPNByType(activeConnectionType, newFields, user.name, activeLocation);
    }
  };

  return (
    <>
      <FormTitle />
      <div className={styles.formContainer}>
        <Input
          value={fields.connection_name}
          label="Name"
          onChange={(e) => {
            handleFieldChange("connection_name", e.target.value);
          }}
          isFullValue
          medium
          error={errors?.name}
        />
        <ConnectionTypeGroup
          value={connectioTypeField}
          onChange={(val) => setConnectionTypeField(val)}
          type={activeConnectionType}
        />
        <Toggle
          isChecked={cloudSelected}
          onChange={() => setCloudSelected(!cloudSelected)}
          label="Agent"
          disabled
        />
        <DropdownBasic
          id={"cloudFormConnectionBandwidth"}
          onChange={(val) => handleFieldChange("max_bandwidth", val.key)}
          selected={mapStringToItem(fields.max_bandwidth)}
          label="Connection Bandwidth"
          itemsList={MAX_BANDWIDTH_LIST.map((key) => mapStringToItem(key))}
          isMedium
        />
        <div className={styles.fieldsGroup}>
          {activeConnectionType &&
          [AWS_LOWERCASE, GCP_LOWERCASE].includes(activeConnectionType) ? (
            <VPCList
              fields={fields}
              onChange={handleFieldChange}
              error={errors?.vpc_id}
            />
          ) : (
            <VnetList
              fields={fields}
              onChange={handleFieldChange}
              error={errors?.vpc_id}
            />
          )}
        </div>
        <DropdownBasic
          id={"cloudFormSubscriptionPeriod"}
          onChange={(val) => handleFieldChange("subscription", val.key)}
          selected={mapStringToItem(fields["subscription"])}
          label="Subscription period"
          itemsList={SUBSCRIPTION_PERIOD_LIST.map((key) =>
            mapStringToItem(key)
          )}
          isMedium
        />
        <SegmentsComponent
          list={fields.network_segments || []}
          onChange={handleFieldChange}
          error={errors?.network_segments}
        />
      </div>
      <FormFooter onAdd={handleAddConnection} />
    </>
  );
};
export default AddCloudForm;
