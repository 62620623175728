import { FWDiaRuleType } from "../../helpers/api/apiTypes";

export const getGroupedDiaFW = (
  rules: Array<FWDiaRuleType>
): { [key: string]: Array<FWDiaRuleType> } => {
  return rules.reduce(
    (acc: { [key: string]: Array<FWDiaRuleType> }, rule: FWDiaRuleType) => {
      const { gate_vi_name } = rule;
      if (!acc[gate_vi_name]) {
        acc[gate_vi_name] = [];
      }
      acc[gate_vi_name].push(rule);
      return acc;
    },
    {}
  );
};
