import { FC, useEffect } from "react";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { PageProps } from "../../../helpers/types";
import {
  NetworkContextContainer,
  useNetworkContext,
} from "../context/NetworkContext";
import styles from "../InterfaceStatistics/ConnectionsStatistics.module.scss";
import InterfaceStatistics from "../InterfaceStatistics/InterfaceStatistics";

const ConnectionsStatistics: FC<PageProps> = ({ tabs: Tabs }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchConnections, fetchSystems } = useNetworkContext();

  useEffect(() => {
    fetchConnections();
    fetchSystems();
  }, [selectedTenant]);

  return (
    <>
      <div className={styles.pageWrapper}>
        <InterfaceStatistics />
      </div>
    </>
  );
};

export default withContexts<PageProps>(ConnectionsStatistics, [
  NetworkContextContainer,
]);
