import { DIA_UNITED_CONNECTIONS } from "../../ZayoPages/Connections/ConnectionConfigurator/consts";
import {
  checkIP,
  checkIPv4WithPrefix,
} from "../../../helpers/validators/Validator";
import { TenantCustomerPortCreate } from "./types";
import { NATIVE } from "../../../helpers/consts";

interface IPAddresses {
  ipv4_addresses: Array<string>;
  ipv6_addresses: Array<string>;
}

export function parseIPAddresses(addresses: string): IPAddresses {
  const body: IPAddresses = {
    ipv4_addresses: [],
    ipv6_addresses: [],
  };
  if (!addresses) return body;
  const isIPv4 = checkIP(addresses) || checkIPv4WithPrefix(addresses);
  if (isIPv4) {
    body.ipv4_addresses = [addresses];
  } else {
    body.ipv6_addresses = [addresses];
  }
  return body;
}

export const BASIC_BODY: Partial<TenantCustomerPortCreate> = {
  name: "",
};

export const getNewCustomerPortBody = (
  name: string,
  endpointType: string,
  connection: {
    [key: string]: any;
  },
  idx: number,
  vlan_id?: number | null,
  virtual_interface_name?: string
): TenantCustomerPortCreate => {
  const body = { ...BASIC_BODY };
  body.name = connection.name;
  const isDia = endpointType === DIA_UNITED_CONNECTIONS;
  if (isDia && vlan_id) {
    body.vlan_id = vlan_id;
  } else if (!isDia && connection.vlan_id !== NATIVE) {
    body.vlan_id = connection.vlan_id;
  }

  body.vi_name = virtual_interface_name || name;
  body.max_bandwidth = parseInt(connection?.max_bandwidth) || 0;
  if (isDia) {
    body.vi_name = connection.name;
  }

  return body as TenantCustomerPortCreate;
};
