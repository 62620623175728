import React, { FC } from "react";
import DeleteIcon from "../../icons/DeleteIcon";
import IconBlankButton, { IconBlankButtonType } from "./IconBlankButton";

type Props = {
  onClick: (arg?: any) => void;
  withLabel?: boolean;
  title?: string;
  disabled?: boolean;
  id: string;
  color?: string;
  className?: string;
} & Omit<IconBlankButtonType, "icon" | "onClick">;

const DeleteButton: FC<Props> = ({
  onClick,
  withLabel,
  title,
  disabled,
  id,
  color,
  className,
  ...rest
}) => {
  return (
    <IconBlankButton
      id={`delete-${id}`}
      title={title}
      onClick={onClick}
      icon={DeleteIcon}
      label={withLabel ? "Delete" : undefined}
      disabled={disabled}
      color={color}
      className={className}
      {...rest}
    />
  );
};
export default DeleteButton;
