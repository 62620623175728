import { useEffect, useMemo, useState } from "react";
import { PRes } from "../../../../../../helpers/api/Api";
import { GCPCloudCredentials } from "../../../../types";
import { useValidation } from "../../../../../../helpers/validators/Validator";
import { useFormField } from "../../../../../../helpers/hooks/useFormField";
import { formFieldProps } from "../../../../../../helpers/dialogs/FieldProps";
import { getDialogControls } from "../../../../../../helpers/getDialogControls";
import { Actions } from "../Actions";
import Input from "../../../../../../components/common/formComponents/Input";
import styles from "./GCPForm.module.scss";
import validateGCPCloudCredentials from "../../../../../../helpers/validators/CloudCredentialsGCPValidator";
import Textarea from "../../../../../../components/common/formComponents/Textarea";
import { useUserContext } from "../../../../../../contexts/UserContext";
import debounce from "lodash/debounce";

type GCPFormProps = {
  createGCPKey: (params?: GCPCloudCredentials) => PRes<any>;
  deleteGCPKey: (id?: number) => PRes<any>;
  updateGCPKey: (id?: number, params?: GCPCloudCredentials) => PRes<any>;
  activeGCPKey: GCPCloudCredentials | undefined;
  onClose: () => void;
};

const getDefaultFields = (
  data?: Partial<GCPCloudCredentials>
): Partial<GCPCloudCredentials> => ({
  json_data: data?.json_data ? JSON.stringify(data.json_data, null, 2) : "",
  portal_url: data?.portal_url ?? "",
  partner_name: data?.partner_name ?? "",
  is_active: true,
});

export const useGCPForm = ({
  createGCPKey,
  deleteGCPKey,
  updateGCPKey,
  activeGCPKey,
  onClose,
}: GCPFormProps) => {
  const DEFAULT_FIELDS = useMemo(() => getDefaultFields(activeGCPKey), [
    activeGCPKey,
  ]);

  const { isZayo } = useUserContext();

  const [fields, handleFieldChange, resetFields] = useFormField<
    Partial<GCPCloudCredentials>
  >(DEFAULT_FIELDS);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, validate] = useValidation<Partial<GCPCloudCredentials>>(
    validateGCPCloudCredentials,
    [fields]
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields]
  );

  const update = (params?: GCPCloudCredentials) =>
    updateGCPKey(activeGCPKey?.id, params);

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const action = isEdit ? update : createGCPKey;
      const { ok } = await action({
        ...fields,
        json_data: JSON.parse(fields.json_data),
      } as GCPCloudCredentials);
      if (ok) {
        onClose();
      }
    }
  };

  const handleDelete = async () => {
    const { ok } = await deleteGCPKey(activeGCPKey?.id);
    if (ok) {
      onClose();
    }
  };

  const intermediateState = !!(!isEdit && activeGCPKey);

  const controls = getDialogControls({
    dialogTitle: activeGCPKey ? "edit" : "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
    disableSubmit: intermediateState,
  });

  useEffect(() => {
    if (isEdit && activeGCPKey) {
      resetFields(getDefaultFields(activeGCPKey));
    } else {
      resetFields(DEFAULT_FIELDS);
    }
  }, [isEdit]);

  useEffect(() => {
    if (isZayo) {
      handleFieldChange("partner_name", "Zayo Group");
    }
  }, [isZayo]);

  const debouncedJsonFormat = useMemo(
    () =>
      debounce((value: string) => {
        try {
          const parsed = JSON.parse(value);
          handleFieldChange("json_data", JSON.stringify(parsed, null, 2));
        } catch (e) {
          // If JSON is invalid, do nothing and keep the current value
        }
      }, 500),
    [handleFieldChange]
  );

  const handleJsonChange = (value: string) => {
    handleFieldChange("json_data", value);
    debouncedJsonFormat(value);
  };

  const form = (
    <>
      {activeGCPKey && !isEdit && (
        <Actions onDelete={handleDelete} onEdit={() => setIsEdit(true)} />
      )}
      {(!activeGCPKey || isEdit) && (
        <div className={styles.fieldsWrapper}>
          <div className={styles.fieldsRow}>
            {!isZayo && (
              <Input
                {...fieldProps("partner_name")}
                label="Partner name"
                error={errors && errors["partner_name"]}
              />
            )}
            <Input
              {...fieldProps("portal_url")}
              label="Portal url"
              error={errors && errors["portal_url"]}
            />
          </div>
          <Textarea
            {...fieldProps("json_data")}
            className={styles.textArea}
            label="Credential Data (json)"
            name="json_data"
            placeholder={fields.json_data}
            value={fields.json_data}
            onChange={(_, value) => handleJsonChange(value as string)}
            error={errors && errors["json_data"]}
          />
        </div>
      )}
    </>
  );

  return { controls, form };
};
