import React, { useCallback } from "react";
import { PageProps, PathEntry } from "../../helpers/types";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../common";
import { RouteEntry } from "../types";
import {
  CONFIGURATION,
  SERVICES_FW,
  SERVICES_FW_DIA_REPORTS,
} from "../../helpers/navigation/entries";
import FirewallDiaContent from "./FirewallDiaContent";
import FirewallContent from "../Firewall/FirewallContent";
import FirewallReports from "../Firewall/FirewallReports";

const FirewallDia: React.FC<PageProps> = ({ tabs: Tabs }) => {
  const path = useMatchPath();
  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={fullPath(CONFIGURATION())} />
      </Route>
      <Routes
        routes={cyberThreatsRoutes}
        tabs={Tabs}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default FirewallDia;

const FwConfig: React.FC<PageProps> = ({ tabs: Tabs }) => {
  return <FirewallContent tabs={Tabs} />;
};

const FwReports: React.FC<PageProps> = ({ tabs: Tabs }) => {
  return <FirewallReports tabs={Tabs} />;
};

const FwDiaConfig: React.FC<PageProps> = ({ tabs: Tabs }) => {
  return <FirewallDiaContent tabs={Tabs} />;
};

const cyberThreatsRoutes: Array<RouteEntry> = [
  {
    pathFunc: CONFIGURATION,
    component: FwDiaConfig,
  },
  {
    pathFunc: SERVICES_FW,
    component: FwConfig,
  },
  {
    pathFunc: SERVICES_FW_DIA_REPORTS,
    component: FwReports,
  },
];
