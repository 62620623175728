import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../../../../helpers/consts";

export const getIpsecExtraType = (fields: { [key: string]: any }): string => {
  if (fields.isAdvanced) return "ipsec";

  if (fields.endPoint === "cloud") {
    switch (fields.cloudOperator) {
      case AWS_LOWERCASE:
        return AWS_LOWERCASE;
      case AZURE_LOWERCASE:
        return AZURE_LOWERCASE;
      case GCP_LOWERCASE:
        return GCP_LOWERCASE;
      case "equinixMetal":
        return "equinixMetal";
      default:
        return "";
    }
  } else if (fields.endPoint === "branch") {
    return "branch";
  }

  return "";
};
