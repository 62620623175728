import React, { FC } from "react";
import { QuotaType } from "../../../../helpers/api/apiTypes";
import { classNames } from "../../../../helpers/common/classNames";
import styles from "./PackagesHeader.module.scss";
import SectionHeader from "./SectionHeader";

type Props = {
  list: Array<QuotaType>;
  onSave?: (title: string) => void;
  onSelect: (title: string) => void;
  isDefaultPackages?: boolean;
  activePackage: string;
  tenant?: string;
};
const PackagesHeader: FC<Props> = ({
  list,
  activePackage,
  onSave,
  onSelect,
  isDefaultPackages,
  tenant,
}) => {
  return (
    <div
      className={classNames(
        styles.tableHeader,
        isDefaultPackages && styles.shortened
      )}
    >
      <div className={styles.headerBasic}>
        <div className={styles.title}>{tenant || "Packages"}</div>
        <div className={styles.subTitle}>
          {isDefaultPackages ? "Limits and features" : "Selected package"}
        </div>
      </div>
      {list &&
        list?.map((quota) => (
          <SectionHeader
            key={quota.name}
            title={quota?.name}
            activePackage={activePackage}
            onSave={onSave}
            onSelect={onSelect}
            isDefaultPackages={isDefaultPackages}
          />
        ))}
    </div>
  );
};
export default PackagesHeader;
