import React, { useEffect, useMemo } from "react";
import DropdownBasic, { DropDownBasicProps } from "../Dropdown/DropdownBasic";
import { withContexts } from "../../../helpers/hocs/withContexts";
import PhysicalPortsContextContainer, {
  usePhysicalPortsContext,
} from "./contexts/PhysicalPortsContext";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { PortType } from "../../../pages/PortsPage/types";

type Props = {
  system?: string;
  selected?: string;
  onSelect: (port: string) => void;
  disabled?: boolean;
  hideLabel?: boolean;
  id: string;
  isNotEditable?: boolean;
  setSelectedPort?: (port: PortType | undefined) => void;
} & Omit<DropDownBasicProps<PortType>, "itemsList">;

const PhysicalPortsDropdown = ({
  selected,
  onSelect,
  system,
  disabled,
  hideLabel,
  id,
  isNotEditable,
  setSelectedPort,
  ...dropdownProps
}: Props) => {
  const { fetchList, list } = usePhysicalPortsContext();
  useEffect(() => {
    if (system) {
      fetchList(system);
    }
  }, [system]);

  const items = useMemo(
    () =>
      (list || []).map((item) => ({
        value: item.name,
        key: item.name,
        data: item,
      })),
    [list]
  );
  return (
    <DropdownBasic
      id={id}
      onChange={(val) => {
        onSelect(val.key);
        setSelectedPort?.(val.data);
      }}
      selected={mapStringToItem(selected || "")}
      label={hideLabel ? undefined : "Port name"}
      placeholder="Physical Port"
      itemsList={items}
      isMedium
      disabled={disabled}
      isNotEditable={isNotEditable}
      {...dropdownProps}
    />
  );
};

export default withContexts<Props>(PhysicalPortsDropdown, [
  PhysicalPortsContextContainer,
]);
