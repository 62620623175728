import { useEffect, useState, useRef } from "react";
import { PRes, Res } from "../api/Api";

interface Cache<T> {
  data: T;
  timestamp: number;
}

type CacheConfig = {
  enabled?: boolean;
  ttl?: number;
  cacheKey?: string;
};

type FetchParams<TParams, TReturn = any> = {
  fetchFn: (params?: TParams) => PRes<TReturn>;
  isEnabled?: boolean;
  deps?: Array<any>;
  cache?: CacheConfig;
  shouldResetState?: boolean;
};

const cacheStore = new Map<string, Cache<any>>();

export const resetCache = (key?: string) => {
  if (key) {
    cacheStore.delete(key);
  } else {
    cacheStore.clear();
  }
};

export const useFetch = <TParams = undefined, TReturn = any>({
  fetchFn,
  isEnabled = false,
  shouldResetState,
  deps,
  cache = { enabled: false, ttl: 5 * 60 * 1000 }, // 5 minutes default TTL
}: FetchParams<TParams, TReturn>) => {
  const initialState: Res<TReturn> = {
    ok: true,
    isLoading: false,
    isRefetching: false,
    isIdle: true,
  };

  const [state, setState] = useState<Res<TReturn>>(initialState);

  const cacheKey = useRef<string>(cache.cacheKey || fetchFn.toString()).current;

  const resetState = () => {
    setState(initialState);
    resetCurrentCache();
  };

  const fetch = async (params?: TParams) => {
    if (cache.enabled) {
      const cachedData = cacheStore.get(cacheKey);
      const now = Date.now();

      if (cachedData && now - cachedData.timestamp < (cache.ttl || 0)) {
        setState((prev) => ({
          ...prev,
          ...cachedData.data,
          isLoading: false,
          isIdle: false,
        }));
        return cachedData.data;
      }
    }

    setState((prev) => ({ ...prev, isLoading: true, isIdle: false }));
    const response = await fetchFn(params);

    if (response.ok) {
      if (cache.enabled) {
        cacheStore.set(cacheKey, {
          data: response,
          timestamp: Date.now(),
        });
      }
      setState((prev) => ({
        ...prev,
        ...response,
        isLoading: false,
        isRefetching: false,
        isIdle: false,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        error: response.error,
        response: undefined,
        isLoading: false,
        isRefetching: false,
        isIdle: false,
      }));
    }

    if (shouldResetState) resetState();

    return response;
  };

  const refetch = async (params?: TParams) => {
    setState((prev) => ({ ...prev, isRefetching: true, isIdle: false }));
    resetCurrentCache();
    return await fetch(params);
  };

  useEffect(() => {
    const isEmptyDependency = deps?.some(
      (dep) => dep === undefined || dep === null
    );

    if (isEmptyDependency) return;

    if (isEnabled) {
      fetch();
    }
  }, [isEnabled, deps]);

  const resetCurrentCache = () => {
    resetCache(cacheKey);
  };

  return { state, fetch, refetch, resetCurrentCache, resetState };
};
