import { CellProps } from "react-table";
import LabelArray from "../../../components/common/table/LabelArray";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import VirtInterfaceIcon from "../../../components/icons/VirtInterfaceIcon";
import { VirtualInterface } from "../types";
import {
  checkIP,
  checkIPv4WithPrefix,
  checkIPv6,
  checkIPv6WithPrefix,
} from "../../../helpers/validators/Validator";
import styles from "./virtualInterfaceTableHeader.module.scss";
import { MemberType } from "../../Layer3Interface/types";

export const virtualInterfaceTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell: { value } }: CellProps<VirtualInterface>) => {
        return <TextWithIcon icon={<VirtInterfaceIcon />} text={value} />;
      },
    },
    {
      Header: "Type",
      accessor: "virtual_interface_type",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return <div>{value || ""}</div>;
      },
    },
    {
      Header: "IP",
      accessor: "ip_addresses",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        const ipv4 = value?.filter(
          (val: string) => checkIPv4WithPrefix(val) || checkIP(val)
        );
        const ipv6 = value?.filter(
          (val: string) => checkIPv6WithPrefix(val) || checkIPv6(val)
        );
        return (
          <div>
            <LabelArray values={ipv4} withWrap={true} />
            <LabelArray
              values={ipv6}
              singleLabelClassname={styles.ips}
              withWrap={true}
            />
          </div>
        );
      },
    },
    {
      Header: "L2 Members",
      accessor: "members",
      Cell: ({ cell: { value } }: CellProps<VirtualInterface>) => {
        const membersList = value
          ?.filter((member: MemberType) => member.interfaces?.length > 0)
          .map((member: MemberType) => member.interfaces)
          .flat()
          .map((item: any) => item?.name);
        return <LabelArray values={membersList || []} />;
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return <div>{value || ""}</div>;
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInterface,
  }));
};
