import { VirtualInetrfaceDIACreate } from "../api/TenantVirtualInterfaceApi/types";
import Validator from "./Validator";

export default function validateDIA(
  data: VirtualInetrfaceDIACreate,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkEmpty(data.system?.location, "system");
  return validator;
}
