import React from "react";
import { tenantApi } from "../../helpers/api/TenantApi";
import {
  ServiceStaticRouteType,
  SortType,
  StaticRoutesBodyType,
} from "../../helpers/api/apiTypes";
import { RequestStatus } from "../../helpers/types";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { UserRoles } from "../../helpers/rolesHelpers";
import { filteredDataByGetawayIp } from "../../helpers/filteredDataByGatawayIp";

type RequestStatuses = {
  staticRoutesStatus?: RequestStatus;
  dynamicRoutesStatus?: RequestStatus;
  addStaticRouteStatus?: RequestStatus;
  editStaticRouteStatus?: RequestStatus;
};

type IState = {
  dynamicRoutesList: Array<any>;
  dynamicRoutesCount: number;
  staticRoutesList: Array<StaticRoutesBodyType>;
  staticRoutesCount: number;
  isUpdateStaticRoutesServicesList: boolean;
} & RequestStatuses;

type IFunc = {
  fetchStaticRoutesList: (
    tenantName: string,
    role: UserRoles,
    offset: number,
    limit: number,
    arg?: SortType
  ) => Promise<void>;
  fetchDynamicRoutesList: (
    tenantName: string,
    role: UserRoles,
    offset: number,
    limit: number,
    arg?: SortType
  ) => Promise<void>;
  addStaticRoutes: (
    tenant: string,
    value: ServiceStaticRouteType,
    role: UserRoles
  ) => Promise<boolean>;
  enableStaticRoutes: (
    tenant: string | undefined,
    value: ServiceStaticRouteType
  ) => Promise<boolean>;
  deleteStaticRoutes: (
    tenant: string,
    value: StaticRoutesBodyType
  ) => Promise<boolean>;
  setIsUpdateStaticRoutesServicesList: (arg: boolean) => void;
  resetAddStaticRouteStatus: () => void;
};

// type IStaticRoutesContext = IState & IFunc;

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useStaticRoutesServicesContext = useContext;

export default class StaticRoutesServicesContextContainer extends AbstractTimeoutHandler<IState> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      staticRoutesList: [],
      dynamicRoutesList: [],
      isUpdateStaticRoutesServicesList: false,
      addStaticRouteStatus: {
        state: "idle",
      },
      dynamicRoutesCount: 0,
      staticRoutesCount: 0,
    };

    this.funcs = {
      fetchStaticRoutesList: this.fetchStaticRoutesList,
      fetchDynamicRoutesList: this.fetchDynamicRoutesList,
      addStaticRoutes: this.addStaticRoutes,
      enableStaticRoutes: this.enableStaticRoutes,
      resetAddStaticRouteStatus: this.resetAddStaticRouteStatus,
      deleteStaticRoutes: this.deleteStaticRoutes,
      setIsUpdateStaticRoutesServicesList: this
        .setIsUpdateStaticRoutesServicesList,
    };
  }

  fetchStaticRoutesList = async (
    tenantName: string,
    role: UserRoles,
    offset: number,
    limit: number,
    sortBy?: SortType
  ): Promise<void> => {
    this.setState({ staticRoutesStatus: setPending("Fetching") });
    const res = await tenantApi.getTenantStaticRoutes(
      tenantName,
      offset,
      limit,
      sortBy
    );
    const result = res.result?.items || [];

    const data = filteredDataByGetawayIp(result, "gateway_ip_address", role);

    this.setState({ staticRoutesList: data });
    this.setState({
      staticRoutesStatus: resToState(res),
      staticRoutesCount: res.result?.count || 0,
    });
  };

  fetchDynamicRoutesList = async (
    tenantName: string,
    role: UserRoles,
    offset: number,
    limit: number,
    sortBy?: SortType
  ): Promise<void> => {
    this.setState({ dynamicRoutesStatus: setPending("Fetching") });
    const res = await tenantApi.getTenantDynamicRoutes(
      tenantName,
      offset,
      limit,
      sortBy
    );

    if (res.ok && res.result) {
      const data = filteredDataByGetawayIp(
        res.result.items || [],
        "gateway",
        role
      );
      this.setState({
        dynamicRoutesList: data,
        dynamicRoutesCount: res.result.count || 0,
      });
    }
    this.setState({ dynamicRoutesStatus: resToState(res) });
  };

  addStaticRoutes = async (
    tenant: string,
    value: ServiceStaticRouteType
  ): Promise<boolean> => {
    this.setState({ addStaticRouteStatus: { state: "pending" } });

    const res = await tenantApi.addStaticRoutes(tenant, value);

    this.setState({
      addStaticRouteStatus: res.ok
        ? {
            state: "ok",
          }
        : {
            state: "error",
            message: res.error,
          },
      isUpdateStaticRoutesServicesList: res.ok,
    });

    return res.ok;
  };

  enableStaticRoutes = async (
    tenant: string | undefined,
    value: ServiceStaticRouteType
  ): Promise<boolean> => {
    if (!tenant) return false;
    this.setState({ editStaticRouteStatus: { state: "pending" } });

    const res = await tenantApi.editTenantStaticRoute(
      tenant,
      !value.enable,
      value.destination
    );

    this.setState({
      editStaticRouteStatus: res.ok
        ? {
            state: "ok",
          }
        : {
            state: "error",
            message: res.error,
          },
      isUpdateStaticRoutesServicesList: !res.ok,
    });

    if (res.ok) {
      const routes = [...this.state.staticRoutesList];
      const updated = routes.find(
        (route) => route.destination === value.destination
      );
      if (updated) {
        updated.enable = !updated.enable;
        const idx = routes.indexOf(updated);
        routes[idx] = updated;
        this.setState({ staticRoutesList: routes });
      }
    }

    return res.ok;
  };

  deleteStaticRoutes = async (
    tenant: string,
    value: StaticRoutesBodyType
  ): Promise<boolean> => {
    const params = {
      destination: value.destination,
      gateway_ip_address: value.gateway_ip_address,
    };
    const res = await tenantApi.deleteStaticRoutes(tenant, params);
    this.setState({ isUpdateStaticRoutesServicesList: res.ok });
    return res.ok;
  };

  setIsUpdateStaticRoutesServicesList = (value: boolean): void => {
    this.setState({ isUpdateStaticRoutesServicesList: value });
  };

  resetAddStaticRouteStatus = (): void => {
    this.setState({ addStaticRouteStatus: { state: "idle" } });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
