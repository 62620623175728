import { Res } from "../../../../helpers/api/Api";
import { PaginateListResponse, RequestStatus } from "../../../../helpers/types";
import { AwsNNI, RegionItem, TotalProviderInfo } from "../../types";
import Table from "../../../../components/common/table/newTable/Table";
import { awsNNITableHeader, awsRegionsTableHeader } from "./tableHeaders";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { TableWrapper } from "../TableWrapper";
import useDeleteDialog from "../../../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";

type AwsTableProps = {
  awsState: Res<PaginateListResponse<AwsNNI>>;
  combinedAwsInfo: {
    regions: Array<RegionItem>;
    total: TotalProviderInfo;
  };
  refetchAwsState: () => Promise<void>;
  isAwsRefetching: boolean;
  deleteNNI: (id?: string) => Promise<void>;
  deleteStatus: RequestStatus;
};

export const AwsTable = ({
  awsState,
  combinedAwsInfo,
  refetchAwsState,
  isAwsRefetching,
  deleteNNI,
  deleteStatus,
}: AwsTableProps) => {
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<AwsNNI>((row) => deleteNNI(row.nni_id), deleteStatus);

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"AWS NNI"}
        name={selectedToDelete?.nni_id}
      />
      <TableWrapper
        items={awsState.result?.items}
        nniTab={
          <Table
            columns={awsNNITableHeader()}
            header={TableHeader}
            data={awsState.result?.items || []}
            gridColumnTemplate={`110px 140px 160px 160px 140px 180px 180px 180px 110px 100px`}
            withoutPagination
            rowActions={{ onDelete: tryDelete }}
          />
        }
        regionTab={
          <Table
            columns={awsRegionsTableHeader()}
            header={TableHeader}
            data={combinedAwsInfo.regions || []}
            gridColumnTemplate={`110px 140px 160px 140px 180px 110px`}
            withoutPagination
          />
        }
        refetch={refetchAwsState}
        isRefetching={isAwsRefetching}
      />
    </>
  );
};
