import React, { useEffect, useMemo } from "react";

import { OneSystemType, VRFType } from "../../../helpers/api/apiTypes";
import DropdownBasic from "../Dropdown/DropdownBasic";
import { withContexts } from "../../../helpers/hocs/withContexts";
import VIListContextContainer, {
  useVIListContext,
} from "./contexts/VIListContext";
import { VirtualInterfaceApi } from "../../../pages/VirtualInterfacePage/types";

type Props = {
  system?: OneSystemType;
  vrf?: VRFType;
  selected?: Partial<VirtualInterfaceApi>;
  onSelect: (vrf?: Partial<VirtualInterfaceApi>) => void;
  className?: string;
  label?: string;
  force?: boolean;
  withClearIcon?: boolean;
};
const VIDropdown: React.FC<Props> = ({
  selected,
  onSelect,
  system,
  vrf,
  className,
  label,
  force,
  withClearIcon,
}) => {
  const { fetchList, list } = useVIListContext();

  useEffect(() => {
    if (system && vrf) {
      fetchList(vrf, system);
    }
    if (vrf && force) {
      fetchList(vrf, system);
    }
  }, [fetchList, system, vrf]);
  const selectedIem = useMemo(
    () =>
      selected
        ? {
            value: selected.name ?? "",
            key: selected.name ?? "",
            data: selected,
          }
        : undefined,
    [selected]
  );
  const items = useMemo(
    () =>
      (list || []).map((item) => ({
        value: item.name,
        key: item.name,
        data: item,
      })),
    [list]
  );
  return (
    <DropdownBasic
      id={"VIDrop"}
      onChange={(val) => {
        if (val.data) {
          onSelect(val.data);
        } else {
          onSelect({ name: "" });
        }
      }}
      selected={selectedIem}
      label={label || "Virtual Interface"}
      itemsList={items}
      isMedium
      disabled={!vrf}
      className={className}
      withClearIcon={withClearIcon}
      placeholder={selectedIem?.value}
    />
  );
};

export default withContexts<Props>(VIDropdown, [VIListContextContainer]);
