import React, { FC, useEffect, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { withContexts } from "../../helpers/hocs/withContexts";
import { PortType } from "./types";
import PortDialog from "./itemDialog/PortDialog";
import { classNames } from "../../helpers/common/classNames";
import styles from "./PortsPageContent.module.scss";
import PortsContext, { usePortsContext } from "./PortsContext";
import PortsScheme from "./PortsScheme/PortsScheme";
import { interfacesTableColumns } from "./table/interfacesTableColumns";
import AddButton from "../../components/common/buttons/AddButton";
import PortsStat from "./PortsStat";
import { isPending } from "../../helpers/common/RequestStateHelpers";
import TableGrouped from "../../components/common/table/newTable/TableGrouped";
import AddLagDialog from "./itemDialog/AddLagDialog";

const TRAFFIC_PORTS = "Traffic Ports";
const LAG_MEMBERS = "Lag Members";
const BACKPLANE_PORTS = "Backplane Ports";

const PortsPageContent: FC = () => {
  const {
    list = [],
    listStatus,
    showScheme,
    fetchList,
    filteredLists,
    selectPort,
    selectedPort,
    systemType,
  } = usePortsContext();

  const [viewItem, setViewItem] = useState<PortType | undefined>();
  const [schemeIsVisible, toggleSchemeVisibility] = useState<boolean>(true);
  const [isShowAdd, setShowAdd] = useState(false);

  useEffect(() => {
    fetchList();
  }, []);

  const rowActions: ActionFuncs<PortType> = useMemo(
    () => ({
      onEdit: setViewItem,
    }),
    []
  );

  const handleClick = (row: any) => {
    const value = selectedPort?.id === row.id ? "" : row.id;
    selectPort(value);
  };

  let listStatusBeforePending: any = undefined;
  if (!isPending(listStatus)) {
    listStatusBeforePending = listStatus;
  }
  let handledStatus = listStatus;
  if (isPending(listStatus) && listStatusBeforePending) {
    handledStatus = listStatusBeforePending;
  }

  const vTypeSystem = systemType?.toLowerCase() === "nsos-v" ? true : false;
  return (
    <>
      {viewItem && (
        <PortDialog
          onClose={() => {
            setViewItem(undefined);
            fetchList();
          }}
          data={viewItem}
        />
      )}
      {isShowAdd && (
        <AddLagDialog onClose={() => setShowAdd(false)} type="add" />
      )}
      <div className={classNames(styles.wrapper, styles.twoCols)}>
        <TableWrapper
          titleProps={{
            title: `Interfaces (${(list || []).length})`,
            children: !vTypeSystem && (
              <AddButton label={"LAG"} onClick={() => setShowAdd(true)} />
            ),
          }}
          dataStatus={listStatus}
          tablePlaceholder={["Interfaces", "Interface"]}
          isEmpty={list && list.length === 0}
          className={styles.tableBlock}
        >
          <div className={styles.tableContainer}>
            <TableGrouped
              columns={interfacesTableColumns(
                handleClick,
                selectedPort?.name || "all"
              )}
              header={TableHeader}
              data={{
                [TRAFFIC_PORTS]: filteredLists?.data || [],
                [LAG_MEMBERS]: filteredLists?.lag || [],
                [BACKPLANE_PORTS]: filteredLists?.backplane || [],
              }}
              gridColumnTemplate={`145px 120px 75px 120px 120px 1fr 70px 70px 0`}
              rowActions={rowActions}
              onClick={handleClick}
              collapsed={[LAG_MEMBERS, BACKPLANE_PORTS]}
              withoutInitialCollapsed={[TRAFFIC_PORTS]}
              extraTitleHeader={"ports"}
              groupRowActions={[TRAFFIC_PORTS]}
              hideEmpty={[BACKPLANE_PORTS]}
              groupClassName={styles.groupedTableStyles}
              clickFeatures={[
                {
                  elementClass: "toggle",
                  clb: {},
                },
              ]}
              gridTemplateRows={"auto max-content auto"}
            />
          </div>
        </TableWrapper>
        <PortsStat className={styles.statBlock} />
        {showScheme && (
          <div
            className={classNames(
              styles.schemeWrapper,
              !schemeIsVisible && styles.hiddenScheme
            )}
          >
            <PortsScheme
              isSchemeShown={schemeIsVisible}
              onClick={() => toggleSchemeVisibility((prev) => !prev)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default withContexts(PortsPageContent, [PortsContext]);
