import { PropsWithChildren } from "react";
import { RequestStatus } from "../../helpers/types";
import { PRes } from "../../helpers/api/Api";
import { AbstractContextProvider } from "./AbstractContext";

let FetchTimeout: NodeJS.Timeout | undefined;
export const FAILED_TO_FETCH = "Failed to fetch";

export type CrudRequestStatuses = {
  listStatus?: RequestStatus;
  removeStatus?: RequestStatus;
  editStatus?: RequestStatus;
  addStatus?: RequestStatus;
};

export type CrudValueState<One> = {
  list?: Array<One>;
  count?: number;
};

export type CrudState<One> = CrudValueState<One> & CrudRequestStatuses;

export type CrudFunc<One> = {
  fetchList: (specificator?: string, offset?: number, limit?: number) => void;
  remove: (item: One) => Promise<boolean>;
  add?: (item: One) => Promise<boolean>;
  edit: (modifiedItem: One) => Promise<boolean>;
};

abstract class AbstractCrudContextContainer<
  One,
  State extends CrudState<One> & RequestsState,
  RequestsState extends CrudRequestStatuses,
  Funcs extends CrudFunc<any>,
  Props = {}
> extends AbstractContextProvider<State, RequestsState, Funcs, Props> {
  constructor(props: Readonly<PropsWithChildren<Props>>) {
    super(props);
  }

  abstract fetchList: () => void;

  componentWillUnmount(): void {
    this.clearTimeout();
  }

  clearTimeout = (): void => {
    if (FetchTimeout) {
      clearTimeout(FetchTimeout);
    }
    FetchTimeout = undefined;
  };

  _fetchListWrap = async (
    fn: (
      specificator?: string,
      offset?: number,
      limit?: number
    ) => PRes<Array<One>>
  ): PRes<Array<One>> => {
    const { ok, result, error, count } = await this._fetchWithStatus(
      fn,
      "listStatus"
    );
    this.setState({ list: ok ? result : undefined, count });

    if (error?.includes(FAILED_TO_FETCH)) {
      FetchTimeout = setTimeout(() => {
        this._fetchListWrap(fn);
      }, 5000);
    }
    return { ok, result, error, count };
  };

  _removeWrap = async (fn: () => PRes<void>): PRes<void> => {
    return this._doActionWrap(fn, "removeStatus");
  };

  _editWrap = async (fn: () => PRes<void>): PRes<void> => {
    return this._doActionWrap(fn, "editStatus");
  };

  _addWrap = async (fn: () => PRes<void>): PRes<void> => {
    return this._doActionWrap(fn, "addStatus");
  };

  _doActionWrap = async <D,>(
    fn: () => PRes<D>,
    status: keyof RequestsState
  ): PRes<D> => {
    const { ok, result, error } = await this._fetchWithStatus(fn, status);
    this._resetStatusInTimeout(status);
    if (ok) {
      this.fetchList();
    }
    return { ok, result, error };
  };
}

export default AbstractCrudContextContainer;
