/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../contexts/UserContext";
import { Res } from "../../../helpers/api/Api";
import { networkApi } from "../../../helpers/api/networkApi";
import { systemApi } from "../../../helpers/api/SystemApi";
import { DropdownItem, RequestStatus } from "../../../helpers/types";
import { System, SystemAPI } from "../../Systems/Provisioning/types";

type Status = "ok" | "pending" | "error";

import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";
import { tenantVirtualInterfacesApi } from "../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  RequestCustomerPort,
  TenantCustomerPort,
  TenantCustomerPortCreate,
  TenantPort,
} from "./types";
import { getGroupedPortsList } from "./components/Ports/groupPorts";
import {
  resToState,
  setError,
  setOk,
  setPending,
} from "../../../helpers/common/RequestStateHelpers";
import { FieldsType } from "../../ZayoPages/Connections/ConnectionConfigurator/types";
import { getDirectConnectBody } from "../Connections/itemConfigurator/helpers/getDirectConnectBody";
import { getNewCustomerPortBody } from "./getNewCustomerPortBody";
import { DIA_UNITED_CONNECTIONS } from "../../ZayoPages/Connections/ConnectionConfigurator/consts";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_PORTS,
} from "./components/Connections/consts";
import { VirtualInetrfaceBridgeDomainAdd } from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import { SiteRegion } from "../../../helpers/api/apiTypes";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  BRIDGED,
  GCP_LOWERCASE,
  NATIVE,
  ROUTED,
  VIRTUAL_INTERFACE_GATE,
} from "../../../helpers/consts";
import { parseBandwidth } from "../../../helpers/parseBandwidth";
import { calcBandwidthWithUnits } from "../../../helpers/calcBandwidthWithUnits";
import { checkIfInternet, getDirectAndCloudConnections } from "./helpers";

type IFunc = {
  fetchConnections: () => void;
  fetchSystems: () => void;
  fetchCurrentSystem: () => void;
  fetchLocations: () => void;
  getTenantPorts: () => Promise<void>;
  getRegions: () => Promise<void>;
  addDirectConnection: (
    name: string,
    connections: Array<FieldsType>,
    addFrom?: string
  ) => Promise<boolean>;
  addCloudConnection: (
    name: string,
    connections: Array<FieldsType>,
    addFrom?: string
  ) => Promise<boolean>;
  createTenantPort: (params: TenantCustomerPortCreate) => Promise<Res<any>>;
  updateCustomerPort: (
    name: string,
    portName: string,
    bw: number,
    isCloud?: boolean
  ) => Promise<boolean>;
  selectPort: (name: string) => Promise<void>;
  requestPort: (params: RequestCustomerPort) => Promise<void>;
  deleteCustomerPort: (name: string, type: string | undefined) => Promise<void>;
  getCustomerPorts: () => Promise<void>;
};

type IState = {
  connections: Array<VirtualInterfaceApi>;
  systems: Array<System>;
  connectionFetchStatus: Status;
  addDirectStatus: RequestStatus;
  addCloudStatus: RequestStatus;
  requestPortStatus: RequestStatus;
  locations: Array<DropdownItem>;
  configApi: string;
  ports: Array<TenantPort>;
  customerPorts: Array<TenantCustomerPort>;
  portsFetchStatus: RequestStatus;
  groupedPorts: { [key: string]: Array<TenantCustomerPort> };
  directConnections: Array<VirtualInterfaceApi>;
  cloudConnections: Array<VirtualInterfaceApi>;
  parentPorts: Array<TenantPort>;
  selectedPort?: TenantCustomerPort;
  regions: Array<SiteRegion>;
  requestRegionStatus: RequestStatus;
};

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const [UnitedConnectionContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useUnitedConnectionContext = useContext;

export const UnitedConnectionContextContainer: FC<Props> = ({ children }) => {
  const { user } = useUserContext();
  const { selectedTenant } = useGlobalFilterContext();
  const [connections, setConnections] = useState<Array<VirtualInterfaceApi>>(
    []
  );
  const [directConnections, setDirectConnections] = useState<
    Array<VirtualInterfaceApi>
  >([]);
  const [cloudConnections, setCloudConnections] = useState<
    Array<VirtualInterfaceApi>
  >([]);

  const [systems, setSystems] = useState<Array<System | SystemAPI>>([]);
  const [ports, setPorts] = useState<Array<TenantPort>>([]);
  const [customerPorts, setCustomerPorts] = useState<Array<TenantCustomerPort>>(
    []
  );
  const [parentPorts, setParentPorts] = useState<Array<TenantPort>>([]);
  const [selectedPort, setSelectedPort] = useState<
    TenantCustomerPort | undefined
  >();
  const [groupedPorts, setGroupedPorts] = useState<{
    [key: string]: Array<TenantCustomerPort>;
  }>({});
  const [locations, setLocations] = useState<Array<DropdownItem>>([]);
  const [connectionFetchStatus, setConnectionFetchStatus] = useState<Status>(
    "pending"
  );
  const [portsFetchStatus, setPortsFetchStatus] = useState<RequestStatus>();
  const [addDirectStatus, setAddDirectStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [requestPortStatus, setRequestPortStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [addCloudStatus, setAddCloudStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [regions, setRegions] = useState<Array<SiteRegion>>([]);
  const [requestRegionStatus, setRequestRegionStatus] = useState<RequestStatus>(
    {
      state: "idle",
    }
  );

  const tenant = selectedTenant || user?.name;

  const selectPort = (name: string) => {
    const port = customerPorts.find((port) => port.name === name);

    if (port) {
      setSelectedPort(port);
    }
  };

  const fetchConnections = async () => {
    setConnectionFetchStatus("pending");
    const { result, ok } = await networkApi.getVirtualInterfaces(tenant);

    if (!ok || !result) {
      setConnectionFetchStatus("error");
      return;
    }

    setConnections(result.items);
    const { direct, cloud } = getDirectAndCloudConnections(result.items);
    setCloudConnections([...cloud]);
    setDirectConnections([...direct]);
    setConnectionFetchStatus("ok");
  };

  const fetchLocations = async (): Promise<void> => {
    const { ok, result } = await systemApi.getSystemsList();
    if (ok && result) {
      const locations = result.map((system) => ({
        key: system.name,
        value: system.location,
      }));
      setLocations(locations);
    }
  };

  const fetchSystems = async () => {
    const { result } = await systemApi.getSystemsList();

    if (!result) {
      return;
    }
    setSystems(result);
  };

  const getTenantPorts = async () => {
    setPortsFetchStatus(setPending("Fetching"));
    const { ok, result } = await configApi.getTenantPorts(tenant);
    await getCustomerPorts();

    if (!ok || !result) {
      setPortsFetchStatus(setError());
      return;
    }
    setPorts(result);
    setParentPorts(result.filter((parent) => !parent.parent));
    setPortsFetchStatus(setOk());
  };

  const getCustomerPorts = async () => {
    const { ok, result } = await configApi.getCustomerPorts(tenant);
    if (ok && result?.length) {
      setCustomerPorts(result);
      const grouped = getGroupedPortsList(result);
      setGroupedPorts(grouped);
    }
  };

  const assignPorts = async (
    name: string,
    connections: Array<FieldsType>,
    addFrom?: string
  ): Promise<Array<any>> => {
    const finalRes: Array<any> = [];

    await Promise.all(
      connections.map(async (connection, index) => {
        if (connection.innerType === CUSTOMER_PORTS) {
          const item = getNewCustomerPortBody(
            name,
            connection.innerType,
            connection,
            0,
            connection.vlan_id ? parseInt(connection.vlan_id) : undefined
          );
          const response = await createTenantPort(item);
          finalRes.push(response);
        } else if (connection.innerType === CLOUD_CONNECTIONS) {
          const { value, unit } = parseBandwidth(connection.BW);
          const max_bandwidth = calcBandwidthWithUnits(value, unit);
          let cloudPortBody;
          // we count only 1st connection for addFromInventory
          if (addFrom && index === 0) {
            cloudPortBody = {
              vi_name: name,
              aws_customer_port_name: connection.aws_customer_port_name,
            };
          } else {
            cloudPortBody = {
              vi_name: name,
              site_name: connection.site_name,
              account_id: connection.Customer_ID,
              max_bandwidth: String(max_bandwidth),
              aws_customer_port_name: connection.aws_customer_port_name,
            };
          }

          const cloudPortResponse = await createCloudPort(cloudPortBody);

          finalRes.push(cloudPortResponse);
        }
      })
    );

    return finalRes;
  };

  const handleDirectConnection = async (
    name: string,
    connections: Array<FieldsType>,
    addFrom?: string
  ) => {
    try {
      const assignPortsResult = await assignPorts(name, connections, addFrom);

      if (assignPortsResult.every((res) => res.ok)) {
        setAddDirectStatus(setOk());
        return true;
      } else {
        setAddDirectStatus(setError("Some ports failed to assign"));
        return false;
      }
    } catch (error) {
      setAddDirectStatus(setError("Failed to add direct connection"));
      return false;
    }
  };

  const formCreateConnections = async (
    name: string,
    connections: Array<FieldsType>,
    virtual_interface_type: typeof BRIDGED | typeof ROUTED,
    addFrom?: string
  ) => {
    const hasDirectConnect = connections.some(
      (connection) => connection.innerType === DIA_UNITED_CONNECTIONS
    );

    if (hasDirectConnect) {
      const res = handleDirectConnection(name, connections, addFrom);
      return { ok: res };
    }

    const labels: { [key: string]: string } = {};
    const tempLabels = { ...labels };

    connections?.forEach((connection) => {
      tempLabels.internet_access =
        connection?.internet_access ?? tempLabels.internet_access;
      tempLabels.remote_type = connection?.cloudType ?? tempLabels.remote_type;

      tempLabels.customer = connection?.Customer_ID ?? tempLabels.customer;
      if (
        connection?.cloudType === AZURE_LOWERCASE &&
        connection?.azureConnectionType
      ) {
        tempLabels.azureConnectionType = connection.azureConnectionType;
      }

      // add remote type for customer ports, in priority Cloud > fttb > DC
      // if fttb or cloud is already set, makes sense to skip, otherwise look for DC
      if (!connection.cloudType && tempLabels.remote_type !== "FTTB") {
        customerPorts?.map((customerPort) => {
          if (
            customerPort.name === connection.name &&
            customerPort.labels?.building_type
          ) {
            tempLabels.remote_type = customerPort.labels?.building_type;
          }
        });
      }
    });
    Object.assign(labels, tempLabels);

    const body = getDirectConnectBody({
      name,
      virtual_interface_type,
      labels,
    });

    if (connections?.[0]?.ip_addresses) {
      body.ip_addresses = [connections[0].ip_addresses];
    }

    const connectionHandlers: {
      [key: string]: (el: FieldsType) => any;
    } = {
      [AWS_LOWERCASE]: (el) =>
        addFrom && !el.site_name
          ? {
              aws_customer_port_name: el.aws_customer_port_name,
            }
          : {
              site_name: el.site_name,
              account_id: el.account_id,
              vlan_id: el.vlan_id,
              max_bandwidth: el.max_bandwidth,
            },
      [AZURE_LOWERCASE]: (el) =>
        addFrom && !el.service_key
          ? {
              azure_customer_port_name: el.azure_customer_port_name,
              max_bandwidth: el.max_bandwidth,
            }
          : {
              service_key: el.service_key,
              vlan_id: el.vlan_id,
              max_bandwidth: el.max_bandwidth,
            },
      gcp: (el) =>
        addFrom && !el.primary_pairing_key
          ? {
              customer_port_name: el.gcp_customer_port_name,
              max_bandwidth: el.max_bandwidth,
            }
          : {
              secondary_pairing_key: el.secondary_pairing_key || undefined,
              primary_pairing_key: el.primary_pairing_key,
              max_bandwidth: el.max_bandwidth,
              region: el.region,
            },
      default: (el) => ({
        customer_port_name: el.name,
        vlan_id: el.vlan_id === NATIVE ? undefined : el.vlan_id,
        max_bandwidth: el.max_bandwidth,
      }),
    };

    connections.forEach((el) => {
      const handler =
        connectionHandlers[el.cloudType] || connectionHandlers.default;
      const sanitizedConnection = handler(el);

      switch (el.cloudType) {
        case AWS_LOWERCASE:
          if (!body.aws_ports_request) {
            body.aws_ports_request = [sanitizedConnection];
          } else {
            body.aws_ports_request.push(sanitizedConnection);
          }
          break;
        case AZURE_LOWERCASE:
          if (!body.azure_ports_request) {
            body.azure_ports_request = [sanitizedConnection];
          } else {
            body.azure_ports_request.push(sanitizedConnection);
          }
          break;
        case GCP_LOWERCASE:
          if (!body.gcp_ports_request) {
            body.gcp_ports_request = [sanitizedConnection];
          } else {
            body.gcp_ports_request.push(sanitizedConnection);
          }
          break;
        default:
          if (!body.customer_ports_request) {
            body.customer_ports_request = [sanitizedConnection];
          } else {
            body.customer_ports_request.push(sanitizedConnection);
          }
      }
    });

    body.vi_name = name;
    body.internet = body.internet || false;

    const response = await tenantVirtualInterfacesApi.createViPlusL2(
      tenant,
      body as VirtualInetrfaceBridgeDomainAdd
    );
    return response;
  };

  const addDirectConnection = async (
    name: string,
    connections: Array<FieldsType>,
    addFrom?: string
  ) => {
    setAddDirectStatus(setPending("Fetching"));

    const createConnectionResult = await formCreateConnections(
      name,
      connections,
      BRIDGED,
      addFrom
    );

    if (!createConnectionResult.ok) {
      setAddDirectStatus(setError());
      return false;
    } else {
      setAddDirectStatus(setOk());
    }

    await fetchConnections();
    await getTenantPorts();
    return true;
  };

  const addCloudConnection = async (
    name: string,
    connections: Array<FieldsType>,
    addFrom?: string
  ) => {
    setAddCloudStatus(setPending("Fetching"));

    const createConnectionResult = await formCreateConnections(
      name,
      connections,
      ROUTED,
      addFrom
    );

    if (!createConnectionResult.ok) {
      setAddCloudStatus(setError());
      return false;
    }

    const errorRes = !createConnectionResult.ok;

    setAddCloudStatus(
      !createConnectionResult.ok ? resToState(createConnectionResult) : setOk()
    );

    if (errorRes) return false;

    await fetchConnections();
    await getTenantPorts();
    return true;
  };

  const createTenantPort = async (
    params: TenantCustomerPortCreate
  ): Promise<Res<any>> => {
    const name =
      tenant + "_" + params.name + "_" + (params.vlan_id || "native");
    return await configApi.createTenantPort(tenant, params.name || "", {
      ...params,
      name,
    });
  };

  const createCloudPort = async (
    params: TenantCustomerPortCreate
  ): Promise<Res<any>> => {
    return await configApi.createCloudPort(
      tenant,
      params.aws_customer_port_name || "",
      {
        ...params,
      }
    );
  };

  const updateCustomerPort = async (
    name: string,
    portName: string,
    bw: number,
    isCloud = false
  ): Promise<boolean> => {
    const stateAction = isCloud ? setAddCloudStatus : setAddDirectStatus;
    stateAction(setPending("Fetching"));
    const res = await configApi.editTenantPort(tenant, name, portName, {
      max_bandwidth: bw,
    });
    stateAction(resToState(res));
    return res.ok;
  };

  const requestPort = async (params: RequestCustomerPort): Promise<void> => {
    setRequestPortStatus(setPending("Fetching"));
    const res = await configApi.requestCustomerPort(tenant, params);
    setRequestPortStatus(resToState(res));
    if (res.ok) {
      await getCustomerPorts();
    }
  };

  const deleteCustomerPort = async (
    name: string,
    type: string | undefined
  ): Promise<void> => {
    setRequestPortStatus(setPending("Fetching"));

    let res = { ok: false };

    switch (type) {
      case AWS_LOWERCASE:
        res = await configApi.deleteAWSPort(tenant, name);
        break;
      case AZURE_LOWERCASE:
        res = await configApi.deleteAzurePort(tenant, name);
        break;
      case GCP_LOWERCASE:
        res = await configApi.deleteGCPPort(tenant, name);
        break;
      default:
        res = await configApi.deleteCustomerPort(tenant, name);
    }
    setRequestPortStatus(resToState(res));
    if (res.ok) {
      await getCustomerPorts();
      await fetchConnections();
      await getTenantPorts();
    }
  };

  const getRegions = async () => {
    setRequestRegionStatus(setPending("Fetching"));
    const res = await configApi.getRegions();
    if (res.ok && res?.result?.length) {
      setRegions(res.result);
    }
    setRequestRegionStatus(resToState(res));
  };

  return (
    <UnitedConnectionContext.Provider
      value={{
        connections,
        systems,
        connectionFetchStatus,
        locations,
        fetchLocations,
        fetchConnections,
        fetchSystems,
        getTenantPorts,
        ports,
        portsFetchStatus,
        groupedPorts,
        directConnections,
        cloudConnections,
        addDirectConnection,
        addDirectStatus,
        parentPorts,
        createTenantPort,
        selectedPort,
        selectPort,
        addCloudStatus,
        addCloudConnection,
        requestPort,
        requestPortStatus,
        customerPorts,
        getRegions,
        regions,
        requestRegionStatus,
        getCustomerPorts,
        deleteCustomerPort,
        updateCustomerPort,
      }}
    >
      {children}
    </UnitedConnectionContext.Provider>
  );
};
