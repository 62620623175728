import { DropdownItem } from "../../../../../../helpers/types";
import { CreateAWSNNI } from "../../../../types";

export const DEFAULT_FIELDS: Partial<CreateAWSNNI> = {
  nni_id: "",
  physical_port_name: "",
  system_name: "",
  site_name: "",
  max_bandwidth: "",
  vlan_pool: { from: 1, to: 4096, used: [] },
};

export const NNI_BANDWIDTH_LIST: Array<DropdownItem> = [
  { key: "1000000", value: "1 Gbps" },
  { key: "10000000", value: "10 Gbps" },
  { key: "100000000", value: "100 Gbps" },
];

export const VLAN_POOL_MIN = 1;
export const VLAN_POOL_MAX = 4096;
