import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function DownloadIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3556 13.075C10.1603 13.2703 9.84374 13.2703 9.64848 13.075L5.56634 8.9928C5.17583 8.60228 5.17583 7.96912 5.56635 7.57859L5.86642 7.27853C6.25694 6.888 6.8901 6.888 7.28063 7.27853L8.85922 8.85712V2.5C8.85922 2.22386 9.08308 2 9.35922 2H10.6449C10.9211 2 11.1449 2.22386 11.1449 2.5V8.85712L12.7235 7.27853C13.114 6.888 13.7472 6.888 14.1377 7.27853L14.4378 7.57859C14.8283 7.96911 14.8283 8.60228 14.4378 8.9928L10.3556 13.075ZM3 15C2.44772 15 2 15.4477 2 16V17C2 17.5523 2.44772 18 3 18H17C17.5523 18 18 17.5523 18 17V16C18 15.4477 17.5523 15 17 15H3Z"
        fill={color || FONT}
        stroke={color || FONT}
      />
    </svg>
  );
}

export default DownloadIcon;
