import {
  PricingType,
  TermsAndConditions,
} from "../../components/dialogs/TermsAndPricingDialog";
import { PaginateListResponse } from "../types";
import Api, { PRes } from "./Api";
import { SERVICE_CATALOG, TERMS_AND_CONDITIONS } from "./apiPaths";

export class PricingApi extends Api {
  constructor() {
    super();
  }

  getServiceCatalog(): PRes<PaginateListResponse<PricingType>> {
    return this.fetchBroker<PaginateListResponse<PricingType>>(
      SERVICE_CATALOG.GET()
    );
  }

  getTermsCatalog(): PRes<PaginateListResponse<TermsAndConditions>> {
    return this.fetchBroker<PaginateListResponse<TermsAndConditions>>(
      TERMS_AND_CONDITIONS.GET_ALL()
    );
  }

  signTerms(params?: {
    tenant: string | undefined;
    service: string | undefined;
    termsId: number | undefined;
    serviceId: number | undefined;
    additionalParams: any | undefined;
  }): PRes<any> {
    const { tenant = "", service, termsId, additionalParams, serviceId } =
      params ?? {};

    return this.fetchBroker<PaginateListResponse<TermsAndConditions>>(
      TERMS_AND_CONDITIONS.SIGN(tenant),
      {
        body: JSON.stringify({
          signature_metadata: additionalParams ?? {},
          related_service: service,
          terms_id: termsId,
          service_id: serviceId,
        }),
      }
    );
  }
}

export const pricingApi = new PricingApi();
