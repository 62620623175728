import { useState } from "react";
import { isoApi } from "../../helpers/api/IsoApi";
import { useFetch } from "../../helpers/hooks/useFetch";
import { useCheckTask } from "../../helpers/hooks/useCheckTask";

export const useNSOSSoftware = () => {
  const [taskId, setTaskId] = useState<string>("");
  const [newVersion, setNewVersion] = useState<string>("");

  const { state: versionsState, fetch: getVersions } = useFetch({
    fetchFn: isoApi.getVersions.bind(isoApi),
    isEnabled: true,
  });

  const { state: checkVersionState, fetch: checkVersion } = useFetch({
    fetchFn: isoApi.checkVersion.bind(isoApi),
  });

  const { state: downloadByUrlState, fetch: downloadByUrl } = useFetch({
    fetchFn: isoApi.downloadByUrl.bind(isoApi),
  });

  const { fetch: checkDownloadStatus } = useFetch({
    fetchFn: isoApi.checkDownloadStatus.bind(isoApi),
  });

  const { state: deleteVersionState, fetch: deleteVersion } = useFetch({
    fetchFn: isoApi.deleteVersion.bind(isoApi),
  });

  const handleGetTaskStatus = async () => {
    try {
      const response = await checkDownloadStatus(taskId);
      return response.result?.status;
    } catch (error) {
      return "error";
    }
  };

  const { taskStatus, isInProgress: downloadInProgress } = useCheckTask({
    getTaskStatus: handleGetTaskStatus,
    taskId,
    onSuccess: () => {
      getVersions();
      setNewVersion("");
    },
  });

  const handleDownloadByUrl = async (url: string) => {
    const response = await downloadByUrl(url);
    if (response.result?.task_id) {
      setTaskId(response.result.task_id);
    }
  };

  const handleCheckVersion = async () => {
    const latestVersionResponse = await checkVersion();

    if (versionsState.result && latestVersionResponse.result?.latest?.version) {
      const newestVersion = versionsState.result.includes(
        latestVersionResponse.result.latest.version
      )
        ? ""
        : latestVersionResponse.result.latest.version;

      setNewVersion(newestVersion);
    }
  };

  return {
    versionsState,
    taskStatus,
    newVersion,
    checkVersionState,
    downloadInProgress,
    downloadByUrlState,
    deleteVersionState,
    getVersions,
    deleteVersion,
    downloadByUrl: handleDownloadByUrl,
    checkDownloadStatus,
    checkVersion: handleCheckVersion,
  };
};
