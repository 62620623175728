import React, { FC, useState } from "react";
import styles from "./ApplicationObservability.module.scss";
import ButtonsGroup, {
  Tab,
} from "../../../../../../components/common/buttons/ButtonsGroup";
import { classNames } from "../../../../../../helpers/common/classNames";
import ChartsHeader from "../../../../../../components/common/charts/ChartsHeader/ChartsHeader";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import { useGlobalFilterContext } from "../../../../../../contexts/GlobalFilterContext";
import GrafanaGraph from "../../../../../../components/common/charts/GrafanaGraph";
import { getApplicationLink } from "../../../../../ApplicationObservabilityPage/components/ApplicationObservabilityStat/components/Statistics/components/StatisticsBody";

const TABS = [
  { name: "Locations", icon: undefined },
  // { name: "Connections", icon: undefined },
  { name: "Source IPs", icon: undefined },
  { name: "Connections", icon: undefined },
];

const ApplicationObservability: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(TABS[1].name);
  const handleTabSelect = (tab: Tab) => {
    setActiveTab(tab.name);
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.chartWrapper,
          styles.large,
          styles.appObservabilityCharts
        )}
      >
        <ChartsHeader
          title={"Application Observability"}
          controls={
            <ButtonsGroup
              tabs={TABS}
              activeTab={activeTab}
              onChange={handleTabSelect}
              withIcon
            />
          }
        />
        <StatisticsChart type={activeTab} />
      </div>
    </div>
  );
};

export default ApplicationObservability;

const StatisticsChart: FC<{ type: string }> = ({ type }) => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  switch (type) {
    case "Connections":
      return (
        <GrafanaGraph
          link={getApplicationLink(14, timeRange.general, selectedTenant)}
        />
      );
    // case "Connections":
    //   return (
    //     <GrafanaGraph
    //       link={getApplicationLink(13, timeRange.general, selectedTenant)}
    //     />
    //   );
    case "Source IPs":
      return (
        <GrafanaGraph
          link={getApplicationLink(17, timeRange.general, selectedTenant)}
        />
      );
    case "Locations":
    default:
      return (
        <GrafanaGraph
          link={getApplicationLink(15, timeRange.general, selectedTenant)}
        />
      );
  }
};
