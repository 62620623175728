import { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function DataCenterNewIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70313 5.4453C6.8886 5.1671 7.20083 5 7.53518 5H16.4648C16.7992 5 17.1114 5.1671 17.2969 5.4453L19 8H5L6.70313 5.4453ZM5 9H19V14V15V19C19 19.5523 18.5523 20 18 20H6C5.44772 20 5 19.5523 5 19V15V14V9ZM7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12ZM12.5 11.5C12.2239 11.5 12 11.7239 12 12C12 12.2761 12.2239 12.5 12.5 12.5H16.5C16.7761 12.5 17 12.2761 17 12C17 11.7239 16.7761 11.5 16.5 11.5H12.5ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18C8.55228 18 9 17.5523 9 17C9 16.4477 8.55228 16 8 16ZM12 17C12 16.7239 12.2239 16.5 12.5 16.5H16.5C16.7761 16.5 17 16.7239 17 17C17 17.2761 16.7761 17.5 16.5 17.5H12.5C12.2239 17.5 12 17.2761 12 17Z"
        fill={color || "#59536B"}
      />
    </svg>
  );
}

export default DataCenterNewIcon;
