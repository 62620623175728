import React, {
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import styles from "./index.module.scss";
import DDoSTable from "./DDoSTable";
import { OneSystemLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { PageProps } from "../../helpers/types";
import { DDoSStatistics } from "./DDoSStatistics";
import { classNames } from "../../helpers/common/classNames";
import { withContexts } from "../../helpers/hocs/withContexts";
import TenantVirtualInterfaceContextContainer from "../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import FirewallContextContainer from "../../contexts/servicesContext/FirewallContext";
import DDoSContextContainer from "../../contexts/servicesContext/DDoSContext";
import { OneSystemTabs } from "../../components/common/navigation/OneSystemTabs";
import BlankButton from "../../components/common/buttons/BlankButton";
import CaretDoubleIcon from "../../components/icons/CaretDoubleIcon";
import { ORANGE_GRAIENT_FIRST } from "../../helpers/common/colorAliases";

const LEFT_PANEL_DEFAULT_WIDTH = 70;
const STORAGE_LEFT_PANEL_WIDTH_KEY = "ddos-system-page-left-panel-width";

const getStorageLeftPanelWidth = () =>
  Number(localStorage.getItem(STORAGE_LEFT_PANEL_WIDTH_KEY))
    ? Number(localStorage.getItem(STORAGE_LEFT_PANEL_WIDTH_KEY))
    : LEFT_PANEL_DEFAULT_WIDTH;

const setStorageLeftPanelWidth = (value: number) =>
  localStorage.setItem(STORAGE_LEFT_PANEL_WIDTH_KEY, value.toString());

const DDoSSystemPage: FC<{}> = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [isChartsHidden, setIsChartsHidden] = useState(false);
  const [leftPanelWidth, setLeftPanelWidth] = useState(
    getStorageLeftPanelWidth
  );
  const isDragging = useRef(false);

  useEffect(() => {
    setStorageLeftPanelWidth(leftPanelWidth);
  }, [leftPanelWidth]);

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    isDragging.current = true;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    e.preventDefault();
    if (isDragging.current) {
      const container = document.querySelector(`.${styles.contentWrapper}`);
      if (container) {
        const containerRect = container.getBoundingClientRect();
        const newWidth =
          ((e.clientX - containerRect.left) / containerRect.width) * 100;
        setLeftPanelWidth(Math.min(Math.max(newWidth, 20), 80));
      }
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    e.preventDefault();
    isDragging.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  useEffect(() => {
    if (showAdd || showEdit) {
      setIsChartsHidden(true);
    }
  }, [showAdd, showEdit]);

  return (
    <OneSystemLayout>
      <div className={styles.wrapper}>
        <OneSystemTabs showTimeRange />
        <div
          className={classNames(
            styles.contentWrapper,
            isChartsHidden && styles.collapsed
          )}
        >
          <div
            className={classNames(styles.leftPart)}
            style={{ width: `${leftPanelWidth}%` }}
          >
            <DDoSTable
              className={styles.leftPartContent}
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              showAdd={showAdd}
              setShowAdd={setShowAdd}
            />

            {!showAdd && !showEdit && (
              <BlankButton
                id={"system_provisioning"}
                onClick={() => setIsChartsHidden((prev) => !prev)}
                className={classNames(
                  styles.collapsedButton,
                  isChartsHidden && styles.expandedButton
                )}
              >
                <CaretDoubleIcon
                  className={styles.expandedIcon}
                  color={ORANGE_GRAIENT_FIRST}
                />
              </BlankButton>
            )}
          </div>

          {!isChartsHidden && (
            <div className={styles.resizer} onMouseDown={handleMouseDown} />
          )}

          <div
            className={classNames(styles.rightPart)}
            style={{ width: `${100 - leftPanelWidth}%` }}
          >
            <DDoSStatistics />
          </div>
        </div>
      </div>
    </OneSystemLayout>
  );
};
export default withLayout<PageProps>(
  withContexts(DDoSSystemPage, [
    DDoSContextContainer,
    FirewallContextContainer,
    TenantVirtualInterfaceContextContainer,
  ])
);
