import CloudProviderIcon from "../../../../components/icons/CloudProviderIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { PROVIDERS } from "./constants";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./ProvidersTabs.module.scss";

type ProvidersTabsProps = {
  activeTab: number;
  onChangeTab: (value: number) => void;
};

export const ProvidersTabs = ({
  activeTab,
  onChangeTab,
}: ProvidersTabsProps) => {
  const selectedProvider = PROVIDERS[activeTab];
  return (
    <div className={styles.cloudPicker}>
      {PROVIDERS.map((provider, index) => (
        <div
          key="provider"
          className={classNames(
            styles.cloudPickerItem,
            selectedProvider.name === provider.name &&
              styles.cloudPickerItemSelected,
            !provider.enabled && styles.cloudPickerItemDisabled
          )}
          role="button"
          onClick={() => onChangeTab(index)}
        >
          <CloudProviderIcon id={provider.name} />
        </div>
      ))}
    </div>
  );
};
