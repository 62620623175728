import { FC, useEffect, useMemo, useState } from "react";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { withLayout } from "../../../helpers/hocs/withLayout";
import {
  UnitedConnectionContextContainer,
  useUnitedConnectionContext,
} from "./UnitedConnectionContext";
import styles from "./ConnectionsContent.module.scss";
import InventoryBlock from "./components/InventoryBlock";
import ConnectionsBlock from "./components/ConnectionsBlock";

const ConnectionsContent: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchConnections, fetchSystems } = useUnitedConnectionContext();
  const [add, setAdd] = useState<string | undefined>();

  const addConnectionCallback = (addFrom?: string) => {
    setAdd(addFrom);
  };

  useEffect(() => {
    fetchConnections();
    fetchSystems();
  }, [selectedTenant]);

  const connectionBlock = useMemo(
    () => (
      <ConnectionsBlock
        addFrom={add}
        addConnectionCallback={addConnectionCallback}
      />
    ),
    [add]
  );

  return (
    <>
      <div className={styles.pageWrapper}>
        <InventoryBlock addConnectionCallback={addConnectionCallback} />
        {connectionBlock}
      </div>
    </>
  );
};

export default withContexts<any>(withLayout<any>(ConnectionsContent), [
  UnitedConnectionContextContainer,
]);
