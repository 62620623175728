import React, { FC, useEffect, useState } from "react";
import FirewallRuleInfo from "./FirewallRuleInfo";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./FirewallRuleConfig.module.scss";
import GeneralSection from "./GeneralSection";
import { DialogType } from "../../../helpers/types";
import {
  DDoSType,
  FWDiaRuleType,
  FWFilters,
  FWPolicy,
  FWRuleType,
  PBMType,
  PBRType,
} from "../../../helpers/api/apiTypes";
import { ADD, EDIT, FW_POLICIES } from "../../../helpers/common/constantsAlias";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import validateFirewallNewRule from "../../../helpers/validators/FirewallNewRuleValidator";
import sendRequest from "../../../helpers/sendRequest";
import { CollapsableTwoColumnLayout } from "../../../components/leftInfoBlock/LayoutTwoColumnFactory";
import DialogBtmButtons from "../../../components/dialogs/common/DialogBtmButtons";
import { isEditMode } from "../../../helpers/isEditMode";
import {
  getPotocolName,
  getProtocolByValue,
} from "../../../helpers/getProtocolByValue";
import FQDNSection from "./FQDNSection";
import RuleSection from "./RuleSection";
import ActionSection from "./ActionSection";
import { CreateDiaFWRule } from "../../FirewallDia/config/DiaFirewallRuleConfig";

export const MAX_FW_PRIORITY_FOR_MANUAL_CREATION = 10_000;
export const LIMIT_FW_PRIORITY_FOR_BLOCK_SECTION_START = 65_000;
export const LIMIT_FW_PRIORITY_FOR_BLOCK_SECTION_END = 65_535;

type Props = {
  onClose: () => void;
  type: DialogType;
  data?: FWRuleType;
};

export type CreateFWRule = {
  name: string;
  enable: boolean;
  priority: number;
  description: string;
  forwarding_policy: string;
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  dst_group: string;
  src_group: string;
  categories: Array<string>;
  allowed_domains: Array<string>;
  denied_domains: Array<string>;
};

const DEFAULT_FIELDS: CreateFWRule = {
  name: "",
  enable: false,
  priority: 0,
  description: "",
  forwarding_policy: FW_POLICIES["Allow"],
  ip_protocol: "",
  src_network: "",
  src_segment: "",
  dst_segment: "",
  src_l4_port: "",
  dst_network: "",
  dst_l4_port: "",
  src_group: "",
  dst_group: "",
  categories: [],
  allowed_domains: [],
  denied_domains: [],
};

const FirewallRuleConfig: FC<Props> = ({ onClose, type, data }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    add,
    addStatus,
    edit,
    editStatus,
    fetchList,
    list,
    userGroups,
  } = useFirewallContext();

  const [fields, handleFieldChange] = useFormField<CreateFWRule>(
    getFlatData(data) || DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<CreateFWRule>(
    validateFirewallNewRule,
    [fields]
  );

  const [fgdnShow, setFQDNShow] = useState<boolean>(false);
  const [isSNI, setIsSNI] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTenant) {
      fetchList(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (list) {
      const priority = data ? data.priority : generatePriority(list);
      handleFieldChange("priority", priority);
    }
  }, [list]);

  const handleFQDN = () => {
    setFQDNShow(!fgdnShow);
  };

  const handleAdd = async () => {
    const { isOk } = validate();
    if (!isOk) return;

    const newFields = getFWPreparedData(fields);
    await sendRequest(isOk, add(newFields, selectedTenant), onClose);
  };

  const handleEdit = async () => {
    if (!data) return;
    const { isOk } = validate();
    if (!isOk) return;

    const newFields = getFWPreparedData(fields);
    await sendRequest(
      isOk,
      edit(newFields, data.name, selectedTenant),
      onClose
    );
  };

  return (
    <CollapsableTwoColumnLayout
      InfoBlock={() => <FirewallRuleInfo param={selectedTenant as string} />}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <GeneralSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            maxPriority={MAX_FW_PRIORITY_FOR_MANUAL_CREATION}
            allowedToChangeName={type === ADD}
          />
          <RuleSection
            className={styles.contentWrapperNoBorderBottom}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            groups={userGroups}
            isSNI={isSNI}
            isFQDN={fgdnShow}
          />
          <ActionSection
            className={
              fgdnShow
                ? styles.contentWrapper
                : styles.contentWrapperNoBorderBottom
            }
            fields={fields}
            onChange={handleFieldChange}
            handleFQDN={handleFQDN}
          />
          <FQDNSection
            className={
              fgdnShow ? styles.contentWrapper : styles.contentWrapperHidden
            }
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            sniInfo={setIsSNI}
          />
        </div>
        <div className={styles.footer}>
          <DialogBtmButtons
            controls={{
              okText: isEditMode(type) ? EDIT : ADD,
              onOk: isEditMode(type) ? handleEdit : handleAdd,
              cancelText: "Cancel",
              onCancel: onClose,
            }}
            errorDisplay={addStatus || editStatus}
          />
        </div>
      </div>
    </CollapsableTwoColumnLayout>
  );
};

export default FirewallRuleConfig;

export const generatePriority = (
  ruleList: Array<FWRuleType | PBMType | PBRType | FWDiaRuleType | DDoSType>
): number => {
  if (!ruleList || ruleList?.length === 0) return 1;
  const priorityList: Array<number> = [];
  ruleList.forEach((rule) => {
    if (rule.priority < 1000) priorityList.push(+rule.priority);
  });
  const maxPriority = Math.max(...priorityList);
  return +maxPriority + 10;
};

export const getFWPreparedData = (
  rule: CreateFWRule | CreateDiaFWRule
): Partial<FWRuleType | FWDiaRuleType> => {
  const {
    name,
    priority,
    forwarding_policy,
    description,
    ip_protocol,
    categories,
    allowed_domains,
    denied_domains,
    src_l4_port,
    dst_l4_port,
  } = rule;

  const filters_ip_protocol = getProtocolByValue(
    ip_protocol,
    !!src_l4_port || !!dst_l4_port
  );

  const otherFilters: any = [];
  [
    "src_l4_port",
    "src_network",
    "src_segment",
    "dst_segment",
    "dst_l4_port",
    "dst_network",
    "dst_group",
    "src_group",
  ].map((val) => {
    if (rule[val as keyof CreateFWRule]) {
      const values = rule[val as keyof CreateFWRule];
      otherFilters.push({
        filter_type: val,
        values,
      });
    }
  });
  return {
    name,
    priority,
    filters: [...filters_ip_protocol, ...otherFilters],
    forwarding_policy: forwarding_policy as FWPolicy,
    description,
    categories,
    allowed_domains,
    denied_domains,
  };
};

export type FW_Filters = {
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  src_group: string;
  dst_group: string;
};

export function prepareFilters(
  data: FWRuleType | PBRType
): { [key: string]: any } {
  const filters: { [key: string]: any } = {};
  data.filters.map((el: FWFilters) => {
    if (el.filter_type !== "ip_protocol") {
      filters[el.filter_type] = Number.isNaN(+el.values)
        ? el.values
        : +el.values;
    }
  });
  return filters;
}

const getFlatData = (
  data: FWRuleType | undefined
): CreateFWRule | undefined => {
  if (!data) return;
  const filters = prepareFilters(data);
  const protocols = data.filters.filter(
    (el) => el.filter_type === "ip_protocol"
  );
  return {
    name: data?.name,
    enable: data?.enable,
    priority: data?.priority,
    description: data?.description,
    forwarding_policy: data?.forwarding_policy,
    ...(filters as FW_Filters),
    ip_protocol: getPotocolName(protocols),
    categories: data?.categories,
    allowed_domains: data?.allowed_domains,
    denied_domains: data?.denied_domains,
  };
};
