import React, { ReactNode } from "react";
import EditButton from "../../buttons/EditButton";
import IconBlankButton from "../../buttons/IconBlankButton";
import DuplicateIcon from "../../../icons/DuplicateIcon";
import SettingsIcon from "../../../icons/SettingsIcon";
import DeleteButton from "../../buttons/DeleteButton";
import PieChartIcon from "../../../icons/PieChartIcon";
import SearchIconSmall from "../../../icons/SearchIconSmall";
import styles from "./RowControls.module.scss";
import { Row } from "react-table";
import { ValidateFunc } from "../../../../helpers/validators/Validator";
import { classNames } from "../../../../helpers/common/classNames";
import VirtInterfaceIcon from "../../../icons/VirtInterfaceIcon";
import Button from "../../buttons/Button";
import AddIcon from "../../../icons/AddIcon";
import { PURPLE } from "../../../../helpers/common/colorAliases";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../../../helpers/consts";

export type HideForType = { [key: string]: "subRows" | "parentRow" };
export type ActionFuncs<D> = {
  onEdit?: (rowOriginal: D) => void;
  onDiveIn?: (rowOriginal: D) => void;
  onDelete?: (rowOriginal: D) => void;
  onDuplicate?: (rowOriginal: D) => void;
  onSetting?: (rowOriginal: D) => void;
  onPieChart?: (rowOriginal: D) => void;
  onHover?: (rowOriginal: D) => void;
  hoverTooltip?: (rowOriginal: D) => ReactNode;
  onClick?: (rowOriginal: D) => void;
  onUpdate?: (rowOriginal: D) => Promise<boolean>;
  onCreatePlus?: (rowOriginal: D) => void;
  onEditInterfaces?: (rowOriginal: D) => void;
  updateValidator?: ValidateFunc<D>;
  hideFor?: HideForType;
};
type Props<D extends { [key: string]: any }> = {
  row: Row<D>;
  actions: ActionFuncs<D>;
  className?: string;
};
export default function RowControls<D extends { [key: string]: any }>(
  props: Props<D>
): React.ReactElement {
  const { row, actions, className } = props;
  const {
    onDuplicate,
    onSetting,
    onPieChart,
    onEdit,
    onDiveIn,
    onDelete,
    onEditInterfaces,
    hoverTooltip,
    onCreatePlus,
    hideFor,
  } = actions;
  function isEditable(data: any) {
    if (
      data?.labels?.remote_type === AWS_LOWERCASE ||
      data?.labels?.remote_type === AZURE_LOWERCASE ||
      data?.labels?.remote_type === GCP_LOWERCASE
    ) {
      return false;
    }
    return true;
  }

  return (
    <div className={classNames(styles.container, className)}>
      {onPieChart && (
        <IconBlankButton
          id={row.id}
          onClick={(e) => {
            e.stopPropagation();
            onPieChart(row.original);
          }}
          icon={PieChartIcon}
        />
      )}
      {onEdit && isEditable(row.original) && (
        <EditButton
          id={`edit${row.id}`}
          title={"Edit"}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(row.original);
          }}
        />
      )}
      {onDuplicate && (
        <IconBlankButton
          id={`duplicate${row.id}`}
          title={"Duplicate"}
          onClick={(e) => {
            e.stopPropagation();
            onDuplicate(row.original);
          }}
          icon={DuplicateIcon}
        />
      )}
      {onDiveIn && (
        <IconBlankButton
          id={`rowControls-DiveIn-${row.id}`}
          title={"Dive in"}
          onClick={(e) => {
            e.stopPropagation();
            onDiveIn(row.original);
          }}
          icon={SearchIconSmall}
        />
      )}
      {onSetting && (
        <IconBlankButton
          id={`settings${row.id}`}
          title={"Settings"}
          onClick={(e) => {
            e.stopPropagation();
            onSetting(row.original);
          }}
          icon={SettingsIcon}
        />
      )}
      {onEditInterfaces && (
        <IconBlankButton
          id={`settings${row.id}`}
          title={"Settings"}
          onClick={(e) => {
            e.stopPropagation();
            onEditInterfaces(row.original);
          }}
          icon={VirtInterfaceIcon}
        />
      )}

      {onCreatePlus && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onCreatePlus(row.original);
          }}
        >
          {
            <div className={styles.plusCreateBtn}>
              <AddIcon color={PURPLE} />
              Create
            </div>
          }
        </Button>
      )}
      {onDelete && isShowControl(row.depth, hideFor?.onDelete) && (
        <DeleteButton
          id={`delete${row.id}`}
          title={"Delete"}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(row.original);
          }}
        />
      )}
      {hoverTooltip && hoverTooltip(row.original)}
    </div>
  );
}

const isShowControl = (rowDepth: number, value?: "subRows" | "parentRow") => {
  if (!value) return true;
  if (value === "parentRow") return rowDepth !== 0;
  if (value === "subRows") return rowDepth === 0;
};
