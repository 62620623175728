import { CreateDDoS } from "../../pages/DDoSSystemPage/DDoSConfig/DDoSConfig";
import { MAX_FW_PRIORITY_FOR_MANUAL_CREATION } from "../../pages/Firewall/config/FirewallRuleConfig";
import Validator from "./Validator";

export default function validateDDoS(
  data: CreateDDoS,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.name, "name");
  validator.checkEmpty(data.flood_cooldown_rate, "flood_cooldown_rate");
  validator.checkEmpty(data.flood_detect_rate, "flood_detect_rate");
  validator.checkEmpty(data.hysteresis_seconds, "hysteresis_seconds");
  validator.checkEmpty(data.flood_rate, "flood_rate");
  validator.checkMinMax(
    data.priority,
    [1, MAX_FW_PRIORITY_FOR_MANUAL_CREATION],
    "priority"
  );

  data.name && validator.checkLength(data.name, [1, 128], "name");

  data.name && validator.checkNameFormat(data.name, "name");

  data.src_network &&
    validator.checkIPWithPrefixAll(data.src_network, "src_network");
  data.dst_network &&
    validator.checkIPWithPrefixAll(data.dst_network, "dst_network");

  validator.checkTrue(
    +data.flood_cooldown_rate <= +data.flood_rate,
    "flood_cooldown_rate",
    "Cooldown rate should be lower or equal than Flood rate"
  );

  return validator;
}
