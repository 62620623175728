import { FC } from "react";
import Input from "../../../../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../../../../components/common/formComponents/RadioGroup";
import CloudSelector from "../../../common/CloudSelector";
import {
  ChangeConnectionsFields,
  ChangeField,
  FieldsType,
} from "../../../types";
import VPCList from "../../../common/formComponents/VPCList/VPCList";
import EndpointDropdown from "../../../common/formComponents/EndpointDropdown";
import IPAddress from "../../../common/formComponents/IPAddress";
import { renderCustomerIdLabel } from "../../../helpers/renderCustomerIdLabel";
import { getConnectionType } from "../../../helpers/getConnectionType";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import {
  AWS_LOWERCASE,
  CONNECTION_AWS_BANDWIDTH_VALUES,
} from "../../../../../../../helpers/consts";

type Props = {
  fields: FieldsType;
  onChange: ChangeConnectionsFields;
  className: string;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
  disabled?: boolean;
};

const CloudConnectionForm: FC<Props> = ({
  fields,
  onChange,
  className,
  type,
  isEdit,
  errors,
  handleChangeBW,
  disabled,
}) => {
  const { isDemo } = useUserContext();

  const handleChange: ChangeField = (field: string, value: any): void => {
    const newFields = { ...fields, [field]: value };
    onChange(0, newFields);
  };
  const idx = 0;
  return (
    <div className={className}>
      <CloudSelector
        selected={fields.cloudType}
        onSelect={(val) => handleChange("cloudType", val)}
        disabled={isEdit}
      />
      <RadioGroup
        label="Connection Type"
        isOneRow
        options={getConnectionType(isDemo)}
        value={fields.Connection_type}
        setActiveValue={(val) => handleChange("Connection_type", val)}
        isNotEditable={isEdit}
      />
      <DropdownBasic
        id={"cloud_bw"}
        label="BW"
        selected={mapStringToItem(fields.max_bandwidth)}
        isMedium
        itemsList={CONNECTION_AWS_BANDWIDTH_VALUES.map(mapStringToItem)}
        onChange={(val) => handleChangeBW(0, String(val))}
        disabled={disabled}
      />
      <EndpointDropdown
        label={"Region"}
        field={fields.location}
        onChange={handleChange}
        type={type}
        cloudType={fields.cloudType}
        isNotEditable={isEdit}
        error={errors?.[idx + "location"]}
      />
      {fields.Connection_type === "ipsec" &&
        fields.cloudType === AWS_LOWERCASE && (
          <VPCList
            onChange={handleChange}
            externalFields={fields}
            error={errors?.[idx + "VPC_ID"]}
          />
        )}
      {fields.Connection_type === "directConnect" && (
        <>
          <Input
            label={renderCustomerIdLabel(fields.cloudType)}
            name="Customer_ID"
            placeholder=""
            value={fields.Customer_ID}
            medium
            onChange={(e) => handleChange("Customer_ID", e.target.value)}
            isNotEditable={isEdit}
            error={errors?.[idx + "Customer_ID"]}
          />
        </>
      )}
      <IPAddress
        field={fields.ip_addresses}
        onChange={handleChange}
        error={errors?.[idx + "ip_addresses"]}
      />
    </div>
  );
};

export default CloudConnectionForm;
