import { CellProps, Column } from "react-table";
import Label from "../../../components/common/table/Label";
import LabelOutline from "../../../components/common/table/LabelOutline";
import { CellFactory } from "../../../components/common/table/newTable/cells/CellsFactory";
import LocationFillIcon from "../../../components/icons/LocationFillIcon";
import ThreeDotsIconNew from "../../../components/icons/ThreeDotsIconNew";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { HistoryLogActivity, HistoryLogCell } from "../domain";

import styles from "./TableColumns.module.scss";

const cellFactory = new CellFactory<HistoryLogCell>({});

type Props = {
  onRowExpand: (cell: HistoryLogCell) => void;
  showLogsButton: boolean;
};

const TableColumns = ({
  onRowExpand,
  showLogsButton,
}: Props): Array<Column<HistoryLogCell>> => [
  {
    accessor: "timestamp",
    Header: "Timestamp",
    Cell: ({ row }: CellProps<HistoryLogCell>) => {
      const { timestamp } = row.original;
      return <span className={styles.timestamp}>{timestamp}</span>;
    },
  },
  {
    accessor: "action",
    Header: "Activity",
    Cell: ({ row }: CellProps<HistoryLogCell>) => {
      const { action } = row.original;

      if (!action) {
        return <></>;
      }

      switch (action) {
        case HistoryLogActivity.CREATE:
          return <Label className={styles.actionCreate}>{action}</Label>;
        case HistoryLogActivity.BGP_CONFIG:
          return <Label className={styles.actionBgp}>{action}</Label>;
        case HistoryLogActivity.DELETE:
          return <Label className={styles.actionDelete}>{action}</Label>;
        default:
          return <Label className={styles.actionUpdate}>{action}</Label>;
      }
    },
  },
  {
    accessor: "name",
    Header: "Name",
    Cell: cellFactory.textCell(),
  },
  {
    accessor: "endpointA",
    Header: "A side",
    Cell: ({ row }: CellProps<HistoryLogCell>) => {
      const { endpointA } = row.original;

      return (
        <LabelOutline
          type="entity"
          icon={<LocationFillIcon color={GREY_FONT} />}
        >
          {endpointA}
        </LabelOutline>
      );
    },
  },
  {
    accessor: "endpointZ",
    Header: "Z side",
    Cell: ({ row }: CellProps<HistoryLogCell>) => {
      const { endpointZ } = row.original;

      return <LabelOutline type="entity">{endpointZ}</LabelOutline>;
    },
  },
  {
    accessor: "connectionBW",
    Header: "Connection BW",
    Cell: cellFactory.textCell(),
  },
  {
    accessor: "remoteAsn",
    Header: "Remote ASN",
    Cell: cellFactory.textCell(),
  },
  {
    accessor: "routerIp",
    Header: "Router IP",
    Cell: cellFactory.textCell(),
  },
  {
    accessor: "logs",
    Cell: ({ row }: CellProps<HistoryLogCell>) => {
      const cell = row.original;

      if (!showLogsButton) {
        return <></>;
      }

      return <ThreeDotsIconNew onClick={() => onRowExpand(cell)} />;
    },
  },
];

export default TableColumns;
