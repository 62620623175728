import React, { useMemo } from "react";
import NavigationTabs from "./NavigationTabs";
import {
  LAG,
  VLAN,
  VRF,
  VNI,
  BGP,
  VTEP,
  VROUTER,
  EVENTS,
  PORTS,
  VIRT_INTERFACES,
  PORT_INTERFACES,
  STATIC_ROUTES,
  TRAFFIC_STATISTIC,
  CONFIGURATION,
  REPORTS,
  HAHEALTH,
  PBR,
  VRF_STATISTIC,
  PBM,
  DDoSSystem,
} from "../../../helpers/navigation/entries";
import styles from "./OneSystemTabs.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import SettingsIcon from "../../icons/SettingsIcon";
import PieChartIcon from "../../icons/PieChartIcon";
import BlankButton from "../buttons/BlankButton";
import { FONT, GREY_DISABLED } from "../../../helpers/common/colorAliases";
import { addPrefix } from "../../../helpers/navigation";
import ActiveVrfSelector from "./ActiveVrfSelector";
import TimerangOverlayComponent from "../timerange/TimerangeOverlayComponent";
import { TabsProps } from "../../../helpers/types";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
export const OneSystemTabs: React.FC<TabsProps> = ({
  disabledVrf,
  hideVrf,
  showTimeRange,
}) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const isReportingActive = useMemo(() => path.includes(REPORTS().path), [
    path,
  ]);

  const vrfHidden = hideVrf || disabledVrf;

  return (
    <div className={styles.systemTabsWrapper}>
      <div className={styles.selectSystemView}>
        <BlankButton
          id={"oneSystemTabReporting"}
          onClick={() => {
            isReportingActive &&
              history.push(
                addPrefix(CONFIGURATION(), path.split("/" + REPORTS().path)[0])
              );
          }}
        >
          <SettingsIcon color={!isReportingActive ? FONT : GREY_DISABLED} />
        </BlankButton>
        <BlankButton
          id={"oneSystemTabConfig"}
          onClick={() => {
            !isReportingActive &&
              history.push(
                addPrefix(REPORTS(), path.split("/" + CONFIGURATION().path)[0])
              );
          }}
        >
          <PieChartIcon color={isReportingActive ? FONT : GREY_DISABLED} />
        </BlankButton>
      </div>
      {isReportingActive ? <SystemReportingTabs /> : <SystemNetworkTabs />}
      <div className={styles.controls}>
        {!vrfHidden && <ActiveVrfSelector disabled={disabledVrf} />}
        {showTimeRange && (
          <TimerangOverlayComponent
            id={"oneSystemTab"}
            className={styles.timerangeWrapper}
          />
        )}
      </div>
    </div>
  );
};
export const SystemNetworkTabs: React.FC = () => {
  const { sysId } = useParams<{ sysId: string }>();
  const entries = [
    PORTS(sysId),
    VRF(sysId),
    VTEP(sysId),
    VIRT_INTERFACES(sysId),
    PORT_INTERFACES(sysId),
    VLAN(sysId),
    VNI(sysId),
    STATIC_ROUTES(sysId),
    BGP(sysId),
    PBR(sysId),
  ];
  const { node } = useOneSystemContext();
  const routes = [...entries];
  if (node?.type === "nsos-6000") {
    routes.push(PBM(sysId));
    routes.push(DDoSSystem(sysId));
  }
  return (
    <NavigationTabs
      entries={routes}
      disabledTabKeys={[LAG(sysId).path]}
      semiDisabled
    />
  );
};

export const SystemReportingTabs: React.FC = () => {
  const { sysId } = useParams<{ sysId: string }>();
  return (
    <NavigationTabs
      entries={[
        TRAFFIC_STATISTIC(sysId),
        VRF_STATISTIC(sysId),
        HAHEALTH(sysId),
        EVENTS(sysId),
        VROUTER(),
      ]}
      disabledTabKeys={[]}
    />
  );
};
