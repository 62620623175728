import React, { CSSProperties, useEffect, useState } from "react";

import "./BlankButton.scss";
import { classNames } from "../../../helpers/common/classNames";
import ReactTooltip from "react-tooltip";
import { useUserContext } from "../../../contexts/UserContext";

type BlankButtonProps = {
  className?: string;
  style?: CSSProperties;
  title?: string;
  id: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  link?: string;
  hint?: string;
  onMouseOver?: React.MouseEventHandler<HTMLButtonElement | HTMLElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement | HTMLElement>;
};
const BlankButton: React.FC<BlankButtonProps> = (props) => {
  const {
    link,
    onClick,
    title,
    id,
    disabled,
    className,
    children,
    hint,
    ...restProps
  } = props;
  const [stateHint, setStateHint] = useState<string | undefined>(hint);
  const { tips } = useUserContext() ?? {};

  useEffect(() => {
    if (id && tips) {
      const newHint = getRelatedTip();
      if (newHint) {
        setStateHint(newHint);
      }
    }
  }, [tips]);

  const getRelatedTip = () => {
    for (let i = 0; i < tips.length; i++) {
      if (`#${id}` === tips[i].element) {
        return tips[i].intro;
      }
    }
  };

  const renderToolTip = () => {
    if (!stateHint) return null;
    return (
      <ReactTooltip
        id={id}
        aria-haspopup="true"
        place="bottom"
        type="light"
        className="tooltip"
      >
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: stateHint }}
        />
      </ReactTooltip>
    );
  };
  if (link) {
    return (
      <a
        data-tip
        data-for={id}
        href={disabled ? undefined : link}
        title={title}
        id={id}
        style={props.style}
        className={classNames("button", className, disabled && "disabled")}
        {...restProps}
      >
        {children}
        {renderToolTip()}
      </a>
    );
  }
  return (
    <button
      data-tip
      data-for={id}
      onClick={onClick}
      type="button"
      id={id}
      title={title}
      disabled={disabled}
      style={props.style}
      className={classNames("button", className)}
      {...restProps}
    >
      {children}
      {renderToolTip()}
    </button>
  );
};

export default BlankButton;
