import React, { FC, useMemo, useState } from "react";
import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import styles from "./CloudDiagram.module.scss";
import BlankButton from "../../../../../components/common/buttons/BlankButton";
import DiagramComponent from "./DiagramComponent";
import { classNames } from "../../../../../helpers/common/classNames";
import DirectDiagramComponent from "./DirectDiagramComponent";
import { VlanData } from "./CloudsTabInfo";

type DiagramProps = {
  connections: Array<VirtualInterfaceApi>;
  onClick: (name: string) => void;
  activeConnection: string;
  isCloud?: boolean;
  ports?: Array<VlanData>;
  onlyDiagram?: boolean;
};

export const CloudDiagram: FC<DiagramProps> = ({
  connections,
  onClick,
  activeConnection,
  isCloud = true,
  ports = [],
  onlyDiagram,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  const diagram = useMemo(
    () =>
      (isCloud ? DiagramComponent : DirectDiagramComponent)(
        connections,
        onClick,
        activeConnection,
        ports
      ),
    [connections.length, activeConnection]
  );
  return (
    <div
      className={classNames(
        styles.DiagramBlock,
        isHidden && styles.closed,
        onlyDiagram && styles.autoHeight
      )}
    >
      {!onlyDiagram ? (
        <div className={styles.DiagramControl}>
          <BlankButton
            id={"hideDiagram"}
            onClick={() => setIsHidden(!isHidden)}
            className={styles.ControlButton}
          >
            {isHidden ? "Open Diagram" : "Hide Diagram"}
          </BlankButton>
        </div>
      ) : null}
      {!isHidden && diagram}
    </div>
  );
};
