import React, { FC, useEffect } from "react";
import Button from "../../../components/common/buttons/Button";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../components/common/formComponents/Input";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import validateAWSVPN from "../../../helpers/validators/AWSVPNValidator";
import { useValidation } from "../../../helpers/validators/Validator";
import SegmentsComponent from "../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/common/SegmentsComponent";
import { AWSVPNBody, AzureVPNBody } from "../../WizardToolPage/types";
import { useCloudsConnectivityContext } from "../CloudsConnectivityContext";
import styles from "./CloudVPNForm.module.scss";
import { azureLocations } from "../const";
import { AZURE_LOWERCASE } from "../../../helpers/consts";

type Props = {
  vpc_id?: string;
  region?: string;
  resource_group_name?: string;
  type: string;
  onClose?: () => void;
  name: string;
};

const DEFAULT_FIELDS: Partial<AWSVPNBody> = {
  connection_name: "",
  system_name: "",
  route_type: "static",
  network_segments: [],
};

const CloudVPNForm: FC<Props> = ({
  vpc_id,
  region,
  type,
  resource_group_name,
  name,
  onClose = () => undefined,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    addVPNByType,
    locationsAWS = [],
    getLocationsForAws,
  } = useCloudsConnectivityContext();

  const [fields, handleFieldChange] = useFormField<Partial<AWSVPNBody>>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<Partial<AWSVPNBody>>(
    validateAWSVPN,
    [fields]
  );

  useEffect(() => {
    if (selectedTenant) getLocationsForAws(selectedTenant);
  }, []);

  const getParamsByType = (): AzureVPNBody | AWSVPNBody => {
    let labels;
    switch (type) {
      case AZURE_LOWERCASE:
        const vnet_name = vpc_id;
        const regionFromMap =
          azureLocations[region as keyof typeof azureLocations];
        labels = { remote_type: type, vnet_name, region: regionFromMap };
        return {
          ...fields,
          labels,
          vnet_name,
          resource_group_name,
          region: regionFromMap,
        } as AzureVPNBody;
      default:
        labels = { remote_type: type, vpc_id, region, name };
        return {
          ...fields,
          labels,
          vpc_id,
          region,
        } as AWSVPNBody;
    }
  };

  const routeTypes = ["static", "bgp"];

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk && selectedTenant) {
      const params: AWSVPNBody | AzureVPNBody = getParamsByType();
      const success = await addVPNByType(type, params, selectedTenant);
      if (success) onClose();
    }
  };

  return (
    <div className={styles.wrapper}>
      <DropdownBasic
        id={"system_name"}
        onChange={(val) => {
          handleFieldChange("system_name", val.key);
        }}
        selected={locationsAWS.find((loc) => loc.key === fields.system_name)}
        label="Location"
        itemsList={locationsAWS}
        isMedium
        error={errors?.system_name}
      />
      <Input
        value={fields.connection_name}
        label="Connection name"
        onChange={(e) => handleFieldChange("connection_name", e.target.value)}
        isFullValue
        medium
        error={errors?.connection_name}
        placeholder={""}
      />
      <SegmentsComponent
        list={fields.network_segments || []}
        onChange={handleFieldChange}
        error={""}
      />
      <DropdownBasic
        id={"route_type"}
        onChange={(val) => {
          handleFieldChange("route_type", val.key);
        }}
        selected={mapStringToItem(fields?.route_type || "")}
        label="Route"
        itemsList={routeTypes.map(mapStringToItem)}
        isMedium
        error={errors?.route_type}
      />
      <Button onClick={onClose} className={styles.btns}>
        Cancel
      </Button>
      <Button onClick={handleSubmit} isPrimaryBtn className={styles.btns}>
        Add
      </Button>
    </div>
  );
};
export default CloudVPNForm;
