import { PaginateListResponse } from "./apiTypes";
import { ApiEntry, GET_REQUEST_STATUS } from "./apiPaths";
import { FetchEventResponse, UUID } from "./types";
import { handlingErrorDetails } from "../handlingErrorDetails";
import { requestMiddleware } from "./RequestMiddleware";

const MAX_ATTEMPTS = 30;
const MAX_ATTEMPTS_EXTENDED = 60;
const WAIT_MS = 1000;
const WAIT_MS_EXTENDED = 8000;

const POLL_METHODS = ["POST", "DELETE", "PUT", "PATCH"];

type InnerEventFn = Res<FetchEventResponse>;
type HeaderType = { [key: string]: string };
type FetchFn<T> = () => Promise<Res<T>>;

export type FetchBroker<P> = (
  [method, path]: ApiEntry,
  options: { [key: string]: any },
  extraHeaders?: HeaderType
) => Promise<Res<P>>;

const BASIC_HEADERS: HeaderType = {
  "Content-Type": "application/json",
};

export const GET_HEADERS = (): HeaderType => {
  const token = localStorage.getItem("token");

  if (token) {
    return { ...BASIC_HEADERS, Authorization: `Bearer ${token}` };
  }

  return { ...BASIC_HEADERS };
};

export type PRes<P> = Promise<Res<P>>;
export type Res<P> = {
  ok: boolean;
  error?: string;
  result?: P;
  count?: number;
  total_count?: number;
  isLoading?: boolean;
  isRefetching?: boolean;
  isIdle?: boolean;
};
export type ResultStatus = {
  id: UUID;
  created_at: string;
  updated_at: string;
  status: "received" | string;
};
export type PaginalRes<P> = Res<PaginateListResponse<P>>;
export const getListCount = (res: PaginalRes<any>): number =>
  res.result?.count || 0;

export enum Method {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

function formMessage(path: string, method: Method): string {
  let action: string;
  let source = "";
  switch (method) {
    case Method.DELETE:
      action = "Delete";
      break;
    case Method.PUT:
    case Method.PATCH:
      action = "Update";
      break;
    default:
      action = "Create";
      const sourceList = path.split("/");
      source = sourceList[sourceList.length - 1];
  }
  if (!source) source = path.match(/\w*$/gm)?.[0] || "";
  if (source.includes("_")) {
    const sources = source.split("_");
    for (let i = 0; i < sources.length; i++) {
      sources[i] = sources?.[i]?.[0]?.toUpperCase() + sources[i]?.substring(1);
    }
    source = sources.join(" ");
  } else {
    source = source?.[0]?.toUpperCase() + source.substring(1);
  }
  return `${action} ${source}`;
}

export default abstract class Api {
  private host = window.location.hostname;

  private protocol: string = window.location.protocol || "http:";
  private port = parseInt(
    window.location.port || (this.protocol === "http:" ? "80" : "443")
  );

  private _brokerPath = `${this.address}/broker`;

  protected brokerApi(path: string): string {
    return `${this._brokerPath}/${path}`;
  }

  get address(): string {
    return `${this.protocol}//${this.host}:${this.port}`;
  }

  async getResult<P>(res: Response): Promise<Res<P>> {
    let result;
    const resClone = res.clone();

    try {
      result = await res.json();
    } catch (e) {
      result = await resClone.text();
    }
    return res.ok
      ? { ok: true, result }
      : {
          ok: false,
          error: JSON.stringify(result.detail || result),
        };
  }

  static getParamsString(params: {
    [key: string]: string | number | undefined;
  }): string {
    const paramsArray = Object.entries(params)
      .filter(([, val]) => val)
      .map(([key, param]) => `${key}=${param}`);
    return `?${paramsArray.join("&")}`;
  }

  public async fetchBroker<P>(
    [method, path, apiOptions]: ApiEntry,
    options: { [key: string]: any } = {},
    extraHeaders?: HeaderType,
    skipMessage?: boolean
  ): Promise<Res<P>> {
    const params = options.query ? Api.getParamsString(options.query) : "";
    // console.log("options", options);
    // TEST NEW API APPROACH
    if (POLL_METHODS.includes(method)) {
      const fn = this.fetch<P>(
        this.brokerApi(path + params),
        {
          method,
          ...options,
        },
        extraHeaders,
        skipMessage
      );

      return this.fetchWithPolling<P>(
        () => fn,
        apiOptions?.extendedWaiting
      ) as Promise<Res<P>>;
    }

    return await this.fetch<P>(
      this.brokerApi(path + params),
      {
        method,
        ...options,
      },
      extraHeaders
    );
  }

  public async postFileToBroker<P extends string>(
    [method, path]: ApiEntry,
    options: { [key: string]: any } = {}
  ): Promise<Res<P>> {
    const headers = GET_HEADERS();
    delete headers["Content-Type"];

    return await this.fetch<P>(
      this.brokerApi(path),
      {
        method,
        headers,
        ...options,
      },
      {}
    );
  }

  public async fetch<P>(
    path: string,
    options: { [key: string]: any } = {},
    otherHeaders?: HeaderType,
    skipMessage?: boolean
  ): Promise<Res<P>> {
    try {
      const res = await fetch(path, {
        method: options.method || Method.GET,
        headers: GET_HEADERS(),
        ...options,
      });
      const { ok, result, error } = await this.getResult<P>(res);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const [id, status] = [result?.id, result?.status];
      if (ok && POLL_METHODS.includes(options?.method) && !skipMessage) {
        await requestMiddleware.addMessage({
          message: formMessage(path, options.method),
          id,
        });
      } else if (
        !ok &&
        POLL_METHODS.includes(options?.method) &&
        !skipMessage
      ) {
        await requestMiddleware.addMessage({
          message: formMessage(path, options.method),
          createdAt: Date.now(),
          status: false,
          e: res.statusText,
        });
      } else if (!skipMessage && ["failed", "success"].includes(status)) {
        const ids = requestMiddleware.getMessagesIds();
        if (ids.some((substring) => path.includes(substring))) {
          const messageId = ids.filter((id) => path.includes(id))[0];
          const failed = status === "failed";
          let e = "";
          if (failed) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            e = result?.extra_details?.error || result?.extra_details?.message;
          }
          await requestMiddleware.updateMessageStatus(
            messageId,
            failed ? false : true,
            e
          );
        }
      }
      return { ok, result, error };
    } catch (e) {
      if (POLL_METHODS.includes(options?.method) && !skipMessage)
        await requestMiddleware.addMessage({
          message: formMessage(path, options.method),
          createdAt: Date.now(),
          status: false,
          e: e + "",
        });
      return { ok: false, error: e + "" };
    }
  }

  public async fetchBrokerFull(
    [method, path]: ApiEntry,
    options: { [key: string]: any } = {}
  ): Promise<any> {
    return await this.fetchFull(this.brokerApi(path), {
      method,
      ...options,
    });
  }

  public async fetchFull(
    path: string,
    options: { [key: string]: any } = {}
  ): Promise<any> {
    try {
      const res = await fetch(path, {
        method: options.method || Method.GET,
        headers: GET_HEADERS(),
        ...options,
      });
      return res;
    } catch (e) {
      return { ok: false, error: e + "" };
    }
  }

  async fetchWithPolling<T>(
    fetchFn: FetchFn<T>,
    extendedWaiting?: boolean
  ): Promise<InnerEventFn> {
    const response = ((await fetchFn()) as unknown) as InnerEventFn;

    const { result, ok } = response;
    if (!ok || !result) {
      return response;
    }

    if (!this.supportsPolling(result)) {
      return response;
    }

    const { id } = result;

    return this.pollRequest(id, extendedWaiting);
  }

  private async pollRequest(
    id: UUID,
    extendedWaiting?: boolean
  ): Promise<Res<FetchEventResponse>> {
    let attempts = 0;
    const reqInterval = extendedWaiting ? WAIT_MS_EXTENDED : WAIT_MS;
    const maxAttempts = extendedWaiting ? MAX_ATTEMPTS_EXTENDED : MAX_ATTEMPTS;
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        const data = await this.poll(id);

        const response = data;
        const { ok, result, error } = data;
        if (error) {
          reject(response);
        }

        if (ok && result) {
          const { status } = result;

          if (status === "success") {
            resolve(data);
            clearInterval(interval);
          } else if (status === "failed") {
            clearInterval(interval);
            resolve({
              ok: false,
              error: handlingErrorDetails(result?.extra_details),
            });
          }
        }
        attempts++;

        if (attempts > maxAttempts) {
          clearInterval(interval);
          resolve({
            ok: false,
            error: JSON.stringify(result?.extra_details || "Timeout"),
          });
        }
      }, reqInterval);
    });
  }

  private async poll(id: UUID): Promise<Res<FetchEventResponse>> {
    return this.fetchBroker(GET_REQUEST_STATUS(id));
  }

  private supportsPolling(payload: FetchEventResponse): boolean {
    return Boolean(payload.id && payload.status && payload.created_at);
  }
}
