import { PortType } from "../../PortsPage/types";

export const getVlanOptions = (
  vlanList: PortType["free"] | undefined
): Array<string> => {
  if (!vlanList) return [];

  const selectThreeFromRange = (
    range: PortType["free"][number]
  ): Array<number> => {
    const { start, end } = range;
    if (end - start < 2) return [start, end];
    const mid = Math.floor((start + end) / 2);
    return [start, mid, end];
  };

  return vlanList
    .flatMap(selectThreeFromRange)
    .filter((el) => el !== 1 && el !== 4096)
    .map((n) => n.toString());
};
