import React, { FC } from "react";
import LayoutComponent from "../../components/common/LayoutComponent";
import { withContexts } from "../../helpers/hocs/withContexts";
import { TenantsTab } from "../../components/common/navigation/Tenants/TenantsTab";
import ConnectionsContext from "../WizardToolPage/ConnectionStep/ConnectionsContext";
import TenantsContext from "../TenantsPage/TenantsContext";
import OrderedPortsPageContent from "./OrderedPortsPageContent";
import OrderedPortsContextContainer from "./OrderedPortsContext";
import TenantVirtualInterfaceContextContainer from "../../contexts/tenantsContext/TenantVirtualInterfaceContext";

const TenantsPage: FC = () => {
  return (
    <LayoutComponent>
      <TenantsTab />
      <OrderedPortsPageContent />
    </LayoutComponent>
  );
};
export default withContexts(TenantsPage, [
  TenantsContext,
  ConnectionsContext,
  OrderedPortsContextContainer,
  TenantVirtualInterfaceContextContainer,
]);
