import { ReactElement } from "react";
function ActiveNodeIcon(): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="12" cy="12" r="12" fill="#6B55F0" />
      <path
        d="M11.9581 12.9495L9.0339 10.0495L8 11.0747L11.9581 15L20 7.02527L18.9661 6L11.9581 12.9495Z"
        fill="#6B55F0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7675 6.4597C14.6939 5.72833 13.3968 5.30078 11.9998 5.30078C8.2995 5.30078 5.2998 8.30047 5.2998 12.0008C5.2998 15.7011 8.2995 18.7008 11.9998 18.7008C15.7001 18.7008 18.6998 15.7011 18.6998 12.0008C18.6998 11.7221 18.6828 11.4473 18.6497 11.1776L17.271 12.5562C16.9934 15.2223 14.7393 17.3008 11.9998 17.3008C9.0727 17.3008 6.6998 14.9279 6.6998 12.0008C6.6998 9.07367 9.0727 6.70078 11.9998 6.70078C13.0089 6.70078 13.9521 6.98278 14.7549 7.47226L15.7675 6.4597Z"
        fill="#6B55F0"
      />
    </svg>
  );
}

export default ActiveNodeIcon;
