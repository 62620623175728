import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import { CellProps } from "react-table";
import {
  getConnectionNameDisplay,
  getConnectionTypeDisplay,
} from "../../../Connections/components/TableColumnsConfig";
import { MembersType } from "../../../../Layer3Interface/types";
import styles from "./cloudTableHeader.module.scss";
import React from "react";
import Label from "../../../../../components/common/table/Label";
import CheckedIcon from "./icons/CheckedIcon";
import { classNames } from "../../../../../helpers/common/classNames";
import CaretUp from "../../../../../components/icons/CaretUp";
import IconBlankButton from "../../../../../components/common/buttons/IconBlankButton";
import { parseVlan } from "./CloudsTabInfo";
import { PortVlan } from "../../../../VLANPage/types";
import { TenantCustomerPort } from "../../types";
import { AWS_LOWERCASE, AZURE_LOWERCASE } from "../../../../../helpers/consts";

export const cloudTableHeader = (
  customerPorts: Array<TenantCustomerPort>,
  isCloud?: boolean,
  onClick?: (val: VirtualInterfaceApi) => void,
  expanded?: Array<number>
): Array<any> => {
  const headers: Array<any> = [
    {
      accessor: (row: any) => [
        row.name,
        row.type,
        row?.virtual_interface_type,
        row?.labels?.remote_type,
        row?.is_ipsec_service,
        row?.is_nat_service,
        row?.is_snat_service,
        row?.is_dia,
      ],
      Header: "Connection Name",
      Cell: ({ cell: { value } }: CellProps<any>) =>
        getConnectionNameDisplay(value),
    },
    {
      accessor: (row: any) => [
        row.type,
        row?.virtual_interface_type,
        row?.labels?.remote_type,
        row?.is_ipsec_service,
        row?.is_nat_service,
        row?.is_snat_service,
        row?.is_dia,
      ],
      Header: "Type",
      Cell: ({ row: { original }, cell: { value } }: CellProps<any>) => {
        if (
          !isCloud ||
          [AWS_LOWERCASE, AZURE_LOWERCASE].includes(
            original.labels?.remote_type
          )
        )
          return getConnectionTypeDisplay(value);
        const portInterface = original.members.find(
          (item: any) =>
            ["port_vlan", "qinq_port_vlan"].includes(item.interface_type) &&
            item.interfaces.length
        );
        const vlanInterface = portInterface?.interfaces[0] as PortVlan;
        const customerPort = customerPorts.find(
          (port) => port.name === vlanInterface?.alias
        );
        return (
          <Label className={styles.noBack}>
            {customerPort?.labels?.operator || "-"}
          </Label>
        );
      },
    },
    {
      Header: "Bandwidth",
      accessor: (row: any) => [row.id, row?.labels],
      Cell: ({ row: { original }, cell: { value } }: CellProps<any>) => {
        const { max_bandwidth } = parseVlan(original);
        if (!max_bandwidth) return <div />;
        return <Label className={styles.noBack}>{max_bandwidth}</Label>;
      },
    },
  ];
  if (isCloud) {
    headers.push(
      {
        Header: "Internet access",
        accessor: "labels",
        Cell: ({ cell: { value } }: CellProps<MembersType>) => {
          const internet_access: boolean = value?.internet_access;
          return internet_access ? <CheckedIcon /> : null;
        },
      },
      {
        Header: "Routing",
        accessor: "connection",
      }
    );
  }

  headers.push({
    Header: "",
    accessor: "id",
    Cell: ({ row }: CellProps<any>) => {
      return (
        <div className={styles.btnContainer}>
          <IconBlankButton
            id={"extraTitleCollapse"}
            onClick={() => onClick?.(row.original)}
            icon={CaretUp}
            className={classNames(
              styles.collapseBtn,
              !expanded?.includes(row.original.id) && styles.rotate
            )}
          />
        </div>
      );
    },
  });

  return headers.map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInterfaceApi,
  }));
};
