import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from "react";
import {
  PricingType,
  TermsAndPricingDialog,
} from "../../components/dialogs/TermsAndPricingDialog";
import { useFetch } from "../../helpers/hooks/useFetch";
import { pricingApi } from "../../helpers/api/PricingApi";
import { useAuthContext } from "../AuthContext";
import { getPricingList, getTerms } from "./helpers";

type PricingModal = "pricing" | "terms";

interface PricingDialogContextType {
  isOpen: PricingModal | undefined;
  openDialog: (product: string, params?: any) => Promise<{ ok: boolean }>;
  closeDialog: () => void;
  setOpenModal: (modal: PricingModal | undefined) => void;
  product: string | undefined;
  selectedPrice: PricingType | undefined;
  selectPrice: (id: number | undefined) => void;
  additionalParams: any | undefined;
}

const PricingDialogContext = createContext<
  PricingDialogContextType | undefined
>(undefined);

export const CLOSE_PRICING_MODAL_EVENT = "close-pricing-modal-event";
export const SUBMIT_SUCCESS_PRICING_MODAL_EVENT =
  "submit-success-pricing-modal-event";
export const SUBMIT_ERROR_PRICING_MODAL_EVENT =
  "submit-error-pricing-modal-event";

export const PricingDialogProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState<PricingModal>();
  const [product, setProduct] = useState<string>();
  const [selectedPrice, setSelectedPrice] = useState<number>();
  const [additionalParams, setAdditionalParams] = useState<any>();
  const { isAuthorized } = useAuthContext();

  const { state: pricingState } = useFetch({
    fetchFn: pricingApi.getServiceCatalog.bind(pricingApi),
    isEnabled: isAuthorized,
  });

  const { state: termsState } = useFetch({
    fetchFn: pricingApi.getTermsCatalog.bind(pricingApi),
    isEnabled: isAuthorized,
  });

  const selectedPriceIndex = useMemo(() => {
    return selectedPrice !== undefined
      ? pricingState.result?.items.find((item) => item.id === selectedPrice)
      : undefined;
  }, [selectedPrice]);

  const pricingList = useMemo(
    () => getPricingList(pricingState, product, additionalParams),
    [pricingState, product, additionalParams]
  );

  const terms = useMemo(() => getTerms(termsState, product), [
    termsState,
    product,
  ]);

  const openDialog = async (
    newProduct: string,
    params: object
  ): Promise<{ ok: boolean }> => {
    setProduct(newProduct);

    const pricingList = getPricingList(pricingState, newProduct, params);
    const terms = getTerms(termsState, newProduct);

    if (!pricingList?.length || !terms) return Promise.resolve({ ok: true });

    if (!pricingList) {
      setIsOpen("terms");
    } else {
      setIsOpen("pricing");
    }

    setAdditionalParams(params);
    return new Promise((resolve) => {
      const onFail = () => {
        resolve({ ok: false });
        removeListeners();
      };

      const onSuccess = () => {
        resolve({ ok: true });
        removeListeners();
      };

      const removeListeners = () => {
        window.removeEventListener(CLOSE_PRICING_MODAL_EVENT, onFail);
        window.removeEventListener(SUBMIT_ERROR_PRICING_MODAL_EVENT, onFail);
        window.removeEventListener(
          SUBMIT_SUCCESS_PRICING_MODAL_EVENT,
          onSuccess
        );
      };

      window.addEventListener(CLOSE_PRICING_MODAL_EVENT, onFail);
      window.addEventListener(SUBMIT_ERROR_PRICING_MODAL_EVENT, onFail);
      window.addEventListener(SUBMIT_SUCCESS_PRICING_MODAL_EVENT, onSuccess);
    });
  };

  const closeDialog = () => {
    setIsOpen(undefined);
    setProduct(undefined);
    window.dispatchEvent(new CustomEvent(CLOSE_PRICING_MODAL_EVENT));
  };

  return (
    <PricingDialogContext.Provider
      value={{
        isOpen,
        openDialog,
        closeDialog,
        product,
        setOpenModal: setIsOpen,
        selectedPrice: selectedPriceIndex,
        selectPrice: setSelectedPrice,
        additionalParams,
      }}
    >
      {children}
      <TermsAndPricingDialog
        pricingList={pricingList}
        terms={terms}
        isPricingLoading={!!pricingState.isLoading}
        isTermsLoading={!!termsState.isLoading}
      />
    </PricingDialogContext.Provider>
  );
};

export const usePricingDialog = () => {
  const context = useContext(PricingDialogContext);
  if (context === undefined) {
    throw new Error(
      "usePricingDialogContext must be used within a PricingDialogProvider"
    );
  }
  return context;
};
