import {
  orderLogAllTableHeader,
  orderLogConnectionsTableHeader,
  orderLogPortsTableHeader,
  orderLogPublicIPTableHeader,
  orderLogServicesTableHeader,
} from "./orderLogTableHeader";
import { Tabs } from "./types";

export const GROUP_KEY = "_groupByKey";

export const SERVICES_NAME_MAP = {
  sdr: "Network Observability",
  dpi: "Application Observability",
  ids: "Cyber Threats",
  fqdn: "Web Filtering",
  firewallservice: "Stateful Firewall",
  diafw: "DIA Firewall",
  nat: "Address Translation (NAT)",
  dns: "DNS observability",
};

export const CONNECTIONS_INTERFACE_NAME_MAP = {
  routed: "Cloud Router",
  bridged: "Direct",
};

export const tabs: Array<{ content: string; path: Tabs }> = [
  {
    content: "All",
    path: Tabs.ALL,
  },
  {
    content: "Ports",
    path: Tabs.PORTS,
  },
  {
    content: "Connections",
    path: Tabs.CONNECTIONS,
  },
  {
    content: "Public IPs",
    path: Tabs.PUBLIC_IPS,
  },
  {
    content: "Services/Packages",
    path: Tabs.SERVICES_PACKAGES,
  },
];

export const TABLE_HEADER_BY_TAB = {
  [Tabs.ALL]: orderLogAllTableHeader(),
  [Tabs.CONNECTIONS]: orderLogConnectionsTableHeader(),
  [Tabs.PORTS]: orderLogPortsTableHeader(),
  [Tabs.PUBLIC_IPS]: orderLogPublicIPTableHeader(),
  [Tabs.SERVICES_PACKAGES]: orderLogServicesTableHeader(),
};

export const TYPE_TO_LABEL_MAP = {
  ports: "Port",
  connections: "Connection",
  public_ip: "Public IP",
  services: "Service/Package",
};
