import React, { FC, useEffect, useState } from "react";
import { useQuotaContext } from "../../../../contexts/tenantsContext/QuotaContext";
import { QuotaType } from "../../../../helpers/api/apiTypes";
import { classNames } from "../../../../helpers/common/classNames";
import { PackageListType } from "../PackagesPage";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./PackagesSections.module.scss";
import SectionCell from "./SectionCell";
import SectionTitle from "./SectionTitle";

type Props = {
  section: { key: string; title: string };
  list: Array<QuotaType>;
  hardcodedList: Array<PackageListType>;
  isDefaultPackages?: boolean;
  isLastRow?: boolean;
  activePackage?: string;
  fields?: any;
  onChange?: any;
};

const PackagesSections: FC<Props> = ({
  section,
  list,
  hardcodedList,
  isDefaultPackages,
  isLastRow,
  activePackage,
  fields,
  onChange,
}) => {
  const { hardcodedFields } = useQuotaContext();
  const [sectionFields, setSectionFields] = useState<Array<string>>();

  useEffect(() => {
    if (hardcodedFields && section) {
      setSectionFields(hardcodedFields[section.key]);
    }
  }, [hardcodedFields, section]);

  const rowClasses = classNames(
    styles.row,
    isDefaultPackages && styles.shortened
  );
  const isActive = (val: string): boolean => {
    return activePackage?.toLowerCase() === val.toLowerCase();
  };

  return (
    <>
      <div className={classNames(rowClasses, styles.firstRow)}>
        <div className={styles.sectionTitle}>{section.title}</div>
        {hardcodedList?.map((quota) => (
          <div
            className={classNames(
              styles.cell,
              styles[quota.name.toLowerCase()],
              section.key !== "networkingFields" && styles.separator,
              isActive(quota.name) && styles.active
            )}
            key={quota.name}
          />
        ))}
      </div>
      {sectionFields &&
        sectionFields.map((key) => {
          return (
            <div className={rowClasses} key={key}>
              <SectionTitle fieldKey={key} className={styles.rowTitle} />
              {hardcodedList?.map((pseudoQuota) => {
                const type = pseudoQuota.name.toLowerCase();
                const isEdit =
                  isActive(pseudoQuota.name) &&
                  (isDefaultPackages || type === "custom");
                return (
                  <div
                    className={classNames(
                      styles.cell,
                      styles[type],
                      isActive(pseudoQuota.name) && styles.active
                    )}
                    key={pseudoQuota.name}
                  >
                    <SectionCell
                      isEdit={isEdit}
                      currentVal={pseudoQuota[key]}
                      fieldKey={key}
                      realValues={list.find(
                        (el) => el.name === pseudoQuota.name
                      )}
                      fields={fields}
                      onChange={onChange}
                    />
                  </div>
                );
              })}
            </div>
          );
        })}
      {isLastRow && (
        <div className={classNames(rowClasses)}>
          <div />
          {hardcodedList?.map((quota) => {
            return (
              <div
                key={quota.name}
                className={classNames(
                  styles.cell,
                  styles.lastRow,
                  styles[quota.name.toLowerCase()],
                  isActive(quota.name) && styles.active
                )}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
export default PackagesSections;
