/* eslint-disable css-modules/no-unused-class */
import React, { FC } from "react";
import Button from "../../../../../../../components/common/buttons/Button";
import TextWithIcon from "../../../../../../../components/common/table/TextWithIcon";
import LocationIcon from "../../../../../../../components/icons/LocationIcon";
import { ORANGE } from "../../../../../../../helpers/common/colorAliases";
import { SELECTORS_LIST } from "./leftTabsConsts";
import styles from "./LeftTabs.module.scss";
import TabBlock from "../TabBlock";
import { useConnectionsContext } from "../../../../ConnectionsContext";

const LeftTabs: FC = () => {
  const {
    activeLocation,
    locations,
    scrollThroughLocations,
    activeConnectionType,
    setActiveConnectionType,
    setActiveConnection,
  } = useConnectionsContext();

  const handleTabClick = (id: string) => {
    // removeSelectedConnection();
    setActiveConnectionType(id);
    setActiveConnection(undefined, undefined);
  };

  return (
    <div className={styles.wrapper}>
      <TextWithIcon
        icon={<LocationIcon color={ORANGE} />}
        text={activeLocation?.location}
        className={styles.location}
      />
      <div className={styles.selectorsList}>
        {SELECTORS_LIST.map((selector) => (
          <div key={selector.title} className={styles.selectorItem}>
            <div className={styles.selectorsTitle}>{selector.title}</div>
            <TabBlock
              list={selector.list}
              onClick={handleTabClick}
              activeType={activeConnectionType}
            />
          </div>
        ))}
      </div>
      <Button
        onClick={() => scrollThroughLocations(activeLocation)}
        className={styles.btn}
        disabled={locations.length <= 1}
      >
        Next Location
      </Button>
    </div>
  );
};

export default LeftTabs;
