import groupBy from "lodash/groupBy";
import sumBy from "lodash/sumBy";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./TableHeaders.module.scss";
import {
  AwsNNI,
  AzureNNI,
  GCPNNI,
  RegionItem,
  TotalProviderInfo,
} from "./types";
import { GREEN, ORANGE, PINK } from "../../helpers/common/colorAliases";

export const getColorByPercentage = (percentage: number): string => {
  if (percentage <= 50) {
    return GREEN;
  } else if (percentage < 75) {
    return ORANGE;
  } else {
    return PINK;
  }
};

export const getColorClassByPercentage = (
  percentage: number,
  linkCapacity?: { value: number; capacity: number }
): string => {
  if (linkCapacity && linkCapacity.value < linkCapacity.capacity)
    return styles.green;

  if (percentage <= 50) {
    return styles.green;
  } else if (percentage < 90) {
    return styles.yellow;
  } else {
    return styles.red;
  }
};

export const getCombinedAwsInfo = (
  data: Array<AwsNNI>
): { regions: Array<RegionItem>; total: TotalProviderInfo } => {
  const groupedByRegion = groupBy(data, (item) => item.aws_region);
  const regions = Object.keys(groupedByRegion).map((key) => {
    const regionNNIs = groupedByRegion[key];
    const nniCount = regionNNIs.length;
    const totalCapacity = sumBy(regionNNIs, (nni) => nni.aws_bandwidth);
    const provisionedCapacity =
      sumBy(regionNNIs, (nni) => nni.aws_used_bandwidth) ?? 0;
    const connections = sumBy(regionNNIs, (nni) => nni.connections_count);

    return {
      region: key,
      nniCount,
      totalCapacity,
      provisionedCapacity,
      connections,
    };
  });

  const regionsCount = Object.keys(groupedByRegion).length;
  const nniCount = data.length;
  const capacity = sumBy(data, (nni) => nni.aws_bandwidth);
  const connections = sumBy(data, (nni) => nni.connections_count);
  const used = sumBy(data, (nni) => nni.aws_used_bandwidth) ?? 0;

  return {
    regions,
    total: {
      regions: regionsCount,
      nniCount,
      capacity,
      connections,
      used,
    },
  };
};

export const getCombinedAzureInfo = (
  data: Array<AzureNNI>
): { regions: Array<RegionItem>; total: TotalProviderInfo } => {
  const groupedByRegion = groupBy(data, (item) => item.peering_location);
  const regions = Object.keys(groupedByRegion).map((key) => {
    const regionNNIs = groupedByRegion[key];
    const nniCount = regionNNIs.length;
    const totalCapacity = sumBy(regionNNIs, (nni) => nni.azure_bandwidth);
    const provisionedCapacity = sumBy(
      regionNNIs,
      (nni) => nni.azure_used_bandwidth
    );
    const connections = sumBy(regionNNIs, (nni) => nni.connections_count);
    const oversubscriptionCapacity = sumBy(
      regionNNIs,
      (nni) => nni.azure_metadata.port_bandwidth_in_mbps
    );

    return {
      region: key,
      nniCount,
      totalCapacity,
      provisionedCapacity,
      connections,
      oversubscriptionCapacity,
    };
  });

  const regionsCount = Object.keys(groupedByRegion).length;
  const nniCount = data.length;
  const capacity = sumBy(data, (nni) => nni.azure_bandwidth) * 1000;
  const used = sumBy(data, (nni) => nni.azure_used_bandwidth) * 1000;
  const connections = sumBy(data, (nni) => nni.connections_count);
  const oversubscriptionCapacity =
    sumBy(data, (nni) => nni.azure_metadata.port_bandwidth_in_mbps) * 1000;

  return {
    regions,
    total: {
      regions: regionsCount,
      nniCount,
      capacity,
      connections,
      used,
      oversubscriptionCapacity,
    },
  };
};

export const getCombinedGCPInfo = (
  data: Array<GCPNNI>
): { regions: Array<RegionItem>; total: TotalProviderInfo } => {
  const groupedByRegion = groupBy(data, (item) => {
    const [key1, key2] = item.gcp_location.split("-");
    return [key1, key2].join("-");
  });
  const regions = Object.keys(groupedByRegion).map((key) => {
    const regionNNIs = groupedByRegion[key];
    const nniCount = regionNNIs.length;
    const totalCapacity = sumBy(regionNNIs, (nni) => nni.managed_max_bandwidth);
    const provisionedCapacity = sumBy(regionNNIs, (nni) => nni.used_bandwidth);
    const connections = sumBy(regionNNIs, (nni) => nni.connections_count);
    const oversubscriptionCapacity = sumBy(
      regionNNIs,
      (nni) => nni.gcp_metadata.port_bandwidth_in_mbps
    );

    return {
      region: key,
      nniCount,
      totalCapacity,
      provisionedCapacity,
      connections,
      oversubscriptionCapacity,
    };
  });

  const regionsCount = Object.keys(groupedByRegion).length;
  const nniCount = data.length;
  const capacity = sumBy(data, (nni) => nni.managed_max_bandwidth);
  const connections = sumBy(data, (nni) => nni.connections_count);
  const used = sumBy(data, (nni) => nni.used_bandwidth) ?? 0;

  return {
    regions,
    total: {
      regions: regionsCount,
      nniCount,
      capacity,
      connections,
      used,
    },
  };
};
