import React, { FC, useEffect, useMemo, useState } from "react";
import { NeighborType } from "../../types";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import ServicesBGPContextContainer, {
  useBGPServicesContext,
} from "../../ServicesBGPContext";
import useDeleteDialog from "../../../../helpers/hooks/useDeleteDialog";
import ServiceBGPHeader from "./components/ServiceBGPHeader";
import { ActionFuncs } from "../../../../components/common/table/newTable/RowControls";
import ConfirmDeleteDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import Table from "../../../../components/common/table/newTable/Table";
import { serviceBGPTableHeader } from "./table/serviceBGPTableHeader";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import styles from "./ServicesBGPPage.module.scss";
import { isPending } from "../../../../helpers/common/RequestStateHelpers";
import UserContextContainer, {
  useUserContext,
} from "../../../../contexts/UserContext";
import ServiceBGPStat from "../ServiceBGPStat";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import ServiceBGPASNConfig from "../ServiceBGPASNConfig/ServiceBGPASNConfig";
import { useHistory } from "react-router-dom";
import { BGP, ADD, EDIT } from "../../../../helpers/navigation/entries";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import VRFSelectorContext from "../../../../contexts/systemsContext/VRFSelectorContext";

const REFETCH_INTERVAL = 10 * 1000;
let FetchInterval: NodeJS.Timeout | undefined;

let listStatusBeforePending: any = undefined;

export type BGPDialogType =
  | "asn"
  | "neighbor"
  | "delete"
  | "routeMap"
  | undefined;

const ServicesBGPPage: FC = () => {
  const history = useHistory();
  const { selectedTenant } = useGlobalFilterContext();

  const {
    fetchData,
    listStatus,
    autonomous_system,
    neighborsList,
    removeAS,
    removeNeighbor,
    removeStatus,
  } = useBGPServicesContext();

  const [selectedNeighbor, setSelectedNeighbor] = useState<string | undefined>(
    undefined
  );
  const [shownDialog, setShownDialog] = useState<BGPDialogType>(undefined);
  const { isDemo } = useUserContext();

  if (!isPending(listStatus)) {
    listStatusBeforePending = listStatus;
  }

  let handledStatus = listStatus;
  if (isPending(listStatus) && listStatusBeforePending) {
    handledStatus = listStatusBeforePending;
  }

  const [
    tryDeleteAS,
    selectedASToDelete,
    sureDeleteASProps,
  ] = useDeleteDialog<string>(removeAS, removeStatus, () =>
    setShownDialog(undefined)
  );
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<string>(removeNeighbor, removeStatus, () => {
    setShownDialog(undefined);
  });

  const restartFetchInterval = (providedTenant: string | undefined) => {
    FetchInterval && clearInterval(FetchInterval);
    FetchInterval = setInterval(() => {
      fetchWrapper(providedTenant);
    }, REFETCH_INTERVAL);
  };

  useEffect(() => {
    fetchWrapper(selectedTenant);
    restartFetchInterval(selectedTenant);
    return () => {
      FetchInterval && clearInterval(FetchInterval);
    };
  }, []);

  const fetchWrapper = (providedTenant: string | undefined) => {
    if (providedTenant) {
      fetchData(providedTenant);
    }
  };

  useEffect(() => {
    fetchWrapper(selectedTenant);
    restartFetchInterval(selectedTenant);
  }, [selectedTenant]);

  const handleEditNeighbor = (row: NeighborType) => {
    history.push(BGP().path + "/" + EDIT(row.remote_router_ip).path);
  };

  const handleAddNeighbor = () => {
    history.push(BGP().path + "/" + ADD().path);
  };

  const handleRowClick = (rowOriginal: NeighborType) => {
    const value =
      selectedNeighbor === rowOriginal.remote_router_ip
        ? ""
        : rowOriginal.remote_router_ip;
    setSelectedNeighbor(value);
  };

  const rowActions: ActionFuncs<NeighborType> = useMemo(
    () => ({
      onEdit: handleEditNeighbor,
      onDelete: (rowOriginal) =>
        tryDelete(rowOriginal.remote_router_ip, selectedTenant),
    }),
    [selectedTenant, tryDelete, handleEditNeighbor]
  );

  const handleDeleteAsn = () => {
    if (!autonomous_system || !selectedTenant) return;
    setShownDialog(undefined);
    tryDeleteAS(selectedTenant);
  };

  if (shownDialog === "asn" && selectedTenant) {
    return (
      <ServiceBGPASNConfig
        onClose={() => setShownDialog(undefined)}
        tenant={selectedTenant}
        neighbors={neighborsList.length}
        type={autonomous_system ? "edit" : "add"}
        data={autonomous_system}
        onDelete={handleDeleteAsn}
      />
    );
  }

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Neighbor"}
        name={`${selectedToDelete}`}
      />
      <ConfirmDeleteDialog
        {...sureDeleteASProps}
        deviceType={"Autonomous System"}
        name={`AS for tenant ${selectedASToDelete}`}
      />
      <div className={styles.wrapper}>
        <ServiceBGPHeader
          data={autonomous_system}
          className={styles.complexHeader}
          onClick={() => {
            setShownDialog("asn");
          }}
          onAdd={handleAddNeighbor}
          disabled={!autonomous_system}
        />
        <div className={styles.contentContainer}>
          <div className={styles.table}>
            {handledStatus?.state === "pending" ? (
              <PageLoader />
            ) : (
              <Table
                columns={serviceBGPTableHeader(
                  setSelectedNeighbor,
                  selectedNeighbor || "all",
                  isDemo
                )}
                header={TableHeader}
                data={neighborsList}
                gridColumnTemplate={
                  isDemo
                    ? `1fr 1fr 1fr 1fr 1fr 1fr 0`
                    : `1fr repeat(6, 0.75fr) 0`
                }
                rowActions={rowActions}
                onClick={handleRowClick}
                isRowHighlighted={(row) =>
                  row.remote_router_ip === selectedNeighbor
                }
              />
            )}
          </div>
          <ServiceBGPStat
            handledStatus={handledStatus}
            selectedNeighbor={selectedNeighbor}
          />
        </div>
      </div>
    </>
  );
};

export default withContexts(ServicesBGPPage, [
  ServicesBGPContextContainer,
  UserContextContainer,
  VRFSelectorContext,
]);
