import { createContextAndUse } from "../../../../contexts/common/utils";
import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";

import {
  IUserContext,
  withUserContextProps,
} from "../../../../contexts/UserContext";
import { RequestStatus } from "../../../../helpers/types";
import {
  resToState,
  setPending,
} from "../../../../helpers/common/RequestStateHelpers";
import { NATRuleAdd } from "../../types";
import { configApi } from "../../../../helpers/api/ConfigApi";
import { NAT_TYPES_MAP, NEW_PUBLIC_IP } from "../../const";
import { tenantVirtualInterfacesApi } from "../../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import { RequestedGateVIAdd } from "../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { Res } from "../../../../helpers/api/Api";
import { PROTOCOLS_NAMES_MAP } from "../../../../helpers/getProtocolByValue";
import { PROTOCOL_ANY } from "../../../../helpers/common/constantsAlias";

type RequestStatuses = {
  addStatus?: RequestStatus;
  editStatus?: RequestStatus;
};

type State = {};

type IState = State & RequestStatuses;
type IFunc = {
  add: (fields: { [key: string]: any }, tenantName: string) => Promise<boolean>;
  edit: (
    fields: { [key: string]: any },
    tenantName: string,
    ruleName: string
  ) => Promise<boolean>;
};

const [
  NATRuleConfigurationContext,
  useNATRuleConfigurationContext,
] = createContextAndUse<IState, IFunc>();
export { useNATRuleConfigurationContext };

class NATRuleConfigurationContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IUserContext
> {
  Context = NATRuleConfigurationContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {};
  }

  add = async (
    fields: { [key: string]: any },
    tenantName: string
  ): Promise<boolean> => {
    if (!Object.keys(fields)) return false;
    this.setState({ addStatus: setPending() });

    const newFields = { ...fields };

    if (newFields.generalProtocol) {
      delete newFields.generalProtocol;
    }

    const isOneToOne = newFields.rule_type === NAT_TYPES_MAP[0].key;

    newFields.filters = [];
    if (newFields.src_l4_port) {
      newFields.filters.push({
        filter_type: "src_l4_port",
        values: newFields.src_l4_port,
      });
      delete newFields.src_l4_port;
    }

    if (newFields.dst_l4_port) {
      newFields.filters.push({
        filter_type: "dst_l4_port",
        values: newFields.dst_l4_port,
      });
      delete newFields.dst_l4_port;
    }

    if (
      newFields?.filterProtocol &&
      newFields.filterProtocol !== PROTOCOL_ANY
    ) {
      newFields.filters.push({
        filter_type: "ip_protocol",
        values: PROTOCOLS_NAMES_MAP[newFields.filterProtocol],
      });
      delete newFields.filterProtocol;
    }

    if (!isOneToOne) {
      newFields.filters.push({
        filter_type: "src_network",
        values: newFields.inside,
      });
      delete newFields.inside;
    } else {
      delete newFields.filters;
    }

    if (newFields.outside === NEW_PUBLIC_IP) {
      const body = { ...newFields.newPublicIp };
      delete newFields.newPublicIp;
      const newIPres = await this._requestNewPublicIp(body, tenantName);
      if (newIPres.ok) {
        newFields.outside = newIPres.result;
        return await this._addNatRule(newFields, tenantName);
      }

      this.setState({ addStatus: resToState(newIPres) });
      return false;
    }
    return await this._addNatRule(newFields, tenantName);
  };

  _addNatRule = async (
    body: { [key: string]: any },
    tenantName: string
  ): Promise<boolean> => {
    const res = await configApi.addNatRule(tenantName, body as NATRuleAdd);
    this.setState({ addStatus: resToState(res) });
    return res.ok;
  };

  _requestNewPublicIp = async (
    body: RequestedGateVIAdd,
    tenantName: string
  ): Promise<Res<string | undefined>> => {
    const res = await tenantVirtualInterfacesApi.requestVIGate(
      tenantName,
      body
    );
    if (!res.ok) return res;

    const status = await this._getValueAfterDelay(res.result.id);
    const result =
      status && status?.status === "success"
        ? { ok: true, result: body.name }
        : {
            ok: false,
            error: status?.extra_details?.message,
            result: undefined,
          };
    return result;
  };

  _getValueAfterDelay = async (id: string): Promise<{ [key: string]: any }> => {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const data = await configApi.getRequestByID(id);
        resolve(data.result);
      }, 10000); // 10 seconds delay
    });
  };

  edit = async (
    fields: { [key: string]: any },
    tenantName: string,
    ruleName: string
  ): Promise<boolean> => {
    this.setState({ editStatus: setPending() });
    const params = {
      name: fields.name,
      outside: fields.outside,
      description: fields.description,
    };
    const res = await configApi.editNatRule(tenantName, ruleName, params);
    this.setState({ editStatus: resToState(res) });
    return res.ok;
  };

  funcs = { add: this.add, edit: this.edit };
}

export default withUserContextProps(NATRuleConfigurationContextContainer);
