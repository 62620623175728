import { CellProps } from "react-table";
import Label from "../../../../components/common/table/Label";
import { CellFactory } from "../../../../components/common/table/newTable/cells/CellsFactory";

import { VirtualInterface } from "../../../VirtualInterfacePage/types";
import { StateStatus } from "../../types/connections";
import { getIconAndText } from "../helpers/getConnectionType";
import { RemoteConfigButton } from "./RemoteConfigButton";
import { StateCell } from "./StateCell";

import styles from "./TableColumnsConfig.module.scss";
import LabelArray from "../../../../components/common/table/LabelArray";
import {
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
} from "../../../../helpers/validators/Validator";
import { ReactElement } from "react";
import { getBandwidthStringForTable } from "../../../../helpers/getBandwidthStringForTable";

const cellFactory = new CellFactory<VirtualInterface>({});

/* eslint-disable @typescript-eslint/no-unused-vars */

export function getConnectionNameDisplay(value: any): ReactElement {
  const [
    name,
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    is_dia,
  ] = value;
  const [icon, _] = getIconAndText(
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    undefined,
    undefined,
    is_dia
  );
  const isRemote = remote_type === "remote_users";
  const displayName = isRemote ? "Remote users" : name;
  return (
    <div className={styles.connectionName}>
      {icon}
      <span className={(isRemote && styles.remote) || ""}>{displayName}</span>
    </div>
  );
}

export function getConnectionTypeDisplay(value: any): ReactElement {
  const [
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    is_dia,
  ] = value;
  const [_, displayType] = getIconAndText(
    type,
    vit,
    remote_type,
    is_ipsec_service,
    is_nat_service,
    is_snat_service,
    undefined,
    undefined,
    is_dia
  );
  return <Label>{displayType || "-"}</Label>;
}

export const TableColumnsTenantConfig: Array<any> = [
  {
    accessor: (row: any) => [
      row.name,
      row.type,
      row?.virtual_interface_type,
      row?.labels?.remote_type,
      row?.is_ipsec_service,
      row?.is_nat_service,
      row?.is_snat_service,
      row?.is_dia,
    ],
    Header: "Name",
    Cell: ({ cell: { value } }: CellProps<any>) =>
      getConnectionNameDisplay(value),
  },
  {
    accessor: (row: any) => [
      row.type,
      row?.virtual_interface_type,
      row?.labels?.remote_type,
      row?.is_ipsec_service,
      row?.is_nat_service,
      row?.is_snat_service,
    ],
    Header: "Type",
    Cell: ({ cell: { value } }: CellProps<any>) =>
      getConnectionTypeDisplay(value),
  },
  {
    accessor: "ip_addresses",
    Header: "Interface IP",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      const ipv4 = value?.filter((val: string) => checkIPv4WithPrefix(val));
      const ipv6 = value?.filter((val: string) => checkIPv6WithPrefix(val));
      return (
        <div>
          <LabelArray values={ipv4} withWrap={true} />
          <LabelArray
            values={ipv6}
            singleLabelClassname={styles.ips}
            withWrap={true}
          />
        </div>
      );
    },
  },
  {
    accessor: "network_segments",
    Header: "Segments",
    Cell: cellFactory.labelArrayCell(""),
  },
  {
    accessor: "state",
    Header: "Admin/Oper State",
    Cell: ({
      cell: { value },
    }: {
      cell: {
        value: {
          administrative_state: StateStatus;
          operational_state: StateStatus;
        };
      };
    }) => {
      return <StateCell {...value} />;
    },
  },
  {
    Header: "Max Bandwidth",
    accessor: "max_bandwidth",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      return value ? getBandwidthStringForTable(value) : "Unlimited";
    },
  },
  {
    Header: "Customer Port",
    accessor: "customer_port_name",
  },
  {
    accessor: "description",
    Header: "Description",
  },
  {
    accessor: "remote_config",
    Header: "",
    Cell: (cell: any) => {
      const vi = cell.row.original;

      return <RemoteConfigButton vi={vi} />;
    },
  },
];

export const TableColumnsAdminConfig: Array<any> = [
  {
    accessor: (row: any) => [
      row.name,
      row.type,
      row?.virtual_interface_type,
      row?.labels?.remote_type,
      row?.is_ipsec_service,
      row?.is_nat_service,
      row?.is_snat_service,
      row?.is_dia,
    ],
    Header: "Name",
    Cell: ({ cell: { value } }: CellProps<any>) =>
      getConnectionNameDisplay(value),
  },
  {
    accessor: (row: any) => [
      row.type,
      row?.virtual_interface_type,
      row?.labels?.remote_type,
      row?.is_ipsec_service,
      row?.is_nat_service,
      row?.is_snat_service,
      row?.is_dia,
    ],
    Header: "Type",
    Cell: ({ cell: { value } }: CellProps<any>) =>
      getConnectionTypeDisplay(value),
  },
  {
    accessor: "ip_addresses",
    Header: "Interface IP",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      const ipv4 = value?.filter((val: string) => checkIPv4WithPrefix(val));
      const ipv6 = value?.filter((val: string) => checkIPv6WithPrefix(val));
      return (
        <div>
          <LabelArray values={ipv4} withWrap={true} />
          <LabelArray
            values={ipv6}
            singleLabelClassname={styles.ips}
            withWrap={true}
          />
        </div>
      );
    },
  },
  {
    accessor: "state",
    Header: "Admin/Oper State",
    Cell: ({
      cell: { value },
    }: {
      cell: {
        value: {
          administrative_state: StateStatus;
          operational_state: StateStatus;
        };
      };
    }) => {
      return <StateCell {...value} />;
    },
  },
  {
    Header: "Max Bandwidth",
    accessor: "max_bandwidth",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      return value ? getBandwidthStringForTable(value) : "Unlimited";
    },
  },
  {
    Header: "Customer Port",
    accessor: "customer_port_name",
  },
  {
    accessor: "description",
    Header: "Description",
  },
  {
    accessor: "remote_config",
    Header: "",
    Cell: (cell: any) => {
      const vi = cell.row.original;

      return <RemoteConfigButton vi={vi} />;
    },
  },
];
