import { FC, useMemo, useState } from "react";
import { useTimerangeContext } from "../../../../../contexts/TimerangeContext";
import GrafanaGraph from "../../../../../components/common/charts/GrafanaGraph";
import { GTimeRange } from "../../../../../components/common/charts/GraphanaLinksFactory";
import { TrafficType } from "../../../../../helpers/types";
import ChartToppersTabs from "../../../../../components/common/ChartToppersTabs";
import { getPanelIdByTimerange } from "../../../../../helpers/getPanelIdByTimerange";
import { BPS } from "../../../../../helpers/common/constantsAlias";

const OverallCharts: FC = () => {
  const { timeRange } = useTimerangeContext();
  const [activeTraffic, setActiveTraffic] = useState<TrafficType>(BPS);
  const isBPS = activeTraffic === BPS;

  const bpsPanel = useMemo(
    () => getPanelIdByTimerange(timeRange.general, "inOutGeneralBPS"),
    [timeRange]
  );
  const ppsPanel = useMemo(
    () => getPanelIdByTimerange(timeRange.general, "inOutGeneralPPS"),
    [timeRange]
  );

  return (
    <>
      <div>
        <ChartToppersTabs
          activeTab={activeTraffic}
          setTabActive={setActiveTraffic}
        />
        <GrafanaGraph
          link={getLink(
            timeRange.general,
            activeTraffic === BPS ? bpsPanel : ppsPanel
          )}
        />
      </div>
      <GrafanaGraph link={getLink(timeRange.general, isBPS ? 146 : 147)} />
      <GrafanaGraph link={getLink(timeRange.general, isBPS ? 148 : 149)} />
    </>
  );
};
export default OverallCharts;

const getLink = (timeRange: GTimeRange, panel: number): string => {
  return `/broker/grafana/d-solo/gui_graphs/gui-graphs?orgId=1&from=${timeRange.from}&to=${timeRange.to}&panelId=${panel}&theme=light`;
};
