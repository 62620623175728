import { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function FiberIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M10.9243 21.1489L14.4673 17.6059L7.90625 11.0449L4.3633 14.5879"
        stroke={color || "#59536B"}
        strokeWidth="1.4"
      />
      <path
        d="M19.6176 14.0961C17.4373 13.8613 15.3202 13.6697 13.2534 16.2613"
        stroke={color || "#59536B"}
      />
      <path
        d="M10.8917 7.92883C11.0886 9.6347 10.2356 11.1437 9.25146 12.3247"
        stroke={color || "#59536B"}
      />
      <path
        d="M15.7136 5.46753C15.0825 8.18254 12.8231 11.1605 10.5304 13.6688"
        stroke={color || "#59536B"}
      />
      <path
        d="M18.7981 9.07542C16.1081 10.2564 13.8445 12.7168 11.9418 15.0788"
        stroke={color || "#59536B"}
      />
      <circle
        cx="18.9277"
        cy="8.94559"
        r="1.07737"
        transform="rotate(45 18.9277 8.94559)"
        fill={color || "#59536B"}
        stroke={color || "#59536B"}
      />
      <circle
        cx="19.6504"
        cy="14.1292"
        r="1.07737"
        transform="rotate(45 19.6504 14.1292)"
        fill={color || "#59536B"}
        stroke={color || "#59536B"}
      />
      <circle
        cx="15.9102"
        cy="4.22098"
        r="1.07737"
        transform="rotate(45 15.9102 4.22098)"
        fill={color || "#59536B"}
        stroke={color || "#59536B"}
      />
      <circle
        cx="10.793"
        cy="6.45145"
        r="1.07737"
        transform="rotate(45 10.793 6.45145)"
        fill={color || "#59536B"}
        stroke={color || "#59536B"}
      />
    </svg>
  );
}

export default FiberIcon;
