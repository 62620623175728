import { CellProps } from "react-table";
import CloudProviderIcon from "../../../../components/icons/CloudProviderIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import LocationFillIcon from "../../../../components/icons/LocationFillIcon";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../TableHeaders.module.scss";
import { getPercentageOfTotal } from "../../../../helpers/common/numberHelpers";
import { getColorClassByPercentage } from "../../helpers";
import { AwsNNI, RegionItem } from "../../types";
import { getBandwidthStringForTable } from "../../../../helpers/getBandwidthStringForTable";
import { AWS_LOWERCASE } from "../../../../helpers/consts";

const IconColumn = {
  Header: "Cloud Provider",
  accessor: "",
  Cell: () => {
    return (
      <CloudProviderIcon
        id={AWS_LOWERCASE}
        color={GREY_FONT}
        height={28}
        width={28}
      />
    );
  },
};

export const awsNNITableHeader = () =>
  [
    IconColumn,
    {
      Header: "Region",
      accessor: "aws_region",
      Cell: ({ cell: { value } }: CellProps<AwsNNI>) => {
        return (
          <div className={styles.region}>
            <LocationFillIcon color={GREY_FONT} />
            {value}
          </div>
        );
      },
    },
    {
      Header: "Site Name",
      accessor: "site_name",
    },
    {
      Header: "NNI Id",
      accessor: "nni_id",
    },
    {
      Header: "Link Capacity",
      accessor: "aws_bandwidth",
      Cell: ({ cell: { value } }: CellProps<AwsNNI>) => {
        return getBandwidthStringForTable(value);
      },
    },
    {
      Header: "Provisioned Capacity",
      accessor: (row: any) => [row.aws_bandwidth, row.aws_used_bandwidth],
      Cell: ({ cell: { value } }: CellProps<AwsNNI>) => {
        const [bw, usedBW] = value;
        const usedBWLabel = getBandwidthStringForTable(usedBW);
        const percentage = getPercentageOfTotal(usedBW, bw);
        return (
          <div className={getColorClassByPercentage(percentage)}>
            {usedBWLabel} ({percentage}%)
          </div>
        );
      },
    },
    {
      Header: "System name",
      accessor: "system_name",
    },
    {
      Header: "Port name",
      accessor: "physical_port_name",
    },
    {
      Header: "Connections",
      accessor: "connections_count",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof AwsNNI,
  }));

export const awsRegionsTableHeader = () =>
  [
    IconColumn,
    {
      Header: "Region",
      accessor: "region",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return (
          <div className={styles.region}>
            <LocationFillIcon color={GREY_FONT} />
            {value}
          </div>
        );
      },
    },
    {
      Header: "NNIs",
      accessor: "nniCount",
    },
    {
      Header: "Link Capacity",
      accessor: "totalCapacity",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return getBandwidthStringForTable(value);
      },
    },
    {
      Header: "Provisioned Capacity",
      accessor: (row: RegionItem) => [
        row.totalCapacity,
        row.provisionedCapacity,
      ],
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        const [bw, usedBW] = value;
        const usedBWLabel = getBandwidthStringForTable(usedBW);
        const percentage = getPercentageOfTotal(usedBW, bw);
        return (
          <div className={getColorClassByPercentage(percentage)}>
            {usedBWLabel} ({percentage}%)
          </div>
        );
      },
    },
    {
      Header: "Connections",
      accessor: "connections",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return value;
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof RegionItem,
  }));
