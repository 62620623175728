import React, { FC, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import AddButton from "../../../../components/common/buttons/AddButton";
import Table from "../../../../components/common/table/Table";
import ConfirmDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import NatConfigurationContext, {
  useNatContext,
} from "../../../../contexts/servicesContext/NatConfigurationContext";
import TenantVirtualInterfaceContextContainer from "../../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import { NatRuleType } from "../../../../helpers/api/apiTypes";
import { ADD, EDIT } from "../../../../helpers/common/constantsAlias";
import { getDeleteDialogControls } from "../../../../helpers/getDialogControls";
import getRowControls from "../../../../helpers/getRowControls";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { DialogTitle, PageProps } from "../../../../helpers/types";
import styles from "./NATRulesOverview.module.scss";
import { natRulesTableColumns } from "./table/natRulesTableColumns";
import TenantVIContextContainer from "../../../../contexts/tenantsContext/TenantVIContext";
import NATRuleConfiguration from "../NATRuleConfiguration";
import { REPORTS, SERVICES_NAT } from "../../../../helpers/navigation/entries";
import { ACTIVE_NAT_QUERY_PARAM } from "../../../../helpers/navigation";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";

const NATRulesPage: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    natRulesStatus,
    natRulesList,
    fetchNatRulesList,
    isUpdateNatRulesList,
    setIsUpdateNatRulesList,
    deleteNatRule,
  } = useNatContext();
  const { selectedTenant } = useGlobalFilterContext();

  const history = useHistory();

  const [natListData, setNatListData] = useState<Array<NatRuleType>>([]);
  const [selectRule, setSelectRule] = useState<NatRuleType | undefined>();

  const [dialogMode, setDialogMode] = useState<DialogTitle>(ADD);
  const [isConfigMode, setIsConfigMode] = useState(false);
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (selectedTenant) {
      fetchNatRulesList(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (natRulesList) {
      setNatListData(natRulesList);
    }
  }, [natRulesList]);

  useEffect(() => {
    if (isUpdateNatRulesList) {
      fetchNatRulesList(selectedTenant as string);
      setIsUpdateNatRulesList(false);
    }
  }, [isUpdateNatRulesList]);

  const handleOpenDialog = (param: DialogTitle): void => {
    setDialogMode(param);
    setIsConfigMode(true);
  };

  const handleDeleteNatRule = async () => {
    if (selectRule && selectRule.name) {
      const successAdd = await deleteNatRule(
        selectedTenant as string,
        selectRule.name
      );
      if (successAdd) {
        setIsDeleteDialogShown(false);
        setSelectRule(undefined);
      }
    }
  };

  const handleEdit = (rowOriginal: any) => {
    handleOpenDialog(EDIT);
    setSelectRule(rowOriginal);
  };

  const renderControls = useMemo(
    () =>
      getRowControls({
        onEdit: (rowOriginal) => {
          handleEdit(rowOriginal);
        },
        onDelete: (rowOriginal) => {
          setIsDeleteDialogShown(true);
          setSelectRule(rowOriginal);
        },
        onDiveIn: (rowOriginal) => {
          history.push(
            REPORTS().path + `?${ACTIVE_NAT_QUERY_PARAM}=${rowOriginal.name}`
          );
        },
      }),
    []
  );

  if (isConfigMode) {
    return (
      <NATRuleConfiguration
        onClose={() => setIsConfigMode(false)}
        fetchData={fetchNatRulesList}
        data={selectRule}
      />
    );
  }

  return (
    <>
      {isDeleteDialogShown && (
        <ConfirmDialog
          icon={DeleteIcon}
          title="Delete NAT Rule"
          message={`Are you sure you want to delete Rule?`}
          controls={getDeleteDialogControls({
            onDelete: handleDeleteNatRule,
            onCancel: () => setIsDeleteDialogShown(false),
          })}
        />
      )}
      <div className={styles.contentWrapper}>
        <Tabs tabs={[SERVICES_NAT()]} />
        <ServicesRedirectPlaceholder type="nat">
          <Table
            columns={natRulesTableColumns}
            data={natListData}
            gridColumnTemplate="100px 150px 150px repeat(3, 1fr) 0px"
            header="Network Address Translation"
            headerControls={<AddButton onClick={() => handleOpenDialog(ADD)} />}
            dataStatus={natRulesStatus}
            controls={renderControls}
            onClick={handleEdit}
            withoutPagination
          />
        </ServicesRedirectPlaceholder>
      </div>
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(NATRulesPage, [
    TenantVirtualInterfaceContextContainer,
    NatConfigurationContext,
    TenantVIContextContainer,
  ])
);
