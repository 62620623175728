import React, { FC, useMemo } from "react";
import {
  PACKAGES,
  PROVISIONING,
  TENANTS,
  USERS,
  USER_GROUPS,
  ORDERED_PORTS,
  SERVICE_CATALOG,
} from "../../../../helpers/navigation/entries";
import BasicTabs from "../BasicTabs/BasicTabs";
import { useUserContext } from "../../../../contexts/UserContext";

type Props = {
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  defaultTenants?: Array<any>;
  remoteUsersRoot?: Array<any>;
};

export const TenantsTab: FC<Props> = ({
  showTimeRange,
  noConfig,
  noReports,
  defaultTenants,
  remoteUsersRoot,
}) => {
  const { isZayo } = useUserContext();
  // remoteUsersRoot is a special indicator that it is remote users page
  const tabs = useMemo(() => {
    if (isZayo && remoteUsersRoot) {
      return [USER_GROUPS()];
    }

    return remoteUsersRoot
      ? [PROVISIONING(), USER_GROUPS()]
      : [
          PROVISIONING(),
          USERS(),
          USER_GROUPS(),
          SERVICE_CATALOG(),
          PACKAGES(),
          ORDERED_PORTS(),
        ];
  }, [remoteUsersRoot]);

  return (
    <BasicTabs
      showTimeRange={showTimeRange}
      noConfig={noConfig}
      noReports={noReports}
      tabs={tabs}
      root={remoteUsersRoot || [TENANTS()]}
      defaultTenants={defaultTenants}
    />
  );
};
