import React, { FC } from "react";
import AppButtons from "./components/Buttons/AppButtons";
import styles from "./ApplicationsStatistics.module.scss";
import LeftPart from "./components/LeftPart/LeftPart";
import BtmPart from "./components/BtmPart/BtmPart";
import RightPart from "./components/RightPart/RightPart";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../components/common/charts/GrafanaContainer/GrafanaContainer";

const ApplicationsStatistics: FC = () => {
  return (
    <ServicesRedirectPlaceholder type="dpi">
      <GrafanaContainer type={GRAFANA_TYPES.DPI} className={styles.wrapper}>
        <div className={styles.container}>
          <AppButtons className={styles.buttons} />
          <LeftPart className={styles.appCharts} />
          <RightPart className={styles.topAppDonut} />
          <BtmPart className={styles.topAppTable} />
        </div>
      </GrafanaContainer>
    </ServicesRedirectPlaceholder>
  );
};

export default ApplicationsStatistics;
