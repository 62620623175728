import {
  VLAN_OFFSET_MAX,
  VLAN_OFFSET_MIN,
  VLAN_OFFSET_ZERO,
} from "../../pages/CloudResourcesPage/components/AddNNIDialog/components/AzureForm/constants";
import { CreateAzureNNI } from "../../pages/CloudResourcesPage/types";
import Validator from "./Validator";

export default function validateAzureNNI(maxBandwidth: number | undefined) {
  return (
    data: Partial<CreateAzureNNI>,
    initValidator?: Validator
  ): Validator => {
    const validator = initValidator || new Validator();
    validator.checkEmpty(data.nni_id, "nni_id");
    validator.checkEmpty(
      data.primary_physical_port_name,
      "primary_physical_port_name"
    );
    validator.checkEmpty(data.primary_system_name, "primary_system_name");
    validator.checkEmpty(data.max_bandwidth, "max_bandwidth");
    validator.checkEmpty(data.peering_location, "peering_location");
    validator.checkEmpty(data.s_vlan_offset?.toString(), "s_vlan_offset");

    if (Number(data.s_vlan_offset) !== VLAN_OFFSET_ZERO) {
      validator.checkMinMax(
        data.s_vlan_offset,
        [VLAN_OFFSET_MIN, VLAN_OFFSET_MAX],
        "s_vlan_offset"
      );
    }

    if (maxBandwidth !== undefined) {
      validator.checkMinMax(
        data.max_bandwidth,
        [0, maxBandwidth],
        "max_bandwidth"
      );
    }

    if (data.secondary_system_name || data.secondary_physical_port_name) {
      validator.checkEmpty(data.secondary_system_name, "secondary_system_name");
      validator.checkEmpty(
        data.secondary_physical_port_name,
        "secondary_physical_port_name"
      );
    }

    if (
      data.primary_system_name === data.secondary_system_name &&
      data.primary_physical_port_name &&
      data.secondary_physical_port_name
    ) {
      validator.checkTrue(
        data.primary_physical_port_name !== data.secondary_physical_port_name,
        "secondary_physical_port_name",
        "Secondary Port should not be equal to Primary"
      );
    }

    return validator;
  };
}
