import React, { FC, useEffect, useState } from "react";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { OptionalLabel } from "../OptionalLabel";
import LabelArray from "../table/LabelArray";
import DropdownInput from "./components/DropdownInput";
import DropdownList from "./components/DropdownList";
import Error from "./components/Error";
import styles from "./DropdownAutocomplete.module.scss";
import { DropdownItem } from "../../../helpers/types";
import { isNotEmpty } from "../../../helpers/common/arrayHelpers";
import { classNames } from "../../../helpers/common/classNames";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside";

type DropdownAutocompleteProps = {
  selected: DropdownItem;
  valuesList: Array<string>;
  currentValues: Array<string>;
  onChange: (arg: Array<string>) => void;
  placeholder: string;
  error?: string;
  isMedium: boolean;
  label?: string;
  createText?: string;
  onCreate?: () => void;
  isPlaceholderAlwaysVisible?: boolean;
  className?: string;
  id: string;
  listClassName?: string;
  emptyListPlaceholder?: string;
};

const DropdownAutocomplete: FC<DropdownAutocompleteProps> = ({
  selected,
  valuesList,
  currentValues,
  onChange,
  placeholder,
  error,
  isMedium,
  label,
  createText,
  onCreate,
  isPlaceholderAlwaysVisible,
  className,
  id,
  listClassName,
  emptyListPlaceholder,
}) => {
  const [isListShown, setIsListShown] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string | undefined>("");

  const [activeValue, setActiveValue] = useState<number>(0);
  const [filteredValues, setFilteredValues] = useState<Array<string>>([]);

  const [selectedValues, setSelectedValues] = useState<
    Array<string> | undefined
  >(currentValues);

  const dropDownRef = useClickOutside<HTMLDivElement>(() =>
    setIsListShown(false)
  );

  useEffect(() => {
    if (selectedValues) {
      handleSetValues();
    }
  }, [selectedValues]);

  const handleSetValues = () => {
    const newFilteredValues: Array<string> = valuesList.filter(
      (value: string) => !selectedValues?.includes(value)
    );
    setFilteredValues(newFilteredValues);
    selectedValues && onChange(selectedValues);
  };

  const handleClick = () => {
    handleSetValues();
    setIsListShown((prev) => !prev);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value;
    const newFilteredValues: Array<string> = valuesList.filter(
      (value: any) =>
        value.toLowerCase().includes(inputValue.toLowerCase()) &&
        !selectedValues?.includes(value)
    );
    setFilteredValues(newFilteredValues);
    setActiveValue(0);
    setIsListShown(true);
    setInputValue(inputValue);
  };

  const handleSelect = (value: { key: string }) => {
    setActiveValue(0);
    setFilteredValues([]);
    setInputValue("");
    setSelectedValues((prev) => (prev ? [...prev, value.key] : [value.key]));
    setIsListShown(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === "13") {
      // enter key
      setActiveValue(0);
      setIsListShown(false);
      setInputValue(filteredValues[activeValue]);
    } else if (e.key === "38") {
      // up arrow
      return activeValue === 0 ? null : setActiveValue(activeValue - 1);
    } else if (e.key === "40") {
      // down arrow
      return activeValue - 1 === filteredValues.length
        ? null
        : setActiveValue(activeValue + 1);
    }
  };

  const handleRemoveSelectedItem = (value: string) => {
    setSelectedValues((prev) =>
      prev?.filter((item) => item.toString() !== value)
    );
  };

  useEffect(() => {
    if (filteredValues?.length === 0) {
      const hideList = setTimeout(() => {
        setIsListShown(false);
      }, 2000);
      return () => {
        clearTimeout(hideList);
      };
    }
  }, [filteredValues]);

  return (
    <div ref={dropDownRef} className={classNames(styles.wrapper, className)}>
      <OptionalLabel
        className={styles.dropDownLabel}
        isShown={Boolean(label)}
        text={label}
      >
        <div data-cy="input-wrapper" style={{ width: "100%" }}>
          <DropdownInput
            id={id}
            isAutocompleteField
            isMedium={isMedium}
            selected={selected}
            isListShown={isListShown}
            onClick={handleClick}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            value={inputValue}
            placeholder={placeholder}
            isPlaceholderAlwaysVisible={isPlaceholderAlwaysVisible}
            isError={Boolean(error)}
          />
          {error && <Error error={error} />}
          {isNotEmpty(selectedValues) && (
            <LabelArray
              values={selectedValues}
              className={styles.selectedItems}
              isRemovable
              onClick={handleRemoveSelectedItem}
              withWrap
            />
          )}
        </div>
      </OptionalLabel>
      {isListShown && (
        <DropdownList
          className={listClassName || styles.list}
          handleSelect={handleSelect}
          isListShown={isListShown}
          valuesList={
            isNotEmpty(filteredValues)
              ? filteredValues.map(mapStringToItem)
              : []
          }
          isMedium={isMedium}
          createText={createText}
          onCreate={onCreate}
          setIsListShown={setIsListShown}
          emptyListPlaceholder={emptyListPlaceholder}
        />
      )}
    </div>
  );
};

export default DropdownAutocomplete;
