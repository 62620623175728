import React from "react";
import styles from "./DNSResolvers.module.scss";
import LoaderIcon from "../../../../../components/common/loadStates/LoaderIcon";
import CopyIcon from "../../../../../components/icons/CopyIcon";

interface DNSResolversProps {
  resolversipv4: Array<string>;
  resolversipv6: Array<string>;
}

const DNSResolvers: React.FC<DNSResolversProps> = ({
  resolversipv4,
  resolversipv6,
}) => {
  const handleCopy = (value: string) => {
    if (value) {
      navigator.clipboard.writeText(value);
    }
  };

  return (
    <div className={styles.dnsResolversPopup}>
      {!resolversipv4.length && !resolversipv6.length ? (
        <div className={styles.flexCentered}>
          <LoaderIcon />
        </div>
      ) : (
        <table className={styles.dnsResolversTable}>
          <thead>
            <tr>
              <th>IPv4</th>
              <th>IPv6</th>
            </tr>
          </thead>
          <tbody>
            {Array.from({
              length: Math.max(resolversipv4.length, resolversipv6.length),
            }).map((_, index) => (
              <tr key={index}>
                <td className={styles.ipv4Item}>
                  {resolversipv4[index] || "-"}
                </td>
                <td className={styles.ipv6Item}>
                  <div className={styles.ipv6Container}>
                    <span>{resolversipv6[index] || "-"}</span>
                    {resolversipv6[index] && (
                      <button
                        className={styles.copyButton}
                        onClick={() => handleCopy(resolversipv6[index])}
                      >
                        {<CopyIcon />}
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DNSResolvers;
