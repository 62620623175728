import React, { FC, ReactElement } from "react";
import { useTutorialContext } from "../../../../../contexts/TutorialContext";
import { useUserContext } from "../../../../../contexts/UserContext";
import { IconProps } from "../../../../../helpers/types";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import ReleaseNotesIcon from "../../../../icons/ReleaseNotesIcon";
import TutorialsIcon from "../../../../icons/TutorialsIcon";
import UserGuideIcon from "../../../../icons/UserGuideIcon";
import styles from "./Documentation.module.scss";

type Props = {};

const Documentation: FC<Props> = () => {
  const { isTenant, isZayo } = useUserContext();
  return (
    <div className={styles.wrapper}>
      {getDocumentations(isTenant, isZayo).map((doc) => {
        if (doc.onClick) {
          return (
            <div className={styles.link} onClick={doc.onClick} key={doc.label}>
              <IconBlankButton
                id={"documentation"}
                icon={doc.icon}
                label={doc.label}
                className={styles.btn}
              />
            </div>
          );
        } else {
          return (
            <a
              key={doc.label}
              href={doc.link}
              target={"_blank"}
              className={styles.link}
              rel="noreferrer"
            >
              <IconBlankButton
                id={"documentation"}
                icon={doc.icon}
                label={doc.label}
                className={styles.btn}
              />
            </a>
          );
        }
      })}
    </div>
  );
};

export default Documentation;

const getDocumentations = (
  isTenant: boolean,
  isZayo?: boolean
): Array<{
  link?: string;
  icon: (props: IconProps) => ReactElement;
  label: string;
  onClick?: () => void;
}> => {
  const { showTutorial } = useTutorialContext();
  const tutorialLink = isZayo
    ? "https://app.storylane.io/share/oiyl8aug5zfh"
    : "https://app.storylane.io/share/r9mi9ymsj7n0";
  const additionalLinks = isZayo
    ? [
        {
          link: "https://partners.insidepacket.com/videos",
          icon: UserGuideIcon,
          label: "How-To Videos",
        },
      ]
    : [];
  if (isTenant) {
    return [
      {
        link: tutorialLink,
        icon: TutorialsIcon,
        label: "Tutorials",
        onClick: () => showTutorial(tutorialLink),
      },
      ...additionalLinks,
    ];
  }
  return [
    {
      link: tutorialLink,
      icon: TutorialsIcon,
      label: "Tutorials",
      onClick: () => showTutorial(tutorialLink),
    },
    ...additionalLinks,
    {
      link:
        "https://drive.google.com/file/d/131K0_TCdmNGoeOhzp2rbw6TXuvy0HwuW/view",
      icon: UserGuideIcon,
      label: "User guide",
    },
    {
      link:
        "https://drive.google.com/file/d/13ECD5yq3KpQrUJbXCmxj1WEQYi9e2kca/view",
      icon: ReleaseNotesIcon,
      label: "Release notes",
    },
  ];
};
