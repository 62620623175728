import * as React from "react";
import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import {
  getLocationPosition,
  STAR_WIDTH,
} from "../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/utils";
import CloudCircle from "./icons/CloudCircle";
import { getIconAndText } from "../../../Connections/helpers/getConnectionType";
import CloudCenter from "./icons/CloudCenter";
import InterfaceConnectorWithLabel from "../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/parts/InterfaceConnectorWithLabell";
import { parseVlan } from "./CloudsTabInfo";
import { useUserContext } from "../../../../../contexts/UserContext";
import { checkIfInternet } from "../../helpers";

const center = {
  x: STAR_WIDTH / 2,
  y: 200,
};

export default function Component(
  connections: Array<VirtualInterfaceApi>,
  onClick: (name: string) => void,
  activeConnection: string
) {
  const { isZayo } = useUserContext();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      viewBox={`0 0 ${STAR_WIDTH} 400`}
      style={{ margin: "0.5rem", height: "400px" }}
    >
      {connections.map((connection, idx) => {
        const position = getLocationPosition(
          connections.length,
          idx,
          5,
          2,
          center
        );
        const isInternet = checkIfInternet(connection);
        const [icon, _] = getIconAndText(
          connection.type,
          connection.virtual_interface_type,
          connection?.labels?.remote_type,
          connection.is_ipsec_service,
          connection.is_nat_service,
          connection.is_snat_service,
          position.x - 19,
          position.y - 19,
          connection.is_dia,
          isInternet
        );
        const { vlan_id, max_bandwidth } = parseVlan(connection);
        const location = isInternet ? "Internet" : connection.name;
        const connectorLabel = isInternet
          ? undefined
          : `BW ${max_bandwidth || "-"}`;
        return (
          <>
            <InterfaceConnectorWithLabel
              from={center}
              to={position}
              key={connection.name}
              active={activeConnection === connection.name}
              label={connectorLabel}
              labelWidth={100}
              labelHeight={16}
              additional={connection.ip_addresses?.[0]}
            />
            <CloudCircle
              {...position}
              key={connection.name}
              onClick={() => onClick(connection.name)}
              location={location}
              icon={icon}
              active={activeConnection === connection.name}
              vlan={vlan_id}
            />
          </>
        );
      })}
      <CloudCenter
        location={"Cloud Router"}
        x={center.x}
        y={center.y}
        active={!!activeConnection}
        isZayo={isZayo}
      />
    </svg>
  );
}
