import React, { FC, useEffect, useMemo } from "react";
import Input from "../../../../../../../../components/common/formComponents/Input";
import BWDropdown from "../../../../common/formComponents/BWDropdown";
import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
import styles from "./DIAItem.module.scss";
import { mapStringToItem } from "../../../../../../../../helpers/mapStringToItem";
import DropdownBasic from "../../../../../../../../components/common/Dropdown/DropdownBasic";
import ConnectionsContextContainer, {
  useConnectionsContext,
} from "../../../../../../../WizardToolPage/ConnectionStep/ConnectionsContext";
import { DropdownItem } from "../../../../../../../../helpers/types";
import { withContexts } from "../../../../../../../../helpers/hocs/withContexts";
import { useGlobalFilterContext } from "../../../../../../../../contexts/GlobalFilterContext";
import { TenantCustomerPort } from "../../../../../../../Network/UnitedConnections/types";
import TenantVirtualInterfaceContextContainer, {
  useTenantVirtualInterfaceContext,
} from "../../../../../../../../contexts/tenantsContext/TenantVirtualInterfaceContext";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: () => void;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
  selectedPort?: TenantCustomerPort;
  disabled?: boolean;
};

const DIAConnectionItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  handleChangeBW,
  disabled,
}) => {
  const { diaList, fetchDIAList } = useConnectionsContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { membersList, fetchMembersList } = useTenantVirtualInterfaceContext();

  const filteredDia = diaList?.filter(
    (dia) => dia.is_dia && dia.location === fields.availableLocation
  );

  // if we already have a connection with the same name in the list -> remove from the list
  const names = useMemo(() => {
    return (
      filteredDia
        ?.filter((dia) => {
          return !membersList?.some(
            (member) => member.virtual_interface_name === dia.name
          );
        })
        .map((dia) => dia.name) || []
    );
  }, [filteredDia, membersList]);

  useEffect(() => {
    if (!diaList?.length) {
      selectedTenant && fetchDIAList(selectedTenant);
    }
    if (!membersList?.length) {
      selectedTenant && fetchMembersList(selectedTenant);
    }
  }, [selectedTenant]);

  const handleDiaSelect = (val: DropdownItem) => {
    onChange("name", val.key);
  };

  const subnet = useMemo(() => {
    return (
      filteredDia?.find((dia) => dia.name === fields.name)?.ip_address || ""
    );
  }, [fields.name, filteredDia]);

  return (
    <ItemContainer
      type={type}
      title={""}
      idx={idx}
      onDelete={onDelete}
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      <DropdownBasic
        id={"name"}
        onChange={handleDiaSelect}
        selected={mapStringToItem(fields.name)}
        label="DIA Name"
        itemsList={names.map(mapStringToItem)}
        isMedium
        disabled={isEdit || disabled}
        error={errors?.[idx + "name"]}
        listClassName={styles.list}
      />
      <Input
        label="Subnet"
        name="subnet"
        value={subnet}
        isPlaceholderAlwaysVisible
        medium
        disabled={disabled}
        isNotEditable
        onChange={(e) => {
          console.log(e.target.value);
        }}
      />
      <BWDropdown
        field={fields.max_bandwidth}
        onChange={handleChangeBW}
        error={errors?.[idx + "max_bandwidth"]}
        idx={idx}
        disabled={isEdit || disabled}
      />
    </ItemContainer>
  );
};

export default withContexts<Props>(DIAConnectionItem, [
  ConnectionsContextContainer,
  TenantVirtualInterfaceContextContainer,
]);
