import React, { FC } from "react";
import PhysicalPortsDropdown from "../../common/OneSystem/PhysicalPortsDropdown";
import { PortType } from "../../../pages/PortsPage/types";
import DropdownWithSearch from "../../common/Dropdown/DropdownWithSearch";
import { mapStringToItem } from "../../../helpers/mapStringToItem";

type Props = {
  system: string;
  isEdit: boolean;
  fieldProps: any;
  fields: { [key: string]: any };
  onChange: (fields: string, value: string | number) => void;
  error?: string;
  errorVlanId?: string;
  vlanIdOptions?: Array<string>;
  setSelectedPort?: (port: PortType | undefined) => void;
};

const VlanFields: FC<Props> = ({
  isEdit,
  fieldProps,
  fields,
  onChange,
  system,
  error,
  errorVlanId,
  setSelectedPort,
  vlanIdOptions = [],
}) => {
  return (
    <>
      <PhysicalPortsDropdown
        id={"vlanFieldsPortName"}
        selected={fields.physical_port_name}
        onSelect={(val) => onChange("physical_port_name", val)}
        system={system}
        error={error}
        setSelectedPort={setSelectedPort}
      />
      <DropdownWithSearch
        id="VLAN ID"
        {...fieldProps("vlan_id")}
        label="VLAN ID"
        isNotEditable={isEdit}
        placeholder={fields.vlan_id}
        onChange={(val) => onChange("vlan_id", val.key ?? val)}
        selected={mapStringToItem(fields.vlan_id)}
        error={errorVlanId}
        valuesList={vlanIdOptions}
        isMedium
      />
    </>
  );
};
export default VlanFields;
