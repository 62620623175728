import CaretBig from "../../../../components/icons/CaretBig";
import { ACCENT, BORDER_COLOR } from "../../../../helpers/common/colorAliases";
import BlankButton from "../../../../components/common/buttons/BlankButton";
import styles from "./SelectRowComponent.module.scss";

type Props<T = string> = {
  onSelect: (row: T) => void;
  selected?: T;
  currentRow: T;
  withPropaganation?: boolean;
};

const SelectRow = <T,>({
  onSelect,
  selected,
  currentRow,
  withPropaganation,
}: Props<T>) => {
  const isActive = selected === currentRow;
  const isAll =
    (typeof currentRow === "string" && currentRow === "all") ||
    currentRow === undefined;
  return (
    <BlankButton
      id={"selected" + currentRow}
      onClick={(e) => {
        !withPropaganation && e.stopPropagation();
        onSelect(currentRow);
      }}
      className={styles.wrapper}
    >
      {isAll && <span>All</span>}
      <CaretBig
        color={isActive ? ACCENT : BORDER_COLOR}
        className={styles.icon}
      />
    </BlankButton>
  );
};

export default SelectRow;
