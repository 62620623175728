import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function DIAConnectionIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.71436 4.28516C1.60979 4.28516 0.714355 5.18059 0.714355 6.28516V13.7137C0.714355 14.8183 1.60979 15.7137 2.71436 15.7137H17.2858C18.3904 15.7137 19.2858 14.8183 19.2858 13.7137V6.28516C19.2858 5.18059 18.3904 4.28516 17.2858 4.28516H2.71436ZM15.8823 11.993L16.3629 13.5709H17.8772L15.5286 6.9149H13.8102L11.4706 13.5709H12.985L13.4656 11.993H15.8823ZM14.7941 8.38846C14.8515 8.57587 15.1024 9.38444 15.5467 10.8142H13.8193C14.2908 9.28771 14.5765 8.33103 14.6762 7.94413C14.7004 8.05294 14.7397 8.20106 14.7941 8.38846ZM7.857 10.193C7.857 11.2842 7.54567 12.12 6.92299 12.7003C6.30334 13.2807 5.40711 13.5709 4.23431 13.5709H2.35721V6.9421H4.43834C5.52046 6.9421 6.36077 7.22775 6.95926 7.79904C7.55776 8.37033 7.857 9.16832 7.857 10.193ZM6.39704 10.2293C6.39704 8.80559 5.76832 8.09375 4.51088 8.09375H3.76277V12.4102H4.36579C5.71996 12.4102 6.39704 11.6832 6.39704 10.2293ZM9.23082 13.5709V6.9421H10.6364V13.5709H9.23082Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default DIAConnectionIcon;
