import React, { ReactElement } from "react";

function EmptyPortsPlaceholderIcon(): ReactElement {
  return (
    <svg
      width="170"
      height="170"
      viewBox="0 0 170 170"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4316 42.2656C37.1179 42.2656 34.4316 44.9519 34.4316 48.2656V87.6074C34.4316 90.9211 37.1179 93.6074 40.4316 93.6074H51.7988C52.9034 93.6074 53.7988 94.5028 53.7988 95.6074V114.506C53.7988 117.82 56.4851 120.506 59.7988 120.506H110.204C113.518 120.506 116.204 117.82 116.204 114.506V95.6074C116.204 94.5028 117.099 93.6074 118.204 93.6074H129.571C132.885 93.6074 135.571 90.9211 135.571 87.6074V48.2656C135.571 44.9519 132.885 42.2656 129.571 42.2656H40.4316Z"
        fill="white"
      />
      <path
        d="M35.4316 48.2656C35.4316 45.5042 37.6702 43.2656 40.4316 43.2656V41.2656C36.5656 41.2656 33.4316 44.3996 33.4316 48.2656H35.4316ZM35.4316 87.6074V48.2656H33.4316V87.6074H35.4316ZM40.4316 92.6074C37.6702 92.6074 35.4316 90.3688 35.4316 87.6074H33.4316C33.4316 91.4734 36.5657 94.6074 40.4316 94.6074V92.6074ZM51.7988 92.6074H40.4316V94.6074H51.7988V92.6074ZM54.7988 114.506V95.6074H52.7988V114.506H54.7988ZM59.7988 119.506C57.0374 119.506 54.7988 117.268 54.7988 114.506H52.7988C52.7988 118.372 55.9328 121.506 59.7988 121.506V119.506ZM110.204 119.506H59.7988V121.506H110.204V119.506ZM115.204 114.506C115.204 117.268 112.965 119.506 110.204 119.506V121.506C114.07 121.506 117.204 118.372 117.204 114.506H115.204ZM115.204 95.6074V114.506H117.204V95.6074H115.204ZM129.571 92.6074H118.204V94.6074H129.571V92.6074ZM134.571 87.6074C134.571 90.3688 132.332 92.6074 129.571 92.6074V94.6074C133.437 94.6074 136.571 91.4734 136.571 87.6074H134.571ZM134.571 48.2656V87.6074H136.571V48.2656H134.571ZM129.571 43.2656C132.332 43.2656 134.571 45.5042 134.571 48.2656H136.571C136.571 44.3996 133.437 41.2656 129.571 41.2656V43.2656ZM40.4316 43.2656H129.571V41.2656H40.4316V43.2656ZM117.204 95.6074C117.204 95.0551 117.652 94.6074 118.204 94.6074V92.6074C116.547 92.6074 115.204 93.9505 115.204 95.6074H117.204ZM51.7988 94.6074C52.3511 94.6074 52.7988 95.0551 52.7988 95.6074H54.7988C54.7988 93.9505 53.4557 92.6074 51.7988 92.6074V94.6074Z"
        fill="#D5D7EB"
      />
      <rect
        x="63.5586"
        y="59.4043"
        width="42.8861"
        height="17.0633"
        rx="3"
        stroke="#C4C7E0"
        strokeWidth="2"
        strokeDasharray="2.8 2.8"
      />
      <circle cx="118.353" cy="119.431" r="21.519" fill="#F6F4FF" />
      <circle
        cx="118.353"
        cy="119.431"
        r="20.519"
        stroke="#6B55F0"
        strokeOpacity="0.3"
        strokeWidth="2"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.583 110.824H117.123V118.202L109.746 118.202V120.661H117.123V128.039H119.583V120.661H126.961V118.202L119.583 118.202V110.824Z"
        fill="#6B55F0"
      />
    </svg>
  );
}

export default EmptyPortsPlaceholderIcon;
