import React, { FC } from "react";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import TrafficLayout from "../../common/TrafficLayout";
import { useTrafficContext } from "../../TrafficContext";
import styles from "./Latency.module.scss";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";
import { GRAFANA_ROOT_PATH } from "../../../../components/common/charts/GraphanaLinksFactory";
import { getMsFromDate } from "../../../../components/common/charts/GraphanaTableLinks";
import { getFiltersParams } from "../../../ApplicationsDashboard/common/appStatisticsHelper";
import { TRAFFIC_FIELD_VALUE } from "../../consts";

const Latency: FC<{ tabs: any }> = ({ tabs }) => {
  const { filters } = useTrafficContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  const getLink = (panelId: number) => {
    const address = GRAFANA_ROOT_PATH + "/d-solo/Jtm7WxnNk/latency";
    const orgId = "orgId=1";
    const theme = "theme=light";

    const { from, to } = getMsFromDate(timeRange.general);
    const dateRange = `from=${from}&to=${to}`;

    const defaultFilters = `var-Filters=tenant_name.keyword%7C%3D%7C${selectedTenant}&var-Filters=Tcp+Latency%7C%3E%7C0&var-Filters=APP+Latency%7C%3E%7C0`;
    const refresh = "refresh=10s";

    const filtersParams = filters
      ? `&${getFiltersParams(TRAFFIC_FIELD_VALUE, filters)}`
      : "";
    return `${address}?${orgId}&${defaultFilters}${filtersParams}&${dateRange}&panelId=${panelId}&${theme}&${refresh}`;
  };

  return (
    <TrafficLayout tabs={tabs}>
      <ServicesRedirectPlaceholder type="sdr">
        <GrafanaContainer type={GRAFANA_TYPES.SDR} className={styles.wrapper}>
          <div className={styles.chartsWrapper}>
            <div className={styles.basicChart}>
              <GrafanaGraph link={getLink(5)} />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph link={getLink(22)} />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph link={getLink(26)} />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph link={getLink(28)} />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph link={getLink(29)} />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph link={getLink(30)} />
            </div>
          </div>
        </GrafanaContainer>
      </ServicesRedirectPlaceholder>
    </TrafficLayout>
  );
};

export default Latency;
