import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import DropdownBasic from "../../common/Dropdown/DropdownBasic";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { NEW_PUBLIC_IP, SOURCE_NAT } from "../../../pages/NATRulesPage/const";
import {
  RequestedGateVIAdd,
  VirtualInetrfaceAllTypes,
  VirtualInetrfaceGate,
} from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import { DropdownItem } from "../../../helpers/types";
import GateVIDropdownContextContainer, {
  useGateVIDropdownContext,
} from "./GateVIDropdownContext";

import styles from "./GateVIDropdown.module.scss";
import { withContexts } from "../../../helpers/hocs/withContexts";
import LocationDropdown from "../LocationDropdown/LocationDropdown";
import { VI_GATE } from "../../tenants/TenantPackages/components/constAlias";

type InterfaceItem = DropdownItem<VirtualInetrfaceGate>;

type Props = {
  selected: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  label?: string;
  onChangeNewPublicIp: (val: RequestedGateVIAdd) => void;
  type: string;
  className?: string;
};

export const GateVIDropdown: FC<Props> = ({
  selected,
  onChange,
  disabled,
  label,
  onChangeNewPublicIp,
  type,
  className,
}): ReactElement => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchList, list = [] } = useGateVIDropdownContext();

  const [location, setLocation] = useState<string>("");

  useEffect(() => {
    selectedTenant && fetchList(selectedTenant);
  }, [selectedTenant]);

  const gateVIs: Array<any> = useMemo(() => {
    const newList: Array<any> = [];
    list?.forEach((vi) => {
      const newItem = {
        key: vi.name,
        data: { ...vi },
      };
      if (vi.type === VI_GATE && type === SOURCE_NAT) {
        newList.push(newItem);
      }
      if (type !== SOURCE_NAT) {
        newList.push(newItem);
      }
    });

    return [...newList, getRequestedGateVI(list)];
  }, [list, type]);

  const handleChangeVI = (item: InterfaceItem) => {
    item.data && onChange(item.key);
  };

  const handleChangeLocation = (val: string) => {
    setLocation(val);
    const newPublicIP: RequestedGateVIAdd = {
      ...getRequestedGateVI(list).data,
    };
    newPublicIP.system_name = val;
    newPublicIP.data = val;
    onChangeNewPublicIp(newPublicIP);
  };

  return (
    <div className={styles.wrapper}>
      <DropdownBasic
        label={label}
        id={"gateVIDropdown"}
        onChange={handleChangeVI}
        selected={gateVIs.find((el) => el.key === selected)}
        itemsList={gateVIs}
        isMedium
        disabled={disabled}
        listClassName={className}
      />
      {selected === NEW_PUBLIC_IP && (
        <LocationDropdown
          selected={location}
          onChange={(val) => handleChangeLocation(val)}
        />
      )}
    </div>
  );
};

export default withContexts<Props>(GateVIDropdown, [
  GateVIDropdownContextContainer,
]);

const getRequestedGateVI = (
  list: Array<VirtualInetrfaceAllTypes>
): {
  key: string;
  data: RequestedGateVIAdd;
} => {
  return {
    key: NEW_PUBLIC_IP,
    data: {
      system_name: "",
      name: `Public_Access_${getName(list)}`,
      type: "system",
      data: null,
      ipsec_service: false,
      nat_service: true,
      snat_service: true,
      shared: false,
    },
  };
};

const getName = (list: Array<VirtualInetrfaceAllTypes>): string => {
  let attemptCount = 0;
  while (attemptCount < 10000) {
    const randomNumber = Math.floor(Math.random() * 10000) + 1;
    const name = `Public_Access_${randomNumber}`;
    if (!list.find((el) => el.name === name)) {
      return name;
    }
    attemptCount++;
  }
  throw new Error("Unable to generate a unique name.");
};
