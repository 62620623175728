import React, { FC } from "react";
import styles from "./ContentBlock.module.scss";
import TabsBlock from "./TabsBlock";

type Props = {
  tabs: Array<string>;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  content?: React.ReactElement;
  showDialog?: boolean;
  color?: "orange" | "blue";
};

const ContentBlock: FC<Props> = ({
  tabs,
  activeTab,
  setActiveTab,
  content,
  showDialog,
  color,
}) => {
  return (
    <div className={styles.block}>
      <TabsBlock
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        showDialog={showDialog}
        color={showDialog ? color : undefined}
      />
      <div className={styles.wrapper}>
        <div className={styles.inner}>{content}</div>
      </div>
    </div>
  );
};

export default ContentBlock;
