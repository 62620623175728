import React, { ReactElement, useMemo, useEffect, useState } from "react";
import LogoIcon from "../icons/LogoIcon";
import { getDateWithTimeAndWeekday } from "../../helpers/common/dateHelpers";
import useCurrentDate from "../../helpers/hooks/useCurrentDate";
import { Link } from "react-router-dom";
import { WIZARDS, INSIGHTS } from "../../helpers/navigation/entries";
import ExternalApi from "./components/ExternalApi";
import styles from "./Header.module.scss";
import UserLabel from "./components/UserLabel";
import { useUserContext } from "../../contexts/UserContext";
import { Steps } from "intro.js-react";
import "intro.js/introjs.css";
import steps from "./UserGuide.json";
import HeaderMenu from "./components/HeaderMenu";
import LogoutButton from "./components/LogoutButton";
import DevPortalIcon from "../icons/DevPortalIcon";
import BlankButton from "../common/buttons/BlankButton";
import TextWithIcon from "../common/table/TextWithIcon";
import { ACCENT } from "../../helpers/common/colorAliases";
import EventsIcon from "./components/EventsIcon/EventsIcon";
import SearchBar from "../SearchBar/SearchBar";
const USER_GUIDE_KEY = "USER_GUIDE";

// const steps = [
//   {
//     element: "#dashboard",
//     intro:
//       "This is <br/><strong>dashboard</strong> <ul><li>1</li><li>2</li><ul>",
//     position: "top",
//   },
//   {
//     element: "#systems",
//     intro: "systems",
//     position: "top",
//   },
//   {
//     element: "#network",
//     intro: "network",
//     position: "top",
//   },
//   {
//     element: "#services",
//     intro: "services",
//     position: "top",
//   },
//   {
//     element: "#tenants",
//     intro: "tenants",
//     position: "top",
//   },
//   {
//     element: "#tenants",
//     intro: "tenants",
//     position: "top",
//   },
//   {
//     element: "#Locations",
//     intro: "Locations",
//     position: "left",
//   },
//   {
//     element: "#Segments",
//     intro: "tenants",
//     position: "left",
//   },
//   {
//     element: "#Logout",
//     intro: "Logout",
//     position: "left",
//   },
//   {
//     element: "#Alert",
//     intro: "Alert",
//     position: "left",
//   },
// ];

export default function Header(): ReactElement {
  const { isTenantEmpty, isTenant, isZayo, user } = useUserContext();
  const remoteUser = user?.secondRole === "remote_user";
  const logoLink = useMemo(() => {
    if (!isTenant) return INSIGHTS().path;
    if (isTenantEmpty) return WIZARDS().path;
    return INSIGHTS().path;
  }, [isTenant, isTenantEmpty]);

  const [stepsActive, setStepsActive] = useState<boolean>(false); // TODO checked ref changes

  const isUserGuideEnabled = (): boolean => {
    return localStorage.getItem(USER_GUIDE_KEY) === "true";
  };

  const handleUserGuideCompleted = () => {
    localStorage.setItem(USER_GUIDE_KEY, "false");
  };

  useEffect(() => {
    setTimeout(() => {
      setStepsActive(isUserGuideEnabled());
    }, 2000);
  }, []);

  return (
    <div className={styles.container}>
      {remoteUser ? (
        <>
          <Steps
            enabled={stepsActive}
            steps={steps}
            initialStep={0}
            onExit={handleUserGuideCompleted}
            onComplete={handleUserGuideCompleted}
          />
          <div className={styles.leftPart}>
            {isZayo ? (
              <img
                src="/zayoGrafics/zayoLogoSmall.png"
                className={styles.zayoLogo}
              />
            ) : (
              <LogoIcon />
            )}
            <div className={styles.data}>
              {getDateWithTimeAndWeekday(useCurrentDate())}
            </div>
          </div>
          <div className={styles.rightPart}>
            <UserLabel />
            <LogoutButton />
          </div>
        </>
      ) : (
        <>
          <Steps
            enabled={stepsActive}
            steps={steps}
            initialStep={0}
            onExit={handleUserGuideCompleted}
            onComplete={handleUserGuideCompleted}
          />
          <div className={styles.leftPart}>
            <Link to={`/${logoLink}`}>
              {isZayo ? (
                <img
                  src="/zayoGrafics/zayoLogoSmall.png"
                  className={styles.zayoLogo}
                />
              ) : (
                <LogoIcon />
              )}
            </Link>
            <div className={styles.data}>
              {getDateWithTimeAndWeekday(useCurrentDate())}
            </div>
          </div>
          <SearchBar />
          <div className={styles.rightPart}>
            <UserLabel />
            {!isTenant ? <ExternalApi /> : null}
            {isZayo && (
              <Link to={"/broker/redoc"} target={"_blank"}>
                <BlankButton
                  id="devPortal"
                  onClick={() => undefined}
                  className={styles.devBtn}
                >
                  <TextWithIcon
                    icon={<DevPortalIcon color={ACCENT} />}
                    text={"Developer Portal"}
                  />
                </BlankButton>
              </Link>
            )}
            <EventsIcon />
            <HeaderMenu />
            <LogoutButton />
          </div>
        </>
      )}
    </div>
  );
}
