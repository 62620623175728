import React, { FC } from "react";
import styles from "./TableControlsOrderedPorts.module.scss";
import { useOrderedPortsContext } from "../OrderedPortsContext";
import { ASSIGNED_CAPITAL, PENDING_CAPITAL } from "../../../helpers/consts";

type Props = {
  onAdd?: () => void;
  noSearch?: boolean;
};

const MODES = [
  { label: "Pending Ports", mode: PENDING_CAPITAL },
  { label: "Assigned Ports", mode: ASSIGNED_CAPITAL },
];

const TableControlsOrderedPorts: FC<Props> = () => {
  const {
    selectedOrderedPortsMode,
    setSelectedOrderedPortsMode,
    fetchData,
  } = useOrderedPortsContext();

  const handleModeChange = (mode: string) => {
    setSelectedOrderedPortsMode(mode);
    fetchData();
  };

  return (
    <div className={styles.tableControls}>
      {MODES.map(({ label, mode }, index) => (
        <React.Fragment key={mode}>
          <div
            className={`${styles.portsModeSelector} ${
              selectedOrderedPortsMode === mode ? styles.active : ""
            }`}
            onClick={() => handleModeChange(mode)}
          >
            {label}
          </div>
          {index < MODES.length - 1 && (
            <span className={styles.separator}>|</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TableControlsOrderedPorts;
