import React, { FC } from "react";

import styles from "./DnsStatistics.module.scss";

import GrafanaGraph from "../../components/common/charts/GrafanaGraph";
import { getDNSLink } from "./common/dnsStatisticsHelper";
import { useTimerangeContext } from "../../contexts/TimerangeContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useDnsDashboardContext } from "./DnsContext";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useUserContext } from "../../contexts/UserContext";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";
import { TopPart } from "./components/TopPart";

const DnsStatistics: FC = () => {
  const { isDemo } = useUserContext();

  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();
  const { filters } = useDnsDashboardContext();

  const trValue = isDemo
    ? { from: "2023-07-18T14:58:11.319Z", to: "2023-07-19T08:32:11.505Z" }
    : timeRange.general;

  return (
    <ServicesRedirectPlaceholder type="dns">
      <GrafanaContainer
        type={GRAFANA_TYPES.DPI}
        className={styles.wrapper}
        ignorePlaceholder={isDemo}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            <TopPart timeRange={trValue} />
          </div>
          <div className={styles.middle}>
            <GrafanaGraph
              link={getDNSLink(2, trValue, filters, selectedTenant)}
            />
          </div>
          <div className={styles.btmLeft}>
            <GrafanaGraph
              link={getDNSLink(5, trValue, filters, selectedTenant)}
            />
          </div>
          <div className={styles.btmRight}>
            <GrafanaGraph
              link={getDNSLink(8, trValue, filters, selectedTenant)}
            />
          </div>
        </div>
      </GrafanaContainer>
    </ServicesRedirectPlaceholder>
  );
};

export default DnsStatistics;
