import { FC, useEffect, useState } from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import Button from "../../common/buttons/Button";
import Input from "../../common/formComponents/Input";
import LoaderIcon from "../../common/loadStates/LoaderIcon";
import UnifiedTextPair from "../../common/UnifiedTextPair";
import DialogTemplate from "../../dialogs/common/DialogTemplate";
import SummaryInfoBlock from "../../leftInfoBlock/SummaryInfoBlock";
import PasswordChangeDialog from "./PasswordChangeDialog";

import styles from "./UserEditDialog.module.scss";
import { withContexts } from "../../../helpers/hocs/withContexts";
import UserIcon from "../../icons/UserIcon";
import UserEditDialogContextContainer, {
  useUserEditDialogContext,
} from "./UserEditDialogContext";
import { useValidation } from "../../../helpers/validators/Validator";
import validateUserEmail from "../../../helpers/validators/EditUserValidator";

type Props = {
  onClose: () => void;
};

const UserEditDialog: FC<Props> = ({ onClose }) => {
  const {
    userStatus,
    currUser,
    fetchCurrUser,
    editCurrentUser,
  } = useUserEditDialogContext();
  const { user, isAdmin } = useUserContext();

  const [fields, handleFieldChange, resetFields] = useFormField<any>({});
  const [errors, validate] = useValidation<any>(validateUserEmail, [fields]);
  const [passwordIsChanging, setPasswordIsChanging] = useState(false);
  const [userName, setUserName] = useState(user?.name);
  useEffect(() => {
    if ((user && user?.secondName) || isAdmin) {
      setUserName(user?.secondName || user?.name);
      fetchCurrUser();
    }
  }, [user]);

  useEffect(() => {
    if (currUser) {
      resetFields(currUser);
    }
  }, [currUser]);

  if (passwordIsChanging) {
    return (
      <PasswordChangeDialog onCancel={() => setPasswordIsChanging(false)} />
    );
  }

  const submitForm = async () => {
    const { isOk } = validate();
    const { email, phone, first_name, last_name, description } = fields;
    if (isOk && user) {
      const res = await editCurrentUser({
        email,
        first_name,
        last_name,
        phone,
        description,
      });
      if (res) {
        onClose();
      }
    }
  };

  return (
    <DialogTemplate
      title="Edit"
      controls={{
        okText: "Save",
        onOk: submitForm,
        cancelText: "Cancel",
        onCancel: onClose,
      }}
      errorDisplay={userStatus}
      onClose={onClose}
      leftColumn={
        <SummaryBlock
          showChangePassword={true}
          onPasswordChange={() => setPasswordIsChanging(true)}
        />
      }
    >
      {userStatus?.state === "pending" ? (
        <div className={styles.dialogPending}>
          <LoaderIcon />
        </div>
      ) : (
        <>
          <div className={styles.form}>
            <div className={styles.column}>
              <Input
                name="username"
                label="Username"
                medium
                isNotEditable
                value={userName}
                error=""
              />
              <Input
                name="email"
                label="Email"
                placeholder="current_email"
                handleFieldChange={handleFieldChange}
                medium
                value={fields.email}
                error={errors?.["email"]}
              />

              <Input
                name="phone"
                label="Phone"
                placeholder="+x (xxx) xxx xxxx"
                handleFieldChange={handleFieldChange}
                medium
                isOptional
                value={fields.phone}
                error=""
              />
            </div>
            <div className={styles.column}>
              <Input
                name="first_name"
                label="First Name"
                placeholder="current_first_name"
                handleFieldChange={handleFieldChange}
                medium
                value={fields.first_name}
                error=""
              />
              <Input
                name="last_name"
                label="Last Name"
                placeholder="current_last_name"
                handleFieldChange={handleFieldChange}
                medium
                value={fields.last_name}
                error=""
              />
              <Input
                name="description"
                label="Description"
                handleFieldChange={handleFieldChange}
                medium
                isOptional
                value={fields.description}
                error=""
              />
            </div>
          </div>
        </>
      )}
    </DialogTemplate>
  );
};

type SummaryBlockProps = {
  showChangePassword?: boolean;
  onPasswordChange: () => void;
};

const SummaryBlock: FC<SummaryBlockProps> = ({
  showChangePassword = false,
  onPasswordChange,
}) => {
  const { currUser } = useUserEditDialogContext();
  return (
    <SummaryInfoBlock icon={<UserIcon />} title={"User"}>
      <UnifiedTextPair subtitle="Username" text={currUser?.username || "-"} />
      <div className={styles.bottomButton}>
        {showChangePassword && (
          <Button
            className={styles.changePasswordBtn}
            onClick={onPasswordChange}
          >
            Change password
          </Button>
        )}
      </div>
    </SummaryInfoBlock>
  );
};
export default withContexts<Props>(UserEditDialog, [
  UserEditDialogContextContainer,
]);
