import React, { ReactElement } from "react";
import { FONT, WHITE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ShieldIcon({
  color,
  width = 37,
  height = 43,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 37 43"
      fill="none"
    >
      <path
        fill={color || FONT}
        d="M37 8.726c0 28.56-18.5 33.32-18.5 33.32S0 38.078 0 8.725L18.5 0 37 8.726z"
      />
      <path
        fill={WHITE}
        d="M18.5 42.499S0 38.532 0 9.179L18.5.454V42.5z"
        opacity="0.15"
      />
    </svg>
  );
}

export default ShieldIcon;
