import React, { FC } from "react";
import LoaderIcon from "../../../../components/common/loadStates/LoaderIcon";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import { PURPLE } from "../../../../helpers/common/colorAliases";

import styles from "./CmdLoader.module.scss";

type Props = {
  message?: string;
  color?: string;
};
export const CmdLoader: FC<Props> = ({ message, color = PURPLE }) => {
  return (
    <div className={styles.loadingIcon}>
      <TextWithIcon
        className={styles.attemptsMessage}
        icon={<LoaderIcon color={color} />}
        text={message}
      />
    </div>
  );
};
