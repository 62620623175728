import { CreateGCPNNI } from "../../../../types";

export const DEFAULT_FIELDS: Partial<CreateGCPNNI> = {
  primary_nni: "",
  primary_zone: 1,
  primary_physical_port_name: "",
  primary_system_name: "",
  secondary_nni: "",
  secondary_zone: 2,
  secondary_physical_port_name: "",
  secondary_system_name: "",
  regions: [],
  managed_max_bandwidth: 0,
  vlan_pool: { from: 1, to: 4096, used: [] },
};

export const VLAN_POOL_MIN = 1;
export const VLAN_POOL_MAX = 4096;
