import { SiteRegion } from "../../../../../../helpers/api/apiTypes";
import { CreateGCPNNI } from "../../../../types";

export const createGCPSiteObject = (
  fields: CreateGCPNNI,
  primarySystemLocation: string
): SiteRegion => {
  const region = fields.regions.join(", ");
  return {
    name: `GCP ${region}`,
    connection_type: "Cloud",
    operator: "gcp",
    address: region,
    cloud_region: region,
    network_status: "off",
    handover_bw: [
      {
        bw: fields.managed_max_bandwidth,
        hw: "fiber",
      },
    ],
    naas_location: primarySystemLocation || "string",
    naas_systems: [fields.primary_system_name],
    labels: {
      additionalProp1: "string",
      additionalProp2: "string",
      additionalProp3: "string",
    },
  };
};
