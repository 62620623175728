import { FC, useEffect, useState } from "react";
import styles from "./SearchBar.module.scss";
import SearchField from "./components/SearchField";
import SearchResultsList from "./components/SearchResultsList";
import { useClickOutside } from "../../helpers/hooks/useClickOutside";
import { NavItem } from "./helpers/types";
import { getPagesMap } from "./helpers/consts";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import { getSearchType } from "./helpers/getSearchType";
import { usePackagesContext } from "../../contexts/PackagesContext";

const SearchBar: FC = () => {
  const history = useHistory();
  const [showList, setShowList] = useState<boolean>(false);
  const elementRef = useClickOutside(() => setShowList(false));
  const { isPackagesVisible } = usePackagesContext();

  const [searchValue, setSearchValue] = useState<string>("");
  const [navList, setNavList] = useState<Array<NavItem> | undefined>(undefined);

  const { isZayo, isTenant } = useUserContext();
  const searchType = getSearchType(isTenant, isZayo);

  useEffect(() => {
    if (searchValue) {
      const newList = getPagesMap(isPackagesVisible)[
        searchType
      ].filter((page) =>
        page.title.toLowerCase().includes(searchValue.toLowerCase())
      );
      setNavList(newList);
    }
  }, [searchValue, isPackagesVisible]);

  const handleClick = () => setShowList(true);

  const handleChooseValue = (value: string, goto: string) => {
    setSearchValue(value);
    setShowList(false);
    history.replace(goto);
  };

  return (
    <div
      className={styles.wrapper}
      ref={elementRef as React.RefObject<HTMLDivElement>}
    >
      <SearchField
        searchValue={searchValue}
        onChange={setSearchValue}
        onEnter={handleChooseValue}
        onClick={handleClick}
      />
      {showList && (
        <SearchResultsList
          searchValue={searchValue}
          navList={navList}
          onClick={handleChooseValue}
        />
      )}
    </div>
  );
};

export default SearchBar;
