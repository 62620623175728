import { FC, useEffect, useMemo } from "react";
import DropdownBasic from "../../../../components/common/Dropdown/DropdownBasic";
import {
  ChangeField,
  FieldsType,
} from "../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useLayer3InterfaceDialogContext } from "./Layer3InterfaceDialogContext";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
};

const TenantSystemDropdown: FC<Props> = ({ fields, onChange }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchTenant, tenant } = useLayer3InterfaceDialogContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchTenant(selectedTenant);
    }
  }, [selectedTenant]);
  const systems = useMemo(() => tenant?.systems || [], [tenant]);

  return (
    <DropdownBasic
      id={"system_name"}
      onChange={(val) => onChange("system_name", val.key)}
      selected={mapStringToItem(fields.system_name)}
      label="System name"
      itemsList={systems.map((key) => mapStringToItem(key))}
      isMedium
    />
  );
};

export default TenantSystemDropdown;
