import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { PRes } from "../../helpers/api/Api";
import { configApi } from "../../helpers/api/ConfigApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { PENDING_CAPITAL } from "../../helpers/consts";
import { RequestStatus } from "../../helpers/types";
import { CustomerPortType } from "../Network/UnitedConnections/types";

type RequestStatuses = {
  state: "pending" | "ok" | "error" | "idle";
  message?: string;
};
type State = {
  list: Array<any>;
  count: number;
  listStatus?: RequestStatus;
  deleteRequestStatus?: RequestStatus;
  selectedOrderedPortsMode?: string;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchData: () => Promise<void>;
  deleteCustomerPort: (tenant: string, name: string) => PRes<any>;
  setSelectedOrderedPortsMode: (mode: string) => void;
};

const [OrderedPortsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useOrderedPortsContext = useContext;

export default class OrderedPortsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = OrderedPortsContext;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      list: [],
      count: 0,
      listStatus: { state: "idle" },
      deleteRequestStatus: { state: "idle" },
      selectedOrderedPortsMode: PENDING_CAPITAL,
      state: "idle",
    };
  }

  fetchData = async (): Promise<void> => {
    this.setState({ listStatus: setPending("Fetching") });
    const res = await configApi.getOrderedPortsList();
    if (res.ok && res.result) {
      const { selectedOrderedPortsMode } = this.state;

      const filteredList = res.result.filter(
        (port: CustomerPortType) =>
          (selectedOrderedPortsMode === PENDING_CAPITAL
            ? port.request_status === "pending"
            : port.request_status === "assigned") && port.labels?.building_type // building_type means only customer ports
      );

      this.setState({
        list: filteredList,
        count: filteredList.length || 0,
      });
    }

    this.setState({ listStatus: resToState(res) });
  };

  setSelectedOrderedPortsMode = (mode: string) => {
    this.setState({ selectedOrderedPortsMode: mode });
  };

  deleteCustomerPort = async (tenant: string, name: string): PRes<any> => {
    this.setState({ deleteRequestStatus: setPending("Fetching") });
    const res = await configApi.deleteCustomerPort(tenant, name);
    this.setState({ deleteRequestStatus: resToState(res) });
    if (res.ok) {
      await this.fetchData();
    }
    return res;
  };

  funcs = {
    fetchData: this.fetchData,
    deleteCustomerPort: this.deleteCustomerPort,
    setSelectedOrderedPortsMode: this.setSelectedOrderedPortsMode,
  };
}
