import React, { useEffect } from "react";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { APP_BTN_MAP } from "./cyberThreadsAndDNSButtonsConsts";
import AppButtonsContextContainer, {
  useAppButtonsContext,
} from "./AppButtonsContext";

import styles from "./AppButtons.module.scss";

import { classNames } from "../../../../helpers/common/classNames";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import Card from "../../../../components/common/Card/Card";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";

type Props = {
  className?: string;
};

const CyberThreadsAndDNSButtons: React.FC<Props> = ({ className }) => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  const {
    fetchCyberThreadsAndDNSCardsData,
    cardsData,
  } = useAppButtonsContext();

  useEffect(() => {
    if (timeRange) {
      fetchCyberThreadsAndDNSCardsData(timeRange.general, selectedTenant);
    }
  }, [timeRange, selectedTenant]);

  return (
    <div className={classNames(className, styles.wrapper)}>
      {APP_BTN_MAP?.map((card) => (
        <Card {...card} data={cardsData} key={card.title} />
      ))}
    </div>
  );
};

export default withContexts<Props>(CyberThreadsAndDNSButtons, [
  AppButtonsContextContainer,
]);
