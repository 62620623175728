/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { FilterAndSort, VRFType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import AbstractCrudContextContainer, {
  createContextAndUse,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";

type IState = CrudState<VRFType, VRFType> & {
  vrfList?: Array<VRFType>;
};

type IFunc = {
  updateNetworkObservability: (vrf?: VRFType | undefined) => Promise<boolean>;
  updateDNSObservability: (vrf?: VRFType | undefined) => Promise<boolean>;
  fetchNewVRFsList: (system: string) => void;
};

const [Context, useContext] = createContextAndUse<
  IState,
  IFunc & CrudFunc<VRFType, string>
>();

export const useVRFContext = useContext;

type Props = React.PropsWithChildren<{ additionParameter: string | number }>;

export default class VRFContextContainer extends AbstractCrudContextContainer<
  VRFType,
  VRFType,
  IState,
  string
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);

    this.funcs = {
      fetchNewVRFsList: this.fetchNewVRFsList,
      updateNetworkObservability: this.updateNetworkObservability,
      updateDNSObservability: this.updateDNSObservability,
    };
  }

  listReq = async (params?: FilterAndSort) => {
    return configApi.getVRFList(this.props.additionParameter, params?.sort);
  };

  selectedReq = async (vrfName: string | number) => {
    return await configApi.getVRF(
      this.props.additionParameter,
      vrfName as string
    );
  };

  addReq = async (params: Partial<VRFType>) => {
    return await configApi.addVRF(
      this.props.additionParameter,
      params as Partial<VRFType>
    );
  };

  editReq = async (params: Partial<VRFType>, vrfName: string | number) => {
    return await configApi.editVRF(
      this.props.additionParameter,
      vrfName as string,
      params
    );
  };

  removeReq = async (vrfName: string | number) => {
    return await configApi.deleteVRF(
      this.props.additionParameter,
      vrfName as string
    );
  };

  updateNetworkObservability = async (vrf?: VRFType | undefined) => {
    if (vrf) {
      const params = { enable: !vrf.is_sdr, rate: vrf.rate };
      const res = await configApi.editVrfSDR(vrf.system_name, vrf.name, params);
      if (res.ok) {
        this.fetchNewVRFsList(vrf.system_name);
      }
      return res.ok;
    }
    return false;
  };

  updateDNSObservability = async (vrf?: VRFType | undefined) => {
    if (vrf) {
      const params = { enable: vrf.is_dns };
      const res = await configApi.editVrfDNS(vrf.system_name, vrf.name, params);
      if (res.ok) {
        this.fetchNewVRFsList(vrf.system_name);
      }
      return res.ok;
    }
    return false;
  };

  fetchNewVRFsList = async (system: string) => {
    const listOfVRFs = await this.listReq();
    if (listOfVRFs?.result) {
      const updatedVRFs = await Promise.all(
        listOfVRFs?.result.map(async (vrf) => {
          if (vrf.vrf_type === "internet") {
            const [sdrRes, dnsRes] = await Promise.all([
              configApi.getVrfSDR(system, vrf.name),
              configApi.getVrfDNS(system, vrf.name),
            ]);
            return {
              ...vrf,
              is_sdr: sdrRes?.result?.enable,
              sdr_rate: sdrRes?.result?.rate,
              is_dns: dnsRes?.result?.enable,
            };
          } else {
            return vrf;
          }
        })
      );
      this.setState({
        vrfList: updatedVRFs || [],
      });
    }
  };

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, ...this.funcs, ...this.baseFuncs }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
