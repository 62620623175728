import React, { FC, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeSeriesScale,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";
import { labelizeBytes } from "../../OverviewPage/utils";
import { arubaApi } from "../../../helpers/api/ArubaApi";
import { BPS } from "../../../helpers/common/constantsAlias";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeSeriesScale,
  Tooltip,
  Legend
);

type Props = {
  id: string;
  interfaceName: string;
};

const multiplier = 0.13333333;

const options = {
  responsive: true,
  interaction: {
    mode: "index" as const,
    intersect: false,
  },
  scales: {
    x: {
      type: "timeseries" as const,
      time: {
        displayFormats: {
          minute: "HH:mm",
        },
      },
      ticks: {
        maxTicksLimit: 8,
        maxRotation: 0,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value: string | number) => {
          return labelizeBytes(+value) + BPS;
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (tooltip: any) => {
          return labelizeBytes(tooltip.raw) + BPS;
        },
      },
    },
  },
};

const Chart: FC<Props> = ({ id, interfaceName }) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    const [name] = interfaceName.split(" (");

    const params = {
      nePk: id,
      timeRange: "1h",
      interfaceName: name,
      trafficType: "all_traffic",
      startTime: (now - 3600).toString(),
      endTime: now.toString(),
      overlay: "0",
      granularity: "minute",
    };

    arubaApi.getInterfaceTimeSeries(params).then((res) => {
      const json: Array<any> = res.result || [];
      json.reverse();

      const labels: Array<number> = [];
      const datasets = [
        {
          label: "Bandwidth Tx",
          data: [] as Array<number>,
          borderWidth: 2,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          pointRadius: 0,
        },
        {
          label: "Bandwidth Rx",
          data: [] as Array<number>,
          borderWidth: 2,
          borderDash: [5, 5],
          borderColor: "#35A2EB",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          pointRadius: 0,
        },
        {
          label: "Firewall Tx",
          data: [] as Array<number>,
          borderWidth: 2,
          borderColor: "#FF6384",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          pointRadius: 0,
        },
        {
          label: "Firewall Rx",
          data: [] as Array<number>,
          borderWidth: 2,
          borderDash: [5, 5],
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          pointRadius: 0,
        },
      ];

      json.forEach((row) => {
        const {
          fwdrops_tx_bytes,
          ohead_tx_bytes,
          tx_bytes,
          ohead_rx_bytes,
          rx_bytes,
          fwdrops_rx_bytes,
          timestamp,
        } = row;

        const tx_bandwidth = (tx_bytes - ohead_tx_bytes) * multiplier;
        const rx_bandwidth = (rx_bytes - ohead_rx_bytes) * multiplier;

        const tx_firewall = fwdrops_tx_bytes * multiplier;
        const rx_firewall = fwdrops_rx_bytes * multiplier;

        datasets[0].data.push(Math.round(tx_bandwidth));
        datasets[1].data.push(Math.round(rx_bandwidth));
        datasets[2].data.push(Math.round(tx_firewall));
        datasets[3].data.push(Math.round(rx_firewall));

        labels.push(timestamp * 1000);
      });

      setData({ labels, datasets });
    });
  }, [id, interfaceName]);

  return (
    <div>
      <h4>{interfaceName}</h4>
      {data && <Line options={options} data={data} />}
    </div>
  );
};

export default Chart;
