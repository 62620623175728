import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import Table from "../../components/common/table/newTable/Table";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { withContexts } from "../../helpers/hocs/withContexts";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import Layer3InterfaceAddDialog from "./itemDialog/Layer3InterfaceAddDialog";
import Layer3InterfaceEditDialog from "./itemDialog/Layer3InterfaceEditDialog";
import Layer3InterfaceContextContainer, {
  useLayer3InterfaceContext,
} from "./Layer3InterfaceContext";
import { layer3InterfaceTableHeader } from "./table/layer3InterfaceTableHeader";
import { Layer3Interface } from "./types";

const Layer3InterfaceContent: FC = () => {
  const {
    list = [],
    fetchList,
    listStatus,
    remove,
    removeStatus,
  } = useLayer3InterfaceContext();

  const { selected } = useTenantContext();

  const { tenant_name } = useParams<{ tenant_name: string }>();

  const [viewItem, setViewItem] = useState<Layer3Interface | undefined>();
  const [showAdd, setShowAdd] = useState(false);

  const handleTenantRemove = async (vi: Layer3Interface) => {
    if (!selected) {
      return;
    }
    await remove(vi, selected?.name);

    handleListUpdate();
  };

  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<Layer3Interface>(handleTenantRemove, removeStatus, () => {
    setViewItem(undefined);
  });

  useEffect(() => {
    handleListUpdate();
  }, []);

  const rowActions: ActionFuncs<Layer3Interface> = useMemo(
    () => ({
      onEdit: setViewItem,
      onDelete: (rowOriginal) => {
        tryDelete(rowOriginal);
      },
    }),
    [tenant_name, tryDelete, setViewItem]
  );

  const handleListUpdate = () => {
    fetchList(tenant_name);
  };

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Virtual Interface"}
        name={selectedToDelete?.name}
      />
      {showAdd && tenant_name && (
        <Layer3InterfaceAddDialog
          onClose={() => setShowAdd(false)}
          type={"add"}
          tenant={tenant_name}
          onSubmit={handleListUpdate}
        />
      )}
      {viewItem && tenant_name && (
        <Layer3InterfaceEditDialog
          onClose={() => setViewItem(undefined)}
          type={"edit"}
          tenant={tenant_name}
          data={viewItem}
        />
      )}
      <TableWrapper
        titleProps={{
          title: `${(list || []).length} Virtual Interface`,
          children: <AddButton onClick={() => setShowAdd(true)} />,
        }}
        dataStatus={listStatus}
        tablePlaceholder={["Virtual Interfaces", "Virtual Interface"]}
        isEmpty={list && list.length === 0}
      >
        <Table
          columns={layer3InterfaceTableHeader()}
          header={TableHeader}
          data={list}
          gridColumnTemplate={`1.5fr 140px 1fr 1fr 140px 0.5fr 0`}
          rowActions={rowActions}
          onClick={setViewItem}
        />
      </TableWrapper>
    </>
  );
};

export default withContexts(Layer3InterfaceContent, [
  Layer3InterfaceContextContainer,
]);
