import { GCPCloudCredentials } from "../../pages/CloudResourcesPage/types";
import Validator from "./Validator";

export default function validateGCPCloudCredentials(
  data: Partial<GCPCloudCredentials>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.partner_name, "partner_name");
  validator.checkEmpty(data.portal_url, "portal_url");
  validator.checkJSON(data.json_data, "json_data");
  return validator;
}
