import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  CLOUD_CONNECTIVITY,
  CONFIGURATION,
  CONNECTIONS,
  CONNECTIVITY,
  NETWORK,
  REPORTS,
  SDWAN,
} from "../../../../helpers/navigation/entries";
import { PathEntry } from "../../../../helpers/types";
import TimerangOverlayComponent from "../../timerange/TimerangeOverlayComponent";
import NavigationTabs from "../NavigationTabs";
import ActiveTenantSelector from "../tenantSelector/ActiveTenantSelector";

import styles from "./ConnectivityTab.module.scss";
import BlankButton from "../../buttons/BlankButton";
import SettingsIcon from "../../../icons/SettingsIcon";

const CONNECTIONS_PATH = formPathFromArray([
  NETWORK(),
  CONNECTIVITY(),
  CONNECTIONS(),
  CONFIGURATION(),
]);

const REPORTS_PATH = formPathFromArray([
  NETWORK(),
  CONNECTIVITY(),
  CONNECTIONS(),
  REPORTS(),
]);

export const ConnectivityTab: React.FC<{}> = ({}) => {
  const { path } = useRouteMatch();
  const history = useHistory();

  return (
    <div className={styles.tabsWrapper}>
      <TabsComponent />
      <div className={styles.controlsWrapper}>
        <ActiveTenantSelector />
        {(path === CONNECTIONS_PATH || path === REPORTS_PATH) && (
          <TimerangOverlayComponent
            id={"connectivityTab"}
            className={styles.timerangeWrapper}
          />
        )}
        {path === REPORTS_PATH && (
          <BlankButton
            id={"oneSystemTabReporting"}
            onClick={() => {
              history.push(CONNECTIONS_PATH);
            }}
          >
            <SettingsIcon />
          </BlankButton>
        )}
      </div>
    </div>
  );
};
export const TabsComponent: React.FC = () => {
  const history = useHistory();
  const handleClick = (path: PathEntry) => {
    history.replace(formPathFromArray([NETWORK(), CONNECTIVITY(), path]));
  };

  return (
    <NavigationTabs
      entries={servicesArray}
      disabledTabKeys={[]}
      onClick={handleClick}
    />
  );
};

const servicesArray = [CONNECTIONS(), CLOUD_CONNECTIVITY(), SDWAN()];
