export function randomNumberBounds(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

export const convertBytesToGb = (value: number): number => {
  return Math.round((value / (1024 * 1024 * 1024)) * 100) / 100;
};

export const convertToKM = (value: number) => {
  if (value >= 1000000000) {
    return { value: Math.round(value / 100000000) / 10, extension: "G" };
  }
  if (value >= 1000000) {
    return { value: Math.round(value / 100000) / 10, extension: "M" };
  }
  if (value >= 1000) {
    return { value: Math.round(value / 100) / 10, extension: "K" };
  }
  return { value, extension: "" };
};

export function getPercentageOfValue(
  value: number,
  percentage: number
): number {
  return Math.floor((value * percentage) / 100);
}

export function getPercentageOfTotal(value: number, total: number): number {
  if (total === 0) {
    return 0;
  }
  return Math.round((value / total) * 100);
}
