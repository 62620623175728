import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function GroupIcon(props: IconProps): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="10.9995"
        y="3.66602"
        width="5.5"
        height="5.5"
        rx="2.75"
        fill="#59536B"
      />
      <rect
        x="3.66602"
        y="8.25"
        width="5.5"
        height="5.5"
        rx="2.75"
        fill="#59536B"
      />
      <rect
        x="10.9995"
        y="12.832"
        width="5.5"
        height="5.5"
        rx="2.75"
        fill="#59536B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.273926 8.25039C0.273926 3.84592 3.84445 0.275391 8.24893 0.275391H13.7489C18.1534 0.275391 21.7239 3.84592 21.7239 8.25039V13.7504C21.7239 18.1549 18.1534 21.7254 13.7489 21.7254H8.24893C3.84445 21.7254 0.273926 18.1549 0.273926 13.7504V8.25039ZM8.24893 1.55872C4.55322 1.55872 1.55726 4.55469 1.55726 8.25039V13.7504C1.55726 17.4461 4.55322 20.4421 8.24893 20.4421H13.7489C17.4446 20.4421 20.4406 17.4461 20.4406 13.7504V8.25039C20.4406 4.55469 17.4446 1.55872 13.7489 1.55872H8.24893Z"
        fill="#59536B"
      />
    </svg>
  );
}

export default GroupIcon;
