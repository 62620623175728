import React, { FC, useEffect, useState } from "react";
import LayoutComponent from "../../common/LayoutComponent";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import Button from "../../common/buttons/Button";
import { useHistory } from "react-router-dom";
import styles from "./TenantPackagesPage.module.scss";
import { useQuotaContext } from "../../../contexts/tenantsContext/QuotaContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { QuotaType } from "../../../helpers/api/apiTypes";
import { PackagesTabs } from "../../common/navigation/PackagesTabs";
import PackagesHeader from "./components/PackagesHeader";
import PackagesSections from "./components/PackagesSections";
import { SECTIONS_MAP } from "./components/constAlias";
import EmtyPackages from "./components/EmtyPackages";

export type PackageListType = {
  name: string;
  max_bw: string;
  [key: string]: any;
};

const DEFAULT_FIELDS = {
  name: "",
  fw_rules: 0,
  nat_rules: 0,
  maximum_bgp_neighbors: 0,
};

const TenantsPackagesPage: FC = () => {
  const history = useHistory();
  const {
    fetchList,
    list,
    listStatus,
    updateCustomQuota,
    changeTenantQuota,
    fetchHardcodedData,
    hardcodedListFull,
  } = useQuotaContext();
  const { selected } = useTenantContext();

  const [activePackage, setActivePackage] = useState("");

  const [fields, handleFieldChange, resetFields] = useFormField<
    Partial<QuotaType>
  >(DEFAULT_FIELDS);

  useEffect(() => {
    fetchList();
    fetchHardcodedData();
  }, []);

  useEffect(() => {
    if (selected) {
      setActivePackage(selected?.quota_package_name);
    }
  }, [selected]);

  useEffect(() => {
    if (list) {
      const custom = list?.find((el) => el.name === "Custom");
      if (custom) resetFields(custom);
    }
  }, [list]);

  const handleSubmit = async () => {
    const isCustom = activePackage?.toLowerCase() === "custom";
    const success = isCustom
      ? await updateCustomQuota(fields)
      : await changeTenantQuota(activePackage as string);
    if (success) handleCancel();
  };

  const handleCancel = () => {
    history.goBack();
  };

  if (!hardcodedListFull || !list || !activePackage) {
    return <EmtyPackages status={listStatus} tabs={<PackagesTabs />} />;
  }

  return (
    <>
      <LayoutComponent>
        <PackagesTabs />
        <div className={styles.container}>
          <div className={styles.tableWrapper}>
            <PackagesHeader
              list={list}
              activePackage={activePackage as string}
              onSelect={setActivePackage}
              tenant={selected?.name}
            />
            {SECTIONS_MAP.map((item, i) => (
              <PackagesSections
                key={item.key}
                activePackage={activePackage as string}
                section={item}
                list={list}
                fields={fields}
                onChange={handleFieldChange}
                hardcodedList={hardcodedListFull}
                isLastRow={i === SECTIONS_MAP.length - 1}
              />
            ))}
          </div>
          <div className={styles.footer}>
            <Button onClick={handleCancel} className={styles.btn}>
              Cancel
            </Button>
            <Button onClick={handleSubmit} className={styles.btn} isPrimaryBtn>
              Save
            </Button>
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};
export default TenantsPackagesPage;
