import React, { useMemo } from "react";
import NoDataPlaceholder from "../../../../components/common/loadStates/NoDataPlaceholder";
import { CRITICAL, GREEN } from "../../../../helpers/common/colorAliases";
import {
  MAX_FETCH_ATTEMPTS,
  useLookingGlassContext,
} from "../LookingGlassContext";
import { CmdLoader } from "./CmdLoader";

import styles from "./CmdPanel.module.scss";

const CmdPanel = () => {
  const {
    commandOutput,
    commandExecStatus,
    fetchAttempts,
    currentCommandUUID,
  } = useLookingGlassContext();

  const { state: execStatus, message } = commandExecStatus;

  const Result = useMemo(() => {
    switch (execStatus) {
      case "error":
        return (
          <NoDataPlaceholder
            isError
            title={message || "Something bad happened"}
          />
        );
      case "pending":
        if (currentCommandUUID === null) {
          return <CmdLoader message="Executing command..." color={GREEN} />;
        }

        if (fetchAttempts < Math.floor(MAX_FETCH_ATTEMPTS / 2)) {
          return <CmdLoader message="Waiting for command to be finished..." />;
        }

        return (
          <CmdLoader
            message={`Waiting for command to be finished... \n${fetchAttempts}/${MAX_FETCH_ATTEMPTS} attempts`}
            color={CRITICAL}
          />
        );
      default:
        return <div className={styles.output}>{commandOutput}</div>;
    }
  }, [execStatus, commandOutput, fetchAttempts]);

  const showEmptyPlaceholder = !commandOutput && execStatus === "ok";

  return (
    <div className={styles.wrapper}>
      {showEmptyPlaceholder && (
        <NoDataPlaceholder title="Enter IP address and run" />
      )}
      {Result}
    </div>
  );
};

export default CmdPanel;
