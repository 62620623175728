import { FWFilters } from "../../../helpers/api/apiTypes";
import { getProtocolType } from "../../../helpers/getFirewallsFilterValues";
import {
  parseTCPFlags,
  parseTOS,
  parseTTL,
  TCP_STATES,
} from "../PBMConfig/PBMConfig";

export type ParsedFilters = {
  src?: string;
  src_port?: string;
  dst?: string;
  dst_port?: string;
  tos?: string;
  ttl?: Array<number>;
  dscp?: string | number;
  esn?: string | number;
  fragmented?: boolean;
  srcProtocolAndPort?: string;
  dstProtocolAndPort?: string;
  flags?: Array<boolean | undefined>;
  hasFlags?: boolean;
  ingress_vi?: string;
};

export function parseFilters(filters: Array<FWFilters>): ParsedFilters {
  const ip_protocol = getProtocolType(filters);
  const parsedFilters: ParsedFilters = {};

  filters.map((val) => {
    if (["src_group", "src_network"].includes(val.filter_type)) {
      parsedFilters.src = val.values as string;
    } else if (val.filter_type === "src_l4_port") {
      parsedFilters.src_port = val.values as string;
    } else if (val.filter_type === "ingress_vi") {
      parsedFilters.ingress_vi = val.values as string;
    } else if (val.filter_type === "dst_l4_port") {
      parsedFilters.dst_port = val.values as string;
    } else if (["dst_group", "dst_network"].includes(val.filter_type)) {
      parsedFilters.dst = val.values as string;
    } else if (val.filter_type === "tos") {
      parsedFilters.tos = val.values as string;
    } else if (val.filter_type === "is_fragmented") {
      parsedFilters.fragmented = val.values as boolean;
    } else if (val.filter_type === "ttl") {
      parsedFilters.ttl = parseTTL(val.values as string);
    } else if (val.filter_type === "tcp_control") {
      parsedFilters.flags = [];
      const flags = parseTCPFlags(val.values as string);
      let hasFlags = false;
      flags.map((flag) => {
        const flagDetected =
          flag === TCP_STATES.SET
            ? true
            : flag === TCP_STATES.UNSET
            ? false
            : undefined;
        if (!hasFlags && flagDetected !== undefined) hasFlags = true;
        parsedFilters.flags?.push(flagDetected);
      });
      parsedFilters.hasFlags = hasFlags;
    }
  });

  let srcProtocolAndPort =
    ip_protocol + (parsedFilters.src_port ? ": " + parsedFilters.src_port : "");
  if (ip_protocol === "Any" && parsedFilters.src_port === "Any") {
    srcProtocolAndPort = "Any";
  }

  let dstProtocolAndPort =
    ip_protocol + (parsedFilters.dst_port ? ": " + parsedFilters.dst_port : "");
  if (ip_protocol === "Any" && parsedFilters.dst_port === "Any") {
    dstProtocolAndPort = "Any";
  }

  if (!parsedFilters.dst_port) parsedFilters.dst_port = "Any";

  parsedFilters.srcProtocolAndPort = srcProtocolAndPort;
  parsedFilters.dstProtocolAndPort = dstProtocolAndPort;

  if (parsedFilters.tos) {
    [parsedFilters.dscp, parsedFilters.esn] = parseTOS(parsedFilters.tos);
  }
  return parsedFilters;
}
