import {
  CreateInterfacesType,
  CreateTenantType,
} from "../components/dialogs/Tenant/TenantDialog";
import { TenantType } from "./api/apiTypes";
import { ROUTED } from "./consts";

export const getTenantPayloadsFromParams = (
  params: CreateTenantType
): [any, any, any] => {
  const tenantPayload = getTenantBody(params);
  const viPayloads = getVIPayloads(params.interfaces);
  const l2Payloads = getL2Payloads(params.interfaces, params.name);
  return [tenantPayload, viPayloads, l2Payloads];
};

const getL2Payloads = (
  params: Array<CreateInterfacesType>,
  tenant: string
): Array<any> => {
  return params.map((el) => {
    if (el.type === "vlan") {
      return {
        type: el.type,
        system: el.system,
        payload: {
          administrative_state: "up",
          mtu: el.mtu,
          max_bandwidth: 0,
          virtual_interface_name: el.virtual_interface_name,
          vrf_name: tenant,
          alias: "",
          physical_port_name: el.physical_port_name,
          vlan_id: el.interface_id,
        },
      };
    }
    if (el.type === "vni") {
      return {
        type: el.type,
        system: el.system,
        payload: {
          administrative_state: "up",
          mtu: 9000,
          max_bandwidth: 0,
          virtual_interface_name: el.virtual_interface_name,
          vrf_name: tenant,
          alias: "",
          vni_id: el.interface_id,
          vtep_id: el.vtep_id,
          vtep_name: el.vtep_name,
          evpn_type: "VXLAN_L2",
        },
      };
    }
  });
};

const getVIPayloads = (params: Array<CreateInterfacesType>): Array<any> => {
  return params.map((el) => ({
    name: el.virtual_interface_name,
    mtu: el.mtu,
    virtual_interface_type: ROUTED,
    administrative_state: "up",
    network_segments: el.network_segments,
  }));
};

const getTenantBody = (params: CreateTenantType): Partial<TenantType> => {
  const {
    is_firewall_service,
    is_nat_service,
    is_ids_service,
    is_ddos_service,
    is_load_balancing_service,
    is_fqdn_service,
    is_secure_web_gw_service,
    is_mesh_enabled,
    is_dia_fw_service,
  } = params;
  const name = params.name;
  const full_name = params.company_name;
  const contact_info = {
    email: params.email,
    name: params.contact_name,
  };
  const systems: Array<string> = [];
  params.interfaces.map((el) => {
    if (!systems.includes(el.system)) systems.push(el.system);
  });

  return {
    name,
    full_name,
    contact_info,
    systems,
    is_firewall_service,
    is_nat_service,
    is_ids_service,
    is_ddos_service,
    is_load_balancing_service,
    is_secure_web_gw_service,
    is_fqdn_service,
    quota_package_name: "Pro",
    is_mesh_enabled,
    is_dia_fw_service,
  };
};
