export function parseBandwidth(
  bandwidth: string | number
): { value: number; unit: string } {
  if (typeof bandwidth === "number") {
    if (bandwidth >= 1000000) {
      return { value: bandwidth / 1000000, unit: "Gbps" };
    } else if (bandwidth >= 1000) {
      return { value: bandwidth / 1000, unit: "Mbps" };
    } else {
      return { value: bandwidth, unit: "Kbps" };
    }
  }

  const regex = /^(\d+(?:\.\d+)?)\s*(\w+)$/;
  const match = String(bandwidth).match(regex);

  return match
    ? { value: parseFloat(match[1]), unit: match[2] }
    : { value: 0, unit: "" };
}
