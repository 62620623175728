import { CellProps } from "react-table";
import StatusIcon from "../../../components/icons/StatusIcon";
import LabelArray from "../../../components/common/table/LabelArray";
import RowControls, {
  ActionFuncs,
} from "../../../components/common/table/newTable/RowControls";
import styles from "./cloudsTableHeader.module.scss";
import { Layer3InterfaceNew } from "../../Layer3Interface/types";
import { getColorFromStatus } from "../../../helpers/common/colorHelpers";
import { AWS_LOWERCASE } from "../../../helpers/consts";

export const cloudsTableHeader = (
  actions: ActionFuncs<Layer3InterfaceNew>,
  type?: string
): any => {
  const baseColums = [
    {
      Header: "",
      accessor: "status",
      Cell: ({ row: { original } }: CellProps<Layer3InterfaceNew>) => {
        const status = original.operational_state;
        return <StatusIcon color={getColorFromStatus(status || "")} />;
      },
    },
    {
      Header: "Region",
      accessor: "region",
      Cell: ({ row: { original } }: CellProps<Layer3InterfaceNew>) => {
        return original.labels?.region || "";
      },
    },
    {
      Header: type === "azure" ? "VNET name" : "VPC ID",
      accessor: "vpc",
      Cell: ({ row: { original } }: CellProps<Layer3InterfaceNew>) => {
        const value =
          original.labels?.vpc_id || original.labels?.vnet_name || "";
        return (
          <span className={styles.spanValue} title={value}>
            {value}
          </span>
        );
      },
    },
    {
      Header: "Subnets (CIDR)",
      accessor: "remote_network",
      Cell: ({
        row: { original },
      }: CellProps<
        Layer3InterfaceNew & { remote_networks?: Array<string> }
      >) => {
        const subnets = original.remote_networks || [];
        return <LabelArray values={subnets} />;
      },
    },
    {
      Header: "Connection type",
      accessor: "type",
      Cell: ({
        cell: { value },
      }: CellProps<
        Layer3InterfaceNew & { remote_network?: Array<string> }
      >) => {
        const connectionType = getConnectionTypeName(value);
        return (
          <span className={styles.spanValue} title={connectionType}>
            {connectionType}
          </span>
        );
      },
    },
    {
      Header: "Connection",
      accessor: "name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: CellProps<Layer3InterfaceNew>) => {
        return (
          <RowControls
            className={styles.rowAction}
            row={row}
            actions={{
              ...actions,
            }}
          />
        );
      },
    },
  ];

  if (type === AWS_LOWERCASE) {
    baseColums.splice(3, 0, {
      Header: "VPC name",
      accessor: "labels",
      Cell: ({ row: { original } }: CellProps<Layer3InterfaceNew>) => {
        const value = original.labels?.name || "";
        return (
          <span className={styles.spanValue} title={value}>
            {value}
          </span>
        );
      },
    });
  }

  return baseColums.map((el) => ({
    ...el,
    accessor: el.accessor as keyof Layer3InterfaceNew,
  }));
};

export const getConnectionTypeName = (value: string): string => {
  switch (true) {
    case value.includes("Virtual Interface Tenant Bridge Domain"):
      return "Direct connect";
    case value.includes("Virtual Interface IPSEC"):
      return "IPSEC";
    default:
      return "";
  }
};
