/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from "react";
import { Checkbox } from "../formComponents/Checkbox";

type IndeterminateCheckboxProps = {
  indeterminate?: boolean;
  checked?: boolean;
  onChange?: (e: ChangeEvent<Element>) => void;
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, checked, onChange }: IndeterminateCheckboxProps, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: ForwardedRef<any> = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        resolvedRef={resolvedRef}
        onChange={onChange}
        isChecked={!!checked}
        indeterminate={indeterminate}
      />
    );
  }
);

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

export default IndeterminateCheckbox;
