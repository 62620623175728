import { useEffect, useMemo, useState } from "react";
import { PRes } from "../../../../../../helpers/api/Api";
import { AwsCloudCredentials } from "../../../../types";
import { useValidation } from "../../../../../../helpers/validators/Validator";
import validateAwsCloudCredentials from "../../../../../../helpers/validators/CloudCredentialsAwsValidator";
import { useFormField } from "../../../../../../helpers/hooks/useFormField";
import { DEFAULT_FIELDS } from "./constants";
import { formFieldProps } from "../../../../../../helpers/dialogs/FieldProps";
import { getDialogControls } from "../../../../../../helpers/getDialogControls";
import { Actions } from "../Actions";
import Input from "../../../../../../components/common/formComponents/Input";
import styles from "../../Forms.module.scss";

type AwsFormProps = {
  createAwsKey: (params?: AwsCloudCredentials) => PRes<any>;
  deleteAwsKey: (id?: number) => PRes<any>;
  updateAwsKey: (id?: number, params?: AwsCloudCredentials) => PRes<any>;
  activeAwsKey: AwsCloudCredentials | undefined;
  onClose: () => void;
};

export const useAwsForm = ({
  createAwsKey,
  deleteAwsKey,
  updateAwsKey,
  activeAwsKey,
  onClose,
}: AwsFormProps) => {
  const [fields, handleFieldChange, resetFields] = useFormField<
    Partial<AwsCloudCredentials>
  >(DEFAULT_FIELDS);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, validate] = useValidation<Partial<AwsCloudCredentials>>(
    validateAwsCloudCredentials,
    [fields]
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields]
  );

  const update = (params?: AwsCloudCredentials) =>
    updateAwsKey(activeAwsKey?.id, params);

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const action = isEdit ? update : createAwsKey;
      const { ok } = await action(fields as AwsCloudCredentials);
      if (ok) {
        onClose();
      }
    }
  };

  const handleDelete = async () => {
    const { ok } = await deleteAwsKey(activeAwsKey?.id);
    if (ok) {
      onClose();
    }
  };

  const intermediateState = !!(!isEdit && activeAwsKey);

  const controls = getDialogControls({
    dialogTitle: activeAwsKey ? "edit" : "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
    disableSubmit: intermediateState,
  });

  useEffect(() => {
    if (isEdit && activeAwsKey) {
      resetFields({
        access_key: activeAwsKey.access_key,
        user: activeAwsKey.user,
        secret_key: activeAwsKey.secret_key,
        resource: activeAwsKey.resource,
      });
    } else {
      resetFields(DEFAULT_FIELDS);
    }
  }, [isEdit]);

  const form = (
    <>
      {activeAwsKey && !isEdit && (
        <Actions onDelete={handleDelete} onEdit={() => setIsEdit(true)} />
      )}
      {(!activeAwsKey || isEdit) && (
        <>
          <div className={styles.fields}>
            <Input
              {...fieldProps("user")}
              label="User"
              error={errors && errors["user"]}
            />
            <Input
              {...fieldProps("resource")}
              label="Resource"
              error={errors && errors["resource"]}
            />
            <Input
              {...fieldProps("access_key")}
              label="Access Token"
              type="password"
              error={errors && errors["access_key"]}
            />
            <Input
              {...fieldProps("secret_key")}
              label="Secret Key"
              type="password"
              error={errors && errors["secret_key"]}
            />
          </div>
        </>
      )}
    </>
  );

  return { controls, form };
};
