import React, { FC, useState } from "react";
import ButtonsGroup, {
  Tab,
} from "../../../../components/common/buttons/ButtonsGroup";
import ChartsHeader from "../../../../components/common/charts/ChartsHeader/ChartsHeader";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { classNames } from "../../../../helpers/common/classNames";
import styles from "./TopPart.module.scss";
import { getDNSLink } from "../../common/dnsStatisticsHelper";
import { GTimeRange } from "../../../../components/common/charts/GraphanaLinksFactory";
import { useApplicationsDashboardContext } from "../../../ApplicationsDashboard/ApplicationObservabilityContext";
import { useDnsDashboardContext } from "../../DnsContext";

type Props = {
  timeRange: GTimeRange;
  className?: string;
};

const TABS = [
  { name: "Source IPs", icon: undefined },
  { name: "Locations", icon: undefined },
];

export const TopPart: FC<Props> = ({ timeRange, className }) => {
  const [activeTab, setActiveTab] = useState<string>(TABS[0].name);
  const handleTabSelect = (tab: Tab) => {
    setActiveTab(tab.name);
  };

  return (
    <div className={classNames(className, styles.container)}>
      <ChartsHeader
        title={""}
        controls={
          <ButtonsGroup
            tabs={TABS}
            activeTab={activeTab}
            onChange={handleTabSelect}
            withIcon
          />
        }
      />
      <StatisticsChart type={activeTab} timeRange={timeRange} />
    </div>
  );
};

const StatisticsChart: FC<{ type: string; timeRange: GTimeRange }> = ({
  timeRange,
  type,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { filters } = useDnsDashboardContext();

  switch (type) {
    case "Source IPs":
      return (
        <GrafanaGraph
          link={getDNSLink(3, timeRange, filters, selectedTenant)}
        />
      );
    case "Locations":
    default:
      return (
        <GrafanaGraph
          link={getDNSLink(9, timeRange, filters, selectedTenant)}
        />
      );
  }
};
