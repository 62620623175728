import React, { ReactElement } from "react";

function WorkFromHomeIcon(): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52837 7.26298C2.84428 7.63923 2.44167 8.37916 2.49729 9.15791L3.23335 19.4628C3.30811 20.5094 4.17899 21.3203 5.22827 21.3203H18.7838C19.8331 21.3203 20.704 20.5094 20.7787 19.4628L21.5148 9.15791C21.5704 8.37916 21.1678 7.63923 20.4837 7.26298L12.9699 3.13038C12.3697 2.80029 11.6424 2.80029 11.0422 3.13038L3.52837 7.26298ZM15.1861 16.1443C14.8976 16.4847 14.4065 16.5097 14.0892 16.2C13.537 15.6609 12.805 15.3333 12 15.3333C11.195 15.3333 10.463 15.6609 9.91077 16.2C9.59353 16.5097 9.10241 16.4847 8.81383 16.1443C8.52526 15.8038 8.54851 15.2768 8.86575 14.9671C9.69274 14.1598 10.7936 13.6667 12 13.6667C13.2064 13.6667 14.3073 14.1598 15.1343 14.9671C15.4515 15.2768 15.4748 15.8038 15.1861 16.1443ZM17.7979 13.0612C17.5093 13.4017 17.0182 13.4266 16.701 13.1169C15.4591 11.9046 13.8108 11.1667 12 11.1667C10.1893 11.1667 8.54094 11.9046 7.29904 13.1169C6.98179 13.4266 6.49067 13.4017 6.2021 13.0612C5.91353 12.7207 5.93677 12.1937 6.25401 11.884C7.77063 10.4035 9.7879 9.5 12 9.5C14.2121 9.5 16.2294 10.4035 17.746 11.884C18.0632 12.1937 18.0865 12.7207 17.7979 13.0612Z"
        fill="#59536B"
      />
    </svg>
  );
}

export default WorkFromHomeIcon;
