import styles from "./commonFilterView.module.scss";
import LabelOutline from "../../../components/common/table/LabelOutline";
import SourceIcon from "../../../components/icons/topologyButtons/SourceIcon";
import { GREY_DISABLED } from "../../../helpers/common/colorAliases";
import DestinationIcon from "../../../components/icons/topologyButtons/DestinationIcon";
import { ParsedFilters } from "./parseFilters";
import { ReactElement } from "react";

export function getSrc(parsedFilters: ParsedFilters): ReactElement | null {
  return (
    ((parsedFilters.src || parsedFilters.srcProtocolAndPort) && (
      <div className={styles.source}>
        <LabelOutline type="font" className={styles.back}>
          <div>
            <SourceIcon color={GREY_DISABLED} />
            <span className={styles.prefix}>SRC:</span>
            <span>{parsedFilters.src}</span>
          </div>
        </LabelOutline>
        <LabelOutline type="font">
          {parsedFilters.srcProtocolAndPort}
        </LabelOutline>
      </div>
    )) ||
    null
  );
}

export function getDst(parsedFilters: ParsedFilters): ReactElement | null {
  return (
    ((parsedFilters.dst || parsedFilters.dst_port) && (
      <div className={styles.source}>
        <LabelOutline type="font" className={styles.back}>
          <div>
            <DestinationIcon color={GREY_DISABLED} />
            <span className={styles.prefix}>DST:</span>
            <span>{parsedFilters.dst}</span>
          </div>
        </LabelOutline>
        <LabelOutline type="font">
          {parsedFilters.dstProtocolAndPort}
        </LabelOutline>
      </div>
    )) ||
    null
  );
}

export function getSourceVRF(
  vrf: string,
  parsedFilters: ParsedFilters
): ReactElement | null {
  return (
    (parsedFilters.ingress_vi && (
      <div className={styles.source}>
        <LabelOutline type="font" className={styles.back}>
          <div>
            <SourceIcon color={GREY_DISABLED} />
            <span className={styles.prefix}>VRF:</span>
            <span>{vrf}</span>
          </div>
        </LabelOutline>
        <LabelOutline type="font">
          {"ingress: "}
          {parsedFilters.ingress_vi}
        </LabelOutline>
      </div>
    )) ||
    null
  );
}

export function getDstVRF(vrf: string): ReactElement {
  return (
    <div className={styles.source}>
      <LabelOutline type="font" className={styles.back}>
        <div>
          <DestinationIcon color={GREY_DISABLED} />
          <span className={styles.prefix}>VRF:</span>
          <span>{vrf}</span>
        </div>
      </LabelOutline>
    </div>
  );
}
