import { ReactElement } from "react";
import CloudSection from "../components/IpsecConnectionCloud/components/CloudSection";
import { AWS_LOWERCASE } from "../../../../../helpers/consts";

export const renderSectionByCloudOperator = (
  cloudOperator: string,
  className?: string
): ReactElement | undefined => {
  switch (cloudOperator) {
    case AWS_LOWERCASE:
      return <CloudSection className={className} />;
    default:
      null;
  }
};
