import { FC, PropsWithChildren, useEffect } from "react";
import placeholder from "./common/placeholder.svg";
import { useHistory } from "react-router-dom";
import { SERVICES_PREFERENCES } from "../../helpers/navigation/entries";
import styles from "./ServicesRedirectPlaceholder.module.scss";
import { withContexts } from "../../helpers/hocs/withContexts";
import {
  ServicesPreferencesContextContainer,
  useServicesPreferencesContext,
} from "../../pages/ServicesPreferencesPage/ServicesPreferencesContext";
import InfoIcon from "../icons/InfoIcon";
import { GREY_DISABLED } from "../../helpers/common/colorAliases";
import { Toggle } from "../common/Toggle";
import { PageLoader } from "../common/loadStates/LoaderIcon";
import { ServiceType } from "../../helpers/types";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { usePricingDialog } from "../../contexts/pricingDialogContext/PricingDialogContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { usePackagesContext } from "../../contexts/PackagesContext";
import { classNames } from "../../helpers/common/classNames";

type Props = {
  type: ServiceType;
  withoutInfo?: boolean;
};

const ServicesRedirectPlaceholder: FC<PropsWithChildren<Props>> = ({
  type,
  withoutInfo,
  children,
}) => {
  const history = useHistory();
  const {
    service,
    fetchService,
    editService,
    fetchStatus,
    error,
  } = useServicesPreferencesContext();
  const { packagesListState, isPackagesVisible } = usePackagesContext();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();
  const { openDialog } = usePricingDialog();
  const adminMode = servicesStatus?.[type]?.adminSide;

  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedTenant && type) {
      fetchService(selectedTenant, type);
    }
  }, [selectedTenant, type]);

  const handleEditService = async () => {
    if (!service || !selectedTenant) return;

    const { ok: isSigned } = await openDialog(service.type, {
      name: service.type,
    });

    if (!isSigned) return;

    const params = {
      type: service.type,
      enable: !service.enable,
      data: service.data,
    };
    const res = await editService(params, selectedTenant);
    if (res) {
      fetchServicesStatus(selectedTenant);
    }
  };

  const title = adminMode
    ? " service was disabled by the administrator"
    : " service is disabled";

  if (
    servicesStatusRequest?.state === "pending" ||
    fetchStatus?.state === "pending" ||
    packagesListState?.isLoading
  )
    return <PageLoader />;

  if (servicesStatus && servicesStatus[type]?.overall) return <>{children}</>;

  return (
    <div
      className={classNames(styles.wrapper, withoutInfo && styles.withoutInfo)}
    >
      <div className={styles.placeholderWrapper}>
        <img src={placeholder} alt="" />
        <div className={styles.title}>
          {service?.title}
          {title}
        </div>
        {adminMode ? (
          <div className={styles.descr}>
            Please ask your administrator to enable this service
          </div>
        ) : (
          <div className={styles.descr}>
            <p>
              You can enable it in the{" "}
              <span
                onClick={() => {
                  history.replace("/" + SERVICES_PREFERENCES().path);
                }}
              >
                {isPackagesVisible ? "Packages" : "Services"} page
              </span>
            </p>
            {!isPackagesVisible && (
              <p onClick={() => handleEditService()}>
                or enable {service?.title} now{" "}
                <Toggle
                  id={service?.type}
                  isChecked={!!service?.enable}
                  onChange={() => undefined}
                />
              </p>
            )}
            {error && <span className={styles.error}>{error}</span>}
          </div>
        )}
      </div>
      {!withoutInfo && (
        <div className={styles.infoWrapper}>
          <div className={styles.header}>
            <InfoIcon color={GREY_DISABLED} />
            Information
          </div>
          <div className={styles.info}>{service?.description}</div>
        </div>
      )}
    </div>
  );
};

export default withContexts<PropsWithChildren<Props>>(
  ServicesRedirectPlaceholder,
  [ServicesPreferencesContextContainer]
);
