import React, { ReactElement, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";

import styles from "./NavigationBar.module.scss";
import { classNames } from "../../helpers/common/classNames";

import WithSubList from "../common/WithSubList";

import { UserRoles, UserSecondRoles } from "../../helpers/rolesHelpers";
import { TenantParams, useUserContext } from "../../contexts/UserContext";

import { zayoTenantFormButtons } from "./components/zayoTenantFormButtons";
import { tenantFormButtons } from "./components/tenantFormButtons";
import { adminFormButtons } from "./components/adminFormButtons";
import { zayoAdminFormButtons } from "./components/zayoAdminFormButtons";
import { editorFormButtons } from "./components/editorFormButtons";
import { usePackagesContext } from "../../contexts/PackagesContext";

const getNavButtons = (
  role: UserRoles,
  secondRole: UserSecondRoles,
  goTo: any,
  isDemo?: boolean,
  isZayo?: boolean,
  tenantParams?: TenantParams,
  isPackagesVisible?: boolean
) => {
  switch (role) {
    case UserRoles.ADMIN:
      return secondRole === "editor"
        ? editorFormButtons(goTo, isPackagesVisible)
        : isZayo || isDemo
        ? zayoAdminFormButtons(goTo, isPackagesVisible)
        : adminFormButtons(goTo, isPackagesVisible);
    case UserRoles.TENANT:
      return secondRole === "remote_user"
        ? []
        : isZayo || isDemo
        ? zayoTenantFormButtons(goTo, isDemo, tenantParams, isPackagesVisible)
        : tenantFormButtons(goTo, tenantParams, isPackagesVisible);
    default:
      return [];
  }
};

export default function NavigationBar(): ReactElement {
  const history = useHistory();
  const {
    user,
    tenantParams,
    checkTenantParams,
    isZayo,
    isDemo,
  } = useUserContext();
  const { isPackagesVisible } = usePackagesContext();

  useEffect(() => {
    checkTenantParams(user);
  }, []);

  const navButtons = useMemo(
    () =>
      user?.role && user?.secondRole
        ? getNavButtons(
            user?.role,
            user?.secondRole,
            history.push,
            isDemo,
            isZayo,
            tenantParams,
            isPackagesVisible
          )
        : [],
    [user?.role, tenantParams, isPackagesVisible]
  );

  const containerClasses = classNames(
    styles.container,
    isZayo === true && styles.zayoContainer
  );

  return (
    <div className={containerClasses}>
      {navButtons.map((el, i) => {
        const middle = Math.round(navButtons.length / 2 - 1);
        const withSeparator =
          (user.role === UserRoles.TENANT && i === middle && !isZayo) ||
          el.withSeparator;
        const Tab = (): ReactElement => (
          <NavLink
            key={el.path}
            to={"/" + el.path}
            id={el.path}
            className={(active) =>
              classNames(
                styles.tabButton,
                active && styles.active,
                el.disabled && styles.disabled
              )
            }
          >
            <el.Icon
              color={
                el.disabled
                  ? "var(--bottom-nav-active-background)"
                  : "var(--bottom-nav-active-color)"
              }
            />
            {el.content}
          </NavLink>
        );
        if (el.subList && !el.disabled) {
          return (
            <>
              <WithSubList list={el.subList} key={el.path}>
                <Tab />
              </WithSubList>
              {withSeparator && <div className={styles.separator} />}
            </>
          );
        }
        return (
          <>
            <Tab />
            {withSeparator && <div className={styles.separator} />}
          </>
        );
      })}
    </div>
  );
}
