import { FC, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import { orderedPortsTableHeader } from "./table/orderedPortsTableHeader";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { useOrderedPortsContext } from "./OrderedPortsContext";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { CustomerPortType } from "../Network/UnitedConnections/types";
import CreateL2Dialog from "./dialog/CreateL2Dialog";
import ConfirmDialog from "../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../helpers/getDialogControls";
import TableControlsOrderedPorts from "./table/TableControlsOrderedPorts";
import { PENDING_CAPITAL } from "../../helpers/consts";

const OrderedPortsPageContent: FC = () => {
  const {
    listStatus,
    list = [],
    count,
    fetchData,
    deleteCustomerPort,
    deleteRequestStatus,
    selectedOrderedPortsMode,
  } = useOrderedPortsContext();
  const [viewItem, setViewItem] = useState<any>();
  const [connectionToDelete, setConnectionToDelete] = useState<any>();

  const fetchWrapper = () => {
    fetchData();
  };

  const rowActions: ActionFuncs<CustomerPortType> | undefined = useMemo(
    () =>
      selectedOrderedPortsMode === PENDING_CAPITAL
        ? {
            onCreatePlus: setViewItem,
            onDelete: (row) => {
              setConnectionToDelete(row);
            },
          }
        : undefined,
    [selectedOrderedPortsMode]
  );

  const handleConnectionDelete = async () => {
    if (!connectionToDelete) {
      return;
    }

    const { tenant_name, name } = connectionToDelete;
    const { ok } = await deleteCustomerPort(tenant_name, name);
    if (ok) {
      setConnectionToDelete(null);
    }
  };

  return (
    <>
      {connectionToDelete && (
        <ConfirmDialog
          status={deleteRequestStatus}
          icon={DeleteIcon}
          title="Delete Customer Port"
          message={`Are you sure you want to delete ${connectionToDelete?.name}?`}
          controls={getDeleteDialogControls({
            onDelete: handleConnectionDelete,
            onCancel: () => setConnectionToDelete(null),
          })}
        />
      )}
      {viewItem ? (
        <CreateL2Dialog
          type={"add"}
          systemsList={[]}
          onClose={() => {
            setViewItem(undefined);
          }}
          data={viewItem}
        />
      ) : (
        <TableWrapper
          titleProps={{
            title: `Ports (${count || 0})`,
            children: <TableControlsOrderedPorts />,
          }}
          dataStatus={listStatus}
          tablePlaceholder={["Ports", "Port"]}
        >
          <Table
            fetchAction={fetchWrapper}
            data={list}
            count={count}
            totalCount={count}
            columns={orderedPortsTableHeader()}
            header={TableHeader}
            gridColumnTemplate="180px repeat(8, 150px) 170px 200px"
            rowActions={rowActions}
          />
        </TableWrapper>
      )}
    </>
  );
};
export default OrderedPortsPageContent;
