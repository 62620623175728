import React, { FC, useCallback, useMemo } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import { useValidation } from "../../../helpers/validators/Validator";
import { NewDialogProps } from "../../../helpers/types";
import VirtualInterfaceDialogContextContainer, {
  useVirtualInterfaceDialogContext,
} from "./VirtualInterfaceDialogContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import { VirtualInterface, VirtualInterfaceUpdate } from "../types";
import FieldsGroup from "../../../components/common/FieldsGroup";
import { validateVirtInterfaceEdit } from "../../../helpers/validators/VirtInterfaceValidator";
import useFieldGroup from "../../../helpers/hooks/useFieldGroup";
import { isSame } from "../../../helpers/common/submitHelper";
import { getMissedIPs, getUniqueIPs } from "../utils/getUniqueIPs";
import styles from "./VirtualInterfaceEditDialog.module.scss";
import { formFieldProps } from "../../../helpers/dialogs/FieldProps";
import Input from "../../../components/common/formComponents/Input";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { capitalizeFirstLetter } from "../../../helpers/common/stringHelpers";
import { ADMIN_STATE } from "../../../helpers/common/constantsAlias";

const getDefaultFields = (data?: VirtualInterface): VirtualInterfaceUpdate => ({
  add_ip_addresses: data ? [...data?.ip_addresses] : [],
  delete_ip_addresses: [],
  description: data?.description ?? "",
  mtu: data?.mtu ?? 9200,
  administrative_state: data?.administrative_state ?? "up",
});

const VirtualInterfaceAddDialog: FC<
  NewDialogProps<VirtualInterface> & { vrf: string }
> = ({ onClose, data, vrf }) => {
  const { editVI, editStatus } = useVirtualInterfaceDialogContext();

  const [fields, handleFieldChange] = useFormField<VirtualInterfaceUpdate>(
    getDefaultFields(data)
  );
  const [errors, validate] = useValidation<VirtualInterfaceUpdate>(
    validateVirtInterfaceEdit,
    [fields]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk && data) {
      const formIps = fields.add_ip_addresses.filter((ip) => ip);
      const prevIps = data.ip_addresses;

      const params = {
        ...fields,
        add_ip_addresses: getUniqueIPs(prevIps, formIps),
        delete_ip_addresses: getMissedIPs(prevIps, formIps),
      };
      const success = await editVI(params, data, vrf);
      if (success) onClose();
    }
  };

  const handleFieldsGroup = useCallback(
    (i: number, name: string, value: string | number) => {
      const [key, fieldGroup] = useFieldGroup(i, name, value, fields);
      handleFieldChange(key, fieldGroup);
    },
    [handleFieldChange]
  );

  const disableSubmit = isSame(data, fields);

  const controls = getDialogControls({
    dialogTitle: "save",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
    disableSubmit: disableSubmit,
  });

  const leftColumn = (
    <SummaryBlock title={"Virtual Interface"} data={data} vrf={vrf} />
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  return (
    <DialogTemplate
      title={"Edit Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={editStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        <FieldsGroup
          name="add_ip_addresses"
          placeholder="X.X.X.X/X or X::X/X"
          fields={fields?.add_ip_addresses || []}
          title="IP addresses"
          onChange={handleFieldsGroup}
          errors={errors}
          isOptional
          medium
        />

        <DropdownBasic
          id={"virtualInterfaceAddDialogAdminState"}
          onChange={(val) =>
            handleFieldChange("administrative_state", val.key.toLowerCase())
          }
          selected={mapStringToItem(
            capitalizeFirstLetter(fields.administrative_state) || ""
          )}
          label="Admin State"
          itemsList={ADMIN_STATE.map((el) =>
            mapStringToItem(capitalizeFirstLetter(el))
          )}
          isMedium
        />
        <Input
          {...fieldProps("mtu")}
          label="MTU"
          type="number"
          min={64}
          max={20000}
        />
        <Input
          {...fieldProps("description")}
          label="Description"
          isOptional
          isFullValue
        />
      </div>
    </DialogTemplate>
  );
};

export default withContexts<NewDialogProps<VirtualInterface> & { vrf: string }>(
  VirtualInterfaceAddDialog,
  [VirtualInterfaceDialogContextContainer]
);
