import { ReactNode, useState } from "react";
import { default as TableWrapperDefault } from "../../../../components/common/table/newTable/layout/TableWrapper";
import styles from "./TableWrapper.module.scss";
import { CLOUD_RESOURCES_TABS } from "../../constants";
import TabsComponent from "../../../../components/common/navigation/TabsComponent";
import { OK_STATUS } from "../../../../components/const/api";
import ReleaseButton from "../../../../components/common/buttons/ReleaseButton";

type TableWrapperProps = {
  items: Array<unknown> | undefined | null;
  regionTab: ReactNode;
  nniTab: ReactNode;
  refetch: () => Promise<void>;
  isRefetching: boolean;
};

export const TableWrapper = ({
  items,
  regionTab,
  nniTab,
  refetch,
  isRefetching,
}: TableWrapperProps) => {
  const [activeTab, setActiveTab] = useState<string>(CLOUD_RESOURCES_TABS[0]);

  return (
    <TableWrapperDefault
      titleProps={{
        title: (
          <TabsComponent
            tabs={CLOUD_RESOURCES_TABS}
            activeTab={activeTab}
            setTabActive={(val) => setActiveTab(val)}
            isBtnMode
          />
        ),
        children: (
          <ReleaseButton
            onClick={refetch}
            id="refetch"
            isLoading={isRefetching}
            disabled={isRefetching}
          />
        ),
      }}
      dataStatus={OK_STATUS}
      tablePlaceholder={["", ""]}
      isEmpty={false}
      className={styles.table}
    >
      {items?.length ? (
        <>
          {activeTab === CLOUD_RESOURCES_TABS[0] && regionTab}
          {activeTab === CLOUD_RESOURCES_TABS[1] && nniTab}
        </>
      ) : (
        <div className={styles.noCloudSites}>Cloud sites not found</div>
      )}
    </TableWrapperDefault>
  );
};
