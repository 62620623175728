import { useEffect, useMemo, useState } from "react";
import { AsyncDropdown } from "../../../../../../components/common/Dropdown/AsyncDropdown";
import PhysicalPortsDropdown from "../../../../../../components/common/OneSystem/PhysicalPortsDropdown";
import { formFieldProps } from "../../../../../../helpers/dialogs/FieldProps";
import { getDialogControls } from "../../../../../../helpers/getDialogControls";
import { GCPNNI, CreateGCPNNI } from "../../../../types";
import { DEFAULT_FIELDS, VLAN_POOL_MAX, VLAN_POOL_MIN } from "./constants";
import { useFormField } from "../../../../../../helpers/hooks/useFormField";
import { useValidation } from "../../../../../../helpers/validators/Validator";
import { PRes } from "../../../../../../helpers/api/Api";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../Forms.module.scss";
import gcpStyles from "./GCPForm.module.scss";
import { systemApi } from "../../../../../../helpers/api/SystemApi";
import Input from "../../../../../../components/common/formComponents/Input";
import DropdownWithSearch from "../../../../../../components/common/Dropdown/DropdownWithSearch";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import { useFetch } from "../../../../../../helpers/hooks/useFetch";
import { configApi } from "../../../../../../helpers/api/ConfigApi";
import validateGCPNNI from "../../../../../../helpers/validators/CreateGCPNNIValidator";
import { OptionalLabel } from "../../../../../../components/common/OptionalLabel";
import { classNames } from "../../../../../../helpers/common/classNames";
import LabelArray from "../../../../../../components/common/table/LabelArray";
import { ZoneSelect } from "./ZoneSelect";
import { createGCPSiteObject } from "./helpers";

type GCPFormProps = {
  createGCPNNI: (params?: CreateGCPNNI) => PRes<any>;
  gcpNNIOptions: Array<GCPNNI>;
  onClose: () => void;
};

const getBandwidthLabel = (bandwidth: number | undefined) =>
  bandwidth ? Math.floor(bandwidth / 1000000) : undefined;

const getBandwidthValue = (bandwidth: number | undefined) =>
  bandwidth ? bandwidth * 1000000 : undefined;

export const useGCPForm = ({
  createGCPNNI,
  onClose,
  gcpNNIOptions,
}: GCPFormProps) => {
  const { fetch: createSite } = useFetch({
    fetchFn: configApi.createSite.bind(configApi),
  });

  const [fields, handleFieldChange] = useFormField<Partial<CreateGCPNNI>>(
    DEFAULT_FIELDS
  );
  const [primarySystemLocation, setPrimarySystemLocation] = useState("");
  const selectedNNI = useMemo(
    () => gcpNNIOptions.find((option) => option.nni_id === fields.primary_nni),
    [fields.primary_nni]
  );
  const regionOptions = selectedNNI?.gcp_regions ?? [];
  const bandwidth = getBandwidthLabel(selectedNNI?.max_bandwidth);

  const [errors, validate] = useValidation<Partial<CreateGCPNNI>>(
    validateGCPNNI(bandwidth),
    [fields]
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();

    if (isOk) {
      const { ok } = await createGCPNNI({
        ...fields,
        managed_max_bandwidth: getBandwidthValue(fields.managed_max_bandwidth),
      } as CreateGCPNNI);
      if (ok) {
        createSite(
          createGCPSiteObject(fields as CreateGCPNNI, primarySystemLocation)
        );
        onClose();
      }
    }
  };

  const addRegion = (value: string) => {
    const current = fields.regions ?? [];
    const next = [...new Set([...current, value])];
    handleFieldChange("regions", next);
  };

  const removeRegion = (value: string) => {
    const current = fields.regions ?? [];
    const next = current.filter((el) => el !== value);
    handleFieldChange("regions", next);
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const lastRegion = fields.regions?.[fields?.regions.length - 1];

  useEffect(() => {
    if (!fields.managed_max_bandwidth && selectedNNI) {
      handleFieldChange(
        "managed_max_bandwidth",
        getBandwidthLabel(selectedNNI.max_bandwidth)
      );
    }
  }, [selectedNNI]);

  const form = (
    <div className={classNames(styles.fields, gcpStyles.gcpFields)}>
      <div className={gcpStyles.nni}>
        <DropdownWithSearch
          label="NNI Primary"
          id="primary_nni"
          onChange={(val) => {
            handleFieldChange("primary_nni", val.key);
            const nni = gcpNNIOptions.find(
              (option) => option.nni_id === val.key
            );
            handleFieldChange(
              "managed_max_bandwidth",
              getBandwidthLabel(nni?.managed_max_bandwidth)
            );
          }}
          selected={mapStringToItem(fields.primary_nni || "")}
          valuesList={gcpNNIOptions.map((item) => item.nni_id)}
          isMedium
          error={(errors && errors["primary_nni"]) ?? ""}
          listClassName={styles.dropdown}
          placeholder={fields.primary_nni || ""}
        />
        <ZoneSelect
          value={fields.primary_zone}
          onChange={(val) => {
            handleFieldChange("primary_zone", val);
          }}
          hasErrors={!!errors?.["primary_nni"]}
        />
      </div>
      <AsyncDropdown
        id="primary_system_name"
        {...fieldProps("primary_system_name")}
        fetchFn={() => systemApi.getSystemsList()}
        mapValue={(value) => ({ key: value.name, value: value.name })}
        selected={fields.primary_system_name}
        onChange={(value) => {
          handleFieldChange("primary_system_name", value.key);
          handleFieldChange("primary_physical_port_name", "");
          setPrimarySystemLocation(value.data?.location ?? "");
        }}
        label="Primary System"
        isMedium
        error={errors && errors["primary_system_name"]}
      />
      <PhysicalPortsDropdown
        label="Primary Port"
        system={fields.primary_system_name}
        selected={fields.primary_physical_port_name}
        onSelect={(val) => handleFieldChange("primary_physical_port_name", val)}
        id={"primary_physical_port_name"}
        error={errors && errors["primary_physical_port_name"]}
      />
      <Input
        {...fieldProps("managed_max_bandwidth")}
        label="NNI Bandwidth"
        error={errors && errors["managed_max_bandwidth"]}
        disabled={bandwidth === undefined}
      />
      <div className={gcpStyles.regions}>
        <DropdownWithSearch
          label="Regions"
          id="regions"
          onChange={(val) => {
            addRegion(val.key);
          }}
          selected={mapStringToItem(lastRegion || "")}
          valuesList={regionOptions}
          isMedium
          error={(errors && errors["regions"]) ?? ""}
          listClassName={styles.dropdown}
          placeholder={lastRegion || ""}
          disabled={!selectedNNI}
        />
        <LabelArray
          values={fields.regions}
          isRemovable
          onClick={removeRegion}
          className={gcpStyles.regionsLabels}
          withWrap
        />
      </div>
      <div className={gcpStyles.nni}>
        <DropdownWithSearch
          label="NNI Secondary"
          id="secondary_nni"
          onChange={(val) => {
            handleFieldChange("secondary_nni", val.key);
          }}
          selected={mapStringToItem(fields.secondary_nni || "")}
          valuesList={gcpNNIOptions.map((item) => item.nni_id)}
          isMedium
          error={(errors && errors["secondary_nni"]) ?? ""}
          listClassName={styles.dropdown}
          placeholder={fields.secondary_nni || ""}
        />
        <ZoneSelect
          value={fields.secondary_zone}
          onChange={(val) => {
            handleFieldChange("secondary_zone", val);
          }}
          hasErrors={!!errors?.["secondary_nni"]}
        />
      </div>
      <AsyncDropdown
        id="secondary_system_name"
        {...fieldProps("secondary_system_name")}
        fetchFn={() => systemApi.getSystemsList()}
        mapValue={(value) => ({ key: value.name, value: value.name })}
        selected={fields.secondary_system_name}
        onChange={(value) => {
          handleFieldChange("secondary_system_name", value.key);
          handleFieldChange("secondary_physical_port_name", "");
        }}
        label="Secondary System"
        isMedium
        error={errors && errors["secondary_system_name"]}
      />
      <PhysicalPortsDropdown
        label="Secondary Port"
        system={fields.secondary_system_name}
        selected={fields.secondary_physical_port_name}
        onSelect={(val) =>
          handleFieldChange("secondary_physical_port_name", val)
        }
        id={"secondary_physical_port_name"}
        error={errors && errors["secondary_physical_port_name"]}
      />
      <OptionalLabel text="VLAN Pool" isShown className={styles.vlanPoolLabel}>
        <div className={styles.vlanPool}>
          <Input
            name="vlan_pool_from"
            value={fields.vlan_pool?.from}
            medium
            onChange={(e) =>
              handleFieldChange("vlan_pool", {
                ...fields.vlan_pool,
                from: +e.target.value,
              })
            }
            type="number"
            min={VLAN_POOL_MIN}
            max={VLAN_POOL_MAX}
            error={errors && errors["vlan_pool_from"]}
          />
          <span>-</span>
          <Input
            name="vlan_pool_to"
            value={fields.vlan_pool?.to}
            medium
            onChange={(e) =>
              handleFieldChange("vlan_pool", {
                ...fields.vlan_pool,
                to: +e.target.value,
              })
            }
            type="number"
            min={VLAN_POOL_MIN}
            max={VLAN_POOL_MAX}
            error={errors && errors["vlan_pool_to"]}
          />
        </div>
      </OptionalLabel>
    </div>
  );

  return { controls, form };
};
