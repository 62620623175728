/* eslint-disable css-modules/no-unused-class */
import React, { FC } from "react";
import styles from "./PortItem.module.scss";
import AwsIcon from "../../../../../../components/icons/interfaceTypes/AWSIcon";
import PortDetails from "./PortDetails";
import { TenantCustomerPort, TenantPort } from "../../../types";
import { VirtualInterfaceApi } from "../../../../../VirtualInterfacePage/types";
import CloudPortIcon from "../../../../../../components/icons/CloudPortIcon";

interface AWSPortInfoProps {
  port: TenantCustomerPort;
  i: number;
  hidden: boolean;
  operatorIcon: JSX.Element | string;
  operator: string | JSX.Element;
  pending: boolean;
  filteredConnections: Array<VirtualInterfaceApi>;
  selectPortToDelete: (port: TenantCustomerPort) => void;
  setHidden: (value: boolean) => void;
  getConnectionsCount: () => JSX.Element;
  addConnectionBtn: JSX.Element;
  hasFreeBw: boolean;
  parentPort: TenantPort | undefined;
  usedBW: number;
  freeBW: number;
  getConnectionDetail: (connection: VirtualInterfaceApi) => JSX.Element;
}

const AWSPortInfo: FC<AWSPortInfoProps> = ({
  port,
  hidden,
  operatorIcon,
  operator,
  pending,
  filteredConnections,
  selectPortToDelete,
  setHidden,
  getConnectionsCount,
  addConnectionBtn,
  getConnectionDetail,
}) => {
  const additionalDetails = (
    <div className={styles.unitWrapper}>
      <AwsIcon />
      <div className={styles.unit}>
        <span className={styles.unitTitle}>Region:</span>
        <span className={styles.unitInfo}>{port.labels?.cloud_region}</span>
      </div>
    </div>
  );
  return (
    <PortDetails
      portName={port.name}
      location={port.location || port.labels?.naas_location || "-"}
      usedBW={port.used_bw}
      freeBW={port.assigned_bw - port.used_bw}
      assignedBW={port.assigned_bw}
      operatorIcon={operatorIcon}
      portIcon={<CloudPortIcon />}
      port={port}
      operator={operator}
      hidden={hidden}
      hasConnections={!!filteredConnections.length}
      pending={pending}
      selectPortToDelete={() => selectPortToDelete(port)}
      setHidden={setHidden}
      additionalDetails={additionalDetails}
      getConnectionsCount={getConnectionsCount}
      addConnectionBtn={addConnectionBtn}
      filteredConnections={filteredConnections}
      getConnectionDetail={getConnectionDetail}
      hasFreeBw={filteredConnections.length === 0} // a workaround - we shouldn't show add connection button if there are connections on aws port
    />
  );
};

export default AWSPortInfo;
