import Api from "./Api";
import { ISO } from "./apiPaths";

export class IsoApi extends Api {
  constructor() {
    super();
  }

  async getVersions() {
    return await this.fetchBroker<Array<string>>(ISO.GET_VERSIONS());
  }

  async deleteVersion(version?: string) {
    return await this.fetchBroker<null>(ISO.DELETE_VERSION(version));
  }

  async checkVersion() {
    return await this.fetchBroker<any>(ISO.CHECK_VERSION());
  }

  async downloadByUrl(url?: string) {
    return await this.fetchBroker<any>(ISO.DOWNLOAD_BY_URL(url), {
      body: JSON.stringify({ link: url }),
    });
  }

  async checkDownloadStatus(taskId?: string) {
    return await this.fetchBroker<any>(ISO.CHECK_DOWNLOAD_STATUS(taskId));
  }
}

export const isoApi = new IsoApi();
