import {
  VLAN_POOL_MAX,
  VLAN_POOL_MIN,
} from "../../pages/CloudResourcesPage/components/AddNNIDialog/components/GCPForm/constants";
import { CreateGCPNNI } from "../../pages/CloudResourcesPage/types";
import Validator from "./Validator";

export default function validateGCPNNI(maxBandwidth: number | undefined) {
  return (
    data: Partial<CreateGCPNNI>,
    initValidator?: Validator
  ): Validator => {
    const validator = initValidator || new Validator();
    validator.checkEmpty(data.primary_nni, "primary_nni");
    validator.checkEmpty(
      data.primary_physical_port_name,
      "primary_physical_port_name"
    );
    validator.checkEmpty(data.primary_system_name, "primary_system_name");
    validator.checkEmpty(data.primary_zone, "primary_zone");

    validator.checkEmpty(data.secondary_nni, "secondary_nni");
    validator.checkEmpty(
      data.secondary_physical_port_name,
      "secondary_physical_port_name"
    );
    validator.checkEmpty(data.secondary_system_name, "secondary_system_name");
    validator.checkEmpty(data.secondary_zone, "secondary_zone");

    validator.checkEmpty(data.managed_max_bandwidth, "managed_max_bandwidth");
    validator.checkEmptyArray(data.regions, "regions");

    if (maxBandwidth !== undefined) {
      validator.checkMinMax(
        data.managed_max_bandwidth,
        [0, maxBandwidth],
        "managed_max_bandwidth"
      );
    }

    if (
      data.primary_system_name === data.secondary_system_name &&
      data.primary_physical_port_name &&
      data.secondary_physical_port_name
    ) {
      validator.checkTrue(
        data.primary_physical_port_name !== data.secondary_physical_port_name,
        "secondary_physical_port_name",
        "Secondary Port should not be equal to Primary"
      );
    }

    validator.checkMinMax(
      data.vlan_pool?.from,
      [VLAN_POOL_MIN, VLAN_POOL_MAX],
      "vlan_pool_from"
    );
    validator.checkMinMax(
      data.vlan_pool?.to,
      [VLAN_POOL_MIN, VLAN_POOL_MAX],
      "vlan_pool_to"
    );

    return validator;
  };
}
