import { usePricingDialog } from "../../../../../contexts/pricingDialogContext/PricingDialogContext";
import DialogTemplateOneColumn from "../../../common/DialogTemplateOneColumn";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./PricingDialog.module.scss";
import { classNames } from "../../../../../helpers/common/classNames";
import CheckboxIcon from "../../../../icons/CheckboxIcon";
import { Money } from "../../../../common/Money";
import { PricingType } from "./types";
import Loader from "../../../../common/Loader/Loader";

type PricingDialogProps = {
  pricingList: Array<PricingType> | undefined;
  isLoading: boolean;
};

export const PricingDialog = ({
  pricingList,
  isLoading,
}: PricingDialogProps) => {
  const {
    closeDialog,
    setOpenModal,
    selectedPrice,
    selectPrice,
  } = usePricingDialog();

  if (!pricingList?.length) {
    setOpenModal("terms");

    return null;
  }

  return (
    <DialogTemplateOneColumn
      header={<span className={styles.title}>Payment</span>}
      onClose={closeDialog}
      controls={{
        okText: "Continue",
        disableSubmit: selectedPrice === undefined,
        onOk: () => {
          setOpenModal("terms");
        },
        cancelText: "Cancel",
        onCancel: closeDialog,
      }}
      className={styles.dialog}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <div className={styles.wrapper}>
            {pricingList?.map(
              ({
                id,
                monthly_recurring_charge,
                contract_period,
                non_recurring_charge,
                discount,
              }) => {
                const isSelected = selectedPrice?.id === id;

                return (
                  <div
                    key={id}
                    className={classNames(
                      styles.card,
                      isSelected && styles.cardSelected
                    )}
                    onClick={() => selectPrice(id)}
                  >
                    <div className={styles.cardPrice}>
                      <span className={styles.cardPriceValue}>
                        <Money {...monthly_recurring_charge} />
                      </span>
                      <span className={styles.cardPricePeriod}>Per Month</span>
                    </div>
                    <div className={styles.cardInfo}>
                      <div className={styles.cardPeriod}>
                        <span className={styles.cardPeriodTitle}>
                          Minimum period {contract_period} Month
                        </span>
                        <span>
                          <span className={styles.cardPeriodPer}>
                            Price per year{" "}
                            <Money
                              value={Math.floor(
                                monthly_recurring_charge.value * 12
                              )}
                              currency={monthly_recurring_charge.currency}
                            />{" "}
                          </span>
                          {!!non_recurring_charge.value && (
                            <span className={styles.cardPeriodFee}>
                              + Installation fee{" "}
                              <Money {...non_recurring_charge} />
                            </span>
                          )}
                        </span>
                      </div>
                      <div className={styles.cardRight}>
                        {!!discount && (
                          <div className={styles.cardDiscount}>{discount}%</div>
                        )}
                        <div
                          className={classNames(
                            styles.radio,
                            isSelected && styles.radioSelected
                          )}
                        >
                          {isSelected && <CheckboxIcon />}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
          <div className={styles.payment}>
            <div className={styles.paymentMethod}>Payment method:</div>
            <div className={styles.paymentInfo}>
              <span className={styles.paymentInfoTitle}>Monthly invoice</span>
              <span className={styles.paymentInfoText}>
                The first invoice will be prorated and issued on service
                commencement. Subsequent invoices will be issued at the
                beginning of each month.
              </span>
            </div>
          </div>
        </>
      )}
    </DialogTemplateOneColumn>
  );
};
