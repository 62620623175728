import React, { FC } from "react";
import Section from "../../../components/common/Section";
import {
  CreateDDoS,
  DDOS_UNIT,
  getUnitTitle,
  getUnitByTitle,
} from "./DDoSConfig";
import styles from "./ActionSection.module.scss";
import Input from "../../../components/common/formComponents/Input";

type Props = {
  fields: CreateDDoS;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  errors?: any;
  className: string;
  gridTemplate: string;
};

export const ContinueAction = {
  key: "None",
  data: "ROUTE",
};

const ActionSection: FC<Props> = ({
  fields,
  onChange,
  className,
  errors,
  gridTemplate,
}) => {
  const units = [
    DDOS_UNIT.KBITS_PER_SECOND,
    DDOS_UNIT.MBITS_PER_SECOND,
    DDOS_UNIT.GBITS_PER_SECOND,
    DDOS_UNIT.PACKETS_PER_SECOND,
  ].map(getUnitTitle);

  const handleChange = (val: string) => {
    onChange("unit", getUnitByTitle(val));
  };

  return (
    <>
      <Section
        title={"Per rule"}
        className={className}
        columnsCount={3}
        gridTemplateString={gridTemplate}
      >
        <div className={styles.column}>
          <div className={styles.blocksWrapper}>
            <Input
              type="number"
              name={"max_rate"}
              handleFieldChange={onChange}
              value={fields.max_rate}
              error={errors && errors["max_rate"]}
              medium
              label="Meter"
            />
            <Input
              type="number"
              name={"flood_detect_rate"}
              handleFieldChange={onChange}
              value={fields.flood_detect_rate}
              error={errors && errors["flood_detect_rate"]}
              medium
              label="Detect rate"
              className={styles.wideInput}
            />
            <Input
              name={"unit"}
              handleFieldChange={(name: string, value: string) =>
                handleChange(value)
              }
              value={getUnitTitle(fields.unit)}
              placeholder={fields.unit}
              error={errors && errors["unit"]}
              medium
              valuesList={units}
              label="Units"
            />
          </div>
        </div>
      </Section>

      <Section
        title={"Per DUA (Device Under Attack)"}
        className={className}
        columnsCount={3}
        gridTemplateString={gridTemplate}
      >
        <div className={styles.blocksWrapper}>
          <Input
            type="number"
            name={"flood_rate"}
            handleFieldChange={onChange}
            value={fields.flood_rate}
            error={errors && errors["flood_rate"]}
            medium
            label="Flood rate"
            className={styles.wideInput}
            min={0}
          />
          <Input
            type="number"
            name={"flood_cooldown_rate"}
            handleFieldChange={onChange}
            value={fields.flood_cooldown_rate}
            error={errors && errors["flood_cooldown_rate"]}
            medium
            label="Cooldown rate"
            className={styles.wideInput}
            errorWithTooltip
            min={0}
          />
          <Input
            type="number"
            name={"hysteresis_seconds"}
            handleFieldChange={onChange}
            value={fields.hysteresis_seconds}
            error={errors && errors["hysteresis_seconds"]}
            medium
            label="Hysteresis seconds"
            className={styles.wideInput}
            min={0}
          />
        </div>
      </Section>
    </>
  );
};
export default ActionSection;
