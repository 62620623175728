import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { userApi } from "../../../helpers/api/UserApi";
import {
  resToState,
  setPending,
} from "../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../helpers/types";

type RequestStatuses = {
  userStatus?: RequestStatus;
};

type State = {
  currUser?: any;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchCurrUser: () => Promise<void>;
  fetchTenantUser: (tenant: string, user: string) => Promise<void>;
  editCurrentUser: (fields: any) => Promise<boolean>;
  editPasswordCurrentUser: (
    fields: any
  ) => Promise<{ ok: boolean; error: string }>;
};

const [UserEditDialogContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useUserEditDialogContext = useContext;

export default class UserEditDialogContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = UserEditDialogContext;

  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchCurrUser = async (): Promise<void> => {
    this.setState({ userStatus: setPending() });
    const res = await userApi.getCurrentUser();
    if (res.ok && res.result) {
      this.setState({ currUser: res.result });
    }
    this.setState({ userStatus: resToState(res) });
  };

  fetchTenantUser = async (tenant: string, user: string): Promise<void> => {
    this.setState({ userStatus: setPending() });
    const res = await userApi.getTenantUser(tenant, user);
    if (res.ok && res.result) {
      this.setState({ currUser: res.result });
    }
    this.setState({ userStatus: resToState(res) });
  };

  editCurrentUser = async (fields: any): Promise<boolean> => {
    this.setState({ userStatus: setPending() });
    const res = await userApi.editCurrentUser(fields);
    this.setState({ userStatus: resToState(res) });
    return res.ok;
  };

  editPasswordCurrentUser = async (
    fields: any
  ): Promise<{ ok: boolean; error: string }> => {
    this.setState({ userStatus: setPending() });
    const res = await userApi.editPasswordCurrentUser(fields);
    this.setState({ userStatus: resToState(res) });
    return {
      ok: res.ok,
      error: res.error ? JSON.parse(res.error).error_description : "",
    };
  };

  funcs = {
    fetchCurrUser: this.fetchCurrUser,
    editCurrentUser: this.editCurrentUser,
    editPasswordCurrentUser: this.editPasswordCurrentUser,
    fetchTenantUser: this.fetchTenantUser,
  };
}
