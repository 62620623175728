import React, { FC, useEffect, useMemo, useState } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import Table from "../../components/common/table/newTable/Table";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { withContexts } from "../../helpers/hocs/withContexts";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import { VtepVni } from "./types";
import { vniTableHeader } from "./table/vniTableColumns";
import VNIDialog from "./itemDialog/VNIDialog";
import VNIContextContainer, { useVNIContext } from "./VNIContext";

import styles from "./VNIPageContent.module.scss";

const VNIPageContent: FC = () => {
  const {
    list = [],
    fetchList,
    listStatus,
    remove,
    removeStatus,
    vtepList,
  } = useVNIContext();

  const [showAdd, setShowAdd] = useState(false);
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<VtepVni>(remove, removeStatus);

  useEffect(() => {
    fetchList();
  }, []);

  const rowActions: ActionFuncs<VtepVni> = useMemo(
    () => ({
      onDelete: tryDelete,
    }),
    [tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"VNI"}
        name={selectedToDelete?.name}
      />
      {showAdd && <VNIDialog onClose={() => setShowAdd(false)} type={"add"} />}
      <TableWrapper
        titleProps={{
          title: `${(list || []).length} VNI`,
          children: <AddButton onClick={() => setShowAdd(true)} />,
        }}
        dataStatus={listStatus}
        tablePlaceholder={["VNI", "VNI"]}
        isEmpty={list && list.length === 0}
        className={styles.tableWrapper}
      >
        <div className={styles.tableContent}>
          {vtepList
            ?.filter((vtep) => {
              const data = list?.filter((vni) => +vni.vtep_id === +vtep.id);

              return data.length;
            })
            ?.map((vtep, i) => {
              const data = list?.filter((vni) => +vni.vtep_id === +vtep.id);
              const vtepName = vtep?.name;
              return (
                <div className={styles.vniRow} key={vtepName}>
                  <Table
                    extraTitle={vtepName}
                    columns={vniTableHeader()}
                    header={i === 0 ? TableHeader : undefined}
                    data={data}
                    gridColumnTemplate={`1.5fr 1fr repeat(3, 140px) 0`}
                    rowActions={rowActions}
                  />
                </div>
              );
            })}
        </div>
      </TableWrapper>
    </>
  );
};

export default withContexts(VNIPageContent, [VNIContextContainer]);
