import { ReactElement, useEffect } from "react";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./WireGuardApp.module.scss";
import { useUserContext } from "../../contexts/UserContext";
import FormComponent from "../LoginPage/components/FormComponent";
import { useAuthContext } from "../../contexts/AuthContext";
import LogoIconWhite from "../../components/icons/LogoIconWhite";
import LoginIconsInColumn from "../../components/icons/LoginIconsInColumn";

export default function WireGuardApp(): ReactElement {
  const { checkTenantParams } = useUserContext();
  const { isAuthorized, user } = useAuthContext();

  useEffect(() => {
    isAuthorized && checkTenantParams(user);
  }, [isAuthorized]);

  if (isAuthorized) {
    window.location.href = "/assets/ok.txt";
  }
  return (
    <div className={styles.container}>
      <div className={styles.leftPart}>
        <div className={styles.leftContent}>
          <LogoIconWhite />
          <LoginIconsInColumn />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.formLogin}>
          <FormComponent />
        </div>
      </div>
    </div>
  );
}
