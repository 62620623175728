import { GTimeRange } from "./../../components/common/charts/GraphanaLinksFactory";
import Api from "./Api";
import fromIDSCountBody from "./data/idsCountBody";
import fromApplicationsFiltersBodyBody, {
  FiltersFieldType,
} from "./data/applicationsFiltersBody";
import fromAppObservabilityCountBody from "./data/applicationObservability";
import fromApplicationsCountBody from "./data/applicationsCounts";
import fromCyberThreatsCountBody from "./data/cyberThreats";
import fromElasticFilterBody from "./data/elasticFiltersBody";
import grafanaTimeRangeBody from "./data/grafanaTimeRangeBody";
import { getHistoryLogQuery } from "./elasticQueries/historyLog";
import { ElasticHistoryLogHit, ElasticResponse } from "./types";
import grafanaSystemsTimeRangeBody from "./data/grafanaSystemsTimeRangeBody";
import { elasticTenantEventsBody } from "./data/elasticTenantEventsBody";
import { extractLogsFromEvents } from "../parsingEventsToObject";
import { EventsType } from "../../pages/EventsPage/types";
import { elasticSystemEventsBody } from "./data/elasticSystemEventsBody";
import { elasticAllSystemsEventsBody } from "./data/elasticAllSystemsEventsBody";
import { elasticServiceBGPEvents } from "./data/elasticServiceBGPEvents";
import { elasticSearchUniqueQueries } from "./data/elasticSearchUniqueQueries";
import { elasticSearchNatRules } from "./data/elasticSearchNatRules";
import { elasticSearchFwRules } from "./data/elasticSearchFwRules";
import { elasticSearchSourceIps } from "./data/elasticSearchSourceIps";
import { elasticSearchDestinationIps } from "./data/elasticSearchDestinationIps";
import { elasticSearchSourceSegments } from "./data/elasticSearchSourceSegments";
import { elasticSearchDestinationSegments } from "./data/elasticSearchDestinationSegments";
import { elasticSearchCyberThreats } from "./data/elasticSearchCyberThreats";
import { elasticSearchDnsServers } from "./data/elasticSearchDnsServers";
import { elasticSearchDnsDomains } from "./data/elasticSearchDnsDomains";
import { elasticSearchHttpsDomains } from "./data/elasticSearchHttpsDomains";
import fromBlockedApplicationsCountBody from "./data/blockedApplications";
import fromThreadsSevirityBody from "./data/threatsSevirity";
import { elasticRestoreConfigInfo } from "./data/elasticRestoreConfigInfo";
import { elasticRemoteUsersStatus } from "./data/elasticRemoteUsersStatus";
import { RemoteUserStatus } from "../types";
import { elasticHAHealthInfo } from "./data/elasticHAHealthInfo";
import { elasticHAHealthLogs } from "./data/elasticHAHealthLogs";
import { elasticHALastFailover } from "./data/elasticHALastFailover";

type Res<P> = { ok: boolean; error?: string; result?: P };

const ELASTIC_ROOT_PATH = "/broker/elasticsearch";

export class ElasticApi extends Api {
  constructor() {
    super();
  }

  private async handleFetch(body: any, endpoint?: string): Promise<any> {
    const url = endpoint || `${ELASTIC_ROOT_PATH}/logsta*/_search`;
    try {
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!res.ok) {
        throw new Error(`HTTP Error: ${res.status} ${res.statusText}`);
      }

      const elasticRespone = {
        ok: res.ok,
        result: await res.json(),
      };

      return elasticRespone;
    } catch (error) {
      throw new Error(`Request Error: ${error}`);
    }
  }

  async getIDSCount(
    vrfId: string | number,
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<Res<number>> {
    const body = fromIDSCountBody(vrfId, timeRange, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result =
            result.result?.aggregations &&
            result.result.aggregations["1"]?.value;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getElasticFiltersValues(
    fieldName: string,
    tenant: string,
    eventType: string,
    timeRange: GTimeRange
  ): Promise<Res<number>> {
    const body = fromElasticFilterBody(fieldName, tenant, eventType, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result =
            result.result?.aggregations &&
            result.result.aggregations["2"]?.buckets?.map(
              (el: any) => el.key_as_string || el.key
            );
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getRemoteUserStatus(
    tenant: string,
    timeRange: GTimeRange
  ): Promise<Array<RemoteUserStatus>> {
    const body = elasticRemoteUsersStatus(tenant, timeRange);
    const users: Array<RemoteUserStatus> = [];
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          const hasUsers =
            result.result?.aggregations &&
            result.result.aggregations["2"]?.buckets?.length > 0;
          if (hasUsers) {
            const userList = result.result.aggregations["2"]?.buckets;
            userList.map((userInfo: any) => {
              const statuses = userInfo?.["4"]?.buckets;
              statuses.map((info: any) => {
                const status = info.key;
                const ips = info?.["5"]?.buckets;
                ips.map((ip: any) => {
                  const locationInfo = ip?.["6"]?.buckets[0];
                  const connectionInfoAll = locationInfo?.["3"]?.buckets;
                  const connectionInfo =
                    connectionInfoAll?.[connectionInfoAll?.length - 1];
                  const city =
                    connectionInfo?.["7"]?.buckets[0]?.["9"]?.buckets[0]?.key;
                  users.push({
                    username: userInfo.key,
                    location: locationInfo.key,
                    handshake: Date.parse(connectionInfo.key_as_string),
                    ip: ip.key,
                    status: status,
                    city,
                  });
                });
              });
            });
          }
        }
        users.sort((a, b) => b.handshake - a.handshake);
        return users;
      })
      .catch((error) => {
        return users;
      });
  }

  async getAppFiltersValues(
    type: FiltersFieldType,
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<Res<number>> {
    const body = fromApplicationsFiltersBodyBody(type, timeRange, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result =
            result.result?.aggregations &&
            result.result.aggregations["2"]?.buckets?.map((el: any) => el.key);
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getTenantEvents(tenantName?: string): Promise<Res<number>> {
    try {
      const res = await fetch(`${ELASTIC_ROOT_PATH}/logsta*/_search`, {
        method: "POST",
        body: JSON.stringify({
          track_total_hits: false,
          sort: [
            {
              "@timestamp": {
                order: "desc",
                unmapped_type: "boolean",
              },
            },
          ],
          fields: [
            {
              field: "*",
              include_unmapped: "true",
            },
            {
              field: "@timestamp",
              format: "strict_date_optional_time",
            },
            {
              field: "syslog5424_ts",
              format: "strict_date_optional_time",
            },
          ],
          size: 500,
          version: true,
          script_fields: {},
          stored_fields: ["*"],
          runtime_mappings: {},
          _source: false,
          query: {
            bool: {
              must: [],
              filter: [
                {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [
                            {
                              exists: {
                                field: "entity.keyword",
                              },
                            },
                          ],
                          minimum_should_match: 1,
                        },
                      },
                      {
                        bool: {
                          must_not: {
                            bool: {
                              should: [
                                {
                                  match_phrase: {
                                    "entity.keyword": "DPI",
                                  },
                                },
                              ],
                              minimum_should_match: 1,
                            },
                          },
                        },
                      },
                      {
                        bool: {
                          must_not: {
                            multi_match: {
                              type: "best_fields",
                              query: "southbound",
                              lenient: true,
                            },
                          },
                        },
                      },
                      {
                        bool: {
                          must_not: {
                            bool: {
                              should: [
                                {
                                  match_phrase: {
                                    "rule_type.keyword": "NOP",
                                  },
                                },
                              ],
                              minimum_should_match: 1,
                            },
                          },
                        },
                      },
                    ],
                  },
                },
                // {
                //   range: {
                //     "@timestamp": {
                //       format: "strict_date_optional_time",
                //       gte: "2023-03-19T22:00:00.000Z",
                //       lte: "2023-03-27T08:08:49.852Z",
                //     },
                //   },
                // },
                {
                  match_phrase: { "tenant_name.keyword": tenantName },
                },
              ],
              should: [],
              must_not: [],
            },
          },
          highlight: {
            pre_tags: ["@kibana-highlighted-field@"],
            post_tags: ["@/kibana-highlighted-field@"],
            fields: {
              "*": {},
            },
            fragment_size: 2147483647,
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await this.getResult<any>(res);
      if (result.ok) {
        result.result = result.result?.hits?.hits;
      }
      return result;
    } catch (e) {
      return { ok: false, error: e + "" };
    }
  }

  async getAdminEvents(tenantName?: string): Promise<Res<number>> {
    try {
      const res = await fetch(`${ELASTIC_ROOT_PATH}/logsta*/_search`, {
        method: "POST",
        body: JSON.stringify({
          track_total_hits: false,
          sort: [
            {
              "@timestamp": {
                order: "desc",
                unmapped_type: "boolean",
              },
            },
          ],
          fields: [
            {
              field: "*",
              include_unmapped: "true",
            },
            {
              field: "@timestamp",
              format: "strict_date_optional_time",
            },
            {
              field: "syslog5424_ts",
              format: "strict_date_optional_time",
            },
          ],
          size: 500,
          version: true,
          script_fields: {},
          stored_fields: ["*"],
          runtime_mappings: {},
          _source: false,
          query: {
            bool: {
              must: [],
              filter: [
                {
                  bool: {
                    filter: [
                      {
                        bool: {
                          should: [
                            {
                              exists: {
                                field: "entity.keyword",
                              },
                            },
                          ],
                          minimum_should_match: 1,
                        },
                      },
                      {
                        bool: {
                          must_not: {
                            multi_match: {
                              type: "best_fields",
                              query: "southbound",
                              lenient: true,
                            },
                          },
                        },
                      },
                      {
                        bool: {
                          must_not: {
                            bool: {
                              should: [
                                {
                                  exists: {
                                    field: "tenant_name.keyword",
                                  },
                                },
                              ],
                              minimum_should_match: 1,
                            },
                          },
                        },
                      },
                      {
                        bool: {
                          must_not: {
                            bool: {
                              should: [
                                {
                                  match_phrase: {
                                    "entity.keyword": "DPI",
                                  },
                                },
                              ],
                              minimum_should_match: 1,
                            },
                          },
                        },
                      },
                    ],
                  },
                },
                // {
                //   range: {
                //     "@timestamp": {
                //       format: "strict_date_optional_time",
                //       gte: "2023-03-27T03:23:13.108Z",
                //       lte: "2023-03-27T09:00:00.000Z",
                //     },
                //   },
                // },
                {
                  match_phrase: { "tenant_name.keyword": tenantName },
                },
              ],
              should: [],
              must_not: [],
            },
          },
          highlight: {
            pre_tags: ["@kibana-highlighted-field@"],
            post_tags: ["@/kibana-highlighted-field@"],
            fields: {
              "*": {},
            },
            fragment_size: 2147483647,
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await this.getResult<any>(res);
      if (result.ok) {
        result.result = result.result?.hits?.hits;
      }
      return result;
    } catch (e) {
      return { ok: false, error: e + "" };
    }
  }

  async getNatRules(
    tenantName?: string,
    activeNat?: string
  ): Promise<Res<number>> {
    const filter = [
      {
        bool: {
          should: [{ match_phrase: { "event_type.keyword": "SDR" } }],
          minimum_should_match: 1,
        },
      },
      { match_phrase: { "tenant_name.keyword": tenantName } },
    ];
    if (activeNat) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter.push({ match_phrase: { "NAT Rule Name.keyword": activeNat } });
    }
    try {
      const res = await fetch(`${ELASTIC_ROOT_PATH}/logsta*/_search`, {
        method: "POST",
        body: JSON.stringify({
          track_total_hits: false,
          sort: [{ "@timestamp": { order: "desc", unmapped_type: "boolean" } }],
          fields: [
            { field: "*", include_unmapped: "true" },
            { field: "@timestamp", format: "strict_date_optional_time" },
            { field: "First sampled", format: "strict_date_optional_time" },
            { field: "Last sampled", format: "strict_date_optional_time" },
            {
              field: "data.first_sampled",
              format: "strict_date_optional_time",
            },
            { field: "data.last_sampled", format: "strict_date_optional_time" },
            { field: "syslog5424_ts", format: "strict_date_optional_time" },
          ],
          size: 1000,
          version: true,
          script_fields: {},
          stored_fields: ["*"],
          runtime_mappings: {},
          _source: false,
          query: {
            bool: {
              must: [],
              filter,
              should: [],
              must_not: [],
            },
          },
          highlight: {
            pre_tags: ["@kibana-highlighted-field@"],
            post_tags: ["@/kibana-highlighted-field@"],
            fields: { "*": {} },
            fragment_size: 2147483647,
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await this.getResult<any>(res);
      if (result.ok) {
        result.result = result.result?.hits?.hits;
      }
      return result;
    } catch (e) {
      return { ok: false, error: e + "" };
    }
  }

  async getFWRules(
    tenantName?: string,
    activeFWRule?: string
  ): Promise<Res<number>> {
    const filter = [
      {
        bool: {
          should: [{ match_phrase: { "event_type.keyword": "SDR" } }],
          minimum_should_match: 1,
        },
      },
      { match_phrase: { "tenant_name.keyword": tenantName } },
    ];
    if (activeFWRule) {
      filter.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        match_phrase: { "Firewall Rule Name.keyword": activeFWRule },
      });
    }
    try {
      const res = await fetch(`${ELASTIC_ROOT_PATH}/logsta*/_search`, {
        method: "POST",
        body: JSON.stringify({
          track_total_hits: false,
          sort: [{ "@timestamp": { order: "desc", unmapped_type: "boolean" } }],
          fields: [
            { field: "*", include_unmapped: "true" },
            { field: "@timestamp", format: "strict_date_optional_time" },
            { field: "First sampled", format: "strict_date_optional_time" },
            { field: "Last sampled", format: "strict_date_optional_time" },
            {
              field: "data.first_sampled",
              format: "strict_date_optional_time",
            },
            { field: "data.last_sampled", format: "strict_date_optional_time" },
            { field: "syslog5424_ts", format: "strict_date_optional_time" },
          ],
          size: 500,
          version: true,
          script_fields: {},
          stored_fields: ["*"],
          runtime_mappings: {},
          _source: false,
          query: {
            bool: {
              must: [],
              filter: filter,
              should: [],
              must_not: [],
            },
          },
          highlight: {
            pre_tags: ["@kibana-highlighted-field@"],
            post_tags: ["@/kibana-highlighted-field@"],
            fields: { "*": {} },
            fragment_size: 2147483647,
          },
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await this.getResult<any>(res);
      if (result.ok) {
        result.result = result.result?.hits?.hits;
      }
      return result;
    } catch (e) {
      return { ok: false, error: e + "" };
    }
  }

  async getHistoryLog(
    tenant: string,
    timeRange: GTimeRange
  ): Promise<Res<ElasticResponse<ElasticHistoryLogHit>>> {
    const body = JSON.stringify(getHistoryLogQuery(tenant, timeRange));
    return this.fetch(`${ELASTIC_ROOT_PATH}/logsta*/_search`, {
      method: "POST",
      body,
    });
  }

  async getAppObservability(
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<
    Res<{
      "1": {
        value: number;
      };
      "2": {
        value: number;
      };
    }>
  > {
    const body = fromAppObservabilityCountBody(timeRange, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.aggregations;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getCyberThreats(
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<
    Res<{
      "1": {
        value: number;
      };
      "2": {
        value: number;
      };
    }>
  > {
    const body = fromCyberThreatsCountBody(timeRange, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.aggregations;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getApplicationsCounts(
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<
    Res<{
      [key: string]: {
        value: number;
      };
    }>
  > {
    const body = fromApplicationsCountBody(timeRange, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.aggregations;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getThreadsSevirity(
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<
    Res<{
      [key: string]: {
        buckets: Array<{
          key: number;
          doc_count: number;
        }>;
      };
    }>
  > {
    const body = fromThreadsSevirityBody(timeRange, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.aggregations;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getBlockedApplicationsCounts(
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<
    Res<{
      [key: string]: {
        value: number;
      };
    }>
  > {
    const body = fromBlockedApplicationsCountBody(timeRange, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.aggregations;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getGrafanaTimeRange(
    type: string,
    tenantName?: string
  ): Promise<Res<Array<TimeRangeValueConverted>>> {
    const body = grafanaTimeRangeBody(type, tenantName);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = convertTimeRangeResponse(
            result.result?.aggregations[2].buckets
          );
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getSystemsGrafanaTimeRange(): Promise<
    Res<Array<TimeRangeValueConverted>>
  > {
    const body = grafanaSystemsTimeRangeBody();
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = convertTimeRangeResponse(
            result.result?.aggregations[9].buckets
          );
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getEventsByType(
    type: EventsType,
    size: string,
    timeRange?: GTimeRange,
    matchParam?: string,
    timeRangeMS?: { from: number; to: number }
  ): Promise<any> {
    let body;
    switch (type) {
      case "bgp":
        body = matchParam
          ? elasticServiceBGPEvents(matchParam, timeRange, timeRangeMS)
          : undefined;
        break;
      case "tenant":
        body = matchParam
          ? elasticTenantEventsBody(matchParam, size, timeRange)
          : undefined;
        break;
      case "system":
        body = matchParam
          ? elasticSystemEventsBody(matchParam, size, timeRange, timeRangeMS)
          : undefined;
        break;
      case "systems":
        body = elasticAllSystemsEventsBody(size, timeRange, timeRangeMS);
        break;
    }
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = extractLogsFromEvents(result.result);
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  // SEACRH QUERIES
  async searchUniqueQueries(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchUniqueQueries(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.aggregations;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchNatRules(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchNatRules(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchFwRules(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchFwRules(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchSourceIps(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchSourceIps(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchDestinationIps(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchDestinationIps(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchSourceSegments(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchSourceSegments(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchDestinationSegments(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchDestinationSegments(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchCyberThreats(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchCyberThreats(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchDnsServers(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchDnsServers(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchDnsDomains(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchDnsDomains(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchRestoreConfigInfo(timeRange: GTimeRange): Promise<any> {
    const body = elasticRestoreConfigInfo(timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getHALastFailover(
    activeNode: string,
    standbyNode: string,
    timeRange?: GTimeRange
  ): Promise<any> {
    const body = elasticHALastFailover(activeNode, standbyNode, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.hits?.hits;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getHAHealthInfo(node: string, timeRange?: GTimeRange): Promise<any> {
    const body = elasticHAHealthInfo(node, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result?.hits?.hits;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async getHAHealthLogs(
    activeNode: string,
    standbyNode?: string,
    timeRange?: GTimeRange
  ): Promise<any> {
    const body = elasticHAHealthLogs(activeNode, standbyNode, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        return result.result?.hits?.hits;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }

  async searchHttpsHostnames(
    ip: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> {
    const body = elasticSearchHttpsDomains(ip, tenant, timeRange);
    return this.handleFetch(body)
      .then((result) => {
        if (result.ok) {
          result.result = result.result.aggregations[2].buckets;
        }
        return result;
      })
      .catch((error) => {
        return { ok: false, error: error + "" };
      });
  }
}
export const elasticApi = new ElasticApi();

type TimeRangeValueInitial = {
  doc_count: number;
  from: number;
  from_as_string: string;
  key: string;
  to: number;
  to_as_string: string;
};

type TimeRangeValueConverted = {
  doc_count: number;
  timeRange: number;
};

const convertTimeRangeResponse = (
  values: Array<TimeRangeValueInitial>
): Array<TimeRangeValueConverted> => {
  const newValues = values.map((val) => ({
    doc_count: val.doc_count,
    timeRange: val.to - val.from,
  }));
  return newValues;
};
