import React, { FC } from "react";
import StatisticsBody from "./components/StatisticsBody";
import styles from "./DNSStatistics.module.scss";
import ServicesRedirectPlaceholder from "../../../../../components/ServicesRedirectPlaceholder";
import CyberThreadsAndDNSButtons from "../../../../ApplicationsDashboard/components/Buttons/CyberThreadsAndDNSButtons";

type Props = { showSubmenu?: boolean };

const DNSStatistics: FC<Props> = () => {
  return (
    <ServicesRedirectPlaceholder type="dns">
      <div className={styles.wrapper}>
        <CyberThreadsAndDNSButtons />
        <StatisticsBody />
      </div>
    </ServicesRedirectPlaceholder>
  );
};

export default DNSStatistics;
