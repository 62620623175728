import React, { FC, useEffect, useState } from "react";
import PBRInfo from "./PBRInfo";
import styles from "./PBRConfig.module.scss";
import GeneralSection from "./GeneralSection";
import { DialogType } from "../../../helpers/types";
import { PBRType, PBRActionType } from "../../../helpers/api/apiTypes";
import {
  ADD,
  EDIT,
  PROTOCOL_ANY,
} from "../../../helpers/common/constantsAlias";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import sendRequest from "../../../helpers/sendRequest";
import DialogBtmButtons from "../../../components/dialogs/common/DialogBtmButtons";
import { isEditMode } from "../../../helpers/isEditMode";
import {
  getPotocolName,
  getProtocolByValue,
} from "../../../helpers/getProtocolByValue";
import RuleSection from "./RuleSection";
import ActionSection, { ContinueAction } from "./ActionSection";
import validatePBR from "../../../helpers/validators/PBRValidator";
import { usePBRContext } from "../../../contexts/servicesContext/PBRContext";
import InfoIcon from "../../../components/icons/InfoIcon";
import { CollapsableThreeColumnLayout } from "../../../components/leftInfoBlock/LayoutThreeColumnFactory";
import PBRDescription from "./PBRDescription";
import {
  generatePriority,
  prepareFilters,
} from "../../Firewall/config/FirewallRuleConfig";
import { System } from "../../Systems/Provisioning/types";
import { useVRFSelectorContext } from "../../../contexts/systemsContext/VRFSelectorContext";

export const MAX_FW_PRIORITY_FOR_MANUAL_CREATION = 10_000;

type Props = {
  onClose: () => void;
  type: DialogType;
  data?: PBRType;
  isGlobal?: boolean;
  system?: System;
};

export type CreatePBR = {
  name: string;
  enable: boolean;
  priority: number;
  description: string;
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  dst_group: string;
  src_group: string;
  action: string;
  gateway_ip_address: string;
  gateway_interface?: string;
  gateway_MAC?: string;
  gateway_vrf_name?: string;
  labels: object;
  tracked: boolean;
  ip_type: string;
  tenant_name?: string;
  vrf_name?: string;
  ingress_vi?: string;
};

const DEFAULT_FIELDS: CreatePBR = {
  name: "",
  enable: true,
  priority: 0,
  description: "",
  action: "",
  ip_protocol: PROTOCOL_ANY,
  src_network: "",
  src_segment: "",
  dst_segment: "",
  src_l4_port: "",
  dst_network: "",
  dst_l4_port: "",
  src_group: "",
  dst_group: "",
  gateway_ip_address: "",
  gateway_interface: "",
  gateway_MAC: "",
  labels: {},
  tracked: false,
  ip_type: "ipv4",
};

const PBRConfig: FC<Props> = ({ onClose, type, data, isGlobal, system }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    add,
    addStatus,
    edit,
    editStatus,
    fetchList,
    pbrList,
    globalAdd,
    globalEdit,
    fetchGlobalList,
  } = usePBRContext();
  const { userGroups } = useFirewallContext();
  const { selectedVRF: vrf } = isGlobal
    ? useVRFSelectorContext()
    : { selectedVRF: undefined };

  const [fields, handleFieldChange] = useFormField<CreatePBR>(
    getFlatData(data) || DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<CreatePBR>(validatePBR, [fields]);

  useEffect(() => {
    if (selectedTenant) {
      isGlobal ? fetchGlobalList() : fetchList(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (pbrList) {
      const priority = data ? data.priority : generatePriority(pbrList);
      handleFieldChange("priority", priority);
    }
  }, [pbrList]);

  const editQuery = isGlobal ? globalEdit : edit;
  const addQuery = isGlobal ? globalAdd : add;

  const handleAdd = async () => {
    const { isOk } = validate();
    if (!isOk) return;

    const newFields = getPreparedData(fields, vrf);

    await sendRequest(
      isOk,
      addQuery(newFields as PBRType, selectedTenant),
      onClose
    );
  };

  const handleEdit = async () => {
    if (!data) return;
    const { isOk } = validate();
    if (!isOk) return;

    const newFields = getPreparedData(fields);
    await sendRequest(isOk, editQuery(newFields, selectedTenant), onClose);
  };

  const leftPart = () => {
    return (
      <div className={styles.infoBlock}>
        <InfoIcon />
        Set at least one filter in Policy to Save.
      </div>
    );
  };

  return (
    <CollapsableThreeColumnLayout
      InfoBlock={() => <PBRInfo />}
      Additional={() => <PBRDescription />}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <GeneralSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            maxPriority={MAX_FW_PRIORITY_FOR_MANUAL_CREATION}
          />
          <RuleSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            groups={userGroups}
            system={system}
          />
          <ActionSection
            className={styles.contentWrapper}
            fields={fields}
            onChange={handleFieldChange}
            system={system}
            ableGWChange={!isEditMode(type)}
          />
        </div>
        <div className={styles.footer}>
          <DialogBtmButtons
            controls={{
              okText: isEditMode(type) ? EDIT : ADD,
              onOk: isEditMode(type) ? handleEdit : handleAdd,
              cancelText: "Cancel",
              onCancel: onClose,
              disableSubmit: !fields.gateway_ip_address && !fields.action,
              leftPart: leftPart(),
            }}
            errorDisplay={addStatus || editStatus}
          />
        </div>
      </div>
    </CollapsableThreeColumnLayout>
  );
};

export default PBRConfig;

const getPreparedData = (rule: CreatePBR, vrf?: string): Partial<PBRType> => {
  const {
    name,
    priority,
    description,
    ip_protocol,
    gateway_ip_address,
    tracked,
    ip_type,
    gateway_vrf_name,
    tenant_name,
    vrf_name,
    src_l4_port,
    dst_l4_port,
  } = rule;

  const filters_ip_protocol = getProtocolByValue(
    ip_protocol,
    !!src_l4_port || !!dst_l4_port
  );

  const otherFilters: any = [];
  [
    "src_l4_port",
    "src_network",
    "src_segment",
    "dst_segment",
    "dst_l4_port",
    "dst_network",
    "dst_group",
    "src_group",
    "ingress_vi",
  ].map((val) => {
    if (rule[val as keyof CreatePBR]) {
      const values = rule[val as keyof CreatePBR] || 0;
      otherFilters.push({
        filter_type: val,
        values,
      });
    }
  });
  return {
    name,
    priority,
    filters: [...filters_ip_protocol, ...otherFilters],
    action: ContinueAction.data as PBRActionType,
    description,
    labels: { tracked: tracked, ip_type: ip_type },
    gateway_ip_address,
    gateway_vrf_name: gateway_vrf_name || vrf_name,
    vrf_name,
    tenant_name: tenant_name || vrf,
  };
};

type PBRFilters = {
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  src_group: string;
  dst_group: string;
  tracked: boolean;
  ip_type: string;
  ingress_vi: string;
};

const getFlatData = (data: PBRType | undefined): CreatePBR | undefined => {
  if (!data) return;
  const filters = prepareFilters(data);
  const protocols = data.filters.filter(
    (el) => el.filter_type === "ip_protocol"
  );
  return {
    name: data?.name,
    enable: data?.enable,
    priority: data?.priority,
    description: data?.description,
    ...(filters as PBRFilters),
    ip_protocol: getPotocolName(protocols),
    labels: data?.labels,
    action: data?.action,
    gateway_ip_address: data?.gateway_ip_address,
    gateway_vrf_name: data?.gateway_vrf_name,
    tenant_name: data?.tenant_name,
    vrf_name: data?.vrf_name,
  };
};
