import React, { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import styles from "./PBRPage.module.scss";
import PBRTable from "./PBRTable";
import { NETWORK_RESOURCES } from "../../helpers/navigation/entries";
import { OneSystemLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";

const PBRPage: FC<PageProps> = ({ tabs: Tabs }) => {
  if (window.location.href.includes(NETWORK_RESOURCES().path)) {
    return (
      <OneSystemLayout>
        <div className={styles.container}>
          <Tabs />
          <PBRTable />
        </div>
      </OneSystemLayout>
    );
  }

  return (
    <div className={styles.container}>
      <Tabs />
      <PBRTable />
    </div>
  );
};
export default withLayout<PageProps>(PBRPage);
