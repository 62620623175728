import { DropdownItem } from "../../../../../helpers/types";
import { DIA_UNITED_CONNECTIONS } from "../../../../ZayoPages/Connections/ConnectionConfigurator/consts";

export const CUSTOMER_LOCATIONS = "customerLocation";
export const CUSTOMER_PORTS = "customerPort";
export const CLOUD_CONNECTIONS = "cloudConnection";

export const DIA = "dia";
export const DATA_CENTER_PORT = "dataCenterPort";

export const Z_PORT_MENU: Array<DropdownItem> = [
  { key: CUSTOMER_PORTS, value: "Customer Port" },
  { key: CLOUD_CONNECTIONS, value: "Cloud Connection" },
  { key: DIA_UNITED_CONNECTIONS, value: "Internet" },
];

export const A_PORT_MENU: Array<DropdownItem> = [
  { key: CUSTOMER_PORTS, value: "Customer Port" },
  { key: CLOUD_CONNECTIONS, value: "Cloud Connection" },
];
