// common

import { UUID } from "./types";

const createOffsetSkipQuery = (offset?: number, limit?: number): string => {
  // console.log("CREAT", offset, limit);
  if (offset === undefined && limit === undefined) return "";
  return `?offset=${offset}&limit=${limit}`;
};

const createDstQuery = (dst: string): string => {
  const formatted = dst.replace("/", "%2F");
  return `?destination=${formatted}`;
};

export type ApiEntryOptions = {
  polling?: boolean;
  extendedWaiting?: boolean;
};

export type ApiEntry = [
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  path: string,
  options?: ApiEntryOptions
];

type ApiFunc = (...args: Array<any>) => ApiEntry;

type SimpleCrudMap = {
  [key: string]: ApiFunc;
  LIST: () => ApiEntry;
  ADD: () => ApiEntry;
  EDIT: (id: string) => ApiEntry;
  GET: (id: string) => ApiEntry;
  DELETE: (id: string) => ApiEntry;
};

type CrudMap = {
  [key: string]: ApiFunc;
  LIST: (tenant: string, optSpecifier?: string) => ApiEntry;
  ADD: (tenant: string, optSpecifier?: string) => ApiEntry;
  EDIT: (tenant: string, id: string, optSpecifier?: string) => ApiEntry;
  GET: (tenant: string, id: string, optSpecifier?: string) => ApiEntry;
  DELETE: (tenant: string, id: string, optSpecifier?: string) => ApiEntry;
};

type CrudSpecifierMap = {
  [key: string]: ApiFunc;
  LIST: (tenant: string, specifier: string) => ApiEntry;
  ADD: (tenant: string, specifier: string) => ApiEntry;
  EDIT: (tenant: string, specifier: string, id: string) => ApiEntry;
  GET: (tenant: string, specifier: string, id: string) => ApiEntry;
  DELETE: (tenant: string, specifier: string, id: string) => ApiEntry;
};

export const getPathUnit = (unit: string | undefined): string => {
  return unit ? `/${unit}` : "";
};

/* ----------------------------METHODS---------------------------- */
/* --------------------------------------------------------------- */

const GET = (path: string): ApiEntry => ["GET", path];
const POST = (path: string, options?: ApiEntryOptions): ApiEntry => [
  "POST",
  path,
  options,
];
const PUT = (path: string, options?: ApiEntryOptions): ApiEntry => [
  "PUT",
  path,
  options,
];
const DELETE = (path: string, options?: ApiEntryOptions): ApiEntry => [
  "DELETE",
  path,
  options,
];
const PATCH = (path: string, options?: ApiEntryOptions): ApiEntry => [
  "PATCH",
  path,
  options,
];

/* ----------------------------PREFIXES---------------------------- */
/* ---------------------------------------------------------------- */
const CONFIG_PATH = "config";
const SYSTEM_PATH_PREFIX = "system";
const TENANT_PATH_PREFIX = "tenant";
const REPORT_PATH = "report";

/* ----------------------------SYSTEM---------------------------- */
/* -------------------------------------------------------------- */
const SYSTEM_PATH = (system: string): string =>
  `${SYSTEM_PATH_PREFIX}/${system}`;

export const SYSTEM: SimpleCrudMap = {
  LIST: () => GET(`${SYSTEM_PATH_PREFIX}`),
  ADD: () => POST(`${SYSTEM_PATH_PREFIX}`, { polling: true }),
  EDIT: (name: string) => PUT(`${SYSTEM_PATH(name)}?commit=true`),
  GET: (name: string) => GET(`${SYSTEM_PATH(name)}`),
  DELETE: (name: string) => DELETE(`${SYSTEM_PATH(name)}`),
  VERSION: (name: string) => GET(`${SYSTEM_PATH(name)}/version`),
  RESET: (name: string) => POST(`${SYSTEM_PATH(name)}/reset`),
  FACTORY_RESET: (name: string) => POST(`${SYSTEM_PATH(name)}/factory_reset`),
  FAILOVER: (name: string) => POST(`${SYSTEM_PATH(name)}/failover`),
  GET_HA: (name: string) => GET(`${SYSTEM_PATH(name)}/ha_conf`),
  UPDATE_HA: (name: string) => PUT(`${SYSTEM_PATH(name)}/ha_conf`),
};

/* ----------------------------NODE---------------------------- */
/* ------------------------------------------------------------ */
const NODE_PATH = (node_name: string): string => `node/${node_name}`;

export const NODES: { [key: string]: ApiFunc } = {
  GET_VERSION: (node_name: string) => GET(`${NODE_PATH(node_name)}/version`),
  EDIT: (node_name: string) =>
    PUT(`${NODE_PATH(node_name)}`, { polling: true }),
  EDIT_DESCRIPTION: (node_name: string) =>
    PATCH(`${NODE_PATH(node_name)}/desription`),
  EDIT_LOCATION: (node_name: string) =>
    PATCH(`${NODE_PATH(node_name)}/location`),
  RESET: (node_name: string) => POST(`${NODE_PATH(node_name)}/reset`),
  FACTORY_RESET: (node_name: string) =>
    POST(`${NODE_PATH(node_name)}/factory_reset`),
  GET_NON_ASSIGNED_NODES: () => GET("node/non_assigned"),
  RECONFIG_NODE: (node_name: string) =>
    POST(`${NODE_PATH(node_name)}/reconfigure`),
};

/* ----------------------------SYSTEM CONFIG---------------------------- */
/* --------------------------------------------------------------------- */
const SYSTEM_CONFIG_PATH = (system: string): string =>
  `${CONFIG_PATH}/system/${system}`;
const SYSTEM_L2_PATH = (system: string): string =>
  `${SYSTEM_CONFIG_PATH(system)}/l2`;

// PHYSICAL PORT
const PORTS_PATH = (system: string): string =>
  `${SYSTEM_CONFIG_PATH(system)}/physical_port`;

export const PORTS: Pick<
  CrudMap,
  | "LIST"
  | "GET"
  | "EDIT"
  | "CREATE_LAG"
  | "ADD_PORT"
  | "DEL_PORT"
  | "EDIT_MIN_PORTS"
  | "EDIT_ALIAS"
  | "EDIT_DESCRIPTION"
> &
  Partial<CrudMap> = {
  LIST: (system: string) => GET(`${PORTS_PATH(system)}`),
  GET: (system: string, id: string) => GET(`${PORTS_PATH(system)}/${id}`),
  EDIT: (system: string, id: string) =>
    PUT(`${PORTS_PATH(system)}/${id}/alias`),
  CREATE_LAG: (system: string) => POST(`${PORTS_PATH(system)}`),
  ADD_PORT: (system: string, name) =>
    PUT(`${PORTS_PATH(system)}/${name}/lag/add`),
  DEL_PORT: (system: string, name) =>
    PUT(`${PORTS_PATH(system)}/${name}/lag/remove`),
  EDIT_MIN_PORTS: (system: string, name) =>
    PUT(`${PORTS_PATH(system)}/${name}/lag/min_ports`),
  EDIT_ALIAS: (system: string, name) =>
    PUT(`${PORTS_PATH(system)}/${name}/alias`),
  EDIT_DESCRIPTION: (system: string, name) =>
    PUT(`${PORTS_PATH(system)}/${name}/data`),
};

// VTEP
const VTEP_PATH = (system: string): string =>
  `${SYSTEM_CONFIG_PATH(system)}/vtep`;

export const VTEP: CrudMap = {
  LIST: (system_name: string) => GET(`${VTEP_PATH(system_name)}`),
  ADD: (system_name: string) =>
    POST(`${VTEP_PATH(system_name)}`, { polling: true }),
  GET: (system_name: string, vtep_name: string) =>
    GET(`${VTEP_PATH(system_name)}/${vtep_name}`),
  EDIT: (system: string, vtep_name: string) =>
    PUT(`${VTEP_PATH(system)}/${vtep_name}`, { polling: true }),
  DELETE: (system_name: string, vtep_name: string) =>
    DELETE(`${VTEP_PATH(system_name)}/${vtep_name}`, { polling: true }),
  SET_DESCR: (system_name: string, vtep_name: string) =>
    PATCH(`${VTEP_PATH(system_name)}/${vtep_name}/description`),
  SET_IPS: (system_name: string, vtep_name: string) =>
    PATCH(`${VTEP_PATH(system_name)}/${vtep_name}/remote_ips`),
};

// L2 PORT INTERFACE
const PORT_INTERFACE_PATH = (system: string): string =>
  `${SYSTEM_L2_PATH(system)}/port_interface`;

export const PORT_INTERFACE: Pick<
  CrudMap,
  "LIST" | "ADD" | "GET" | "DELETE" | "EDIT_THRESHOLD"
> &
  Partial<CrudMap> = {
  LIST: (system: string) => GET(PORT_INTERFACE_PATH(system)),
  ADD: (system: string) => POST(PORT_INTERFACE_PATH(system), { polling: true }),
  DELETE: (system: string) =>
    DELETE(PORT_INTERFACE_PATH(system), { polling: true }),
  GET: (system: string, alias: string) =>
    GET(`${PORT_INTERFACE_PATH(system)}/${alias}`),
  EDIT_THRESHOLD: (system: string, alias: string) =>
    PATCH(`${PORT_INTERFACE_PATH(system)}/${alias}/max_bandwidth`, {
      polling: true,
    }),
};

// L2 PORT VLAN
const PORT_VLAN_PATH = (system: string): string =>
  `${SYSTEM_L2_PATH(system)}/port_vlan`;

export const PORT_VLAN: Pick<
  CrudMap,
  "LIST" | "ADD" | "GET" | "REMOVE" | "DELETE_PARAMS" | "EDIT_THRESHOLD"
> &
  Partial<CrudMap> = {
  LIST: (system: string) => GET(`${PORT_VLAN_PATH(system)}`),
  ADD: (system: string) => POST(PORT_VLAN_PATH(system), { polling: true }),
  REMOVE: (system: string) => DELETE(PORT_VLAN_PATH(system), { polling: true }),
  GET: (system: string, alias: string) =>
    GET(`${PORT_VLAN_PATH(system)}/${alias}`),
  DELETE_PARAMS: (system: string) =>
    DELETE(`${PORT_VLAN_PATH(system)}`, { polling: true }),
  EDIT_THRESHOLD: (system: string, alias: string) =>
    PATCH(`${PORT_VLAN_PATH(system)}/${alias}/max_bandwidth`, {
      polling: true,
    }),
};

// L2 VTEP VNI
const VTEP_VNI_PATH = (system: string): string =>
  `${SYSTEM_L2_PATH(system)}/vtep_vni`;

export const VTEP_VNI: CrudMap = {
  LIST: (system: string) => GET(`${VTEP_VNI_PATH(system)}`),
  ADD: (system: string) => POST(`${VTEP_VNI_PATH(system)}`, { polling: true }),
  GET: (system: string, alias: string) =>
    GET(`${VTEP_VNI_PATH(system)}/${alias}`),
  // NOT EMPLEMENTED YET
  EDIT: (system: string, alias: string) =>
    PUT(`${VTEP_VNI_PATH(system)}/${alias}`, { polling: true }),
  DELETE: (system: string) =>
    DELETE(`${VTEP_VNI_PATH(system)}`, { polling: true }),
  EDIT_THRESHOLD: (system: string, alias: string) =>
    PATCH(`${VTEP_VNI_PATH(system)}/${alias}/max_bandwidth`, { polling: true }),
};

// VRF
const VRF_PATH = (system: string): string =>
  `${SYSTEM_CONFIG_PATH(system)}/vrf`;

export const VRF = {
  LIST: (system: string) => GET(`${VRF_PATH(system)}`),
  ADD: (system: string) => POST(`${VRF_PATH(system)}`, { polling: true }),
  EDIT: (system: string, vrf: string) =>
    PUT(`${VRF_PATH(system)}/${vrf}/description`, { polling: true }),
  GET: (system: string, vrf: string) => GET(`${VRF_PATH(system)}/${vrf}`),
  DELETE: (system: string, vrf: string) =>
    DELETE(`${VRF_PATH(system)}/${vrf}`, { polling: true }),
  GET_SDR: (system: string, vrf: string) =>
    GET(`${VRF_PATH(system)}/${vrf}/sdr`),
  EDIT_SDR: (system: string, vrf: string) =>
    PUT(`${VRF_PATH(system)}/${vrf}/sdr`, { polling: true }),
  GET_DNS: (system: string, vrf: string) =>
    GET(`${VRF_PATH(system)}/${vrf}/dns`),
  EDIT_DNS: (system: string, vrf: string) =>
    PUT(`${VRF_PATH(system)}/${vrf}/dns`, { polling: true }),
};

// VIRTUAL INTERFACE
const VIRTUAL_INTERFACE_PATH = (system: string, vrf?: string): string =>
  `${SYSTEM_CONFIG_PATH(system)}/vrf/${vrf}/virtual_interface`;

export const VIRTUAL_INTERFACE = {
  LIST: (system: string, vrf?: string) =>
    GET(`${VIRTUAL_INTERFACE_PATH(system, vrf)}`),
  ADD: (system: string, vrf?: string) =>
    POST(`${VIRTUAL_INTERFACE_PATH(system, vrf)}`, { polling: true }),
  EDIT: (system: string, vi_name: string, vrf?: string) =>
    PATCH(`${VIRTUAL_INTERFACE_PATH(system, vrf)}/${vi_name}/ip_addresses`, {
      polling: true,
    }),
  EDIT_MTU: (system: string, vi_name: string, vrf?: string) =>
    PATCH(`${VIRTUAL_INTERFACE_PATH(system, vrf)}/${vi_name}/mtu`, {
      polling: true,
    }),
  EDIT_DESCRIPTION: (system: string, vi_name: string, vrf?: string) =>
    PATCH(`${VIRTUAL_INTERFACE_PATH(system, vrf)}/${vi_name}/description`, {
      polling: true,
    }),
  EDIT_ADMINISTRATIVE_STATE: (system: string, vi_name: string, vrf?: string) =>
    PATCH(
      `${VIRTUAL_INTERFACE_PATH(system, vrf)}/${vi_name}/administrative_state`,
      {
        polling: true,
      }
    ),
  GET: (system: string, vi_name: string, vrf?: string) =>
    GET(`${VIRTUAL_INTERFACE_PATH(system, vrf)}/${vi_name}`),
  DELETE: (system: string, vi_name: string, vrf?: string) =>
    DELETE(`${VIRTUAL_INTERFACE_PATH(system, vrf)}/${vi_name}`, {
      polling: true,
    }),
  LIST_MEMBERS: (system: string, vi_name: string, vrf?: string) =>
    GET(`${VIRTUAL_INTERFACE_PATH(system, vrf)}/${vi_name}/members`),
  ALL_TYPES: (tenant: string) =>
    GET(`tenant/${tenant}/virtual_interface/all_types`),
};

// STATIC ROUTE
const STATIC_ROUTE_PATH = (system: string, vrf: string): string =>
  `${SYSTEM_CONFIG_PATH(system)}/vrf/${vrf}/static_route`;

export const STATIC_ROUTE: Pick<CrudSpecifierMap, "LIST" | "ADD" | "DELETE"> &
  Partial<CrudSpecifierMap> = {
  LIST: (system: string, vrf: string) =>
    GET(`${STATIC_ROUTE_PATH(system, vrf)}`),
  ADD: (system: string, vrf: string) =>
    POST(`${STATIC_ROUTE_PATH(system, vrf)}`, { polling: true }),
  DELETE: (system: string, vrf: string) =>
    DELETE(`${STATIC_ROUTE_PATH(system, vrf)}`, { polling: true }),
};

// SYSTEM BGP
const BGP_SYSTEM_PATH = (system: string): string =>
  `${SYSTEM_CONFIG_PATH(system)}/bgp`;

export const BGP_SYSTEM: { [key: string]: ApiFunc } = {
  GET_UNDERLAY: (system: string) => GET(`${BGP_SYSTEM_PATH(system)}/underlay`),
  EDIT_UNDERLAY: (system: string) =>
    PUT(`${BGP_SYSTEM_PATH(system)}/underlay`, { polling: true }),
  GET_STATUS: (system: string) => GET(`${BGP_SYSTEM_PATH(system)}/status`),
  GET_CONFIG: (system: string) => GET(`${BGP_SYSTEM_PATH(system)}/config`),
  // TODO Remove GET_STATUS_BY_TENANT
  GET_STATUS_BY_TENANT: (tenant: string) =>
    GET(`config/service/${tenant}/bgp/status`),
};

// SYSTEM ROUTING BGP PFX
const BGP_PFX_SYSTEM_PATH = (): string => `config/bgp_pfx_list`;

export const BGP_PFX_SYSTEM: { [key: string]: ApiFunc } = {
  LIST: () => GET(BGP_PFX_SYSTEM_PATH()),
};

/* ----------------------------NODE CONFIG---------------------------- */
/* ------------------------------------------------------------------- */
const NODE_CONFIG_PATH = (node: string): string =>
  `${CONFIG_PATH}/node/${node}`;

export const VROUTER_SYSTEM: { [key: string]: ApiFunc } = {
  // TODO Remove MAC ??
  LIST_MAC: (node: string) => GET(`${NODE_CONFIG_PATH(node)}/mac`),
  LIST_ARP: (node: string, vrf: string) =>
    GET(`${NODE_CONFIG_PATH(node)}/vrf/${vrf}/arp`),
  LIST_ROUTES: (node: string, vrf: string) =>
    GET(`${NODE_CONFIG_PATH(node)}/vrf/${vrf}/route`),
};

/* ----------------------------SERVICE---------------------------- */
/* --------------------------------------------------------------- */
const SERVICE_PATH = (tenant: string): string => `service/${tenant}`;

// PORTS
const TENANT_PORTS_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/l2`;
const TENANT_CUSTOMER_PORTS_PATH = (tenant: string): string =>
  `${TENANT_PORTS_PATH(tenant)}/customer_port`;
const TENANT_AWS_PORTS_PATH = (tenant: string): string =>
  `${TENANT_PORTS_PATH(tenant)}/aws_direct_connect`;
const TENANT_AZURE_PORTS_PATH = (tenant: string): string =>
  `${TENANT_PORTS_PATH(tenant)}/azure_direct_connect`;
const TENANT_GCP_PORTS_PATH = (tenant: string): string =>
  `${TENANT_PORTS_PATH(tenant)}/gcp_direct_connect`;
export const TENANT_PORTS = {
  LIST: (tenant: string) => GET(`${TENANT_PORTS_PATH(tenant)}`),
  ADD_CUSTOMER_VLAN: (tenant: string, name: string) =>
    POST(`${TENANT_CUSTOMER_PORTS_PATH(tenant)}/${name}/vlan`, {
      polling: true,
    }),
  ADD_CLOUD_VLAN: (tenant: string, name: string) =>
    POST(`${TENANT_PORTS_PATH(tenant)}/aws_direct_connect`, {
      polling: true,
    }),
  EDIT_CUSTOMER_VLAN: (tenant: string, connection: string, name: string) =>
    PUT(`${TENANT_CUSTOMER_PORTS_PATH(tenant)}/${connection}/vlan/${name}`, {
      polling: true,
    }),
  DELETE_CUSTOMER_VLAN: (tenant: string, connection: string, name: string) =>
    DELETE(`${TENANT_CUSTOMER_PORTS_PATH(tenant)}/${connection}/vlan/${name}`, {
      polling: true,
    }),
  REQUEST_PORT: (tenant: string) =>
    POST(TENANT_CUSTOMER_PORTS_PATH(tenant), {
      polling: true,
    }),
  CUSTOMER_LIST: (tenant: string) =>
    GET(`${TENANT_CUSTOMER_PORTS_PATH(tenant)}`),
  DELETE_CUSTOMER_PORT: (tenant: string, name: string) =>
    DELETE(`${TENANT_CUSTOMER_PORTS_PATH(tenant)}/${name}`, {
      polling: true,
    }),
  DELETE_AWS_VLAN: (tenant: string, name: string) =>
    DELETE(`${TENANT_AWS_PORTS_PATH(tenant)}/${name}`, {
      polling: true,
    }),
  DELETE_AZURE_VLAN: (tenant: string, name: string) =>
    DELETE(`${TENANT_AZURE_PORTS_PATH(tenant)}/${name}`, {
      polling: true,
    }),
  DELETE_GCP_VLAN: (tenant: string, name: string) =>
    DELETE(`${TENANT_GCP_PORTS_PATH(tenant)}/${name}`, {
      polling: true,
    }),
};

// ORDERED PORTS
const ORDERED_PORTS_PATH = (): string => `config/customer_port`;
export const ORDERED_PORTS = {
  LIST: () => GET(ORDERED_PORTS_PATH()),
};

// SITES
const SITES_PATH = "sites";
export const SITES = {
  LIST: () => GET(SITES_PATH),
  ADD: () => POST(SITES_PATH),
};

// FIREWALL
const FW_RULE_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/firewall_rule`;

export const FW_RULE: CrudMap = {
  LIST: (tenant: string) => GET(`${FW_RULE_PATH(tenant)}`),
  ADD: (tenant: string) => POST(`${FW_RULE_PATH(tenant)}`, { polling: true }),
  ADD_DIA: (tenant: string) =>
    POST(`${FW_RULE_PATH(tenant)}/dia`, { polling: true }),
  EDIT: (tenant: string, rule_name: string) =>
    PUT(`${FW_RULE_PATH(tenant)}/${rule_name}`, { polling: true }),
  GET: (tenant: string, rule_name: string) =>
    GET(`${FW_RULE_PATH(tenant)}/${rule_name}`),
  DELETE: (tenant: string, rule_name: string) =>
    DELETE(`${FW_RULE_PATH(tenant)}/${rule_name}`, { polling: true }),
};

// PBR
const PBR_PATH = (tenant: string): string => `${SERVICE_PATH(tenant)}/pbr_rule`;
const PBR_GLOBAL_PATH = `config/pbr_rule`;

export const PBR_CRUD: CrudMap = {
  LIST: (tenant: string) => GET(`${PBR_PATH(tenant)}`),
  GLOBAL_LIST: (tenant: string) =>
    GET(`${PBR_GLOBAL_PATH}?tenant_name=${tenant}`),
  ADD: (tenant: string) => POST(`${PBR_PATH(tenant)}`, { polling: true }),
  GLOBAL_ADD: () => POST(PBR_GLOBAL_PATH, { polling: true }),
  EDIT: (tenant: string, rule_name: string) =>
    PUT(`${PBR_PATH(tenant)}/${rule_name}`, { polling: true }),
  GLOBAL_EDIT: (tenant: string, rule_name: string) =>
    PUT(`${PBR_GLOBAL_PATH}/${tenant}/${rule_name}`, { polling: true }),
  GET: (tenant: string, rule_name: string) =>
    GET(`${PBR_PATH(tenant)}/${rule_name}`),
  DELETE: (tenant: string, rule_name: string) =>
    DELETE(`${PBR_PATH(tenant)}/${rule_name}`, { polling: true }),
  GLOBAL_DELETE: (tenant: string, rule_name: string) =>
    DELETE(`${PBR_GLOBAL_PATH}/${tenant}/${rule_name}`, { polling: true }),
};

// PBM
const PBM_PATH = (tenant: string): string => `${SERVICE_PATH(tenant)}/pbm_rule`;

export const PBM_CRUD: CrudMap = {
  LIST: (tenant: string) => GET(`${PBM_PATH(tenant)}`),
  ADD: (tenant: string) => POST(`${PBM_PATH(tenant)}`, { polling: true }),
  EDIT: (tenant: string, rule_name: string) =>
    PUT(`${PBM_PATH(tenant)}/${rule_name}`, { polling: true }),
  GET: (tenant: string, rule_name: string) =>
    GET(`${PBM_PATH(tenant)}/${rule_name}`),
  DELETE: (tenant: string, rule_name: string) =>
    DELETE(`${PBM_PATH(tenant)}/${rule_name}`, { polling: true }),
};

// DDoS
const DDoS_PATH = (system: string, vrf: string): string =>
  `${VRF_PATH(system)}/${vrf}/ip_flood`;

export const DDoS_CRUD = {
  LIST: (system: string, vrf: string) => GET(`${DDoS_PATH(system, vrf)}`),
  ADD: (system: string, vrf: string) =>
    POST(`${DDoS_PATH(system, vrf)}`, { polling: true }),
  EDIT: (system: string, vrf: string, ip_flood: string) =>
    PUT(`${DDoS_PATH(system, vrf)}/ip_flood/${ip_flood}`, { polling: true }),
  GET: (system: string, vrf: string, ip_flood: string) =>
    GET(`${DDoS_PATH(system, vrf)}/ip_flood_name/${ip_flood}`),
  DELETE: (system: string, vrf: string, ip_flood: string) =>
    DELETE(`${DDoS_PATH(system, vrf)}/ip_flood_name/${ip_flood}`, {
      polling: true,
    }),
};

// NAT
const NAT_RULE_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/nat_rule`;

export const NAT_RULE: CrudMap = {
  LIST: (tenant: string) => GET(`${NAT_RULE_PATH(tenant)}`),
  ADD: (tenant: string) => POST(`${NAT_RULE_PATH(tenant)}`, { polling: true }),
  EDIT: (tenant: string, rule_name: string) =>
    PUT(`${NAT_RULE_PATH(tenant)}/${rule_name}`, { polling: true }),
  GET: (tenant: string, rule_name: string) =>
    GET(`${NAT_RULE_PATH(tenant)}/${rule_name}`),
  DELETE: (tenant: string, rule_name: string) =>
    DELETE(`${NAT_RULE_PATH(tenant)}/${rule_name}`, { polling: true }),
};

// SERVICE BGP
const SERVICE_BGP_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/bgp`;
const SERVICE_BGP_AS_PATH = (tenant: string): string =>
  `${SERVICE_BGP_PATH(tenant)}/autonomous_system`;
const SERVICE_BGP_NEIGHBOR_PATH = (tenant: string): string =>
  `${SERVICE_BGP_PATH(tenant)}/neighbor`;

export const BGP_SERVICE: { [key: string]: ApiFunc } = {
  GET_STATUS: (tenant: string) => GET(`${SERVICE_BGP_PATH(tenant)}/status`),
  GET_SUMMARY: (tenant: string) => GET(`${SERVICE_BGP_PATH(tenant)}/summery`),
};

export const BGP_SERVICE_AS: Pick<CrudMap, "ADD" | "DELETE" | "GET"> &
  Partial<CrudMap> &
  any = {
  GET: (tenant: string) => GET(`${SERVICE_BGP_AS_PATH(tenant)}`),
  ADD: (tenant: string) =>
    POST(`${SERVICE_BGP_AS_PATH(tenant)}`, { polling: true }),
  DELETE: (tenant: string) =>
    DELETE(`${SERVICE_BGP_AS_PATH(tenant)}`, { polling: true }),
};

export const BGP_SERVICE_NEIGHBOR: Pick<
  CrudMap,
  "LIST" | "GET" | "ADD" | "DELETE" | "EDIT"
> &
  Partial<CrudMap> = {
  LIST: (tenant: string) => GET(`${SERVICE_BGP_NEIGHBOR_PATH(tenant)}`),
  ADD: (tenant: string) =>
    POST(`${SERVICE_BGP_NEIGHBOR_PATH(tenant)}`, { polling: true }),
  GET: (tenant: string, ip: string) =>
    GET(`${SERVICE_BGP_NEIGHBOR_PATH(tenant)}/${ip}`),
  EDIT: (tenant: string, ip: string) =>
    PUT(`${SERVICE_BGP_NEIGHBOR_PATH(tenant)}/${ip}`),
  DELETE: (tenant: string, ip: string) =>
    DELETE(`${SERVICE_BGP_NEIGHBOR_PATH(tenant)}/${ip}`, { polling: true }),
};

// SERVICES BGP PFXs
const SERVICE_BGP_PFX_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/bgp_pfx_list`;

export const SERVICE_BGP_PFX: Pick<CrudMap, "LIST" | "GET" | "ADD" | "DELETE"> &
  Partial<CrudMap> = {
  LIST: (tenant: string) => GET(`${SERVICE_BGP_PFX_PATH(tenant)}`),
  ADD: (tenant: string) =>
    POST(`${SERVICE_BGP_PFX_PATH(tenant)}`, { polling: true }),
  GET: (tenant: string, name: string) =>
    GET(`${SERVICE_BGP_PFX_PATH(tenant)}/${name}`),
  DELETE: (tenant: string, name: string) =>
    DELETE(`${SERVICE_BGP_PFX_PATH(tenant)}/${name}`, { polling: true }),
};

// SDR *NOT IMPLEMENTED*

// STATIC ROUTE
const TENANT_STATIC_R_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/static_route`;

export const TENANT_STATIC_ROUTE: { [key: string]: ApiFunc } = {
  LIST: (tenant: string, offset: number, skip: number) =>
    GET(
      `${TENANT_STATIC_R_PATH(tenant)}${createOffsetSkipQuery(offset, skip)}`
    ),
  ADD: (tenant: string) =>
    POST(`${TENANT_STATIC_R_PATH(tenant)}`, { polling: true }),
  DELETE: (tenant: string) =>
    DELETE(`${TENANT_STATIC_R_PATH(tenant)}`, { polling: true }),
  EDIT: (tenant: string) =>
    POST(`${TENANT_STATIC_R_PATH(tenant)}`, { polling: true }),
  GET: (tenant: string, id: string) =>
    GET(`${TENANT_STATIC_R_PATH(tenant)}/${id}`),
  ENABLE: (tenant: string, dst: string) =>
    PUT(`${TENANT_STATIC_R_PATH(tenant)}/enabled${createDstQuery(dst)}`, {
      polling: true,
    }),
};

// VROUTER ARP, ROUTES
export const VROUTER_SERVICE: { [key: string]: ApiFunc } = {
  LIST_ARP: (tenant: string, offset: number, skip: number) =>
    GET(`${SERVICE_PATH(tenant)}/arp${createOffsetSkipQuery(offset, skip)}`),
  LIST_ROUTES: (tenant: string, offset: number, skip: number) =>
    GET(`${SERVICE_PATH(tenant)}/route${createOffsetSkipQuery(offset, skip)}`),
};

// IDS
const SERVICE_IDS_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/ids/rule`;
export const SERVICE_IDS: { [key: string]: ApiFunc } = {
  GET: (tenant: string) => GET(`${SERVICE_IDS_PATH(tenant)}`),
  EDIT: (tenant: string) => PUT(`${SERVICE_IDS_PATH(tenant)}`),
};

// DPI
const SERVICE_DPI_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/dpi`;

export const SERVICE_DPI: { [key: string]: ApiFunc } = {
  GET: (tenant: string) => GET(`${SERVICE_DPI_PATH(tenant)}`),
  EDIT: (tenant: string) => PUT(`${SERVICE_DPI_PATH(tenant)}`),
};

// LOAD BALANCE *NOT IMPLEMENTED*

// AWS
const AWS_PATH = (tenant: string): string => `${SERVICE_PATH(tenant)}/aws`;

export const SERVICE_AWS: any = {
  GET: (tenant: string, region: string) =>
    GET(`${AWS_PATH(tenant)}/vpcs?region=${region}`),
  POST: (tenant: string) => POST(`${AWS_PATH(tenant)}/site_to_site_vpn`),
};

// AZURE
const AZURE_PATH = (tenant: string): string => `${SERVICE_PATH(tenant)}/azure`;

export const SERVICE_AZURE: any = {
  GET: (tenant: string, region: string) =>
    GET(`${AZURE_PATH(tenant)}/vnets?region=${region}`),
  POST: (tenant: string) => POST(`${AZURE_PATH(tenant)}/site_to_site_vpn`),
};

// GCP *NOT IMPLEMENTED*

// NETWORK SEGMENTATION
const SEGMENTATION_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/network_segmentation`;

export const SEGMENTATIONS: { [key: string]: ApiFunc } = {
  GET: (tenant_name: string) => GET(SEGMENTATION_PATH(tenant_name)),
  DELETE: (tenant_name: string, segment_name: string) =>
    PUT(`${SEGMENTATION_PATH(tenant_name)}/${segment_name}`),
};

// NETWORK OBJECT *NOT IMPLEMENTED*

// LOOKING GLASS
const LOOKING_GLASS_PATH = (): string => `service/looking_glass`;

export const CMD_PATH = {
  PING: () => POST(`${LOOKING_GLASS_PATH()}/ping`),
  TRACEROUTE: () => POST(`${LOOKING_GLASS_PATH()}/traceroute`),
  MTU: () => POST(`${LOOKING_GLASS_PATH()}/mtu`),
  GET_RESULT: () => POST(`${LOOKING_GLASS_PATH()}/result`),
};

// ENABLED SERVICES
const SERVICES_PREFERENCES_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/enabled_service`;

export const SERVICES_PREFERENCES: any = {
  GET: (tenant: string) => GET(SERVICES_PREFERENCES_PATH(tenant)),
  EDIT: (tenant: string) =>
    PUT(SERVICES_PREFERENCES_PATH(tenant), { polling: true }),
};

// CONNECTION
const SERVICE_CONNECTION_PATH = (tenant_name: string): string =>
  `${SERVICE_PATH(tenant_name)}/connection`;

export const SERVICE_CONNECTION: any = {
  GET_LIST: (tenant_name: string) =>
    GET(`${SERVICE_CONNECTION_PATH(tenant_name)}`),
  POST: (tenant_name: string) =>
    POST(`${SERVICE_CONNECTION_PATH(tenant_name)}`, { extendedWaiting: true }),
  PUT: (tenant_name: string, name: string) =>
    PUT(`${SERVICE_CONNECTION_PATH(tenant_name)}/${name}`, {
      extendedWaiting: true,
    }),
  DELETE: (tenant_name: string, name: string) =>
    DELETE(`${SERVICE_CONNECTION_PATH(tenant_name)}/${name}`, {
      extendedWaiting: true,
    }),
};

// ENDPOINT
const SERVICE_ENDPOINT_PATH = (tenant_name: string): string =>
  `${SERVICE_PATH(tenant_name)}/endpoint`;

export const SERVICE_ENDPOINT: any = {
  GET_LIST: (tenant_name: string) =>
    GET(`${SERVICE_ENDPOINT_PATH(tenant_name)}`),
  POST: (tenant_name: string) => POST(`${SERVICE_ENDPOINT_PATH(tenant_name)}`),
  PUT: (tenant_name: string, name: string) =>
    POST(`${SERVICE_ENDPOINT_PATH(tenant_name)}/${name}`),
  DELETE: (tenant_name: string, name: string) =>
    DELETE(`${SERVICE_ENDPOINT_PATH(tenant_name)}/${name}`),
};

// WIRE GUARD
const WIRE_GUARD_PATH = (tenant_name: string): string =>
  `${SERVICE_PATH(tenant_name)}/wg_tunnel`;

export const WIRE_GUARD: any = {
  GET_TUNNELS_LIST: (tenant_name: string, user_name: string) =>
    GET(`${WIRE_GUARD_PATH(tenant_name)}/get_list?user_name=${user_name}`),
  GET_USER_TUNNELS_LIST: (tenant_name: string, user_name: string) =>
    GET(`${WIRE_GUARD_PATH(tenant_name)}/get_user_list/${user_name}`),
  GET_TUNNEL_WG_CONFIG: (
    tenant_name: string,
    user_name: string,
    tunnel_id: string
  ) =>
    GET(`${WIRE_GUARD_PATH(tenant_name)}/get_conf/${user_name}/${tunnel_id}`),
  GET_TUNNEL_WG_ONE: (
    tenant_name: string,
    user_name: string,
    tunnel_id: string
  ) => GET(`${WIRE_GUARD_PATH(tenant_name)}/get_one/${user_name}/${tunnel_id}`),
  CREATE_TUNNEL_WG: (
    tenant_name: string,
    user_name: string,
    system_name: string
  ) => POST(`${WIRE_GUARD_PATH(tenant_name)}/${user_name}/${system_name}`),
  DELETE_TUNNEL_WG: (
    tenant_name: string,
    user_name: string,
    tunnel_id: string
  ) => DELETE(`${WIRE_GUARD_PATH(tenant_name)}/${user_name}/${tunnel_id}`),
};

// WIRE GUARD - STATUS

export const WIRE_GUARD_STATUS: any = {
  GET_WG_TUNNEL_STATUS: (tenant_name: string) =>
    GET(`service/${tenant_name}/wg_tunnel_status`),
  GET_WG_TUNNEL_STATUS_FOR_USER: (tenant_name: string, user_name: string) =>
    GET(`service/${tenant_name}/wg_tunnel_status/${user_name}`),
};
/* ----------------------------CONFIG---------------------------- */
/* -------------------------------------------------------------- */
// REQUEST
const CONFIG_REQUEST_PATH = (): string => `config/request`;

export const GET_REQUEST_STATUS = (id: UUID): ApiEntry =>
  GET(`${CONFIG_REQUEST_PATH()}/${id}`);
export const CONFIG_REQUEST: any = {
  GET_USER_ALL: () => GET(`${CONFIG_REQUEST_PATH()}/user/all`),
  GET_TENANT_ALL: () => GET(`${CONFIG_REQUEST_PATH()}/tenant/all`),
  GET_BY_ID: (id: string) => GET(`${CONFIG_REQUEST_PATH()}/${id}`),
};

// CONNECTION
export const CONFIG_CONNECTION = {
  GET: (tenant: string) => GET(`config/connection/${tenant}`),
};

// ENDPOINT
const CONFIG_ENDPOINT_PATH = (): string => `config/endpoint`;

export const CONFIG_ENDPOINT: any = {
  GET_LIST: (tenant_name: string) => GET(`${CONFIG_ENDPOINT_PATH()}`),
  POST: (tenant_name: string) => POST(`${CONFIG_ENDPOINT_PATH()}`),
  PUT: (tenant_name: string, name: string) =>
    POST(`${CONFIG_ENDPOINT_PATH()}/${name}`),
  DELETE: (tenant_name: string, name: string) =>
    DELETE(`${CONFIG_ENDPOINT_PATH()}/${name}`),
};

// IPAM / PUBLIC IP RANGE
const IPAM_PATH = (): string => `config/public_ip_range`;

export const IPAM: any = {
  GET: () => GET(IPAM_PATH()),
  CREATE: () => POST(IPAM_PATH()),
  DELETE: (name: string) => DELETE(`${IPAM_PATH()}/${name}`),
};

// IPAM CONFIG

const IPAM_CONFIGURE_PATH = (): string => `config/ipam`;
const IPAM_CONFIGURE_PATH_FOR_TENANT = (system: string): string =>
  `config/ipam/pfx_available?location_type=location&name=${system}&dia_service=true&ipsec_service=false&nat_service=true&snat_service=true&shared=false&limit=0&offset=0`;

export const IPAM_CONF: any = {
  GET: () => GET(IPAM_CONFIGURE_PATH()),
  GET_FOR_TENANT: (system: string) =>
    GET(IPAM_CONFIGURE_PATH_FOR_TENANT(system)),
  CREATE: () => POST(IPAM_CONFIGURE_PATH()),
  UPDATE: (name: string) => PUT(`${IPAM_CONFIGURE_PATH}/${name}`),
  DELETE: (name: string) => DELETE(`${IPAM_CONFIGURE_PATH()}/${name}`),
  USAGE: () => GET("config/ipam/pool_usage"),
};

// FQDN / WEB FILETRING RANGE
const FQDN_PATH = (): string => `config/fqdn_category`;

export const FQDN: any = {
  LIST: () => GET(FQDN_PATH()),
  POST: () => POST(FQDN_PATH()),
  EDIT: (name: string) => PUT(`${FQDN_PATH()}/${name}`),
  DELETE: (name: string) => DELETE(`${FQDN_PATH()}/${name}`),
};

// CLOUD CONNECT
const CLOUD_CONNECT_PATH = () => `config/cloud_connect`;

export const CLOUD_CONNECT = {
  LIST_AWS: () => GET(`${CLOUD_CONNECT_PATH()}/aws`),
  CREATE_AWS: () => POST(`${CLOUD_CONNECT_PATH()}/aws`, { polling: true }),
  DELETE_AWS: (id: string) =>
    DELETE(`${CLOUD_CONNECT_PATH()}/aws/${id}`, { polling: true }),
  LIST_AZURE: () => GET(`${CLOUD_CONNECT_PATH()}/azure`),
  CREATE_AZURE: () => POST(`${CLOUD_CONNECT_PATH()}/azure`, { polling: true }),
  DELETE_AZURE: (id: string) =>
    DELETE(`${CLOUD_CONNECT_PATH()}/azure/${id}`, { polling: true }),
  LIST_GCP: () => GET(`${CLOUD_CONNECT_PATH()}/gcp`),
  CREATE_GCP: () => POST(`${CLOUD_CONNECT_PATH()}/gcp`, { polling: true }),
  DELETE_GCP: (id: string) =>
    DELETE(`${CLOUD_CONNECT_PATH()}/gcp/${id}`, { polling: true }),
};

// SERVICES PACKAGES
const SERVICES_PACKAGES_PATH = () => `config/services_package`;

export const SERVICES_PACKAGES = {
  LIST: () => GET(SERVICES_PACKAGES_PATH()),
};

/* ----------------------------TENANT---------------------------- */
/* ---------------------------------------------------------------*/
const TENANT_PATH = (tenant: string) => `tenant/${tenant}`;

export const TENANT: { [key: string]: ApiFunc } = {
  LIST: (offset, skip) =>
    GET(`${TENANT_PATH_PREFIX}${createOffsetSkipQuery(offset, skip)}`),
  ADD: () => POST(`${TENANT_PATH_PREFIX}`),
  EDIT: (tenant: string) => PUT(`${TENANT_PATH(tenant)}`),
  GET: (tenant: string) => GET(`${TENANT_PATH(tenant)}`),
  DELETE: (id: string) => DELETE(`${TENANT_PATH(id)}`),
  EDIT_CONTACT: (tenant: string, params: string) =>
    PUT(`${TENANT_PATH(tenant)}/contact_info?${params}`),
  EDIT_LOCATIONS: (tenant: string) =>
    PATCH(`${TENANT_PATH(tenant)}/add_systems`),
  REMOVE_SYSTEMS: (tenant: string) =>
    PATCH(`${TENANT_PATH(tenant)}/remove_systems`),
  EDIT_QUOTAS: (tenant: string, params: string) =>
    PUT(`${TENANT_PATH(tenant)}/quotas?${params}`),
  EDIT_SERVICES: (tenant: string) =>
    PATCH(`${TENANT_PATH(tenant)}/services`, { polling: true }),
  VI_LIST: (tenant: string) => GET(`${TENANT_PATH(tenant)}`),
  THRESHOLD_LIST: (tenant: string) =>
    GET(`${TENANT_PATH(tenant)}/max_bandwidth`),
  DELETE_USER: (tenant: string) => DELETE(`${TENANT_PATH(tenant)}/users`),
  UPDATE_USER: (tenant: string) => PUT(`${TENANT_PATH(tenant)}/users`),
  CREATE_USER: (tenant: string) => POST(`${TENANT_PATH(tenant)}/users`),
  GET_USERS_GROUPS: (tenant: string) =>
    GET(`${TENANT_PATH(tenant)}/users/group`),
  CREATE_USERS_GROUPS: (tenant: string) =>
    POST(`${TENANT_PATH(tenant)}/users/group`),
  CREATE_DIA: (tenant: string) => POST(`${TENANT_PATH(tenant)}/dia`),
  DELETE_DIA: (tenant: string, dia: string) =>
    DELETE(`${TENANT_PATH(tenant)}/dia/${dia}`),
};

//USER GROUPS
export const USER_GROUPS_PATH = {
  LIST: (tenant: string) => GET(`${SERVICE_PATH(tenant)}/group`),
  CREATE: (tenant: string) => POST(`${SERVICE_PATH(tenant)}/group`),
  GET_GROUP: (tenant: string, groupName: string) =>
    GET(`${SERVICE_PATH(tenant)}/group/${groupName}`),
  UPDATE_GROUP: (tenant: string, groupName: string) =>
    PUT(`${SERVICE_PATH(tenant)}/group/${groupName}`),
  DELETE_GROUP: (tenant: string, groupName: string) =>
    DELETE(`${SERVICE_PATH(tenant)}/group/${groupName}`),
  GLOBAL_LIST: () => GET("group"),
};

// VIRTUAL INTERFACE
const VIRTUAL_INTERFACE_TENANT_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/virtual_interface`;

// Groups
export const GROUPS_PATH = {
  GET_GROUPS: () => GET(`group`),
  CREATE_GROUP: () => POST(`group`),
  UPDATE_GROUP: (name: string) => PUT(`group/${name}`),
  DELETE_GROUP: (name: string) => DELETE(`group/${name}`),
};

// TODO Remove ??
export const IPSEC_PATH: any = {
  GET_ALL_VIS: (tenant: string) =>
    GET(`tenant/${tenant}/virtual_interface/ipsec`),
  GET_ALL_GATEWAYS: (tenant: string) =>
    GET(`tenant/${tenant}/virtual_interface/ipsec_gateway`),
};

// TODO Remove ??
const SERVICE_INTERNET_PATH = (tenant: string): string =>
  `config/service/${tenant}/internet`;
export const SERVICE_INTERNET: { [key: string]: ApiFunc } = {
  ADD_NAT: (tenant: string) => POST(`${SERVICE_INTERNET_PATH(tenant)}/nat`),
  ADD_IPSEC: (tenant: string) => POST(`${SERVICE_INTERNET_PATH(tenant)}/ipsec`),
  ADD_LOAD_BALANCER: (tenant: string) =>
    POST(`${SERVICE_INTERNET_PATH(tenant)}/load_balancer`),
};

// TOD Remove ??
export const CONNECTIONS_INTERFACES: any = {
  DELETE_BRIDGE: (tenant: string, name: string) =>
    DELETE(`tenant/${tenant}/virtual_interface/${name}`),
  DELETE_IPSEC_GATEWAY: (tenant: string, name: string) =>
    DELETE(`tenant/${tenant}/virtual_interface/ipsec_gateway/${name}`),
  DELETE_IPSEC: (tenant: string, name: string) =>
    DELETE(`tenant/${tenant}/virtual_interface/ipsec/${name}`),
};

// TODO Remove ??
export const TENANT_VIRTUAL_INTERFACES: { [key: string]: ApiFunc } = {
  GET: (tenant: string) =>
    GET(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/all_types`),
};

// TODO Remove ??
export const VIRTUAL_INTERFACE_TENANT: { [key: string]: ApiFunc } = {
  LIST_ALL: (tenant: string) =>
    GET(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/all_types`),
  LIST: (tenant: string) => GET(VIRTUAL_INTERFACE_TENANT_PATH(tenant)),
  LIST_NAT: (tenant: string) =>
    GET(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/nat`),
  GET: (tenant: string, vi_name: string) =>
    GET(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`),
  ADD: (tenant: string) =>
    POST(VIRTUAL_INTERFACE_TENANT_PATH(tenant), {
      polling: true,
      extendedWaiting: true,
    }),
  EDIT: (tenant: string, vi_name: string) =>
    PATCH(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}/ip_addresses`, {
      polling: true,
    }),
  DELETE: (tenant: string, vi_name: string) =>
    DELETE(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`, {
      polling: true,
    }),
};

// VI ALL TYPES
export const TENANT_VI_ALL_TYPES: { [key: string]: ApiFunc } = {
  LIST: (tenant: string) =>
    GET(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/all_types`),
};

// VI BRIDGE DOMAIN
export const TENANT_VI_BRIDGE_DOMAIN: { [key: string]: ApiFunc } = {
  LIST: (tenant: string) => GET(VIRTUAL_INTERFACE_TENANT_PATH(tenant)),
  POST: (tenant: string) =>
    POST(VIRTUAL_INTERFACE_TENANT_PATH(tenant), {
      polling: true,
    }),
  EDIT: (tenant: string, vi_name: string) =>
    PUT(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`, {
      polling: true,
    }),
  DELETE: (tenant: string, vi_name: string) =>
    DELETE(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`, {
      polling: true,
    }),
  EDIT_IPS: (tenant: string, vi_name: string) =>
    PATCH(`${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}/ip_addresses`, {
      polling: true,
    }),
  EDIT_SEGMENTS: (tenant: string, vi_name: string) =>
    PATCH(
      `${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}/network_segments`,
      {
        polling: true,
      }
    ),
};

// VIRTUAL INTERFACE GATE
const GATE_VIRTUAL_INTERFACE_TENANT_PATH = (tenant: string): string =>
  `${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/gate`;

export const TENANT_VI_GATE: { [key: string]: ApiFunc } = {
  LIST: (tenant: string) =>
    GET(`${GATE_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}`),
  POST: (tenant: string) =>
    POST(`${GATE_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}`),
  EDIT: (tenant: string, vi_name: string) =>
    PUT(`${GATE_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`),
  DELETE: (tenant: string, vi_name: string) =>
    DELETE(`${GATE_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`),
};

// VIRTUAL INTERFACE IPSEC
const IPSEC_VIRTUAL_INTERFACE_TENANT_PATH = (tenant: string): string =>
  `${VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/ipsec`;

export const TENANT_VI_IPSEC: { [key: string]: ApiFunc } = {
  LIST: (tenant: string) =>
    GET(`${IPSEC_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}`),
  POST: (tenant: string) =>
    POST(`${IPSEC_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}`, {
      polling: true,
    }),
  POST_DEFAULT: (tenant: string) =>
    POST(`${IPSEC_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/default`, {
      polling: true,
    }),
  EDIT: (tenant: string, vi_name: string) =>
    PUT(`${IPSEC_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`, {
      polling: true,
    }),
  DELETE: (tenant: string, vi_name: string) =>
    DELETE(`${IPSEC_VIRTUAL_INTERFACE_TENANT_PATH(tenant)}/${vi_name}`, {
      polling: true,
    }),
};

// AWS
const AWS_ACCESS_KEY_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/aws_access_key`;
export const AWS_ACCESS_KEY: any = {
  GET: (tenant: string) => GET(AWS_ACCESS_KEY_PATH(tenant)),
  POST: (tenant: string) => POST(AWS_ACCESS_KEY_PATH(tenant)),
  DELETE: (tenant: string, uuid: UUID) => DELETE(AWS_ACCESS_KEY_PATH(tenant)),
};

// AZURE
const AZURE_ACCESS_KEY_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/azure_access_key`;
export const AZURE_ACCESS_KEY: any = {
  GET: (tenant: string) => GET(AZURE_ACCESS_KEY_PATH(tenant)),
  POST: (tenant: string) => POST(AZURE_ACCESS_KEY_PATH(tenant)),
  DELETE: (tenant: string) => DELETE(AZURE_ACCESS_KEY_PATH(tenant)),
};

// AZURE - extract data from the key
const AZURE_KEY_DATA_PATH = (tenant: string): string =>
  `${TENANT_PORTS_PATH(tenant)}`;
export const AZURE_KEY_DATA: any = {
  GET: (tenant: string, key: string) =>
    GET(`${AZURE_KEY_DATA_PATH(tenant)}/azure_service_key?service_key=${key}`),
};
export const AZURE_PEERING_LOCATIONS: any = {
  GET: () => GET(`/sites/azure_peering_locations`),
};

const SERVICE_CONNECTIONS_PATH = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/connection/connections`;
export const SERVICE_CONNECTIONS: any = {
  POST: (tenant: string) => POST(`${SERVICE_CONNECTIONS_PATH(tenant)}`),
};

// Aruba
const ARUBA_ACCESS_KEY_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/aruba_access_key`;
export const ARUBA_ACCESS_KEY: any = {
  GET: (tenant: string) => GET(ARUBA_ACCESS_KEY_PATH(tenant)),
  POST: (tenant: string) => POST(ARUBA_ACCESS_KEY_PATH(tenant)),
  DELETE: (tenant: string, uuid: UUID) => DELETE(ARUBA_ACCESS_KEY_PATH(tenant)),
};
const SERVICE_ARUBA = (tenant: string): string =>
  `${SERVICE_PATH(tenant)}/aruba`;
export const ARUBA_BROKER: any = {
  DISCOVER: (tenant: string) => GET(`${SERVICE_ARUBA(tenant)}/discover`),
  CONNECT: (tenant: string) => POST(`${SERVICE_ARUBA(tenant)}/connect`),
};
export const ARUBA_BROKER_API: any = {
  APPLIANCE: (tenant: string) => GET(`${SERVICE_ARUBA(tenant)}/api/appliance`),
  GROUP: (tenant: string) => GET(`${SERVICE_ARUBA(tenant)}/api/gms/group`),
  MAP: (tenant: string) => GET(`${SERVICE_ARUBA(tenant)}/api/gms/grNode`),
  STATS: (tenant: string, path: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/${path}`);
  },
  HEALTH: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/health`);
  },
  HEALTH_LOSS: (tenant: string) => {
    return GET(`${SERVICE_ARUBA(tenant)}/api/health/lossPeriodSummary`);
  },
  HEALTH_LATENCY: (tenant: string) => {
    return GET(`${SERVICE_ARUBA(tenant)}/api/health/latencyPeriodSummary`);
  },
  HEALTH_JITTER: (tenant: string) => {
    return GET(`${SERVICE_ARUBA(tenant)}/api/health/jitterPeriodSummary`);
  },
  HEALTH_MOS: (tenant: string) => {
    return GET(`${SERVICE_ARUBA(tenant)}/api/health/mosPeriodSummary`);
  },
  APPLICATION: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/application2`);
  },
  BANDWIDTH: (tenant: string) => {
    return POST(
      `${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/overlays/bandwidth`
    );
  },
  DNS: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/dns`);
  },
  FLOW: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats/aggregate/flow/active`);
  },
  TUNNEL: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/tunnel`);
  },
  PORTS: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/ports`);
  },
  TOP_TALKERS: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/topTalkers`);
  },
  USER: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/user2`);
  },
  INTERFACE: (tenant: string) => {
    return POST(`${SERVICE_ARUBA(tenant)}/api/stats/aggregate/interface`);
  },
  INTERFACE_OVERLAY: (tenant: string) => {
    return POST(
      `${SERVICE_ARUBA(tenant)}/api/stats2/aggregate/interfaceOverlay`
    );
  },
  INTERFACE_TIME_SERIES: (tenant: string) => {
    return GET(`${SERVICE_ARUBA(tenant)}/api/stats/timeseries/interface`);
  },
};

// GCP *NOT IN BROKER* ??
const GCP_ACCESS_KEY_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/gcp_access_key`;
export const GCP_ACCESS_KEY: any = {
  GET: (tenant: string) => GET(GCP_ACCESS_KEY_PATH(tenant)),
  POST: (tenant: string) => POST(GCP_ACCESS_KEY_PATH(tenant)),
  DELETE: (tenant: string, uuid: UUID) => DELETE(GCP_ACCESS_KEY_PATH(tenant)),
};

// PUBLIC IP
const REQUEST_GATE_VI_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/request_gate_vi`;

export const REQUESTED_GATE_VI: { [key: string]: ApiFunc } = {
  POST: (tenant: string) =>
    POST(`${REQUEST_GATE_VI_PATH(tenant)}`, { polling: true }),
  DELETE: (tenant: string, vi_name: string) =>
    DELETE(`${REQUEST_GATE_VI_PATH(tenant)}/${vi_name}`, { polling: true }),
};

// ORDER LOG
export const ORDER_LOG: { [key: string]: ApiFunc } = {
  GET: (tenant: string) => GET(`${SERVICE_PATH(tenant)}/events`),
};

/* ----------------------------MIXED---------------------------- */
/* ------------------------------------------------------------- */
// LICENSE, ISO
export const LICENSE: { [key: string]: ApiFunc } = {
  SET: (key: string) => POST(`iso/license?file_content=${key}`),
};

export const ISO: { [key: string]: ApiFunc } = {
  GET_VERSIONS: () => GET(`iso/`),
  DELETE_VERSION: (version: string) => DELETE(`iso/${version}`),
  CHECK_VERSION: () => GET(`iso/link`),
  DOWNLOAD_BY_URL: () => POST(`iso/download`),
  CHECK_DOWNLOAD_STATUS: (taskId: string) => GET(`iso/status/${taskId}`),
};

// USERS
const GET_TOKEN_PATH = (): string => `users/get_token`;
const GET_TOKEN_LIFESPAN = (): string => `users/token_lifespan`;
const USER_PATH = (): string => `users`;
const USERS_PER_TENANT_PATH = (tenant: string): string =>
  `tenant/${tenant}/users`;
const CURRENT_USER_PATH = (): string => `users/current`;
const SSO_PATH = (): string => "users/get_sso_providers";
const GET_TOKEN_SSO = (): string => "users/get_token_sso";

export const USER_BROKER = {
  GET_USER: () => GET(USER_PATH()),
  GET_CURRENT_USER: () => GET(CURRENT_USER_PATH()),
  EDIT_CURRENT_USER: () => PUT(CURRENT_USER_PATH()),
  EDIT_PASSWORD_CURRENT_USER: (value: string) =>
    PUT(`${CURRENT_USER_PATH()}/password?new_password=${value}`),
  UPDATE_USER: () => PUT(USER_PATH()),
  ADD_USER: () => POST(USER_PATH()),
  DELETE_USER: () => DELETE(USER_PATH()),
  GET_TENANT_USERS: (tenant: string) => GET(USERS_PER_TENANT_PATH(tenant)),
  GET_TENANT_USER: (tenant: string, user: string) =>
    GET(`${USERS_PER_TENANT_PATH(tenant)}?username=${user}`),
  ADD_USER_TO_TENANT: (tenant: string) => POST(USERS_PER_TENANT_PATH(tenant)),
  GET_INFO_TEXTS: () => GET(UI_INFO_TEXTS()),
  GET_SSO_PROVIDERS: () => GET(SSO_PATH()),
  GET_TOKEN_SSO: () => POST(GET_TOKEN_SSO()),
};

export const AUTH_BROKER: { [key: string]: ApiFunc } = {
  GET_TOKEN: () => POST(GET_TOKEN_PATH()),
  GET_TOKEN_LIFESPAN: () => GET(GET_TOKEN_LIFESPAN()),
};

// NIRO
export const VERSION_PATH: ApiEntry = ["GET", "niro/version"];
const UI_INFO_TEXTS = (): string => `niro/help_text`;
const NIRO_CLOUD_CONNECT_AWS = (): string => `niro/cloudconnect/aws/keys`;
const NIRO_CLOUD_CONNECT_AZURE = (): string => `niro/cloudconnect/azure/keys`;
const NIRO_CLOUD_CONNECT_GPC = (): string => `niro/cloudconnect/gcp/keys`;

export const NIRO = {
  FACTORY_RESET: () => POST(`niro/factory_reset`),
  EXPORT_CONFIG: (type: string) =>
    POST(`config/restore/export?archive_type=${type}`),
  IMPORT_CONFIG: (type: string) =>
    POST(`config/restore/import?config_type=${type}`),
  DOWNLOAD_CONFIG: (uuid: UUID) => GET(`config/restore/export/${uuid}`),
  GET_BRANDING: () => GET(`niro/niro_branding`),
  GET_AWS_KEYS: () => GET(NIRO_CLOUD_CONNECT_AWS()),
  CREATE_AWS_KEY: () => POST(NIRO_CLOUD_CONNECT_AWS()),
  DELETE_AWS_KEY: (id?: number) => DELETE(`${NIRO_CLOUD_CONNECT_AWS()}/${id}`),
  UPDATE_AWS_KEY: (id?: number) => PUT(`${NIRO_CLOUD_CONNECT_AWS()}/${id}`),
  GET_AZURE_KEYS: () => GET(NIRO_CLOUD_CONNECT_AZURE()),
  CREATE_AZURE_KEY: () => POST(NIRO_CLOUD_CONNECT_AZURE()),
  DELETE_AZURE_KEY: (id?: number) =>
    DELETE(`${NIRO_CLOUD_CONNECT_AZURE()}/${id}`),
  UPDATE_AZURE_KEY: (id?: number) => PUT(`${NIRO_CLOUD_CONNECT_AZURE()}/${id}`),
  GET_GCP_KEYS: () => GET(NIRO_CLOUD_CONNECT_GPC()),
  CREATE_GCP_KEY: () => POST(NIRO_CLOUD_CONNECT_GPC()),
  DELETE_GCP_KEY: (id?: number) => DELETE(`${NIRO_CLOUD_CONNECT_GPC()}/${id}`),
  UPDATE_GCP_KEY: (id?: number) => PUT(`${NIRO_CLOUD_CONNECT_GPC()}/${id}`),
};

// QUOTA
const QUOTA_PATH = (quotaPackage: string): string =>
  `niro/quota/${quotaPackage}`;
const QUOTA_TENANT_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/quota`;

export const QUOTA: { [key: string]: ApiFunc } = {
  GET: (quotaPackage: string) => GET(`${QUOTA_PATH(quotaPackage)}`),
  EDIT: (quotaPackage: string) => PUT(`${QUOTA_PATH(quotaPackage)}`),
  GET_TENANT_QUOTA: (tenant: string) => GET(`${QUOTA_TENANT_PATH(tenant)}`),
  EDIT_TENANT_QUOTA: (tenant: string) =>
    PATCH(`${QUOTA_TENANT_PATH(tenant)}_package`),
  EDIT_TENANT_QUOTA_VAL: (tenant: string) =>
    PUT(`${QUOTA_TENANT_PATH(tenant)}`),
};

// SERVICES PACKAGE
const SERVICES_PACKAGE_TENANT_PATH = (tenant: string): string =>
  `${TENANT_PATH(tenant)}/services`;

export const SERVICES_PACKAGE = {
  EDIT_TENANT_SERVICES_PACKAGE: (tenant: string) =>
    PATCH(`${SERVICES_PACKAGE_TENANT_PATH(tenant)}_package`),
};

// LOCATION
export const LOCATIONS: { [key: string]: ApiFunc } = {
  GET: () => GET(`location`),
};

// REPORTS
const TENANT_REPORT_PATH = (tenant: string): string =>
  `${REPORT_PATH}/statistics/tenant/${tenant}`;

export const REPORT = {
  FW_RULES: (tenant: string, type: string, params: string): ApiEntry =>
    GET(`${TENANT_REPORT_PATH(tenant)}/fw/${type}` + params),
};

// PRICING
const SERVICE_CATALOG_PATH = () => "orders/catalog";

export const SERVICE_CATALOG = {
  GET: () => GET(SERVICE_CATALOG_PATH()),
};

// TERMS AND CONDITIONS
const TERMS_AND_CONDITIONS_PATH = () => "orders/terms_and_conditions";

export const TERMS_AND_CONDITIONS = {
  GET_ALL: () => GET(`${TERMS_AND_CONDITIONS_PATH()}/all`),
  SIGN: (tenant: string) =>
    POST(`${TERMS_AND_CONDITIONS_PATH()}/${tenant}/sign`),
};
