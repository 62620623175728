import { PosProps } from "../utils";
import React from "react";

export default function InterfaceConnectorWithLabel({
  from,
  to,
  active,
  label,
  labelWidth,
  labelHeight,
  additional,
}: {
  from: PosProps;
  to: PosProps;
  active?: boolean;
  label?: string;
  labelWidth: number;
  labelHeight: number;
  additional?: string;
}): React.ReactElement {
  const x = (to.x + from.x) / 2;
  const y = (to.y + from.y) / 2;
  return (
    <>
      <path
        stroke={active ? "rgba(107, 85, 240, 0.60)" : "#BEB8EA"}
        d={`M${from.x} ${from.y}L${to.x} ${to.y}`}
        strokeWidth={active ? 2.5 : 1}
      />
      {label && (
        <>
          <rect
            width={labelWidth}
            height={labelHeight}
            stroke={active ? "rgba(107, 85, 240, 0.60)" : "#BEB8EA"}
            strokeWidth={active ? 2.5 : 1}
            fill="white"
            x={x - labelWidth / 2}
            y={y - 10}
            rx={5}
            ry={5}
          />
          <text
            height={labelHeight}
            fill="var(--font)"
            fontSize="12"
            x={x + 5}
            y={y + 3}
            textAnchor="middle"
          >
            {label}
          </text>
        </>
      )}
      {additional && (
        <>
          <text
            height={labelHeight}
            fill={active ? "#6E39DF" : "#786FB9"}
            fontSize="12"
            x={x + 5}
            y={y + labelHeight + 5}
            textAnchor="middle"
          >
            {additional}
          </text>
        </>
      )}
    </>
  );
}
