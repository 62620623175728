import DashboardLeft from "./components/leftPart/DashboardLeft";
import DashboardRight from "./components/rightPart/DashboardRight";
import styles from "./TopologyPage.module.scss";
import { withContexts } from "../../helpers/hocs/withContexts";
import { TopologyPageProvider } from "./context";
import { NetworkContextContainer } from "../Network/context/NetworkContext";

type Props = { tabs: any };

const TopologyPage = ({ tabs: Tabs }: Props) => {
  return (
    <>
      <Tabs />
      <div className={styles.twoColumns}>
        <DashboardLeft />
        <DashboardRight />
      </div>
    </>
  );
};

export default withContexts<Props>(TopologyPage, [
  TopologyPageProvider,
  NetworkContextContainer,
]);
