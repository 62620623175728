import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

export const elasticHAHealthLogs = (
  activeNode: string,
  standbyNode?: string,
  timeRange?: GTimeRange,
  timeRangeMS?: { from: number; to: number }
): { [key: string]: any } => {
  let gte, lte;
  if (timeRange) {
    gte = parseGTime(timeRange.from);
    lte = parseGTime(timeRange.to);
  }
  if (timeRangeMS) {
    gte = new Date(timeRangeMS.from).toISOString();
    lte = new Date(timeRangeMS.to).toISOString();
  }

  const elasticQuery = standbyNode
    ? {
        track_total_hits: false,
        sort: [
          {
            "@timestamp": {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        fields: [
          {
            field: "_",
            include_unmapped: "true",
          },
          {
            field: "@timestamp",
            format: "strict_date_optional_time",
          },
          {
            field: "event_type",
          },
          {
            field: "node_name",
          },
          {
            field: "current_score",
          },
          {
            field: "previous_score",
          },
          {
            field: "reasons.0.name",
          },
          {
            field: "reasons.1.name",
          },
          {
            field: "reasons.2.name",
          },
          {
            field: "reasons.3.name",
          },
          {
            field: "role",
          },
          {
            field: "syslog5424_ts",
            format: "strict_date_optional_time",
          },
        ],
        size: 500,
        version: true,
        script_fields: {},
        stored_fields: ["_"],
        runtime_mappings: {},
        _source: false,
        query: {
          bool: {
            must: [],
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              "event_type.keyword": "NSOS_Health",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              "event_type.keyword": "Node Role Changed",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              "node_name.keyword": activeNode,
                            },
                          },
                          {
                            match_phrase: {
                              "node_name.keyword": standbyNode,
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  minimum_should_match: 1,
                },
              },
              {
                range: {
                  "@timestamp": {
                    format: "strict_date_optional_time",
                    gte,
                    lte,
                  },
                },
              },
            ],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      }
    : {
        track_total_hits: false,
        sort: [
          {
            "@timestamp": {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        fields: [
          {
            field: "_",
            include_unmapped: "true",
          },
          {
            field: "@timestamp",
            format: "strict_date_optional_time",
          },
          {
            field: "event_type",
          },
          {
            field: "node_name",
          },
          {
            field: "current_score",
          },
          {
            field: "previous_score",
          },
          {
            field: "reasons.0.name",
          },
          {
            field: "reasons.1.name",
          },
          {
            field: "reasons.2.name",
          },
          {
            field: "reasons.3.name",
          },
          {
            field: "role",
          },
          {
            field: "syslog5424_ts",
            format: "strict_date_optional_time",
          },
        ],
        size: 500,
        version: true,
        script_fields: {},
        stored_fields: ["_"],
        runtime_mappings: {},
        _source: false,
        query: {
          bool: {
            must: [],
            filter: [
              {
                bool: {
                  should: [
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              "event_type.keyword": "NSOS_Health",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                    {
                      bool: {
                        should: [
                          {
                            match_phrase: {
                              "event_type.keyword": "Node Role Changed",
                            },
                          },
                        ],
                        minimum_should_match: 1,
                      },
                    },
                  ],
                  minimum_should_match: 1,
                },
              },
              {
                range: {
                  "@timestamp": {
                    format: "strict_date_optional_time",
                    gte,
                    lte,
                  },
                },
              },
              {
                match_phrase: {
                  "node_name.keyword": activeNode,
                },
              },
            ],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      };

  return elasticQuery;
};
