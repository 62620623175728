/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { systemApi } from "../../helpers/api/SystemApi";
import {
  PaginateListResponse,
  TrafficStatisticsType,
  ConnectionStatisticsType,
} from "../../helpers/api/apiTypes";
import { RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { createContextUtils } from "../common/utils";
import { getNodeFromSystem } from "../../helpers/getNodeFromSystem";
import { System, SystemNode } from "../../pages/Systems/Provisioning/types";

type RequestStatuses = {
  oneSystemStatus?: RequestStatus;
  systemTrafficStatus?: RequestStatus;
  interfaceStatStatus?: RequestStatus;
  resetStatus?: RequestStatus;
};

type IState = {
  system: System | undefined;
  systemConnectionsStat: PaginateListResponse<ConnectionStatisticsType>;
  systemTrafficStat: PaginateListResponse<TrafficStatisticsType>;
  interfaceStatistics: PaginateListResponse<TrafficStatisticsType>;
  isInfoCollapsed: boolean;
  isActiveNode?: boolean;
  node?: SystemNode;
} & RequestStatuses;

type IFunc = {
  fetchSystem: (name: string) => void;
  resetSystem: (name?: string) => void;
  factoryResetSystem: (name?: string) => void;
  setIsInfoCollapsed: (newIsInfoCollapsed: boolean) => void;
};

export type IOneSystemContext = IState & IFunc;

const [
  UserContext,
  useOneSystemContext,
  withOneSystemContextProps,
] = createContextUtils<IState, IFunc>();

export { withOneSystemContextProps, useOneSystemContext };

export default class OneSystemContextContainer extends Component {
  funcs: IFunc;
  state: IState;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      system: undefined,
      systemConnectionsStat: { count: 0, total_count: 0, items: [] },
      systemTrafficStat: { count: 0, total_count: 0, items: [] },
      interfaceStatistics: { count: 0, total_count: 0, items: [] },
      isInfoCollapsed: false,
    };

    this.funcs = {
      fetchSystem: this.fetchSystem,
      setIsInfoCollapsed: this.setIsInfoCollapsed,
      resetSystem: this.resetSystem,
      factoryResetSystem: this.factoryResetSystem,
    };
  }

  fetchSystem = async (name: string): Promise<void> => {
    this.setState({ oneSystemStatus: setPending("Fetching") });
    const res = await systemApi.getSystemById(name);

    this.setState({
      system: res.result,
      node: getNodeFromSystem(res.result),
      oneSystemStatus: resToState(res),
    });
  };

  resetSystem = async (name?: string): Promise<void> => {
    if (!name) return;
    this.setState({ resetStatus: setPending("Fetching") });
    const res = await systemApi.resetSystem(name);
    this.setState({ resetStatus: resToState(res) });
  };

  factoryResetSystem = async (name?: string): Promise<void> => {
    if (!name) return;
    this.setState({ resetStatus: setPending("Fetching") });
    const res = await systemApi.factoryResetSystem(name);
    this.setState({ resetStatus: resToState(res) });
  };

  setIsInfoCollapsed = (newIsInfoCollapsed: boolean): void => {
    this.setState((oldData: IState) => {
      return {
        ...oldData,
        isInfoCollapsed: newIsInfoCollapsed,
      };
    });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
