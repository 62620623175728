import CloudProviderIcon from "../../../../components/icons/CloudProviderIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { getPercentageOfTotal } from "../../../../helpers/common/numberHelpers";
import { getBandwidthStringForTable } from "../../../../helpers/getBandwidthStringForTable";
import BWUsageIcon from "../../../Network/UnitedConnections/components/Ports/icons/BWUsageIcon";
import { getColorByPercentage, getColorClassByPercentage } from "../../helpers";
import { TotalProviderInfo } from "../../types";
import { CloudIcon } from "./CloudIcon";
import styles from "./CloudPanel.module.scss";

type CloudPanelProps = {
  item: string;
  total: TotalProviderInfo;
  onSelect: (item: string) => void;
  selectedCloud: string;
};

export const CloudPanel = ({
  item,
  total: {
    regions,
    nniCount,
    capacity: linkCapacity,
    connections,
    used,
    oversubscriptionCapacity,
  },
  onSelect,
  selectedCloud,
}: CloudPanelProps) => {
  const capacity = oversubscriptionCapacity
    ? oversubscriptionCapacity
    : linkCapacity;

  const usedBWLabel = getBandwidthStringForTable(used);
  const percentage = getPercentageOfTotal(used, capacity);
  const strokeLength = 3.14 * 25 * 2;
  const usedBW = strokeLength * (used / capacity);
  const freeBW = strokeLength - usedBW;

  return (
    <div
      className={classNames(
        styles.wrapper,
        item === selectedCloud && styles.selected
      )}
      onClick={() => onSelect(item)}
    >
      <div className={styles.cloudIcon}>
        <CloudIcon />
        <div className={styles.cloudProviderIcon}>
          <CloudProviderIcon id={item} />
        </div>
      </div>
      <BWUsageIcon
        used={used}
        all={capacity}
        className={styles.usageIcon}
        style={
          {
            "--used": used ? usedBW : 0,
            "--free": freeBW,
            "--stroke-final": !used
              ? "#d8daeb"
              : getColorByPercentage(percentage),
          } as Record<string, string | number>
        }
        strokeWidth={4}
      />
      <div className={styles.row}>
        <div className={styles.label}>Regions</div>
        <div>{regions}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>NNIs</div>
        <div>{nniCount}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Link Capacity</div>
        <div>{getBandwidthStringForTable(linkCapacity)}</div>
      </div>
      {!!oversubscriptionCapacity && (
        <div className={styles.row}>
          <div className={styles.label}>Oversubscription Capacity</div>
          <div>{getBandwidthStringForTable(oversubscriptionCapacity)}</div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.label}>Connections</div>
        <div>{connections}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Used</div>
        <div className={getColorClassByPercentage(percentage)}>
          {usedBWLabel} ({percentage}%)
        </div>
      </div>
    </div>
  );
};
