import { CellProps } from "react-table";
import { VirtualInterfaceType } from "../../../../../helpers/api/apiTypes";

import { CellFactory } from "../../newTable/cells/CellsFactory";
import { getBandwidthStringForTable } from "../../../../../helpers/getBandwidthStringForTable";
import TextWithIcon from "../../TextWithIcon";
import PortIcon from "../../../../icons/PortIcon";
import VLANIcon from "../../../../icons/VLANIcon";
import VTEPIcon from "../../../../icons/VTEPIcon";
import {
  PORT_INTERFACE_STRING,
  PORT_QINQ_STRING,
  PORT_VLAN_STRING,
  VTEP_VNI_STRING,
} from "../../../../../helpers/consts";
import QINQWithStatusIcon from "../../../../icons/QINQWithStatusIcon";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";

const cellFactory = new CellFactory<VirtualInterfaceType>({});

const getIcon = (type: string, operational_state: string) => {
  switch (type) {
    case PORT_VLAN_STRING:
      return (
        <VLANIcon indicatorColor={getColorFromStatus(operational_state)} />
      );
    case PORT_INTERFACE_STRING:
      return <PortIcon color={getColorFromStatus(operational_state)} />;
    case PORT_QINQ_STRING:
      return (
        <QINQWithStatusIcon color={getColorFromStatus(operational_state)} />
      );
    case VTEP_VNI_STRING:
      return <VTEPIcon color={getColorFromStatus(operational_state)} />;
    default:
      return <PortIcon color={getColorFromStatus(operational_state)} />;
  }
};

export const l2InterfacesTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row, cell: { value } }: CellProps<any>) => {
        return (
          <TextWithIcon
            icon={getIcon(
              row.original?.parent_interface_type,
              row.original?.operational_state
            )}
            text={value}
          />
        );
      },
    },
    {
      Header: "Alias",
      accessor: "alias",
      Cell: ({ row, cell: { value } }: CellProps<any>) => {
        return value || row.original?.name;
      },
    },
    {
      Header: "VI",
      accessor: "virtual_interface_name",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value;
      },
    },
    {
      Header: "MTU",
      accessor: "mtu",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value;
      },
    },
    {
      Header: "System",
      accessor: "system_name",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value;
      },
    },
    {
      Header: "Max Bandwidth",
      accessor: "max_bandwidth",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value ? getBandwidthStringForTable(value) : "Unlimited";
      },
    },
    {
      Header: "Op-state",
      accessor: "operational_state",
      Cell: cellFactory.opStateCell(),
    },
    {
      Header: "Trunk",
      accessor: "trunk",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value ? "Yes" : "No";
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInterfaceType,
  }));
};
