import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import FirewallIcon from "../../../components/icons/tabs/FirewallIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { FWDiaRuleType } from "../../../helpers/api/apiTypes";
import { formatDate } from "../../../helpers/formatDate";
import { ACTIONS } from "../../Firewall/config/FQDNSection";

type Props = {
  param: string;
};

const DiaFirewallRuleInfo: FC<Props> = ({ param }) => {
  const { selectedFwRule } = useFirewallContext();

  return (
    <SummaryInfoBlock icon={<FirewallIcon />} title="DIA FW Rule">
      {selectedFwRule && (
        <RuleInfoSection
          rule={selectedFwRule as FWDiaRuleType}
          tenant={param}
        />
      )}
    </SummaryInfoBlock>
  );
};
export default DiaFirewallRuleInfo;

type FWInfoProps = {
  rule: FWDiaRuleType;
  tenant: string;
};

const EMPTY_SECTION_VALUE = "-";

function getAction(rule: FWDiaRuleType): string {
  const action = ACTIONS.find(
    (action) => action.value === rule?.forwarding_policy
  );
  return action?.label || "";
}

const RuleInfoSection = ({ rule, tenant }: FWInfoProps) => {
  return (
    <>
      <UnifiedTextPair subtitle="Action" text={getAction(rule)} />
      <UnifiedTextPair subtitle="Tenant" text={tenant} />
      <UnifiedTextPair
        subtitle="Created by"
        text={rule.username || EMPTY_SECTION_VALUE}
      />
      <UnifiedTextPair
        subtitle="User IP"
        text={rule.user_ip_address || EMPTY_SECTION_VALUE}
      />
      <UnifiedTextPair
        subtitle="Created at"
        text={formatDate(rule.created_at)}
      />
      <UnifiedTextPair
        subtitle="Updated at"
        text={formatDate(rule.updated_at)}
      />
    </>
  );
};
