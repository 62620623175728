import React, { FC, useEffect, useState } from "react";

import styles from "../../../UsersGroup.module.scss";
import UserGroupComponent from "./UserGroupComponent";
import UserGroupsContextContainer, {
  useUserGroupsContext,
} from "../../../UserGroupsContext";
import { UserGroup } from "../../../../../helpers/types";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { GLOBAL_TENANT } from "../../../../../contexts/UserListContext";
import { withContexts } from "../../../../../helpers/hocs/withContexts";
import AddButton from "../../../../../components/common/buttons/AddButton";
import UserGroupComponentAdd from "./UserGroupComponentAdd";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";

export type Props = {
  type?: string;
};

const ContentUserGroup: FC<Props> = () => {
  const {
    groupsItems,
    groupStatus,
    fetchGroups,
    fetchUsers,
    createUsersGroup,
  } = useUserGroupsContext();

  const { selectedTenant } = useGlobalFilterContext();
  const [isAddGroup, setIsAddGroup] = useState(false);

  useEffect(() => {
    const fetchGroupsData = async () => {
      if (selectedTenant) {
        await fetchGroups(selectedTenant);
        await fetchUsers(selectedTenant);
      }
    };
    fetchGroupsData();
  }, [selectedTenant, groupsItems?.length]);

  const addNewGroup = () => {
    setIsAddGroup(true);
  };

  const closeAddGroup = () => {
    setIsAddGroup(false);
  };

  const handleAddGroup = async (data: UserGroup) => {
    if (!selectedTenant) return;
    const addGroupRes = await createUsersGroup(selectedTenant, {
      name: data.name,
      users: data.users,
      subnets: data.subnets,
    });
    if (addGroupRes) {
      closeAddGroup();
      fetchGroups(selectedTenant);
    }
  };

  return (
    <>
      {groupStatus?.state === "pending" ? (
        <PageLoader />
      ) : (
        <>
          <div className={styles.header}>
            Groups ({groupsItems?.length})
            <AddButton onClick={addNewGroup} />
          </div>
          {isAddGroup && (
            <UserGroupComponentAdd
              selectedTenant={selectedTenant || GLOBAL_TENANT}
              onAdd={handleAddGroup}
              onClose={closeAddGroup}
            />
          )}
          {groupsItems?.map((oneGroup: UserGroup) => {
            return (
              <UserGroupComponent
                key={oneGroup.name}
                group={oneGroup}
                selectedTenant={selectedTenant || GLOBAL_TENANT}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default withContexts<Props>(ContentUserGroup, [
  UserGroupsContextContainer,
]);
