import React, { FC } from "react";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getTrafficLink } from "../../common/trafficHelper";
import TrafficLayout from "../../common/TrafficLayout";
import { useTrafficContext } from "../../TrafficContext";
import styles from "./Trends.module.scss";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";

const Trends: FC<{ tabs: any }> = ({ tabs }) => {
  const { filters, isServerTraffic } = useTrafficContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  return (
    <TrafficLayout tabs={tabs}>
      <ServicesRedirectPlaceholder type="sdr">
        <GrafanaContainer type={GRAFANA_TYPES.SDR} className={styles.wrapper}>
          <div className={styles.chartsWrapper}>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 61 : 60,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 63 : 62,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 65 : 64,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 67 : 66,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 59 : 58,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 73 : 56,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
          </div>
        </GrafanaContainer>
      </ServicesRedirectPlaceholder>
    </TrafficLayout>
  );
};

export default Trends;
