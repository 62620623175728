import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function GlobeIconLessLines(props: IconProps): ReactElement {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={props.x}
      y={props.y}
    >
      <path
        d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
        fill="white"
        stroke="#59536B"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M10 17.5C12.0711 17.5 13.75 14.1421 13.75 10C13.75 5.85786 12.0711 2.5 10 2.5C7.92893 2.5 6.25 5.85786 6.25 10C6.25 14.1421 7.92893 17.5 10 17.5Z"
        stroke="#59536B"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 10H17.5"
        stroke="#59536B"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default GlobeIconLessLines;
