import React, { FC, useEffect } from "react";
import { useUserContext } from "../../../../../contexts/UserContext";
import styles from "./NiroVersion.module.scss";
import StatusIcon from "../../../../icons/StatusIcon";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import ISOIcon from "../../../../icons/ISOIcon";
import { useHistory } from "react-router-dom";
import { NSOS_SOFTWARE } from "../../../../../helpers/navigation/entries";

type Props = {};

const NiroVersion: FC<Props> = ({}) => {
  const { version, fetchVersion } = useUserContext();
  const history = useHistory();

  useEffect(() => {
    fetchVersion();
  }, []);

  if (!version) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.version}>
        <div className={styles.iconWrapper}>
          <StatusIcon />
        </div>
        {version}
      </div>
      <IconBlankButton
        id={"license"}
        icon={ISOIcon}
        label={"NSOS Software"}
        className={styles.btn}
        onClick={() => history.push("/" + NSOS_SOFTWARE().path)}
      />
    </div>
  );
};

export default NiroVersion;
