import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import {
  SEGMENTATION,
  SERVICES_FW,
  SERVICES_FW_DIA,
  SERVICES_NAT,
  SERVICES_PREFERENCES,
  WEB_FILTERING,
} from "../../helpers/navigation/entries";
import { Routes } from "../../pages/common";
import { RouteEntry } from "../../pages/types";
import Firewall from "../../pages/Firewall";
import NATRulesPage from "../../pages/NATRulesPage";
import { ServicesTab } from "../../components/common/navigation/Services/ServicesTab";
import ServicesPreferencesPage from "../../pages/ServicesPreferencesPage";
import Segments from "./components/Segments";
import WebFilteringPage from "../../pages/WebFilteringPage";
import FirewallDia from "../../pages/FirewallDia";

export default function Services(): ReactElement {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(SERVICES_FW(), path)} />
      </Route>
      <Routes
        routes={servicesRoutes}
        tabs={ServicesTab}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
}

const servicesRoutes: Array<RouteEntry> = [
  { pathFunc: SERVICES_FW, component: Firewall },
  { pathFunc: SERVICES_NAT, component: NATRulesPage },
  { pathFunc: SEGMENTATION, component: Segments },
  { pathFunc: WEB_FILTERING, component: WebFilteringPage },
  { pathFunc: SERVICES_PREFERENCES, component: ServicesPreferencesPage },
  { pathFunc: SERVICES_FW_DIA, component: FirewallDia },
];
