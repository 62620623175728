import React, { FC, useEffect, useState, useMemo } from "react";
import {
  ChangeField,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import LocationIcon from "../../../../../components/icons/LocationIcon";
import { GREY_DISABLED } from "../../../../../helpers/common/colorAliases";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { SiteRegion } from "../../../../../helpers/api/apiTypes";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";
import { DropdownItem } from "../../../../../helpers/types";
import styles from "./RegionDropdown.module.scss";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  idx: number;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  regions: Array<SiteRegion>;
  disabled?: boolean;
};

const RegionDropdown: FC<Props> = ({
  fields,
  onChange,
  idx,
  isEdit,
  errors,
  regions = [],
  disabled,
}) => {
  const [list, setList] = useState<Array<DropdownItem>>([]);

  const filteredList = useMemo(() => {
    return regions.filter(
      (item) =>
        item.connection_type === "Cloud" &&
        item.cloud_region &&
        item.operator.toLowerCase() === fields.cloudType
    );
  }, [regions, fields.cloudType]);

  useEffect(() => {
    const list: Array<DropdownItem> = filteredList.map((item) =>
      mapStringToItem(item.cloud_region || "")
    );
    setList(list);
  }, [filteredList]);

  return (
    <DropdownBasic
      id={"location"}
      onChange={(item) => {
        if (item.key) {
          onChange("location", item.key);
        }
      }}
      placeholder={fields.location || "Select"}
      selected={mapStringToItem(fields.location)}
      label={"Region"}
      itemsList={list}
      disabled={disabled}
      isMedium
      error={errors?.[idx + "location"]}
      icon={<LocationIcon color={GREY_DISABLED} />}
      isNotEditable={isEdit}
      errorWithTooltip
      listClassName={styles.list}
    />
  );
};

export default RegionDropdown;
