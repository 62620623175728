import React, { FC } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import { useConnectionConfiguratorContext } from "../ConnectionConfiguratorContext";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./PortsLabels.module.scss";

const LABELS_VALUES: { [key: string]: Array<string> } = {
  cloudRouter: ["Cloud Router"],
  elan: ["ELAN"],
  azPorts: ['"A" Port', '"Z" Port'],
};

type Props = {
  className: string;
};

const PortsLabels: FC<Props> = ({ className }) => {
  const { portType } = useConnectionConfiguratorContext();
  const values = LABELS_VALUES[portType.key];
  return (
    <div
      className={classNames(styles.wrapper, styles[portType.key], className)}
    >
      {values.map((val) => (
        <div
          key={val}
          className={classNames(styles.labelBlock, styles[portType.key])}
        >
          {val.toUpperCase()}
        </div>
      ))}
    </div>
  );
};

export default PortsLabels;
