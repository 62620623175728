import { FC } from "react";
import styles from "./TenantConfigAdd.module.scss";
import InfoBlock from "../../../pages/Systems/Provisioning/components/SystemConfig/components/InfoBlock";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import { TenantServices } from "../../../helpers/api/apiTypes";
import { validateTenant } from "../../../helpers/validators/TenantValidator";
import { cloneDeep } from "lodash";
import LeftBlock from "./components/LeftBlock";
import ContentBlock from "./components/ContentBlock";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import { useLocalTenantsContext } from "../../../pages/TenantsPage/TenantsContext";
import { generateRandomPassword } from "../../../helpers/generateRandomPassword";
import InfoTextRight from "./components/InfoTextRight";

export type CreateTenantType = {
  name: string;
  email: string;
  contact_name: string;
  company_name: string;
} & TenantServices;

const DEFAULT_FIELDS = {
  name: "",
  systems: undefined,
  full_name: "",
  is_firewall_service: true,
  is_dia_fw_service: false,
  is_nat_service: true,
  is_ids_service: false,
  is_dns_service: true,
  is_ddos_service: false,
  is_segments_service: true,
  is_remote_user_service: false,
  is_fqdn_service: true,
  is_load_balancing_service: false,
  is_secure_web_gw_service: true,
  is_sdr_service: true,
  is_mesh_enabled: false,
  quota_package_name: "Essentials",
  username: "",
  password: generateRandomPassword(7),
  email: "",
  role: "admin",
  enabled: true,
  password_is_expired: true,
  first_name: "",
  last_name: "",
  sdr_service_rate: 500000,
};

type Props = {
  onClose: () => void;
};

const TenantConfigAdd: FC<Props> = ({ onClose }) => {
  const {
    addNewTenant,
    updateServices,
    addStatus,
    resetStatus,
    createAdminUser,
  } = useTenantContext();
  const { fetchData } = useLocalTenantsContext();
  const [fields, handleFieldChange] = useFormField<any>(
    cloneDeep(DEFAULT_FIELDS)
  );
  const [errors, validate] = useValidation<any>(validateTenant, [fields]);

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  const handleAdd = async () => {
    const { isOk } = validate();
    if (isOk) {
      const tenantFields = {
        name: fields.name || fields.full_name,
        systems: fields.systems,
        full_name: fields.full_name,
        is_firewall_service: fields.is_firewall_service,
        is_nat_service: fields.is_nat_service,
        is_ids_service: fields.is_ids_service,
        is_dns_service: fields.is_dns_service,
        is_ddos_service: fields.is_ddos_service,
        is_segments_service: fields.is_segments_service,
        is_remote_user_service: fields.is_remote_user_service,
        is_fqdn_service: fields.is_fqdn_service,
        is_load_balancing_service: fields.is_load_balancing_service,
        is_secure_web_gw_service: fields.is_secure_web_gw_service,
        is_sdr_service: fields.is_sdr_service,
        is_mesh_enabled: fields.is_mesh_enabled,
        quota_package_name: fields.quota_package_name,
        is_dia_fw_service: fields.is_dia_fw_service,
      };
      const adminFields = {
        username: fields.username.toLowerCase(),
        password: fields.password,
        email: fields.email,
        role: "admin",
        enabled: true,
        password_is_expired: true,
        first_name: fields.first_name,
        last_name: fields.last_name,
      };
      const isAdminFieldsFilled: boolean =
        adminFields.username && adminFields.password && adminFields.email;
      const tenantRes = await addNewTenant(tenantFields);
      if (tenantRes) {
        if (isAdminFieldsFilled) {
          await createAdminUser(tenantFields.name, adminFields);
        }
        if (fields.is_sdr_service) {
          const params = [
            {
              type: "sdr",
              enable: true,
              data: {
                rate: fields.sdr_service_rate,
              },
            },
          ];
          await updateServices(tenantFields.name, params);
        }
        fetchData();
        handleClose();
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <LeftBlock className={styles.basicAppearance} fields={fields} />
      <ContentBlock
        className={styles.basicAppearance}
        fields={fields}
        onChange={handleFieldChange}
        onClose={handleClose}
        onAction={handleAdd}
        actionStatus={addStatus}
        errors={errors}
      />
      <InfoBlock className={styles.basicAppearance}>
        <InfoTextRight />
      </InfoBlock>
    </div>
  );
};
export default TenantConfigAdd;
