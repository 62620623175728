import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withContexts } from "../../helpers/hocs/withContexts";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import {
  DETAILED_RECORDS,
  FLOWS,
  GEOLOCATION,
  LATENCY,
  OVERIVIEW,
  TRENDS,
} from "../../helpers/navigation/entries";
import { Routes } from "../common";
import { RouteEntry } from "../types";
import TrafficButtonsContextContainer from "./common/Buttons/TrafficButtonsContext";
import DetailedRecords from "./components/DetailedRecords";
import Flows from "./components/Flows";
import Geolocation from "./components/Geolocation";
import Overview from "./components/Overview";
import Trends from "./components/Trends";
import TrafficContextContainer from "./TrafficContext";
import Latency from "./components/Latency";

type Props = { tabs: any };

const TrafficDashboard: FC<Props> = ({ tabs: Tabs }) => {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(OVERIVIEW(), path)} />
      </Route>
      <Routes
        routes={dashboardRoutes}
        tabs={Tabs as any}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default withContexts<Props>(TrafficDashboard, [
  TrafficContextContainer,
  TrafficButtonsContextContainer,
]);

const dashboardRoutes: Array<RouteEntry> = [
  { pathFunc: OVERIVIEW, component: Overview },
  { pathFunc: TRENDS, component: Trends },
  { pathFunc: FLOWS, component: Flows },
  { pathFunc: LATENCY, component: Latency },
  { pathFunc: GEOLOCATION, component: Geolocation },
  { pathFunc: DETAILED_RECORDS, component: DetailedRecords },
];
