export type CreateIpam = {
  name: string;
  from_ip: string;
  to_ip: string;
  type: "global" | "location" | "system";
  data: string | null;
  vrf_name: string;
  ipsec_service: boolean;
  nat_service: boolean;
  snat_service: boolean;
  shared: boolean;
  min_allocated_pfl?: number;
  max_allocated_pfl?: number;
  iconType?: string;
};

export type CreateDIA = {
  ip: string;
  prefix_length: number;
  type: "global" | "location" | "system";
  data: string | null;
  vrf_name: string;
  dia_service: boolean;
  ipsec_service: boolean;
  nat_service: boolean;
  snat_service: boolean;
  shared: boolean;
  blocked: boolean;
  min_allocated_pfl: number;
  max_allocated_pfl: number;
  labels: {
    description: string;
  };
};

export type CreateDIAonTenant = {
  id: number;
  name: string;
  ip_pool_size: string;
  is_nat: boolean;
  is_snat: boolean;
  is_ipsec: boolean;
  tenant: string;
  system: { name: string; location: string };
};

export type IpamType = "global" | "location" | "system";

export type PublicIpManagementRecord = CreateIpam & {
  id: string;
};

export type PublicIpManagementServices =
  | "ipsec_service"
  | "snat_service"
  | "nat_service";

export type PublicIpManagementRow = Omit<
  PublicIpManagementRecord,
  PublicIpManagementServices
> & {
  services: Array<string>;
};

export const IPAM_SERVICES: { [key: string]: string } = {
  ipsec_service: "IPSEC",
  nat_service: "NAT",
  snat_service: "SNAT",
};

export type IpamRow = {
  subRows: Array<PublicIpManagementRow>;
  name: string;
};
