import { PropsWithChildren } from "react";
import { Res } from "../helpers/api/Api";
import { PaginateListResponse } from "../helpers/types";
import { Package } from "../pages/ServicesPreferencesPage/types";
import { createContextAndUse } from "./common/AbstractCrudContext";
import { useFetch } from "../helpers/hooks/useFetch";
import { configApi } from "../helpers/api/ConfigApi";
import { useAuthContext } from "./AuthContext";

type IFunc = {};

type IState = {
  packagesList: Array<Package>;
  isPackagesVisible: boolean;
  packagesListState?: Res<PaginateListResponse<Package>>;
};

const [PackagesContext, useContext] = createContextAndUse<IState, IFunc>();
export const usePackagesContext = useContext;

export const PackagesContextContainer = ({
  children,
}: PropsWithChildren<{}>) => {
  const { isAuthorized } = useAuthContext();

  const { state: packagesListState } = useFetch({
    fetchFn: configApi.getPackages.bind(configApi),
    isEnabled: isAuthorized,
  });

  return (
    <PackagesContext.Provider
      value={{
        packagesList: packagesListState.result?.items ?? [],
        packagesListState,
        isPackagesVisible: (packagesListState.result?.visible_count ?? 0) > 0,
      }}
    >
      {children}
    </PackagesContext.Provider>
  );
};
