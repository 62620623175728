import { FC } from "react";
import PortStatus from "./PortStatus";
import UnavailableBg from "./UnavailableBg";
import { PortTypeScheme } from "../../../../types";
import { getPortBodyColor } from "../helpers/getPortBodyColors";
import { usePortsContext } from "../../../../PortsContext";
import AsideConnections from "./AsideConnections";
import { getSubPorts } from "../helpers/getSubPorts";

type Props = {
  x: number;
  y: number;
  type: number;
  portIdx: number;
  data: Array<PortTypeScheme>;
};

const HEIGHT = 29;

const PortBody: FC<Props> = ({ x, y, type, portIdx, data }) => {
  const { setTooltip, hoveredPort, selectPort } = usePortsContext();

  const [subPorts, withSubPorts] = getSubPorts(data, type, portIdx);

  const isNotAvailable = data.length === 0;
  const isBackPlane = portIdx >= 34;
  const isHovered = data.find((port) => {
    return hoveredPort?.name === port.name;
  });

  const status = data && data[0]?.operational_state;
  const [fill, border] = getPortBodyColor(status, withSubPorts);

  return (
    <svg
      x={x}
      y={y}
      width="60"
      height="29"
      viewBox="0 0 60 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: isNotAvailable ? "auto" : "pointer" }}
      onMouseEnter={() =>
        (isNotAvailable || isBackPlane) &&
        setTooltip({
          id: portIdx.toString(),
          value: data[0] ? "Backplane port" : "Not available",
        })
      }
      onMouseLeave={() => setTooltip(undefined)}
    >
      {isNotAvailable && <UnavailableBg />}
      <rect
        x="0.5"
        y="0.5"
        width="59"
        height="28"
        rx="1.5"
        fill={fill}
        stroke={
          isBackPlane ? "#59536B" : isHovered ? "var(--accent-color)" : border
        }
      />
      {(isBackPlane || isHovered) && (
        <path
          d="M0 2C0 0.89543 0.895431 0 2 0H4V29H2C0.895431 29 0 28.1046 0 27V2Z"
          fill={isHovered ? "var(--accent-color)" : "#59536B"}
        />
      )}
      {!isNotAvailable && !withSubPorts && (
        <PortStatus cx="52" cy="8" r="3" status={status} />
      )}
      <text
        x={8}
        y={Math.ceil(HEIGHT / 2)}
        fill="var(--grey60)"
        fontSize="0.875rem"
        textAnchor="start"
        dominantBaseline="middle"
        fontWeight={600}
        opacity={isNotAvailable ? 0.6 : 1}
      >
        {type}/<tspan fill={"var(--grey-font)"}>{portIdx}</tspan>
      </text>
      {withSubPorts && <AsideConnections x={41} y={0} subPorts={subPorts} />}
    </svg>
  );
};

export default PortBody;
