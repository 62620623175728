import React, { FC } from "react";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getTrafficLink } from "../../common/trafficHelper";
import TrafficLayout from "../../common/TrafficLayout";
import { useTrafficContext } from "../../TrafficContext";
import styles from "./DetailedRecords.module.scss";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";

const DetailedRecords: FC<{ tabs: any }> = ({ tabs }) => {
  const { filters } = useTrafficContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  return (
    <TrafficLayout tabs={tabs}>
      <ServicesRedirectPlaceholder type="sdr">
        <GrafanaContainer
          type={GRAFANA_TYPES.SDR}
          className={styles.basicChart}
        >
          <GrafanaGraph
            link={getTrafficLink(
              10,
              timeRange.general,
              selectedTenant,
              filters
            )}
          />
        </GrafanaContainer>
      </ServicesRedirectPlaceholder>
    </TrafficLayout>
  );
};

export default DetailedRecords;
