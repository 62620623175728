import { FC, useEffect, useMemo, useState } from "react";
import styles from "./NodeConfig.module.scss";
import Input from "../../../../../../../components/common/formComponents/Input";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import IconBlankButton from "../../../../../../../components/common/buttons/IconBlankButton";
import CloseIcon from "../../../../../../../components/icons/CloseIcon";
import { GREY_FONT } from "../../../../../../../helpers/common/colorAliases";
import { useSystemConfigContext } from "../../SystemConfigContext";
import { FieldsType } from "../../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import DropdownWithSearch from "../../../../../../../components/common/Dropdown/DropdownWithSearch";
import { DropdownItem } from "../../../../../../../helpers/types";

type Props = {
  idx: number;
  fields: FieldsType;
  onChange: (fields: keyof FieldsType, value: any) => void;
  onDelete: () => void;
  errors?: { [key: string]: string };
  isEdit?: boolean;
};

const NodeConfig: FC<Props> = ({
  idx,
  fields,
  onChange,
  onDelete,
  errors,
  isEdit,
}) => {
  const {
    locations = [],
    system,
    fetchNonAssignedNodes,
    nonAssignedNodes,
  } = useSystemConfigContext();
  const isExistingNode = useMemo(
    () =>
      !!system?.nodes?.find(
        (node) => node.id === fields?.id && node.name === fields?.name
      ),
    [system, fields]
  );

  useEffect(() => {
    fetchNonAssignedNodes();
  }, []);

  const handleChange = (val: DropdownItem | string) => {
    if (typeof val === "string") {
      onChange("name", val);
    } else {
      onChange("name", val.key);
    }
  };

  const existingNode = useMemo(
    () => nonAssignedNodes?.find((node) => node.name === fields.name),
    [nonAssignedNodes, fields.name]
  );

  useEffect(() => {
    if (fields.name && existingNode) {
      onChange("address", existingNode.address);
      onChange("isExisting", true);
    } else if (fields.name) {
      onChange("isExisting", false);
    }
  }, [fields.name, existingNode]);

  return (
    <div className={styles.wrapper}>
      <DropdownWithSearch
        label="Node Name"
        id="name"
        placeholder={fields.name ?? ""}
        selected={mapStringToItem(fields.name)}
        valuesList={nonAssignedNodes?.map((el: any) => el.name)}
        onChange={handleChange}
        isMedium
        error={(errors && errors[`${idx}_name`]) || ""}
        disabled={isEdit || isExistingNode}
      />
      <Input
        label="Location"
        name="location"
        placeholder=""
        value={fields.location}
        handleFieldChange={onChange}
        medium
        valuesList={locations.map((loc) => loc.name)}
      />
      <Input
        label="Host IP"
        name="address"
        placeholder=""
        value={fields.address}
        handleFieldChange={onChange}
        medium
        error={errors && errors[`${idx}_address`]}
        isNotEditable={isEdit || isExistingNode}
        disabled={existingNode}
      />
      <Input
        label="Description"
        name="description"
        placeholder=""
        value={fields.description}
        handleFieldChange={onChange}
        medium
        error={undefined}
        isOptional
        isFullValue
      />
      {idx > 0 && (
        <IconBlankButton
          className={styles.deleteBtn}
          id={idx + "delete"}
          icon={CloseIcon}
          onClick={onDelete}
          color={GREY_FONT}
        />
      )}
    </div>
  );
};
export default NodeConfig;
