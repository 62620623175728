import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

export default function FirewallIcon({
  color,
  width = 19,
  height = 19,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 19 26"
      fill="none"
    >
      <path
        fill={color || FONT}
        d="M17.533 14.71a7.96 7.96 0 00-.694-2.515 9.669 9.669 0 00-1.318-2.103 8.375 8.375 0 00-.383-.444l-.182-.199a2.183 2.183 0 00-.12-.128l-.054-.055a2.708 2.708 0 01-.184-.2 3.072 3.072 0 00-.081-.084c-.039-.04-.083-.083-.122-.132l-.015-.018a13.198 13.198 0 01-.714-.847 5.65 5.65 0 01-.543-.84 3.855 3.855 0 01-.358-1.015 6.215 6.215 0 01-.126-1.14 9.615 9.615 0 01.035-1.039c.018-.2.037-.378.056-.534a.372.372 0 00-.464-.405 4.19 4.19 0 00-.54.182 4.93 4.93 0 00-1.105.639 5.23 5.23 0 00-1.183 1.264 5.518 5.518 0 00-.83 2.052 6.516 6.516 0 00.05 2.577 7.85 7.85 0 00.997 2.432c.252.4.493.735.73 1.016.072.089.268.317.27.32l.078.09a6.1 6.1 0 01.133.155c.037.044.068.08.101.116l.006.006c.372.445.634.772.851 1.061.24.318.45.627.643.942.206.338.353.633.463.928.123.335.205.698.243 1.081.034.337.035.715 0 1.154a10.766 10.766 0 01-.249 1.519.372.372 0 00.434.447c.175-.034.343-.079.51-.135a5.253 5.253 0 002.414-1.69c.449-.556.8-1.224 1.014-1.93.24-.785.323-1.66.237-2.527zm-7.293 2.28a4.414 4.414 0 00-.774-1.16l-.086-.094a1.477 1.477 0 00-.055-.06l-.024-.023a1.306 1.306 0 01-.077-.083l-.013-.014-.032-.034c-.019-.02-.037-.038-.053-.058a2.687 2.687 0 01-.584-.781 1.768 1.768 0 01-.163-.464 2.86 2.86 0 01-.057-.52c-.005-.16.004-.311.011-.411a.372.372 0 00-.53-.362 2.335 2.335 0 00-.942.823c-.129.192-.3.51-.379.935-.071.372-.064.79.022 1.177.081.376.239.76.456 1.113.115.182.225.335.33.46l.129.15v.001l.033.038a2.387 2.387 0 01.104.121l.005.006c.115.137.263.317.39.485.11.146.205.287.293.43a1.997 1.997 0 01.322.918c.015.154.015.326 0 .524a4.57 4.57 0 01-.05.378.372.372 0 00.506.408 2.396 2.396 0 00.962-.72c.205-.254.366-.559.464-.88.108-.355.146-.765.108-1.155a3.647 3.647 0 00-.316-1.149z"
      />
    </svg>
  );
}
