/* eslint-disable css-modules/no-unused-class */
import React, { CSSProperties, FC } from "react";
import styles from "./PortItem.module.scss";
import DeleteButton from "../../../../../../components/common/buttons/DeleteButton";
import ReactTooltip from "react-tooltip";
import IconBlankButton from "../../../../../../components/common/buttons/IconBlankButton";
import BWUsageIcon from "../icons/BWUsageIcon";
import LocationIcon from "../../../../../../components/icons/LocationIcon";
import { classNames } from "../../../../../../helpers/common/classNames";
import CaretDown from "../../../../../../components/icons/CaretDown";
import { VirtualInterfaceApi } from "../../../../../VirtualInterfacePage/types";
import { TenantPort } from "../../../types";

interface CustomCSSProperties extends CSSProperties {
  "--used": number;
  "--stroke-final"?: string;
  "--free": number;
}

interface PortDetailsProps {
  portName: string;
  location: string;
  usedBW: number;
  freeBW: number;
  assignedBW: number;
  operatorIcon: JSX.Element | string;
  operator: string | JSX.Element;
  hidden: boolean;
  hasConnections: boolean;
  pending: boolean;
  selectPortToDelete: () => void;
  setHidden: (value: boolean) => void;
  additionalDetails: JSX.Element;
  getConnectionsCount: () => JSX.Element;
  addConnectionBtn: JSX.Element;
  filteredConnections: Array<any>;
  hasFreeBw: boolean;
  getConnectionDetail: (connection: VirtualInterfaceApi) => JSX.Element;
  portIcon: any;
  isSecondary?: boolean;
  port?: TenantPort;
}

const PortDetails: FC<PortDetailsProps> = ({
  portName,
  location,
  usedBW,
  freeBW,
  assignedBW,
  hidden,
  hasConnections,
  pending,
  selectPortToDelete,
  setHidden,
  additionalDetails,
  getConnectionsCount,
  addConnectionBtn,
  filteredConnections,
  hasFreeBw,
  getConnectionDetail,
  portIcon,
  isSecondary,
  port,
}) => {
  const isDeleteDisabled = hasConnections || isSecondary;
  return (
    <div className={styles.form}>
      <div className={styles.titleWrapper}>
        <span className={classNames(styles.title, pending && styles.pending)}>
          {portIcon} {portName}
        </span>
        <div className={styles.connectionsInfoWrapper}>
          {hidden && getConnectionsCount()}
          <DeleteButton
            onClick={selectPortToDelete}
            className={styles.deleteIcon}
            disabled={isDeleteDisabled}
            id={portName}
          />
          <ReactTooltip
            disable={!isDeleteDisabled}
            id={`delete-${portName}`}
            aria-haspopup="true"
            place="bottom"
            type="light"
            className="tooltip"
          >
            {hasConnections && (
              <div>
                To delete this port, first remove all associated connections
              </div>
            )}
            {isSecondary && (
              <div>To delete this port, delete the associated primary port</div>
            )}
          </ReactTooltip>
          <IconBlankButton
            id={"customerPortDown"}
            icon={CaretDown}
            className={classNames(styles.icon, !hidden && styles.opened)}
            onClick={() => setHidden(!hidden)}
          />
        </div>
      </div>
      <div className={classNames(styles.wrapper, hidden && styles.hidden)}>
        <div className={styles.portData}>
          <div className={styles.iconsData}>
            {additionalDetails}
            <div className={styles.unitWrapper}>
              <BWUsageIcon
                used={port?.used_bw || usedBW}
                all={assignedBW}
                className={styles.bw}
                style={
                  {
                    "--used": !port?.used_bw ? 0 : usedBW,
                    "--free": freeBW,
                    "--stroke-final": !port?.used_bw ? "#d8daeb" : "",
                  } as CustomCSSProperties
                }
              />
            </div>
            <div className={styles.unitWrapper}>
              <LocationIcon />
              <div className={styles.unit}>
                <span className={styles.unitTitle}>Location:</span>
                <span className={styles.unitInfo}>{location || "-"}</span>
              </div>
            </div>
            {port?.labels?.building && (
              <div className={styles.unitWrapper}>
                <div className={styles.unit}>
                  <span className={styles.unitTitle}>Building:</span>
                  <span className={styles.unitInfo}>
                    {port?.labels?.building}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
        {!pending && (
          <div className={styles.connections}>
            {hasConnections ? (
              <div>
                {filteredConnections.map((connection, i) => (
                  <div key={i}>{getConnectionDetail(connection)}</div>
                ))}
                {hasFreeBw && addConnectionBtn}
              </div>
            ) : hasFreeBw ? (
              addConnectionBtn
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default PortDetails;
