import { classNames } from "../../../../../helpers/common/classNames";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./Activity.module.scss";

type ActivityProps = {
  activity: string;
};

export const Activity = ({ activity }: ActivityProps) => {
  return (
    <div
      className={classNames(
        styles[activity.toLowerCase()] ?? styles.update,
        styles.activity
      )}
    >
      {activity}
    </div>
  );
};
