import React, { PropsWithChildren } from "react";
import { UserRoles } from "../rolesHelpers";
import { useUserContext } from "../../contexts/UserContext";

// to show component for a specific role

type Options = {
  roles?: Array<UserRoles>;
};

export type Props = {
  roles?: Array<UserRoles>;
};

function withUserRole<P>(
  WrappedComponent: React.FC<P & { [K in keyof Props]: never }>,
  options: Options = {}
): React.FC<P & Props> {
  // eslint-disable-next-line react/display-name
  return (props): React.ReactElement | null => {
    const { user } = useUserContext();
    const { roles } = options;
    // eslint-disable-next-line react/prop-types
    const { roles: propsRoles, ...restProps } = props;
    const noRestrictions = !propsRoles && !roles;
    const allRoles = [...(propsRoles || []), ...(roles || [])];
    const hasAccess = noRestrictions || allRoles.includes(user?.role);
    if (hasAccess) {
      return <WrappedComponent {...(restProps as P)} />;
    }

    return null;
  };
}

export default withUserRole;

const _WithUserRoleComponent: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  return <>{children}</>;
};

export const WithUserRole = withUserRole(_WithUserRoleComponent);
