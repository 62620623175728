import Validator from "./Validator";
import { RequestCustomerPort } from "../../pages/Network/UnitedConnections/types";

export default function validateRequestPort(
  data: RequestCustomerPort,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkEmpty(data.max_bandwidth, "max_bandwidth");
  return validator;
}
