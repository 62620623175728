import React, { FC } from "react";
import { classNames } from "../../../../helpers/common/classNames";
import styles from "./ChartsContainer.module.scss";
import { useSystemProvisioningContext } from "../ProvisioningContextContainer";
import OverallCharts from "./components/OverallCharts";
import SystemCharts from "./components/SystemCharts";
import GrafanaContainer from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";

type Props = { className: string; isHidden: boolean };

const ChartsContainer: FC<Props> = ({ className, isHidden }) => {
  const { selectedRow } = useSystemProvisioningContext();

  return (
    <GrafanaContainer
      className={classNames(styles.wrapper, className)}
      type={"systems"}
      isHidden={isHidden}
      skipDocCountCheck
    >
      <div className={styles.chartsContainer}>
        {!selectedRow || selectedRow === "all" ? (
          <OverallCharts />
        ) : (
          <SystemCharts />
        )}
      </div>
    </GrafanaContainer>
  );
};
export default ChartsContainer;
