import { FC, useMemo } from "react";
import Button from "../../../../components/common/buttons/Button";
import DropDownInput, {
  DropdownItem,
} from "../../../../components/common/formComponents/DropDownInput";
import Input from "../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../components/common/formComponents/RadioGroup";
import LoaderIcon from "../../../../components/common/loadStates/LoaderIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { WHITE } from "../../../../helpers/common/colorAliases";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import validateLookingGlass from "../../../../helpers/validators/LookingGlassValidator";
import { useValidation } from "../../../../helpers/validators/Validator";
import { CMD_LIST, PROTOCOL_LIST } from "../const";
import { useLookingGlassContext } from "../LookingGlassContext";
import { LookingGlassCommands, LookingGlassFields } from "../types";
import styles from "./ControlPanel.module.scss";
import { useUserContext } from "../../../../contexts/UserContext";
import { Checkbox } from "../../../../components/common/formComponents/Checkbox";

const getDefaultFields = (location: string): LookingGlassFields => ({
  ip: "",
  location,
  isDia: false,
  cmd: LookingGlassCommands.PING,
  protocol: PROTOCOL_LIST[0],
  max_mtu: 0,
  min_mtu: 0,
});

const ControlPanel: FC = () => {
  const {
    runCommand,
    commandExecStatus,
    userSystems,
    selectedSystem,
    setSelectedSystem,
    diaExist,
  } = useLookingGlassContext();

  const formIsDisabled = commandExecStatus.state === "pending";

  const DEFAULT_FIELDS = useMemo(
    () => getDefaultFields(selectedSystem?.location ?? ""),
    [selectedSystem]
  );

  const [fields, setField] = useFormField<LookingGlassFields>(DEFAULT_FIELDS);

  const [errors, validate] = useValidation<Partial<LookingGlassFields>>(
    validateLookingGlass,
    [fields]
  );

  const handleCommandExecution = () => {
    const { isOk } = validate();
    isOk && runCommand(fields);
  };

  const handleLocationUpdate = (system: DropdownItem) => {
    setField("location", system.key);
    setSelectedSystem({ location: system.value, system: system.key });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>Looking Glass</div>
        <div
          className={classNames(styles.form, formIsDisabled && styles.disabled)}
        >
          <DropDownInput
            id={"controlPanelSourceLocation"}
            selected={{
              key: selectedSystem?.system || "",
              value: selectedSystem?.location,
            }}
            itemsList={userSystems.map((s) => ({
              key: s.system || "",
              value: s.location,
            }))}
            onChange={handleLocationUpdate}
            label="Source Location"
            isMedium
            disabled={userSystems.length === 0}
            error={errors?.location}
          />
          {diaExist && (
            <Checkbox
              isChecked={fields.isDia}
              onChange={() => setField("isDia", !fields.isDia)}
              label="Use DIA"
            />
          )}
          <RadioGroup
            value={fields.cmd}
            setActiveValue={(cmd) => setField("cmd", cmd)}
            options={CMD_LIST}
            isNotEditable={formIsDisabled}
          />
          <Input
            label="Target IP"
            medium
            value={fields.ip}
            placeholder="1.1.1.1"
            onChange={({ target }) => setField("ip", target.value)}
            disabled={formIsDisabled}
            error={errors?.ip}
          />
          {fields.cmd === LookingGlassCommands.TRACERT && (
            <DropDownInput
              id={"controlPanelProtocol"}
              selected={{
                key: fields.protocol,
                value: fields.protocol,
              }}
              itemsList={PROTOCOL_LIST.map((p) => ({
                key: p,
                value: p,
              }))}
              onChange={(value) => setField("protocol", value.key)}
              label="Protocol"
              isMedium
            />
          )}
          {fields.cmd === LookingGlassCommands.MTU && (
            <>
              <Input
                label="MTU max"
                name="max_mtu"
                placeholder=""
                value={fields.max_mtu}
                medium
                type="number"
                min={64}
                max={20000}
                onChange={(e) => setField("max_mtu", e.target.value)}
                error={errors?.max_mtu}
              />
              <Input
                label="MTU min"
                name="min_mtu"
                placeholder=""
                value={fields.min_mtu}
                medium
                type="number"
                min={64}
                max={20000}
                onChange={(e) => setField("min_mtu", e.target.value)}
                error={errors?.min_mtu}
              />
            </>
          )}
        </div>
      </div>
      <RunButton onClick={handleCommandExecution} />
    </div>
  );
};

type Props = {
  onClick: () => void;
};
const RunButton: FC<Props> = ({ onClick }) => {
  const { isDemo, isZayo } = useUserContext();

  const { commandExecStatus, userSystems } = useLookingGlassContext();

  const { state: execStatus } = commandExecStatus;

  const getButtonContent = () => {
    switch (execStatus) {
      case "pending":
        return (
          <LoaderIcon className={styles.loaderBtn} height={18} color={WHITE} />
        );
      case "error":
        return "Run again";
      default:
        return "Run";
    }
  };

  return (
    <Button
      className={styles.runBtn}
      isPrimaryBtn
      onClick={onClick}
      disabled={
        execStatus === "pending" ||
        userSystems.length === 0 ||
        (isDemo && isZayo)
      }
    >
      {getButtonContent()}
    </Button>
  );
};

export default ControlPanel;
