import React, { FC, useEffect } from "react";
import DDoSInfo from "./DDoSInfo";
import styles from "./DDoSConfig.module.scss";
import GeneralSection from "./GeneralSection";
import { DialogType } from "../../../helpers/types";
import { DDoSType, FWFilters, VRFType } from "../../../helpers/api/apiTypes";
import {
  ADD,
  CE,
  ECT_0,
  ECT_1,
  EDIT,
  NECT,
  PROTOCOL_ANY,
} from "../../../helpers/common/constantsAlias";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import sendRequest from "../../../helpers/sendRequest";
import DialogBtmButtons from "../../../components/dialogs/common/DialogBtmButtons";
import { isEditMode } from "../../../helpers/isEditMode";
import {
  getPotocolName,
  getProtocolByValue,
} from "../../../helpers/getProtocolByValue";
import RuleSection from "./RuleSection";
import ActionSection from "./ActionSection";
import validateDDoS from "../../../helpers/validators/DDoSValidator";
import { MAX_FW_PRIORITY_FOR_MANUAL_CREATION } from "../../PBRPage/PBRConfig/PBRConfig";
import { generatePriority } from "../../Firewall/config/FirewallRuleConfig";
import { CollapsableTwoColumnLayout } from "../../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { System } from "../../Systems/Provisioning/types";
import {
  PBM_UNIT as DDOS_UNIT,
  TCP_STATES,
} from "../../PBMPage/PBMConfig/PBMConfig";
import { useDDoSContext } from "../../../contexts/servicesContext/DDoSContext";

type Props = {
  onClose: () => void;
  type: DialogType;
  data?: DDoSType;
  selectedVRF?: VRFType;
  system?: System;
};

export {
  PBM_UNIT as DDOS_UNIT,
  getUnitByTitle,
  getUnitTitle,
  TCP_STATES,
} from "../../PBMPage/PBMConfig/PBMConfig";

export type CreateDDoS = {
  name: string;
  enable: boolean;
  priority: number;
  description: string;
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  dst_group: string;
  src_group: string;
  fin: string;
  ack: string;
  urg: string;
  syn: string;
  rst: string;
  psh: string;
  is_fragmented: boolean;
  unit: string;
  max_rate: number;
  hop_limit?: Array<number>;
  ttl?: Array<number>;
  esn?: string;
  dscp?: number;
  ingress_vi?: string;
  flood_detect_rate: number;
  flood_cooldown_rate: number;
  hysteresis_seconds: number;
  flood_rate: number;
};

const DEFAULT_FIELDS: CreateDDoS = {
  name: "",
  enable: true,
  priority: 0,
  description: "",
  ip_protocol: PROTOCOL_ANY,
  src_network: "",
  src_segment: "",
  dst_segment: "",
  src_l4_port: "",
  dst_network: "",
  dst_l4_port: "",
  src_group: "",
  dst_group: "",
  fin: TCP_STATES.ANY,
  ack: TCP_STATES.ANY,
  urg: TCP_STATES.ANY,
  syn: TCP_STATES.ANY,
  rst: TCP_STATES.ANY,
  psh: TCP_STATES.ANY,
  is_fragmented: false,
  unit: DDOS_UNIT.PACKETS_PER_SECOND,
  max_rate: 0,
  flood_detect_rate: 1,
  flood_cooldown_rate: 0,
  hysteresis_seconds: 30,
  flood_rate: 0,
};

const DDoSConfig: FC<Props> = ({
  onClose,
  type,
  data,
  selectedVRF,
  system,
}) => {
  const {
    add,
    addStatus,
    edit,
    editStatus,
    fetchList,
    ddosList,
  } = useDDoSContext();
  const { userGroups } = useFirewallContext();

  const [fields, handleFieldChange] = useFormField<CreateDDoS>(
    getFlatData(data) || DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<CreateDDoS>(validateDDoS, [fields]);

  useEffect(() => {
    selectedVRF && fetchList({ vrf: selectedVRF?.name, system: system?.name });
  }, [selectedVRF]);

  useEffect(() => {
    if (ddosList) {
      const priority = data ? data.priority : generatePriority(ddosList);
      handleFieldChange("priority", priority);
    }
  }, [ddosList]);

  const handleAdd = async () => {
    const { isOk } = validate();

    if (!isOk) return;

    const newFields = getPreparedData(fields);
    await sendRequest(
      isOk,
      add(newFields, { vrf: selectedVRF?.name, system: system?.name }),
      onClose
    );
  };

  const handleEdit = async () => {
    if (!data) return;
    const { isOk } = validate();
    if (!isOk) return;

    const newFields = getPreparedData(fields);

    await sendRequest(
      isOk,
      edit(newFields, data.name, {
        vrf: selectedVRF?.name,
        system: system?.name,
      }),
      onClose
    );
    await fetchList({ vrf: selectedVRF?.name, system: system?.name });
  };

  const gridTemplate = "1fr 0.5fr 0.5fr";

  return (
    <CollapsableTwoColumnLayout
      InfoBlock={() => <DDoSInfo tenant={selectedVRF?.name} />}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <GeneralSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            maxPriority={MAX_FW_PRIORITY_FOR_MANUAL_CREATION}
            gridTemplate={gridTemplate}
          />
          <RuleSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            groups={userGroups}
            gridTemplate={gridTemplate}
            selectedVRF={selectedVRF}
            system={system}
          />
          <ActionSection
            className={styles.contentWrapper}
            fields={fields}
            onChange={handleFieldChange}
            gridTemplate={gridTemplate}
            errors={errors}
          />
        </div>
        <div className={styles.footer}>
          <DialogBtmButtons
            controls={{
              okText: isEditMode(type) ? EDIT : ADD,
              onOk: isEditMode(type) ? handleEdit : handleAdd,
              cancelText: "Cancel",
              onCancel: onClose,
            }}
            errorDisplay={addStatus || editStatus}
          />
        </div>
      </div>
    </CollapsableTwoColumnLayout>
  );
};

export default DDoSConfig;

const ECN_VALUES: { [key: string]: string } = {
  [ECT_0]: "10",
  [ECT_1]: "01",
  [CE]: "11",
  [NECT]: "00",
};

function prepareTCPFlags(flags: Array<string>): string {
  let value = "";
  let mask = "";
  flags.map((flag) => {
    const flagBit = flag === TCP_STATES.SET ? "1" : "0";
    const flagMask = flag === TCP_STATES.ANY || !flag ? "0" : "1";
    value += flagBit;
    mask += flagMask;
  });
  return `${parseInt(value, 2)}/${parseInt(mask, 2)}`;
}

const getPreparedData = (rule: CreateDDoS): Partial<DDoSType> => {
  const {
    name,
    priority,
    description,
    ip_protocol,
    unit,
    max_rate,
    urg,
    ack,
    psh,
    rst,
    syn,
    fin,
    src_l4_port,
    dst_l4_port,
    flood_cooldown_rate,
    flood_detect_rate,
    hysteresis_seconds,
    flood_rate,
  } = rule;
  const filters_ip_protocol = getProtocolByValue(
    ip_protocol,
    !!src_l4_port || !!dst_l4_port
  );

  const otherFilters: any = [];
  [
    "src_l4_port",
    "src_network",
    "src_segment",
    "dst_segment",
    "dst_l4_port",
    "dst_network",
    "dst_group",
    "src_group",
    "is_fragmented",
    "hop_limit",
    "ttl",
    "ingress_vi",
  ].map((val) => {
    if (rule[val as keyof CreateDDoS]) {
      let values = rule[val as keyof CreateDDoS] || 0;
      if (["ttl", "hop_limit"].includes(val)) {
        const formatted = values as Array<number>;
        values = `${formatted[0]}/${formatted[1]}`;
      }
      if (values) otherFilters.push({ filter_type: val, values });
    }
  });

  if (rule.dscp || rule.esn) {
    const esn = rule?.esn || NECT;
    const values = (Number(rule.dscp) || 0).toString(2) + ECN_VALUES?.[esn];
    otherFilters.push({ filter_type: "tos", values });
  }

  const tcpFlags = prepareTCPFlags([urg, ack, psh, rst, syn, fin]);
  otherFilters.push({ filter_type: "tcp_control", values: tcpFlags });

  const [realUnit, realMaxRate] = parseUnit(unit, max_rate);

  return {
    name,
    priority,
    filters: [...filters_ip_protocol, ...otherFilters],
    description,
    unit: realUnit as string,
    max_rate: realMaxRate as number,
    flood_cooldown_rate,
    flood_detect_rate,
    hysteresis_seconds,
    flood_rate,
  };
};

type DDoSFilters = {
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  src_group: string;
  dst_group: string;
  fin: string;
  ack: string;
  urg: string;
  syn: string;
  ece: string;
  rst: string;
  cwr: string;
  psh: string;
  is_fragmented: boolean;
  hop_limit?: Array<number>;
  ttl?: Array<number>;
  tos: string;
};

export function parseTOS(tos: string): Array<string | number> {
  const esn = tos.slice(tos.length - 2);
  let esnString = "";
  Object.keys(ECN_VALUES).map((val) => {
    if (ECN_VALUES[val] === esn) esnString = val;
  });
  const dscp = parseInt(tos.slice(0, -2), 2);
  return [dscp, esnString];
}

export function parseTTL(ttl: string): Array<number> {
  const ttlRange = ttl.split("/");
  return [parseInt(ttlRange[0]), parseInt(ttlRange[1])];
}

export function parseTCPFlags(flags: string): Array<string> {
  const parsed: Array<string> = [];
  const parsedArr = flags.split("/").map((flag) => {
    return parseInt(flag).toString(2).padStart(6, "0");
  });
  const values = parsedArr[0].split("");
  const masks = parsedArr[1].split("");
  values.map((value, i) => {
    let parsedVal = TCP_STATES.ANY;
    if (value === "1" && masks[i] === "1") {
      parsedVal = TCP_STATES.SET;
    } else if (value === "0" && masks[i] === "1") {
      parsedVal = TCP_STATES.UNSET;
    }
    parsed.push(parsedVal);
  });
  return parsed;
}

export function parseUnit(
  unit: string,
  max_rate: number
): Array<string | number> {
  if (
    ![DDOS_UNIT.MBITS_PER_SECOND, DDOS_UNIT.GBITS_PER_SECOND].includes(unit)
  ) {
    return [unit, max_rate];
  }
  return [
    DDOS_UNIT.KBITS_PER_SECOND,
    unit === DDOS_UNIT.MBITS_PER_SECOND
      ? max_rate * 1000
      : max_rate * 1000 ** 2,
  ];
}

export function prepareUnit(
  unit: string,
  max_rate: number
): Array<string | number> {
  if (unit !== DDOS_UNIT.KBITS_PER_SECOND) {
    return [unit, max_rate];
  }
  if (!(max_rate % 1000 ** 2)) {
    return [DDOS_UNIT.GBITS_PER_SECOND, max_rate / 1000 ** 2];
  }
  return [DDOS_UNIT.MBITS_PER_SECOND, max_rate / 1000];
}

export const tcpCheckboxes = ["urg", "ack", "psh", "rst", "syn", "fin"];

const getFlatData = (data: DDoSType | undefined): CreateDDoS | undefined => {
  if (!data) return;
  const filters: { [key: string]: any } = {};
  data.filters.map((el: FWFilters) => {
    if (el.filter_type !== "ip_protocol") {
      if (el.filter_type === "ttl") {
        filters[el.filter_type] = parseTTL(el.values as string);
      } else {
        filters[el.filter_type] = Number.isNaN(+el.values)
          ? el.values
          : +el.values;
      }
    }
  });
  const protocols = data.filters.filter(
    (el) => el.filter_type === "ip_protocol"
  );

  let dscp, esn;
  const tos = data.filters.filter((el) => el.filter_type === "tos");
  if (tos.length > 0) {
    [dscp, esn] = parseTOS(tos[0].values as string);
  }

  let urg, ack, psh, rst, syn, fin;
  const tcp_control = data.filters.filter(
    (el) => el.filter_type === "tcp_control"
  );
  if (tcp_control.length > 0) {
    [urg, ack, psh, rst, syn, fin] = parseTCPFlags(
      tcp_control[0].values as string
    );
  }

  const [unit, max_rate] = prepareUnit(data?.unit, data?.max_rate);

  return {
    name: data?.name,
    enable: data?.enable,
    priority: data?.priority,
    description: data?.description,
    ...(filters as DDoSFilters),
    ip_protocol: getPotocolName(protocols),
    unit: unit as string,
    max_rate: max_rate as number,
    dscp: dscp as number,
    esn: esn as string,
    urg: urg || TCP_STATES.ANY,
    ack: ack || TCP_STATES.ANY,
    psh: psh || TCP_STATES.ANY,
    rst: rst || TCP_STATES.ANY,
    syn: syn || TCP_STATES.ANY,
    fin: fin || TCP_STATES.ANY,
    flood_detect_rate: data?.flood_detect_rate,
    flood_cooldown_rate: data?.flood_cooldown_rate,
    hysteresis_seconds: data?.hysteresis_seconds,
    flood_rate: data?.flood_rate,
  };
};
