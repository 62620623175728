import { UpdateUserGroup, User } from "../types";
import Api from "./Api";
import { NIRO, TENANT, USER_BROKER, USER_GROUPS_PATH } from "./apiPaths";
import { UserDTO } from "./apiTypes";

type Res<P> = { ok: boolean; error?: string; result?: P };

export class UserApi extends Api {
  constructor() {
    super();
  }

  async getUser(username: string): Promise<Res<UserDTO>> {
    const query = { username };

    return await this.fetchBroker<UserDTO>(USER_BROKER.GET_USER(), {
      query,
    });
  }

  async getUserTEXTS(): Promise<Res<any>> {
    return await this.fetchBroker<UserDTO>(USER_BROKER.GET_INFO_TEXTS());
  }

  async getTenantUsers(tenant: string): Promise<Res<Array<User>>> {
    return await this.fetchBroker<Array<User>>(
      USER_BROKER.GET_TENANT_USERS(tenant)
    );
  }

  async getTenantUser(tenant: string, user: string): Promise<Res<User>> {
    return await this.fetchBroker<User>(
      USER_BROKER.GET_TENANT_USER(tenant, user)
    );
  }

  async getGlobalUsers(): Promise<Res<Array<User>>> {
    return await this.fetchBroker<Array<User>>(USER_BROKER.GET_USER());
  }

  async updateUser(tenant: string, user: any): Promise<Res<User>> {
    return await this.fetchBroker<User>(TENANT.UPDATE_USER(tenant), {
      body: JSON.stringify(user),
    });
  }

  async createUser(tenant: string, user: any): Promise<Res<User>> {
    return await this.fetchBroker<User>(TENANT.CREATE_USER(tenant), {
      body: JSON.stringify(user),
    });
  }

  async deleteUser(tenant: string, username: string): Promise<Res<string>> {
    const query = { username };
    return await this.fetchBroker<string>(TENANT.DELETE_USER(tenant), {
      query,
    });
  }

  async addUser(user: User): Promise<Res<User>> {
    return await this.fetchBroker<User>(USER_BROKER.ADD_USER(), {
      body: JSON.stringify(user),
    });
  }

  async addUserToTenant(user: User, tenant: string): Promise<Res<User>> {
    return await this.fetchBroker<User>(
      USER_BROKER.ADD_USER_TO_TENANT(tenant),
      {
        body: JSON.stringify(user),
      }
    );
  }

  async getCurrentUser(): Promise<Res<any>> {
    return await this.fetchBroker<any>(USER_BROKER.GET_CURRENT_USER());
  }
  async editCurrentUser(fields: any): Promise<Res<any>> {
    return await this.fetchBroker<any>(USER_BROKER.EDIT_CURRENT_USER(), {
      body: JSON.stringify(fields),
    });
  }
  async editPasswordCurrentUser(password: string): Promise<Res<any>> {
    return await this.fetchBroker<any>(
      USER_BROKER.EDIT_PASSWORD_CURRENT_USER(password),
      {},
      undefined,
      true
    );
  }

  async getNiroBranding(): Promise<Res<{ name: string }>> {
    return await this.fetchBroker<{ name: string }>(NIRO.GET_BRANDING());
  }
  async getUsersGroup(tenant: string): Promise<Res<any>> {
    return await this.fetchBroker<any>(TENANT.GET_USERS_GROUPS(tenant));
  }
  async getUsersGroupsList(tenant: string): Promise<Res<any>> {
    return await this.fetchBroker<any>(USER_GROUPS_PATH.LIST(tenant));
  }

  async getOneUsersGroup(tenant: string, groupName: string): Promise<Res<any>> {
    return await this.fetchBroker<any>(
      USER_GROUPS_PATH.GET_GROUP(tenant, groupName)
    );
  }

  async createUsersGroup(
    tenant: string,
    fields: UpdateUserGroup
  ): Promise<Res<any>> {
    return await this.fetchBroker<any>(USER_GROUPS_PATH.CREATE(tenant), {
      body: JSON.stringify(fields),
    });
  }

  async updateUsersGroup(
    tenant: string,
    groupName: string,
    fields: UpdateUserGroup
  ): Promise<Res<any>> {
    return await this.fetchBroker<any>(
      USER_GROUPS_PATH.UPDATE_GROUP(tenant, groupName),
      {
        body: JSON.stringify(fields),
      }
    );
  }

  async deleteUsersGroup(tenant: string, groupName: string): Promise<Res<any>> {
    return await this.fetchBroker<any>(
      USER_GROUPS_PATH.DELETE_GROUP(tenant, groupName)
    );
  }

  async getGlobalUsersGroupsList(): Promise<Res<any>> {
    return await this.fetchBroker<any>(USER_GROUPS_PATH.GLOBAL_LIST());
  }

  async getSSOProviders(): Promise<Res<any>> {
    return await this.fetchBroker<any>(USER_BROKER.GET_SSO_PROVIDERS());
  }

  async getTokenSSO(state: string, code: string): Promise<Res<any>> {
    const fields = {
      state: state,
      code: code,
      tenant_name: "global",
    };
    return await this.fetchBroker<any>(
      USER_BROKER.GET_TOKEN_SSO(),
      {
        body: JSON.stringify(fields),
      },
      undefined,
      true
    );
  }

  async deleteGlobalUser(username: string): Promise<Res<UserDTO>> {
    const query = { username };
    return await this.fetchBroker<UserDTO>(USER_BROKER.DELETE_USER(), {
      query,
    });
  }

  async updateGlobalUser(user: any): Promise<Res<User>> {
    return await this.fetchBroker<User>(USER_BROKER.UPDATE_USER(), {
      body: JSON.stringify(user),
    });
  }
}

export const userApi = new UserApi();
