import {
  BRIDGED,
  ROUTED,
  VIRTUAL_INTERFACE_GATE,
} from "../../../helpers/consts";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";

export const checkIfInternet = (item: VirtualInterfaceApi) => {
  return item?.type === VIRTUAL_INTERFACE_GATE && item?.is_dia === false;
};

export const getDirectAndCloudConnections = (
  connections: Array<VirtualInterfaceApi>
) => {
  const direct = connections.filter(
    (item) =>
      item?.virtual_interface_type === BRIDGED ||
      (item?.type === VIRTUAL_INTERFACE_GATE &&
        item?.is_dia === true &&
        item.members.some((member) => member?.interfaces?.length > 0))
  );
  const cloud = connections.filter(
    (item) => item?.virtual_interface_type === ROUTED || checkIfInternet(item)
  );

  return { direct, cloud };
};
