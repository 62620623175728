import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../components/common/formComponents/Input";
import TextWithIcon from "../../components/common/table/TextWithIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import { withDialogLayout } from "../../helpers/hocs/withDialogLayout";
import ISOIcon from "../../components/icons/ISOIcon";
import { classNames } from "../../helpers/common/classNames";
import LoaderIcon from "../../components/common/loadStates/LoaderIcon";
import DownloadButton from "../../components/common/buttons/DownloadButton";
import DeleteButton from "../../components/common/buttons/DeleteButton";
import { formatVersion } from "./helpers";
import { useNSOSSoftware } from "./hooks";
import ReleaseButton from "../../components/common/buttons/ReleaseButton";
import Button from "../../components/common/buttons/Button";
import { DownloadStatus } from "./DownloadStatus";
import { TaskStatus } from "../../helpers/hooks/useCheckTask";

import styles from "./NSOSSoftware.module.scss";
import CheckMarkIcon from "../../components/icons/CheckMarkIcon";

const NSOSSoftware = () => {
  const {
    versionsState,
    taskStatus,
    newVersion,
    checkVersionState,
    downloadInProgress,
    downloadByUrlState,
    deleteVersionState,
    getVersions,
    deleteVersion,
    downloadByUrl,
    checkVersion,
  } = useNSOSSoftware();

  const history = useHistory();

  const [url, setUrl] = useState<string>("");

  const exitPage = () => {
    history.goBack();
  };

  const handleDownloadVersion = (version: string) => {
    const link = document.createElement("a");
    link.href = `/broker/iso/${formatVersion(version)}`;
    link.download = formatVersion(version);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteVersion = async (version: string) => {
    await deleteVersion(version);
    await getVersions();
  };

  const handleDownloadByUrl = async (url: string) => {
    await downloadByUrl(url);
  };

  return (
    <div className={styles.dialog}>
      <CloseIcon
        height={18}
        width={16}
        className={styles.dialogCloseBtn}
        color="currentColor"
        onClick={exitPage}
      />
      <TextWithIcon
        className={styles.dialogHeader}
        icon={<ISOIcon color="var(--essential-package-color)" />}
        text={"NSOS Software"}
      />

      <div className={classNames(styles.row, styles.rowNsosImages)}>
        <label
          htmlFor="nsosImages"
          className={classNames(styles.rowLabel, styles.rowNsosImages)}
        >
          Available NSOS images
        </label>

        {versionsState.isLoading || deleteVersionState.isLoading ? (
          <div className={styles.loaderWrapper}>
            <LoaderIcon />
          </div>
        ) : (
          <div className={styles.rowVersions}>
            {versionsState.result?.map((version: string) => (
              <div key={version} className={styles.version}>
                {formatVersion(version)}
                <div className={styles.versionActions}>
                  <DownloadButton
                    onClick={() => {
                      handleDownloadVersion(version);
                    }}
                    id="download"
                  />
                  <DeleteButton
                    onClick={() => {
                      handleDeleteVersion(version);
                    }}
                    id="delete"
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      <div className={styles.row}>
        <div className={styles.rowLabel}>Check for new version</div>
        {!newVersion ? (
          <ReleaseButton
            onClick={checkVersion}
            id="check"
            isLoading={checkVersionState.isLoading}
            disabled={checkVersionState.isLoading}
          />
        ) : (
          <div className={styles.newVersion}>
            <div className={styles.newVersionContent}>
              <span className={styles.newVersionText}>
                <CheckMarkIcon /> New version available:
              </span>
              <span className={styles.newVersionLabel}>
                {formatVersion(newVersion)}
              </span>
            </div>
            <Button
              isPrimaryBtn
              className={styles.downloadBtn}
              onClick={() =>
                handleDownloadByUrl(checkVersionState.result.latest.link)
              }
              disabled={downloadInProgress || downloadByUrlState.isLoading}
              isLoading={downloadInProgress || downloadByUrlState.isLoading}
            >
              Download
            </Button>
          </div>
        )}
      </div>

      <div className={styles.row}>
        <label htmlFor="url" className={styles.rowLabel}>
          Download from link
        </label>
        <div className={styles.downloadWrapper}>
          <Input
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            name="url"
            placeholder="https://"
            className={styles.rowInput}
          />
          <Button
            isPrimaryBtn
            className={styles.downloadBtn}
            onClick={() => handleDownloadByUrl(url)}
            disabled={
              !url || downloadInProgress || downloadByUrlState.isLoading
            }
            isLoading={downloadInProgress || downloadByUrlState.isLoading}
          >
            Download
          </Button>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.rowLabel}>Download status</div>
        <DownloadStatus status={taskStatus as TaskStatus} />
      </div>
    </div>
  );
};

export default withDialogLayout(NSOSSoftware, styles.dialogFrame);
