import { FC } from "react";

type Props = { title: string; label?: string };

const SectionTitle: FC<Props> = ({ title, label }) => {
  const labelWidth = (label || "-").length * 8;
  return (
    <g>
      <text
        x={0}
        y={0}
        fill="var(--accent-color)"
        fontSize="0.875rem"
        textAnchor="start"
        dominantBaseline="hanging"
        fontWeight={600}
        opacity={0.6}
      >
        {title}
      </text>
      <rect
        x={0}
        y={20}
        height={"18"}
        width={labelWidth + 8}
        fill={"rgba(107, 85, 240, 0.10)"}
        rx="4"
      />
      <text
        x={3}
        y={20 + 24 / 2}
        fill="var(--grey60)"
        fontSize="0.625rem"
        textAnchor="start"
        fontWeight={600}
      >
        {label?.toUpperCase() || "-"}
      </text>
    </g>
  );
};

export default SectionTitle;
