export const AVAILABLE_FIELDS_APPLICATION = [
  { label: "Locations", value: "locations" },
  { label: "Source IPs", value: "srcIPs" },
  { label: "Destination IP", value: "dstIP" },
  { label: "Source Connections", value: "srcConnections" },
  { label: "Hostnames", value: "hostnames" },
  { label: "AS Name", value: "Destination AS Name" },
  { label: "Firewall Rule Name", value: "Firewall Rule Name" },
  { label: "FQDN Filter Action", value: "FQDN_action" },
  { label: "Is DIA", value: "is_dia" },
];

export enum APPLICATION_FILTER_FIELD {
  LOCATION = "locations",
  USER = "users",
  SRC_CONNECTIONS = "srcConnections",
  SRC_IP = "srcIPs",
  DST_IP = "dstIP",
  HOSTNAMES = "hostnames",
  FQDN_ACTION = "FQDN_action",
  AS_NAME = "Destination AS Name",
  FIREWALL_RULE_NAME = "Firewall Rule Name",
  IS_DIA = "is_dia",
}

export const APPLICATION_FIELDS_MAP: Array<string> = [
  APPLICATION_FILTER_FIELD.LOCATION,
  APPLICATION_FILTER_FIELD.FQDN_ACTION,
  APPLICATION_FILTER_FIELD.SRC_CONNECTIONS,
  APPLICATION_FILTER_FIELD.AS_NAME,
  APPLICATION_FILTER_FIELD.SRC_IP,
  APPLICATION_FILTER_FIELD.DST_IP,
  APPLICATION_FILTER_FIELD.HOSTNAMES,
  APPLICATION_FILTER_FIELD.FIREWALL_RULE_NAME,
  APPLICATION_FILTER_FIELD.IS_DIA,
];

export const APPLICATION_FIELD_VALUE: { [key: string]: string } = {
  [APPLICATION_FILTER_FIELD.LOCATION]: "Location",
  [APPLICATION_FILTER_FIELD.FIREWALL_RULE_NAME]: "Firewall Rule Name",
  [APPLICATION_FILTER_FIELD.SRC_CONNECTIONS]: "Incoming Connection",
  [APPLICATION_FILTER_FIELD.AS_NAME]: "Destination AS Name",
  [APPLICATION_FILTER_FIELD.FQDN_ACTION]: "FQDN_action",
  [APPLICATION_FILTER_FIELD.SRC_IP]: "Source IP",
  [APPLICATION_FILTER_FIELD.DST_IP]: "Destination IP",
  [APPLICATION_FILTER_FIELD.HOSTNAMES]: "Hostname",
  [APPLICATION_FILTER_FIELD.IS_DIA]: "is_dia",
};

export const APPLICATION_FIELD_LABEL: { [key: string]: string } = {
  [APPLICATION_FILTER_FIELD.LOCATION]: "Location",
  [APPLICATION_FILTER_FIELD.FIREWALL_RULE_NAME]: "Firewall Rule Name",
  [APPLICATION_FILTER_FIELD.AS_NAME]: "Destination AS Name",
  [APPLICATION_FILTER_FIELD.FQDN_ACTION]: "FQDN Filter Action",
  [APPLICATION_FILTER_FIELD.SRC_CONNECTIONS]: "Source Connection",
  [APPLICATION_FILTER_FIELD.SRC_IP]: "Source IPs",
  [APPLICATION_FILTER_FIELD.DST_IP]: "Destination IPs",
  [APPLICATION_FILTER_FIELD.HOSTNAMES]: "Hostnames",
  [APPLICATION_FILTER_FIELD.IS_DIA]: "is_dia",
};

export const LOCALSTORAGE_APPLICATION_KEY = "LOCALSTORAGE_APPLICATION_KEY";
