import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../../../helpers/consts";

export const PROVIDERS = [
  {
    name: AWS_LOWERCASE,
    enabled: true,
  },
  {
    name: AZURE_LOWERCASE,
    enabled: true,
  },
  {
    name: GCP_LOWERCASE,
    enabled: true,
  },
];
