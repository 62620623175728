export const PRICING_PRODUCTS = {
  CUSTOMER_PORT: "customer_port",
  DIA: "dia",
  DIA_CONNECTION: "dia_connection",
  DIRECT_CLOUD_LINK: "direct_cloud_link",
  CR_CLOUD_LINK: "cr_cloud_link",
  CLOUD_ROUTER: "cloud_router",
  TENANT_SERVICE_PACKAGE: "tenant_service_package",
  DIRECT_CONNECTION: "direct_connection",
  DIRECT_ELAN_CONNECTION: "direct_elan_connection",
} as const;
