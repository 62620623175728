import React, { FC, useEffect, useState } from "react";
import Input from "../../../components/common/formComponents/Input";
import Section from "../../../components/common/Section";
import { CreatePBR } from "./PBRConfig";
import styles from "./RuleSection.module.scss";
import { UserGroups } from "../../../contexts/servicesContext/FirewallContext";
import SourceBlock from "./SourceBlock";
import DestinationBlock from "./DestinationBlock";
import { PROTOCOLS_TYPES_MAP } from "../../../helpers/common/constantsAlias";
import RadioGroup from "../../../components/common/formComponents/RadioGroup";
import { OneSystemType, VRFType } from "../../../helpers/api/apiTypes";
import VRFDropdown from "../../../components/common/OneSystem/VRFDropdown";
import { System } from "../../Systems/Provisioning/types";
import VIDropdown from "../../../components/common/OneSystem/VIDropdown";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";

export const IP_ACTIONS = [
  {
    value: "ipv4",
    label: "IPv4",
  },
  {
    value: "ipv6",
    label: "IPv6",
  },
];

export type IPPoliciesType = "IPv4" | "IPv6";

type Props = {
  fields: CreatePBR;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
  groups: UserGroups | undefined;
  system?: System;
};

const RuleSection: FC<Props> = ({
  fields,
  errors,
  onChange,
  className,
  groups,
  system,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const [policies, setPolicies] = useState<string>(fields.ip_type);
  const [activeVRF, setActiveVRF] = useState<VRFType | any | undefined>({
    value: fields?.vrf_name,
    key: fields?.vrf_name,
    name: system ? fields?.vrf_name : selectedTenant,
  });
  const [activeVI, setActiveVI] = useState<Partial<VirtualInterfaceApi>>({
    name: fields?.ingress_vi || "",
  });

  useEffect(() => {
    if (!system && selectedTenant) {
      onChange("vrf_name", selectedTenant);
      onChange("gateway_vrf_name", selectedTenant);
    }
  }, [selectedTenant]);

  const handleChangePolicies = (val: string): void => {
    setPolicies(val as IPPoliciesType);
    onChange("ip_type", val);
  };

  const handleSetVRF = (val?: VRFType): void => {
    if (!val) return;
    onChange("vrf_name", val.name);
    setActiveVRF(val);
  };

  const handleSetVI = (val?: Partial<VirtualInterfaceApi>): void => {
    if (!val) return;
    onChange("ingress_vi", val.name);
    setActiveVI({ name: val.name, id: val.id });
  };

  return (
    <Section title={"Policy"} className={className} columnsCount={3}>
      <div className={styles.column}>
        <RadioGroup
          className={styles.radioWrapper}
          label={"IP protocol"}
          isOneRow
          options={IP_ACTIONS}
          value={policies}
          setActiveValue={handleChangePolicies}
          radioContainerClassName={styles.radioContainer}
        />
        <SourceBlock
          fields={fields}
          errors={errors}
          onChange={onChange}
          groups={groups}
        />
        <DestinationBlock
          fields={fields}
          errors={errors}
          onChange={onChange}
          groups={groups}
        />
        {system && (
          <VRFDropdown
            system={system as OneSystemType}
            selected={activeVRF}
            onSelect={(val) => handleSetVRF(val)}
            className={styles.portWrapper}
            label="Source VRF"
          />
        )}
      </div>
      <div className={styles.column}>
        <Input
          label="Protocol"
          className={styles.portWrapper}
          name={"ip_protocol"}
          handleFieldChange={onChange}
          value={fields.ip_protocol}
          placeholder={fields.ip_protocol || "Protocol"}
          error={errors && errors["ip_protocol"]}
          valuesList={PROTOCOLS_TYPES_MAP}
          medium
        />
        <Input
          label="Source Port"
          name="src_l4_port"
          className={styles.portWrapper}
          placeholder="xxx"
          handleFieldChange={onChange}
          value={fields.src_l4_port}
          medium
          error={errors && errors["src_l4_port"]}
          isOptional
        />
        <Input
          label="Destination Port"
          name="dst_l4_port"
          className={styles.portWrapper}
          placeholder="xxx"
          handleFieldChange={onChange}
          value={fields.dst_l4_port}
          medium
          error={errors && errors["dst_l4_port"]}
          isOptional
        />
        <VIDropdown
          system={system as OneSystemType}
          vrf={activeVRF}
          selected={activeVI}
          onSelect={(val) => handleSetVI(val)}
          className={styles.portWrapper}
          label="Source Interface"
          force={!system}
          withClearIcon
        />
      </div>
    </Section>
  );
};
export default RuleSection;
