import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../contexts/common/AbstractDialogContext";
import { IPortsContext, withPortsContextProps } from "../PortsContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../../contexts/systemsContext/OneSystemContext";
import { PortType } from "../types";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<PortType>;

type IState = State & RequestStatuses;
type IFunc = DialogFunc<PortType> & {
  editAlias: (name: string, port: Partial<PortType>) => Promise<boolean>;
  editDescription: (name: string, port: Partial<PortType>) => Promise<boolean>;
};

const [PortDialogContext, usePortDialogContext] = createContextUtils<
  IState,
  IFunc
>();
export { usePortDialogContext };

class PortDialogContextContainer extends AbstractDialogContextContainer<
  PortType,
  IState,
  RequestStatuses,
  IFunc,
  IPortsContext & IOneSystemContext
> {
  Context = PortDialogContext;

  editAlias = async (
    name: string,
    port: Partial<PortType>
  ): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() =>
      configApi.editAlias(system.name, name, { alias: port.name })
    );
    return ok;
  };

  editDescription = async (
    name: string,
    port: Partial<PortType>
  ): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await configApi.editDescription(
      system.name,
      name,
      port.labels?.description ?? ""
    );
    return ok;
  };

  // not exist
  edit = async (): Promise<boolean> => {
    return false;
  };

  // not exist
  add = async (): Promise<boolean> => {
    return false;
  };

  funcs = {
    editAlias: this.editAlias,
    editDescription: this.editDescription,
    edit: this.edit,
    add: this.add,
  };
}

export default withPortsContextProps(
  withOneSystemContextProps(PortDialogContextContainer)
);
