import { PathEntry } from "../types";

const addId = (value?: string | number) => (value ? `_${value}` : "");

export type EntryFunc = (id?: string) => PathEntry;

export const LOGIN: EntryFunc = () => ({
  content: "Login",
  path: "login",
});

// COMMON
export const REMOTE_USER_PAGE: EntryFunc = (username?: string) => ({
  content: "Remote user",
  path: "remoteUser",
});

export const REMOTE_USER: EntryFunc = (username?: string) => ({
  content: username ? "Remote user " + username : "Remote user",
  path: username ? "remoteUser_" + username : "remoteUser",
});
export const ADD: EntryFunc = () => ({
  content: "Add",
  path: "add",
});
export const EDIT: EntryFunc = (name?: string) => ({
  content: name ? "Edit " + name : "Edit",
  path: name ? "edit_" + name : "edit",
});
export const REPORTS: EntryFunc = () => ({
  content: "Reports",
  path: "reports",
});
export const CONFIGURATION: EntryFunc = () => ({
  content: "Configuration",
  path: "configuration",
});
export const EVENTS: EntryFunc = () => {
  return {
    content: "Events",
    path: "events",
  };
};
export const IPAM: EntryFunc = () => ({
  content: "IPAM",
  path: "ipam",
});
export const SITES: EntryFunc = () => ({
  content: "Sites",
  path: "sites",
});
export const STATIC_ROUTES: EntryFunc = () => {
  return {
    content: "Routes",
    path: "Routes",
  };
};
export const BGP: EntryFunc = () => {
  return {
    content: "BGP",
    path: "bgp",
  };
};
export const NEW_CONNECTION: EntryFunc = () => ({
  content: "Add New Connection",
  path: "newConnection",
});

// NETWORK RESOURCES / SYSTEMS
export const NETWORK_RESOURCES: EntryFunc = () => ({
  content: "Network Resources",
  path: "networkResources",
});
export const SYSTEMS: EntryFunc = () => ({
  content: "Systems",
  path: "systems",
});
export const CLOUD_RESOURCES: EntryFunc = () => ({
  content: "Cloud Resources",
  path: "cloudResources",
});
export const SYSTEM: EntryFunc = (name) => {
  return {
    content: name || "",
    path: "system" + addId(name),
  };
};
export const MESH_NETWORK: EntryFunc = () => ({
  content: "Mesh Network",
  path: "meshNetwork",
});

// SYSTEM SUB PAGES
export const PORTS: EntryFunc = () => {
  return { content: "Ports", path: "ports" };
};
export const PORT: EntryFunc = (id) => {
  return {
    content: id || "",
    path: "ports/port" + addId(id),
  };
};
export const LAG: EntryFunc = () => {
  return { content: "LAG", path: "lag" };
};
export const VRF: EntryFunc = () => {
  return { content: "VRF", path: "vrf" };
};
export const VTEP: EntryFunc = () => {
  return { content: "VTEP", path: "vtep" };
};
export const VIRT_INTERFACES: EntryFunc = () => {
  return { content: "Virtual Interfaces", path: "virtualInterfaces" };
};
export const PORT_INTERFACES: EntryFunc = () => {
  return {
    content: "Port Interfaces",
    path: "portInterfaces",
  };
};
export const VLAN: EntryFunc = () => {
  return {
    content: "VLAN",
    path: "vlan",
  };
};

export const HAHEALTH: EntryFunc = () => {
  return {
    content: "Health and HA",
    path: "hahealth",
  };
};
export const VNI: EntryFunc = () => {
  return {
    content: "VNI",
    path: "vni",
  };
};
export const TRAFFIC_STATISTIC: EntryFunc = () => {
  return {
    content: "Traffic Statistics",
    path: "traffic_statistics",
  };
};
export const VRF_STATISTIC: EntryFunc = () => {
  return {
    content: "VRF Statistics",
    path: "vrf_statistics",
  };
};
export const VROUTER: EntryFunc = () => ({
  content: "vRouter",
  path: "vRouter",
});
export const LOGICAL_INTERFACES: EntryFunc = () => {
  return {
    content: "Logical Interfaces",
    path: "logicalInterfaces",
  };
};

// TENANTS
export const TENANTS: EntryFunc = () => ({
  content: "Tenants",
  path: "tenants",
});
export const PROVISIONING: EntryFunc = () => ({
  content: "Provisioning",
  path: "provisioning",
});
export const PACKAGES: EntryFunc = () => {
  return {
    content: "Packages",
    path: "packages",
  };
};
export const SERVICE_CATALOG: EntryFunc = () => {
  return {
    content: "Service Catalog",
    path: "service_catalog",
  };
};
export const ORDERED_PORTS: EntryFunc = () => {
  return {
    content: "Ordered Ports",
    path: "ordered_ports",
  };
};
export const USERS: EntryFunc = () => ({
  content: "Users",
  path: "users",
});
export const REMOTE_USERS: EntryFunc = () => ({
  content: "Remote Users",
  path: "remoteUsers",
});
export const USER_GROUPS: EntryFunc = () => ({
  content: "Groups",
  path: "userGroups",
  // directParent: REMOTE_USERS(),
  // directParentPath: REMOTE_USERS().path,
});
export const REMOTE_USERS_USER_GROUPS: EntryFunc = () => ({
  content: "Groups",
  path: "userGroups",
  directParent: REMOTE_USERS(),
  directParentPath: REMOTE_USERS().path,
});
export const IP_GROUPS: EntryFunc = () => ({
  content: "IP Groups",
  path: "ipGroups",
});
export const REMOTE_USERS_STATUS: EntryFunc = () => ({
  content: "Remote User Status",
  path: "remoteStatus",
});
export const TENANT: EntryFunc = (tenant_name) => {
  return {
    content: tenant_name || "",
    path: "tenant" + addId(tenant_name),
  };
};
export const TENANT_L3: EntryFunc = () => {
  return {
    content: "Layer 3 Interfaces (VI)",
    path: "layer3interfaces",
  };
};
export const TENANT_L2: EntryFunc = () => {
  return {
    content: "Layer 2 Interfaces",
    path: "layer2interfaces",
  };
};
export const TENANT_USAGE: EntryFunc = () => {
  return {
    content: "Usage",
    path: "usage",
  };
};

export const DIA: EntryFunc = () => {
  return {
    content: "DIA service",
    path: "dia",
  };
};

// INSIGHTS
export const INSIGHTS: EntryFunc = () => ({
  content: "Insights",
  path: "insights",
});

export const INSIGHTS_SDWAN: EntryFunc = () => {
  return {
    content: "SDWAN Insights",
    path: "sdwan",
  };
};

export const INSIGHTS_OVERVIEW: EntryFunc = () => {
  return {
    content: "Overview",
    path: "sdwan",
  };
};

export const INSIGHTS_TRENDS: EntryFunc = () => {
  return {
    content: "Trends",
    path: "sdwan_trends",
  };
};

export const TOPOLOGY: EntryFunc = () => {
  return {
    content: "Topology",
    path: "topology",
  };
};
export const APPLICATIONS: EntryFunc = () => {
  return {
    content: "Application Observability",
    path: "applications",
  };
};
export const APPLICATIONS_STAT: EntryFunc = () => {
  return {
    content: "Application Observability",
    path: "applicationsStat",
  };
};
export const DNS: EntryFunc = () => {
  return {
    content: "DNS",
    path: "dns",
  };
};
export const TRAFFIC: EntryFunc = () => {
  return {
    content: "Network Observability",
    path: "networkObservability",
  };
};
export const OVERIVIEW: EntryFunc = () => {
  return {
    content: "Overview",
    path: "overview",
  };
};
export const LATENCY: EntryFunc = () => {
  return {
    content: "Latency",
    path: "latency",
  };
};
export const TRENDS: EntryFunc = () => {
  return {
    content: "Trends",
    path: "trends",
  };
};
export const FLOWS: EntryFunc = () => {
  return {
    content: "Flows",
    path: "flows",
  };
};
export const GEOLOCATION: EntryFunc = () => {
  return {
    content: "Geolocation",
    path: "geolocation",
  };
};
export const DETAILED_RECORDS: EntryFunc = () => {
  return {
    content: "Detailed Records",
    path: "detailedRecords",
  };
};
export const CYBER_THREATS: EntryFunc = () => {
  return {
    content: "Cyber Threats",
    path: "cyberThreats",
  };
};
export const DNS_THREATS: EntryFunc = () => {
  return {
    content: "DNS Threats",
    path: "dnsThreats",
  };
};
export const ADVANCED_THREATS: EntryFunc = () => {
  return {
    content: "Advanced Threats",
    path: "advancedThreats",
  };
};

// NETWORK
export const NETWORK: EntryFunc = () => {
  return {
    content: "Network",
    path: "network",
  };
};
export const ZAYO_CONNECTIONS: EntryFunc = () => ({
  content: "Connections",
  path: "connections",
});
export const HISTORY_LOG: EntryFunc = () => ({
  content: "History Log",
  path: "historyLog",
});
export const ZAYO_NEW_CONNECTION: EntryFunc = () => ({
  content: "New Connection",
  path: "newConnection",
});
export const ZAYO_EDIT_CONNECTION: EntryFunc = (name) => {
  return {
    content: name || "",
    path: "editConnection" + addId(name),
  };
};
export const CONNECTIVITY: EntryFunc = () => ({
  content: "Cloud and Connections",
  path: "connectivity",
});
export const CONNECTIONS: EntryFunc = () => ({
  content: "Connections",
  path: "connections",
});
export const CONNECTIONS_UNITED: EntryFunc = () => ({
  content: "Build Your Network",
  path: "connections_united",
});
export const CLOUD_CONNECTIVITY: EntryFunc = () => ({
  content: "Clouds",
  path: "cloudConnectivity",
});
export const SDWAN: EntryFunc = () => ({
  content: "SDWAN Providers",
  path: "sdwanProviders",
});
export const CLOUD_ROUTER: EntryFunc = () => ({
  content: "Cloud Router",
  path: "cloudRouter",
});
export const LOOKING_GLASS: EntryFunc = () => {
  return {
    content: "Looking Glass",
    path: "lookingGlass",
  };
};
export const ARPS: EntryFunc = () => {
  return {
    content: "ARPs",
    path: "arps",
  };
};

export const PBR: EntryFunc = () => {
  return {
    content: "PBR",
    path: "pbr",
  };
};

export const PBM: EntryFunc = () => {
  return {
    content: "PBM",
    path: "pbm",
  };
};

export const DDoSSystem: EntryFunc = () => {
  return {
    content: "DDoS",
    path: "ddos",
  };
};

export const ROUTE_FILTERS: EntryFunc = () => {
  return {
    content: "Route Filters",
    path: "routeFilters",
  };
};

// SERVICES
export const SERVICES: EntryFunc = () => ({
  content: "Services",
  path: "services",
});
export const SERVICES_FW: EntryFunc = () => ({
  content: "Firewall",
  path: "firewall",
});

export const SERVICES_FW_DIA: EntryFunc = () => ({
  content: "DIA Firewall",
  path: "dia",
});
export const SERVICES_FW_DIA_REPORTS: EntryFunc = () => ({
  content: "DIA Reports",
  path: "reports",
});
export const SERVICES_NAT: EntryFunc = () => ({
  content: "NAT",
  path: "nat",
});
export const SEGMENTATION: EntryFunc = () => ({
  content: "Segmentation",
  path: "segmentation",
});
export const SEGMENTS: EntryFunc = () => ({
  content: "Segments",
  path: "segments",
});
export const SEGMENTS_RULES: EntryFunc = () => ({
  content: "Segments Rules",
  path: "rules",
});
export const SERVICES_PREFERENCES = (
  id?: string,
  isPackagesVisible?: boolean
) => ({
  content: isPackagesVisible ? "Packages" : "My Services",
  path: "servicesPreferences",
});
export const WEB_FILTERING: EntryFunc = () => ({
  content: "Web Filtering",
  path: "webFiltering",
});

// WIZARDS
export const WIZARDS: EntryFunc = () => {
  return {
    content: "Build your network",
    path: "wizards",
  };
};
export const LOCATIONS: EntryFunc = () => ({
  content: "Locations",
  path: "locations",
});

// OTHERS
export const REMOTE_CONFIG: EntryFunc = () => ({
  content: "IPSec remote config",
  path: "remote_config",
});
export const LOAD_BALANCER: EntryFunc = () => ({
  content: "Load Balancer",
  path: "loadBalancer",
});
export const ENTER_LICENSE_KEY: EntryFunc = () => ({
  content: "Enter License Key",
  path: "enterLicenseKey",
});
export const SEARCH: EntryFunc = () => ({
  content: "Search",
  path: "search",
});
export const NSOS_SOFTWARE: EntryFunc = () => ({
  content: "NSOS Software",
  path: "nsosSoftware",
});

//
export const ZAYO_REPORT_USAGE_BILLING: EntryFunc = () => ({
  content: "Report / Usage / Billing",
  path: "reportUsageBilling",
});
export const USAGE_REPORT: EntryFunc = () => ({
  content: "Usage report",
  path: "usageReport",
});
export const ZAYO_SERVICES: EntryFunc = () => ({
  content: "Services",
  path: "services",
});
export const MOBILE_APP: EntryFunc = () => ({
  content: "mobileApp",
  path: "/mobile/app/login",
});
