import React, { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import styles from "./PBMPage.module.scss";
import PBMTable from "./PBMTable";
import { OneSystemLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { PageProps } from "../../helpers/types";

const PBMPage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <OneSystemLayout>
      <div className={styles.container}>
        <Tabs />
        <PBMTable />
      </div>
    </OneSystemLayout>
  );
};
export default withLayout<PageProps>(PBMPage);
