import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";

function ApplicationsIcon({ color, width, height }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width={width}
      height={height}
    >
      <path
        fill={color || "#6B55F0"}
        fillRule="evenodd"
        d="M1.637 2.637a1 1 0 0 1 1-1h1.392a1 1 0 0 1 1 1v1.392a1 1 0 0 1-1 1H2.637a1 1 0 0 1-1-1V2.637Zm4.704 0a1 1 0 0 1 1-1h1.392a1 1 0 0 1 1 1v1.392a1 1 0 0 1-1 1H7.341a1 1 0 0 1-1-1V2.637Zm5.703-1a1 1 0 0 0-1 1v1.392a1 1 0 0 0 1 1h1.393a1 1 0 0 0 1-1V2.637a1 1 0 0 0-1-1h-1.393ZM1.637 7.34a1 1 0 0 1 1-1h1.392a1 1 0 0 1 1 1v1.392a1 1 0 0 1-1 1H2.637a1 1 0 0 1-1-1V7.34Zm5.704-1a1 1 0 0 0-1 1v1.392a1 1 0 0 0 1 1h1.392a1 1 0 0 0 1-1V7.34a1 1 0 0 0-1-1H7.341Zm3.703 1a1 1 0 0 1 1-1h1.393a1 1 0 0 1 1 1v1.392a1 1 0 0 1-1 1h-1.393a1 1 0 0 1-1-1V7.34Zm-8.407 3.703a1 1 0 0 0-1 1v1.393a1 1 0 0 0 1 1h1.392a1 1 0 0 0 1-1v-1.393a1 1 0 0 0-1-1H2.637Zm3.704 1a1 1 0 0 1 1-1h1.392a1 1 0 0 1 1 1v1.393a1 1 0 0 1-1 1H7.341a1 1 0 0 1-1-1v-1.393Zm5.703-1a1 1 0 0 0-1 1v1.393a1 1 0 0 0 1 1h1.393a1 1 0 0 0 1-1v-1.393a1 1 0 0 0-1-1h-1.393Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ApplicationsIcon;
