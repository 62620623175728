import { GTimeRange } from "../components/common/charts/GraphanaLinksFactory";

export const getPanelIdByTimerange = (
  timeRange: GTimeRange,
  type: PanelType
): number => {
  const tf = getTimeframe(timeRange.from);
  return PANELS_ID[type][tf];
};

const getTimeframe = (date: string): TimeFrameType => {
  const match = date?.match(/now-(\d+)/);
  let value = match ? parseInt(match[1]) : 0;
  if (!value) {
    const delta = new Date(Date.now() - Date.parse(date));
    value = delta.getHours() * 60 + delta.getMinutes();
  }
  if (value <= 3 * 60) return "tf1";
  if (value > 3 * 60 && value <= 24 * 60) return "tf15";
  return "tf60";
};

type TimeFrames = { tf1: number; tf15: number; tf60: number };
type PanelsList = {
  inOutGeneralBPS: TimeFrames;
  inOutGeneralPPS: TimeFrames;
  interfacePps: TimeFrames;
  interfaceBps: TimeFrames;
  interfacePpsDia: TimeFrames;
  interfaceBpsDia: TimeFrames;
  dashboardPps: TimeFrames;
  dashboardBps: TimeFrames;
  systemCcn: TimeFrames;
  systemCps: TimeFrames;
  systemBps: TimeFrames;
  systemPps: TimeFrames;
  firewallPps: TimeFrames;
  firewallPpsAccept: TimeFrames;
  firewallBps: TimeFrames;
  firewallBpsAccept: TimeFrames;
  firewallCps: TimeFrames;
  firewallCpsAccept: TimeFrames;
  firewallCcn: TimeFrames;
  firewallCcnAccept: TimeFrames;
  natPpsRx: TimeFrames;
  natPpsTx: TimeFrames;
  natBpsRx: TimeFrames;
  natBpsTx: TimeFrames;
  trafficOverview77: TimeFrames;
  trafficGeolocation75: TimeFrames;
  vrfBps: TimeFrames;
  vrfPps: TimeFrames;
};
type PanelType = keyof PanelsList;
type TimeFrameType = keyof TimeFrames;

export const PANELS_ID: PanelsList = {
  inOutGeneralBPS: { tf1: 173, tf15: 218, tf60: 219 },
  inOutGeneralPPS: { tf1: 172, tf15: 220, tf60: 221 },
  interfacePps: { tf1: 131, tf15: 174, tf60: 175 },
  interfaceBps: { tf1: 129, tf15: 176, tf60: 177 },
  interfacePpsDia: { tf1: 243, tf15: 244, tf60: 245 },
  interfaceBpsDia: { tf1: 240, tf15: 241, tf60: 242 },
  dashboardPps: { tf1: 59, tf15: 178, tf60: 179 },
  dashboardBps: { tf1: 58, tf15: 180, tf60: 181 },
  systemCcn: { tf1: 73, tf15: 186, tf60: 187 },
  systemCps: { tf1: 69, tf15: 188, tf60: 189 },
  systemBps: { tf1: 67, tf15: 190, tf60: 191 },
  systemPps: { tf1: 65, tf15: 192, tf60: 193 },
  vrfBps: { tf1: 67, tf15: 190, tf60: 191 },
  vrfPps: { tf1: 65, tf15: 192, tf60: 193 },
  firewallPps: { tf1: 79, tf15: 194, tf60: 195 },
  firewallPpsAccept: { tf1: 81, tf15: 196, tf60: 197 },
  firewallBps: { tf1: 163, tf15: 198, tf60: 199 },
  firewallBpsAccept: { tf1: 167, tf15: 200, tf60: 201 },
  firewallCps: { tf1: 85, tf15: 202, tf60: 203 },
  firewallCpsAccept: { tf1: 89, tf15: 204, tf60: 205 },
  firewallCcn: { tf1: 87, tf15: 206, tf60: 207 },
  firewallCcnAccept: { tf1: 89, tf15: 208, tf60: 209 },
  natPpsRx: { tf1: 95, tf15: 210, tf60: 211 },
  natPpsTx: { tf1: 150, tf15: 212, tf60: 213 },
  natBpsRx: { tf1: 93, tf15: 214, tf60: 215 },
  natBpsTx: { tf1: 152, tf15: 216, tf60: 217 },
  trafficOverview77: { tf1: 77, tf15: 182, tf60: 183 },
  trafficGeolocation75: { tf1: 75, tf15: 184, tf60: 185 },
};
