import { ConnectionFormType, VIMapType } from "../../../types";

import { group as d3Group } from "d3";
// do not change this. Static content is stick to this concrete values
export const STAR_WIDTH = 539;
export const STAR_HEIGHT = 537;

export type PosProps = {
  x: number;
  y: number;
};

export type InterfacesGroup = {
  position: PosProps;
  size: "small" | "big";
  connection_type: string;
  count: number;
  data: Array<ConnectionFormType>;
};
type StarLayout = Array<{
  position: PosProps;
  location: string;
  interfacesGroups: Array<InterfacesGroup>;
}>;

export function createStarLayout(
  viMap?: VIMapType,
  groupByType?: boolean
): StarLayout {
  const totalLoc = viMap ? Object.entries(viMap).length : 0;

  return Object.entries(viMap || {}).map(
    ([location, { system, viList }], locI) => {
      return {
        location,
        system,
        interfacesGroups: createGroupsForLocation(
          viList,
          totalLoc,
          locI,
          groupByType
        ),
        position: getLocationPosition(totalLoc, locI),
      };
    }
  );
}

function createGroupsForLocation(
  interfaces: Array<ConnectionFormType | undefined>,
  totalLoc: number,
  locI: number,
  groupByType?: boolean
): Array<InterfacesGroup> {
  const ints = (interfaces || []).filter((f): f is ConnectionFormType => !!f);
  const groups: Array<InterfacesGroup> = [];
  if (groupByType) {
    const grouped = d3Group(ints, (interf) => interf?.labels.remote_type);
    let i = 0; // forEach for Map does not have number index as second parameter
    grouped.forEach((value) => {
      groups.push(
        _createGroupFromArray(value, totalLoc, grouped.size, locI, i)
      );
      i++;
    });
  } else {
    ints.forEach((value, i) => {
      groups.push(
        _createGroupFromArray([value], totalLoc, ints.length, locI, i)
      );
    });
  }

  return groups;
}

function _createGroupFromArray(
  ints: Array<ConnectionFormType>,
  totalLoc: number,
  totalGroups: number,
  locI: number,
  groupI: number
): InterfacesGroup {
  return {
    position: getIntPosition(totalLoc, totalGroups, locI, groupI),
    size: getSizeType(totalLoc, totalGroups),
    connection_type: ints[0].labels?.remote_type || "",
    count: ints.length,
    data: ints,
  };
}

export const LOC_RADIUS = 72.5;
const INT_RAD = 200;
const CENTER = {
  x: 270.5,
  y: 235.5,
};

export function getLocationPosition(
  total: number,
  num: number,
  xMode = 1,
  yMode = 1,
  center?: PosProps,
  noChangeCorner = false
): PosProps {
  const rad = ((2 * Math.PI) / total) * num;
  const realCenter = center || CENTER;
  if (noChangeCorner) {
    return { x: realCenter.x * xMode, y: realCenter.y * yMode };
  }
  const x = realCenter.x + Math.cos(rad) * LOC_RADIUS * xMode;
  const y = realCenter.y + Math.sin(rad) * LOC_RADIUS * yMode;
  return { x, y };
}

function getSizeType(totalL: number, totalI: number): "small" | "big" {
  const unit = (2 * Math.PI) / totalL;
  return unit / totalI < 0.2 ? "small" : "big";
}
function getIntPosition(
  totalL: number,
  totalI: number,
  numL: number,
  numI: number
): PosProps {
  const unit = (2 * Math.PI) / totalL;
  const unitStartRad = unit * numL - unit / 2;
  const rad = unitStartRad + (unit / (totalI + 1)) * (numI + 1);
  const x = CENTER.x + Math.cos(rad) * INT_RAD;
  const y = CENTER.y + Math.sin(rad) * INT_RAD;
  return { x, y };
}
