import { ReactElement } from "react";
import styles from "./SSOProvidersComponent.module.scss";
import { useAuthContext } from "../../../contexts/AuthContext";
import GoogleIcon from "../../../components/icons/GoogleIcon";

export type ProviderData = {
  icon: ReactElement;
  title: string;
  desc?: string;
};

const getDataByProvider = (provider: string): ProviderData => {
  switch (provider) {
    case "google":
    default:
      return {
        icon: <GoogleIcon />,
        title: "Google",
      };
  }
};

export const SSOProvidersComponent = (): ReactElement => {
  const { ssoProviders, setSSOSource } = useAuthContext();

  const handleClick = (source: string, path: string) => {
    setSSOSource(source);
    window.location.href = path;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <hr className={styles.line} />
        <div className={styles.title}>or sign in with</div>
        <hr className={styles.line} />
      </div>
      {ssoProviders &&
        Object.keys(ssoProviders).map((provider: string) => {
          const providerData = getDataByProvider(provider);
          return (
            <div
              key={provider}
              className={styles.provider}
              onClick={() => handleClick(provider, ssoProviders?.[provider])}
            >
              <div className={styles.content}>
                {providerData.icon}
                <div className={styles.contentTitle}>{providerData.title}</div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
