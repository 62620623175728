import React, { MouseEventHandler } from "react";
import AwsIcon from "../../../../../../components/icons/interfaceTypes/AWSIcon";
import GCPIcon from "../../../../../../components/icons/interfaceTypes/GCPIcon";
import AzureIcon from "../../../../../../components/icons/interfaceTypes/Azure";
import BareMetalIcon from "../../../../../../components/icons/interfaceTypes/BareMetal";
import NSPBranch from "../../../../../../components/icons/interfaceTypes/NSPBranch";
import InternetIcon from "../../../../../../components/icons/interfaceTypes/InternetIcon";
import RemoteUsersIcon from "../../../../../../components/icons/interfaceTypes/RemoteUsersIcon";
import BranchIcon from "../../../../../../components/icons/interfaceTypes/BranchIcon";
import VPCLabel from "../VPCLabel";
import { InterfacesGroup, PosProps } from "../utils";
import IpsecIcon from "../../../../../../components/icons/interfaceTypes/IpsecIcon";
import ActiveTunnelLabel from "../ActiveTunnelLabel";
import IOTIcon from "../../../../../../components/icons/interfaceTypes/IOTIcon";
import SDWANIcon from "../../../../../../components/icons/interfaceTypes/SDWANIcon";
import { AZURE_LOWERCASE } from "../../../../../../helpers/consts";

function randomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default function InterfaceCircle({
  x,
  y,
  groupData,
  onMouseOver,
  onMouseLeave,
  onClick,
  active,
}: PosProps & {
  onMouseOver?: MouseEventHandler<Node> | undefined;
  onMouseLeave?: MouseEventHandler<Node> | undefined;
  onClick?: () => void;
  groupData: InterfacesGroup;
  active: boolean;
}): React.ReactElement {
  const isSmall = groupData.size === "small";
  const isGroup = groupData.count > 1;
  const name = isGroup
    ? `${groupData.connection_type} ${groupData.count}`
    : groupData.data[0]?.name;

  const vpcs: Array<string> = groupData.data.reduce(
    (acc: Array<string>, int): Array<string> => [...acc, ...(int?.vpc || [])],
    []
  );
  const tunnels: number = groupData.data?.[0]?.tunnelAmount;
  const isIOT = /^iot/.test(groupData.connection_type);
  const fakedTunnels = isIOT ? randomInteger(150, 300) : tunnels;
  const remoteSystem = /^remote_users/.test(groupData.connection_type) || isIOT;
  const r = isSmall ? 10 : 20;
  const TypeIcon = (() => {
    const type = groupData?.connection_type;
    switch (true) {
      case /^aws/.test(type):
        return AwsIcon;
      case /^gcp/.test(type):
        return GCPIcon;
      case /^azure/.test(type):
        return AzureIcon;
      case /^bareMetal/.test(type):
        return BareMetalIcon;
      case /^data_center/.test(type):
      case /^colo/.test(type):
        return NSPBranch;
      case /^internet/.test(type):
        return InternetIcon;
      case /^ipsec/.test(type):
        return IpsecIcon;
      case isIOT:
        return IOTIcon;
      case /^remote_users/.test(type):
        return RemoteUsersIcon;
      case /^sdwan/.test(type):
        return SDWANIcon;
      case /^bridged/.test(type):
      case /^branch/.test(type):
      case /^routed/.test(type):
      default:
        return BranchIcon;
    }
  })();
  return (
    <>
      {isGroup && (
        <circle
          cx={x + 4}
          cy={y}
          r={r}
          fill="var(--accent-color-opacity)"
          stroke="#BEB8EA"
        />
      )}
      <circle
        cx={x}
        cy={y}
        r={r}
        fill="#fff"
        stroke={active ? "var(--accent-color)" : "#BEB8EA"}
      />
      <text
        x={x}
        y={y + r + 5}
        fill="var(--font)"
        fontSize={isSmall ? 6 : 11}
        textAnchor="middle"
      >
        {name}
      </text>
      <TypeIcon
        x={x}
        y={y}
        width={isSmall ? 13 : 20}
        height={isSmall ? 13 : 20}
      />
      {vpcs && vpcs.length > 0 && (
        <VPCLabel
          x={x}
          y={y - (isSmall ? 15 : 30)}
          count={vpcs.length}
          isSmall={isSmall}
          label={
            groupData?.connection_type === AZURE_LOWERCASE ? "VNets" : "VPCs"
          }
        />
      )}
      {remoteSystem && (
        <ActiveTunnelLabel
          x={x}
          y={y - (isSmall ? 15 : 30)}
          count={fakedTunnels}
          isSmall={fakedTunnels < 10}
          label={""}
        />
      )}
      <circle
        cx={x}
        cy={y}
        r={r}
        fill="#fff"
        opacity={0}
        style={{ cursor: "pointer" }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
      />
    </>
  );
}
