export const AVAILABLE_FIELDS_NETWORK = [
  { label: "Locations", value: "locations" },
  { label: "Source IPs", value: "srcIPs" },
  { label: "Destination IP", value: "dstIP" },
  { label: "Protocol", value: "protocol" },
  { label: "Source Connections", value: "srcConnections" },
  { label: "Destination Connections", value: "dstConnections" },
  { label: "Destination Port Services", value: "dstPortServices" },
  { label: "Destination Country", value: "dstCountry" },
  { label: "Destination ASN", value: "dstASN" },
  { label: "Firewall Rule Name", value: "Firewall Rule Name" },
  { label: "Is DIA", value: "is_dia" },
];

export enum TRAFFIC_FILTER_FIELD {
  LOCATION = "locations",
  SRC_SEGMENTS = "srcSegments",
  DST_SEGMENTS = "dstSegments",
  SRC_CONNECTIONS = "srcConnections",
  DST_CONNECTIONS = "dstConnections",
  SRC_IP = "srcIPs",
  DST_IP = "dstIP",
  DST_PORT_SERVICES = "dstPortServices",
  DST_ASN = "dstASN",
  DST_COUNTRY = "dstCountry",
  PROTOCOL = "protocol",
  PUBLIC_PRIVATE = "publicPrivate",
  FIREWALL_RULE_NAME = "Firewall Rule Name",
  IS_DIA = "is_dia",
}

export const TRAFFIC_FIELDS_MAP: Array<string> = [
  TRAFFIC_FILTER_FIELD.LOCATION,
  TRAFFIC_FILTER_FIELD.SRC_SEGMENTS,
  TRAFFIC_FILTER_FIELD.DST_SEGMENTS,
  TRAFFIC_FILTER_FIELD.SRC_CONNECTIONS,
  TRAFFIC_FILTER_FIELD.DST_CONNECTIONS,
  TRAFFIC_FILTER_FIELD.SRC_IP,
  TRAFFIC_FILTER_FIELD.DST_IP,
  TRAFFIC_FILTER_FIELD.DST_PORT_SERVICES,
  TRAFFIC_FILTER_FIELD.DST_ASN,
  TRAFFIC_FILTER_FIELD.DST_COUNTRY,
  TRAFFIC_FILTER_FIELD.PROTOCOL,
  TRAFFIC_FILTER_FIELD.PUBLIC_PRIVATE,
  TRAFFIC_FILTER_FIELD.FIREWALL_RULE_NAME,
  TRAFFIC_FILTER_FIELD.IS_DIA,
];

export const TRAFFIC_FIELD_VALUE: { [key: string]: string } = {
  [TRAFFIC_FILTER_FIELD.LOCATION]: "Location",
  [TRAFFIC_FILTER_FIELD.SRC_SEGMENTS]: "Source Segments",
  [TRAFFIC_FILTER_FIELD.DST_SEGMENTS]: "Destination Segments",
  [TRAFFIC_FILTER_FIELD.SRC_CONNECTIONS]: "Source Connection",
  [TRAFFIC_FILTER_FIELD.DST_CONNECTIONS]: "Destination Connection",
  [TRAFFIC_FILTER_FIELD.SRC_IP]: "Source IP",
  [TRAFFIC_FILTER_FIELD.DST_IP]: "Destination IP",
  [TRAFFIC_FILTER_FIELD.DST_PORT_SERVICES]: "Destination Port app",
  [TRAFFIC_FILTER_FIELD.FIREWALL_RULE_NAME]: "Firewall Rule Name",
  [TRAFFIC_FILTER_FIELD.DST_ASN]: "Destination AS Name",
  [TRAFFIC_FILTER_FIELD.DST_COUNTRY]: "Destination Country",
  [TRAFFIC_FILTER_FIELD.PROTOCOL]: "Protocol",
  [TRAFFIC_FILTER_FIELD.PUBLIC_PRIVATE]: "Destination IP Type",
  [TRAFFIC_FILTER_FIELD.IS_DIA]: "is_dia",
};

export const TRAFFIC_FIELD_LABEL: { [key: string]: string } = {
  [TRAFFIC_FILTER_FIELD.LOCATION]: "Locations",
  [TRAFFIC_FILTER_FIELD.SRC_SEGMENTS]: "Source Segments",
  [TRAFFIC_FILTER_FIELD.DST_SEGMENTS]: "Destination Segments",
  [TRAFFIC_FILTER_FIELD.SRC_CONNECTIONS]: "Source Connections",
  [TRAFFIC_FILTER_FIELD.DST_CONNECTIONS]: "Destination Connections",
  [TRAFFIC_FILTER_FIELD.FIREWALL_RULE_NAME]: "Firewall Rule Name",
  [TRAFFIC_FILTER_FIELD.SRC_IP]: "Source IPs",
  [TRAFFIC_FILTER_FIELD.DST_IP]: "Destination IP",
  [TRAFFIC_FILTER_FIELD.DST_PORT_SERVICES]: "Destination Port Services",
  [TRAFFIC_FILTER_FIELD.DST_ASN]: "Destination ASN",
  [TRAFFIC_FILTER_FIELD.DST_COUNTRY]: "Destination Country",
  [TRAFFIC_FILTER_FIELD.PROTOCOL]: "Protocol",
  [TRAFFIC_FILTER_FIELD.PUBLIC_PRIVATE]: "Public/Private",
  [TRAFFIC_FILTER_FIELD.IS_DIA]: "is_dia",
};
