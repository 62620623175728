import React from "react";
import WizardLayoutComponent from "../../pages/WizardToolPage/common/WizardLayoutComponent";

// to wrap Wizards screen to basic layout

type Props = {};

export function withWizardToolLayout<T>(
  WrappedComponent: React.ComponentType<T>
) {
  return function Component(props: T & Props) {
    return (
      <WizardLayoutComponent>
        <WrappedComponent {...props} />
      </WizardLayoutComponent>
    );
  };
}
