import { formPathFromArray } from "../../../helpers/navigation";
import {
  SERVICES_PREFERENCES,
  ADVANCED_THREATS,
  APPLICATIONS,
  BGP,
  CLOUD_CONNECTIVITY,
  CLOUD_ROUTER,
  CONFIGURATION,
  CONNECTIONS,
  CONNECTIVITY,
  CYBER_THREATS,
  DETAILED_RECORDS,
  DNS,
  DNS_THREATS,
  EVENTS,
  FLOWS,
  GEOLOCATION,
  HISTORY_LOG,
  INSIGHTS,
  IPAM,
  LOCATIONS,
  NETWORK,
  NETWORK_RESOURCES,
  OVERIVIEW,
  PACKAGES,
  PROVISIONING,
  REPORTS,
  SEARCH,
  SEGMENTATION,
  SEGMENTS,
  SEGMENTS_RULES,
  SERVICES,
  SERVICES_FW,
  SERVICES_NAT,
  STATIC_ROUTES,
  SYSTEMS,
  TENANTS,
  TOPOLOGY,
  TRAFFIC,
  TRENDS,
  USERS,
  WIZARDS,
  ZAYO_CONNECTIONS,
  WEB_FILTERING,
  USER_GROUPS,
  SDWAN,
  CONNECTIONS_UNITED,
  SERVICES_FW_DIA,
  ORDERED_PORTS,
  REMOTE_USERS,
  ARPS,
  PBR,
  CLOUD_RESOURCES,
  INSIGHTS_SDWAN,
  SERVICE_CATALOG,
  SITES,
  LATENCY,
} from "../../../helpers/navigation/entries";
import BellIcon from "../../icons/BellIcon";
import ConfigIcon from "../../icons/ConfigIcon";
import ConnectionIcon from "../../icons/ConnectionIcon";
import CyberIcon from "../../icons/CyberIcon";
import NetworkObservabilityIcon from "../../icons/NetworkObservabilityIcon";
import ReportsIcon from "../../icons/ReportsIcon";
import SearchIcon from "../../icons/SearchIcon";
import StaticRouteIcon from "../../icons/StaticRouteIcon";
import SystemIcon from "../../icons/SystemIcon";
import UserIcon from "../../icons/UserIcon";
import InsightsIcon from "../../icons/tabs/InsightsIcon";
import NetworkIcon from "../../icons/tabs/NetworkIcon";
import ServicesIcon from "../../icons/tabs/ServicesIcon";
import TenantsIcon from "../../icons/tabs/TenantsIcon";
import WizardsIcon from "../../icons/tabs/WizardsIcon";
import ApplicationsIcon from "../../icons/topologyButtons/ApplicationsIcon";
import DNSIcon from "../../icons/topologyButtons/DNSIcon";
import SegmentsIcon from "../../icons/topologyButtons/SegmentsIcon";
import IPAMIcon from "../../icons/zayo/menu/IPAMIcon";
import { NavItem } from "./types";

// NETWORK RESOURSES
const NETWORK_RESOURCES_PAGE: NavItem = {
  title: "Network Resources",
  path: formPathFromArray([NETWORK_RESOURCES()]),
  icon: SystemIcon,
};
const SYSTEMS_PAGE: NavItem = {
  title: "Systems",
  path: formPathFromArray([NETWORK_RESOURCES(), SYSTEMS()]),
  icon: SystemIcon,
};
const NETWORK_RESOURCES_IPAM_PAGE: NavItem = {
  title: "Network Resources IPAM",
  path: formPathFromArray([NETWORK_RESOURCES(), IPAM()]),
  icon: IPAMIcon,
};
const NETWORK_RESOURCES_SITES_PAGE: NavItem = {
  title: "Sites",
  path: formPathFromArray([NETWORK_RESOURCES(), SITES()]),
  icon: SystemIcon,
};
const NETWORK_RESOURCES_EVENTS_PAGE: NavItem = {
  title: "Network Resources Events",
  path: formPathFromArray([NETWORK_RESOURCES(), EVENTS()]),
  icon: BellIcon,
};
const NETWORK_RESOURCES_CLOUD_RESOURCES_PAGE: NavItem = {
  title: "Network Resources Cloud Resources",
  path: formPathFromArray([NETWORK_RESOURCES(), CLOUD_RESOURCES()]),
  icon: IPAMIcon,
};

// TENANTS
const TENANTS_PAGE: NavItem = {
  title: "Tenants",
  path: formPathFromArray([TENANTS()]),
  icon: TenantsIcon,
};
const TENANTS_PROVISIONING_PAGE: NavItem = {
  title: "Tenants Provisioning",
  path: formPathFromArray([TENANTS(), PROVISIONING()]),
  icon: TenantsIcon,
};
const PACKAGES_PAGE: NavItem = {
  title: "Admin Packages",
  path: formPathFromArray([TENANTS(), PACKAGES()]),
  icon: TenantsIcon,
};
const SERVICE_CATALOG_PAGE: NavItem = {
  title: "Service Catalog",
  path: formPathFromArray([TENANTS(), SERVICE_CATALOG()]),
  icon: TenantsIcon,
};
const ORDERED_PORTS_PAGE: NavItem = {
  title: "Ordered Ports",
  path: formPathFromArray([TENANTS(), ORDERED_PORTS()]),
  icon: TenantsIcon,
};
const USERS_PAGE: NavItem = {
  title: "Users",
  path: formPathFromArray([TENANTS(), USERS()]),
  icon: UserIcon,
};
const USERS_GROUPS_PAGE: NavItem = {
  title: "Users Groups",
  path: formPathFromArray([TENANTS(), USER_GROUPS()]),
  icon: UserIcon,
};

// INSIGHTS
const INSIGHTS_PAGE: NavItem = {
  title: "Insights",
  path: formPathFromArray([INSIGHTS()]),
  icon: InsightsIcon,
};
const TOPOLOGY_PAGE: NavItem = {
  title: "Topology",
  path: formPathFromArray([INSIGHTS(), TOPOLOGY()]),
  icon: InsightsIcon,
};
const APP_OBSERVABILITY_PAGE = {
  title: "Application Observability",
  path: formPathFromArray([INSIGHTS(), APPLICATIONS()]),
  icon: ApplicationsIcon,
};
const APP_OBSERVABILITY_DNS_PAGE = {
  title: "DNS",
  path: formPathFromArray([INSIGHTS(), APPLICATIONS(), DNS()]),
  icon: DNSIcon,
};
const NETWORK_OBSERVABILITY_PAGE = {
  title: "Network Observability",
  path: formPathFromArray([INSIGHTS(), TRAFFIC()]),
  icon: NetworkObservabilityIcon,
};
const NETWORK_OBSERVABILITY_OVERVIEW_PAGE = {
  title: "Network Observability Overview",
  path: formPathFromArray([INSIGHTS(), TRAFFIC(), OVERIVIEW()]),
  icon: NetworkObservabilityIcon,
};
const NETWORK_OBSERVABILITY_TRENDS_PAGE = {
  title: "Network Observability Trends",
  path: formPathFromArray([INSIGHTS(), TRAFFIC(), TRENDS()]),
  icon: NetworkObservabilityIcon,
};
const NETWORK_OBSERVABILITY_FLOWS_PAGE = {
  title: "Network Observability Flows",
  path: formPathFromArray([INSIGHTS(), TRAFFIC(), FLOWS()]),
  icon: NetworkObservabilityIcon,
};
const NETWORK_OBSERVABILITY_LATENCY_PAGE = {
  title: "Network Latency",
  path: formPathFromArray([INSIGHTS(), TRAFFIC(), LATENCY()]),
  icon: NetworkObservabilityIcon,
};
const NETWORK_OBSERVABILITY_GEO_PAGE = {
  title: "Network Observability Geolocation",
  path: formPathFromArray([INSIGHTS(), TRAFFIC(), GEOLOCATION()]),
  icon: NetworkObservabilityIcon,
};
const NETWORK_OBSERVABILITY_DETAILED_RECORDS_PAGE = {
  title: "Network Observability Detailed Records",
  path: formPathFromArray([INSIGHTS(), TRAFFIC(), DETAILED_RECORDS()]),
  icon: NetworkObservabilityIcon,
};
const CYBER_THREATS_PAGE = {
  title: "Cyber Threats",
  path: formPathFromArray([INSIGHTS(), CYBER_THREATS()]),
  icon: UserIcon,
};
const CYBER_THREATS_DNS_PAGE = {
  title: "DNS Threats",
  path: formPathFromArray([INSIGHTS(), CYBER_THREATS(), DNS_THREATS()]),
  icon: DNSIcon,
};
const CYBER_THREATS_ADVANCED_PAGE = {
  title: "Advanced Threats",
  path: formPathFromArray([INSIGHTS(), CYBER_THREATS(), ADVANCED_THREATS()]),
  icon: CyberIcon,
};
const APP_INSIGHTS_SDWAN_PAGE = {
  title: "SDWAN Insights",
  path: formPathFromArray([INSIGHTS(), INSIGHTS_SDWAN()]),
  icon: UserIcon,
};

// NETWORK
const NETWORK_PAGE = {
  title: "Network",
  path: formPathFromArray([NETWORK()]),
  icon: NetworkIcon,
};
const ZAYO_CONNECTIONS_ISOLATE_PAGE = {
  title: "Connections",
  path: formPathFromArray([ZAYO_CONNECTIONS()]),
  icon: ConnectionIcon,
};
const ZAYO_NETWORK_SEGMENTS_PAGE = {
  title: "Connections",
  path: formPathFromArray([NETWORK(), SEGMENTS()]),
  icon: SegmentsIcon,
};
const ZAYO_CONNECTIONS_PAGE = {
  title: "Connections",
  path: formPathFromArray([NETWORK(), ZAYO_CONNECTIONS()]),
  icon: ConnectionIcon,
};
const ZAYO_HISTORY_LOG_PAGE = {
  title: "History Log",
  path: formPathFromArray([NETWORK(), ZAYO_CONNECTIONS(), HISTORY_LOG()]),
  icon: ConnectionIcon,
};
const CONNECTIVITY_PAGE = {
  title: "Cloud and Connections / Connectivity",
  path: formPathFromArray([NETWORK(), CONNECTIVITY()]),
  icon: ConnectionIcon,
};
const UNITED_CONNECTIONS_PAGE = {
  title: "Connections",
  path: formPathFromArray([NETWORK(), CONNECTIONS_UNITED()]),
  icon: ConnectionIcon,
};
const UNITED_CONNECTIONS_CONFIGURATION_PAGE = {
  title: "Connections Configuration",
  path: formPathFromArray([NETWORK(), CONNECTIONS_UNITED(), CONFIGURATION()]),
  icon: ConnectionIcon,
};
const CONNECTIVITY_CONNECTIONS_CONFIG_PAGE = {
  title: "Connectivity Connections Configuration",
  path: formPathFromArray([
    NETWORK(),
    CONNECTIVITY(),
    CONNECTIONS(),
    CONFIGURATION(),
  ]),
  icon: ConfigIcon,
};
const CONNECTIVITY_CONNECTIONS_REPORTS_PAGE = {
  title: "Connectivity Connections Reports",
  path: formPathFromArray([
    NETWORK(),
    CONNECTIVITY(),
    CONNECTIONS(),
    REPORTS(),
  ]),
  icon: ReportsIcon,
};
const CLOUD_CONNECTIVITY_PAGE = {
  title: "Clouds",
  path: formPathFromArray([NETWORK(), CONNECTIVITY(), CLOUD_CONNECTIVITY()]),
  icon: ConnectionIcon,
};
const SDWAN_PROVIDERS_PAGE = {
  title: "SDWAN Providers",
  path: formPathFromArray([NETWORK(), CONNECTIVITY(), SDWAN()]),
  icon: ConnectionIcon,
};
const CLOUD_ROUTER_PAGE = {
  title: "Cloud Router",
  path: formPathFromArray([NETWORK(), CLOUD_ROUTER()]),
  icon: StaticRouteIcon,
};
const NETWORK_STATIC_ROUTES_CONFIG_PAGE = {
  title: "Network Static Routes Configuration",
  path: formPathFromArray([NETWORK(), CLOUD_ROUTER(), STATIC_ROUTES()]),
  icon: ConfigIcon,
};
const NETWORK_STATIC_ROUTES_REPORTS_PAGE = {
  title: "Network Static Routes Reports",
  path: formPathFromArray([
    NETWORK(),
    CLOUD_ROUTER(),
    STATIC_ROUTES(),
    REPORTS(),
  ]),
  icon: ReportsIcon,
};
const NETWORK_BGP_CONFIG_PAGE = {
  title: "Network BGP Configuration",
  path: formPathFromArray([NETWORK(), CLOUD_ROUTER(), BGP(), CONFIGURATION()]),
  icon: ConfigIcon,
};
const NETWORK_BGP_REPORTS_PAGE = {
  title: "Network BGP Reports",
  path: formPathFromArray([NETWORK(), CLOUD_ROUTER(), BGP(), REPORTS()]),
  icon: ReportsIcon,
};
const NETWORK_ARPS_CONFIG_PAGE = {
  title: "Network ARPs Configuration",
  path: formPathFromArray([NETWORK(), CLOUD_ROUTER(), ARPS()]),
  icon: ReportsIcon,
};
const NETWORK_PBR_CONFIG_PAGE = {
  title: "Network PBR Configuration",
  path: formPathFromArray([NETWORK(), CLOUD_ROUTER(), PBR()]),
  icon: ReportsIcon,
};
const REMOTE_USERS_PAGE = {
  title: "Remote Users",
  path: formPathFromArray([NETWORK(), REMOTE_USERS()]),
  icon: UserIcon,
};
const REMOTE_USERS_GROUPS_PAGE = {
  title: "Remote Users Groups",
  path: formPathFromArray([NETWORK(), REMOTE_USERS(), USER_GROUPS()]),
  icon: UserIcon,
};

// SERVICES
const SERVICES_PAGE = {
  title: "Services",
  path: formPathFromArray([SERVICES()]),
  icon: ServicesIcon,
};
const SERVICES_FW_CONFIG_PAGE = {
  title: "Firewall Rules Configuration",
  path: formPathFromArray([SERVICES(), SERVICES_FW(), CONFIGURATION()]),
  icon: ConfigIcon,
};
const SERVICES_DIA_FW_CONFIG_PAGE = {
  title: "DIA Firewall Rules Configuration",
  path: formPathFromArray([SERVICES(), SERVICES_FW_DIA(), CONFIGURATION()]),
  icon: ConfigIcon,
};
const SERVICES_FW_REPORTS_PAGE = {
  title: "Firewall Rules Reports",
  path: formPathFromArray([SERVICES(), SERVICES_FW(), REPORTS()]),
  icon: ReportsIcon,
};
const SERVICES_NAT_CONFIG_PAGE = {
  title: "NAT Rules Configuration",
  path: formPathFromArray([SERVICES(), SERVICES_NAT(), CONFIGURATION()]),
  icon: ConfigIcon,
};
const SERVICES_NAT_REPORTS_PAGE = {
  title: "NAT Rules Reports",
  path: formPathFromArray([SERVICES(), SERVICES_NAT(), REPORTS()]),
  icon: ReportsIcon,
};
const SERVICES_SEGMENTATION_PAGE = {
  title: "Segmentation",
  path: formPathFromArray([SERVICES(), SEGMENTATION()]),
  icon: ReportsIcon,
};
const SERVICES_SEGMENTS_RULES_PAGE = {
  title: "Segments Rules",
  path: formPathFromArray([SERVICES(), SEGMENTATION(), SEGMENTS_RULES()]),
  icon: SegmentsIcon,
};
const SERVICES_SEGMENTS_PAGE = {
  title: "Segments",
  path: formPathFromArray([SERVICES(), SEGMENTATION(), SEGMENTS()]),
  icon: SegmentsIcon,
};
const SERVICES_PREFERENCES_PAGE = (isPackagesVisible?: boolean) => ({
  title: isPackagesVisible ? "Packages" : "My Services",
  path: formPathFromArray([SERVICES(), SERVICES_PREFERENCES()]),
  icon: SegmentsIcon,
});
const SERVICES_WEB_FILTERING_CONFIG_PAGE = {
  title: "Web Filtering Configuration",
  path: formPathFromArray([SERVICES(), WEB_FILTERING(), CONFIGURATION()]),
  icon: ConfigIcon,
};
const SERVICES_WEB_FILTERING_STATISTICS_PAGE = {
  title: "Web Filtering Statistics",
  path: formPathFromArray([SERVICES(), WEB_FILTERING(), REPORTS()]),
  icon: ReportsIcon,
};

// WIZARDS
const WIZARDS_PAGE = {
  title: "Wizards",
  path: formPathFromArray([WIZARDS()]),
  icon: WizardsIcon,
};
const WIZARDS_LOCATIONS_PAGE = {
  title: "Wizards Locations",
  path: formPathFromArray([WIZARDS(), LOCATIONS()]),
  icon: WizardsIcon,
};
const WIZARDS_CONNECTIONS_PAGE = {
  title: "Wizards Connections",
  path: formPathFromArray([WIZARDS(), CONNECTIONS()]),
  icon: WizardsIcon,
};
const WIZARDS_SERVICES_PAGE = {
  title: "Wizards Services",
  path: formPathFromArray([WIZARDS(), SERVICES()]),
  icon: WizardsIcon,
};

// OTHERS
const SEARCH_PAGE = {
  title: "Search",
  path: formPathFromArray([SEARCH()]),
  icon: SearchIcon,
};
const IPAM_PAGE = {
  title: "IPAM",
  path: formPathFromArray([IPAM()]),
  icon: IPAMIcon,
};
const EVENTS_PAGE = {
  title: "Events",
  path: formPathFromArray([EVENTS()]),
  icon: BellIcon,
};

export const getPagesMap = (isPackagesVisible: boolean) => {
  const PAGES_MAP: { [key: string]: Array<NavItem> } = {
    admin: [
      NETWORK_RESOURCES_PAGE,
      SYSTEMS_PAGE,
      NETWORK_RESOURCES_IPAM_PAGE,
      NETWORK_RESOURCES_EVENTS_PAGE,
      TENANTS_PAGE,
      TENANTS_PROVISIONING_PAGE,
      PACKAGES_PAGE,
      SERVICE_CATALOG_PAGE,
      NETWORK_RESOURCES_SITES_PAGE,
      ORDERED_PORTS_PAGE,
      USERS_PAGE,
      USERS_GROUPS_PAGE,
      INSIGHTS_PAGE,
      TOPOLOGY_PAGE,
      APP_OBSERVABILITY_PAGE,
      APP_OBSERVABILITY_DNS_PAGE,
      APP_INSIGHTS_SDWAN_PAGE,
      NETWORK_OBSERVABILITY_PAGE,
      NETWORK_OBSERVABILITY_OVERVIEW_PAGE,
      NETWORK_OBSERVABILITY_TRENDS_PAGE,
      NETWORK_OBSERVABILITY_FLOWS_PAGE,
      NETWORK_OBSERVABILITY_LATENCY_PAGE,
      NETWORK_OBSERVABILITY_GEO_PAGE,
      NETWORK_OBSERVABILITY_DETAILED_RECORDS_PAGE,
      CYBER_THREATS_PAGE,
      CYBER_THREATS_DNS_PAGE,
      CYBER_THREATS_ADVANCED_PAGE,
      NETWORK_PAGE,
      CONNECTIVITY_PAGE,
      UNITED_CONNECTIONS_PAGE,
      CONNECTIVITY_CONNECTIONS_CONFIG_PAGE,
      CONNECTIVITY_CONNECTIONS_REPORTS_PAGE,
      CLOUD_CONNECTIVITY_PAGE,
      SDWAN_PROVIDERS_PAGE,
      CLOUD_ROUTER_PAGE,
      NETWORK_STATIC_ROUTES_CONFIG_PAGE,
      NETWORK_STATIC_ROUTES_REPORTS_PAGE,
      NETWORK_BGP_CONFIG_PAGE,
      NETWORK_BGP_REPORTS_PAGE,
      NETWORK_ARPS_CONFIG_PAGE,
      NETWORK_PBR_CONFIG_PAGE,
      SERVICES_PAGE,
      SERVICES_FW_CONFIG_PAGE,
      SERVICES_DIA_FW_CONFIG_PAGE,
      SERVICES_FW_REPORTS_PAGE,
      SERVICES_NAT_CONFIG_PAGE,
      SERVICES_NAT_REPORTS_PAGE,
      SERVICES_SEGMENTATION_PAGE,
      SERVICES_SEGMENTS_RULES_PAGE,
      SERVICES_SEGMENTS_PAGE,
      SERVICES_PREFERENCES_PAGE(isPackagesVisible),
      SERVICES_WEB_FILTERING_CONFIG_PAGE,
      SERVICES_WEB_FILTERING_STATISTICS_PAGE,
      SEARCH_PAGE,
      IPAM_PAGE,
      EVENTS_PAGE,
    ],
    tenant: [
      INSIGHTS_PAGE,
      TOPOLOGY_PAGE,
      APP_OBSERVABILITY_PAGE,
      SERVICE_CATALOG_PAGE,
      NETWORK_RESOURCES_SITES_PAGE,
      APP_OBSERVABILITY_DNS_PAGE,
      APP_INSIGHTS_SDWAN_PAGE,
      NETWORK_OBSERVABILITY_PAGE,
      NETWORK_OBSERVABILITY_OVERVIEW_PAGE,
      NETWORK_OBSERVABILITY_TRENDS_PAGE,
      NETWORK_OBSERVABILITY_FLOWS_PAGE,
      NETWORK_OBSERVABILITY_LATENCY_PAGE,
      NETWORK_OBSERVABILITY_GEO_PAGE,
      NETWORK_OBSERVABILITY_DETAILED_RECORDS_PAGE,
      CYBER_THREATS_PAGE,
      CYBER_THREATS_DNS_PAGE,
      CYBER_THREATS_ADVANCED_PAGE,
      NETWORK_PAGE,
      CONNECTIVITY_PAGE,
      UNITED_CONNECTIONS_PAGE,
      CONNECTIVITY_CONNECTIONS_CONFIG_PAGE,
      CONNECTIVITY_CONNECTIONS_REPORTS_PAGE,
      CLOUD_CONNECTIVITY_PAGE,
      SDWAN_PROVIDERS_PAGE,
      CLOUD_ROUTER_PAGE,
      NETWORK_STATIC_ROUTES_CONFIG_PAGE,
      NETWORK_STATIC_ROUTES_REPORTS_PAGE,
      NETWORK_BGP_CONFIG_PAGE,
      NETWORK_BGP_REPORTS_PAGE,
      SERVICES_PAGE,
      SERVICES_FW_CONFIG_PAGE,
      SERVICES_DIA_FW_CONFIG_PAGE,
      SERVICES_FW_REPORTS_PAGE,
      SERVICES_NAT_CONFIG_PAGE,
      SERVICES_NAT_REPORTS_PAGE,
      SERVICES_SEGMENTATION_PAGE,
      SERVICES_SEGMENTS_RULES_PAGE,
      SERVICES_SEGMENTS_PAGE,
      SERVICES_PREFERENCES_PAGE(isPackagesVisible),
      SERVICES_WEB_FILTERING_CONFIG_PAGE,
      SERVICES_WEB_FILTERING_STATISTICS_PAGE,
      WIZARDS_PAGE,
      WIZARDS_LOCATIONS_PAGE,
      WIZARDS_CONNECTIONS_PAGE,
      WIZARDS_SERVICES_PAGE,
      SEARCH_PAGE,
      IPAM_PAGE,
      EVENTS_PAGE,
      REMOTE_USERS_GROUPS_PAGE,
      REMOTE_USERS_PAGE,
    ],
    zayoAdmin: [
      NETWORK_RESOURCES_PAGE,
      SYSTEMS_PAGE,
      NETWORK_RESOURCES_IPAM_PAGE,
      SERVICE_CATALOG_PAGE,
      NETWORK_RESOURCES_SITES_PAGE,
      NETWORK_RESOURCES_EVENTS_PAGE,
      NETWORK_RESOURCES_CLOUD_RESOURCES_PAGE,
      TENANTS_PAGE,
      TENANTS_PROVISIONING_PAGE,
      PACKAGES_PAGE,
      ORDERED_PORTS_PAGE,
      USERS_PAGE,
      USERS_GROUPS_PAGE,
      INSIGHTS_PAGE,
      TOPOLOGY_PAGE,
      APP_OBSERVABILITY_PAGE,
      APP_OBSERVABILITY_DNS_PAGE,
      NETWORK_OBSERVABILITY_PAGE,
      NETWORK_OBSERVABILITY_OVERVIEW_PAGE,
      NETWORK_OBSERVABILITY_TRENDS_PAGE,
      NETWORK_OBSERVABILITY_FLOWS_PAGE,
      NETWORK_OBSERVABILITY_LATENCY_PAGE,
      NETWORK_OBSERVABILITY_GEO_PAGE,
      NETWORK_OBSERVABILITY_DETAILED_RECORDS_PAGE,
      CYBER_THREATS_PAGE,
      CYBER_THREATS_DNS_PAGE,
      CYBER_THREATS_ADVANCED_PAGE,
      NETWORK_PAGE,
      ZAYO_CONNECTIONS_PAGE,
      ZAYO_HISTORY_LOG_PAGE,
      CONNECTIVITY_PAGE,
      CONNECTIVITY_CONNECTIONS_CONFIG_PAGE,
      CONNECTIVITY_CONNECTIONS_REPORTS_PAGE,
      UNITED_CONNECTIONS_PAGE,
      CLOUD_CONNECTIVITY_PAGE,
      SDWAN_PROVIDERS_PAGE,
      CLOUD_ROUTER_PAGE,
      NETWORK_STATIC_ROUTES_CONFIG_PAGE,
      NETWORK_STATIC_ROUTES_REPORTS_PAGE,
      NETWORK_BGP_CONFIG_PAGE,
      NETWORK_BGP_REPORTS_PAGE,
      SERVICES_PAGE,
      SERVICES_FW_CONFIG_PAGE,
      SERVICES_DIA_FW_CONFIG_PAGE,
      SERVICES_FW_REPORTS_PAGE,
      SERVICES_NAT_CONFIG_PAGE,
      SERVICES_NAT_REPORTS_PAGE,
      SERVICES_SEGMENTATION_PAGE,
      SERVICES_SEGMENTS_RULES_PAGE,
      SERVICES_SEGMENTS_PAGE,
      SERVICES_PREFERENCES_PAGE(isPackagesVisible),
      SERVICES_WEB_FILTERING_CONFIG_PAGE,
      SERVICES_WEB_FILTERING_STATISTICS_PAGE,
      SEARCH_PAGE,
      IPAM_PAGE,
      EVENTS_PAGE,
      UNITED_CONNECTIONS_CONFIGURATION_PAGE,
    ],
    zayoTenant: [
      INSIGHTS_PAGE,
      TOPOLOGY_PAGE,
      APP_OBSERVABILITY_PAGE,
      SERVICE_CATALOG_PAGE,
      NETWORK_RESOURCES_SITES_PAGE,
      APP_OBSERVABILITY_DNS_PAGE,
      NETWORK_OBSERVABILITY_PAGE,
      NETWORK_OBSERVABILITY_OVERVIEW_PAGE,
      NETWORK_OBSERVABILITY_TRENDS_PAGE,
      NETWORK_OBSERVABILITY_FLOWS_PAGE,
      NETWORK_OBSERVABILITY_LATENCY_PAGE,
      NETWORK_OBSERVABILITY_GEO_PAGE,
      NETWORK_OBSERVABILITY_DETAILED_RECORDS_PAGE,
      NETWORK_PAGE,
      ZAYO_CONNECTIONS_ISOLATE_PAGE,
      ZAYO_NETWORK_SEGMENTS_PAGE,
      CONNECTIVITY_CONNECTIONS_CONFIG_PAGE,
      CLOUD_CONNECTIVITY_PAGE,
      SDWAN_PROVIDERS_PAGE,
      CLOUD_ROUTER_PAGE,
      REMOTE_USERS_PAGE,
      REMOTE_USERS_GROUPS_PAGE,
      NETWORK_STATIC_ROUTES_CONFIG_PAGE,
      NETWORK_BGP_CONFIG_PAGE,
      NETWORK_BGP_REPORTS_PAGE,
      NETWORK_ARPS_CONFIG_PAGE,
      NETWORK_PBR_CONFIG_PAGE,
      SERVICES_PAGE,
      SERVICES_FW_CONFIG_PAGE,
      SERVICES_DIA_FW_CONFIG_PAGE,
      SERVICES_FW_REPORTS_PAGE,
      SERVICES_NAT_CONFIG_PAGE,
      SERVICES_PREFERENCES_PAGE(isPackagesVisible),
      UNITED_CONNECTIONS_CONFIGURATION_PAGE,
    ],
  };

  return PAGES_MAP;
};
