import Button from "../../../components/common/buttons/Button";
import { classNames } from "../../../helpers/common/classNames";
import { getPackageServices } from "../helpers/getModifiedServicesList";
import { Package } from "../types";
import styles from "./ServicesPackages.module.scss";
import BooleanTrueIcon from "../../../components/icons/BooleanTrueIcon";
import BooleanFalseIcon from "../../../components/icons/BooleanFalseIcon";
import {
  ACCENT,
  GREY_DISABLED,
  GREY_FONT,
  WHITE,
} from "../../../helpers/common/colorAliases";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import { tenantApi } from "../../../helpers/api/TenantApi";
import { useEffect, useState } from "react";
import BlankButton from "../../../components/common/buttons/BlankButton";
import { useServicesPreferencesContext } from "../ServicesPreferencesContext";
import InfoIcon from "../../../components/icons/InfoIcon";
import { usePricingDialog } from "../../../contexts/pricingDialogContext/PricingDialogContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { PRICING_PRODUCTS } from "../../../contexts/pricingDialogContext/constants";

type ServicesPackagesProps = {
  packages: Array<Package>;
  noInfoIcon?: boolean;
  fullWidth?: boolean;
};

const Content = ({
  services,
  noInfoIcon,
  fullWidth,
}: {
  services: ReturnType<typeof getPackageServices>["services"];
  noInfoIcon?: boolean;
  fullWidth?: boolean;
}) => {
  const { selected } = useTenantContext();
  const selectedPackage =
    selected?.services_package_name ?? selected?.quota_package_name;

  const { selectService, selectedService } = useServicesPreferencesContext();

  return (
    <div className={styles.content}>
      {Object.keys(services).map((key) => (
        <div key={key} className={styles.groupWrapper}>
          <div className={styles.groupName}>{key}</div>
          <div className={styles.group}>
            {services[key].map((service) => {
              if (service.type === "ids") return null;

              const ServiceIcon = service.icon;
              const isSelected = service.type === selectedService?.type;

              return (
                <div key={service.title} className={styles.service}>
                  <div className={styles.serviceNameWrapper}>
                    <div className={styles.serviceName}>
                      <ServiceIcon color={GREY_FONT} />{" "}
                      <span>{service.title}</span>
                    </div>
                    {!noInfoIcon && (
                      <BlankButton
                        id={service.type + "_info"}
                        onClick={() =>
                          selectService(isSelected ? undefined : service)
                        }
                      >
                        <InfoIcon color={isSelected ? ACCENT : GREY_DISABLED} />
                      </BlankButton>
                    )}
                  </div>
                  <div className={styles.servicePackages}>
                    {service.data.enabled.map((servicePackage: any) => {
                      const isPackageSelected =
                        servicePackage.name === selectedPackage;

                      return (
                        <div
                          key={servicePackage.name}
                          className={classNames(
                            styles.servicePackage,
                            styles.columnWidth,
                            isPackageSelected && styles.selectedBackground,
                            fullWidth && styles.columnFullWidth
                          )}
                        >
                          {servicePackage.enabled ? (
                            <BooleanTrueIcon />
                          ) : (
                            <BooleanFalseIcon />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

const Header = ({
  packagesNames,
  fullWidth,
}: {
  packagesNames: ReturnType<typeof getPackageServices>["packagesNames"];
  fullWidth?: boolean;
}) => {
  const [isSelecting, setIsSelecting] = useState(false);
  const { selected, fetchTenant } = useTenantContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { openDialog } = usePricingDialog();
  const selectedPackage =
    selected?.services_package_name ?? selected?.quota_package_name;

  useEffect(() => {
    if (selectedTenant && selected?.name !== selectedTenant) {
      fetchTenant(selectedTenant);
    }
  }, [selectedTenant]);

  const handleChangePackage = async (newPackage: string) => {
    if (!selected?.name) return;

    setIsSelecting(true);

    const { ok: isSigned } = await openDialog(
      PRICING_PRODUCTS.TENANT_SERVICE_PACKAGE,
      {
        package: newPackage,
      }
    );

    if (!isSigned) {
      setIsSelecting(false);
      return;
    }

    await tenantApi.editTenantServicesPackage(selected?.name, {
      services_package_name: newPackage,
    });

    await fetchTenant(selected.name);
    setIsSelecting(false);
  };

  return (
    <div className={styles.header}>
      <span className={styles.headerTitle}>
        Packages
        <br /> and features
      </span>
      <div className={styles.headerNames}>
        {packagesNames.map(({ name, presentationName }) => {
          const isPackageSelected = name === selectedPackage;

          return (
            <div
              key={name}
              className={classNames(
                styles.headerName,
                styles.columnWidth,
                isPackageSelected && styles.headerNameSelected,
                isPackageSelected && styles.selectedBackground,
                fullWidth && styles.columnFullWidth
              )}
            >
              <span className={styles.packageName}>
                {presentationName || name}
              </span>
              {isPackageSelected ? (
                <Button
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onClick={() => {}}
                  disabled
                  className={classNames(styles.btn, styles.selectedBtn)}
                >
                  <BooleanTrueIcon color={WHITE} />
                </Button>
              ) : (
                <Button
                  onClick={() => handleChangePackage(name)}
                  disabled={isSelecting}
                  className={styles.btn}
                >
                  Select
                </Button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ServicesPackages = ({
  packages,
  noInfoIcon,
  fullWidth,
}: ServicesPackagesProps) => {
  const { services, packagesNames } = getPackageServices(packages);

  return (
    <div
      className={styles.wrapper}
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        "--number-of-packages": packagesNames.length,
      }}
    >
      <Header packagesNames={packagesNames} fullWidth={fullWidth} />
      <Content
        services={services}
        noInfoIcon={noInfoIcon}
        fullWidth={fullWidth}
      />
    </div>
  );
};
