import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../../../contexts/common/AbstractDialogContext";
import { System, SystemAPI, SystemNodeCreate } from "../../types";
import { createContextUtils } from "../../../../../contexts/common/utils";
import {
  ISystemProvisioningContext,
  withSystemProvisioningContextProps,
} from "../../ProvisioningContextContainer";
import { systemApi } from "../../../../../helpers/api/SystemApi";
import { LocationsListApi } from "../../../../WizardToolPage/types";
import { getEditNodesParams } from "./helpers/getEditNodesParams";
import {
  resToState,
  setPending,
} from "../../../../../helpers/common/RequestStateHelpers";
import { configApi } from "../../../../../helpers/api/ConfigApi";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<System> & {
  list: Array<string>;
  locations: Array<LocationsListApi>;
  system?: SystemAPI;
  nonAssignedNodes: Array<any>;
};

type IState = State & RequestStatuses;
type IFunc = DialogFunc<System> & {
  fetchList: () => void;
  fetchLocations: () => Promise<void>;
  getSystem: (system: string) => Promise<void>;
  editNode: (params: any) => Promise<boolean>;
  fetchNonAssignedNodes: () => Promise<void>;
};

const [SystemConfigContext, useSystemConfigContext] = createContextUtils<
  IState,
  IFunc
>();

export { useSystemConfigContext };

class SystemConfigContextContainer extends AbstractDialogContextContainer<
  SystemNodeCreate,
  IState,
  RequestStatuses,
  IFunc,
  ISystemProvisioningContext
> {
  Context = SystemConfigContext;

  _updateSystems = (ok: boolean) => {
    if (ok) {
      this.props.fetchList();
    }
  };

  fetchLocations = async (): Promise<void> => {
    const { ok, result } = await configApi.getLocationsApi();
    if (ok && result) {
      this.setState({ locations: result });
    }
  };

  getSystem = async (system: string): Promise<void> => {
    const { ok, result } = await systemApi.getSystemById(system);
    if (ok && result) {
      this.setState({ system: result });
    }
  };

  add = async (params: any): Promise<any> => {
    const newNodes = params.nodes
      .filter(({ isExisting }: any) => !isExisting)
      .map((node: any) => {
        delete node.isExisting;
        return node;
      });

    const existingNodes = params.nodes
      .filter(({ isExisting }: any) => !!isExisting)
      .map(({ name }: any) => name);
    const reqBody = {
      name: params.name,
      location: params.location,
      new_nodes: newNodes,
      existing_nodes: existingNodes,
    };

    const { ok } = await this._addWrap(() => systemApi.addSystem(reqBody));
    this._updateSystems(ok);
    return ok;
  };

  edit = async (params: any): Promise<any> => {
    const system = this.state.system;
    if (params.nodes?.length > (system?.nodes || []).length) {
      const newNode = params.nodes.find((node: any) => !node.id);
      const isExistingNode = newNode?.isExisting;
      delete newNode.isExisting;
      const { ok } = await this._editWrap(() =>
        systemApi.updateSystem(params.name, {
          status: params.status,
          add_new_nodes: isExistingNode ? [] : [newNode],
          add_existing_nodes: isExistingNode ? [newNode.name] : [],
          remove_nodes: [],
        })
      );
      this._updateSystems(ok);
      return ok;
    } else {
      const nodes = getEditNodesParams(params, system);
      const res = await Promise.all(
        nodes.map(
          async (n): Promise<any> =>
            await systemApi.updateNode(n.node, n.params)
        )
      );
      const errorRes = res.find((r) => !r.ok);
      this._updateSystems(errorRes?.ok || res[0]?.ok);
      return errorRes?.ok || res[0]?.ok;
    }
  };

  editNode = async (params: any): Promise<boolean> => {
    this.setState({ editStatus: setPending() });
    const res = await systemApi.updateNode(params.name, {
      location: params.location,
      description: params.description,
    });
    this.setState({ editStatus: resToState(res) });
    this._updateSystems(res.ok);
    return res.ok;
  };

  fetchNonAssignedNodes = async (): Promise<void> => {
    const res = await systemApi.getNonAssignedNodes();
    if (res.ok && res.result?.items?.length) {
      this.setState({
        nonAssignedNodes: res.result.items.filter((item: any) => !!item.name),
      });
    }
  };

  fetchList = async (): Promise<void> => {
    const res = await systemApi.getSystemsList();
    if (res.ok && res.result) {
      const systemsNames = res.result
        .filter((system) => system.subRows && system.subRows.length <= 1)
        .map((system) => system.name);
      this.setState({ list: systemsNames || [] });
    }
  };

  funcs = {
    fetchLocations: this.fetchLocations,
    fetchList: this.fetchList,
    edit: this.edit,
    add: this.add,
    getSystem: this.getSystem,
    editNode: this.editNode,
    fetchNonAssignedNodes: this.fetchNonAssignedNodes,
  };
}

export default withSystemProvisioningContextProps(SystemConfigContextContainer);
