/* eslint-disable react/jsx-key */
import { ReactElement } from "react";
import AWSIcon from "../../../../components/icons/cloudConnections/AWSIcon";
import AzureIcon from "../../../../components/icons/cloudConnections/AzureIcon";
import GCPIcon from "../../../../components/icons/cloudConnections/GCPIcon";
import ColoIcon from "../../../../components/icons/connections/ColoIcon";
import BranchIcon from "../../../../components/icons/connections/BranchIcon";
import GlobeWithNATIcon from "../../../../components/icons/GlobeWithNATIcon";
import GlobeIconLessLines from "../../../../components/icons/GlobeIconLessLines";
import NATIcon from "../../../../components/icons/NATIcon";
import LockIcon from "../../../../components/icons/LockIcon";
import ThreeDotsIconNew from "../../../../components/icons/ThreeDotsIconNew";
import RemoteUsersIcon from "../../../../components/icons/interfaceTypes/RemoteUsersIcon";
import LoopWithBGPIcon from "../../../../components/icons/BGPIconNew";
import GlobeWithLockIcon from "../../../../components/icons/GlobeWithLockIcon";
import LoopbackIconNew from "../../../../components/icons/LoopbackIconNew";
import IOTIcon from "../../../../components/icons/interfaceTypes/IOTIcon";
import SDWANIcon from "../../../../components/icons/interfaceTypes/SDWANIcon";
import PortBaseIcon from "../../UnitedConnections/components/Ports/icons/PortBaseIcon";
import DIAIconLabel from "../../../../components/icons/DIAIconLabel";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  BRIDGED,
  GCP_LOWERCASE,
  ROUTED,
  VIRTUAL_INTERFACE_GATE,
} from "../../../../helpers/consts";
import GlobeIcon from "../../../../components/icons/GlobeIcon";
import AwsIcon from "../../../../components/icons/interfaceTypes/AWSIcon";

const DIRECTLY_CONNECTED = "Directly connected";
const IPSEC_TUNNEL = "IPSec tunnel";

const getIPSECTextAndIcon = (label: string): [ReactElement, string] => {
  switch (label) {
    case AWS_LOWERCASE:
      return [<AWSIcon />, "AWS IPSec"];
    case AZURE_LOWERCASE:
      return [<AzureIcon />, "Azure IPSec"];
    case GCP_LOWERCASE:
      return [<GCPIcon />, "GCP IPsec"];
    case "data_center":
      return [<ColoIcon />, IPSEC_TUNNEL];
    case "branch":
      return [<BranchIcon />, IPSEC_TUNNEL];
    default:
      return [<LockIcon />, IPSEC_TUNNEL];
  }
};

// for virtual_interface_type = routed && Virtual Interface Tenant Bridge Domain type
const getRoutedAndOrIcon = (
  label = "",
  x?: number,
  y?: number
): [ReactElement, string] => {
  switch (label) {
    case AWS_LOWERCASE:
      return [<AWSIcon x={x} y={y} />, "AWS Direct Connect"];
    case AZURE_LOWERCASE:
      return [<AzureIcon x={x} y={y} />, "Azure Express Route"];
    case GCP_LOWERCASE:
      return [<GCPIcon x={x} y={y} />, "GCP Interconnect"];
    case "iot":
      return [<IOTIcon x={x} y={y} />, "QoS"];
    case "sdwan":
      return [<SDWANIcon x={x} y={y} />, "SDWAN"];
    case "remote_users":
      return [<RemoteUsersIcon x={x} y={y} />, "Remote Users"];
    case "data_center":
      return [<ColoIcon x={x} y={y} />, DIRECTLY_CONNECTED];
    case "branch":
      return [<BranchIcon x={x} y={y} />, DIRECTLY_CONNECTED];
    default:
      return [<PortBaseIcon x={x} y={y} />, DIRECTLY_CONNECTED];
  }
};

export const getGateTextAndIcon = (
  is_ipsec_service?: boolean,
  is_nat_service?: boolean,
  is_snat_service?: boolean,
  is_dia?: boolean,
  is_internet?: boolean,
  x?: number,
  y?: number
): [ReactElement, string] => {
  if (is_internet) {
    return [<GlobeIcon x={x} y={y} width={24} height={24} />, "Internet"];
  }
  if (is_dia) {
    return [
      <DIAIconLabel x={x} y={y} width={24} height={24} />,
      "Direct Internet",
    ];
  }
  if (is_snat_service && !is_nat_service && !is_ipsec_service) {
    return [
      <GlobeWithNATIcon x={x} y={y} width={24} height={24} />,
      "Internet Access",
    ];
  }
  if ((is_nat_service || is_snat_service) && is_ipsec_service)
    return [
      <GlobeIconLessLines x={x} y={y} width={24} height={24} />,
      "Internet Gate",
    ];
  if (is_ipsec_service)
    return [<GlobeWithLockIcon x={x} y={y} />, "IPSec Gate"];
  return [<NATIcon x={x} y={y} />, "NAT Gate"];
};

export const getTextAndIconByVirtualInterfaceType = (
  type: string,
  label?: string,
  x?: number,
  y?: number
): [ReactElement, string] => {
  switch (type) {
    case "loopback":
      return label === "bgp"
        ? [<LoopWithBGPIcon x={x} y={y} />, "BGP source"]
        : [<LoopbackIconNew x={x} y={y} />, "Loopback Interface"];
    case BRIDGED:
      if (label === AWS_LOWERCASE) {
        return [<AwsIcon x={x} y={y} />, "AWS Direct Connect"];
      }
      if (label === AZURE_LOWERCASE) {
        return [<AzureIcon x={x} y={y} />, "Azure"];
      }
      if (label === GCP_LOWERCASE) {
        return [<GCPIcon x={x} y={y} />, "GCP"];
      }
      return [<ThreeDotsIconNew x={x} y={y} />, label || "Ethernet Link"];
    case ROUTED:
      return getRoutedAndOrIcon(label, x, y);
    default:
      return [<ThreeDotsIconNew x={x} y={y} />, ""];
  }
};

export const getIconAndText = (
  type: string,
  vit?: string,
  remote_type?: string,
  is_ipsec_service?: boolean,
  is_nat_service?: boolean,
  is_snat_service?: boolean,
  x?: number,
  y?: number,
  is_dia?: boolean,
  is_internet?: boolean
): [ReactElement, string] => {
  if (vit) {
    return getTextAndIconByVirtualInterfaceType(vit, remote_type, x, y);
  } else {
    if (type === VIRTUAL_INTERFACE_GATE) {
      return getGateTextAndIcon(
        is_ipsec_service,
        is_nat_service,
        is_snat_service,
        is_dia,
        is_internet,
        x,
        y
      );
    } else {
      return getIPSECTextAndIcon(remote_type || "");
    }
  }
};
