import { CellProps } from "react-table";
import Label from "../../../components/common/table/Label";
import LabelArray from "../../../components/common/table/LabelArray";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import VirtInterfaceIcon from "../../../components/icons/VirtInterfaceIcon";
import { Layer3Interface } from "../types";
import {
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
  checkIP,
  checkIPv6,
} from "../../../helpers/validators/Validator";
import styles from "./layer3InterfaceTableHeader.module.scss";

export const layer3InterfaceTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }: CellProps<Layer3Interface>) => {
        if (row.values.is_dia) {
          return (
            <TextWithIcon icon={<VirtInterfaceIcon />} text={row.values.name} />
          );
        } else {
          return (
            <TextWithIcon icon={<VirtInterfaceIcon />} text={row.values.name} />
          );
        }
      },
    },
    {
      Header: "Type",
      accessor: "virtual_interface_type",
    },
    {
      Header: "IP",
      accessor: "ip_addresses",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        const ipv4 = value?.filter(
          (val: string) => checkIPv4WithPrefix(val) || checkIP(val)
        );
        const ipv6 = value?.filter(
          (val: string) => checkIPv6WithPrefix(val) || checkIPv6(val)
        );
        return (
          <div>
            <LabelArray values={ipv4} withWrap={true} />
            <LabelArray
              values={ipv6}
              singleLabelClassname={styles.ips}
              withWrap={true}
            />
          </div>
        );
      },
    },
    {
      Header: "L2 Members",
      accessor: "members",
      Cell: ({ cell: { value } }: CellProps<Layer3Interface>) => {
        return value?.length > 0 ? (
          <LabelArray values={value} />
        ) : (
          <Label>—</Label>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof Layer3Interface,
  }));
};
