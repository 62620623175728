import React, { FC, HTMLAttributes } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./Button.module.scss";
import ProgressIcon from "../../icons/progressIcon/ProgressIcon";

export type ButtonProps = {
  isPrimaryBtn?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: (arg: any) => void;
  children: React.ReactNode;
  isBtnSmall?: boolean;
  isLoading?: boolean;
} & Omit<HTMLAttributes<HTMLButtonElement>, "onClick">;

const Button: FC<ButtonProps> = ({
  isPrimaryBtn,
  className,
  onClick,
  children,
  disabled,
  isBtnSmall,
  isLoading,
  ...buttonAttributes
}) => {
  const btnContainer = classNames(
    styles.btn,
    isPrimaryBtn && styles.primaryBtn,
    disabled && styles.disabledBtn,
    isBtnSmall && styles.smallBtn,
    className
  );

  return (
    <button
      className={btnContainer}
      onClick={onClick}
      disabled={disabled}
      {...buttonAttributes}
    >
      {isLoading ? <ProgressIcon /> : children}
    </button>
  );
};

export default Button;
