/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { DDoSType, FilterAndSort } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import AbstractCrudContextContainer, {
  createContextAndUseWithExtra,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";
import withTimerangeContext from "../../helpers/hocs/withTimerangeContext";
import { RequestStatus, TimerangeContextType } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";

type IState = CrudState<DDoSType, DDoSType> & {
  ddosList: Array<DDoSType>;
  ddosListStatus: RequestStatus;
  lastFetchedTenant: string;
  selectedDDoS: DDoSType | undefined;
};

type Addition = { system?: string; vrf?: string };

type IFunc = {
  fetchList: (addition?: Addition) => void;
  selectDDoS: (ddos: DDoSType | undefined, addition?: Addition) => void;
  changeDDoSEnable: (ddos: DDoSType | undefined, addition?: Addition) => void;
  removeDDoS: (ddos: DDoSType, addition?: Addition) => void;
};

export type DDoSDeleteArgs = DDoSType & {
  tenant: string;
};

const [Context, useContext] = createContextAndUseWithExtra<
  IState,
  IFunc & CrudFunc<DDoSType, DDoSType, Addition>,
  TimerangeContextType
>();

export const useDDoSContext = useContext;

type Props = React.PropsWithChildren<{
  timerangeContext: TimerangeContextType;
}>;

class DDoSContextContainer extends AbstractCrudContextContainer<
  DDoSType,
  DDoSType,
  IState,
  DDoSType
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.funcs = {
      fetchList: this.fetchList,
      selectDDoS: this.selectDDoS,
      changeDDoSEnable: this.changeDDoSEnable,
      removeDDoS: this.removeReq,
    };
  }

  changeDDoSEnable = async (
    ddos: DDoSType | undefined,
    addition?: Addition
  ) => {
    if (ddos) {
      await this.editReq(
        { enable: !ddos.enable, priority: ddos.priority },
        ddos.name,
        addition
      );
    }
  };

  selectDDoS = async (ddos: DDoSType | undefined) => {
    this.setState({ selectedDDoS: ddos });
  };

  listReq = async (params?: FilterAndSort, addition?: Addition) => {
    const { system = "", vrf = "" } = addition ?? { system: "", vrf: "" };
    const res = await configApi.getDDoSList(system, vrf);
    // TEMP SOLUTION FOR SORTING
    if (res.ok) {
      const sortedResult = res.result?.sort(
        (prev, next) => prev.priority - next.priority || prev.id - next.id
      );
      return { ...res, result: sortedResult };
    }
    return res;
  };

  fetchList = async (addition?: Addition): Promise<void> => {
    this.setState({ ddosListStatus: setPending("Fetching") });
    const res = await this.listReq(undefined, addition);

    if (res.ok && res.result) {
      this.setState({ ddosList: res.result });
    }
    this.setState({
      ddosListStatus: resToState(res),
    });
  };

  selectedReq = async (ip_flood?: string | number, addition?: Addition) => {
    const { system = "", vrf = "" } = addition ?? { system: "", vrf: "" };
    return await configApi.getDDoS(system, vrf, (ip_flood ?? "").toString());
  };

  addReq = async (params: Partial<DDoSType>, addition?: Addition) => {
    const { system = "", vrf = "" } = addition ?? { system: "", vrf: "" };
    const res = await configApi.addDDoS(system, vrf, params);
    if (res.ok) {
      await this.fetchList(addition);
    }
    return res;
  };

  removeReq = async (ddos: DDoSType, addition?: Addition) => {
    const { system = "", vrf = "" } = addition ?? { system: "", vrf: "" };
    const res = await configApi.deleteDDoS(system, vrf, ddos.name.toString());
    if (res.ok) {
      await this.fetchList(addition);
    }
    return res;
  };

  editReq = async (
    params: Partial<DDoSType>,
    ip_flood: string | number,
    addition?: Addition
  ) => {
    const { system = "", vrf = "" } = addition ?? { system: "", vrf: "" };
    const res = await configApi.editDDoS(
      system,
      vrf,
      ip_flood.toString(),
      params
    );
    if (res && res.ok) {
      await this.fetchList(addition);
    }
    return res;
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.baseFuncs,
          ...this.funcs,
          ...this.props.timerangeContext,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default withTimerangeContext(DDoSContextContainer);
