import { FC } from "react";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./TabsBlock.module.scss";
import { classNames } from "../../../../../helpers/common/classNames";

type Props = {
  tabs: Array<string>;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  showDialog?: boolean;
  color?: "orange" | "blue";
};

const TabsBlock: FC<Props> = ({
  tabs,
  activeTab,
  setActiveTab,
  showDialog,
  color,
}) => {
  return (
    <div className={styles.block}>
      <div className={styles.wrapper}>
        {tabs.map((tab) => {
          const isActive = tab === activeTab;
          const style = classNames(
            styles.tab,
            isActive && styles.active,
            isActive && color && styles[color]
          );
          return (
            <div className={style} onClick={() => setActiveTab(tab)} key={tab}>
              <div
                className={classNames(
                  styles.label,
                  isActive && showDialog && styles.showDialog
                )}
              >
                {tab}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabsBlock;
