import {
  CreateFWRule,
  MAX_FW_PRIORITY_FOR_MANUAL_CREATION,
} from "../../pages/Firewall/config/FirewallRuleConfig";
import Validator from "./Validator";

export default function validateFirewallNewRule(
  data: CreateFWRule,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.name, "name");
  validator.checkMinMax(
    data.priority,
    [1, MAX_FW_PRIORITY_FOR_MANUAL_CREATION],
    "priority"
  );

  data.name && validator.checkLength(data.name, [1, 128], "name");

  data.name && validator.checkNameFormat(data.name, "name");

  data.src_network &&
    validator.checkIPWithPrefixAll(data.src_network, "src_network");
  data.dst_network &&
    validator.checkIPWithPrefixAll(data.dst_network, "dst_network");

  return validator;
}
