/* eslint-disable css-modules/no-unused-class */
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside";
import { DropdownItem } from "../../../helpers/types";
import { OptionalLabel } from "../OptionalLabel";
import DropdownInput from "./components/DropdownInput";
import DropdownList from "./components/DropdownList";
import Error from "./components/Error";

import styles from "./DropdownBasic.module.scss";
import IconBlankButton from "../buttons/IconBlankButton";
import WarningIcon from "../../icons/WarningIcon";
import { mapStringToItem } from "../../../helpers/mapStringToItem";

export type DropDownBasicProps<D> = {
  itemsList:
    | Array<DropdownItem<D>>
    | { [key: string]: Array<DropdownItem<D>> }
    | undefined;
  onChange?: (item: DropdownItem<D>) => void;
  className?: string;
  wrapperClassName?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  selected?: DropdownItem<D> | string;
  isSmaller?: boolean;
  isMedium?: boolean;
  isNotFormElement?: boolean;
  icon?: ReactElement;
  isPseudoBtnMode?: boolean;
  selectedValues?: any;
  isListEditable?: boolean;
  createText?: string;
  createTextClassName?: string;
  withInput?: boolean;
  onCreate?: (val?: string) => void;
  disabled?: boolean;
  isPlaceholderAlwaysVisible?: boolean;
  isNotEditable?: boolean;
  isRightCorner?: boolean;
  id: string;
  onEditOption?: (key?: string) => void;
  onDeleteOption?: (key?: string) => void;
  spanClassName?: string;
  listClassName?: string;
  onClick?: () => void;
  withClearIcon?: boolean;
  forcedClear?: boolean;
  errorWithTooltip?: boolean;
  hideEmptyListPlaceholder?: boolean;
  isLoading?: boolean;
};

function DropdownBasic<D>(props: DropDownBasicProps<D>): ReactElement {
  const {
    onChange,
    itemsList,
    className,
    label,
    error,
    selected: selectedValue,
    isSmaller,
    isMedium,
    isNotFormElement,
    placeholder,
    icon,
    isPseudoBtnMode,
    isListEditable,
    createText,
    onCreate,
    onEditOption,
    onDeleteOption,
    disabled,
    isPlaceholderAlwaysVisible,
    isNotEditable,
    isRightCorner,
    id,
    spanClassName,
    listClassName,
    onClick,
    createTextClassName,
    withInput,
    withClearIcon,
    forcedClear,
    errorWithTooltip,
    hideEmptyListPlaceholder,
    wrapperClassName,
    isLoading,
    ...restProps
  } = props;
  const [isListShown, setIsListShown] = useState(false);

  const dropDownRef = useClickOutside<HTMLDivElement>(() =>
    setIsListShown(false)
  );

  const handleSelect = useCallback(
    (item): void => {
      onChange?.(item);
      setIsListShown(false);
    },
    [onChange, setIsListShown]
  );

  const selected = useMemo(() => {
    if (typeof selectedValue === "string") {
      return mapStringToItem(selectedValue);
    }

    return selectedValue;
  }, [selectedValue]);

  return (
    <OptionalLabel
      className={classNames(
        className,
        styles.dropDownLabel,
        errorWithTooltip && styles.errorWithTooltip
      )}
      isShown={Boolean(label)}
      text={label}
      medium={isMedium}
      {...restProps}
    >
      <div data-cy="input-wrapper" style={{ width: "100%" }}>
        <div
          ref={dropDownRef}
          className={classNames(styles.dropDownWrapper, wrapperClassName)}
        >
          <DropdownInput
            id={`dropBasic-${id}`}
            onClick={() => {
              setIsListShown((prevState) => !prevState);
              onClick?.();
            }}
            isSmaller={isSmaller}
            isMedium={isMedium}
            isPseudoBtnMode={isPseudoBtnMode}
            icon={icon}
            isListShown={isListShown}
            selected={selected}
            placeholder={placeholder}
            isError={Boolean(error)}
            disabled={disabled}
            isPlaceholderAlwaysVisible={isPlaceholderAlwaysVisible}
            isNotEditable={isNotEditable}
            withClearIcon={withClearIcon}
            forcedClear={forcedClear}
            onChange={onChange}
          />
          <DropdownList
            valuesList={itemsList || undefined}
            isListShown={isListShown}
            handleSelect={handleSelect}
            isSmaller={isSmaller}
            isMedium={isMedium}
            isNotFormElement={isNotFormElement}
            isListEditable={isListEditable}
            createText={createText}
            createTextClassName={createTextClassName}
            onCreate={onCreate}
            withInput={withInput}
            setIsListShown={setIsListShown}
            isRightCorner={isRightCorner}
            onEditOption={onEditOption}
            onDeleteOption={onDeleteOption}
            spanClassName={spanClassName}
            className={listClassName}
            hideEmptyListPlaceholder={hideEmptyListPlaceholder}
            isLoading={isLoading}
          />
        </div>
        {error && !errorWithTooltip && <Error error={error} />}
        {error && errorWithTooltip && (
          <IconBlankButton
            className={styles.errorIcon}
            id={`error-${id as string}`}
            icon={WarningIcon}
            color="var(--orange)"
            hoverColor="var(--orange)"
            hint={error}
          />
        )}
      </div>
    </OptionalLabel>
  );
}

export default DropdownBasic;
