import { TenantCustomerPort } from "../../types";

export const getGroupedPortsList = (
  ports: Array<TenantCustomerPort>
): { [key: string]: Array<TenantCustomerPort> } => {
  return ports.reduce(
    (
      acc: { [key: string]: Array<TenantCustomerPort> },
      port: TenantCustomerPort
    ) => {
      const { location } = port;
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(port);
      return acc;
    },
    {}
  );
};
