import { CellProps } from "react-table";
import DIAIconLabel from "../../components/icons/DIAIconLabel";
import NaaSIconLabel from "../../components/icons/NaaSIconLabel";
import LabelArray from "../../components/common/table/LabelArray";
import Label from "../../components/common/table/Label";
import styles from "./Table.columns.module.scss";

export const IpamTableColumns = (): Array<any> => {
  return [
    {
      accessor: "id",
      Header: "ID",
    },
    {
      accessor: "iconType",
      Header: "",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        if (value === "dia") {
          return <DIAIconLabel />;
        } else return <NaaSIconLabel />;
      },
    },
    {
      accessor: "name",
      Header: "Pool Name",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return <div>{value || ""}</div>;
      },
    },
    {
      accessor: "from_ip",
      Header: "Start IP / IP Prefix",
    },
    {
      accessor: "to_ip",
      Header: "End IP",
    },
    {
      accessor: "allocated",
      Header: "Allocated Prefixes (Min-Max)",
    },
    {
      accessor: "data",
      Header: "System",
    },
    {
      accessor: "vrf_name",
      Header: "VRF",
    },
    {
      accessor: "services",
      Header: "Naas Services",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return <LabelArray isOutlined values={value} />;
      },
    },
    {
      accessor: "usage",
      Header: "Usage (in use/total)",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        if (value) {
          return (
            <div>
              {Object.keys(value).map((key) => (
                <div key={key} className={styles.usage}>
                  <span>Pool /{key}:</span>
                  <Label>
                    {value[key].used} / {value[key].total}
                  </Label>
                </div>
              ))}
            </div>
          );
        } else {
          return <div>{""}</div>;
        }
      },
    },
    {
      accessor: "tenants",
      Header: "Tenants",
    },
    {
      accessor: "shared",
      Header: "Shared",
    },
  ];
};
