import { SiteRegion } from "../../../../../../helpers/api/apiTypes";
import { CreateAzureNNI } from "../../../../types";

export const createAzureSiteObject = (
  fields: CreateAzureNNI,
  primarySystemLocation: string
): SiteRegion => {
  return {
    name: `Azure ${fields.peering_location}`,
    connection_type: "Cloud",
    operator: "azure",
    address: fields.peering_location,
    cloud_region: fields.peering_location,
    network_status: "off",
    handover_bw: [
      {
        bw: fields.max_bandwidth * 1000,
        hw: "fiber",
      },
    ],
    naas_location: primarySystemLocation || "string",
    naas_systems: [fields.primary_system_name],
    labels: {
      additionalProp1: "string",
      additionalProp2: "string",
      additionalProp3: "string",
    },
  };
};
