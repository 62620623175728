import { FC, useState } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./PortsStat.module.scss";
import { usePortsContext } from "../PortsContext";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import PortIcon from "../../../components/icons/PortIcon";
import { getColorFromStatus } from "../../../helpers/common/colorHelpers";
import { TrafficType } from "../../../helpers/types";
import GrafanaGraph from "../../../components/common/charts/GrafanaGraph";
import ChartToppersTabs from "../../../components/common/ChartToppersTabs";
import { GTimeRange } from "../../../components/common/charts/GraphanaLinksFactory";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
import { useTimerangeContext } from "../../../contexts/TimerangeContext";
import { PageLoader } from "../../../components/common/loadStates/LoaderIcon";
import TimerangOverlayComponent from "../../../components/common/timerange/TimerangeOverlayComponent";
import { BPS } from "../../../helpers/common/constantsAlias";

type Props = { className: string };

const PortsStat: FC<Props> = ({ className }) => {
  const { system } = useOneSystemContext();
  const { timeRange } = useTimerangeContext();
  const { selectedPort } = usePortsContext();
  const [activeTraffic, setActiveTraffic] = useState<TrafficType>(BPS);

  if (!system) return <PageLoader />;

  const status =
    selectedPort?.administrative_state === "up" &&
    selectedPort?.operational_state === "up"
      ? "up"
      : "down";
  const bgColor = status === "up" ? "#E6F9F2" : "#fde4ec";
  const fontColor = getColorFromStatus(status);

  const isBPS = activeTraffic === BPS;
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.title}>
        <TextWithIcon
          icon={
            <PortIcon
              color={
                selectedPort
                  ? getColorFromStatus(selectedPort?.administrative_state)
                  : undefined
              }
            />
          }
          text={selectedPort?.alias || selectedPort?.name || "Total Statistics"}
        />
        {selectedPort && (
          <div
            className={styles.statusLabel}
            style={{ backgroundColor: bgColor, color: fontColor }}
          >
            {selectedPort?.administrative_state}/
            {selectedPort?.operational_state}
          </div>
        )}
      </div>
      <div className={styles.controls}>
        <TimerangOverlayComponent
          id={"portStat" + selectedPort?.id}
          className={styles.timeRange}
        />
        <ChartToppersTabs
          activeTab={activeTraffic}
          setTabActive={setActiveTraffic}
        />
      </div>
      <div className={styles.charts}>
        <GrafanaGraph
          link={grafanaLink(
            timeRange.general,
            system.name,
            getPanel(isBPS, !!selectedPort),
            selectedPort?.name
          )}
        />
        {!selectedPort && (
          <GrafanaGraph
            link={grafanaLink(timeRange.general, system.name, isBPS ? 8 : 10)}
          />
        )}
      </div>
    </div>
  );
};

export default PortsStat;

const grafanaLink = (
  timeRange: GTimeRange,
  system: string,
  panel?: number,
  portName?: string
) => {
  if (!panel) return "";
  const { from, to } = timeRange;
  const name = portName?.replace(/\//g, "%2F");
  const port = name ? `&var-port_name=${name}` : "";

  const link = `/broker/grafana/d-solo/gdkgFbj4k1/physical-ports?orgId=1${port}&var-System_name=${system}&from=${from}&to=${to}&theme=light&panelId=${panel}`;

  return link;
};

const getPanel = (isBps: boolean, isPort: boolean): number | undefined => {
  switch (true) {
    // BPS and port
    case isBps && isPort:
      return 5;
    // PPS and port
    case !isBps && isPort:
      return 6;
    // BPS and all in
    case isBps && !isPort:
      return 7;
    // PPS and all in
    case !isBps && !isPort:
      return 9;
    default:
      return undefined;
  }
};
