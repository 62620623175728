import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ReleaseIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9543 0.816406L15.2785 4.20063L10.9543 7.58469V5.51669C7.32465 5.51669 4.37405 8.46728 4.37405 12.097C4.37405 13.8578 5.06301 15.5128 6.30668 16.7571L4.45104 18.6206C2.70332 16.8782 1.74194 14.5619 1.74194 12.097C1.74194 7.01765 5.87501 2.88458 10.9543 2.88458V0.816406ZM15.602 7.43658L17.4576 5.57304C19.2054 7.3155 20.1667 9.63176 20.1667 12.0967C20.1667 17.176 16.0337 21.3091 10.9544 21.3091V23.3772L6.64522 19.9931L10.9544 16.609V18.677C14.584 18.677 17.5346 15.7264 17.5346 12.0967C17.5346 10.3358 16.8457 8.68091 15.602 7.43658Z"
        fill={color || FONT}
        stroke={color || FONT}
      />
    </svg>
  );
}

export default ReleaseIcon;
