import { FC, useEffect, useMemo, useState } from "react";
import VPCContextContainer, { useVPCContext } from "./VPCContext";
import DropDownInput, {
  mapStringToItem,
} from "../../../../../../../components/common/formComponents/DropDownInput";
import { withContexts } from "../../../../../../../helpers/hocs/withContexts";
import Input, {
  types,
} from "../../../../../../../components/common/formComponents/Input";
import styles from "./styles.module.scss";
import LocationIcon from "../../../../../../../components/icons/LocationIcon";
import { REGIONS } from "../../utils/regions";
import CaretOutline from "../../../../../../../components/icons/CaretOutline";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import InputWithStatus from "../../../../../../../components/common/InputWithStatus/InputWithStatus";
import { AWSAccessKey } from "../../../../../types";
import { EDIT } from "../../../../../../../helpers/common/constantsAlias";
import { TokenPlug } from "../TokenPlug";
import { useValidation } from "../../../../../../../helpers/validators/Validator";
import { CloudCredentialsFields } from "../../../../../../CloudsConnectivityPage/components/CloudCredentials";
import validateCloudCredentials from "../../../../../../../helpers/validators/CloudCredentialsValidator";
import CloudsConnectivityContext, {
  useCloudsConnectivityContext,
} from "../../../../../../CloudsConnectivityPage/CloudsConnectivityContext";
import { classNames } from "../../../../../../../helpers/common/classNames";
import { AWS_LOWERCASE } from "../../../../../../../helpers/consts";

type Props = {
  onChange: any;
  fields: any;
  error?: string;
  mode?: string;
};

const DEFAULT_FIELDS: AWSAccessKey = {
  access_key_id: "",
  secret_access_key: "",
  session_token: "",
  description: "",
};

const VPCList: FC<Props> = ({
  fields: externalFields,
  onChange,
  error,
  mode,
}) => {
  const { user } = useUserContext();
  const { list = [], fetchList, listStatus } = useVPCContext();
  const {
    tokens,
    correctTokens,
    getAccessKeys,
    deleteAccessKey,
    createAccessKey,
  } = useCloudsConnectivityContext();

  const [fields, handleFieldChange, resetFields] = useFormField(DEFAULT_FIELDS);
  const [showList, setShowList] = useState<boolean>(false);
  const [errors, validate] = useValidation<CloudCredentialsFields>(
    validateCloudCredentials,
    [fields]
  );

  const isEdit = useMemo(() => mode === EDIT, [mode]);
  const type = AWS_LOWERCASE;
  const isToken = correctTokens?.[type];

  useEffect(() => {
    getAccessKeys(user.name, type);

    return () => {
      resetFields(DEFAULT_FIELDS);
    };
  }, []);

  useEffect(() => {
    if (tokens && type) {
      const fields = tokens[type];
      if (fields) {
        correctTokens?.[type]
          ? resetFields(fields)
          : resetFields(DEFAULT_FIELDS);
      }
    }
  }, [tokens, type, correctTokens]);

  const handleFetchDiscovery = () => {
    if (externalFields.region && isToken) {
      setShowList(true);
      fetchList(externalFields.region, user.name);
    }
  };

  const handleCheckBlur = async () => {
    if (fields.access_key_id && fields.secret_access_key) {
      const { isOk } = validate();
      if (isOk) {
        if (tokens?.[type]) await deleteAccessKey(user.name, type);
        await createAccessKey(fields, user.name, type);
      }
    }
  };

  const regionsList = useMemo(() => (isToken ? REGIONS[AWS_LOWERCASE] : []), [
    isToken,
  ]);

  const getTokenInputs = () => {
    const tokenIsExpired = tokens?.[type] && !correctTokens?.[type];
    const titleStyle = classNames(
      styles.title,
      tokenIsExpired && styles.orange
    );
    return (
      <div className={styles.tokenFields}>
        <div className={titleStyle}>
          {tokenIsExpired
            ? "Token is expired/incorrect. Please update:"
            : "Token"}
        </div>
        <div className={styles.field}>
          <Input
            value={fields.access_key_id}
            onChange={(e) => handleFieldChange("access_key_id", e.target.value)}
            isFullValue
            medium
            error={errors?.access_key_id}
            placeholder="Access key"
            onBlur={handleCheckBlur}
            type={types.PASSWORD}
          />
          <Input
            value={fields.secret_access_key}
            onChange={(e) =>
              handleFieldChange("secret_access_key", e.target.value)
            }
            isFullValue
            medium
            error={errors?.secret_access_key}
            placeholder="Secret key"
            onBlur={handleCheckBlur}
            type={types.PASSWORD}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {!isToken || !correctTokens?.[type] ? getTokenInputs() : <TokenPlug />}
      <DropDownInput
        id={"VPCListCloudRegion"}
        selected={mapStringToItem(externalFields.region)}
        isMedium
        onChange={(val) => onChange("region", val.key)}
        label="Cloud region"
        itemsList={regionsList.map(mapStringToItem)}
        icon={<LocationIcon />}
        customDropdownIcon={
          externalFields.region ? <CaretOutline /> : undefined
        }
        error={showList && error ? "" : error}
        onBlur={handleFetchDiscovery}
      />
      {isEdit && (
        <DropdownBasic
          id={"VPCListVPC"}
          onChange={(val) => onChange("vpc_id", val.key)}
          selected={mapStringToItem(externalFields.vpc_id)}
          label="VPC"
          itemsList={list.map((key) => mapStringToItem(key))}
          isMedium
          error={listStatus?.state === "error" ? listStatus?.message : error}
        />
      )}
      {showList && !isEdit && (
        <InputWithStatus
          status={listStatus}
          pendingMessage={"Discovering VPCs"}
        >
          <DropdownBasic
            id={"VPCListVPC"}
            onChange={(val) => onChange("vpc_id", val.key)}
            selected={mapStringToItem(externalFields.vpc_id)}
            label="VPC"
            itemsList={list.map((key) => mapStringToItem(key))}
            isMedium
            error={listStatus?.state === "error" ? listStatus?.message : error}
          />
        </InputWithStatus>
      )}
    </>
  );
};

export default withContexts<Props>(VPCList, [
  VPCContextContainer,
  CloudsConnectivityContext,
]);
