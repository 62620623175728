import React, { FC, useState } from "react";
import GrafanaGraph from "../../components/common/charts/GrafanaGraph";
import LayoutComponent from "../../components/common/LayoutComponent";
import { TenantsTab } from "../../components/common/navigation/Tenants/TenantsTab";
import ButtonsGroup, {
  Tab,
} from "../../components/common/buttons/ButtonsGroup";
import ChartsHeader from "../../components/common/charts/ChartsHeader/ChartsHeader";

const BILLING_CATALOG = "Billing Catalog";
const TERMS_AND_CONDITIONS = "Terms and Conditions";
const SIGNED_TERMS = "Signed Terms";

const ServiceCatalogPage: FC<{ tabs: any }> = ({ tabs }) => {
  const BASE_GRAFANA_LINK =
    "/broker/grafana/d-solo/-U-VYZMHk/service-dashboard?orgId=1&theme=light";
  const TABS = [
    { name: BILLING_CATALOG, icon: undefined },
    { name: TERMS_AND_CONDITIONS, icon: undefined },
    { name: SIGNED_TERMS, icon: undefined },
  ];
  const TAB_TO_PANEL_ID_MAP = {
    [BILLING_CATALOG]: "2",
    [TERMS_AND_CONDITIONS]: "4",
    [SIGNED_TERMS]: "6",
  };

  type TabNames = keyof typeof TAB_TO_PANEL_ID_MAP;

  const [activeTab, setActiveTab] = useState<TabNames>(BILLING_CATALOG);
  const [activePanelId, setActivePanelId] = useState<string>(
    TAB_TO_PANEL_ID_MAP[BILLING_CATALOG]
  );
  const handleTabSelect = (tab: Tab) => {
    if (tab.name in TAB_TO_PANEL_ID_MAP) {
      setActiveTab(tab.name as TabNames);
      setActivePanelId(TAB_TO_PANEL_ID_MAP[tab.name as TabNames]);
    }
  };

  return (
    <LayoutComponent>
      <TenantsTab />
      <ChartsHeader
        title={""}
        controls={
          <ButtonsGroup
            tabs={TABS}
            activeTab={activeTab}
            onChange={handleTabSelect}
            withIcon
          />
        }
      />
      <GrafanaGraph link={`${BASE_GRAFANA_LINK}&panelId=${activePanelId}`} />
    </LayoutComponent>
  );
};

export default ServiceCatalogPage;
