import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Filter } from "./filterTypes";
import styles from "./FilterForm.module.scss";
import PlusIcon from "../../../icons/PlusIcon";
import { ACCENT } from "../../../../helpers/common/colorAliases";
import { filterFieldsStyles } from "./filterFieldsStyles";

interface FilterFormProps {
  onAddFilter: (filter: Filter) => void;
  availableFields: Array<{ label: string; value: string }>;
  filtersData: any;
  isFormOpened: boolean;
  toggleForm: any;
}

const conditionOptions = [
  { label: "is", value: "is" },
  { label: "is not", value: "is not" },
];

const FilterForm: React.FC<FilterFormProps> = ({
  onAddFilter,
  availableFields,
  filtersData,
  isFormOpened,
  toggleForm,
}) => {
  const [selectedField, setSelectedField] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [condition, setCondition] = useState<{
    label: string;
    value: string;
  }>({ label: "is", value: "is" });
  const [valueOptions, setValueOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [selectedValue, setSelectedValue] = useState<{
    label: string;
    value: string;
  } | null>(null);

  useEffect(() => {
    if (selectedField && filtersData) {
      // Dynamic generation of filter values
      const fieldValues = filtersData[selectedField.value];
      if (Array.isArray(fieldValues)) {
        const options = fieldValues.map((val: string) => ({
          label: val,
          value: val,
        }));
        setValueOptions(options);
      } else {
        setValueOptions([]);
      }
    }
  }, [selectedField, filtersData]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (selectedField && selectedValue) {
      onAddFilter({
        field: selectedField.value,
        condition: condition.value,
        value: selectedValue.value,
      });
      setSelectedField(null);
      setCondition({ label: "is", value: "is" });
      setSelectedValue(null);
      setValueOptions([]);
    }
  };

  return (
    <div className={styles.filterIcon}>
      <div className={styles.plusSign} onClick={toggleForm}>
        {<PlusIcon color={ACCENT} />}
      </div>

      {isFormOpened && (
        <form onSubmit={handleSubmit} className={styles.filterForm}>
          <Select
            value={selectedField}
            onChange={(selectedOption) => {
              setSelectedField(
                selectedOption as { label: string; value: string }
              );
              setSelectedValue(null);
            }}
            options={availableFields}
            placeholder="Select a field"
            styles={filterFieldsStyles}
            menuPlacement="auto"
          />
          <Select
            value={condition}
            onChange={(selectedOption) =>
              setCondition(selectedOption as { label: string; value: string })
            }
            options={conditionOptions}
            styles={filterFieldsStyles}
            menuPlacement="auto"
          />
          <Select
            value={selectedValue}
            onChange={(selectedOption) =>
              setSelectedValue(
                selectedOption as { label: string; value: string }
              )
            }
            options={valueOptions}
            placeholder={
              !valueOptions.length ? "No possible values" : "Select a value"
            }
            styles={filterFieldsStyles}
            menuPlacement="auto"
            isDisabled={!valueOptions.length}
          />
          <button type="submit" className={styles.addButton}>
            Add Filter
          </button>
        </form>
      )}
    </div>
  );
};

export default FilterForm;
