import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./InventoryBlock.module.scss";
import BlockTitle from "./common/BlockTitle";
import ContentBlock from "./common/ContentBlock";
import ServicesTabInfo from "./Services/ServicesTabInfo";
import ConnectionsContextContainer, {
  useConnectionsContext,
} from "../../../WizardToolPage/ConnectionStep/ConnectionsContext";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import PortsTabInfo from "./Ports/PortsTabInfo";
import PublicIPs, { cleanAndSplitIPs } from "./PublicIPs/PublicIPs";
import OrderLog from "./OrderLog/OrderLog";
import { ConnectionContextContainer } from "../../Connections/ConnectionContext";
import { usePackagesContext } from "../../../../contexts/PackagesContext";
import DNSResolversButton from "../../../../components/common/buttons/DNSResolversButton";
import CloudLinksInfo from "./Ports/CloudLinksInfo";

type Props = {
  addConnectionCallback: (val: string) => void;
};

const InventoryBlock: FC<Props> = ({ addConnectionCallback }) => {
  const { isPackagesVisible } = usePackagesContext();
  const { groups } = useConnectionsContext();
  const tabs = useMemo(() => {
    let servicesTab = "My Services";

    if (isPackagesVisible) servicesTab = "Packages";
    return ["Ports", "Cloud Links", "Public IPs", servicesTab, "Order Log"];
  }, [isPackagesVisible]);

  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [showDialog, setShowDialog] = useState(false);
  const [isDNSResolversOpened, setIsDNSResolversOpened] = useState(false);
  const [showAdditional, setShowAdditional] = useState(false);
  const [DNSResolversList, setDNSResolversList] = useState<any>({
    ipv4: [],
    ipv6: [],
  });

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setShowDialog(false);
    setShowAdditional(false);
    setIsDNSResolversOpened(false);
  };

  const handleControl = (val?: boolean) => {
    setShowDialog(val === undefined ? !showDialog : val);
  };

  useEffect(() => {
    if (!groups) return;

    const dnsGroup = groups.find(
      (group: any) => group.name === "DNS_resolvers"
    );
    if (!dnsGroup || !dnsGroup.subnets) {
      return;
    }

    setDNSResolversList(cleanAndSplitIPs(dnsGroup.subnets));
  }, [groups]);

  const labels = {
    [tabs[0]]: [
      "Order Ports",
      // eslint-disable-next-line react/jsx-key
      <PortsTabInfo
        onAction={handleControl}
        isRequest={showDialog}
        addConnectionCallback={addConnectionCallback}
      />,
    ],
    [tabs[1]]: [
      "",
      // eslint-disable-next-line react/jsx-key
      <CloudLinksInfo
        onAction={handleControl}
        isRequest={showDialog}
        addConnectionCallback={addConnectionCallback}
      />,
    ],
    [tabs[2]]: [
      "Request IP",
      // eslint-disable-next-line react/jsx-key
      <PublicIPs
        isAdditional={showAdditional}
        onAction={handleControl}
        isRequest={showDialog}
        isDNSResolversOpened={isDNSResolversOpened}
        setIsAdditional={setShowAdditional}
      />,
    ],
    // eslint-disable-next-line react/jsx-key
    [tabs[3]]: ["", <ServicesTabInfo noInfoIcon />],
    // eslint-disable-next-line react/jsx-key
    [tabs[4]]: ["", <OrderLog />],
  };

  if (activeTab === "Public IPs") {
    return (
      <>
        <div className={styles.wrapper}>
          <BlockTitle
            title={"inventory"}
            onClick={handleControl}
            label={labels[activeTab][0] as string}
            additionalContent={
              (DNSResolversList?.ipv4.length > 0 ||
                DNSResolversList?.ipv6.length > 0) && (
                <div className={styles.additionalButton}>
                  <DNSResolversButton
                    onClick={() => {
                      setIsDNSResolversOpened(!isDNSResolversOpened);
                    }}
                    label={"DNS Resolvers"}
                    disabled={false}
                  />
                </div>
              )
            }
            disabled={showDialog || showAdditional}
          />
          <ContentBlock
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            content={labels[activeTab][1] as React.ReactElement}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        <BlockTitle
          title={"inventory"}
          onClick={handleControl}
          label={labels[activeTab][0] as string}
          disabled={showDialog || showAdditional}
        />
        <ContentBlock
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={handleTabChange}
          content={labels[activeTab][1] as React.ReactElement}
        />
      </div>
    </>
  );
};

export default withContexts<Props>(InventoryBlock, [
  ConnectionsContextContainer,
  ConnectionContextContainer,
]);
