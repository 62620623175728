import React from "react";
import NavigationTabs from "./NavigationTabs";
import { TENANT_L2, TENANT_L3, DIA } from "../../../helpers/navigation/entries";
import styles from "./OneTenantTabs.module.scss";

export const OneTenantTabs: React.FC = () => {
  return (
    <div className={styles.systemTabsWrapper}>
      <NavigationTabs
        entries={[TENANT_L2(), TENANT_L3(), DIA()]}
        disabledTabKeys={[]}
      />
    </div>
  );
};
