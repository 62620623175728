import React, { FC } from "react";
import DownloadIcon from "../../icons/DownloadIcon";
import IconBlankButton from "./IconBlankButton";

type Props = {
  onClick: (arg?: any) => void;
  withLabel?: boolean;
  title?: string;
  disabled?: boolean;
  id: string;
  color?: string;
  className?: string;
};

const DownloadButton: FC<Props> = ({
  onClick,
  withLabel,
  title,
  disabled,
  id,
  color,
  className,
}) => {
  return (
    <IconBlankButton
      id={`download-${id}`}
      title={title}
      onClick={onClick}
      icon={DownloadIcon}
      label={withLabel ? "Download" : undefined}
      disabled={disabled}
      color={color}
      className={className}
    />
  );
};
export default DownloadButton;
