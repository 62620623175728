import { Toggle } from "../../../../../components/common/Toggle";
import { classNames } from "../../../../../helpers/common/classNames";
import styles from "./menuToggleItem.module.scss";

type Props = {
  checked: boolean;
  title: string;
  id: string;
  className?: string;
  onClick: (args?: any) => void;
};
export const MenuToggleItem = ({
  checked,
  title,
  className,
  onClick,
  id,
}: Props) => {
  return (
    <div className={classNames(styles.toggleItem, className)}>
      {title}
      <Toggle isChecked={checked} onChange={() => onClick(id)} id={id} />
    </div>
  );
};
