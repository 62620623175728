/* eslint-disable css-modules/no-unused-class */
import { ReactElement, useEffect } from "react";
import styles from "./LoginPage.module.scss";
import LogoIcon from "../../components/icons/LogoIcon";
import { useUserContext } from "../../contexts/UserContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import FormComponent from "./components/FormComponent";
import RightSideImage from "./components/RightSideImage";
import { useAuthContext } from "../../contexts/AuthContext";
import {
  INSIGHTS,
  NETWORK_RESOURCES,
  REMOTE_USER,
  TENANTS,
  WIZARDS,
} from "../../helpers/navigation/entries";
import { useHistory } from "react-router";
import { parseQuery } from "../../helpers/common/parseQuery";
import { UserRoles, UserSecondRoles } from "../../helpers/rolesHelpers";

export default function LoginPage(): ReactElement {
  const { checkTenantParams, tenantParams, isZayo, isDemo } = useUserContext();
  const {
    isAuthorized,
    user,
    resetIsPasswordExpired,
    getSSOProviders,
  } = useAuthContext();
  const history = useHistory();

  useEffect(() => {
    isAuthorized && checkTenantParams(user);
    getSSOProviders();
  }, [isAuthorized]);

  useEffect(() => {
    if (!isAuthorized || !user) return;
    resetIsPasswordExpired();
    const DocumentationURL = parseQuery("next");
    if (DocumentationURL) {
      window.location.href = `${DocumentationURL}`;
      return;
    }
    if (user.role === UserRoles.TENANT) {
      if (user.secondRole === UserSecondRoles.REMOTE_USER) {
        history.push("/" + REMOTE_USER().path);
        return;
      }
      const path = tenantParams?.isEmptyConfig
        ? WIZARDS().path
        : INSIGHTS().path;
      history.push("/" + path);
      return;
    }
    if (user.secondRole === UserSecondRoles.EDITOR) {
      history.push("/" + TENANTS().path);
      return;
    }
    history.push("/" + NETWORK_RESOURCES().path);
  }, [isAuthorized, tenantParams, user, isZayo, isDemo]);

  if (isZayo === undefined || isDemo === undefined) {
    return <PageLoader />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {isZayo ? (
          <img src="/zayoGrafics/zayoLogoBig.png" className={styles.zayoLogo} />
        ) : (
          <LogoIcon />
        )}
        <FormComponent />
      </div>
      <RightSideImage />
    </div>
  );
}
