import React, { ReactElement } from "react";
import { IconProps } from "../../../../../../helpers/types";
import { getCorrectBandwidthUnit } from "../../../../../../helpers/getCorrectBandwidthUnit";
import { transferFromKbps } from "../../../../../../helpers/transferFromKbps";

type FillProps = {
  used: number;
  all: number;
  strokeWidth?: number;
};

function BWUsageIcon({
  className,
  style,
  used,
  all,
  strokeWidth,
}: IconProps & FillProps): ReactElement {
  const unit = getCorrectBandwidthUnit(all);
  const allBW = transferFromKbps(all, unit);
  const usedFromKbps = transferFromKbps(used, unit);
  const usedBW = Number.isInteger(usedFromKbps)
    ? usedFromKbps
    : usedFromKbps.toFixed(1);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      className={className}
      style={style}
    >
      <circle
        cx="27"
        cy="27"
        r="25"
        stroke="#D8DAEB"
        strokeWidth={strokeWidth ? strokeWidth : 3}
      />
      <g>
        <circle
          className={"outer"}
          cx="27"
          cy="27"
          r="25"
          strokeWidth={strokeWidth ? strokeWidth : 3}
          transform="rotate(-90, 27, 27)"
          fill="none"
        />
        (
        <>
          <text
            textAnchor="middle"
            x="50%"
            y="50%"
            fontSize="12"
            fill="#59536B"
            height="16px"
            width="34px"
            fontWeight={600}
            opacity={0.9}
          >
            {`${usedBW} ${unit.slice()[0]}`}
          </text>
          <text
            textAnchor="middle"
            x="50%"
            y="75%"
            fontSize="10"
            fill="#59536B"
            height="12px"
            width="34px"
            opacity={0.5}
          >
            {`/${allBW || 0} ${unit.slice()[0]}`}
          </text>
        </>
        )
      </g>
    </svg>
  );
}

export default BWUsageIcon;
