import { FC, useState } from "react";
import styles from "./StatisticsItem.module.scss";
import BGPIcon from "../../../../../components/icons/BGPIcon";
import { useTimerangeContext } from "../../../../../contexts/TimerangeContext";
import GrafanaGraph from "../../../../../components/common/charts/GrafanaGraph";
import { GTimeRange } from "../../../../../components/common/charts/GraphanaLinksFactory";
import TabsComponent from "../../../../../components/common/navigation/TabsComponent";

type Props = {
  tenant: string;
  peer_ip?: string;
  asn: number;
  providedNodeName?: string;
};

const NEIGHBOR_TABS = ["Total Prefixes", "Neighbor Summary"];

const StatisticsItem: FC<Props> = ({
  tenant,
  peer_ip,
  asn,
  providedNodeName,
}) => {
  const { timeRange } = useTimerangeContext();
  const [activeTab, setActiveTab] = useState<string>(NEIGHBOR_TABS[0]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        {peer_ip ? (
          <>
            <BGPIcon />
            <TabsComponent
              tabs={NEIGHBOR_TABS}
              activeTab={activeTab}
              setTabActive={(val) => setActiveTab(val)}
              isBtnMode
              className={styles.tabs}
            />
          </>
        ) : (
          `Total active peers for AS ${asn}`
        )}
      </div>
      <div className={styles.statContainer}>
        <GrafanaGraph
          link={getGraphLink(
            timeRange.general,
            tenant,
            peer_ip,
            providedNodeName
          )}
        />
      </div>
    </div>
  );
};
export default StatisticsItem;

const getGraphLink = (
  timeRange: GTimeRange,
  tenant: string,
  peer_ip?: string,
  providedNodeName?: string
): string => {
  if (providedNodeName) {
    return `/broker/grafana/d-solo/efH_Tu34z/bgp_telemetry?orgId=1&var-Filters=tenant_name.keyword|=|${tenant}&var-Filters=node_name.keyword%7C%3D%7C${providedNodeName}&from=${timeRange.from}&to=${timeRange.to}&panelId=6&theme=light`;
  }
  if (!peer_ip) {
    return `/broker/grafana/d-solo/efH_Tu34z/bgp_telemetry?orgId=1&var-Filters=tenant_name.keyword|=|${tenant}&from=${timeRange.from}&to=${timeRange.to}&panelId=6&theme=light`;
  }
  return `/broker/grafana/d-solo/efH_Tu34z/bgp_telemetry?orgId=1&var-Filters=tenant_name.keyword%7C%3D%7C${tenant}&var-Filters=peer_ip.keyword%7C%3D%7C${peer_ip}&from=${timeRange.from}&to=${timeRange.to}&theme=light&panelId=2`;
};
