import React, { FC, useEffect, useState } from "react";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import PortsLabels from "./common/PortsLabels";
import APortBlock from "./components/APort/APortBlock";
import ServicesBlock from "./components/Services/ServicesBlock";
import ZPortBlock from "./components/ZPort/ZPortBlock";
import ConnectionConfiguratorContext, {
  useConnectionConfiguratorContext,
} from "./ConnectionConfiguratorContext";
import {
  CLOUD_CONNECTIONS,
  CLOUD_ROUTER,
  PORT_TYPES_MAP,
  INITIAL_CONNECTIONS_FIELDS,
  CONNECTIONS_DEFAULTS_FIELDS,
} from "./consts";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./ConnectionConfigurator.module.scss";
import { useHistory } from "react-router-dom";
import {
  ZAYO_EDIT_CONNECTION,
  NEW_CONNECTION,
} from "../../../../helpers/navigation/entries";
import { getDataObjectCopy } from "../../../../helpers/getDataObjectCopy";
import DialogBtmButtons from "../../../../components/dialogs/common/DialogBtmButtons";
import { ADD, CANCEL } from "../../../../helpers/common/constantsAlias";
import { useValidation } from "../../../../helpers/validators/Validator";
import validateConnection from "../../../../helpers/validators/ZayoConnectionValidator";
import { ChangeConnectionsFields, FieldsType } from "./types";
import LeftBlock from "./components/LeftBlock/LeftBlock";
import { useConnectionsContext } from ".././ConnectionContext";
import { classNames } from "../../../../helpers/common/classNames";
import { ServiceAPI } from "../../../ServicesPreferencesPage/types";
import LayoutComponent from "../../../../components/common/LayoutComponent";

type Props = {
  noLayout?: boolean;
  className?: string;
};

const ConnectionConfiguratorAdd: FC<Props> = ({ noLayout, className }) => {
  const history = useHistory();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    add,
    addStatus,
    portType,
    listApi,
    changePortType,
    services,
    fetchServices,
  } = useConnectionConfiguratorContext();
  const { fetchData } = useConnectionsContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchData(selectedTenant);
      fetchServices(selectedTenant);
    }
  }, [selectedTenant]);

  const [currServices, setCurrServices] = useState<Array<ServiceAPI>>([]);

  useEffect(() => {
    if (services) {
      setCurrServices(services);
    }
  }, [services]);

  // Fields
  const [fields, onChangeFields] = useFormField(INITIAL_CONNECTIONS_FIELDS);

  // Change fields
  const handleChangeConnectionsFields: ChangeConnectionsFields = (
    idx: number,
    connectionFields: FieldsType
  ): void => {
    const connections = getDataObjectCopy(fields.connections);
    connections[idx] = { ...connectionFields };
    onChangeFields("connections", connections);
  };

  // Change BW Fields
  const handleChangeBW = (idx: number, value: string): void => {
    const newConnections = fields.connections.map((el: FieldsType, i) => {
      if (portType.key !== "azPorts" && idx !== i) return el;
      return {
        ...el,
        BW: value,
      };
    });
    onChangeFields("connections", newConnections);
  };

  // Delete connection
  const handleDeleteConnection = (idx: number) => {
    if (fields.connections.length === 2 && idx === 1) {
      handleChangeConnectionsFields(
        idx,
        CONNECTIONS_DEFAULTS_FIELDS[CLOUD_CONNECTIONS]
      );
      return;
    }
    const connections = fields.connections.filter((el, i) => i !== idx);
    onChangeFields("connections", connections);
  };

  // errors
  const [errors, validate] = useValidation(validateConnection, [
    {
      ...fields,
      isCloudRouter: portType.key === CLOUD_ROUTER,
    },
  ]);

  // Actions
  useEffect(() => {
    const connections = fields.connections;
    if (
      connections[0].innerType === CLOUD_CONNECTIONS &&
      connections[1].innerType === CLOUD_CONNECTIONS &&
      portType.key !== "cloudRouter"
    ) {
      changePortType(PORT_TYPES_MAP[1]);
    }
  }, [fields, portType]);

  useEffect(() => {
    if (portType.key === "cloudRouter") {
      const crConnection = listApi?.find(
        (el) => el.z_endpoint_name === "Cloud_Router"
      )?.labels.parentConnection;
      if (crConnection) {
        const path = history.location.pathname;
        const editPath = path.replace(
          NEW_CONNECTION().path,
          ZAYO_EDIT_CONNECTION(crConnection).path
        );
        history.push(editPath);
      }
    }
  }, [portType]);

  const onClose = () => {
    history.goBack();
  };

  const handleAddConnections = async () => {
    const { isOk } = validate();
    if (!selectedTenant || !isOk) return;
    const res = await add(selectedTenant, {
      ...fields,
      services: currServices,
    });
    if (res) {
      onClose();
    }
  };

  const isAZPorts = portType.key === "azPorts";
  const isIpsec = fields.connections.some(
    (el) => el.Connection_type === "ipsec"
  );

  const getWrapper = (children: React.ReactElement) => {
    return noLayout ? children : <LayoutComponent>{children}</LayoutComponent>;
  };

  return getWrapper(
    <div
      className={classNames(
        styles.wrapper,
        isAZPorts && styles.twoCols,
        className
      )}
    >
      <PortsLabels className={styles.labels} />
      <LeftBlock
        connectionName={fields.name}
        onChangeConnectionName={(val) => onChangeFields("name", val)}
        selected={fields.connections[0].innerType}
        onSelect={(innerType: string) => {
          const newFields = CONNECTIONS_DEFAULTS_FIELDS[innerType];
          handleChangeConnectionsFields(0, newFields);
        }}
        error={errors?.name}
        className={styles.leftBlock}
      />
      <APortBlock
        className={styles.aPort}
        aPort={fields.connections[0]}
        onChange={handleChangeConnectionsFields}
        errors={errors}
        handleChangeBW={handleChangeBW}
      />
      <ZPortBlock
        fields={fields.connections}
        onChange={handleChangeConnectionsFields}
        onDelete={handleDeleteConnection}
        className={styles.zPort}
        errors={errors}
        handleChangeBW={handleChangeBW}
      />
      {!isAZPorts && (
        <ServicesBlock
          className={styles.services}
          services={currServices}
          setServices={setCurrServices}
        />
      )}
      <DialogBtmButtons
        className={styles.footer}
        controls={{
          okText: ADD,
          onOk: handleAddConnections,
          cancelText: CANCEL,
          onCancel: onClose,
          disableSubmit: isIpsec,
        }}
        errorDisplay={addStatus}
      />
    </div>
  );
};

export default withContexts<any>(ConnectionConfiguratorAdd, [
  ConnectionConfiguratorContext,
]);
