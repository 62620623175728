import { BrowserRouter } from "react-router-dom";
import NiroContextContainer from "./contexts/NiroContext";
import { ReactElement } from "react";
import { Router } from "./components/Router";
import UserContextContainer from "./contexts/UserContext";
import GlobalFilterContextContainer from "./contexts/GlobalFilterContext";
import AuthContextContainer from "./contexts/AuthContext";
import TutorialContextContainer from "./contexts/TutorialContext";
import NotificationsContextContainer from "./contexts/NotificationsContext";
import { PricingDialogProvider } from "./contexts/pricingDialogContext/PricingDialogContext";
import { PackagesContextContainer } from "./contexts/PackagesContext";

function App(): ReactElement {
  return (
    <AuthContextContainer>
      <UserContextContainer>
        <NotificationsContextContainer>
          <GlobalFilterContextContainer>
            <NiroContextContainer>
              <TutorialContextContainer>
                <PricingDialogProvider>
                  <PackagesContextContainer>
                    <BrowserRouter>
                      <Router />
                    </BrowserRouter>
                  </PackagesContextContainer>
                </PricingDialogProvider>
              </TutorialContextContainer>
            </NiroContextContainer>
          </GlobalFilterContextContainer>
        </NotificationsContextContainer>
      </UserContextContainer>
    </AuthContextContainer>
  );
}

export default App;
