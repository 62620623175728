import React from "react";
import { PosProps } from "../../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/utils";

export default function CloudCircle({
  x,
  y,
  active,
  location,
  onClick,
  icon,
  vlan,
}: PosProps & {
  active?: boolean;
  location: string;
  onClick?: () => void;
  icon: any;
  vlan?: number | string;
}): React.ReactElement {
  return (
    <>
      <text
        x={x - 5}
        y={y + 40}
        textAnchor="middle"
        fill="var(--font)"
        fontSize="12"
      >
        {location}
      </text>
      <circle
        cx={x - 5}
        cy={y - 5}
        r="28"
        fill={"#fff"}
        stroke={active ? "rgba(107, 85, 240, 0.60)" : "#BEB8EA"}
        strokeWidth={active ? 2.5 : 1}
        cursor={"pointer"}
        onClick={onClick}
      />
      {vlan && (
        <>
          <rect
            width={vlan && typeof vlan !== "string" ? "74px" : "45px"}
            height="17px"
            fill="rgba(190, 184, 234, 0.7)"
            x={x}
            y={y - 38}
            rx={5}
            ry={5}
          />
          <text
            height="17px"
            fill="var(--font)"
            fontSize="12"
            x={x + 5}
            y={y - 25}
            textAnchor="right"
          >
            {typeof vlan === "string" ? vlan : `VLAN ${vlan || "-"}`}
          </text>
        </>
      )}
      {icon}
    </>
  );
}
