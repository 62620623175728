import { TenantParams } from "../../../contexts/UserContext";
import { getSublistFromArray } from "../../../helpers/getSublistFromArray";
import {
  LOCATIONS,
  APPLICATIONS,
  CONNECTIONS,
  CYBER_THREATS,
  INSIGHTS,
  SERVICES,
  TOPOLOGY,
  TRAFFIC,
  WIZARDS,
  NETWORK,
  SEGMENTATION,
  CLOUD_ROUTER,
  SERVICES_FW,
  SERVICES_PREFERENCES,
  SERVICES_NAT,
  WEB_FILTERING,
  REMOTE_USERS,
  REMOTE_USERS_USER_GROUPS,
  CONNECTIVITY,
  INSIGHTS_SDWAN,
  CONNECTIONS_UNITED,
  LOOKING_GLASS,
} from "../../../helpers/navigation/entries";
import InsightsIcon from "../../icons/tabs/InsightsIcon";
import NetworkIcon from "../../icons/tabs/NetworkIcon";
import ServicesIcon from "../../icons/tabs/ServicesIcon";
import WizardsIcon from "../../icons/tabs/WizardsIcon";
import { ButtonInfo } from "../types";

export const tenantFormButtons = (
  goTo: any,
  tenantParams?: TenantParams,
  isPackagesVisible?: boolean
): Array<ButtonInfo> => {
  return [
    {
      ...INSIGHTS(),
      Icon: InsightsIcon,
      disabled: tenantParams?.isEmptyConfig,
      subList: getSublistFromArray(
        [
          TOPOLOGY(),
          APPLICATIONS(),
          TRAFFIC(),
          INSIGHTS_SDWAN(),
          CYBER_THREATS(),
        ],
        goTo,
        INSIGHTS(),
        []
      ) as any,
    },
    {
      ...WIZARDS(),
      Icon: WizardsIcon,
      subList: getSublistFromArray(
        [LOCATIONS(), CONNECTIONS(), SERVICES()],
        goTo,
        WIZARDS()
      ) as any,
    },
    {
      ...NETWORK(),
      Icon: NetworkIcon,
      disabled: tenantParams?.isEmptyConfig,
      subList: getSublistFromArray(
        [
          CONNECTIVITY(),
          CLOUD_ROUTER(),
          REMOTE_USERS(),
          REMOTE_USERS_USER_GROUPS(),
          LOOKING_GLASS(),
          CONNECTIONS_UNITED(),
        ],
        goTo,
        NETWORK(),
        []
      ) as any,
    },
    {
      ...SERVICES(),
      Icon: ServicesIcon,
      disabled: tenantParams?.isEmptyConfig,
      subList: getSublistFromArray(
        [
          SERVICES_FW(),
          SERVICES_NAT(),
          SEGMENTATION(),
          WEB_FILTERING(),
          SERVICES_PREFERENCES("", isPackagesVisible),
        ],
        goTo,
        SERVICES()
      ) as any,
    },
  ];
};
