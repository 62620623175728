import { useEffect, useState } from "react";

export enum TaskStatus {
  Idle = "idle",
  InProcess = "in process",
  Done = "done",
  Error = "error",
}

type CheckTaskParams = {
  getTaskStatus: () => Promise<string>;
  taskId: string;
  onSuccess?: () => void;
  onError?: () => void;
  onComplete?: () => void;
};

export const useCheckTask = ({
  getTaskStatus,
  taskId,
  onSuccess,
  onError,
  onComplete,
}: CheckTaskParams) => {
  const [taskStatus, setTaskStatus] = useState<TaskStatus>(TaskStatus.Idle);

  useEffect(() => {
    if (!taskId) {
      return;
    }

    if (taskStatus !== TaskStatus.Idle && taskStatus !== TaskStatus.InProcess) {
      return;
    }

    const interval = setInterval(checkTaskStatus, 1000 * 10);

    async function checkTaskStatus() {
      const taskStatus = await getTaskStatus();
      setTaskStatus(taskStatus as TaskStatus);

      if (taskStatus === TaskStatus.Done) {
        onSuccess?.();
      }
      if (taskStatus === TaskStatus.Error) {
        onError?.();
      }

      if (taskStatus === TaskStatus.Done || taskStatus === TaskStatus.Error) {
        clearInterval(interval);
        onComplete?.();
      }
    }

    checkTaskStatus();
    return () => clearInterval(interval);
  }, [taskId]);

  useEffect(() => {
    setTaskStatus(TaskStatus.Idle);
  }, [taskId]);

  return { taskStatus, isInProgress: taskStatus === TaskStatus.InProcess };
};
