import React, { PropsWithChildren } from "react";
import styles from "./TitleBlock.module.scss";

export type TitleProps = PropsWithChildren<{
  title: string | React.ReactElement;
  noTitle?: boolean;
}>;
export default function TitleBlock({
  title,
  children,
  noTitle,
}: TitleProps): React.ReactElement {
  return !noTitle ? (
    <div className={styles.tableHeader}>
      {title}
      {children}
    </div>
  ) : (
    <div />
  );
}
