import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function GCPIcon({ color, className, x, y }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      x={x}
      y={y}
    >
      <g clipPath="url(#clip0_1418_49)">
        <path
          d="M17.8572 10.3371L19.5812 8.57266L19.6961 7.82974C16.5545 4.90615 11.5604 5.23765 8.72837 8.52144C7.9417 9.43349 7.35809 10.5706 7.04688 11.7447L7.66434 11.6557L11.1123 11.0738L11.3785 10.7952C12.9123 9.07131 15.5056 8.83939 17.2765 10.3061L17.8572 10.3371Z"
          fill={color || GREY_FONT}
        />
        <path
          d="M22.0365 11.6912C21.6403 10.1977 20.8267 8.85514 19.6955 7.82812L17.2758 10.3044C18.2976 11.1589 18.8796 12.446 18.8547 13.7961V14.2356C20.044 14.2356 21.0082 15.2224 21.0082 16.4395C21.0082 17.6567 20.044 18.6188 18.8547 18.6188H14.5416L14.1182 19.0892V21.7327L14.5416 22.1475H18.8547C21.9482 22.1721 24.4756 19.6504 24.4997 16.4844C24.5142 14.5654 23.5897 12.7663 22.0365 11.6912Z"
          fill={color || GREY_FONT}
        />
        <path
          d="M10.235 22.1487H14.542V18.6199H10.235C9.92815 18.6199 9.63096 18.5524 9.3519 18.4219L8.74086 18.6138L7.00474 20.3781L6.85352 20.9787C7.82713 21.731 9.01504 22.1541 10.235 22.1487Z"
          fill={color || GREY_FONT}
        />
        <path
          d="M10.2351 10.7032C7.14146 10.722 4.64866 13.304 4.66709 16.4699C4.6774 18.2379 5.48421 19.9022 6.85356 20.9799L9.35194 18.4231C8.26801 17.9219 7.78635 16.6165 8.27606 15.5072C8.7657 14.398 10.0414 13.905 11.1252 14.4061C11.6028 14.627 11.9853 15.0185 12.201 15.5072L14.6994 12.9504C13.6364 11.5282 11.9847 10.6967 10.2351 10.7032Z"
          fill={color || GREY_FONT}
        />
      </g>
      <defs>
        <clipPath id="clip0_1418_49">
          <rect
            width="19.8333"
            height="16.3333"
            fill="white"
            transform="translate(4.66699 5.83203)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GCPIcon;
