import { CreateDiaFWRule } from "../../pages/FirewallDia/config/DiaFirewallRuleConfig";
import Validator from "./Validator";
import { MAX_FW_PRIORITY_FOR_MANUAL_CREATION } from "../../pages/Firewall/config/FirewallRuleConfig";

export default function validateDiaFirewallNewRule(
  data: CreateDiaFWRule,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.name, "name");
  data.name && !data?.id && validator.checkLength(data.name, [1, 128], "name");
  data.name && !data?.id && validator.checkNameFormat(data.name, "name");

  validator.checkMinMax(
    data.priority,
    [1, MAX_FW_PRIORITY_FOR_MANUAL_CREATION],
    "priority"
  );

  validator.checkEmpty(data.gate_vi_name, "gate_vi_name");
  validator.checkEmpty(data.policy_name, "policy_name");

  if (data.policy_name !== "incoming") {
    validator.checkEmpty(data.src_network, "src_network");
  } else {
    validator.checkEmpty(data.dst_network, "dst_network");
  }

  data.src_network &&
    validator.checkIPWithPrefixAll(data.src_network, "src_network");
  data.dst_network &&
    validator.checkIPWithPrefixAll(data.dst_network, "dst_network");

  return validator;
}
