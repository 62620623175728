import { useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../../helpers/navigation";
import {
  CONFIGURATION,
  NEW_CONNECTION,
  REPORTS,
} from "../../../helpers/navigation/entries";
import { PageProps, PathEntry } from "../../../helpers/types";
import { Routes } from "../../common";
import { RouteEntry } from "../../types";
import ConnectionsStatistics from "../InterfaceStatistics";
import ConnectionsContent from "./ConnectionsContent";
import ConnectionCofigurator from "./itemConfigurator";

const Connections = ({ tabs }: PageProps) => {
  const path = useMatchPath();

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={fullPath(CONFIGURATION())} />
      </Route>
      <Routes
        routes={connectionsRoutes}
        tabs={tabs}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default Connections;

const ConnectionsConfig = () => {
  return <ConnectionCofigurator />;
};
const ConnectionsReport = ({ tabs }: PageProps) => {
  return <ConnectionsStatistics tabs={tabs} />;
};
const ConnectionsPage = ({ tabs }: PageProps) => {
  return <ConnectionsContent tabs={tabs} />;
};

const connectionsRoutes: Array<RouteEntry> = [
  {
    pathFunc: CONFIGURATION,
    component: ConnectionsPage,
  },
  {
    pathFunc: REPORTS,
    component: ConnectionsReport,
  },
  {
    pathFunc: NEW_CONNECTION,
    component: ConnectionsConfig,
  },
];
