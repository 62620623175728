import { CellProps } from "react-table";
import { getDateWithTime } from "../../../../../helpers/common/dateHelpers";
import { Activity } from "./Activity";
import { getItemForAll } from "./helpers";
import { SERVICES_NAME_MAP } from "./constants";
import { getBandwidthStringForTable } from "../../../../../helpers/getBandwidthStringForTable";

const commonHeaders = [
  {
    Header: "Timestamp",
    accessor: "created_at",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      return getDateWithTime(value) || "-";
    },
  },
  {
    Header: "Activity",
    accessor: "action",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      return <Activity activity={value} />;
    },
  },
  {
    Header: "Location",
    accessor: "extra",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      return value?.location ?? "";
    },
  },
  // TODO: add price field when the BE field is ready
  // {
  //   Header: "Cost",
  //   accessor: "price",
  //   Cell: ({ cell: { value } }: CellProps<any>) => {
  //     return value || "-";
  //   },
  // },
];

export const orderLogPortsTableHeader = (): Array<any> => [
  commonHeaders[0],
  commonHeaders[1],
  {
    Header: "BW",
    accessor: "payload",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      return getBandwidthStringForTable(value.requested_bw);
    },
  },
  commonHeaders[2],
];

export const orderLogConnectionsTableHeader = (): Array<any> => [
  commonHeaders[0],
  commonHeaders[1],
  {
    Header: "BW",
    accessor: "extra",
    Cell: ({ cell: { value } }: CellProps<any>) => {
      let maxBW;

      if (value?.l2_data?.length) {
        maxBW = Math.max(...value.l2_data.map((el: any) => el.max_bandwidth));
      }

      return getBandwidthStringForTable(maxBW);
    },
  },
  // commonHeaders[2],
];

export const orderLogPublicIPTableHeader = (): Array<any> => [
  commonHeaders[0],
  commonHeaders[1],
  {
    Header: "Type",
    accessor: "entity_type",
  },
  commonHeaders[2],
];

export const orderLogServicesTableHeader = (): Array<any> => [
  commonHeaders[0],
  commonHeaders[1],
];

export const orderLogAllTableHeader = (): Array<any> => [
  commonHeaders[0],
  commonHeaders[1],
  {
    Header: "Item",
    accessor: "order",
    Cell: ({ cell: { value }, row: { original } }: CellProps<any>) => {
      return getItemForAll(value, original);
    },
  },
  {
    Header: "Name",
    Cell: ({ row: { original } }: CellProps<any>) => {
      switch (original.order) {
        case "ports":
          return original.payload?.name ?? "";
        case "connections":
          return original.payload?.name ?? "";
        case "services":
          const serviceName = original.entity_type?.toLowerCase();
          const result =
            SERVICES_NAME_MAP[serviceName as keyof typeof SERVICES_NAME_MAP] ??
            serviceName;
          return result;
      }

      return "";
    },
  },
  // commonHeaders[2],
];
