/* eslint-disable css-modules/no-unused-class */
import React, { FC, useState } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import { DropdownItem } from "../../../../../helpers/types";
import Button from "../../../buttons/Button";
import styles from "./DropdownList.module.scss";
import ListContainer from "./components/ListContainer";
import Input from "../../../formComponents/Input";

type DropdownListProps = {
  valuesList:
    | Array<DropdownItem>
    | { [key: string]: Array<DropdownItem> }
    | undefined;
  isSmaller?: boolean;
  isMedium?: boolean;
  isNotFormElement?: boolean;
  isListShown: boolean;
  handleSelect: (arg: any) => void;
  isListEditable?: boolean;
  createText?: string;
  onCreate?: (val?: string) => void;
  setIsListShown: (arg: boolean) => void;
  isRightCorner?: boolean;
  withCheckbox?: boolean;
  isAside?: boolean;
  className?: string;
  createTextClassName?: string;
  withInput?: boolean;
  onEditOption?: (key?: string) => void;
  onDeleteOption?: (key?: string) => void;
  emptyListPlaceholder?: string;
  hideEmptyListPlaceholder?: boolean;
  spanClassName?: string;
  additionalValuesList?:
    | Array<DropdownItem>
    | { [key: string]: Array<DropdownItem> }
    | undefined;
  valuesWithLabel?: boolean;
  label?: string;
  isLoading?: boolean;
};

const DropdownList: FC<DropdownListProps> = (props) => {
  const {
    valuesList,
    isSmaller,
    isMedium,
    isNotFormElement,
    isListShown,
    handleSelect,
    isListEditable,
    createText,
    onCreate = () => undefined,
    setIsListShown,
    isRightCorner,
    withCheckbox,
    isAside,
    className,
    onEditOption,
    onDeleteOption,
    emptyListPlaceholder,
    spanClassName,
    additionalValuesList,
    valuesWithLabel,
    label,
    hideEmptyListPlaceholder,
    createTextClassName,
    withInput,
    isLoading,
  } = props;
  const [inputVal, setInputVal] = useState<string>("");

  const handleClick = () => {
    setIsListShown(false);
    withInput ? onCreate(inputVal) : onCreate();
  };

  const wrapperStyles = classNames(
    styles.wrapper,
    !isListShown && styles.hiddenList,
    isSmaller && styles.smaller,
    isMedium && styles.medium,
    isRightCorner && styles.toRightCorner,
    isAside && styles.aside,
    className
  );

  const dropDownList = classNames(
    styles.dropDownList,
    isNotFormElement && styles.notFormElement
  );

  const isEmpty =
    (!valuesList && !additionalValuesList) ||
    (Array.isArray(valuesList) &&
      valuesList.length === 0 &&
      !additionalValuesList);

  if (isEmpty && hideEmptyListPlaceholder) return null;

  return (
    <div className={wrapperStyles}>
      {valuesWithLabel && label && <div className={styles.label}>{label}</div>}
      <div className={dropDownList}>
        <ListContainer
          valuesList={valuesList}
          isListEditable={isListEditable}
          emptyListPlaceholder={emptyListPlaceholder}
          onEditOption={onEditOption}
          onDeleteOption={onDeleteOption}
          withCheckbox={withCheckbox}
          handleSelect={handleSelect}
          spanClassName={spanClassName}
          additionalValuesList={additionalValuesList}
          isLoading={isLoading}
        />
      </div>
      {createText && (
        <div className={createTextClassName}>
          {withInput && (
            <Input
              value={inputVal}
              label={"New"}
              className={styles.labelInput}
              onChange={(e) => setInputVal(e.target.value)}
              medium
            />
          )}
          <Button onClick={handleClick} className={styles.createBtn}>
            <span>+</span>
            {createText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DropdownList;
