import { usePricingDialog } from "../../../contexts/pricingDialogContext/PricingDialogContext";
import {
  PricingDialog,
  PricingType,
  TermsAndConditions,
  TermsDialog,
} from "./components";

type TermsAndPricingDialogProps = {
  pricingList: Array<PricingType> | undefined;
  terms: TermsAndConditions | undefined;
  isPricingLoading: boolean;
  isTermsLoading: boolean;
};

export const TermsAndPricingDialog = ({
  pricingList,
  terms,
  isPricingLoading,
  isTermsLoading,
}: TermsAndPricingDialogProps) => {
  const { isOpen } = usePricingDialog();

  if (isOpen === "pricing" && pricingList)
    return (
      <PricingDialog pricingList={pricingList} isLoading={isPricingLoading} />
    );

  if (isOpen === "terms" && terms)
    return <TermsDialog terms={terms} isLoading={isTermsLoading} />;

  return null;
};
