/* eslint-disable css-modules/no-unused-class */
import React, { FC } from "react";
import PortDetails from "./PortDetails";
import PortVlanIcon from "../icons/PortVlanIcon";
import styles from "./PortItem.module.scss";
import { TenantCustomerPort, TenantPort } from "../../../types"; // Подключаем типы
import { VirtualInterfaceApi } from "../../../../../VirtualInterfacePage/types";
import PortBaseIcon from "../icons/PortBaseIcon";

interface CustomerPortInfoProps {
  port: TenantCustomerPort;
  i: number;
  hidden: boolean;
  operatorIcon: JSX.Element | string;
  operator: string | JSX.Element;
  parentPort?: TenantPort | undefined;
  pending: boolean;
  hasFreeBw: boolean;
  usedBW: number;
  freeBW: number;
  filteredConnections: Array<VirtualInterfaceApi>;
  getConnectionDetail: (connection: VirtualInterfaceApi) => JSX.Element;
  addConnectionBtn: JSX.Element;
  getConnectionsCount: () => JSX.Element;
  selectPortToDelete: (port: TenantCustomerPort) => void;
  setHidden: (value: boolean) => void;
}

const CustomerPortInfo: FC<CustomerPortInfoProps> = ({
  port,
  i,
  hidden,
  operatorIcon,
  operator,
  filteredConnections,
  pending,
  selectPortToDelete,
  setHidden,
  getConnectionsCount,
  addConnectionBtn,
  hasFreeBw,
  usedBW,
  freeBW,
  getConnectionDetail,
  parentPort,
}) => {
  const additionalDetails = (
    <div className={styles.unitWrapper}>
      {operatorIcon}
      <div className={styles.unit}>
        <span className={styles.unitTitle}>Operator:</span>
        <span className={styles.unitInfo}>{operator}</span>
      </div>
    </div>
  );

  return (
    <PortDetails
      portName={port.name}
      location={port.location || port.labels?.naas_location || "-"}
      usedBW={usedBW}
      freeBW={freeBW}
      portIcon={parentPort?.trunk ? <PortVlanIcon /> : <PortBaseIcon />}
      assignedBW={port.assigned_bw}
      operatorIcon={operatorIcon}
      operator={operator}
      port={port}
      hidden={hidden}
      hasConnections={!!filteredConnections.length}
      pending={pending}
      selectPortToDelete={() => selectPortToDelete(port)}
      setHidden={setHidden}
      additionalDetails={additionalDetails}
      getConnectionsCount={getConnectionsCount}
      addConnectionBtn={addConnectionBtn}
      getConnectionDetail={getConnectionDetail}
      filteredConnections={filteredConnections}
      hasFreeBw={hasFreeBw}
    />
  );
};

export default CustomerPortInfo;
