import Validator from "./Validator";

export default function validateMaxBandwidth(
  data: any,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkIf32bitOrLess(
    data.max_bandwidth,
    data.bandwidthUnit || data.bw_unit,
    "max_bandwidth"
  );
  return validator;
}
