import React, { ReactElement } from "react";
import { IconProps } from "../../../../../../helpers/types";

function PortVlanIcon({ color, className, x, y }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      x={x}
      y={y}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 6H9V8C9 9.65685 10.3431 11 12 11H19.5V12.5C19.5 13.0523 19.0523 13.5 18.5 13.5H16.5V17C16.5 17.5523 16.0523 18 15.5 18H8.5C7.94772 18 7.5 17.5523 7.5 17V13.5H5.5C4.94772 13.5 4.5 13.0523 4.5 12.5V7C4.5 6.44772 4.94772 6 5.5 6Z"
        fill={color || "#59536B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 1C11.8954 1 11 1.89543 11 3V7C11 8.10457 11.8954 9 13 9H19C20.1046 9 21 8.10457 21 7V3C21 1.89543 20.1046 1 19 1H13ZM19.5 2H17.8539L16.3971 5.57045C16.1727 6.19973 16.0412 6.64022 16.0026 6.89193C15.9816 6.78249 15.9272 6.58824 15.8396 6.30917C15.7554 6.02736 15.6731 5.78112 15.5924 5.57045L14.1461 2H12.5L15.1086 8H16.8862L19.5 2Z"
        fill={color || "#59536B"}
      />
    </svg>
  );
}

export default PortVlanIcon;
