import { getSublistFromArray } from "../../../helpers/getSublistFromArray";
import {
  APPLICATIONS,
  CYBER_THREATS,
  INSIGHTS,
  SERVICES,
  TOPOLOGY,
  TRAFFIC,
  NETWORK,
  CONNECTIVITY,
  SEGMENTATION,
  CLOUD_ROUTER,
  SERVICES_FW,
  SERVICES_NAT,
  SERVICES_PREFERENCES,
  WEB_FILTERING,
  REMOTE_USERS,
  REMOTE_USERS_USER_GROUPS,
  LOOKING_GLASS,
} from "../../../helpers/navigation/entries";
import InsightsIcon from "../../icons/tabs/InsightsIcon";
import NetworkIcon from "../../icons/tabs/NetworkIcon";
import ServicesIcon from "../../icons/tabs/ServicesIcon";
import WizardsIcon from "../../icons/tabs/WizardsIcon";
import { ButtonInfo } from "../types";

export const zayoTenantFormButtons = (
  goTo: () => void,
  isDemo?: boolean,
  tenantParams?: any,
  isPackagesVisible?: boolean
): Array<ButtonInfo> => {
  if (isDemo) {
    return [
      {
        ...INSIGHTS(),
        Icon: InsightsIcon,
        disabled: tenantParams?.isEmptyConfig,
        subList: getSublistFromArray(
          [TOPOLOGY(), APPLICATIONS(), TRAFFIC(), CYBER_THREATS()],
          goTo,
          INSIGHTS(),
          []
        ) as any,
      },
      {
        ...NETWORK(),
        Icon: NetworkIcon,
        disabled: tenantParams?.isEmptyConfig,
        subList: getSublistFromArray(
          [
            CONNECTIVITY(),
            CLOUD_ROUTER(),
            REMOTE_USERS(),
            REMOTE_USERS_USER_GROUPS(),
            LOOKING_GLASS(),
          ],
          goTo,
          NETWORK(),
          []
        ) as any,
      },
      {
        ...SERVICES(),
        Icon: ServicesIcon,
        disabled: tenantParams?.isEmptyConfig,
        subList: getSublistFromArray(
          [
            SERVICES_FW(),
            WEB_FILTERING(),
            SERVICES_NAT(),
            SEGMENTATION(),
            SERVICES_PREFERENCES(),
          ],
          goTo,
          SERVICES()
        ) as any,
      },
    ];
  }
  return [
    {
      content: "Build your Network",
      path: "network/connections_united/configuration",
      Icon: WizardsIcon,
    },
    {
      ...INSIGHTS(),
      Icon: InsightsIcon,
      disabled: tenantParams?.isEmptyConfig,
      withSeparator: true,
      subList: getSublistFromArray(
        [TOPOLOGY(), APPLICATIONS(), TRAFFIC()],
        goTo,
        INSIGHTS(),
        []
      ) as any,
    },
    {
      ...NETWORK(),
      Icon: NetworkIcon,
      disabled: tenantParams?.isEmptyConfig,
      subList: getSublistFromArray(
        [
          CONNECTIVITY(),
          CLOUD_ROUTER(),
          REMOTE_USERS_USER_GROUPS(),
          LOOKING_GLASS(),
        ],
        goTo,
        NETWORK(),
        []
      ) as any,
    },
    {
      ...SERVICES(),
      Icon: ServicesIcon,
      disabled: tenantParams?.isEmptyConfig,
      subList: getSublistFromArray(
        [
          SERVICES_FW(),
          SERVICES_NAT(),
          SERVICES_PREFERENCES("", isPackagesVisible),
        ],
        goTo,
        SERVICES()
      ) as any,
    },
  ];
};
