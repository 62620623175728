import React, { FC, useEffect } from "react";
import { useTenantVirtualInterfaceContext } from "../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import VLANIcon from "../../../components/icons/VLANIcon";

type SumProps = {
  title: string;
  data: any;
  system: string;
};

const SummaryBlock: FC<SumProps> = ({ title, data, system }) => {
  const { selected, fetchSelected } = useTenantVirtualInterfaceContext();
  useEffect(() => {
    if (data.virtual_interface_id) fetchSelected(data?.virtual_interface_id);
  }, [data.virtual_interface_id]);
  return (
    <SummaryInfoBlock icon={<VLANIcon />} title={title}>
      <UnifiedTextPair subtitle={"Virtual Interface"} text={selected?.name} />
      <UnifiedTextPair subtitle={"System"} text={system} />
    </SummaryInfoBlock>
  );
};

export default SummaryBlock;
