import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
  MBPS,
} from "../../../../../helpers/consts";
import { parseBandwidth } from "../../../../../helpers/parseBandwidth";
import { CLOUD_CONNECTIONS } from "./consts";

export function updateConnectionsFromInventory(fields, addFrom, customerPorts) {
  const getCloudType = (portType) => {
    if (
      portType === AWS_LOWERCASE ||
      portType === AZURE_LOWERCASE ||
      portType === GCP_LOWERCASE
    ) {
      return portType;
    }
    return updatedConnections[0].cloudType;
  };

  const getCloudLocation = (port) => {
    if (port.type === AZURE_LOWERCASE) {
      return port.location;
    }
    return port.labels?.cloud_region || updatedConnections[0].location;
  };

  const getBandwidth = (port, bwValue, bwUnit) => {
    if (port.type === AWS_LOWERCASE || port.type === AZURE_LOWERCASE) {
      return `${bwValue} ${bwUnit}`;
    }
    return `50 ${MBPS}`;
  };

  const port = customerPorts.find((port) => port.name === addFrom);
  if (!port) return fields;

  const updatedConnections = [...fields.connections];
  const { value, unit } = parseBandwidth(port.assigned_bw);
  updatedConnections[0] = {
    ...updatedConnections[0],
    name: addFrom,
    aws_customer_port_name: port.type === AWS_LOWERCASE ? addFrom : undefined,
    gcp_customer_port_name: port.type === GCP_LOWERCASE ? addFrom : undefined,
    azure_customer_port_name:
      port.type === AZURE_LOWERCASE ? addFrom : undefined,
    innerType:
      port.type === AWS_LOWERCASE ||
      port.type === AZURE_LOWERCASE ||
      port.type === GCP_LOWERCASE
        ? CLOUD_CONNECTIONS
        : updatedConnections[0].innerType,
    BW: getBandwidth(port, value, unit),
    max_bandwidth: getBandwidth(port, value, unit),
    location: getCloudLocation(port),
    account_id: port.labels?.account_id || updatedConnections[0].Customer_ID,
    Connection_type: "directConnect",
    cloudType: getCloudType(port.type),
    peeringLocation: port.labels?.cloud_region,
    vlan_id: undefined,
    ip_addresses: undefined,
  };

  return {
    ...fields,
    connections: updatedConnections,
  };
}
