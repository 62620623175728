import { useEffect, useMemo } from "react";
import { configApi } from "../../helpers/api/ConfigApi";
import { useFetch } from "../../helpers/hooks/useFetch";
import {
  getCombinedAwsInfo,
  getCombinedAzureInfo,
  getCombinedGCPInfo,
} from "./helpers";
import { systemApi } from "../../helpers/api/SystemApi";
import {
  AwsCloudCredentials,
  AzureCloudCredentials,
  CreateAWSNNI,
  CreateAzureNNI,
  CreateGCPNNI,
  GCPCloudCredentials,
} from "./types";

const CACHE_CLOUD_DATA_TIME = 1000 * 60 * 5;

export const useCloudResources = () => {
  // AWS
  const {
    state: awsState,
    fetch: getAwsState,
    refetch: refetchAwsState,
  } = useFetch({
    fetchFn: configApi.getCloudConnectAWS.bind(configApi),
    cache: {
      enabled: true,
      ttl: CACHE_CLOUD_DATA_TIME,
      cacheKey: "cloud-resources-aws-state",
    },
  });

  const { fetch: createAWSNNI } = useFetch({
    fetchFn: configApi.createCloudConnectAWS.bind(configApi),
  });

  const { fetch: deleteAWSNNI, state: deleteAWSNNIState } = useFetch({
    fetchFn: configApi.deleteCloudConnectAWS.bind(configApi),
    shouldResetState: true,
  });

  const { fetch: createAwsKey } = useFetch({
    fetchFn: systemApi.createAwsKey.bind(systemApi),
  });

  const {
    state: awsKeys,
    fetch: getAwsKeys,
    refetch: refetchAwsKeys,
  } = useFetch({
    fetchFn: systemApi.getAwsKeys.bind(systemApi),
    isEnabled: true,
    cache: {
      enabled: true,
      ttl: CACHE_CLOUD_DATA_TIME,
      cacheKey: "cloud-resources-aws-keys",
    },
  });

  const { fetch: deleteAwsKey } = useFetch({
    fetchFn: systemApi.deleteAwsKey.bind(systemApi),
  });

  const { fetch: updateAwsKey } = useFetch({
    fetchFn: systemApi.updateAwsKey.bind(systemApi),
  });

  const combinedAwsInfo = useMemo(() => {
    return getCombinedAwsInfo(awsState.result?.items ?? []);
  }, [awsState]);

  const activeAwsKey = awsKeys.result?.items?.find((key) => key.is_active);

  // AZURE
  const {
    state: azureState,
    fetch: getAzureState,
    refetch: refetchAzureState,
  } = useFetch({
    fetchFn: configApi.getCloudConnectAzure.bind(configApi),
    cache: {
      enabled: true,
      ttl: CACHE_CLOUD_DATA_TIME,
      cacheKey: "cloud-resources-azure-state",
    },
  });

  const { fetch: createAzureNNI } = useFetch({
    fetchFn: configApi.createCloudConnectAzure.bind(configApi),
  });

  const { fetch: deleteAzureNNI, state: deleteAzureNNIState } = useFetch({
    fetchFn: configApi.deleteCloudConnectAzure.bind(configApi),
    shouldResetState: true,
  });

  const { fetch: createAzureKey } = useFetch({
    fetchFn: systemApi.createAzureKey.bind(systemApi),
  });

  const {
    state: azureKeys,
    fetch: getAzureKeys,
    refetch: refetchAzureKeys,
  } = useFetch({
    fetchFn: systemApi.getAzureKeys.bind(systemApi),
    isEnabled: true,
    cache: {
      enabled: true,
      ttl: CACHE_CLOUD_DATA_TIME,
      cacheKey: "cloud-resources-azure-keys",
    },
  });

  const { fetch: deleteAzureKey } = useFetch({
    fetchFn: systemApi.deleteAzureKey.bind(systemApi),
  });

  const { fetch: updateAzureKey } = useFetch({
    fetchFn: systemApi.updateAzureKey.bind(systemApi),
  });

  const azureNNIOptions = useMemo(
    () =>
      azureState.result?.items.filter(
        (item) => item.primary_system_name === "null"
      ),
    [azureState]
  );

  const azureNNIs = useMemo(
    () =>
      azureState.result?.items.filter(
        (item) => item.primary_system_name !== "null"
      ),
    [azureState]
  );

  const combinedAzureInfo = useMemo(() => {
    return getCombinedAzureInfo(azureNNIs ?? []);
  }, [azureState]);

  const activeAzureKey = azureKeys.result?.items?.find((key) => key.is_active);

  // GCP
  const {
    state: GCPState,
    fetch: getGCPState,
    refetch: refetchGCPState,
  } = useFetch({
    fetchFn: configApi.getCloudConnectGCP.bind(configApi),
    cache: {
      enabled: true,
      ttl: CACHE_CLOUD_DATA_TIME,
      cacheKey: "cloud-resources-gcp-state",
    },
  });

  const { fetch: createGCPNNI } = useFetch({
    fetchFn: configApi.createCloudConnectGCP.bind(configApi),
  });

  const { fetch: deleteGCPNNI, state: deleteGCPNNIState } = useFetch({
    fetchFn: configApi.deleteCloudConnectGCP.bind(configApi),
    shouldResetState: true,
  });

  const { fetch: createGCPKey } = useFetch({
    fetchFn: systemApi.createGCPKey.bind(systemApi),
  });

  const {
    state: GCPKeys,
    fetch: getGCPKeys,
    refetch: refetchGCPKeys,
  } = useFetch({
    fetchFn: systemApi.getGCPKeys.bind(systemApi),
    isEnabled: true,
    cache: {
      enabled: true,
      ttl: CACHE_CLOUD_DATA_TIME,
      cacheKey: "cloud-resources-gcp-keys",
    },
  });

  const { fetch: deleteGCPKey } = useFetch({
    fetchFn: systemApi.deleteGCPKey.bind(systemApi),
  });

  const { fetch: updateGCPKey } = useFetch({
    fetchFn: systemApi.updateGCPKey.bind(systemApi),
  });

  const GCPNNIOptions = useMemo(
    () => GCPState.result?.items.filter((item) => item.primary_nni === "null"),
    [GCPState]
  );

  const GCPNNIs = useMemo(
    () => GCPState.result?.items.filter((item) => item.primary_nni !== "null"),
    [GCPState]
  );

  const combinedGCPInfo = useMemo(() => {
    return getCombinedGCPInfo(GCPNNIs ?? []);
  }, [GCPState]);

  const activeGCPKey = GCPKeys.result?.items?.find((key) => key.is_active);

  useEffect(() => {
    // get clouds sequentially otherwise it's failing
    const getCloudStates = async () => {
      await getAwsState();
      await getAzureState();
      await getGCPState();
    };

    getCloudStates();
  }, []);

  return {
    // AWS
    awsState,
    createAWSNNI: async (params?: CreateAWSNNI | undefined) => {
      const res = await createAWSNNI(params);
      refetchAwsState();
      return res;
    },
    deleteAWSNNI: async (id?: string) => {
      const res = await deleteAWSNNI(id);
      refetchAwsState();
      return res;
    },
    deleteAWSNNIState,
    combinedAwsInfo,
    createAwsKey: async (params?: AwsCloudCredentials | undefined) => {
      const res = await createAwsKey(params);
      refetchAwsKeys();
      return res;
    },
    activeAwsKey,
    deleteAwsKey: async (params?: number | undefined) => {
      const res = await deleteAwsKey(params);
      refetchAwsKeys();
      return res;
    },
    updateAwsKey: async (id?: number, params?: AwsCloudCredentials) => {
      const res = await updateAwsKey({ id, params });
      refetchAwsKeys();
      return res;
    },
    getAwsKeys,
    isAwsLoading: awsState.isLoading && !awsState.isRefetching,
    isAwsRefetching: !!awsState.isRefetching || !!awsKeys.isRefetching,
    refetchAwsState: async () => {
      refetchAwsState();
      refetchAwsKeys();
    },
    // AZURE
    getAzureKeys,
    createAzureKey: async (params?: AzureCloudCredentials | undefined) => {
      const res = await createAzureKey(params);
      refetchAzureKeys();
      return res;
    },
    deleteAzureKey: async (params?: number | undefined) => {
      const res = await deleteAzureKey(params);
      refetchAzureKeys();
      return res;
    },
    updateAzureKey: async (id?: number, params?: AzureCloudCredentials) => {
      const res = await updateAzureKey({ id, params });
      refetchAzureKeys();
      return res;
    },
    activeAzureKey,
    combinedAzureInfo,
    createAzureNNI: async (params?: CreateAzureNNI | undefined) => {
      const res = await createAzureNNI(params);
      refetchAzureState();
      return res;
    },
    deleteAzureNNI: async (id?: string) => {
      const res = await deleteAzureNNI(id);
      refetchAzureState();
      return res;
    },
    deleteAzureNNIState,
    azureNNIOptions,
    azureNNIs,
    isAzureLoading: azureState.isLoading && !azureState.isRefetching,
    isAzureRefetching: !!azureState.isRefetching || !!azureKeys.isRefetching,
    refetchAzureState: async () => {
      refetchAzureState();
      refetchAzureKeys();
    },
    // GCP
    getGCPKeys,
    createGCPKey: async (params?: GCPCloudCredentials | undefined) => {
      const res = await createGCPKey(params);
      refetchGCPKeys();
      return res;
    },
    deleteGCPKey: async (params?: number | undefined) => {
      const res = await deleteGCPKey(params);
      refetchGCPKeys();
      return res;
    },
    updateGCPKey: async (id?: number, params?: GCPCloudCredentials) => {
      const res = await updateGCPKey({ id, params });
      refetchGCPKeys();
      return res;
    },
    activeGCPKey,
    combinedGCPInfo,
    createGCPNNI: async (params?: CreateGCPNNI | undefined) => {
      const res = await createGCPNNI(params);
      refetchGCPState();
      return res;
    },
    deleteGCPNNI: async (id?: string) => {
      const res = await deleteGCPNNI(id);
      refetchGCPState();
      return res;
    },
    deleteGCPNNIState,
    GCPNNIOptions,
    GCPNNIs,
    isGCPLoading: GCPState.isLoading && !GCPState.isRefetching,
    isGCPRefetching: !!GCPState.isRefetching || !!GCPKeys.isRefetching,
    refetchGCPState: async () => {
      refetchGCPState();
      refetchGCPKeys();
    },
  };
};
