import { Fragment, ReactNode, useRef, useState } from "react";
import { ButtonProps } from "../Button";
import styles from "./ButtonList.module.scss";
import AddButton from "../AddButton";

type ButtonListProps = Omit<ButtonProps, "onClick" | "children"> & {
  list: Array<ReactNode>;
};

export const ButtonList = ({ list, ...buttonProps }: ButtonListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef<any>(null);

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 300); // Delay to allow moving cursor to the popup
  };

  return (
    <div className={styles.wrapper}>
      <AddButton
        onClick={() => {
          return null;
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...buttonProps}
      />

      {isOpen && (
        <div
          className={styles.dropdown}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {list.map((el, index) => (
            <Fragment key={index}>{el}</Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
