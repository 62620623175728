import React, { FC, Fragment, useState } from "react";
import { NewDialogProps } from "../../../../helpers/types";
import DialogTemplateOneColumn from "../../../../components/dialogs/common/DialogTemplateOneColumn";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import {
  AwsCloudCredentials,
  AzureCloudCredentials,
  GCPCloudCredentials,
} from "../../types";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./CredentialsDialog.module.scss";
import { PRes } from "../../../../helpers/api/Api";
import KeyIcon from "../../../../components/icons/KeyIcon";
import { useAwsForm } from "./components";
import { ProvidersTabs } from "../ProvidersTabs";
import { useAzureForm } from "./components/AzureForm";
import { useGCPForm } from "./components/GCPForm";

type CredentialsDialogProps = {
  createAwsKey: (params?: AwsCloudCredentials) => PRes<any>;
  deleteAwsKey: (id?: number) => PRes<any>;
  updateAwsKey: (id?: number, params?: AwsCloudCredentials) => PRes<any>;
  activeAwsKey: AwsCloudCredentials | undefined;
  createAzureKey: (params?: AzureCloudCredentials) => PRes<any>;
  deleteAzureKey: (id?: number) => PRes<any>;
  updateAzureKey: (id?: number, params?: AzureCloudCredentials) => PRes<any>;
  activeAzureKey: AzureCloudCredentials | undefined;
  createGCPKey: (params?: GCPCloudCredentials) => PRes<any>;
  deleteGCPKey: (id?: number) => PRes<any>;
  updateGCPKey: (id?: number, params?: GCPCloudCredentials) => PRes<any>;
  activeGCPKey: GCPCloudCredentials | undefined;
} & NewDialogProps<any>;

export const CredentialsDialog: FC<CredentialsDialogProps> = ({
  onClose,
  createAwsKey,
  activeAwsKey,
  deleteAwsKey,
  updateAwsKey,
  createAzureKey,
  deleteAzureKey,
  updateAzureKey,
  activeAzureKey,
  createGCPKey,
  activeGCPKey,
  deleteGCPKey,
  updateGCPKey,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { controls: awsControls, form: awsForm } = useAwsForm({
    createAwsKey,
    deleteAwsKey,
    updateAwsKey,
    activeAwsKey,
    onClose,
  });
  const { controls: azureControls, form: azureForm } = useAzureForm({
    createAzureKey,
    deleteAzureKey,
    updateAzureKey,
    activeAzureKey,
    onClose,
  });
  const { controls: gcpControls, form: gcpForm } = useGCPForm({
    createGCPKey,
    deleteGCPKey,
    updateGCPKey,
    activeGCPKey,
    onClose,
  });

  const TAB_TO_FORM_MAP = {
    0: {
      controls: awsControls,
      form: awsForm,
    },
    1: {
      controls: azureControls,
      form: azureForm,
    },
    2: {
      controls: gcpControls,
      form: gcpForm,
    },
  };

  const { controls, form } =
    TAB_TO_FORM_MAP[activeTab as keyof typeof TAB_TO_FORM_MAP] ?? {};

  return (
    <DialogTemplateOneColumn
      header={
        <div className={styles.header}>
          <KeyIcon color={GREY_FONT} /> <span>Cloud Credentials</span>
        </div>
      }
      onClose={onClose}
      controls={controls}
      className={styles.dialog}
    >
      <ProvidersTabs activeTab={activeTab} onChangeTab={setActiveTab} />
      <Fragment key={activeTab}>{form}</Fragment>
    </DialogTemplateOneColumn>
  );
};
