import React, { FC, useEffect, useState } from "react";
import styles from "./Details.module.scss";
import NavigationTabs from "../../../../../components/common/navigation/NavigationTabs";
import AddDeviceIcon from "../../../../../components/icons/WireGuardIcons/AddDeviceIcon";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import StatusIcon from "../../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";

type Props = {
  className: string;
  details: Array<{
    name: string;
    vpnIp: string;
    tunnelStatus: string;
    handShake: string;
    connectedFrom: string;
  }>;
  activeDevice: string;
  deviceNames?: Array<string>;
};

const Details: FC<Props> = ({ className, deviceNames, details }) => {
  const handleClick = (entry: string) => {
    setActiveTunnel(entry);
  };
  const [activeTunnel, setActiveTunnel] = useState<string | undefined>();

  useEffect(() => {
    if (deviceNames) {
      setActiveTunnel(deviceNames[0]);
    }
  });
  const tunnelRaw = details?.find((obj) => obj.name === activeTunnel);
  const tunnelStatusesMap: any = {
    DOWN: "Disconnected",
    UP: "Connected",
    IDLE: "Idle",
  };
  const mapDetails = [
    { title: "VPN IP", value: tunnelRaw?.vpnIp || "-" },
    {
      title: "Tunnel status",
      value: (
        <TextWithIcon
          icon={
            <StatusIcon
              color={getColorFromStatus(
                tunnelRaw?.tunnelStatus?.toLowerCase() || "-"
              )}
            />
          }
          text={
            tunnelRaw?.tunnelStatus != undefined
              ? tunnelStatusesMap[tunnelRaw?.tunnelStatus]
              : "-"
          }
        />
      ),
    },
    { title: "Latest handshake", value: tunnelRaw?.handShake || "-" },
    { title: "Connected from", value: tunnelRaw?.connectedFrom || "-" },
  ];
  if (!deviceNames || !details) return <PageLoader />;
  return (
    <>
      <div className={className}>
        <div className={styles.devicesRow}>
          <NavigationTabs
            entries={deviceNames || []}
            onStringClick={handleClick}
            active={activeTunnel}
            className={styles.navigation}
          />
          <AddDeviceIcon />
        </div>
        <ul className={styles.list}>
          {mapDetails.map((item) => (
            <li className={styles.listItem} key={item.title}>
              <span className={styles.title}>{item.title}</span>
              <span className={styles.value}>{item.value}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Details;
