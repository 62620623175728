import { FC, useEffect, useMemo, useState } from "react";

import { UserTableColumns } from "./UserTableColumns";
import { User } from "../../../../helpers/types";
import {
  GLOBAL_TENANT,
  useUserListContext,
} from "../../../../contexts/UserListContext";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import Table, {
  DEFAUTL_LIMIT,
  DEFAUTL_OFFSET,
} from "../../../../components/common/table/newTable/Table";
import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import AddButton from "../../../../components/common/buttons/AddButton";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./UserTable.module.scss";
import UserDetails from "../UserDetails/UserDetails";
import { classNames } from "../../../../helpers/common/classNames";
import Config from "../UserDetails/components/Config";
import { capitalizeFirstLetter } from "../../../../helpers/common/stringHelpers";
import { useWireGuardContext } from "../../../../components/common/WireGuardComponents/WireGuardContext";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import Button from "../../../../components/common/buttons/Button";
import AddIcon from "../../../../components/icons/AddIcon";
import { PURPLE } from "../../../../helpers/common/colorAliases";
import { useUserContext } from "../../../../contexts/UserContext";

type Props = {
  onAdd: () => void;
  onEdit?: (user: User) => void;
  onDelete?: (user: User) => void;
  openAddIdentity: () => void;
  openRemoteStatus: () => void;
};

const convertUsersListToTable = (list: Array<User>) => {
  return list.map((user: User) => ({
    ...user,
    role: capitalizeFirstLetter(user?.role),
  }));
};

export const UserTable: FC<Props> = ({
  onAdd,
  onDelete,
  onEdit,
  openAddIdentity,
  openRemoteStatus,
}) => {
  const {
    fetchUserList,
    fetchCurrSystem,
    userList,
    currSystem,
    listFetchRequest,
    updateUser,
    userRequest,
  } = useUserListContext();
  const { isZayo } = useUserContext();
  const { createWGTunnel, deleteWGTunnel } = useWireGuardContext();
  const { selectedTenant } = useGlobalFilterContext();
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    selectedTenant && fetchUserList(offset, limit, selectedTenant, currSystem);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (selectedTenant) {
        await fetchCurrSystem(selectedTenant);
        fetchWrapper(undefined, undefined);
      }
    };
    fetchUserData();
  }, [selectedTenant]);

  const handleRowClick = (row: User) => {
    const newUser = row.username === selectedUser?.username ? undefined : row;
    setSelectedUser(newUser);
  };

  const handleEditUser = async (
    field: string,
    value: boolean,
    username: string,
    editUser?: User
  ) => {
    if (
      !selectedTenant ||
      (editUser?.role === "remote_user" && field === "is_remote_user")
    )
      return;
    await updateUser(selectedTenant, { username, [field]: value });
    if (field === "is_remote_user") {
      if (value) {
        await fetchCurrSystem(selectedTenant);
        if (currSystem)
          await createWGTunnel(selectedTenant, username, currSystem);
      } else {
        await deleteWGTunnel(selectedTenant, username);
      }
    }
  };

  const isEmpty = !userList?.length;
  const wrapperClasses = classNames(
    styles.wrapper,
    selectedUser && styles.twoCols
  );

  const isGlobalAdmin = useMemo(() => selectedTenant === GLOBAL_TENANT, [
    selectedTenant,
  ]);

  const tableButtons = (
    <div className={styles.btnGroup}>
      {!isZayo && (
        <>
          <Button onClick={openRemoteStatus} className={styles.identityBtn}>
            <div className={styles.contentWrapper}>Remote User Status</div>
          </Button>
          <Button onClick={openAddIdentity} className={styles.identityBtn}>
            {
              <div className={styles.contentWrapper}>
                <AddIcon color={PURPLE} className={styles.icon} />
                Add Identity Provider
              </div>
            }
          </Button>
        </>
      )}
      <AddButton onClick={onAdd} />
    </div>
  );

  return (
    <TableWrapper
      isEmpty={isEmpty}
      dataStatus={listFetchRequest}
      titleProps={{
        title: !isEmpty ? `Users (${userList?.length || 0})` : "No users",
        children: tableButtons,
      }}
    >
      {userRequest.state === "pending" ? (
        <PageLoader />
      ) : (
        <div className={wrapperClasses}>
          <div className={styles.table}>
            <Table
              fetchAction={fetchWrapper}
              header={TableHeader}
              columns={UserTableColumns(
                handleRowClick,
                handleEditUser,
                isGlobalAdmin,
                selectedUser?.username
              )}
              data={convertUsersListToTable(userList)}
              gridColumnTemplate={
                (selectedUser &&
                  `60px 120px 1fr 125px 1fr 1fr 100px 120px 70px 40px`) ||
                `80px 200px 1fr 200px 1fr 1fr 80px 140px 70px 40px`
              }
              rowActions={{
                onEdit,
                onDelete,
              }}
              isRowHighlighted={(row) =>
                row.username === selectedUser?.username
              }
            />
          </div>
          {selectedUser && selectedTenant && (
            <div className={styles.wrapper}>
              <UserDetails tenant={selectedTenant} user={selectedUser} />
              <Config
                className={styles.configBox}
                user={selectedUser?.username}
                tenant={selectedTenant}
              />
            </div>
          )}
        </div>
      )}
    </TableWrapper>
  );
};
