import { useMemo } from "react";
import { AsyncDropdown } from "../../../../../../components/common/Dropdown/AsyncDropdown";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import PhysicalPortsDropdown from "../../../../../../components/common/OneSystem/PhysicalPortsDropdown";
import { configApi } from "../../../../../../helpers/api/ConfigApi";
import { formFieldProps } from "../../../../../../helpers/dialogs/FieldProps";
import { getDialogControls } from "../../../../../../helpers/getDialogControls";
import { CreateAWSNNI } from "../../../../types";
import {
  DEFAULT_FIELDS,
  NNI_BANDWIDTH_LIST,
  VLAN_POOL_MAX,
  VLAN_POOL_MIN,
} from "./constants";
import { DropdownItem } from "../../../../../../helpers/types";
import { useFormField } from "../../../../../../helpers/hooks/useFormField";
import { useValidation } from "../../../../../../helpers/validators/Validator";
import validateAwsNNI from "../../../../../../helpers/validators/CreateAwsNNIValidator";
import { PRes } from "../../../../../../helpers/api/Api";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../Forms.module.scss";
import { systemApi } from "../../../../../../helpers/api/SystemApi";
import Input from "../../../../../../components/common/formComponents/Input";
import { OptionalLabel } from "../../../../../../components/common/OptionalLabel";

type AwsFormProps = {
  createAWSNNI: (params?: CreateAWSNNI) => PRes<any>;
  onClose: () => void;
};

export const useAwsForm = ({ createAWSNNI, onClose }: AwsFormProps) => {
  const [fields, handleFieldChange] = useFormField<Partial<CreateAWSNNI>>(
    DEFAULT_FIELDS
  );

  const [errors, validate] = useValidation<Partial<CreateAWSNNI>>(
    validateAwsNNI,
    [fields]
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const { ok } = await createAWSNNI({
        ...fields,
        max_bandwidth: ((fields.max_bandwidth as unknown) as DropdownItem)?.key,
      } as CreateAWSNNI);
      if (ok) {
        onClose();
      }
    }
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const form = (
    <div className={styles.fields}>
      <Input
        {...fieldProps("nni_id")}
        label="NNI ID"
        error={errors && errors["nni_id"]}
      />
      <AsyncDropdown
        id="system_name"
        {...fieldProps("system_name")}
        fetchFn={() => systemApi.getSystemsList()}
        mapValue={(value) => ({ key: value.name, value: value.name })}
        selected={fields.system_name}
        onChange={(value) => {
          handleFieldChange("system_name", value.key);
          handleFieldChange("physical_port_name", "");
        }}
        label="System name"
        isMedium
        error={errors && errors["system_name"]}
      />
      <PhysicalPortsDropdown
        system={fields.system_name}
        selected={fields.physical_port_name}
        onSelect={(val) => handleFieldChange("physical_port_name", val)}
        id={"physical_port_name"}
        error={errors && errors["physical_port_name"]}
      />
      <AsyncDropdown
        id="site_name"
        {...fieldProps("site_name")}
        fetchFn={async () =>
          configApi.getRegions({
            connection_type: "Cloud",
            operator: "aws",
          })
        }
        mapValue={(value) => ({
          value: value.name,
          key: value.name,
          data: value,
        })}
        selected={fields.site_name}
        onChange={(value) => handleFieldChange("site_name", value.key)}
        label="Site name"
        isMedium
        error={errors && errors["site_name"]}
      />
      <DropdownBasic
        id="max_bandwidth"
        {...fieldProps("max_bandwidth")}
        onChange={(value) => handleFieldChange("max_bandwidth", value)}
        selected={fields.max_bandwidth}
        label="NNI Bandwidth"
        itemsList={NNI_BANDWIDTH_LIST}
        isMedium
        error={errors && errors["max_bandwidth"]}
      />
      <OptionalLabel text="VLAN Pool" isShown className={styles.vlanPoolLabel}>
        <div className={styles.vlanPool}>
          <Input
            name="vlan_pool_from"
            value={fields.vlan_pool?.from}
            medium
            onChange={(e) =>
              handleFieldChange("vlan_pool", {
                ...fields.vlan_pool,
                from: +e.target.value,
              })
            }
            type="number"
            min={VLAN_POOL_MIN}
            max={VLAN_POOL_MAX}
            error={errors && errors["vlan_pool_from"]}
          />
          <span>-</span>
          <Input
            name="vlan_pool_to"
            value={fields.vlan_pool?.to}
            medium
            onChange={(e) =>
              handleFieldChange("vlan_pool", {
                ...fields.vlan_pool,
                to: +e.target.value,
              })
            }
            type="number"
            min={VLAN_POOL_MIN}
            max={VLAN_POOL_MAX}
            error={errors && errors["vlan_pool_to"]}
          />
        </div>
      </OptionalLabel>
    </div>
  );

  return { controls, form };
};
