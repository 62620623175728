import React, { FC } from "react";
import Table from "../../../../../../components/common/table/newTable/Table";
import TableHeader from "../../../../../../components/common/table/newTable/rows/TableHeader";
import TableWrapper from "../../../../../../components/common/table/newTable/layout/TableWrapper";
import { SiteRegion } from "../../../../../../helpers/api/apiTypes";
import styles from "./BuildingTable.module.scss";
import { buildingsTableColumns } from "./buildingsTableColumns";

type Props = {
  regions: Array<SiteRegion>;
  onSelect: (val: string) => void;
  hide: (val: boolean) => void;
  isDC?: boolean;
};

export const BuildingTable: FC<Props> = ({ regions, onSelect, hide, isDC }) => {
  const rowClick = (row: SiteRegion) => {
    onSelect(row.name);
    hide(true);
  };

  return (
    <TableWrapper
      titleProps={{
        title: ``,
        noTitle: true,
      }}
      isEmpty={!regions.length}
      className={styles.tableBlock}
    >
      <Table
        header={TableHeader}
        columns={buildingsTableColumns(isDC)}
        data={regions}
        gridColumnTemplate={"150px 70px auto 10px"}
        withoutPagination
        rowClassName={styles.rowClassName}
        onClick={rowClick}
      />
    </TableWrapper>
  );
};
