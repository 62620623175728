import React from "react";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import WarningCircleIcon from "../../../../components/icons/WarningCircleIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";

import styles from "./InfoPanel.module.scss";

const InfoPanel = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.panelHeader}>
        <TextWithIcon
          icon={<WarningCircleIcon height={20} color={GREY_FONT} />}
          text={"Information"}
        />
      </div>
      <div className={styles.description}>
        <div className={styles.sectionHeader}>Looking Glass</div>
        <div className={styles.text}>
          <div className={styles.mainText}>
            <p>
              This tool allows you to test reachability of your network segments
              and hosts.
            </p>
            <p>
              Usage:
              <b>1. Choose a test type:</b>
              PING: Send ICMP ping to test host reachability TRACE: Send ICMP
              traceroute to show hops on the path
              <b>2. Choose a target IP</b>
              The target can be any IP inside your network or in the internet,
              if internet access is available
              <b>3. Chose a source site</b>
              The test will be run from this site
              <b>4. Click “Run”</b>
            </p>
            <p>
              The output of the test will appear on the screen. This can take up
              to 20 seconds.
            </p>
          </div>
          <div className={styles.tip}>
            <i>Tip:</i> If a test fails, make sure there is a firewall rule
            allowing ICMP traffic for the target in place!
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPanel;
