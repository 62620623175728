import { Dispatch, FC } from "react";

type Props = {
  group: any;
  appliances: Array<any>;
  device: any;
  setDevice: Dispatch<string>;
};

const Group: FC<Props> = ({ group, appliances, device, setDevice }) => {
  return (
    <div>
      <h5>{group.name}</h5>
      <ul>
        {appliances.map((appliance) => {
          const isActive = device === appliance.id;
          return (
            <li
              key={appliance.id}
              className={isActive ? "active" : ""}
              onClick={() => setDevice(isActive ? "" : appliance.id)}
            >
              {appliance.hostName}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Group;
