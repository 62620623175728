import React, { useEffect, useMemo, useState } from "react";
import { useTenantVirtualInterfaceContext } from "../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import { withLayout } from "../../../helpers/hocs/withLayout";
import AddButton from "../../common/buttons/AddButton";
import { OneTenantTabs } from "../../common/navigation/OneTenantTabs";
import TableWrapper from "../../common/table/newTable/layout/TableWrapper";
import TableHeader from "../../common/table/newTable/rows/TableHeader";
import Table from "../../common/table/newTable/Table";
import { l2InterfacesTableHeader } from "../../common/table/TableHeaders/Systems/l2InterfacesTableHeader";
import L2MeteringDialog from "../../dialogs/L2Meetering/L2MeteringDialog";
import { CollapsableTwoColumnLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import TenantInfo from "./TenantInfo";

import styles from "./TenantL2Interfaces.module.scss";
import { ActionFuncs } from "../../common/table/newTable/RowControls";
import { OneSystemType } from "../../../helpers/api/apiTypes";

const TenantL2Interfaces: React.FC = () => {
  const {
    fetchMembersList,
    membersList,
    membersStatus,
    domainsList,
    deleteL2,
  } = useTenantVirtualInterfaceContext();

  const [viewItem, setViewItem] = useState<any>();
  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    fetchMembersList();
  }, []);

  const rowActions: ActionFuncs<OneSystemType> = useMemo(
    () => ({
      onEdit: setViewItem,
      onDelete: (rowOriginal) => {
        const { system_name, physical_port_name, id, alias } = rowOriginal;

        if (system_name && physical_port_name && id && alias) {
          deleteL2(system_name, physical_port_name, id, alias);
        }
      },
    }),
    [setViewItem, deleteL2]
  );

  // only once we find the first populated domain so we know where to put column titles
  const firstDomainWithDataIndex = useMemo(
    () =>
      domainsList?.findIndex((domain) =>
        membersList?.some((member) => member.domain === domain)
      ),
    [domainsList, membersList]
  );

  return (
    <>
      {viewItem && domainsList && (
        <L2MeteringDialog
          onClose={() => setViewItem(undefined)}
          type={"edit"}
          data={viewItem}
          systemsList={domainsList}
        />
      )}
      {showAdd && domainsList && (
        <L2MeteringDialog
          onClose={() => setShowAdd(false)}
          type={"add"}
          systemsList={domainsList}
        />
      )}
      <CollapsableTwoColumnLayout InfoBlock={() => <TenantInfo />}>
        <div className={styles.contentWrapper}>
          <OneTenantTabs />
          <TableWrapper
            titleProps={{
              title: `${(membersList || []).length} Interfaces`,
              children: <AddButton onClick={() => setShowAdd(true)} />,
            }}
            dataStatus={membersStatus}
            tablePlaceholder={["Interfaces", "Interface"]}
            isEmpty={membersList && membersList.length === 0}
          >
            {domainsList?.map((domain, i) => {
              const data = membersList?.filter(
                (member) => domain === member.domain
              );
              if (!data.length) return null;
              return (
                <Table
                  key={domain}
                  extraTitle={domain}
                  columns={l2InterfacesTableHeader()}
                  header={
                    i === firstDomainWithDataIndex ? TableHeader : undefined
                  }
                  data={data}
                  gridColumnTemplate={`repeat(8, 1fr) 0`}
                  rowActions={rowActions}
                  totalCount={data.length}
                />
              );
            })}
          </TableWrapper>
        </div>
      </CollapsableTwoColumnLayout>
    </>
  );
};

export default withLayout(TenantL2Interfaces);
