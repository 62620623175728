import { useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import { CONFIGURATION, REPORTS } from "../../helpers/navigation/entries";
import { PageProps, PathEntry } from "../../helpers/types";
import { Routes } from "../common";
import { RouteEntry } from "../types";
import NATRulesOverview from "./components/NATRulesOverview/NATRulesOverview";
import NatRulesStatistics from "./components/NATRulesStatistics/NATRulesStatistics";

const NATRulesPage = ({ tabs: Tabs }: PageProps) => {
  const path = useMatchPath();

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={fullPath(CONFIGURATION())} />
      </Route>
      <Routes
        routes={natRoutes}
        tabs={Tabs}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default NATRulesPage;

const NATOverview = ({ tabs: Tabs }: PageProps) => {
  return <NATRulesOverview tabs={Tabs} />;
};
const NATStatistics = ({ tabs: Tabs }: PageProps) => {
  return <NatRulesStatistics tabs={Tabs} />;
};

const natRoutes: Array<RouteEntry> = [
  {
    pathFunc: REPORTS,
    component: NATStatistics,
  },
  {
    pathFunc: CONFIGURATION,
    component: NATOverview,
  },
];
