import React, { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./TableColumnHeaders.module.scss";
import { HeaderGroup } from "react-table";
import { CHECKBOX_ID } from "./Table";
import CaretUp from "../../icons/CaretUp";
import CaretDown from "../../icons/CaretDown";
import { SortingTypes } from "../../../helpers/types";
import { getSortingArrowColor } from "../../../helpers/common/colorHelpers";

type TableColumnHeadersProps = {
  headerGroup: HeaderGroup;
  grid: React.CSSProperties;
  className?: string;
  withSort?: boolean;
};

const TableColumnHeaders: FC<TableColumnHeadersProps> = ({
  headerGroup,
  grid,
  className,
  withSort,
}) => {
  const tableHeader = classNames(styles.tableHeader, className);

  return (
    <div
      {...headerGroup.getHeaderGroupProps()}
      style={grid}
      className={tableHeader}
    >
      {headerGroup.headers.map((column: any) => (
        <div
          key={column.id}
          {...column.getHeaderProps(column.getSortByToggleProps())}
          className={styles.th}
        >
          {column.render("Header")}
          {withSort && column["Header"] && column.id !== CHECKBOX_ID && (
            <div className={styles.sortingWrapper}>
              <CaretUp
                color={getSortingArrowColor(
                  column.isSortedDesc,
                  SortingTypes.Asc
                )}
              />

              <CaretDown
                color={getSortingArrowColor(
                  column.isSortedDesc,
                  SortingTypes.Desc
                )}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TableColumnHeaders;
