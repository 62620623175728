import React, { FC, useState } from "react";
import { UserTable } from "./components/Table/UserTable";
import { UserDialog } from "./components/Dialogs/UserDialog";
import { UserEditMode } from "./types/user";
import UserListContextContainer, {
  GLOBAL_TENANT,
  useUserListContext,
} from "../../contexts/UserListContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { PathEntry, User } from "../../helpers/types";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import LayoutComponent from "../../components/common/LayoutComponent";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { withContexts } from "../../helpers/hocs/withContexts";
import { ConnectionContextContainer } from "../Network/Connections/ConnectionContext";
import { TenantsTab } from "../../components/common/navigation/Tenants/TenantsTab";
import WireGuardContextContainer, {
  useWireGuardContext,
} from "../../components/common/WireGuardComponents/WireGuardContext";
import IdentityDialog from "./components/Dialogs/IdentityDialog";
import { ADD } from "../../helpers/common/constantsAlias";
import { Redirect } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";

export type Props = {
  customRoot?: Array<PathEntry>;
};

const UserPage: FC<Props> = ({ customRoot }) => {
  const {
    selectUser,
    deleteUser,
    unselectUser,
    addUser,
    updateUser,
    resetUserRequest,
    fetchCurrSystem,
    currSystem,
    userRequest,
  } = useUserListContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { createWGTunnel } = useWireGuardContext();
  const { isZayo } = useUserContext();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [identityDialogIsOpen, setIdentityDialogIsOpen] = useState(false);
  const [remoteStatusIsOpen, setRemoteStatusIsOpen] = useState(false);
  const [userEditMode, setUserEditMode] = useState<UserEditMode>("add");

  const handleUserDelete = (user: User | null) => {
    if (!user || !selectedTenant) {
      return;
    }

    deleteUser(selectedTenant, user.username);
  };

  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<User | null>(handleUserDelete, userRequest, () =>
    closeDialogFrame()
  );

  const closeDialogFrame = () => {
    setDialogIsOpen(false);
    resetUserRequest();
    unselectUser();
  };

  const handleUserAdd = async (data: User) => {
    if (!selectedTenant) return;
    const addUserRes = await addUser(
      { ...data, role: data.role.toLowerCase() },
      selectedTenant
    );

    if (addUserRes) {
      closeDialogFrame();
    }

    if (addUserRes && data.is_remote_user) {
      await fetchCurrSystem(selectedTenant);
      if (currSystem)
        await createWGTunnel(selectedTenant, data.username, currSystem);
    }
  };

  const handleUserEdit = async (user: Partial<User>) => {
    if (!selectedTenant) {
      return;
    }

    const isOk = await updateUser(selectedTenant, user);

    if (isOk) {
      closeDialogFrame();
    }
  };

  const openUserEditDialog = (user: User) => {
    // todo: save editing user
    selectUser(user.id);
    setUserEditMode("edit");
    setDialogIsOpen(true);
  };

  const openUserAddDialog = () => {
    setUserEditMode("add");
    setDialogIsOpen(true);
  };

  const openAddIdentity = () => {
    setIdentityDialogIsOpen(true);
  };

  const openRemoteStatus = () => {
    setRemoteStatusIsOpen(true);
  };

  if (identityDialogIsOpen) {
    return (
      <LayoutComponent>
        <IdentityDialog
          type={ADD}
          onClose={() => setIdentityDialogIsOpen(false)}
        />
      </LayoutComponent>
    );
  }

  if (remoteStatusIsOpen) {
    return <Redirect to="remoteStatus" />;
  }

  return (
    <LayoutComponent>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"User page"}
        name={selectedToDelete?.username}
      />
      {dialogIsOpen && (
        <UserDialog
          mode={userEditMode}
          onAdd={handleUserAdd}
          onClose={closeDialogFrame}
          onDelete={tryDelete}
          onEdit={handleUserEdit}
        />
      )}
      {(!isZayo || !customRoot) && (
        <TenantsTab
          defaultTenants={[{ name: GLOBAL_TENANT }]}
          remoteUsersRoot={customRoot}
        />
      )}
      <UserTable
        onAdd={openUserAddDialog}
        openAddIdentity={openAddIdentity}
        onEdit={openUserEditDialog}
        onDelete={tryDelete}
        openRemoteStatus={openRemoteStatus}
      />
    </LayoutComponent>
  );
};

export default withContexts<Props>(UserPage, [
  UserListContextContainer,
  WireGuardContextContainer,
  ConnectionContextContainer,
]);
