import { useMemo, useState } from "react";
import { AsyncDropdown } from "../../../../../../components/common/Dropdown/AsyncDropdown";
import PhysicalPortsDropdown from "../../../../../../components/common/OneSystem/PhysicalPortsDropdown";
import { formFieldProps } from "../../../../../../helpers/dialogs/FieldProps";
import { getDialogControls } from "../../../../../../helpers/getDialogControls";
import { AzureNNI, CreateAzureNNI } from "../../../../types";
import { DEFAULT_FIELDS, VLAN_OFFSET_MAX, VLAN_OFFSET_MIN } from "./constants";
import { useFormField } from "../../../../../../helpers/hooks/useFormField";
import { useValidation } from "../../../../../../helpers/validators/Validator";
import { PRes } from "../../../../../../helpers/api/Api";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../Forms.module.scss";
import { systemApi } from "../../../../../../helpers/api/SystemApi";
import Input from "../../../../../../components/common/formComponents/Input";
import validateAzureNNI from "../../../../../../helpers/validators/CreateAzureNNIValidator";
import DropdownWithSearch from "../../../../../../components/common/Dropdown/DropdownWithSearch";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import { useFetch } from "../../../../../../helpers/hooks/useFetch";
import { configApi } from "../../../../../../helpers/api/ConfigApi";
import { createAzureSiteObject } from "./helpers";

type AzureFormProps = {
  createAzureNNI: (params?: CreateAzureNNI) => PRes<any>;
  azureNNIOptions: Array<AzureNNI>;
  onClose: () => void;
};

const getBandwidthLabel = (bandwidth: number | undefined) =>
  bandwidth ? Math.floor(bandwidth / 1000) : undefined;

const getBandwidthValue = (bandwidth: number | undefined) =>
  bandwidth ? bandwidth * 1000 : undefined;

export const useAzureForm = ({
  createAzureNNI,
  onClose,
  azureNNIOptions,
}: AzureFormProps) => {
  const { fetch: createSite } = useFetch({
    fetchFn: configApi.createSite.bind(configApi),
  });

  const [fields, handleFieldChange] = useFormField<Partial<CreateAzureNNI>>(
    DEFAULT_FIELDS
  );
  const [primarySystemLocation, setPrimarySystemLocation] = useState("");
  const selectedNNI = useMemo(
    () => azureNNIOptions.find((option) => option.nni_id === fields.nni_id),
    [fields.nni_id]
  );
  const bandwidth = getBandwidthLabel(selectedNNI?.azure_bandwidth);

  const [errors, validate] = useValidation<Partial<CreateAzureNNI>>(
    validateAzureNNI(bandwidth),
    [fields]
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const { ok } = await createAzureNNI({
        ...fields,
        max_bandwidth: getBandwidthValue(fields.max_bandwidth),
      } as CreateAzureNNI);
      if (ok) {
        createSite(
          createAzureSiteObject(fields as CreateAzureNNI, primarySystemLocation)
        );
        onClose();
      }
    }
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const form = (
    <div className={styles.fields}>
      <DropdownWithSearch
        label="NNI ID"
        id="nni_id"
        onChange={(val) => {
          handleFieldChange("nni_id", val.key);
          const nni = azureNNIOptions.find(
            (option) => option.nni_id === val.key
          );
          handleFieldChange("peering_location", nni?.peering_location);
          handleFieldChange(
            "max_bandwidth",
            getBandwidthLabel(nni?.azure_bandwidth)
          );
        }}
        selected={mapStringToItem(fields.nni_id || "")}
        valuesList={azureNNIOptions.map((item) => item.nni_id)}
        isMedium
        error={(errors && errors["nni_id"]) ?? ""}
        listClassName={styles.dropdown}
        placeholder={fields.nni_id || ""}
      />
      <AsyncDropdown
        id="primary_system_name"
        {...fieldProps("primary_system_name")}
        fetchFn={() => systemApi.getSystemsList()}
        mapValue={(value) => ({ key: value.name, value: value.name })}
        selected={fields.primary_system_name}
        onChange={(value) => {
          handleFieldChange("primary_system_name", value.key);
          handleFieldChange("primary_physical_port_name", "");
          setPrimarySystemLocation(value.data?.location ?? "");
        }}
        label="Primary System"
        isMedium
        error={errors && errors["primary_system_name"]}
      />
      <AsyncDropdown
        id="secondary_system_name"
        {...fieldProps("secondary_system_name")}
        fetchFn={() => systemApi.getSystemsList()}
        mapValue={(value) => ({ key: value.name, value: value.name })}
        selected={fields.secondary_system_name}
        onChange={(value) => {
          handleFieldChange("secondary_system_name", value.key);
          handleFieldChange("secondary_physical_port_name", "");
        }}
        label="Secondary System"
        isMedium
        error={errors && errors["secondary_system_name"]}
      />
      <Input
        {...fieldProps("peering_location")}
        label="Peering Location"
        error={errors && errors["peering_location"]}
        disabled
      />
      <Input
        {...fieldProps("max_bandwidth")}
        label="NNI Bandwidth"
        error={errors && errors["max_bandwidth"]}
        disabled={bandwidth === undefined}
      />
      <PhysicalPortsDropdown
        label="Primary Port"
        system={fields.primary_system_name}
        selected={fields.primary_physical_port_name}
        onSelect={(val) => handleFieldChange("primary_physical_port_name", val)}
        id={"primary_physical_port_name"}
        error={errors && errors["primary_physical_port_name"]}
      />
      <PhysicalPortsDropdown
        label="Secondary Port"
        system={fields.secondary_system_name}
        selected={fields.secondary_physical_port_name}
        onSelect={(val) =>
          handleFieldChange("secondary_physical_port_name", val)
        }
        id={"secondary_physical_port_name"}
        error={errors && errors["secondary_physical_port_name"]}
      />
      <Input
        {...fieldProps("s_vlan_offset")}
        label="VLAN offset"
        type="number"
        min={VLAN_OFFSET_MIN}
        max={VLAN_OFFSET_MAX}
        error={errors && errors["s_vlan_offset"]}
      />
    </div>
  );

  return { controls, form };
};
