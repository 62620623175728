import { FC, useCallback, useState } from "react";
import { useSystemProvisioningContext } from "../../ProvisioningContextContainer";
import { useTimerangeContext } from "../../../../../contexts/TimerangeContext";
import grafanaLinksFactory, {
  LinkFn,
} from "../../../../../components/common/charts/GraphanaLinksFactory";
import ChartsHeader from "../../../../../components/common/charts/ChartsHeader/ChartsHeader";
import GrafanaGraph from "../../../../../components/common/charts/GrafanaGraph";
import ChartToppersTabs from "../../../../../components/common/ChartToppersTabs";
import { TrafficType } from "../../../../../helpers/types";
import { BPS } from "../../../../../helpers/common/constantsAlias";

const SystemCharts: FC = () => {
  const { timeRange } = useTimerangeContext();
  const { system } = useSystemProvisioningContext();
  const [activeTraffic, setActiveTraffic] = useState<TrafficType>(BPS);

  const linkWithData = useCallback(
    (func: LinkFn) => {
      const data = {
        timeRange: timeRange.general,
        system: system?.name,
        systemIp: system?.address,
      };
      return func(data);
    },
    [timeRange, system]
  );

  return (
    <>
      <div>
        <ChartToppersTabs
          activeTab={activeTraffic}
          setTabActive={setActiveTraffic}
        />
        <GrafanaGraph
          link={linkWithData(
            activeTraffic === BPS
              ? grafanaLinksFactory.system_bps(timeRange.general)
              : grafanaLinksFactory.system_pps(timeRange.general)
          )}
        />
      </div>
      <div>
        <ChartsHeader title={"Incoming traffic per tenant"} />
        <GrafanaGraph
          link={linkWithData(grafanaLinksFactory.system_per_tenant_in)}
        />
      </div>
      <div>
        <ChartsHeader title={"Outgoing traffic per tenant"} />
        <GrafanaGraph
          link={linkWithData(grafanaLinksFactory.system_per_tenant_out)}
        />
      </div>
    </>
  );
};
export default SystemCharts;
