import { DropdownItem } from "./../../../../../helpers/types";
export const CONNECTION_TYPE_IDX_MAP: Array<DropdownItem> = [
  { key: "ipsec", value: "IPSec" },
  { key: "directConnect", value: "Direct Connect" },
  { key: "loopback", value: "Loopback" },
  { key: "gateway", value: "Gateway" },
];

export const CONNECTION_END_POINT_MAP: Array<DropdownItem> = [
  { key: "cloud", value: "Cloud" },
  { key: "branch", value: "Branch" },
  { key: "colocation", value: "Colocation" },
];

export const CONNECTION_END_POINT_IDX = CONNECTION_END_POINT_MAP.map(
  (el) => el.key
);

export const CONNECTION_CLOUD_OPERATOR_MAP: Array<DropdownItem> = [
  { key: "aws", value: "AWS" },
  { key: "gcp", value: "GCP" },
  { key: "azure", value: "Azure" },
  { key: "equinixMetal", value: "Equinix Metal" },
];

export const LOOPBACK_BODY = {
  name: "",
  virtual_interface_type: "loopback",
  ipv4_addresses: [],
  ipv6_addresses: [],
  description: "",
  mtu: 9000,
  administrative_state: "",
  network_segments: [],
  labels: {},
  system_name: "",
};

export const GATE_BODY = {
  name: "",
  description: "",
  virtual_interface_bridge_domain_name: "",
  is_snat_service: false,
  is_nat_service: false,
  is_ipsec_service: false,
  is_lb_service: false,
};

export const REQUESTED_GATE_VI_BODY = {
  system_name: "",
  name: "",
  type: "",
  data: "",
  ipsec_service: true,
  nat_service: true,
  snat_service: true,
  shared: true,
};

export const AWS_VPN_BODY = {
  connection_name: "",
  system_name: "",
  gate_name: "",
  network_segments: [],
  labels: {},
  vpc_id: "",
  region: "",
};

export const IPSEC_BODY = {
  name: "",
  remote_ip: "",
  remote_networks: [""],
  local_networks: [""],
  dh_groups: [18],
  encryption: "aes256gcm",
  auth: "prfsha256",
  psk: "",
  passive: false,
  udp_encap: false,
  nat_traversal: true,
  local_id_type: "ipv4",
  local_id_data: "",
  remote_id_type: "ipv4",
  remote_id_data: "",
  description: "",
  administrative_state: "up",
  virtual_interface_gate_name: "",
  network_segments: [],
  labels: {},
};

export const IPSEC_BRANCH_BODY = {
  name: "",
  remote_ip: "",
  remote_networks: [],
  local_networks: [],
  dh_groups: [18],
  encryption: "aes256gcm",
  auth: "prfsha256",
  psk: "",
  passive: false,
  udp_encap: false,
  nat_traversal: true,
  local_id_type: "ipv4",
  local_id_data: "",
  remote_id_type: "ipv4",
  remote_id_data: "",
  description: "",
  administrative_state: "up",
  virtual_interface_gate_name: "",
  network_segments: [],
  labels: {},
};

export const PHASE1_LOCAL_IDENTIFIER = ["FQDN"];
export const PHASE1_PEER_IDENTIFIER = ["FQDN"];
export const PHASE1_ALGORYTHM = {
  "AES-GSM": ["AES 256"],
  PRF: ["SHA256"],
  DH: ["18"],
  KEY_SIZE: ["256 BIT"],
};
export const PHASE2_PROTOCOL = ["ESP"];
