import React, { FC, useEffect, useMemo, useState } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import {
  OneSystemType,
  PortVlanType,
  VRFType,
} from "../../../helpers/api/apiTypes";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import Input from "../../../components/common/formComponents/Input";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { useValidation } from "../../../helpers/validators/Validator";
import validateVLAN from "../../../helpers/validators/VLANValidator";
import { NewDialogProps } from "../../../helpers/types";
import { formFieldProps } from "../../../helpers/dialogs/FieldProps";
import styles from "./VLANDialog.module.scss";
import { ADMIN_STATE } from "../../../helpers/common/constantsAlias";
import VRFDropdown from "../../../components/common/OneSystem/VRFDropdown";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
import VIDropdown from "../../../components/common/OneSystem/VIDropdown";
import { PortVlan } from "../types";
import VLANDialogContextContainer, {
  useVLANDialogContext,
} from "./VLANDIalogContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import PhysicalPortsDropdown from "../../../components/common/OneSystem/PhysicalPortsDropdown";
import { PortType } from "../../PortsPage/types";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";

const DEFAULT_FIELDS: Partial<PortVlan> = {
  administrative_state: "up",
  mtu: 9200,
  max_bandwidth: 0,
  virtual_interface_id: 0,
  virtual_interface_name: "",
  vrf_name: "",
  alias: "",
  physical_port_name: "",
  vlan_id: 0,
};

const VLANDialog: FC<NewDialogProps<PortVlanType>> = ({ onClose }) => {
  const { add, addStatus } = useVLANDialogContext();
  const { system } = useOneSystemContext();

  const [activeVI, setActiveVI] = useState<Partial<VirtualInterfaceApi>>();
  const [activePort, setActivePort] = useState<PortType>();
  const [fields, handleFieldChange] = useFormField<Partial<PortVlan>>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<Partial<PortVlanType>>(
    validateVLAN(activeVI?.mtu, activePort?.mtu),
    [fields]
  );
  const [activeVRF, setActiveVRF] = useState<VRFType | undefined>();

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk && fields.vlan_id) {
      fields.vlan_id = +fields.vlan_id;
      const success = await add(fields);
      if (success) onClose();
    }
  };

  const handleSetVRF = (val?: VRFType): void => {
    if (!val) return;
    handleFieldChange("vrf_name", val.name);
    setActiveVRF(val);
  };

  const handleSetVI = (val?: Partial<VirtualInterfaceApi>): void => {
    if (!val) return;
    handleFieldChange("virtual_interface_id", val.id);
    handleFieldChange("virtual_interface_name", val.name);
    setActiveVI(val);
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const leftColumn = <SummaryBlock title={"PORT-VLAN"} data={fields} />;

  useEffect(() => {
    if (activePort?.mtu && activeVI?.mtu) {
      handleFieldChange("mtu", Math.min(activePort.mtu, activeVI.mtu));
    } else if (activePort?.mtu) {
      handleFieldChange("mtu", activePort.mtu);
    } else if (activeVI?.mtu) {
      handleFieldChange("mtu", activeVI.mtu);
    }
  }, [activePort, activeVI]);

  return (
    <DialogTemplate
      title={"Add Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={addStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <Input {...fieldProps("alias")} label="Port Name" />
      <DropdownBasic
        id={"VLANDialogAdminState"}
        onChange={(val) => handleFieldChange("administrative_state", val.key)}
        selected={mapStringToItem(fields.administrative_state || "")}
        label="Admin State"
        itemsList={ADMIN_STATE.map(mapStringToItem)}
        isMedium
      />
      <Input {...fieldProps("vlan_id")} label="VLAN ID" />
      <PhysicalPortsDropdown
        system={system?.name}
        selected={fields.physical_port_name}
        onSelect={(val) => handleFieldChange("physical_port_name", val)}
        id={"VLANDialogPortName"}
        setSelectedPort={setActivePort}
        error={errors && errors["physical_port_name"]}
      />
      <div className={styles.viRow}>
        <VRFDropdown
          system={system as OneSystemType}
          selected={activeVRF}
          onSelect={(val) => handleSetVRF(val)}
        />
        <VIDropdown
          system={system as OneSystemType}
          vrf={activeVRF}
          selected={activeVI}
          onSelect={(val) => handleSetVI(val)}
        />
      </div>
      <Input
        {...fieldProps("mtu")}
        label="MTU"
        type="number"
        min={64}
        max={20000}
      />
    </DialogTemplate>
  );
};

export default withContexts<NewDialogProps<PortVlanType>>(VLANDialog, [
  VLANDialogContextContainer,
]);
