import { CreateAzureNNI } from "../../../../types";

export const DEFAULT_FIELDS: Partial<CreateAzureNNI> = {
  nni_id: "",
  peering_location: "",
  primary_physical_port_name: "",
  primary_system_name: "",
  secondary_physical_port_name: "",
  secondary_system_name: "",
  max_bandwidth: 0,
  s_vlan_offset: 0,
};

export const VLAN_OFFSET_ZERO = 0;
export const VLAN_OFFSET_MIN = 100;
export const VLAN_OFFSET_MAX = 4000;
