import { PRICING_PRODUCTS } from "../../../../../contexts/pricingDialogContext/constants";

export const getParams = (
  product: string | undefined,
  params: any | undefined
) => {
  if (!product || !params) return;

  const res: { name: string; vi_name?: string } = { name: "" };

  switch (product) {
    case PRICING_PRODUCTS.DIA_CONNECTION:
    case PRICING_PRODUCTS.DIRECT_CLOUD_LINK:
    case PRICING_PRODUCTS.CR_CLOUD_LINK:
    case PRICING_PRODUCTS.CLOUD_ROUTER:
    case PRICING_PRODUCTS.DIRECT_CONNECTION:
      res.name = params.connectionName;
      res.vi_name = params.connectionName;
      break;
    case PRICING_PRODUCTS.TENANT_SERVICE_PACKAGE:
      res.name = params.package;
      break;
    default:
      res.name = params.name;
      break;
  }

  return res;
};
