import {
  LookingGlassCommands,
  LookingGlassFields,
} from "../../pages/Network/LookingGlass/types";
import Validator from "./Validator";

export default function validateLookingGlass(
  data: Partial<LookingGlassFields>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.cmd, "cmd");

  validator.checkEmpty(data.ip, "ip");
  validator.checkIP(data.ip, "ip");

  validator.checkEmpty(data.location, "location");

  if (data.cmd === LookingGlassCommands.MTU) {
    validator.checkNumber(`${data.max_mtu}`, [64, 20000], "max_mtu");
    validator.checkNumber(`${data.min_mtu}`, [64, 20000], "max_mtu");
  }

  return validator;
}
