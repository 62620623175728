import { AzureCloudCredentials } from "../../pages/CloudResourcesPage/types";
import Validator from "./Validator";

export default function validateAzureCloudCredentials(
  data: Partial<AzureCloudCredentials>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.client_secret, "client_secret");
  validator.checkEmpty(data.subscription_id, "subscription_id");
  validator.checkEmpty(data.tenant_id, "tenant_id");
  validator.checkEmpty(data.client_id, "client_id");
  return validator;
}
