import { FC, useState } from "react";
import { Popover } from "react-tiny-popover";
import styles from "./Chart.module.scss";
import { colors, getLastSevenDays } from "../utils";
import { Row } from "../types";
import Details from "./Details";

type Props = {
  data: Array<[string, Array<Row>]>;
  list: Array<any>;
};

const Chart: FC<Props> = ({ data, list }) => {
  const [popoverOpened, setPopoverOpened] = useState<string>("");
  const names = list.reduce((acc, item) => {
    acc[item.id] = item.hostName;
    return acc;
  }, {});
  const days = getLastSevenDays();

  return (
    <div className="">
      <div className={styles.days}>
        {days.map((day) => {
          return <h5 key={day}>{day}</h5>;
        })}
      </div>

      {data.map((appliance) => {
        const [id, health] = appliance;
        const name = names[id];

        if (!name) return null;

        return (
          <div className={styles.healthwrap} key={id}>
            <h5>{name}</h5>

            <div className={styles.health}>
              {health.map((item) => {
                const { status, time } = item;
                const key = [id, time].join();
                const background = status ? colors[status] : "#ddd";

                return (
                  <Popover
                    key={time}
                    isOpen={popoverOpened === key}
                    onClickOutside={() => setPopoverOpened("")}
                    content={() => {
                      return <Details name={name} id={id} data={item} />;
                    }}
                  >
                    <div
                      style={{ background }}
                      onClick={() => setPopoverOpened(key)}
                    />
                  </Popover>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Chart;
