export const filterFieldsStyles = {
  control: (styles: any) => ({
    ...styles,
    borderRadius: "4px",
    minHeight: "2rem",
    height: "2rem",
    width: "200px",
    "&:hover": {
      borderColor: "#aaa",
    },
    border: "1px solid rgba(216, 218, 235, 1)",
    background:
      "linear-gradient(0deg, rgba(107, 85, 240, 0.03), rgba(107, 85, 240, 0.03)),linear-gradient(0deg, rgba(89, 83, 107, 0.02), rgba(89, 83, 107, 0.02))",
  }),
  option: (styles: any, { isFocused, isSelected }: any) => ({
    ...styles,
    backgroundColor: isSelected
      ? "rgba(107, 85, 240, 0.8)"
      : isFocused
      ? "rgba(107, 85, 240, 0.2)"
      : undefined,
    color: isSelected ? "white" : "black",
    cursor: "default",
    padding: "10px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
  menuList: (styles: any) => ({
    ...styles,
    // Scrollbars:
    "::-webkit-scrollbar": {
      width: "8px",
    },
    "::-webkit-scrollbar-track": {
      background: "rgba(107, 85, 240, 0.2)",
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgb(107, 85, 240)",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: "#6c757d",
    paddingBottom: "0.5rem",
  }),
  singleValue: (styles: any) => ({
    ...styles,
    paddingBottom: "0.5rem",
  }),
};
