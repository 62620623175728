import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";
import styles from "./ProgressIcon.module.scss";

function ProgressIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.circularLoader}
    >
      <path
        d="M5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5"
        stroke={color || FONT}
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default ProgressIcon;
