import React, { FC, useEffect, useState } from "react";
import DiaFirewallRuleInfo from "./DiaFirewallRuleInfo";
import styles from "./DiaFirewallRuleConfig.module.scss";
import { DialogType } from "../../../helpers/types";
import { FWDiaRuleType } from "../../../helpers/api/apiTypes";
import { ADD, EDIT, FW_POLICIES } from "../../../helpers/common/constantsAlias";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import {
  checkIPv4WithPrefix,
  useValidation,
} from "../../../helpers/validators/Validator";
import sendRequest from "../../../helpers/sendRequest";
import { CollapsableTwoColumnLayout } from "../../../components/leftInfoBlock/LayoutTwoColumnFactory";
import DialogBtmButtons from "../../../components/dialogs/common/DialogBtmButtons";
import { isEditMode } from "../../../helpers/isEditMode";
import { getPotocolName } from "../../../helpers/getProtocolByValue";
import RuleSection from "./RuleSection";
import {
  FW_Filters,
  generatePriority,
  getFWPreparedData,
  MAX_FW_PRIORITY_FOR_MANUAL_CREATION,
  prepareFilters,
} from "../../Firewall/config/FirewallRuleConfig";
import GeneralSection from "../../Firewall/config/GeneralSection";
import FQDNSection from "../../Firewall/config/FQDNSection";
import ActionSection from "../../Firewall/config/ActionSection";
import validateDiaFirewallNewRule from "../../../helpers/validators/DiaFirewallNewRuleValidator";
import { parseGeneratedName } from "../../../components/common/table/TableHeaders/Services/firewallTableHeader";

type Props = {
  onClose: () => void;
  type: DialogType;
  data?: FWDiaRuleType;
  gate_vi_name?: string;
};

type PolicyName = "incoming" | "outgoing";

export type CreateDiaFWRule = {
  name: string;
  id?: number;
  enable: boolean;
  priority: number;
  description: string;
  forwarding_policy: string;
  ip_protocol: string;
  src_network: string;
  src_segment: string;
  dst_segment: string;
  src_l4_port: string;
  dst_network: string;
  dst_l4_port: string;
  dst_group: string;
  src_group: string;
  categories: Array<string>;
  allowed_domains: Array<string>;
  denied_domains: Array<string>;
  gate_vi_name: string;
  policy_name: PolicyName;
  time_policies_names: Array<string>;
  ip_type?: string;
};

const DEFAULT_FIELDS: CreateDiaFWRule = {
  name: "",
  enable: false,
  priority: 0,
  description: "",
  forwarding_policy: FW_POLICIES["Allow"],
  ip_protocol: "",
  src_network: "",
  src_segment: "",
  dst_segment: "",
  src_l4_port: "",
  dst_network: "",
  dst_l4_port: "",
  src_group: "",
  dst_group: "",
  categories: [],
  allowed_domains: [],
  denied_domains: [],
  policy_name: "incoming",
  time_policies_names: [],
  gate_vi_name: "",
  ip_type: "ipv4",
};

const DiaFirewallRuleConfig: FC<Props> = ({
  onClose,
  type,
  data,
  gate_vi_name,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    add,
    addStatus,
    edit,
    editStatus,
    fetchList,
    list,
    userGroups,
    gates,
  } = useFirewallContext();

  const [
    fields,
    handleFieldChange,
    resetFields,
  ] = useFormField<CreateDiaFWRule>(
    getFlatData(data) ||
      ({
        ...DEFAULT_FIELDS,
        gate_vi_name,
      } as CreateDiaFWRule)
  );

  const [errors, validate] = useValidation<CreateDiaFWRule>(
    validateDiaFirewallNewRule,
    [fields]
  );

  const [fgdnShow, setFQDNShow] = useState<boolean>(false);
  const [isSNI, setIsSNI] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTenant) {
      fetchList(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (list.length) {
      const diaList = list.filter((item) => !!item.gate_vi_name) || [];
      const priority = data ? data.priority : generatePriority(diaList);
      handleFieldChange("priority", priority);
    }
  }, [list]);

  useEffect(() => {
    resetFields(
      getFlatData(data) ||
        ({
          ...DEFAULT_FIELDS,
          gate_vi_name,
        } as CreateDiaFWRule)
    );
  }, [data]);

  const handleFQDN = () => {
    setFQDNShow(!fgdnShow);
  };

  function getRequestBody(): Partial<FWDiaRuleType> {
    const newFields: Partial<FWDiaRuleType> = getFWPreparedData(fields);
    newFields.gate_vi_name = fields.gate_vi_name;
    return newFields;
  }

  const handleAdd = async () => {
    const { isOk } = validate();
    if (!isOk) return;
    await sendRequest(isOk, add(getRequestBody(), selectedTenant), onClose);
  };

  const handleEdit = async () => {
    if (!data) return;
    const { isOk } = validate();
    if (!isOk) return;
    await sendRequest(
      isOk,
      edit(getRequestBody(), data.name, selectedTenant),
      onClose
    );
  };

  return (
    <CollapsableTwoColumnLayout
      InfoBlock={() => <DiaFirewallRuleInfo param={selectedTenant as string} />}
    >
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <GeneralSection
            className={styles.contentWrapper}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            maxPriority={MAX_FW_PRIORITY_FOR_MANUAL_CREATION}
            allowedToChangeName={type === ADD}
            parsedName={
              type === EDIT && selectedTenant
                ? parseGeneratedName(
                    fields.gate_vi_name,
                    fields.name,
                    selectedTenant
                  )
                : undefined
            }
          />
          <RuleSection
            className={styles.contentWrapperNoBorderBottom}
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            groups={userGroups}
            isSNI={isSNI}
            isFQDN={fgdnShow}
            gates={gates}
            isEdit={isEditMode(type)}
          />
          <ActionSection
            className={
              fgdnShow
                ? styles.contentWrapper
                : styles.contentWrapperNoBorderBottom
            }
            fields={fields}
            onChange={handleFieldChange}
            handleFQDN={handleFQDN}
          />
          <FQDNSection
            className={
              fgdnShow ? styles.contentWrapper : styles.contentWrapperHidden
            }
            fields={fields}
            errors={errors}
            onChange={handleFieldChange}
            sniInfo={setIsSNI}
          />
        </div>
        <div className={styles.footer}>
          <DialogBtmButtons
            controls={{
              okText: isEditMode(type) ? EDIT : ADD,
              onOk: isEditMode(type) ? handleEdit : handleAdd,
              cancelText: "Cancel",
              onCancel: onClose,
            }}
            errorDisplay={addStatus || editStatus}
          />
        </div>
      </div>
    </CollapsableTwoColumnLayout>
  );
};

export default DiaFirewallRuleConfig;

export function getPolicyName(filters: { [key: string]: any }): PolicyName {
  return filters?.src_network ? "outgoing" : "incoming";
}

const getFlatData = (
  data: FWDiaRuleType | undefined
): CreateDiaFWRule | undefined => {
  if (!data) return;
  const filters = prepareFilters(data);
  const protocols = data.filters.filter(
    (el) => el.filter_type === "ip_protocol"
  );
  let ip_type = "ipv4";
  const diaField = filters?.src_network || filters?.dst_network;
  if (diaField && !checkIPv4WithPrefix(diaField)) {
    ip_type = "ipv6";
  }

  return {
    name: data?.name,
    enable: data?.enable,
    priority: data?.priority,
    description: data?.description,
    forwarding_policy: data?.forwarding_policy,
    ...(filters as FW_Filters),
    ip_protocol: getPotocolName(protocols),
    categories: data?.categories,
    allowed_domains: data?.allowed_domains,
    denied_domains: data?.denied_domains,
    gate_vi_name: data.gate_vi_name,
    policy_name: (data?.policy_name as PolicyName) || getPolicyName(filters),
    time_policies_names: data.time_policies_names,
    id: data?.id,
    ip_type: ip_type,
  };
};
