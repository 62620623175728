import React, { ReactElement } from "react";

function LoginIconsInColumn(props: { className?: string }): ReactElement {
  return (
    <svg
      width="90"
      height="328"
      viewBox="0 0 90 328"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45 90C69.8528 90 90 69.8528 90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90ZM56.082 38.0751C56.0884 37.6842 55.8595 37.3189 55.5041 37.156L44.5381 32.127C44.2734 32.0056 43.969 32.0056 43.7044 32.127L32.7383 37.156C32.383 37.3189 32.1542 37.6848 32.1602 38.0757C32.416 54.5036 42.3156 57.8843 43.9065 58.3219C44.0519 58.3619 44.1898 58.3592 44.3337 58.3139C45.9167 57.816 55.8194 54.0647 56.082 38.0751Z"
          fill="#ECF3FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45 209C69.8528 209 90 188.853 90 164C90 139.147 69.8528 119 45 119C20.1472 119 0 139.147 0 164C0 188.853 20.1472 209 45 209ZM56.4222 161.286C55.4598 162.953 53.9262 164.085 52.2114 164.577L51.8902 172.066C51.923 172.084 51.9557 172.102 51.9884 172.121C54.3282 173.472 55.1298 176.464 53.779 178.804C52.4281 181.144 49.4362 181.945 47.0963 180.595C44.7565 179.244 43.9548 176.252 45.3057 173.912C45.9103 172.865 46.8436 172.126 47.9001 171.75L48.2085 164.559C47.6843 164.402 47.17 164.183 46.6756 163.897C46.0776 163.552 45.5485 163.133 45.0931 162.659L38.9891 165.901C39.1612 167.059 38.9579 168.28 38.3273 169.372C36.8445 171.941 33.5605 172.821 30.9922 171.338C28.4239 169.855 27.5439 166.571 29.0267 164.003C30.5095 161.435 33.7936 160.555 36.3619 162.037C36.5762 162.161 36.7789 162.297 36.9693 162.445L43.2453 159.111C42.9183 157.472 43.1626 155.712 44.064 154.151C46.0343 150.738 50.398 149.569 53.8106 151.539C57.2233 153.51 58.3925 157.873 56.4222 161.286Z"
          fill="#ECF3FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45 328C69.8528 328 90 307.853 90 283C90 258.147 69.8528 238 45 238C20.1472 238 0 258.147 0 283C0 307.853 20.1472 328 45 328ZM35.6319 267.799C34.9806 267.566 34.2955 268.049 34.2955 268.741V294.748C34.2955 295.3 34.7433 295.748 35.2955 295.748H54.7049C55.2571 295.748 55.7049 295.3 55.7049 294.748V275.672C55.7049 275.25 55.4392 274.873 55.0412 274.731L35.6319 267.799ZM40.9125 278.926C40.6363 278.926 40.4125 279.15 40.4125 279.426V283.013C40.4125 283.29 40.6363 283.513 40.9125 283.513H42.9709C43.247 283.513 43.4709 283.29 43.4709 283.013V279.426C43.4709 279.15 43.247 278.926 42.9709 278.926H40.9125ZM40.4125 287.072C40.4125 286.796 40.6363 286.572 40.9125 286.572H42.9709C43.247 286.572 43.4709 286.796 43.4709 287.072V290.66C43.4709 290.936 43.247 291.16 42.9709 291.16H40.9125C40.6363 291.16 40.4125 290.936 40.4125 290.66V287.072ZM47.0295 278.926C46.7534 278.926 46.5295 279.15 46.5295 279.426V283.013C46.5295 283.29 46.7534 283.513 47.0295 283.513H49.088C49.3642 283.513 49.588 283.29 49.588 283.013V279.426C49.588 279.15 49.3642 278.926 49.088 278.926H47.0295ZM46.5295 287.072C46.5295 286.796 46.7534 286.572 47.0295 286.572H49.088C49.3642 286.572 49.588 286.796 49.588 287.072V290.66C49.588 290.936 49.3642 291.16 49.088 291.16H47.0295C46.7534 291.16 46.5295 290.936 46.5295 290.66V287.072Z"
          fill="#ECF3FF"
        />
      </g>
    </svg>
  );
}

export default LoginIconsInColumn;
