import { useMemo, useState, ReactNode } from "react";
import {
  SUBMIT_ERROR_PRICING_MODAL_EVENT,
  SUBMIT_SUCCESS_PRICING_MODAL_EVENT,
  usePricingDialog,
} from "../../../../../contexts/pricingDialogContext/PricingDialogContext";
import DialogTemplateOneColumn from "../../../common/DialogTemplateOneColumn";
import styles from "./TermsDialog.module.scss";
import { TermsAndConditions } from "./types";
import { Checkbox } from "../../../../common/formComponents/Checkbox";
import { Money } from "../../../../common/Money";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useFetch } from "../../../../../helpers/hooks/useFetch";
import { pricingApi } from "../../../../../helpers/api/PricingApi";
import Loader from "../../../../common/Loader/Loader";
import { getParams } from "./helpers";

const replaceTextWithComponent = (
  template: string,
  replacements: Record<string, ReactNode>
): Array<ReactNode> => {
  const parts = template.split(/(\{[^}]+\})/g);
  return parts.map((part) => {
    const match = part.match(/^\{([^}]+)\}$/);
    if (match) {
      const key = match[1];
      return replacements[key] || part;
    }
    return part;
  });
};

type TermsDialogProps = {
  terms: TermsAndConditions | undefined;
  isLoading: boolean;
};

export const TermsDialog = ({ terms, isLoading }: TermsDialogProps) => {
  const {
    closeDialog,
    setOpenModal,
    product,
    selectedPrice,
    selectPrice,
    additionalParams,
  } = usePricingDialog();
  const { selectedTenant } = useGlobalFilterContext();
  const [isRead, setIsRead] = useState(false);

  const { fetch: signTerms } = useFetch({
    fetchFn: pricingApi.signTerms.bind(pricingApi),
  });

  const {
    title,
    termsContent,
    accept,
    disclaimer,
    priceAccept,
  } = useMemo(() => {
    if (!terms) return {};

    const content = terms.content.EN?.terms_content;
    const priceAcceptText = content?.find((item) => item.type === "P4")
      ?.content;

    return {
      title: content?.find((item) => item.type === "title")?.content,
      termsContent: content?.find((item) => item.type === "P1")?.content,
      accept: content?.find((item) => item.type === "P2")?.content,
      disclaimer: content?.find((item) => item.type === "P3")?.content,
      priceAccept: replaceTextWithComponent(priceAcceptText ?? "", {
        mrc_price: selectedPrice && (
          <Money {...selectedPrice.monthly_recurring_charge} />
        ),
      }),
    };
  }, [terms]);

  const handleSubmit = async () => {
    const params = getParams(product, additionalParams);

    const { ok } = await signTerms({
      tenant: selectedTenant,
      service: product,
      termsId: terms?.id,
      serviceId: selectedPrice?.id,
      additionalParams: params,
    });

    if (ok) {
      window.dispatchEvent(new CustomEvent(SUBMIT_SUCCESS_PRICING_MODAL_EVENT));
    } else {
      window.dispatchEvent(new CustomEvent(SUBMIT_ERROR_PRICING_MODAL_EVENT));
    }

    setOpenModal(undefined);
    selectPrice(undefined);
  };

  return (
    <DialogTemplateOneColumn
      header={<span className={styles.title}>{title}</span>}
      onClose={closeDialog}
      controls={{
        okText: "Order",
        onOk: handleSubmit,
        cancelText: "Cancel",
        onCancel: () => {
          closeDialog();
        },
        disableSubmit: !isRead,
      }}
      className={styles.dialog}
    >
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <div
            className={styles.terms}
            dangerouslySetInnerHTML={{
              __html: termsContent ?? "",
            }}
          />
          <div className={styles.wrapper}>
            <Checkbox
              isChecked={isRead}
              onChange={() => setIsRead((prev) => !prev)}
              label={accept}
              containerClassName={styles.checkbox}
            />
            <div className={styles.disclaimer}>{disclaimer}</div>
            {priceAccept && <div className={styles.price}>{priceAccept}</div>}
          </div>
        </>
      )}
    </DialogTemplateOneColumn>
  );
};
