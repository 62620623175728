import React, { useEffect, useMemo, useState } from "react";
import DropdownBasic from "../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../components/common/formComponents/Input";
import LoaderIcon from "../../../../components/common/loadStates/LoaderIcon";
import Section from "../../../../components/common/Section";
import LocationIcon from "../../../../components/icons/LocationIcon";
import { VRFType } from "../../../../helpers/api/apiTypes";
import { useVRFList } from "../../../../helpers/api/hooks/useVRFList";
import { useSystemsList } from "../../../../helpers/api/systems/useSystemsList";
import { classNames } from "../../../../helpers/common/classNames";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import { DropdownItem } from "../../../../helpers/types";
import { System, SystemAPI } from "../../../Systems/Provisioning/types";
import DialogFrame from "../../common/DialogFrame/DialogFrame";
import { DialogComponentProps } from "../../common/types";
import { useIpamContext } from "../../IpamContext";
import { CreateIpam, IpamType } from "../../types";
import styles from "./DialogContent.module.scss";
import { DialogFooter } from "./DialogFooter";
import { useValidation } from "../../../../helpers/validators/Validator";
import validateIPAM from "../../../../helpers/validators/IPAMValidation";
import ButtonsGroup, {
  Tab,
} from "../../../../components/common/buttons/ButtonsGroup";
import CheckboxIcon from "../../../../components/icons/CheckboxIcon";
import { Checkbox } from "../../../../components/common/formComponents/Checkbox";

const TYPES: Array<DropdownItem<string>> = [
  { key: "system", value: "System" },
  { key: "location", value: "Location" },
  { key: "global", value: "Global" },
];

const mapSystemsToDropdownList = (
  systems: Array<System | SystemAPI>
): Array<DropdownItem<string>> => {
  return systems.map((s) => ({ key: s.name, value: s.name }));
};

const mapSystemsToLocationDropdownList = (
  systems: Array<System | SystemAPI>
): Array<DropdownItem<string>> => {
  return systems
    .filter((s) => s.location)
    .map((s) => ({ key: s.name, value: s.location }));
};

const mapVrfToDropdownList = (
  vrfs: Array<VRFType>
): Array<DropdownItem<string>> => {
  return vrfs
    .filter((v) => v.vrf_type === "internet")
    .map((v) => ({ key: v.name, value: v.name }));
};

type Props = DialogComponentProps & {
  fields: CreateIpam;
  onChange: (
    name: keyof CreateIpam,
    value: string | undefined | boolean
  ) => void;
  onClose: () => void;
};

const getSelectedSystem = (
  systemList: Array<System | SystemAPI>,
  type: IpamType,
  fields: CreateIpam
) => {
  if (type === "system") {
    return systemList.find((s) => s.name === fields.data);
  }

  if (type === "location") {
    return systemList.find((s) => s.location === fields.data);
  }

  return systemList[0];
};

export const DialogContent = ({
  className,
  fields,
  onChange,
  onClose,
}: Props): JSX.Element => {
  const { createIpam, createDIAIpRange } = useIpamContext();

  const [systemList, systemStatus] = useSystemsList();
  const systemIsLoading = systemStatus === "pending";
  const dropdownSystems = mapSystemsToDropdownList(systemList || []);

  const selectedSystem = getSelectedSystem(
    systemList || [],
    fields.type,
    fields
  );

  const [vrfList, vrfStatus] = useVRFList(selectedSystem?.name || "");
  const vrfIsLoading = vrfStatus === "pending";
  const dropdownVrfList = useMemo(() => mapVrfToDropdownList(vrfList || []), [
    vrfList,
  ]);

  const dropdownLocations = mapSystemsToLocationDropdownList(systemList || []);

  const [activeTab, setActiveTab] = useState<string>("NaaS");
  const diaType = activeTab === "DIA";

  useEffect(() => {
    onChange("vrf_name", undefined);
  }, [fields.data, fields.type]);

  useEffect(() => {
    onChange("data", undefined);
  }, [fields.type]);

  const [errors, validate] = useValidation(validateIPAM, [
    { ...fields, iconType: activeTab },
  ]);

  const onAdd = async () => {
    const { isOk } = validate();
    if (isOk) {
      if (activeTab === "NaaS") {
        const res = await createIpam(fields as CreateIpam);
        if (res) {
          onClose();
        }
      } else if (activeTab === "DIA") {
        const res = await createDIAIpRange(fields as CreateIpam);
        if (res) {
          onClose();
        }
      }
    }
  };

  const handleTabSelect = (tab: Tab) => {
    setActiveTab(tab.name);
  };

  const TABS: Array<Tab> = [
    {
      name: "NaaS",
      icon: CheckboxIcon({}),
      className: styles.typeButton,
      activeStyle: styles.active,
      value: "naas",
    },
    {
      name: "DIA",
      icon: CheckboxIcon({}),
      className: styles.typeButton,
      activeStyle: styles.active,
      value: "dia",
    },
  ];

  return (
    <DialogFrame className={classNames(className, styles.dialogContent)}>
      <Section title="IPAM" columnsCount={2} className={styles.dialogForm}>
        <ButtonsGroup
          className={styles.actionButtons}
          tabs={TABS}
          activeTab={activeTab}
          onChange={handleTabSelect}
          withIcon
        />
        <div></div>
        <Input
          label="Pool Name"
          name="name"
          placeholder=""
          value={fields.name}
          onChange={(e) => onChange("name", e.target.value)}
          medium
          error={errors && errors["name"]}
        />
        <DropdownBasic
          id={"type"}
          label="Type"
          isMedium
          itemsList={TYPES}
          selected={mapStringToItem(
            TYPES.find((t) => t.key === fields.type)?.value || ""
          )}
          onChange={(item) => onChange("type", item.key)}
        />
        {fields.type === "system" && (
          <DropdownBasic
            id={"system"}
            label="System"
            isMedium
            itemsList={dropdownSystems}
            selected={mapStringToItem(fields.data || "")}
            onChange={(item) => onChange("data", item.value)}
            icon={systemIsLoading ? <LoaderIcon height={16} /> : undefined}
            error={errors && errors["data"]}
          />
        )}
        {fields.type === "location" && (
          <DropdownBasic
            id={"location"}
            label="Location"
            isMedium
            itemsList={dropdownLocations}
            selected={mapStringToItem(fields.data || "")}
            onChange={(item) => onChange("data", item.value)}
            icon={<LocationIcon />}
            error={errors && errors["data"]}
          />
        )}
        <DropdownBasic
          id={"vrf"}
          label="VRF"
          isMedium
          itemsList={dropdownVrfList}
          selected={mapStringToItem(fields.vrf_name || "")}
          onChange={(item) => onChange("vrf_name", item.key)}
          icon={vrfIsLoading ? <LoaderIcon height={16} /> : undefined}
          error={errors && errors["vrf_name"]}
        />
        {diaType ? (
          <Input
            label="IP Prefix"
            name="ip_prefix"
            placeholder=""
            medium
            value={fields.from_ip}
            onChange={(e) => onChange("from_ip", e.target.value)}
            error={errors && errors["from_ip"]}
          />
        ) : (
          <>
            <Input
              label="Start IP"
              name="start_ip"
              placeholder=""
              medium
              value={fields.from_ip}
              onChange={(e) => onChange("from_ip", e.target.value)}
              error={errors && errors["from_ip"]}
            />
            <Input
              label="End IP"
              name="end_ip"
              placeholder=""
              medium
              value={fields.to_ip}
              onChange={(e) => onChange("to_ip", e.target.value)}
              error={errors && errors["to_ip"]}
            />
          </>
        )}
        {fields.type != "global" && diaType && <div></div>}
        <div className={styles.label}>
          NaaS Services
          <div className={styles.servicesCheckboxes}>
            <Checkbox
              isChecked={fields.nat_service || false}
              label="NAT"
              onChange={() => {
                onChange("nat_service", !fields.nat_service);
                if (!fields.nat_service) {
                  onChange("ipsec_service", false);
                }
              }}
            />
            <Checkbox
              isChecked={fields.snat_service || false}
              label="SNAT"
              onChange={() => {
                onChange("snat_service", !fields.snat_service);
                if (!fields.snat_service) {
                  onChange("ipsec_service", false);
                  onChange("nat_service", true);
                }
              }}
            />
            <Checkbox
              isChecked={fields.ipsec_service || false}
              label="IPSEC"
              onChange={() => {
                onChange("ipsec_service", !fields.ipsec_service);
                if (!fields.ipsec_service) {
                  onChange("snat_service", false);
                  onChange("nat_service", false);
                }
              }}
            />
          </div>
        </div>
        {!diaType && (
          <div className={styles.label}>
            Usage
            <div className={styles.usage}>
              <Checkbox disabled isChecked={false} label="Shared" />
            </div>
          </div>
        )}
        {diaType && (
          <div className={styles.label}>
            Allocated Prefixes
            <div className={styles.ipInputs}>
              <Input
                name="start_ip"
                placeholder="Min"
                isPlaceholderAlwaysVisible
                medium
                value={fields.min_allocated_pfl}
                onChange={(e) => onChange("min_allocated_pfl", e.target.value)}
                error={errors && errors["min_allocated_pfl"]}
              />
              <Input
                name="end_ip"
                placeholder="Max"
                isPlaceholderAlwaysVisible
                medium
                value={fields.max_allocated_pfl}
                onChange={(e) => onChange("max_allocated_pfl", e.target.value)}
                error={errors && errors["max_allocated_pfl"]}
              />
            </div>
          </div>
        )}
      </Section>
      <DialogFooter onAdd={onAdd} onCancel={onClose} />
    </DialogFrame>
  );
};
