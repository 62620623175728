import React from "react";
import { PosProps } from "../../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/utils";

export default function DirectCenter({
  x,
  y,
  location,
  isZayo,
  labelWidth,
  labelHeight,
}: PosProps & {
  location: string;
  isZayo?: boolean;
  labelWidth: number;
  labelHeight: number;
}): React.ReactElement {
  return (
    <>
      {isZayo && (
        <image
          width="52"
          height="17"
          x={x - 25}
          y={y - 35}
          xlinkHref="/zayoGrafics/zayoLogoSmall.png"
        />
      )}
      <rect
        width={labelWidth}
        height={labelHeight}
        stroke="#BEB8EA"
        strokeWidth={1}
        fill="white"
        x={x - labelWidth / 2}
        y={y - 10}
        rx={5}
        ry={5}
      />
      <text
        height={labelHeight}
        fill="var(--font)"
        fontSize="12"
        x={x + 5}
        y={y + 3}
        textAnchor="middle"
      >
        {location}
      </text>
    </>
  );
}
