import { FC, useEffect } from "react";
import LeftBlock from "./components/LeftBlock";
import ContentBlock from "./components/ContentBlock/ContentBlock";
import InfoBlock from "./components/InfoBlock";
import styles from "./SystemConfig.module.scss";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import { FieldsType } from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { cloneDeep } from "lodash";
import { useSystemConfigContext } from "./SystemConfigContext";
import { useSystemProvisioningContext } from "../../ProvisioningContextContainer";

type Props = {
  onClose: () => void;
  data: any;
  isNode?: boolean;
};

const NodeConfigEdit: FC<Props> = ({ onClose, data, isNode }) => {
  const [fields, handleFieldChange, resetFields] = useFormField<FieldsType>(
    cloneDeep(data)
  );
  const { editNode, editStatus } = useSystemConfigContext();
  const { switchNodeToActive } = useSystemProvisioningContext();

  useEffect(() => {
    if (data) {
      resetFields(cloneDeep(data));
    }
  }, [data]);

  const handleEdit = async () => {
    const success = await editNode(fields);
    if (success) onClose();
  };

  const handleSwitchNode = async (value: any) => {
    const success = await switchNodeToActive(value);
    if (success) onClose();
  };

  return (
    <div className={styles.wrapper}>
      <LeftBlock
        className={styles.basicAppearance}
        fields={fields}
        isNode={isNode}
        onAction={handleSwitchNode}
        onClose={onClose}
      />
      <ContentBlock
        className={styles.basicAppearance}
        fields={fields}
        onChange={handleFieldChange}
        onClose={onClose}
        onAction={handleEdit}
        actionStatus={editStatus}
        errors={undefined}
        isEdit
        isNode
      />
      <InfoBlock className={styles.basicAppearance} />
    </div>
  );
};
export default NodeConfigEdit;
