import { AwsCloudCredentials } from "../../pages/CloudResourcesPage/types";
import Validator from "./Validator";

export default function validateAwsCloudCredentials(
  data: Partial<AwsCloudCredentials>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.user, "user");
  validator.checkEmpty(data.resource, "resource");
  validator.checkEmpty(data.access_key, "accessToken");
  validator.checkEmpty(data.secret_key, "secretKey");
  return validator;
}
