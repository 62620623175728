import React, { useEffect } from "react";
import styles from "./DashboardRight.module.scss";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getApplicationLink } from "../../../../pages/ApplicationObservabilityPage/components/ApplicationObservabilityStat/components/Statistics/components/StatisticsBody";
import { useDashboardFilterContext } from "../../../../contexts/dashboardContext/DashboardFilterContext";
import InterfaceInfoCart from "./InterfaceInfoCart";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import BranchTraffic from "./components/charts/BranchTraffic";
import NewTenantTraffic from "./components/charts/NewTenantTraffic";
import ApplicationObservability from "./components/charts/ApplicationObservability";
import { useTopologyPageContext } from "../../context";
import {
  combineState,
  convertToGroupRows,
  removeNATVis,
} from "../../../Network/helpers/table";
import { useNetworkContext } from "../../../Network/context/NetworkContext";
import { StatGroup } from "../../../Network/InterfaceStatistics/components/StatGroup";

const DashboardRight: React.FC<{}> = ({}) => {
  const { timeRange } = useTimerangeContext();
  const { selectedInterface } = useDashboardFilterContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { connectionType } = useTopologyPageContext();
  const {
    systems,
    fetchSystems,
    fetchConnections,
    direct,
  } = useNetworkContext();

  useEffect(() => {
    fetchConnections();
    fetchSystems();
  }, [selectedTenant]);

  if (connectionType === "Direct") {
    const viGroupsDirect = removeNATVis(
      convertToGroupRows(combineState(direct), systems)
    );

    return (
      <div className={styles.rightPart}>
        {viGroupsDirect.map((group) => (
          <StatGroup group={group} key={group.name.label} />
        ))}
      </div>
    );
  }

  if (selectedInterface) {
    return (
      <GrafanaContainer type={GRAFANA_TYPES.DPI} className={styles.rightPart}>
        <div className={styles.onePart}>
          <BranchTraffic interfaceInfo={selectedInterface} />
          <InterfaceInfoCart
            interfaceInfo={selectedInterface}
            inBand={20}
            outBand={40}
          />
        </div>
      </GrafanaContainer>
    );
  }

  return (
    <GrafanaContainer
      type={GRAFANA_TYPES.DPI}
      className={styles.rightPart}
      showInsteadPlaceholder={<NewTenantTraffic className={styles.traffic} />}
    >
      <div className={styles.demoTopPart}>
        <ApplicationObservability />
      </div>
      <div className={styles.demoBottomPart}>
        <NewTenantTraffic className={styles.left} />
        <GrafanaGraph
          link={getApplicationLink(10, timeRange.general, selectedTenant)}
        />
      </div>
    </GrafanaContainer>
  );
};

export default DashboardRight;
