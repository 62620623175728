import React, { FC, useEffect } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { segmentsTableHeader } from "./common/table/segmentsTableHeader";

import styles from "./SegmentsPageContent.module.scss";
import { useNetworkContext } from "../Network/context/NetworkContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";

const SegmentsPageContent: FC = () => {
  const {
    segmentations,
    segmentRequestStatus,
    fetchSegmentations,
  } = useNetworkContext();

  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchSegmentations();
    }
  }, [selectedTenant]);

  return (
    <ServicesRedirectPlaceholder type="segments">
      <TableWrapper
        titleProps={{
          title: `Segments (${segmentations.length})`,
        }}
        dataStatus={segmentRequestStatus}
        tablePlaceholder={["Segments", "Segment"]}
      >
        <Table
          columns={segmentsTableHeader()}
          header={TableHeader}
          data={segmentations}
          gridColumnTemplate={`150px minmax(240px, 1fr) 1fr`}
          rowClassName={styles.rowClassName}
        />
      </TableWrapper>
    </ServicesRedirectPlaceholder>
  );
};

export default SegmentsPageContent;
