import { CellProps } from "react-table";
import CloudProviderIcon from "../../../../components/icons/CloudProviderIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import LocationFillIcon from "../../../../components/icons/LocationFillIcon";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "../../TableHeaders.module.scss";
import { getPercentageOfTotal } from "../../../../helpers/common/numberHelpers";
import { getColorClassByPercentage } from "../../helpers";
import { GCPNNI, RegionItem } from "../../types";
import { getBandwidthStringForTable } from "../../../../helpers/getBandwidthStringForTable";
import { GCP_LOWERCASE } from "../../../../helpers/consts";

const IconColumn = {
  Header: "Cloud Provider",
  accessor: "",
  Cell: () => {
    return (
      <>
        <CloudProviderIcon
          id={GCP_LOWERCASE}
          color={GREY_FONT}
          height={36}
          width={36}
        />
        GCP
      </>
    );
  },
};

export const gcpNNITableHeader = () =>
  [
    IconColumn,
    {
      Header: "Regions",
      accessor: "regions",
      Cell: ({ cell: { value } }: CellProps<GCPNNI>) => {
        const regions = value.join(", ");
        return (
          <div className={styles.region} title={regions}>
            <LocationFillIcon color={GREY_FONT} />
            {regions}
          </div>
        );
      },
    },
    {
      Header: "NNI Id",
      accessor: "nni_id",
      Cell: ({ cell: { value } }: CellProps<GCPNNI>) => {
        return <div title={value}>{value}</div>;
      },
    },
    {
      Header: "Port",
      accessor: (row: GCPNNI) => [row.system_name, row.physical_port_name],
      Cell: ({ cell: { value } }: CellProps<GCPNNI>) => {
        const [systemName, portName] = value;
        if (!systemName || !portName) return "";

        return `${systemName} - ${portName}`;
      },
    },
    {
      Header: "Zone",
      accessor: "zone",
    },
    {
      Header: "Link Capacity",
      accessor: "managed_max_bandwidth",
      Cell: ({ cell: { value } }: CellProps<GCPNNI>) => {
        return getBandwidthStringForTable(value);
      },
    },
    {
      Header: "Provisioned Capacity",
      accessor: (row: GCPNNI) => [
        row.managed_max_bandwidth,
        row.used_bandwidth,
      ],
      Cell: ({ cell: { value } }: CellProps<GCPNNI>) => {
        const [bw, usedBW] = value;
        const usedBWLabel = getBandwidthStringForTable(usedBW);
        const percentage = getPercentageOfTotal(usedBW, bw);

        return (
          <div className={getColorClassByPercentage(percentage)}>
            {usedBWLabel} ({percentage}%)
          </div>
        );
      },
    },
    {
      Header: "Connections",
      accessor: "connections_count",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof GCPNNI,
  }));

export const gcpRegionsTableHeader = () =>
  [
    IconColumn,
    {
      Header: "Region",
      accessor: "region",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return (
          <div className={styles.region}>
            <LocationFillIcon color={GREY_FONT} />
            {value}
          </div>
        );
      },
    },
    {
      Header: "NNIs",
      accessor: "nniCount",
    },
    {
      Header: "Link Capacity",
      accessor: "totalCapacity",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return getBandwidthStringForTable(value);
      },
    },
    {
      Header: "Provisioned Capacity",
      accessor: (row: RegionItem) => [
        row.totalCapacity,
        row.provisionedCapacity,
      ],
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        const [bw, usedBW] = value;
        const usedBWLabel = getBandwidthStringForTable(usedBW);
        const percentage = getPercentageOfTotal(usedBW, bw);
        return (
          <div className={getColorClassByPercentage(percentage)}>
            {usedBWLabel} ({percentage}%)
          </div>
        );
      },
    },
    {
      Header: "Connections",
      accessor: "connections",
      Cell: ({ cell: { value } }: CellProps<RegionItem>) => {
        return value;
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof RegionItem,
  }));
