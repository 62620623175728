import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function AzureIcon({ color, className, x, y }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      x={x}
      y={y}
    >
      <path
        d="M13.0435 20.6954C15.6939 20.2273 17.883 19.8398 17.9081 19.8345L17.9538 19.8248L15.4516 16.8485C14.0754 15.2115 12.9494 13.8659 12.9494 13.8581C12.9494 13.8434 15.5331 6.72836 15.5477 6.70305C15.5525 6.69459 17.3108 9.73026 19.8099 14.0617C22.1496 18.1169 24.0786 21.4605 24.0965 21.4918L24.1291 21.5488L16.1769 21.5477L8.2247 21.5467L13.0435 20.6954ZM3.5 19.7878C3.5 19.7836 4.67903 17.7369 6.12006 15.2396L8.74012 10.699L11.7935 8.1366C13.4729 6.72728 14.8514 5.57248 14.8569 5.57037C14.8624 5.56826 14.8404 5.62387 14.8078 5.69393C14.7753 5.76399 13.2833 8.96416 11.4923 12.8054L8.2359 19.7895L5.86795 19.7925C4.56558 19.7941 3.5 19.792 3.5 19.7878Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default AzureIcon;
