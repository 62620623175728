import React, { FC, useEffect, useState } from "react";
import styles from "./CloudLinksInfo.module.scss";

import { useUnitedConnectionContext } from "../../UnitedConnectionContext";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import ConfirmDeleteDialog from "../../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import NoDataPlaceholder from "../../../../../components/common/loadStates/NoDataPlaceholder";
import EmptyPortsPlaceholderIcon from "../../../../../components/icons/EmptyPortsPlaceholderIcon";
import { TenantCustomerPort } from "../../types";
import { getPortConnections, PortItem } from "./PortItem/PortItem";
import CaretDown from "../../../../../components/icons/CaretDown";
import CaretUp from "../../../../../components/icons/CaretUp";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../../../../helpers/consts";
import AWSIcon from "../../../../../components/icons/AWSIcon";
import AzureIcon from "../../../../../components/icons/AzureIcon";
import GCPLogo from "../../../../../components/icons/zayo/clouds/GCPLogo";

type Props = {
  onAction: (val?: boolean) => void;
  isRequest: boolean;
  addConnectionCallback: (val: string) => void;
};

const getIconByType = (type: string) => {
  switch (type) {
    case AWS_LOWERCASE:
      return <AWSIcon />;
    case AZURE_LOWERCASE:
      return <AzureIcon />;
    case GCP_LOWERCASE:
      return <GCPLogo width={35} height={35} />;
    default:
      return null;
  }
};

const CloudLinksInfo: FC<Props> = ({ onAction, addConnectionCallback }) => {
  const {
    getTenantPorts,
    portsFetchStatus,
    customerPorts,
    deleteCustomerPort,
    requestPortStatus,
    connections,
    parentPorts,
    ports,
  } = useUnitedConnectionContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    getTenantPorts();
  }, [selectedTenant]);

  const [selectedPort, setSelectedPort] = useState<
    TenantCustomerPort | undefined
  >();
  const [expandedGroups, setExpandedGroups] = useState<Array<string>>([]);

  const filteredPorts = customerPorts.filter(
    (port) => port.type !== "customer_location"
  );

  const groupedPorts = filteredPorts.reduce<
    Record<string, Array<TenantCustomerPort>>
  >((acc, port) => {
    const type = port.type ?? "unknown";
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(port);
    return acc;
  }, {});

  useEffect(() => {
    const firstGroupType = Object.keys(groupedPorts)[0];
    if (firstGroupType) {
      setExpandedGroups([firstGroupType]);
    }
  }, [customerPorts]);

  if (portsFetchStatus?.state === "pending" || !portsFetchStatus) {
    return <PageLoader />;
  }

  const handleDelete = async () => {
    if (selectedTenant && !!selectedPort) {
      await deleteCustomerPort(selectedPort.name, selectedPort.type);
      setSelectedPort(undefined);
    }
  };

  const toggleGroup = (type: string) => {
    setExpandedGroups((prev) =>
      prev.includes(type)
        ? prev.filter((group) => group !== type)
        : [...prev, type]
    );
  };

  if (filteredPorts.length === 0) {
    return (
      <NoDataPlaceholder
        title={"There are no cloud links available"}
        subTitle="Please add cloud links to see them here."
        customIcon={<EmptyPortsPlaceholderIcon />}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <ConfirmDeleteDialog
        isShown={!!selectedPort}
        onOk={handleDelete}
        onCancel={() => setSelectedPort(undefined)}
        deviceType={"Cloud Link"}
        name={`${selectedPort?.name || ""}`}
        status={requestPortStatus}
      />
      {Object.entries(groupedPorts).map(([type, ports]) => {
        // Подсчет уникальных регионов
        const uniqueRegions = new Set(
          ports.map((port) => port.labels?.cloud_region || "unknown")
        ).size;

        // Подсчет соединений для группы
        const groupConnections = ports.reduce((acc, port) => {
          const portConnections = getPortConnections(connections, port.name);
          return acc + portConnections.length;
        }, 0);

        return (
          <div key={type}>
            <div
              className={styles.groupHeader}
              onClick={() => toggleGroup(type)}
            >
              <div className={styles.groupTitle}>
                {getIconByType(type)}
                <span className={styles.typeName}>{type}</span>
              </div>
              <div className={styles.groupInfo}>
                <span>{`Regions: ${uniqueRegions}`}</span>
                <span>{`Connections: ${groupConnections}`}</span>
              </div>
              <button className={styles.toggleButton}>
                {expandedGroups.includes(type) ? (
                  <div className={styles.caretIcon}>
                    <CaretUp />
                  </div>
                ) : (
                  <div className={styles.caretIcon}>
                    <CaretDown />
                  </div>
                )}
              </button>
            </div>
            {expandedGroups.includes(type) && (
              <div className={styles.groupContent}>
                {ports.map((port, i) => (
                  <PortItem
                    key={port.name}
                    port={port}
                    i={i}
                    skipOpened={false}
                    addConnectionCallback={addConnectionCallback}
                    parents={parentPorts}
                    connections={connections}
                    selectPortToDelete={setSelectedPort}
                    l2Ports={ports}
                  />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CloudLinksInfo;
