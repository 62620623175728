import { PortType } from "../../../pages/PortsPage/types";
import { L2Type } from "./L2MeteringDialog";

export const removeUnusedIps = (fields: any, currentL2Type: string) => {
  switch (currentL2Type as L2Type) {
    case "port_vlan":
      delete fields.vtep_id;
      delete fields.vni_id;
      return;
    case "port_interface":
      delete fields.vni_id;
      delete fields.vlan_id;
      delete fields.vtep_id;
      return;
    case "vtep_vni":
    default:
      delete fields.vlan_id;
      delete fields.vtep_id;
      delete fields.physical_port_id;
  }
};
