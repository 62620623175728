import { calcBandwidthWithUnits } from "../calcBandwidthWithUnits";
import isEmpty from "../common/isEmpty";
import { useCallback, useMemo, useState } from "react";

export function errorKey(i: number, fieldName: string): string {
  return i + "_" + fieldName;
}

export const ipv6Reg = "([0-9a-fA-F]{0,4}:){1,7}[0-9a-fA-F]{0,4}";
export const ipv4Reg = "[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}";

export const INVALID_IP_WITH_PREFIX = "Invalid IP address with prefix";
export const INVALID_32_BIT_VALUE_RANGE = "Value is out of range";
export const INVALID_IP = "Invalid IP address";

export const MAX_LENGTH_ERROR = (max: number): string =>
  `String is longer than ${max} characters`;
export const MIN_LENGTH_ERROR = (min: number): string =>
  `String must be at least ${min} characters`;
export type ValidatorResponse = {
  isOk: boolean;
  errors: { [key: string]: string };
};

const azurePattern = "00000000-0000-0000-0000-00000000000";

export function checkMaxLength(value: string, max: number): boolean {
  return value.length <= max;
}
export function checkMinLength(value: string, min: number): boolean {
  return value.length >= min;
}

export const MAX_NUMBER_ERROR = (max: number): string =>
  `Value should not be grater than ${max}`;
export const MIN_NUMBER_ERROR = (min: number): string =>
  `Value should be at least equal to ${min}`;

export function checkMaxNumber(value: string | number, max: number): boolean {
  return +value <= max;
}
export function checkMinNumber(value: string | number, min: number): boolean {
  return +value >= min;
}

export function checkIP(value: string | undefined): boolean {
  const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return value?.match(ipformat) !== null;
}
export function checkIPv6(value: string | undefined): boolean {
  const ipformat = /^([0-9a-fA-F]{0,4}:){1,7}[0-9a-fA-F]{0,4}$/;
  return value?.match(ipformat) !== null;
}

export function checkIPv4WithPrefix(value: string): boolean {
  const ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/[0-9]+/;
  return value.match(ipformat) !== null;
}
export function checkIPv6WithPrefix(value: string): boolean {
  const ipformat = /^([0-9a-fA-F]{0,4}:){1,7}[0-9a-fA-F]{0,4}\/[0-9]+$/;
  return value.match(ipformat) !== null;
}
export function checkEmail(value: string): boolean {
  const emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return value.match(emailFormat) !== null;
}

export function checkAzureTokenFormat(value: string): boolean {
  const azureTokenFormat = /^\w{8}-\w{4}-\w{4}-\w{4}-\w{10,12}$/;
  return value.match(azureTokenFormat) !== null;
}

export function checkNameFormat(value: string): boolean {
  const regex = /^[a-zA-Z0-9_]+$/;
  return regex.test(value);
}

export function checkIf32bitOrLess(
  value: string,
  bandwidthUnit: string
): boolean {
  const min = 0;
  const max = 4294967294;
  const calcValue = calcBandwidthWithUnits(Number(value), bandwidthUnit);

  return calcValue >= min && calcValue <= max;
}

export function checkEqualValues(first: string, second: string): boolean {
  return first === second;
}

export function checkPrefix(value: string): boolean {
  {
    if (value) {
      const [ip, cidr] = value.split("/");
      if (parseInt(cidr) >= 8 && parseInt(cidr) <= 32) {
        return true;
      } else {
        if (!parseInt(cidr)) {
          return true;
        }
        return false;
      }
    }
    return false;
  }
}

export function checkNNIId(value: string): boolean {
  if (value.length < 14) return false;

  const regex = /^(dxcon|dxlag)-.*$/;
  return regex.test(value);
}

export default class Validator {
  private errors: { [key: string]: string } = {};

  getResult = (): ValidatorResponse => {
    return {
      isOk: !Object.entries(this.errors).find(
        ([, value]: [string, string]) => value
      ),
      errors: this.errors,
    };
  };

  private setError = (field: string, err?: string): void => {
    if (!this.errors[field]) {
      this.errors[field] = err || "Validation error";
    }
  };

  checkTrue = (value: boolean, field: string, error?: string): void => {
    if (!value) {
      this.setError(field, error);
    }
  };

  checkLength = (
    value: string,
    [min, max]: [number, number],
    field: string
  ): void => {
    this.checkTrue(checkMinLength(value, min), field, MIN_LENGTH_ERROR(min));
    this.checkTrue(checkMaxLength(value, max), field, MAX_LENGTH_ERROR(max));
  };

  checkNumber = (
    value: string,
    [min, max]: [number, number],
    field: string
  ): void => {
    this.checkTrue(checkMinNumber(value, min), field, MIN_NUMBER_ERROR(min));
    this.checkTrue(checkMaxNumber(value, max), field, MAX_NUMBER_ERROR(max));
  };

  forceSetError = (field: string, err?: string): void => {
    this.errors[field] = err || "Error";
  };

  checkEmpty = (
    value: string | number | undefined | null,
    field: string
  ): void => {
    if (isEmpty(value)) {
      this.setError(field, "Shouldn`t be empty");
    }
  };

  checkMinMax = (
    value: string | number | undefined,
    [min, max]: [number, number],
    field: string
  ): void => {
    const errorMessage = `Should be in ${min}-${max} range`;
    const castedToNumber = Number(value);
    if (!value || !castedToNumber) {
      this.setError(field, errorMessage);
    }
    if (castedToNumber < min || castedToNumber > max) {
      this.setError(field, errorMessage);
    }
  };

  checkEmptyArray = (
    value: Array<string | number> | undefined,
    field: string
  ): void => {
    if (!value || value.length === 0) {
      this.setError(field, "Shouldn`t be empty");
    }
  };

  checkIsNumeric = (value: number, field: string): void => {
    this.checkTrue(Number.isInteger(value), field, "Should be numeric value");
  };

  checkIP = (value: string | undefined, field: string): void => {
    this.checkTrue(checkIP(value), field, INVALID_IP);
  };

  checkIPv6 = (value: string | undefined, field: string): void => {
    this.checkTrue(checkIPv6(value), field, INVALID_IP);
  };

  checkIPAll = (value: string | undefined, field: string): void => {
    this.checkTrue(checkIP(value) || checkIPv6(value), field, INVALID_IP);
  };

  checkIPWithPrefix = (value: string, field: string): void => {
    this.checkTrue(checkIPv4WithPrefix(value), field, INVALID_IP_WITH_PREFIX);
  };

  checkIPorIPWithPrefix = (value: string, field: string): void => {
    this.checkTrue(
      checkIPv4WithPrefix(value) || checkIP(value),
      field,
      INVALID_IP_WITH_PREFIX
    );
  };

  checkIPWithPrefixIPv6 = (value: string, field: string): void => {
    this.checkTrue(checkIPv6WithPrefix(value), field, INVALID_IP_WITH_PREFIX);
  };

  checkIf32bitOrLess = (
    value: string,
    bandwidthUnit: string,
    field: string
  ): void => {
    this.checkTrue(
      checkIf32bitOrLess(value, bandwidthUnit),
      field,
      INVALID_32_BIT_VALUE_RANGE
    );
  };

  checkIPWithPrefixAll = (value: string, field: string): void => {
    this.checkTrue(
      checkIPv4WithPrefix(value) || checkIPv6WithPrefix(value),
      field,
      INVALID_IP_WITH_PREFIX
    );
  };

  checkIPOrIPWithPrefixAll = (value: string, field: string): void => {
    this.checkTrue(
      checkIPv4WithPrefix(value) ||
        checkIPv6WithPrefix(value) ||
        checkIP(value) ||
        checkIPv6(value),
      field,
      INVALID_IP
    );
  };

  checkRemoteNetworkIPWithPrefix = (value: string, field: string): void => {
    this.checkEmpty(value, "remoteNetworks");
    // this.checkTrue(checkIP(value), field, "Invalid IP address");
    // this.checkTrue(value == "0.0.0.0", field, "Can't be 0.0.0.0");
    this.checkTrue(
      checkPrefix(value),
      field,
      "Prefix must be in the range from 8 to 32"
    );
  };

  checkAccountId = (value: string | undefined, field: string): void => {
    if (!value) {
      return;
    }
    const isValidLength = value.length === 12;
    const isNumeric = /^\d{12}$/.test(value);

    this.checkTrue(
      isValidLength && isNumeric,
      field,
      "Account ID must be exactly 12 numeric characters"
    );
  };

  checkInputLength = (
    value: string,
    field: string,
    maxLength: number
  ): void => {
    this.checkEmpty(value, field);
    this.checkTrue(
      value.length < maxLength,
      field,
      `${maxLength} characters or less`
    );
  };

  checkEmail = (value: string, field: string): void => {
    this.checkTrue(checkEmail(value), field, "Invalid email");
  };

  checkAzureTokenFormat = (value: string, field: string): void => {
    this.checkTrue(
      checkAzureTokenFormat(value),
      field,
      `Value must match the pattern ${azurePattern}`
    );
  };

  checkNameFormat = (value: string, field: string): void => {
    this.checkTrue(checkNameFormat(value), field, "Invalid name");
  };

  checkEqualPasswords = (
    first: string,
    second: string,
    field: string
  ): void => {
    this.checkTrue(
      checkEqualValues(first, second),
      field,
      "Passwords are not equal"
    );
  };

  checkNNIId = (value: string | undefined, field: string): void => {
    this.checkEmpty(value, field);
    if (value) {
      this.checkTrue(checkNNIId(value), field, "NNI is incorrect");
    }
  };

  checkJSON = (value: string, field: string): void => {
    try {
      JSON.parse(value);
    } catch (e) {
      this.setError(field, "Invalid JSON format");
    }
  };
}

export function validateShallow(
  data: any,
  initValidator?: Validator
): Validator {
  return initValidator || new Validator();
}

export type ValidateFunc<V> = (val: V, validator?: Validator) => Validator;

export function useValidation<V>(
  validateFunc: ValidateFunc<V> | undefined = validateShallow,
  deps: [V, ...any]
): [ValidatorResponse["errors"] | undefined, () => { isOk: boolean }] {
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const errors: ValidatorResponse["errors"] | undefined = useMemo(() => {
    if (showErrors) {
      return validateFunc(deps[0]).getResult().errors;
    }
    return undefined;
  }, [...deps, showErrors]);

  const validate = useCallback((): { isOk: boolean; data?: V } => {
    const { isOk } = validateFunc(deps[0]).getResult();
    setShowErrors(true);
    return { isOk };
  }, deps);

  return [errors, validate];
}
