import React, { FC } from "react";
import Input from "../../../../../components/common/formComponents/Input";
import Section from "../../../../../components/common/Section";
import { PROTOCOLS_TYPES_MAP } from "../../../../../helpers/common/constantsAlias";
import styles from "./FilterSection.module.scss";

type Props = {
  fields: { [key: string]: any };
  onChange: (field: string, value: any) => void;
  className: string;
  isEdit: boolean;
};

const FilterSection: FC<Props> = ({ fields, onChange, className, isEdit }) => {
  return (
    <Section title={"Filter"} columnsCount={3} className={className}>
      <Input
        label="Source Port"
        name="src_l4_port"
        className={styles.portWrapper}
        placeholder="xxx"
        handleFieldChange={onChange}
        value={fields.src_l4_port}
        medium
        isOptional
      />
      <Input
        label="Destination Port"
        name="dst_l4_port"
        className={styles.portWrapper}
        placeholder="xxx"
        handleFieldChange={onChange}
        value={fields.dst_l4_port}
        medium
        isOptional
      />
      <Input
        label="Protocol"
        name={"filterProtocol"}
        handleFieldChange={onChange}
        className={styles.inputWrapperCenter}
        value={fields.filterProtocol}
        placeholder={fields.filterProtocol || "Protocol"}
        valuesList={PROTOCOLS_TYPES_MAP}
        medium
        isNotEditable={isEdit}
      />
    </Section>
  );
};
export default FilterSection;
