import React, { FC, ReactElement, useMemo, useState, useEffect } from "react";
import ChartsHeader from "../../../../components/common/charts/ChartsHeader/ChartsHeader";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import ChartToppersTabs from "../../../../components/common/ChartToppersTabs";
import Loader from "../../../../components/common/Loader/Loader";
import AutosortTable, {
  defaultDateColumn,
  filedAccessor,
} from "../../../../components/common/table/autoSortTable/autosortTable";
import grafanaLinksFactory from "../../../../components/common/charts/GraphanaLinksFactory";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { elasticApi } from "../../../../helpers/api/ElasticApi";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { PageProps, TrafficType } from "../../../../helpers/types";
import DropdownWithSearch from "../../../../components/common/Dropdown/DropdownWithSearch";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import { NatRuleType } from "../../../../helpers/api/apiTypes";

import NatConfigurationContext, {
  useNatContext,
} from "../../../../contexts/servicesContext/NatConfigurationContext";
import { parseQuery } from "../../../../helpers/common/parseQuery";
import { ACTIVE_NAT_QUERY_PARAM } from "../../../../helpers/navigation";

import styles from "./NATRulesStatistics.module.scss";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";
import { SERVICES_NAT } from "../../../../helpers/navigation/entries";
import { BPS } from "../../../../helpers/common/constantsAlias";

const Source_IP = "Source IP";
const Source_Port = "Source Port";
const Destination_IP = "Destination IP";
const Destination_Port = "Destination Port";
const Location_field = "Location";
const Source_Segments = "Source Segments";
const Destination_Segments = "Destination Segments";
const Client_traffic_count = "Client traffic count";
const Server_traffic_count = "Server traffic count";

const columns = [
  defaultDateColumn,
  {
    accessorKey: "Source_IP", //access nested data with dot notation
    header: Source_IP,
  },
  {
    accessorKey: "Source_Port",
    header: Source_Port,
  },
  {
    accessorKey: "Destination_IP",
    header: Destination_IP,
  },
  {
    accessorKey: "Destination_Port",
    header: Destination_Port,
  },
  {
    accessorKey: "Location_field",
    header: Location_field,
  },
  {
    accessorKey: "Source_Segments",
    header: Source_Segments,
  },
  {
    accessorKey: "Destination_Segments",
    header: Destination_Segments,
  },
  {
    accessorKey: "Client_traffic_count",
    header: Client_traffic_count + "[Bytes]",
  },
  {
    accessorKey: "Server_traffic_count",
    header: Server_traffic_count + "[Bytes]",
  },
];

const NATRulesStatistics: FC<PageProps> = ({ tabs: Tabs }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();

  const [activeTraffic, setActiveTraffic] = useState<TrafficType>(BPS);
  const [activeNat, setActiveNat] = useState<string>(
    parseQuery(ACTIVE_NAT_QUERY_PARAM)
  );
  const [natListData, setNatListData] = useState<
    Array<NatRuleType> | undefined
  >();

  const { natRulesList, fetchNatRulesList } = useNatContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchNatRulesList(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (natRulesList) {
      setNatListData(natRulesList);
      if (!Boolean(activeNat) && natRulesList[0]?.name) {
        setActiveNat(natRulesList[0].name);
      }
    }
  }, [natRulesList]);

  const [bps_rx, bps_tx, pps_rx, pps_tx, pie_rx, pie_tx] = useMemo(() => {
    const data = { timeRange: timeRange.general, tenantName: selectedTenant };
    return [
      grafanaLinksFactory.nat_bps_rx(timeRange.general)(data),
      grafanaLinksFactory.nat_bps_tx(timeRange.general)(data),

      grafanaLinksFactory.nat_pps_rx(timeRange.general)(data),
      grafanaLinksFactory.nat_pps_tx(timeRange.general)(data),

      grafanaLinksFactory.nat_pie_rx(data),
      grafanaLinksFactory.nat_pie_tx(data),
    ];
  }, [timeRange, selectedTenant]);

  const tenantIsReady = Boolean(selectedTenant);

  const formatData = (resultArr: any) => {
    const formattedArr: any = [];
    resultArr.forEach((element: any) => {
      const fields = element.fields;
      formattedArr.push({
        date: new Date(filedAccessor(fields, "@timestamp")),
        Source_IP: filedAccessor(fields, Source_IP),
        Source_Port: filedAccessor(fields, Source_Port),
        Destination_IP: filedAccessor(fields, Destination_IP),
        Destination_Port: filedAccessor(fields, Destination_Port),
        Location_field: filedAccessor(fields, Location_field),
        Source_Segments: filedAccessor(fields, Source_Segments),
        Destination_Segments: filedAccessor(fields, Destination_Segments),
        Client_traffic_count: filedAccessor(fields, Client_traffic_count),
        Server_traffic_count: filedAccessor(fields, Server_traffic_count),
      });
    });
    return formattedArr;
  };

  const renderNatDropDownFilter = () => {
    return (
      <DropdownWithSearch
        withClearIcon
        id={"NatRuleTableFilter"}
        onChange={(val) => {
          setActiveNat(val.key);
        }}
        selected={mapStringToItem(activeNat)}
        placeholder={activeNat || ""}
        error={""}
        valuesList={(natListData || []).map((nat) => nat.name)}
        isMedium
        disabled={false}
      />
    );
  };

  return (
    <>
      <Tabs showTimeRange tabs={[SERVICES_NAT()]} />
      <ServicesRedirectPlaceholder type="nat">
        <div className={styles.chartsContainer}>
          <div className={styles.header}>
            <span>NAT Rules</span>
          </div>
          {!tenantIsReady ? (
            <Loader label="Loading tenant..." />
          ) : (
            <div className={styles.charts}>
              <ChartWrapper
                className={styles.leftChart}
                title={""}
                controls={
                  <ChartToppersTabs
                    activeTab={activeTraffic}
                    setTabActive={setActiveTraffic}
                  />
                }
                links={
                  activeTraffic === BPS ? [bps_rx, bps_tx] : [pps_rx, pps_tx]
                }
              />
              <ChartWrapper
                className={styles.rightChart}
                title="NAT Rules hits"
                links={[pie_rx, pie_tx]}
              />
            </div>
          )}
          {selectedTenant && (
            <AutosortTable
              csvTitle={"NAT Rules Statistics"}
              syntheticRefreshParam={activeNat}
              dropDownFilterContent={renderNatDropDownFilter()}
              tableRequest={() => {
                return elasticApi.getNatRules(selectedTenant, activeNat);
              }}
              formatter={formatData}
              columns={columns}
            />
          )}
        </div>
      </ServicesRedirectPlaceholder>
    </>
  );
};
export default withLayout<PageProps>(
  withContexts(NATRulesStatistics, [NatConfigurationContext])
);

type ChartProps = {
  className?: string;
  title: string;
  links: Array<string>;
  controls?: ReactElement;
};
const ChartWrapper: FC<ChartProps> = ({
  className,
  title,
  links,
  controls,
}) => {
  return (
    <div className={className}>
      <ChartsHeader title={title} controls={controls} />
      <div className={styles.iframesContainer}>
        {links.map((link) => (
          <GrafanaGraph link={link} key={link} />
        ))}
      </div>
    </div>
  );
};
