import React, { useEffect } from "react";
import styles from "./DashboardLeft.module.scss";
import { classNames } from "../../../../helpers/common/classNames";
import { useDashboardFilterContext } from "../../../../contexts/dashboardContext/DashboardFilterContext";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import TopologyButtons from "./TopologyButtons/TopologyButtons";
import EventsTable from "../../../../pages/EventsPage/EventsTable";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { UnitedConnectionContextContainer } from "../../../Network/UnitedConnections/UnitedConnectionContext";
import ConnectionsBlock from "../../../Network/UnitedConnections/components/ConnectionsBlock";
import { useTopologyPageContext } from "../../context";

const DashboardLeft: React.FC<{}> = ({}) => {
  const { setSelectedInterface } = useDashboardFilterContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { setConnectionType } = useTopologyPageContext();

  useEffect(() => {
    if (selectedTenant) {
      setSelectedInterface(undefined);
    }
  }, [selectedTenant]);

  return (
    <div className={classNames(styles.leftPart)}>
      <TopologyButtons className={styles.buttonsContainer} />
      <ConnectionsBlock
        addConnectionCallback={() => {
          return;
        }}
        onlyDiagram
        onTabChange={setConnectionType}
      />
      <EventsTable isCollapsible type="tenant" />
    </div>
  );
};

export default withContexts<any>(DashboardLeft, [
  UnitedConnectionContextContainer,
]);
