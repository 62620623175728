import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LoopbackIconNew({ color }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85181 5.92057C8.63014 5.72177 8.63027 5.3745 8.85208 5.17586L13.2194 1.26491C13.5416 0.976458 14.0532 1.20519 14.053 1.63757L14.0518 4.89707C17.5816 5.70457 20.2998 8.76546 20.5091 12.5616C20.7625 17.1562 17.2432 21.0863 12.6485 21.3397C8.05384 21.593 4.12374 18.0737 3.87038 13.4791C3.85841 13.2619 3.85487 13.046 3.85952 12.8317C3.8715 12.2795 4.32882 11.8416 4.88097 11.8536C5.43313 11.8656 5.87103 12.3229 5.85905 12.8751C5.85551 13.0382 5.85819 13.2029 5.86735 13.3689C6.05989 16.8607 9.04662 19.5353 12.5384 19.3427C16.0302 19.1502 18.7047 16.1634 18.5122 12.6717C18.3621 9.94962 16.5131 7.72315 14.051 6.96596L14.0501 9.46275C14.0499 9.89513 13.5381 10.1235 13.2162 9.83479L8.85181 5.92057Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default LoopbackIconNew;
