import React, { FC, useEffect, useMemo } from "react";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { TenantType } from "../../../helpers/api/apiTypes";
import { ERROR, isOk } from "../../../helpers/common/RequestStateHelpers";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import TenantVirtualInterfaceContextContainer from "../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import {
  TENANT_L2,
  TENANT_L3,
  PACKAGES,
  TENANT_USAGE,
  DIA,
} from "../../../helpers/navigation/entries";
import QuotaContextContainer from "../../../contexts/tenantsContext/QuotaContext";
import Layer3Interface from "../../../pages/Layer3Interface";
import LayoutComponent from "../../../components/common/LayoutComponent";
import DataPlaceHolders from "../../../components/common/loadStates/DataPlaceHolder";
import TenantsPackagesPage from "../../../components/tenants/TenantPackages/TenantPackagesPage";
import TenantUsagePage from "../../../components/tenants/TenantPackages/TenantUsagePage";
import { OneTenantTabs } from "../../../components/common/navigation/OneTenantTabs";
import TenantL2Interfaces from "../../../components/tenants/OneTenant/TenantL2Interfaces";
import DIAPage from "../../../pages/DIAPage";

const OneTenant: FC = () => {
  const path = useMatchPath();
  const { tenant_name: tenant_name } = useParams<{ tenant_name: string }>();
  const { list, fetchList, listStatus, fetchSelected } = useTenantContext();

  useEffect(() => {
    fetchList();
    fetchSelected(tenant_name);
  }, []);

  const tenant = useMemo(
    () =>
      list?.find((list: TenantType) => list.name.toString() === tenant_name),
    [list, tenant_name]
  );

  if (!tenant) {
    const status = isOk(listStatus)
      ? { state: ERROR, message: "No such tenant" }
      : listStatus;

    return (
      <LayoutComponent>
        <DataPlaceHolders status={status} />
      </LayoutComponent>
    );
  }

  return (
    <Switch>
      <Route path={path + "/" + PACKAGES().path}>
        <QuotaContextContainer tenant={tenant_name}>
          <TenantsPackagesPage />
        </QuotaContextContainer>
      </Route>
      <Route path={path + "/" + DIA().path}>
        <DIAPage />
      </Route>
      <Route path={path + "/" + TENANT_USAGE().path}>
        <QuotaContextContainer tenant={tenant_name}>
          <TenantUsagePage />
        </QuotaContextContainer>
      </Route>
      <Route path={path + "/" + TENANT_L3().path}>
        <Layer3Interface tabs={OneTenantTabs} />
      </Route>
      <TenantVirtualInterfaceContextContainer tenant={tenant_name}>
        <Route path={path + "/" + TENANT_L2().path}>
          <TenantL2Interfaces />
        </Route>
      </TenantVirtualInterfaceContextContainer>
      <Route path={path}>
        <Redirect to={path + "/" + TENANT_L3().path} />
      </Route>
    </Switch>
  );
};

export default OneTenant;
