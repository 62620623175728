import { ReactElement } from "react";
import CloudIcon from "../../../../../components/icons/CloudIcon";
import DataCenterPortIcon from "../../../../../components/icons/DataCenterPortIcon";
import LocationFillIcon from "../../../../../components/icons/LocationFillIcon";
import StatusIcon from "../../../../../components/icons/StatusIcon";
import { IconProps } from "../../../../../helpers/types";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
  DIA,
  CUSTOMER_PORTS,
} from "./consts";
import PortBaseIcon from "../Ports/icons/PortBaseIcon";
import DIAConnectionIcon from "../../../../../components/icons/DIAConnectionIcon";
import { DIA_UNITED_CONNECTIONS } from "../../../../ZayoPages/Connections/ConnectionConfigurator/consts";

type IconType = (props: IconProps) => ReactElement;

export const getMenuIcon = (id: string): IconType => {
  switch (id) {
    case CUSTOMER_LOCATIONS:
      return LocationFillIcon;
    case CLOUD_CONNECTIONS:
      return CloudIcon;
    case DATA_CENTER_PORT:
      return DataCenterPortIcon;
    case DIA:
      return DIAConnectionIcon;
    case DIA_UNITED_CONNECTIONS:
      return DIAConnectionIcon;
    case CUSTOMER_PORTS:
      return PortBaseIcon;
    default:
      return StatusIcon;
  }
};
