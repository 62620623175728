import React, { FC, ReactElement, useEffect } from "react";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import GrafanaLoader from "./GrafanaLoader";
import GrafanaPlaceholder from "./GrafanaPlaceholder";
import GrafanaContainerContextContainer, {
  NONE,
  useGrafanaContainerContext,
} from "./GrafanaContainerContext";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { classNames } from "../../../../helpers/common/classNames";

export enum GRAFANA_TYPES {
  DPI = "DPI", // application related graphs - both in application screen and in topology screen
  SDR = "SDR", // network observability graphs
  IDS = "IDS", // cyber threat related graphs
  FQDN = "FQDN", // web filtering statistics
}

type Props = {
  type?: string;
  children: React.ReactNode;
  className?: string;
  placeholderClassName?: string;
  showInsteadPlaceholder?: ReactElement;
  isHidden?: boolean;
  hideCharts?: (value: boolean) => void;
  ignorePlaceholder?: boolean;
  skipDocCountCheck?: boolean;
};

const GrafanaContainer: FC<Props> = ({
  type,
  children,
  className,
  showInsteadPlaceholder,
  isHidden,
  hideCharts = () => undefined,
  ignorePlaceholder,
  skipDocCountCheck,
  placeholderClassName,
}) => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  const {
    getGrafanaTimeRange,
    availableTimeRange,
    timeRangeStatus,
  } = useGrafanaContainerContext();

  useEffect(() => {
    if (type === "systems" || (type && selectedTenant)) {
      getGrafanaTimeRange(
        timeRange.general,
        type,
        selectedTenant,
        skipDocCountCheck
      );
    }
  }, [timeRange, selectedTenant]);

  useEffect(() => {
    hideCharts(availableTimeRange === "none");
  }, [availableTimeRange]);

  // Waiting for availableTimeRange
  if (timeRangeStatus?.state === "pending" && !isHidden) {
    return <GrafanaLoader />;
  }

  // OPTIONAL. Show component instead of placeholder only if availableTimeRange === NONE
  if (
    availableTimeRange &&
    availableTimeRange === NONE &&
    showInsteadPlaceholder
  ) {
    return <div className={className}>{showInsteadPlaceholder}</div>;
  }

  // Show placeholder
  if (availableTimeRange && !ignorePlaceholder) {
    return (
      <GrafanaPlaceholder
        className={classNames(className, placeholderClassName)}
        timeRange={availableTimeRange}
      />
    );
  }

  // Show children
  return <div className={className}>{children}</div>;
};
export default withContexts<Props>(GrafanaContainer, [
  GrafanaContainerContextContainer,
]);
