import React from "react";
import LayoutComponent from "../../../components/common/LayoutComponent";
import CmdPanel from "./components/CmdPanel";
import InfoPanel from "./components/InfoPanel";
import ControlPanel from "./components/ControlPanel";

import styles from "./styles.module.scss";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { LookingGlassContextContainer } from "./LookingGlassContext";
import ActiveTenantSelector from "../../../components/common/navigation/tenantSelector/ActiveTenantSelector";

const LookingGlass = () => {
  return (
    <LayoutComponent>
      <div className={styles.header}>
        <div className={styles.tenantSelector}>
          <ActiveTenantSelector />
        </div>
      </div>
      <div className={styles.wrapper}>
        <ControlPanel />
        <CmdPanel />
        <InfoPanel />
      </div>
    </LayoutComponent>
  );
};

export default withContexts(LookingGlass, [LookingGlassContextContainer]);
