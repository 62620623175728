import { GBPS, MBPS } from "./consts";

export function transferFromKbps(value: number, unit: string): number {
  switch (unit) {
    case MBPS:
      return value / 1000;
    case GBPS:
      return value / 1000000;
    default:
      return value;
  }
}
