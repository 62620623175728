import { RequestState, RequestStatus } from "../types";
import { Res } from "../api/Api";

export const PENDING: RequestState = "pending";
export const OK: RequestState = "ok";
export const ERROR: RequestState = "error";
export const IDLE: RequestState = "idle";

export const setPending = (message?: string): RequestStatus => {
  return { state: PENDING, message };
};

export const setOk = (message?: string): RequestStatus => {
  return { state: OK, message };
};

export const setError = (message?: string): RequestStatus => {
  return { state: ERROR, message };
};

export const isPending = (state?: RequestState | RequestStatus): boolean => {
  return _checkStatus(state, PENDING);
};

export const isOk = (state?: RequestState | RequestStatus): boolean => {
  return _checkStatus(state, OK);
};
export const isError = (state?: RequestState | RequestStatus): boolean => {
  return _checkStatus(state, ERROR);
};

const _checkStatus = (
  state: RequestState | RequestStatus | undefined,
  status: RequestState
): boolean => {
  if (typeof state === "string") {
    return state === status;
  }
  return state?.state === status;
};

export const resToState = (res: Res<any>): RequestStatus => {
  let state: RequestState = OK;

  if (res.isIdle) {
    state = IDLE;
  } else if (res.isLoading) {
    state = PENDING;
  } else if (!res.ok) {
    state = ERROR;
  }

  return {
    state,
    message: res?.error,
  };
};

export const mergeStatuses = (
  status1: RequestStatus | undefined,
  status2: RequestStatus | undefined
): RequestStatus | undefined => {
  if (!status1) {
    return status2;
  }

  if (!status2) {
    return status1;
  }

  return isOk(status1) ? status2 : status1;
};
