import { getSublistFromArray } from "../../../helpers/getSublistFromArray";
import {
  INSIGHTS,
  TOPOLOGY,
  APPLICATIONS,
  TRAFFIC,
  CYBER_THREATS,
  NETWORK,
  CONNECTIVITY,
  SEGMENTATION,
  CLOUD_ROUTER,
  SERVICES,
  TENANTS,
  SERVICES_FW,
  SERVICES_NAT,
  SERVICES_PREFERENCES,
  NETWORK_RESOURCES,
  SYSTEMS,
  IPAM,
  EVENTS,
  PROVISIONING,
  PACKAGES,
  USERS,
  WEB_FILTERING,
  USER_GROUPS,
  CLOUD_RESOURCES,
  CONNECTIONS_UNITED,
  ORDERED_PORTS,
  SERVICE_CATALOG,
  SITES,
  LOOKING_GLASS,
} from "../../../helpers/navigation/entries";
import InsightsIcon from "../../icons/tabs/InsightsIcon";
import NetworkIcon from "../../icons/tabs/NetworkIcon";
import ServicesIcon from "../../icons/tabs/ServicesIcon";
import SystemsIcon from "../../icons/tabs/SystemsIcon";
import TenantsIcon from "../../icons/tabs/TenantsIcon";
import { ButtonInfo } from "../types";

export const zayoAdminFormButtons = (
  goTo: () => void,
  isPackagesVisible?: boolean
): Array<ButtonInfo> => {
  return [
    {
      ...NETWORK_RESOURCES(),
      Icon: SystemsIcon,
      subList: getSublistFromArray(
        [SYSTEMS(), IPAM(), SITES(), CLOUD_RESOURCES(), EVENTS()],
        goTo,
        NETWORK_RESOURCES()
      ) as any,
    },
    {
      ...TENANTS(),
      Icon: TenantsIcon,
      withSeparator: true,
      subList: getSublistFromArray(
        [
          PROVISIONING(),
          USERS(),
          SERVICE_CATALOG(),
          USER_GROUPS(),
          PACKAGES(),
          ORDERED_PORTS(),
        ],
        goTo,
        TENANTS()
      ) as any,
    },
    {
      ...INSIGHTS(),
      Icon: InsightsIcon,
      subList: getSublistFromArray(
        [TOPOLOGY(), APPLICATIONS(), TRAFFIC(), CYBER_THREATS()],
        goTo,
        INSIGHTS(),
        []
      ) as any,
    },
    {
      ...NETWORK(),
      Icon: NetworkIcon,
      subList: getSublistFromArray(
        [CONNECTIVITY(), CLOUD_ROUTER(), LOOKING_GLASS(), CONNECTIONS_UNITED()],
        goTo,
        NETWORK()
      ) as any,
    },
    {
      ...SERVICES(),
      Icon: ServicesIcon,
      subList: getSublistFromArray(
        [
          SERVICES_FW(),
          SERVICES_NAT(),
          SEGMENTATION(),
          WEB_FILTERING(),
          SERVICES_PREFERENCES("", isPackagesVisible),
        ],
        goTo,
        SERVICES()
      ) as any,
    },
  ];
};
