import React, { FC } from "react";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_LOCATIONS,
  CUSTOMER_PORTS,
  DATA_CENTER_PORT,
  DIA,
  DIA_UNITED_CONNECTIONS,
} from "../../../consts";
import { FieldsType } from "../../../types";
import CloudItem from "./CloudItem";
import CustomerLocationItem from "./CustomerLocationItem";
import DataCenterPortItem from "./DataCenterPortItem";
import DIAItem from "./DIAItem";
import CustomerPortItem from "./CustomerPortItem";
import {
  TenantCustomerPort,
  TenantPort,
} from "../../../../../../Network/UnitedConnections/types";
import DIAConnectionItem from "./DIAConnectionItem";

type Props = {
  type: string;
  fields: FieldsType;
  onChange: (field: string, value: any) => void;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: () => void;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
  withIP?: boolean;
  withoutType?: boolean;
  ports?: Array<TenantCustomerPort>;
  parentPorts?: Array<TenantPort>;
  selectedPort?: TenantCustomerPort;
  selectPort?: (name: string) => void;
  regionDropdown?: React.ReactElement;
  disabled?: boolean;
  excluded?: Array<string>;
  onErrorChange?: any;
  addFrom?: string;
  commonFields?: any;
  azureKeyData?: any;
  isAzureKeyActive?: boolean;
};

const FormItemRouter: FC<Props> = (props) => {
  switch (props.type) {
    case CUSTOMER_LOCATIONS:
      return <CustomerLocationItem {...props} />;
    case DATA_CENTER_PORT:
      return <DataCenterPortItem {...props} />;
    case CLOUD_CONNECTIONS:
      return <CloudItem {...props} />;
    case CUSTOMER_PORTS:
      return <CustomerPortItem {...props} />;
    case DIA:
      return <DIAItem {...props} />;
    case DIA_UNITED_CONNECTIONS:
      return <DIAConnectionItem {...props} />;
    default:
      return null;
  }
};
export default FormItemRouter;
