import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../../contexts/common/AbstractDialogContext";
import { createContextUtils } from "../../../../contexts/common/utils";
import { tenantVirtualInterfacesApi } from "../../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import { AWS_LOWERCASE } from "../../../../helpers/consts";
import { getUpdatedFields } from "../../../../helpers/setFields";
import {
  ConnectionConfigType,
  ConnectionEndpoint,
} from "../types/configurator";
import { getAwsVpnBody } from "./helpers/getAwsVpnBody";
import { getBranchBody } from "./helpers/getBranchBody";
import { getDirectConnectBody } from "./helpers/getDirectConnectBody";
import { getIpsecBody } from "./helpers/getIpsecBody";
import { getIpsecExtraType } from "./helpers/getIpsecExtraType";
import { getLoopbackBody } from "./helpers/getLoopbackBody";
import { getRequestedGateVIBody } from "./helpers/getRequestedGateVIBody";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<any> & {
  connectionType: ConnectionConfigType;
  endpoint: ConnectionEndpoint;
  location: string;
  fields: { [key: string]: any };
  showBranchAgent: boolean;
};

type IState = State & RequestStatuses;
type IFunc = DialogFunc<any> & {
  fieldsOnChange: <T extends { [key: string]: any }>(
    key: keyof T,
    value: any
  ) => void;
  setBranchAgentVisibility: (status: boolean) => void;
  resetFields: () => void;
};

const [
  ConnectionConfiguratorContext,
  useConnectionConfiguratorContext,
] = createContextUtils<IState, IFunc>();
export { useConnectionConfiguratorContext };

class ConnectionConfiguratorContextContainer extends AbstractDialogContextContainer<
  any,
  IState,
  RequestStatuses,
  IFunc
> {
  Context = ConnectionConfiguratorContext;

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      connectionType: "ipsec",
      fields: {},
      endpoint: "branch",
      location: "",
      showBranchAgent: true,
    };
  }

  add = async (
    params: { [key: string]: any },
    tenant_name?: string
  ): Promise<boolean> => {
    if (!tenant_name) return false;

    const { ok } = await this._addWrap(() =>
      this.getAddActionByType(params.type, tenant_name, params)
    );
    return ok;
  };

  setBranchAgentVisibility = async (status: boolean): Promise<void> => {
    this.setState({ showBranchAgent: status });
  };

  // By type
  getAddActionByType = async (
    type: string,
    tenant_name: string,
    params: { [key: string]: any }
  ): Promise<any> => {
    let body;
    switch (type as ConnectionConfigType) {
      case "ipsec":
        const ipsecType = getIpsecExtraType(params);
        return this.getIpsecAddActionByType(ipsecType, tenant_name, params);
      case "loopback":
        body = getLoopbackBody(params);
        return await tenantVirtualInterfacesApi.addVirtualInterfaceBridgeDomain(
          tenant_name,
          body
        );
      case "gateway":
        body = getRequestedGateVIBody(params);
        return await tenantVirtualInterfacesApi.requestVIGate(
          tenant_name,
          body
        );
      case "directConnect":
        body = getDirectConnectBody(params);
        return await tenantVirtualInterfacesApi.addVirtualInterfaceBridgeDomain(
          tenant_name,
          body
        );
      default:
        return { ok: false };
    }
  };

  // IPSEC by endPoint and CloudOperator
  getIpsecAddActionByType = async (
    type: string,
    tenant_name: string,
    params: { [key: string]: any }
  ): Promise<any> => {
    let body;
    switch (type) {
      case "ipsec":
        body = getIpsecBody(params);
        return await tenantVirtualInterfacesApi.addVirtualInterfaceIpsec(
          tenant_name,
          body
        );
      case AWS_LOWERCASE:
        body = getAwsVpnBody(params);
        return await tenantVirtualInterfacesApi.createAWSVPN(body, tenant_name);
      case "branch":
        body = getBranchBody(params);
        return await tenantVirtualInterfacesApi.addVirtualInterfaceIpsec(
          tenant_name,
          body
        );
      default:
        return { ok: false };
    }
  };

  // not implemented
  edit = async (): Promise<boolean> => false;

  fieldsOnChange = <T extends { [key: string]: any }>(
    field: keyof T,
    value: any
  ) => {
    const updatedFields = getUpdatedFields<T>(
      this.state.fields as T,
      field,
      value
    );
    this.setState({ fields: updatedFields });
  };

  resetFields(): void {
    this.setState({ fields: {} });
  }

  funcs = {
    edit: this.edit,
    add: this.add,
    fieldsOnChange: this.fieldsOnChange,
    setBranchAgentVisibility: this.setBranchAgentVisibility,
    resetFields: this.resetFields,
  };
}

export default ConnectionConfiguratorContextContainer;
