import { FC, useEffect, useMemo } from "react";
import { withContexts } from "../../helpers/hocs/withContexts";
import SegmentsRulesContext, {
  useSegmentsRulesContext,
} from "./SegmentsRulesContext";
import styles from "./SegmentsRulesContent.module.scss";
import { SegmentsMatrix } from "./components/SegmentsMatrix/SegmentsMatrix";
import { SegmentRulesTable } from "./components/SegmentRulesTable";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";

const SegmentsRulesContent: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const { selectedSegment, fetchSegmentsData } = useSegmentsRulesContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchSegmentsData(selectedTenant);
    }
  }, [selectedTenant]);

  const isCustomStatus = useMemo(() => selectedSegment?.status === "custom", [
    selectedSegment,
  ]);

  return (
    <ServicesRedirectPlaceholder type="segments">
      <div className={styles.wrapper}>
        <SegmentsMatrix />
        {isCustomStatus && <SegmentRulesTable />}
      </div>
    </ServicesRedirectPlaceholder>
  );
};

export default withContexts(SegmentsRulesContent, [SegmentsRulesContext]);
