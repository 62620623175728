import { GCPNNI, RegionItem, TotalProviderInfo } from "../../types";
import Table from "../../../../components/common/table/newTable/Table";
import { gcpNNITableHeader, gcpRegionsTableHeader } from "./tableHeaders";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { TableWrapper } from "../TableWrapper";
import useDeleteDialog from "../../../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { RequestStatus } from "../../../../helpers/types";

type GCPTableProps = {
  gcpNNIs: Array<GCPNNI> | undefined;
  combinedGCPInfo: {
    regions: Array<RegionItem>;
    total: TotalProviderInfo;
  };
  refetchGCPState: () => Promise<void>;
  isGCPRefetching: boolean;
  deleteNNI: (id?: string) => Promise<void>;
  deleteStatus: RequestStatus;
};

export const GCPTable = ({
  gcpNNIs,
  combinedGCPInfo,
  refetchGCPState,
  isGCPRefetching,
  deleteNNI,
  deleteStatus,
}: GCPTableProps) => {
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<GCPNNI>((row) => deleteNNI(row.nni_id), deleteStatus);

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"GCP NNI"}
        name={selectedToDelete?.nni_id}
      />
      <TableWrapper
        items={gcpNNIs}
        nniTab={
          <Table
            columns={gcpNNITableHeader()}
            header={TableHeader}
            data={gcpNNIs || []}
            gridColumnTemplate={`110px 160px 420px 180px 180px 120px 160px 160px 100px`}
            withoutPagination
            rowActions={{ onDelete: tryDelete }}
          />
        }
        regionTab={
          <Table
            columns={gcpRegionsTableHeader()}
            header={TableHeader}
            data={combinedGCPInfo.regions || []}
            gridColumnTemplate={`110px 140px 160px 140px 180px 180px 110px`}
            withoutPagination
          />
        }
        refetch={refetchGCPState}
        isRefetching={isGCPRefetching}
      />
    </>
  );
};
