import React, { FC, useEffect, useState } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import { useConnectionsContext } from "../../ConnectionsContext";
import ConnectionsPlaceholder from "./common/ConnectionsPlaceholder";
import LeftTabs from "./common/LeftTabs";
import CloudForm from "./components/CloudForm";
import ConnectionForm from "./components/ConnectionForm";
import InternetForm from "./components/InternetForm";

import styles from "./ConnectionsConfigurator.module.scss";
import RemoteUserForm from "./components/RemoteUsersForm";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../../../../helpers/consts";

const ConnectionsConfigurator: FC = () => {
  const { activeConnectionType } = useConnectionsContext();
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false);

  useEffect(() => {
    setShowPlaceholder(!Boolean(activeConnectionType));
  }, [activeConnectionType]);

  const formStyles = classNames(
    styles.formWrapper,
    showPlaceholder && styles.placeholder
  );

  return (
    <div className={styles.wrapper}>
      <LeftTabs />
      <div className={formStyles}>
        {showPlaceholder ? (
          <ConnectionsPlaceholder />
        ) : (
          <ConfiguratorBody type={activeConnectionType} />
        )}
      </div>
    </div>
  );
};

export default ConnectionsConfigurator;

const ConfiguratorBody: FC<{ type?: string }> = ({ type }) => {
  switch (type) {
    case "branch":
    case "data_center":
    case "bareMetal":
      return <ConnectionForm />;
    case AWS_LOWERCASE:
    case GCP_LOWERCASE:
    case AZURE_LOWERCASE:
      return <CloudForm />;
    case "internet":
      return <InternetForm />;
    case "secure_users":
      return <RemoteUserForm />;
    default:
      return <ConnectionsPlaceholder />;
  }
};
