import { PortType } from "../../pages/PortsPage/types";
import validateMaxBandwidth from "./MaxBandwidthValidator";
import Validator from "./Validator";

const checkVlanInRange = (
  ranges: PortType["free"] | undefined,
  entry: string | undefined
) => {
  if (!ranges || entry === undefined) return true;
  const value = Number(entry);

  if (value === 1 || value === 4096) return false;

  for (const { start, end } of ranges) {
    if (value >= start && value <= end) return true;
  }

  return false;
};

export default function validateL2Interface(
  data: any,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validateMaxBandwidth(data, validator);
  validator.checkEmpty(data.physical_port_name, "l2_type_field");
  validator.checkEmpty(data.vlan_id, "vlan_id");
  validator.checkTrue(
    checkVlanInRange(data.vlanOptions, data.vlan_id),
    "vlan_id",
    "Vlan ID is already used"
  );

  return validator;
}
