import CheckMarkIcon from "../../components/icons/CheckMarkIcon";
import ClockIcon from "../../components/icons/ClockIcon";
import ErrorIcon from "../../components/icons/ErrorIcon";
import ProgressIcon from "../../components/icons/progressIcon/ProgressIcon";
import { classNames } from "../../helpers/common/classNames";
import { TaskStatus } from "../../helpers/hooks/useCheckTask";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./DownloadStatus.module.scss";

type DownloadStatusProps = {
  status: TaskStatus;
};

const STATUS_LABELS = {
  [TaskStatus.Idle]: (
    <>
      <ClockIcon /> <span>Idle</span>
    </>
  ),
  [TaskStatus.InProcess]: (
    <>
      <ProgressIcon /> <span>In progress</span>
    </>
  ),
  [TaskStatus.Done]: (
    <>
      <CheckMarkIcon /> <span>Finished</span>
    </>
  ),
  [TaskStatus.Error]: (
    <>
      <ErrorIcon /> <span>Failed</span>
    </>
  ),
};

export const DownloadStatus = ({ status }: DownloadStatusProps) => {
  return (
    <div
      className={classNames(styles.status, styles[status.split(" ").join("_")])}
    >
      {STATUS_LABELS[status]}
    </div>
  );
};
