import React, { FC, useEffect, useMemo, useState } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { withContexts } from "../../helpers/hocs/withContexts";
import VTEPDialog from "./itemDialog/VTEPDialog";
import { vtepTableHeader } from "./table/vtepTableHeader";
import { VTEPType } from "./types";
import VTEPContextContainer, { useVTEPContext } from "./VTEPContext";

const VTEPPageContent: FC = () => {
  const {
    list = [],
    fetchList,
    listStatus,
    remove,
    removeStatus,
  } = useVTEPContext();

  const [showAdd, setShowAdd] = useState(false);
  const [viewItem, setViewItem] = useState<VTEPType | undefined>();
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<VTEPType>(remove, removeStatus, () => {
    setViewItem(undefined);
  });

  useEffect(() => {
    fetchList();
  }, []);

  const rowActions: ActionFuncs<VTEPType> = useMemo(
    () => ({
      onEdit: setViewItem,
      onDelete: (rowOriginal) => {
        tryDelete(rowOriginal);
      },
    }),
    [tryDelete, setViewItem]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"VTEP"}
        name={selectedToDelete?.name}
      />
      {showAdd && <VTEPDialog onClose={() => setShowAdd(false)} type={"add"} />}
      {viewItem && (
        <VTEPDialog
          onClose={() => setViewItem(undefined)}
          type="edit"
          data={viewItem}
          onDelete={viewItem && (() => tryDelete(viewItem))}
        />
      )}
      <TableWrapper
        titleProps={{
          title: `${list.length} VTEPs`,
          children: <AddButton onClick={() => setShowAdd(true)} />,
        }}
        dataStatus={listStatus}
        tablePlaceholder={["VTEPs", "VTEP"]}
        isEmpty={list && list.length === 0}
      >
        <Table
          columns={vtepTableHeader()}
          header={TableHeader}
          data={list}
          gridColumnTemplate={`repeat(6, 1fr) 0`}
          rowActions={rowActions}
          onClick={setViewItem}
        />
      </TableWrapper>
    </>
  );
};

export default withContexts(VTEPPageContent, [VTEPContextContainer]);
