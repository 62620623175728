import React, { FC } from "react";
import PhysicalPortsDropdown from "../../common/OneSystem/PhysicalPortsDropdown";

type Props = {
  system: string;
  isEdit: boolean;
  fields: { [key: string]: any };
  onChange: (fields: string, value: string | number) => void;
  error?: string;
};

const PortFields: FC<Props> = ({ isEdit, fields, onChange, system, error }) => {
  return (
    <PhysicalPortsDropdown
      id={"portNameBasic"}
      selected={fields.physical_port_name}
      onSelect={(val) => onChange("physical_port_name", val)}
      system={system}
      isNotEditable={isEdit}
      error={error}
    />
  );
};
export default PortFields;
