import { FC, useEffect } from "react";
import styles from "./BgpStatistics.module.scss";
import StatisticsItem from "./StatisticsItem";
import { useBGPServicesContext } from "../../../ServicesBGPContext";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import NoDataPlaceholder from "../../../../../components/common/loadStates/NoDataPlaceholder";
import { useVRFSelectorContext } from "../../../../../contexts/systemsContext/VRFSelectorContext";

type Props = {
  handledStatus: any;
  selectedNeighbor?: string;
  providedAs?: number;
  providedNodeName?: string;
};

const BgpStatistics: FC<Props> = ({
  handledStatus,
  selectedNeighbor,
  providedAs,
  providedNodeName,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { autonomous_system, fetchData } = useBGPServicesContext();

  const { selectedVRF = "" } = useVRFSelectorContext();

  useEffect(() => {
    if (selectedVRF) {
      fetchData(selectedVRF);
    }
  }, [selectedVRF]);

  useEffect(() => {
    if (selectedTenant) {
      fetchData(selectedTenant);
    }
  }, [selectedTenant]);

  return (
    <div className={styles.wrapper}>
      {!(selectedTenant || selectedVRF) ||
      handledStatus?.state === "pending" ? (
        <PageLoader />
      ) : !(autonomous_system || providedAs) ? (
        <NoDataPlaceholder
          title={"There is no data yet"}
          subTitle="Please configure BGP"
        />
      ) : (
        <StatisticsItem
          tenant={selectedTenant || selectedVRF}
          peer_ip={selectedNeighbor}
          asn={autonomous_system?.asn || providedAs || 0}
          providedNodeName={providedNodeName}
        />
      )}
    </div>
  );
};
export default BgpStatistics;
