import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import styles from "./DashboardHeader.module.scss";
import DashboardControls from "./DashboardControls";
import { useUserContext } from "../../../../contexts/UserContext";
import { useLocation } from "react-router-dom";
import {
  APPLICATIONS,
  APPLICATIONS_STAT,
  CYBER_THREATS,
  DNS,
  TOPOLOGY,
  TRAFFIC,
  OVERIVIEW,
  TRENDS,
  FLOWS,
  GEOLOCATION,
  DETAILED_RECORDS,
  DNS_THREATS,
  ADVANCED_THREATS,
  INSIGHTS_TRENDS,
  INSIGHTS_OVERVIEW,
  INSIGHTS,
  LATENCY,
} from "../../../../helpers/navigation/entries";
import { Tab } from "./DashboardsTabs";
import NetworkTopologyIcon from "../../../../components/icons/Union";
import ShieldFillIcon from "../../../../components/icons/ShieldFillIcon";
import GeoTagIcon from "../../../../components/icons/GeoTagIcon";
import { IS_DEMO } from "../../../../globalFlags";
import PageTitle from "../../../../components/common/PageTitle";
import ActiveTenantSelector from "../../../../components/common/navigation/tenantSelector/ActiveTenantSelector";
import TimerangOverlayComponent from "../../../../components/common/timerange/TimerangeOverlayComponent";
import NavigationTabs from "../../../../components/common/navigation/NavigationTabs";
import AdvancedFilters from "../../../../components/common/navigation/advancedFilters/AdvancedFilters";
import { formPathFromArray } from "../../../../helpers/navigation";

const TABS: Array<Tab> = [
  { ...INSIGHTS_OVERVIEW(), icon: <NetworkTopologyIcon /> },
  { ...INSIGHTS_TRENDS(), icon: <NetworkTopologyIcon /> },
  { ...TOPOLOGY(), icon: <NetworkTopologyIcon /> },
  { ...APPLICATIONS(), icon: <ShieldFillIcon /> },
  {
    ...TRAFFIC(),
    icon: <GeoTagIcon />,
    disabled: true,
  },
  {
    ...CYBER_THREATS(),
    icon: <ShieldFillIcon />,
  },
];

const isNeedAdvanced = () => {
  const location = useLocation();
  const path = location.pathname;

  return !path.includes(formPathFromArray([INSIGHTS(), TOPOLOGY()]));
};

const DashboardHeader: FC<{
  filter?: ReactElement;
  typeSelector?: ReactElement;
}> = ({ filter, typeSelector }) => {
  const { user } = useUserContext();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<Tab | undefined>();

  useEffect(() => {
    const currTab = () =>
      TABS.find((el) => location.pathname.includes(el.path));
    setActiveTab(currTab);
  }, [location.pathname]);

  const pageTitle = useMemo(() => {
    return getPageTitle(activeTab?.content.toString());
  }, [IS_DEMO, user, activeTab]);

  return (
    <>
      <div className={styles.header}>
        <PageTitle title={pageTitle} />
        <DashboardControls>
          {typeSelector}
          <ActiveTenantSelector />
          <TimerangOverlayComponent
            id={"dashboardOverlay"}
            className={styles.timeRange}
          />
        </DashboardControls>
      </div>
      <div>{isNeedAdvanced() && <AdvancedFilters />}</div>
    </>
  );
};
export default DashboardHeader;

const getPageTitle = (type?: string): ReactElement => {
  switch (type) {
    case INSIGHTS_OVERVIEW().content:
      return (
        <NavigationTabs
          entries={[INSIGHTS_OVERVIEW(), INSIGHTS_TRENDS()]}
          disabledTabKeys={[]}
        />
      );
    case INSIGHTS_TRENDS().content:
      return (
        <NavigationTabs
          entries={[INSIGHTS_OVERVIEW(), INSIGHTS_TRENDS()]}
          disabledTabKeys={[]}
        />
      );
    case TOPOLOGY().content:
      return <>{TOPOLOGY().content}</>;
    case APPLICATIONS().content:
      return (
        <NavigationTabs
          entries={[APPLICATIONS_STAT(), DNS()]}
          disabledTabKeys={[]}
        />
      );
    case TRAFFIC().content:
      return (
        <NavigationTabs
          entries={[
            OVERIVIEW(),
            TRENDS(),
            FLOWS(),
            LATENCY(),
            GEOLOCATION(),
            DETAILED_RECORDS(),
          ]}
          disabledTabKeys={[]}
        />
      );
    case CYBER_THREATS().content:
      return (
        <NavigationTabs
          entries={[DNS_THREATS(), ADVANCED_THREATS()]}
          disabledTabKeys={[]}
        />
      );
    default:
      return <>Dashboard</>;
  }
};
