import React, { FC } from "react";
import Input from "../../../../../components/common/formComponents/Input";
import GateVIDropdown from "../../../../../components/common/GateVIDropdown/GateVIDropdown";
import { DESTINATION_NAT } from "../../../const";

import styles from "./OutsideBlock.module.scss";

type Props = {
  fields: { [key: string]: any };
  onChange: (field: string, value: any) => void;
  isEdit: boolean;
};

const OutsideBlock: FC<Props> = ({ fields, onChange, isEdit }) => {
  const isDNAT = fields?.rule_type === DESTINATION_NAT;
  return (
    <div className={styles.colGroup}>
      <GateVIDropdown
        onChange={(val) => onChange("outside", val)}
        selected={fields.outside}
        label={"Outside"}
        onChangeNewPublicIp={(val) => onChange("newPublicIp", val)}
        type={fields.rule_type}
        className={styles.list}
      />
      {isDNAT && (
        <Input
          label=""
          name="outside_port"
          placeholder="Port"
          isPlaceholderAlwaysVisible
          value={fields.outside_port}
          onChange={(e) => onChange("outside_port", +e.target.value)}
          medium
          error={""}
          type={"number"}
          isNotEditable={isEdit}
        />
      )}
    </div>
  );
};
export default OutsideBlock;
