import React, { FC } from "react";
import Section from "../../../components/common/Section";
import { CreatePBM, PBM_UNIT, getUnitTitle, getUnitByTitle } from "./PBMConfig";
import styles from "./ActionSection.module.scss";
import Input from "../../../components/common/formComponents/Input";

type Props = {
  fields: CreatePBM;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  errors?: any;
  className: string;
  gridTemplate: string;
};

export const ContinueAction = {
  key: "None",
  data: "ROUTE",
};

const ActionSection: FC<Props> = ({
  fields,
  onChange,
  className,
  errors,
  gridTemplate,
}) => {
  const units = [
    PBM_UNIT.KBITS_PER_SECOND,
    PBM_UNIT.MBITS_PER_SECOND,
    PBM_UNIT.GBITS_PER_SECOND,
    PBM_UNIT.PACKETS_PER_SECOND,
  ].map(getUnitTitle);

  const handleChange = (val: string) => {
    onChange("unit", getUnitByTitle(val));
  };

  return (
    <Section
      title={"Meter"}
      className={className}
      columnsCount={3}
      gridTemplateString={gridTemplate}
    >
      <div className={styles.column}>
        <div className={styles.twoBlocksWrapper}>
          <Input
            type="number"
            name={"count"}
            handleFieldChange={onChange}
            value={fields.count}
            error={errors && errors["count"]}
            medium
            withDeleteButton
          />
          <Input
            name={"unit"}
            handleFieldChange={(name: string, value: string) =>
              handleChange(value)
            }
            value={getUnitTitle(fields.unit)}
            placeholder={fields.unit}
            error={errors && errors["unit"]}
            medium
            valuesList={units}
          />
        </div>
      </div>
    </Section>
  );
};
export default ActionSection;
