import {
  AWSAccessKey,
  AWSVPNBody,
  AzureAccessKey,
  AzureVPNBody,
  RequestedGateVIAdd,
  VirtualInetrfaceAllTypes,
  VirtualInetrfaceBridgeDomain,
  VirtualInetrfaceBridgeDomainAdd,
  VirtualInetrfaceBridgeDomainApi,
  VirtualInetrfaceBridgeDomainEdit,
  VirtualInetrfaceBridgeDomainEditIps,
  VirtualInetrfaceBridgeDomainEditSegments,
  VirtualInetrfaceGate,
  VirtualInetrfaceGateAdd,
  VirtualInetrfaceGateEdit,
  VirtualInetrfaceIpsec,
  VirtualInetrfaceIpsecAdd,
  VirtualInetrfaceIpsecDefaultAdd,
  VirtualInetrfaceIpsecEdit,
  VPC,
} from "./types";
import { flatMembersList } from "../../flatMembersList";
import { SortType, PaginateListResponse } from "../apiTypes";
import Api from "../Api";
import getManualSortedData from "../../getManualSortedData";
import {
  AWS_ACCESS_KEY,
  AZURE_ACCESS_KEY,
  REQUESTED_GATE_VI,
  SERVICE_AWS,
  SERVICE_AZURE,
  SERVICE_CONNECTIONS,
  TENANT_VI_ALL_TYPES,
  TENANT_VI_BRIDGE_DOMAIN,
  TENANT_VI_GATE,
  TENANT_VI_IPSEC,
} from "../apiPaths";

type Res<P> = { ok: boolean; error?: string; result?: P };

export class TenantVirtualInterfacesApi extends Api {
  constructor() {
    super();
  }

  // ALL TYPES
  async getVirtualInterfacesAllTypes(
    tenant_name: string,
    sortBy?: SortType
  ): Promise<Res<Array<VirtualInetrfaceAllTypes>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<VirtualInetrfaceAllTypes>
    >(TENANT_VI_ALL_TYPES.LIST(tenant_name));
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  // BRIDGE DOMAIN
  async getVirtualInterfacesBridgeDomain(
    tenant_name: string,
    sortBy?: SortType
  ): Promise<Res<Array<VirtualInetrfaceBridgeDomain>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<VirtualInetrfaceBridgeDomainApi>
    >(TENANT_VI_BRIDGE_DOMAIN.LIST(tenant_name));
    const data = flatMembersList(res.result?.items || []);
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async addVirtualInterfaceBridgeDomain(
    tenant_name: string,
    params: VirtualInetrfaceBridgeDomainAdd
  ): Promise<Res<any>> {
    return await this.fetchBroker(TENANT_VI_BRIDGE_DOMAIN.POST(tenant_name), {
      body: JSON.stringify(params),
    });
  }

  async createViPlusL2(
    tenant_name: string,
    params: VirtualInetrfaceBridgeDomainAdd
  ): Promise<Res<any>> {
    return await this.fetchBroker(SERVICE_CONNECTIONS.POST(tenant_name), {
      body: JSON.stringify(params),
    });
  }

  async editVirtualInterfaceBridgeDomain(
    tenant_name: string,
    vi_name: string,
    params: VirtualInetrfaceBridgeDomainEdit
  ): Promise<Res<void>> {
    return await this.fetchBroker(
      TENANT_VI_BRIDGE_DOMAIN.EDIT(tenant_name, vi_name),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async editVirtualInterfaceBridgeDomainIps(
    tenant_name: string,
    vi_name: string,
    params: VirtualInetrfaceBridgeDomainEditIps
  ): Promise<Res<void>> {
    return await this.fetchBroker(
      TENANT_VI_BRIDGE_DOMAIN.EDIT_IPS(tenant_name, vi_name),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async editVirtualInterfaceBridgeDomainSegments(
    tenant_name: string,
    vi_name: string,
    params: VirtualInetrfaceBridgeDomainEditSegments
  ): Promise<Res<void>> {
    return await this.fetchBroker(
      TENANT_VI_BRIDGE_DOMAIN.EDIT_SEGMENTS(tenant_name, vi_name),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async deleteVirtualInterfaceBridgeDomain(
    tenant_name: string,
    vi_name: string
  ): Promise<Res<void>> {
    return await this.fetchBroker(
      TENANT_VI_BRIDGE_DOMAIN.DELETE(tenant_name, vi_name)
    );
  }

  // GATE
  async getVirtualInterfacesGate(
    tenant_name: string
  ): Promise<Res<PaginateListResponse<VirtualInetrfaceGate>>> {
    return await this.fetchBroker<PaginateListResponse<VirtualInetrfaceGate>>(
      TENANT_VI_GATE.LIST(tenant_name)
    );
  }

  async addVirtualInterfaceGate(
    tenant_name: string,
    params: VirtualInetrfaceGateAdd
  ): Promise<Res<any>> {
    return await this.fetchBroker(TENANT_VI_GATE.POST(tenant_name), {
      body: JSON.stringify(params),
    });
  }

  async editVirtualInterfaceGate(
    tenant_name: string,
    vi_name: string,
    params: VirtualInetrfaceGateEdit
  ): Promise<Res<void>> {
    return await this.fetchBroker(TENANT_VI_GATE.EDIT(tenant_name, vi_name), {
      body: JSON.stringify(params),
    });
  }

  async deleteVirtualInterfaceGate(
    tenant_name: string,
    vi_name: string
  ): Promise<Res<void>> {
    return await this.fetchBroker(TENANT_VI_GATE.DELETE(tenant_name, vi_name));
  }

  // REQUEST GATE VI
  async requestVIGate(
    tenant_name: string,
    params: RequestedGateVIAdd
  ): Promise<Res<any>> {
    return await this.fetchBroker(REQUESTED_GATE_VI.POST(tenant_name), {
      body: JSON.stringify(params),
    });
  }

  async deleteRequestedVIGate(
    tenant_name: string,
    vi_name: string
  ): Promise<Res<void>> {
    return await this.fetchBroker(
      REQUESTED_GATE_VI.DELETE(tenant_name, vi_name)
    );
  }

  // IPSEC
  async getVirtualInterfacesIpsec(
    tenant_name: string
  ): Promise<Res<PaginateListResponse<VirtualInetrfaceIpsec>>> {
    return await this.fetchBroker<PaginateListResponse<VirtualInetrfaceIpsec>>(
      TENANT_VI_IPSEC.LIST(tenant_name)
    );
  }

  async addVirtualInterfaceIpsec(
    tenant_name: string,
    params: Partial<VirtualInetrfaceIpsecAdd>
  ): Promise<Res<any>> {
    return await this.fetchBroker(TENANT_VI_IPSEC.POST(tenant_name), {
      body: JSON.stringify(params),
    });
  }

  async addVirtualInterfaceIpsecDefault(
    tenant_name: string,
    params: VirtualInetrfaceIpsecDefaultAdd
  ): Promise<Res<any>> {
    return await this.fetchBroker(TENANT_VI_IPSEC.POST_DEFAULT(tenant_name), {
      body: JSON.stringify(params),
    });
  }

  async editVirtualInterfaceIpsec(
    tenant_name: string,
    vi_name: string,
    params: VirtualInetrfaceIpsecEdit
  ): Promise<Res<void>> {
    return await this.fetchBroker(TENANT_VI_IPSEC.EDIT(tenant_name, vi_name), {
      body: JSON.stringify(params),
    });
  }

  async deleteVirtualInterfaceIpsec(
    tenant_name: string,
    vi_name: string
  ): Promise<Res<void>> {
    return await this.fetchBroker(TENANT_VI_IPSEC.DELETE(tenant_name, vi_name));
  }

  // AWS
  async getAWSAccessKey(tenant: string): Promise<Res<AWSAccessKey>> {
    const res = await this.fetchBroker<AWSAccessKey>(
      AWS_ACCESS_KEY.GET(tenant)
    );
    return res;
  }

  async createAWSAccessKey(
    params: AWSAccessKey,
    tenant: string
  ): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(AWS_ACCESS_KEY.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async deleteAWSAccessKey(tenant: string): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(
      AWS_ACCESS_KEY.DELETE(tenant, "")
    );
    return res;
  }

  async getAWSVPCListApi(
    tenant: string,
    region: string
  ): Promise<Res<Array<VPC>>> {
    const res = await this.fetchBroker<Array<VPC>>(
      SERVICE_AWS.GET(tenant, region)
    );
    return res;
  }

  async createAWSVPN(params: AWSVPNBody, tenant: string): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(SERVICE_AWS.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  // AZURE
  async getAzureAccessKey(tenant: string): Promise<Res<AzureAccessKey>> {
    const res = await this.fetchBroker<AzureAccessKey>(
      AZURE_ACCESS_KEY.GET(tenant)
    );
    return res;
  }

  async createAzureAccessKey(
    params: AzureAccessKey,
    tenant: string
  ): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(AZURE_ACCESS_KEY.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async createAzureVPN(
    params: AzureVPNBody,
    tenant: string
  ): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(SERVICE_AZURE.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }
}

export const tenantVirtualInterfacesApi = new TenantVirtualInterfacesApi();
