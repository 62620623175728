import React from "react";
import { SiteRegion } from "../../../../../../helpers/api/apiTypes";
import styles from "./buildingsTableColumns.module.scss";
import { CellProps } from "react-table";
import Label from "../../../../../../components/common/table/Label";
import { classNames } from "../../../../../../helpers/common/classNames";

export const buildingsTableColumns = (isDC?: boolean): Array<any> => {
  const headers: Array<any> = [
    {
      accessor: "name",
      Header: "Building Name",
      Cell: ({ cell: { value } }: CellProps<SiteRegion>) => (
        <Label key={value} className={classNames(styles.name, styles.label)}>
          {value}
        </Label>
      ),
    },
    {
      accessor: isDC ? "operator" : "connection_type",
      Header: isDC ? "Operator" : "Type",
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: ({ row: { original }, cell: { value } }: CellProps<SiteRegion>) => (
        <Label
          key={original.name}
          tooltipId={original.name}
          className={styles.label}
        >
          {value}
        </Label>
      ),
    },
  ];

  return headers.map((el) => ({
    ...el,
    accessor: el.accessor as keyof SiteRegion,
  }));
};
