import { CellProps } from "react-table";
import { VRFType } from "../../../../../helpers/api/apiTypes";
import VRFIcon from "../../../../icons/VRFIcon";
import { CellFactory } from "../../newTable/cells/CellsFactory";
import { EditableCellFactory } from "../../newTable/cells/EditableCellsFactory";
import { Checkbox } from "../../../formComponents/Checkbox";

const cellFactory = new CellFactory<VRFType>({
  icon: VRFIcon,
});

const editCellFactory = new EditableCellFactory<VRFType>({
  icon: VRFIcon,
});

export const vrfTableHeader = (): Array<any> => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: cellFactory.idCell(),
      EditCell: cellFactory.idCell(),
    },
    {
      Header: "Description",
      accessor: "description",
      EditCell: editCellFactory.input(),
    },
    {
      Header: "Network Observability",
      accessor: "is_sdr",
      Cell: ({ row }: CellProps<VRFType>) => {
        if (row.values.is_sdr === undefined) {
          return <></>;
        } else {
          return (
            <div>
              <Checkbox
                isChecked={row.values.is_sdr}
                onChange={() => {
                  return;
                }}
                className="is_sdr"
              />
            </div>
          );
        }
      },
    },
    {
      Header: "DNS Observability",
      accessor: "is_dns",
      Cell: ({ row }: CellProps<VRFType>) => {
        if (row.values.is_dns === undefined) {
          return <></>;
        } else {
          return (
            <div>
              <Checkbox
                isChecked={row.values.is_dns}
                onChange={() => {
                  return;
                }}
                className="is_dns"
              />
            </div>
          );
        }
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof VRFType }));
};
