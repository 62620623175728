import React, { FC } from "react";
import { InternetIpsec } from "../../../WizardToolPage/types";
import { CONFIG_FIELDS_PHASE1, CONFIG_FIELDS_PHASE2 } from "../../const";
import { useIPsecContext } from "../../IPsecContext";
import { ConfigField } from "../../types";
import ConfigValue from "./ConfigValue";

import styles from "./RemoteConfig.module.scss";

export const RemoteConfig = () => {
  return (
    <div className={styles.config}>
      <div className={styles.header}>Config</div>
      <div className={styles.frame} id="phases">
        <Title>Phase 1</Title>
        <Fields list={CONFIG_FIELDS_PHASE1} />
        <Title>Phase 2</Title>
        <Fields list={CONFIG_FIELDS_PHASE2} />
      </div>
    </div>
  );
};

const Title: FC = ({ children }) => {
  return <div className={styles.title}>{children}</div>;
};

const Fields = ({ list }: { list: Array<ConfigField> }) => {
  const { ipsecInterface } = useIPsecContext();
  const values = ipsecInterface || {};

  const parseValue = (
    value?: string | number | Array<number>,
    key?: keyof InternetIpsec
  ) => {
    if (!key) return value;

    switch (key) {
      case "passive":
        return values.passive ? "passive" : "enable";
      case "ike_version":
        return `IKEv${values.ike_version || 2}`;
      case "nat_traversal":
        return values.nat_traversal ? "enabled" : "disabled";
      case "remote_ip":
        return values.remote_ip && values.remote_ip.split("/")[0];
      case "udp_encap":
        return values.udp_encap && values.nat_traversal ? "UDP" : "ESP";
      case "dh_groups":
        return values.dh_groups;
      default:
        return values[key];
    }
  };

  return (
    <div className={styles.fields}>
      {list.map((field) => {
        const name = field.name;
        const value = parseValue(field.value, field.key);

        return <ConfigValue name={name} value={value} key={field.value} />;
      })}
    </div>
  );
};
