import React from "react";
import DialogLayout from "../../components/common/DialogLayout/DialogLayout";

// to wrap dialogs into basic dialog layout

export function withDialogLayout<T extends object>(
  WrappedComponent: React.ComponentType<T>,
  className?: string
) {
  return function Component(props: T) {
    return (
      <DialogLayout className={className}>
        <WrappedComponent {...props} />
      </DialogLayout>
    );
  };
}
