import React, { FC } from "react";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getTrafficLink } from "../../common/trafficHelper";
import TrafficLayout from "../../common/TrafficLayout";
import { useTrafficContext } from "../../TrafficContext";
import styles from "./Geolocation.module.scss";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";
import { getPanelIdByTimerange } from "../../../../helpers/getPanelIdByTimerange";

const Geolocation: FC<{ tabs: any }> = ({ tabs }) => {
  const { filters, isServerTraffic } = useTrafficContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  return (
    <TrafficLayout tabs={tabs}>
      <ServicesRedirectPlaceholder type="sdr">
        <GrafanaContainer type={GRAFANA_TYPES.SDR} className={styles.wrapper}>
          <div className={styles.chartsWrapper}>
            <div className={styles.map}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 45 : 45,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.sankey}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 51 : 50,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.countryPie}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 20 : 19,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.countryBar}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 42 : 39,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.cityPie}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic
                    ? getPanelIdByTimerange(
                        timeRange.general,
                        "trafficGeolocation75"
                      )
                    : 74,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.cityBar}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 57 : 72,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
          </div>
        </GrafanaContainer>
      </ServicesRedirectPlaceholder>
    </TrafficLayout>
  );
};

export default Geolocation;
