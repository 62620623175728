import {
  CLOUD_CONNECTIONS,
  CUSTOMER_PORTS,
  DIA_UNITED_CONNECTIONS,
} from "../../pages/ZayoPages/Connections/ConnectionConfigurator/consts";
import { FieldsType } from "../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import { AWS_LOWERCASE, AZURE_LOWERCASE } from "../consts";
import Validator from "./Validator";

export default function validateConnection(
  data: {
    name: string;
    connections: Array<FieldsType>;
    isCloudRouter: boolean;
    skip?: Array<string>;
  },
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkNameFormat(data.name, "name");
  data.connections.forEach((connection, i) => {
    const connectionFields = findField(connection, data.isCloudRouter);
    Object.keys(connectionFields).forEach((field) => {
      if (!data.skip?.length || !data.skip.includes(field)) {
        if (connection.innerType === CLOUD_CONNECTIONS) {
          if (connection.cloudType === AWS_LOWERCASE) {
            findAWSValidator(validator, connectionFields, field, i);
          }
          if (connection.cloudType === AZURE_LOWERCASE) {
            findAzureValidator(validator, connectionFields, field, i);
          }
        } else if (connection.innerType === CUSTOMER_PORTS) {
          findCustomerPortValidator(validator, connectionFields, field, i);
        } else if (connection.innerType === DIA_UNITED_CONNECTIONS) {
          findDIAValidator(validator, connectionFields, field, i);
        }
      }
    });
  });
  return validator;
}

const findAWSValidator = (
  validator: Validator,
  data: FieldsType,
  field: string,
  i: number
) => {
  switch (field) {
    case "location":
      return validator.checkEmpty(data.location, i + "location");
    case "max_bandwidth":
      return validator.checkEmpty(data.max_bandwidth, i + "max_bandwidth");
    case "ip_addresses":
      if (!data.ip_addresses) {
        return validator.checkEmpty(data.ip_addresses, i + "ip_addresses");
      }
      return validator.checkIPWithPrefix(data.ip_addresses, i + "ip_addresses");
    case "account_id":
      return validator.checkAccountId(data.account_id, i + "account_id");
    case "Subnet":
      if (!data.Subnet) {
        return validator.checkEmpty(data.Subnet, i + "Subnet");
      }
      return validator.checkIPWithPrefix(data.Subnet, i + "Subnet");
    default:
      return;
  }
};

const findAzureValidator = (
  validator: Validator,
  data: FieldsType,
  field: string,
  i: number
) => {
  switch (field) {
    case "vlan_id":
      return validator.checkMinMax(
        Number(data.vlan_id),
        [2, 4095],
        i + "vlan_id"
      );
    case "BW":
    case "max_bandwidth":
      return validator.checkEmpty(data.BW || data.max_bandwidth, i + "BW");
    case "service_key":
      return validator.checkEmpty(data.service_key, i + "service_key");
    case "ip_addresses":
      if (!data.ip_addresses) {
        return validator.checkEmpty(data.ip_addresses, i + "ip_addresses");
      }
      return validator.checkIPWithPrefix(data.ip_addresses, i + "ip_addresses");
    case "Subnet":
      if (!data.Subnet) {
        return validator.checkEmpty(data.Subnet, i + "Subnet");
      }
      return validator.checkIPWithPrefix(data.Subnet, i + "Subnet");
    default:
      return;
  }
};

const findCustomerPortValidator = (
  validator: Validator,
  data: FieldsType,
  field: string,
  i: number
) => {
  switch (field) {
    case "name":
      return validator.checkEmpty(data.name, i + "name");
    case "BW":
      return validator.checkEmpty(data.max_bandwidth, i + "BW");
    case "VLAN_ID":
      return data.Tagged && validator.checkEmpty(data.VLAN_ID, i + "VLAN_ID");
    case "ip_addresses":
      if (!data.ip_addresses) {
        return validator.checkEmpty(data.ip_addresses, i + "ip_addresses");
      }
      return validator.checkIPWithPrefix(data.ip_addresses, i + "ip_addresses");
    default:
      return;
  }
};

const findDIAValidator = (
  validator: Validator,
  data: FieldsType,
  field: string,
  i: number
) => {
  switch (field) {
    case "name":
      return validator.checkEmpty(data.name, i + "name");
    case "BW":
      return validator.checkEmpty(data.BW || data.max_bandwidth, i + "BW");
    case "Subnet":
      if (!data.Subnet) {
        return validator.checkEmpty(data.Subnet, i + "Subnet");
      }
      return validator.checkIPWithPrefix(data.Subnet, i + "Subnet");
    default:
      return;
  }
};

const findField = (fields: FieldsType, isCloudRouter: boolean): FieldsType => {
  if (!isCloudRouter || fields.hasOwnProperty("ip_addresses")) return fields;
  const result = { ...fields };
  return result;
};
