import { FC, useEffect, useMemo } from "react";
import TableWrapper from "../../../components/common/table/newTable/layout/TableWrapper";
import { ActionFuncs } from "../../../components/common/table/newTable/RowControls";
import TableHeader from "../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../components/common/table/newTable/Table";
import { OK_STATUS } from "../../../components/const/api";
import ConfirmDeleteDialog from "../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import useDeleteDialog from "../../../helpers/hooks/useDeleteDialog";
import { Layer3InterfaceNew } from "../../Layer3Interface/types";
import { useCloudsConnectivityContext } from "../CloudsConnectivityContext";
import { cloudsTableHeader } from "../common/cloudsTableHeader";

import styles from "./CloudDetails.module.scss";
import CloudDiscovery from "./CloudDiscovery";
import { useUserContext } from "../../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import {
  CONNECTIONS,
  CONNECTIVITY,
  NETWORK,
  REPORTS,
  ZAYO_CONNECTIONS,
} from "../../../helpers/navigation/entries";
import CloudCredentials from "./CloudCredentials";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { AWS_LOWERCASE } from "../../../helpers/consts";

type Props = { vpc: Array<Layer3InterfaceNew>; type: string };

const CloudDetails: FC<Props> = ({ vpc, type }) => {
  const { isZayo, isTenant } = useUserContext();
  const history = useHistory();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    requestToUpdate,
    correctTokens,
    getAccessKeys,
    useTenant,
  } = useCloudsConnectivityContext();

  const gotoZayoConnections = () => {
    const path = isTenant
      ? "/" + ZAYO_CONNECTIONS().path
      : "/" + NETWORK().path + "/" + ZAYO_CONNECTIONS().path;
    history.replace(path);
  };

  useEffect(() => {
    if (selectedTenant) {
      getAccessKeys(selectedTenant, type);
      useTenant(selectedTenant);
    }
  }, [selectedTenant]);

  const { remove, removeStatus } = useCloudsConnectivityContext();
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<Layer3InterfaceNew>(remove, removeStatus);

  const rowActions: ActionFuncs<Layer3InterfaceNew> = useMemo(
    () => ({
      onPieChart: () =>
        history.replace(
          "/" +
            NETWORK().path +
            "/" +
            CONNECTIVITY().path +
            "/" +
            CONNECTIONS().path +
            "/" +
            REPORTS().path
        ),
      onEdit: () => {
        isZayo ? gotoZayoConnections() : undefined;
      },
      onDelete: (rowOriginal) => {
        isZayo ? gotoZayoConnections() : tryDelete(rowOriginal, selectedTenant);
      },
    }),
    [tryDelete]
  );
  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Cloud Connection"}
        name={selectedToDelete?.name}
      />
      <div className={styles.wrapper}>
        {vpc && vpc?.length > 0 && (
          <TableWrapper
            dataStatus={OK_STATUS}
            tablePlaceholder={["Clouds", "Cloud"]}
            isEmpty={false}
          >
            <Table
              columns={cloudsTableHeader(rowActions, type)}
              header={TableHeader}
              data={vpc}
              gridColumnTemplate={
                type === AWS_LOWERCASE
                  ? `30px 1fr 1fr 1fr 1.5fr 1fr 1fr 1fr`
                  : `30px 1fr 1fr 1.5fr 1fr 1fr 1fr`
              }
            />
          </TableWrapper>
        )}
        {(!correctTokens?.[type] || requestToUpdate === type) && (
          <CloudCredentials type={type} />
        )}
        {correctTokens?.[type] && <CloudDiscovery type={type} />}
      </div>
    </>
  );
};
export default CloudDetails;
