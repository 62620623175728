import React, { FC, useEffect } from "react";
import LayoutComponent from "../../common/LayoutComponent";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import styles from "./TenantUsagePage.module.scss";
import { useQuotaContext } from "../../../contexts/tenantsContext/QuotaContext";
import { PackagesTabs } from "../../common/navigation/PackagesTabs";
import PackagesSections from "./components/PackagesSections";
import { SECTIONS_MAP } from "./components/constAlias";
import EmtyPackages from "./components/EmtyPackages";
import getIconFromValue from "../../../helpers/getIconFromValue";
import { classNames } from "../../../helpers/common/classNames";

export type PackageListType = {
  name: string;
  max_bw: string;
  [key: string]: any;
};

const TenantUsagePage: FC = () => {
  const {
    listStatus,
    selectedTenantList,
    selectedHardcodedTenantList,
    fetchSelectedTenant,
    fetchHardcodedData,
  } = useQuotaContext();
  const { selected } = useTenantContext();

  useEffect(() => {
    fetchSelectedTenant();
    fetchHardcodedData();
  }, []);

  if (!selectedTenantList || !selectedHardcodedTenantList) {
    return <EmtyPackages status={listStatus} tabs={<PackagesTabs />} />;
  }

  return (
    <>
      <LayoutComponent>
        <PackagesTabs />
        <div className={styles.container}>
          <div className={styles.tableWrapper}>
            <div className={styles.headerRow}>
              <div className={styles.headerBasic}>
                <div className={styles.title}>{selected?.name}</div>
                <div className={styles.subTitle}>Package usage</div>
              </div>
              {selectedTenantList?.map((el) => {
                const isCurrent = el?.name === "Current";
                return (
                  <div
                    key={el.name}
                    className={classNames(
                      styles.columnTitle,
                      isCurrent && styles.current
                    )}
                  >
                    {!isCurrent && getIconFromValue(el.name.toLowerCase())}
                    {el.name}
                  </div>
                );
              })}
            </div>
            {SECTIONS_MAP.map((item, i) => (
              <PackagesSections
                key={item.key}
                activePackage={""}
                section={item}
                list={selectedTenantList}
                hardcodedList={selectedHardcodedTenantList}
                isLastRow={i === SECTIONS_MAP.length - 1}
              />
            ))}
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};
export default TenantUsagePage;
