export const AVAILABLE_FIELDS_DNS = [
  { label: "Locations", value: "locations" },
  { label: "Source IP", value: "srcIPs" },
  { label: "DNS Servers", value: "dstIP" },
  { label: "Hostnames", value: "DNS.rrname" },
  { label: "DNS Query Type", value: "DNS.rrtype" },
  { label: "Is DIA", value: "is_dia" },
];

export enum DNS_FILTER_FIELD {
  LOCATION = "locations",
  SOURCE_IP = "srcIPs",
  DNS_SERVERS = "dstIP",
  HOSTNAMES = "DNS.rrname",
  DNS_QUERY_TYPE = "DNS.rrtype",
  IS_DIA = "is_dia",
}

export const DNS_FIELDS_MAP: Array<string> = [
  DNS_FILTER_FIELD.LOCATION,
  DNS_FILTER_FIELD.SOURCE_IP,
  DNS_FILTER_FIELD.DNS_SERVERS,
  DNS_FILTER_FIELD.HOSTNAMES,
  DNS_FILTER_FIELD.DNS_QUERY_TYPE,
  DNS_FILTER_FIELD.IS_DIA,
];

export const DNS_FIELD_VALUE: { [key: string]: string } = {
  [DNS_FILTER_FIELD.LOCATION]: "Location",
  [DNS_FILTER_FIELD.SOURCE_IP]: "Source IP",
  [DNS_FILTER_FIELD.DNS_SERVERS]: "Destination IP",
  [DNS_FILTER_FIELD.HOSTNAMES]: "DNS.rrname",
  [DNS_FILTER_FIELD.DNS_QUERY_TYPE]: "DNS.rrtype",
  [DNS_FILTER_FIELD.IS_DIA]: "is_dia",
};

export const DNS_FIELD_LABEL: { [key: string]: string } = {
  [DNS_FILTER_FIELD.LOCATION]: "Location",
  [DNS_FILTER_FIELD.SOURCE_IP]: "Source IP",
  [DNS_FILTER_FIELD.DNS_SERVERS]: "Destination IP",
  [DNS_FILTER_FIELD.HOSTNAMES]: "DNS.rrname",
  [DNS_FILTER_FIELD.DNS_QUERY_TYPE]: "DNS.rrtype",
  [DNS_FILTER_FIELD.IS_DIA]: "is_dia",
};
