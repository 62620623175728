import React, { Component, useContext } from "react";
import {
  ASType,
  BGPUnderlay,
  StatusNeighborsType,
} from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import extractFieldsFromBGPStatus from "../../helpers/extractFieldsFromBGPStatus";

type RequestStatuses = {
  underlayStatus?: RequestStatus;
  updateUnderlayStatus?: RequestStatus;
  statusStatus?: RequestStatus;
};

type BGPState = {
  underlay: BGPUnderlay;
  status: { [key: string]: any };
  bgpAS: Partial<ASType>;
  bgpNeighbors: Array<StatusNeighborsType>;
} & RequestStatuses;

type BGPFunc = {
  fetchUnderlay: () => Promise<boolean>;
  updateUnderlay: (param: Partial<BGPUnderlay>) => Promise<boolean>;
  fetchStatus: (
    vrf: string,
    nodeName: string,
    isFirstLoad?: boolean
  ) => Promise<void>;
  resetStatus: () => void;
};

type IBGPContext = BGPState & BGPFunc;

const UserContext = React.createContext<IBGPContext | any>(null);
export const useBGPContext = (): IBGPContext =>
  useContext<IBGPContext>(UserContext);

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  additionParameter: string;
};

class BGPContextContainer extends Component<Props> {
  funcs: BGPFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {};

    this.funcs = {
      fetchStatus: this.fetchStatus,
      fetchUnderlay: this.fetchUnderlay,
      updateUnderlay: this.updateUnderlay,
      resetStatus: this.resetStatus,
    };
  }

  fetchStatus = async (
    vrf: string,
    nodeName: string,
    isFirstLoad?: boolean
  ): Promise<void> => {
    if (isFirstLoad) {
      this.setState({ statusStatus: setPending("Fetching") });
    }

    const res = await configApi.getBGPStatus(
      this.props.additionParameter,
      nodeName
    );

    if (res.ok && res.result) {
      const bgpStatus = res.result[vrf];
      const { bgpNeighbors, bgpAS } = extractFieldsFromBGPStatus(bgpStatus);

      this.setState({
        bgpAS,
        bgpNeighbors,
      });
    }
    this.setState({
      statusStatus: resToState(res),
    });
  };

  fetchUnderlay = async (): Promise<boolean> => {
    this.setState({ underlayStatus: setPending("Fetching") });
    const res = await configApi.getBGPUnderlay(this.props.additionParameter);
    this.setState({
      underlay: res.result,
      underlayStatus: resToState(res),
    });

    return res.ok;
  };

  updateUnderlay = async (param: Partial<BGPUnderlay>): Promise<boolean> => {
    this.setState({ updateUnderlayStatus: setPending("Fetching") });
    const res = await configApi.updateBGPUnderlay(
      this.props.additionParameter,
      param
    );
    this.setState({
      bgpUnderlay: res.result,
      updateUnderlayStatus: resToState(res),
    });
    return res.ok;
  };

  resetStatus = async (): Promise<void> => {
    this.setState({ updateUnderlayStatus: undefined });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default BGPContextContainer;
