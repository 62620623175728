import { CellProps } from "react-table";
import styles from "./diaTableHeader.module.scss";
import { VirtualInetrfaceDIA } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import Label from "../../../../../components/common/table/Label";
import DIAConnectionIcon from "../../../../../components/icons/DIAConnectionIcon";
import LabelArray from "../../../../../components/common/table/LabelArray";
import NaaSIconLabel from "../../../../../components/icons/NaaSIconLabel";
import {
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
} from "../../../../../helpers/validators/Validator";

export const diaTableHeader = (): Array<any> => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({
        cell: { value },
        row: { original },
      }: CellProps<VirtualInetrfaceDIA>) => {
        return (
          <div className={styles.labelName}>
            {original?.is_dia ? <DIAConnectionIcon /> : <NaaSIconLabel />}
            <div data-tip data-for={value}>
              <Label className={styles.noBack}>{value}</Label>
            </div>
          </div>
        );
      },
    },
    {
      Header: "IP pool assigned",
      accessor: "ip_address",
      Cell: ({
        cell: { value },
        row: { original },
      }: CellProps<VirtualInetrfaceDIA>) => {
        const poolValue = original?.is_dia ? value : value?.split("/")?.[0];
        return value ? <Label>{poolValue}</Label> : null;
      },
    },

    {
      Header: "AVBL IPs",
      accessor: "availableIPs",
      Cell: ({
        cell: { value },
        row: { original },
      }: CellProps<VirtualInetrfaceDIA>) => {
        return <div> {original?.is_dia ? value : null} </div>;
      },
    },
    {
      Header: "GW IP",
      accessor: "ip_addresses",
      Cell: ({ cell: { value }, row: { original } }: CellProps<any>) => {
        const valuesList = original?.is_dia ? value : [];
        const ipv4 = valuesList?.filter((val: string) =>
          checkIPv4WithPrefix(val)
        );
        const ipv6 = valuesList?.filter((val: string) =>
          checkIPv6WithPrefix(val)
        );
        return (
          <div>
            <LabelArray values={ipv4} withWrap={true} />
            <LabelArray
              values={ipv6}
              singleLabelClassname={styles.ips}
              withWrap={true}
            />
          </div>
        );
      },
    },
  ];
  return columns.map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInetrfaceDIA,
  }));
};
