import React, { FC } from "react";
import Input from "../../../../../components/common/formComponents/Input";
import { DESTINATION_NAT, NAT_TYPES_MAP } from "../../../const";

import styles from "./InsideBlock.module.scss";

type Props = {
  fields: { [key: string]: any };
  onChange: (field: string, value: any) => void;
  isEdit: boolean;
  errors?: { [key: string]: string };
  validate: () => {
    isOk: boolean;
  };
};

const InsideBlock: FC<Props> = ({
  fields,
  onChange,
  isEdit,
  errors,
  validate,
}) => {
  const isDNAT = fields?.rule_type === DESTINATION_NAT;

  return (
    <div className={styles.colGroup}>
      <Input
        name={"inside"}
        label="Inside"
        placeholder={"X.X.X.X"}
        onChange={(e) => {
          onChange("inside", e.target.value);
        }}
        onBlur={validate}
        isFullValue
        medium
        error={errors && errors["inside"]}
        value={fields.inside}
        isPlaceholderAlwaysVisible
        isNotEditable={isEdit}
        errorWithTooltip={true}
      />
      {isDNAT && (
        <Input
          type={"number"}
          label=""
          name="inside_port"
          placeholder="Port"
          isPlaceholderAlwaysVisible
          value={fields.inside_port}
          onChange={(e) => onChange("inside_port", +e.target.value)}
          medium
          error={""}
          isNotEditable={isEdit}
        />
      )}
    </div>
  );
};
export default InsideBlock;
