import React, { useState, useEffect } from "react";
import styles from "./AddDnsResolversDialog.module.scss";

const ipv4Regex = /^(25[0-5]|2[0-4]\d|1?\d\d?)\.(25[0-5]|2[0-4]\d|1?\d\d?)\.(25[0-5]|2[0-4]\d|1?\d\d?)\.(25[0-5]|2[0-4]\d|1?\d\d?)(?:\/\d+)?$/;
const ipv6Regex = /^(?:[a-fA-F0-9]{1,4}:){1,7}[a-fA-F0-9]{1,4}|::(?:[a-fA-F0-9]{1,4}:){0,6}[a-fA-F0-9]{1,4}(?:\/\d{1,3})?$/;

interface AddDnsResolversDialogProps {
  onClose: () => void;
  onAdd: (resolvers: Array<string>) => void;
  initialResolvers?: Array<string>;
}

const AddDnsResolversDialog: React.FC<AddDnsResolversDialogProps> = ({
  onClose,
  onAdd,
  initialResolvers = [],
}) => {
  const [inputs, setInputs] = useState(["", "", "", ""]);

  useEffect(() => {
    if (initialResolvers.length > 0) {
      const cleanResolvers = initialResolvers.map(
        (resolver) => resolver.split("/")[0] // remove mask
      );
      const ipv4Addresses: Array<string> = [];
      const ipv6Addresses: Array<string> = [];

      cleanResolvers.forEach((resolver) => {
        if (ipv4Regex.test(resolver)) {
          ipv4Addresses.push(resolver);
        } else if (ipv6Regex.test(resolver)) {
          ipv6Addresses.push(resolver);
        } else {
          console.warn(`Invalid resolver skipped: ${resolver}`);
        }
      });

      setInputs([
        ipv4Addresses[0] || "",
        ipv4Addresses[1] || "",
        ipv6Addresses[0] || "",
        ipv6Addresses[1] || "",
      ]);
    }
  }, [initialResolvers]);

  const handleInputChange = (index: number, value: string) => {
    setInputs((prev) => prev.map((item, i) => (i === index ? value : item)));
  };

  const handleAdd = () => {
    const nonEmptyInputs = inputs.filter((value) => value.trim() !== "");
    onAdd(nonEmptyInputs);
  };

  return (
    <div className={styles.dialog}>
      <div className={styles.title}>DNS Resolvers</div>
      <div className={styles.resolverGroup}>
        <div className={styles.resolverInputs}>
          <label className={styles.blockTitle}>IPv4</label>
          <input
            type="text"
            placeholder="XX.XX.XX.XX/XX"
            value={inputs[0]}
            onChange={(e) => handleInputChange(0, e.target.value)}
            className={styles.inputIPv4}
          />
          <input
            type="text"
            placeholder="XX.XX.XX.XX/XX"
            value={inputs[1]}
            onChange={(e) => handleInputChange(1, e.target.value)}
            className={styles.inputIPv4}
          />
        </div>
        <div className={styles.resolverInputs}>
          <label className={styles.blockTitle}>IPv6</label>
          <input
            type="text"
            placeholder="XXXX:XXXX:XXXX:XXXX:XXXX/XX"
            value={inputs[2]}
            onChange={(e) => handleInputChange(2, e.target.value)}
            className={styles.inputIPv6}
          />
          <input
            type="text"
            placeholder="XXXX:XXXX:XXXX:XXXX:XXXX/XX"
            value={inputs[3]}
            onChange={(e) => handleInputChange(3, e.target.value)}
            className={styles.inputIPv6}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <button className={styles.cancelButton} onClick={onClose}>
          Cancel
        </button>
        <button className={styles.addButton} onClick={handleAdd}>
          {initialResolvers?.length > 0 ? "Edit" : "Add"}
        </button>
      </div>
    </div>
  );
};

export default AddDnsResolversDialog;
