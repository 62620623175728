import React, { ReactElement, useMemo } from "react";
import styles from "./AddButton.module.scss";
import Button, { ButtonProps } from "./Button";
import LoaderIcon from "../loadStates/LoaderIcon";
import DNSIcon from "../../icons/topologyButtons/DNSIcon";

type DNSResolversButtonType = {
  isIconOnly?: boolean;
  isTextOnly?: boolean;
  disabled?: boolean;
  label?: string;
  className?: string;
  isLoading?: boolean;
} & Omit<ButtonProps, "children">;

export default function DNSResolversButton({
  isIconOnly,
  isTextOnly,
  label,
  className,
  isLoading,
  ...buttonProps
}: DNSResolversButtonType): ReactElement {
  const buttonContent = useMemo(() => {
    const content = [];
    if (isLoading) {
      return <LoaderIcon />;
    }

    if (!isTextOnly) {
      content.push(<DNSIcon color={"#6e39df"} />);
    }

    if (!isIconOnly) {
      content.push(<span className={styles.btnText}>{label || "DNS"}</span>);
    }

    return content;
  }, [isLoading, isTextOnly, isIconOnly, label]);

  return (
    <Button
      isPrimaryBtn={false}
      isBtnSmall={isIconOnly}
      className={className}
      {...buttonProps}
    >
      {buttonContent}
    </Button>
  );
}
