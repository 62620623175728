import { FC } from "react";
import { IconProps, ServiceType } from "../../helpers/types";
import NetworkObservabilityIcon from "./NetworkObservabilityIcon";
import ShieldIcon from "./ShieldIcon";
import CloudRouterIcon from "./zayo/menu/CloudRouterIcon";
import NATIcon from "./NATIcon";
import FirewallIcon from "./tabs/FirewallIcon";
import DIAIcon from "./DIAIcon";
import DNSIcon from "./topologyButtons/DNSIcon";
import SegmentsIcon from "./topologyButtons/SegmentsIcon";
import ServicesIcon from "./tabs/ServicesIcon";
import ApplicationsIcon from "./topologyButtons/ApplicationsIcon";

type ServiceIconProps = {
  serviceType: ServiceType | string;
  iconProps?: IconProps;
};

const SERVICE_TYPE_TO_ICON_MAP: Record<ServiceType | string, FC<IconProps>> = {
  sdr: NetworkObservabilityIcon,
  dpi: ApplicationsIcon,
  ids: ShieldIcon,
  fqdn: CloudRouterIcon,
  nat: NATIcon,
  firewall: FirewallIcon,
  dia_fw: DIAIcon,
  dns: DNSIcon,
  segments: SegmentsIcon,
  firewallservice: FirewallIcon,
  diafw: DIAIcon,
};

export const ServiceIcon = ({
  serviceType,
  iconProps = {},
}: ServiceIconProps) => {
  const Icon = SERVICE_TYPE_TO_ICON_MAP[serviceType] ?? ServicesIcon;
  return <Icon {...iconProps} />;
};
