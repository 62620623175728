import React, { FC, useEffect, useMemo, useState } from "react";

import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../../components/common/table/newTable/Table";
import {
  TableColumnsAdminConfig,
  TableColumnsTenantConfig,
} from "./TableColumnsConfig";

import styles from "./Table.module.scss";
import { FilterButton } from "./FilterButton";
import { TableGroupHeader } from "./TableGroupHeader";
import {
  combineState,
  convertToGroupRows,
  removeNATVis,
} from "../../helpers/table";
import { VirtualInterfaceApi } from "../../../VirtualInterfacePage/types";
import ConfirmDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../../../helpers/getDialogControls";
import { RequestStatus } from "../../../../helpers/types";
import { useUserContext } from "../../../../contexts/UserContext";
import { UserRoles } from "../../../../helpers/rolesHelpers";
import { useConnectionContext } from "../ConnectionContext";
import Layer3InterfaceAddDialog from "../itemDialog/Layer3InterfaceAddDialog";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { ButtonList } from "../../../../components/common/buttons/ButtonList";
import AddIcon from "../../../../components/icons/AddIcon";
import BlankButton from "../../../../components/common/buttons/BlankButton";
import { PURPLE } from "../../../../helpers/common/colorAliases";
import { useHistory } from "react-router-dom";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  CONFIGURATION,
  CONNECTIONS_UNITED,
  NETWORK,
} from "../../../../helpers/navigation/entries";
import { getAllVlan } from "../../UnitedConnections/components/Clouds/CloudsTabInfo";

const ConnectionsTable: FC = () => {
  const { user } = useUserContext();
  const [showAdd, setShowAdd] = useState(false);
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchConnections, fetchSystems } = useConnectionContext();

  useEffect(() => {
    fetchConnections();
    fetchSystems();
  }, [selectedTenant]);

  const {
    connections,
    systems,
    connectionFetchStatus,
    deleteConnection,
  } = useConnectionContext();

  const [
    connectionToDelete,
    setConnectionToDelete,
  ] = useState<VirtualInterfaceApi | null>(null);
  const [deleteRequestStatus] = useState<RequestStatus | undefined>();

  const data = removeNATVis(
    convertToGroupRows(combineState(connections), systems)
  ).filter((n) => n.subRows.length);

  const viCount = data.reduce((acc, cur) => acc + cur.subRows.length, 0);

  const handleConnectionDelete = async () => {
    if (!connectionToDelete) {
      return;
    }

    const { type, name, is_dia } = connectionToDelete;
    const vlan = getAllVlan(connectionToDelete);
    const { ok } = await deleteConnection(type, name, is_dia, vlan[0]);
    if (ok) {
      setConnectionToDelete(null);
    }
  };

  const tablesParams = useMemo(() => {
    const isAdmin =
      user.role === UserRoles.ADMIN || user.role === UserRoles.ADMIN_FULL;
    const columns = isAdmin
      ? TableColumnsAdminConfig
      : TableColumnsTenantConfig;
    const grid = isAdmin
      ? "1fr 1.5fr 2fr 150px 120px 150px 0.5fr 80px 40px"
      : "1fr 1.5fr 2fr 1.5fr 150px 120px 150px 80px 80px 40px";
    return { columns, grid };
  }, [user]);

  return (
    <div className={styles.tableWrapper}>
      {connectionToDelete && (
        <ConfirmDialog
          status={deleteRequestStatus}
          icon={DeleteIcon}
          title="Delete Connection"
          message={`Are you sure you want to delete ${connectionToDelete?.name}?`}
          controls={getDeleteDialogControls({
            onDelete: handleConnectionDelete,
            onCancel: () => setConnectionToDelete(null),
          })}
        />
      )}
      {showAdd && selectedTenant && (
        <Layer3InterfaceAddDialog
          onClose={() => setShowAdd(false)}
          type={"add"}
          tenant={selectedTenant}
          onSubmit={() => {
            fetchConnections();
            fetchSystems();
          }}
        />
      )}
      <TableWrapper
        dataStatus={{ state: connectionFetchStatus }}
        isEmpty={connections.length == 0}
        titleProps={{
          title: <TableTitle count={viCount} />,
        }}
      >
        <div className={styles.table}>
          <Table
            header={TableHeader}
            columns={tablesParams.columns}
            groupHeader={TableGroupHeader}
            data={data}
            gridColumnTemplate={tablesParams.grid}
            rowActions={{
              onDelete: (row) => {
                setConnectionToDelete((row as unknown) as VirtualInterfaceApi);
              },
            }}
          />
        </div>
      </TableWrapper>
    </div>
  );
};

export default ConnectionsTable;

type Props = {
  count: number;
};

const TableTitle: FC<Props> = ({ count = 0 }) => {
  const history = useHistory();

  return (
    <div className={styles.tableTitleWrapper}>
      <div>Connections ({count})</div>
      <div className={styles.titleButtons}>
        <FilterButton />
        <ButtonList
          list={[
            // eslint-disable-next-line react/jsx-key
            <BlankButton
              id="direct-connection-add"
              className={styles.addButtonItem}
              onClick={() => {
                history.push({
                  pathname: formPathFromArray([
                    NETWORK(),
                    CONNECTIONS_UNITED(),
                    CONFIGURATION(),
                  ]),
                  search: "?tab=Direct",
                });
              }}
            >
              <AddIcon color={PURPLE} width={12} height={12} />
              <span>Direct connection</span>
            </BlankButton>,
            // eslint-disable-next-line react/jsx-key
            <BlankButton
              id="cloud-router-add"
              className={styles.addButtonItem}
              onClick={() => {
                history.push({
                  pathname: formPathFromArray([
                    NETWORK(),
                    CONNECTIONS_UNITED(),
                    CONFIGURATION(),
                  ]),
                  search: "?tab=Cloud Router",
                });
              }}
            >
              <AddIcon color={PURPLE} width={12} height={12} />
              <span>Cloud router connection</span>
            </BlankButton>,
          ]}
        />
      </div>
    </div>
  );
};
