import React, { FC, useEffect, useMemo, useState } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import Table from "../../components/common/table/newTable/Table";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { withContexts } from "../../helpers/hocs/withContexts";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import VirtualInterfaceContextContainer, {
  useVirtualInterfaceContext,
} from "./VirtualInterfaceContext";
import { VirtualInterface } from "./types";
import { useVRFSelectorContext } from "../../contexts/systemsContext/VRFSelectorContext";
import VirtualInterfaceAddDialog from "./itemDialog/VirtualInterfaceAddDialog";
import VirtualInterfaceEditDialog from "./itemDialog/VirtualInterfaceEditDialog";
import { virtualInterfaceTableHeader } from "./table/virtualInterfaceTableHeader";
import { VIRTUAL_INTERFACE_GATE } from "../../helpers/consts";

const VirtualInterfaceContent: FC = () => {
  const {
    list = [],
    fetchList,
    listStatus,
    remove,
    removeStatus,
  } = useVirtualInterfaceContext();

  const { selectedVRF: vrf } = useVRFSelectorContext();

  const [viewItem, setViewItem] = useState<VirtualInterface | undefined>();
  const [showAdd, setShowAdd] = useState(false);
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<VirtualInterface>(remove, removeStatus, () => {
    setViewItem(undefined);
  });

  useEffect(() => {
    fetchList(vrf);
  }, [vrf]);

  const rowActions: ActionFuncs<VirtualInterface> = useMemo(
    () => ({
      onEdit: setViewItem,
      onDelete: (rowOriginal) => {
        tryDelete(rowOriginal, vrf);
      },
    }),
    [vrf, tryDelete, setViewItem]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Virtual Interface"}
        name={selectedToDelete?.name}
      />
      {showAdd && vrf && (
        <VirtualInterfaceAddDialog
          onClose={() => setShowAdd(false)}
          type={"add"}
          vrf={vrf}
        />
      )}
      {viewItem && vrf && (
        <VirtualInterfaceEditDialog
          onClose={() => setViewItem(undefined)}
          type={"edit"}
          vrf={vrf}
          data={viewItem}
        />
      )}
      <TableWrapper
        titleProps={{
          title: `${(list || []).length} Virtual Interface`,
          children: <AddButton onClick={() => setShowAdd(true)} />,
        }}
        dataStatus={listStatus}
        tablePlaceholder={["Virtual Interfaces", "Virtual Interface"]}
        isEmpty={list && list.length === 0}
      >
        <Table
          columns={virtualInterfaceTableHeader()}
          header={TableHeader}
          data={list.filter((item) => item.type !== VIRTUAL_INTERFACE_GATE)}
          gridColumnTemplate={`140px 140px 1fr 1fr 140px 1fr 0`}
          rowActions={rowActions}
          onClick={setViewItem}
        />
      </TableWrapper>
    </>
  );
};

export default withContexts(VirtualInterfaceContent, [
  VirtualInterfaceContextContainer,
]);
