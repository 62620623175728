import { useEffect, useMemo, useState } from "react";
import { PRes } from "../../../../../../helpers/api/Api";
import { AzureCloudCredentials } from "../../../../types";
import { useValidation } from "../../../../../../helpers/validators/Validator";
import { useFormField } from "../../../../../../helpers/hooks/useFormField";
import { DEFAULT_FIELDS } from "./constants";
import { formFieldProps } from "../../../../../../helpers/dialogs/FieldProps";
import { getDialogControls } from "../../../../../../helpers/getDialogControls";
import { Actions } from "../Actions";
import Input from "../../../../../../components/common/formComponents/Input";
import styles from "../../Forms.module.scss";
import validateAzureCloudCredentials from "../../../../../../helpers/validators/CloudCredentialsAzureValidator";

type AzureFormProps = {
  createAzureKey: (params?: AzureCloudCredentials) => PRes<any>;
  deleteAzureKey: (id?: number) => PRes<any>;
  updateAzureKey: (id?: number, params?: AzureCloudCredentials) => PRes<any>;
  activeAzureKey: AzureCloudCredentials | undefined;
  onClose: () => void;
};

export const useAzureForm = ({
  createAzureKey,
  deleteAzureKey,
  updateAzureKey,
  activeAzureKey,
  onClose,
}: AzureFormProps) => {
  const [fields, handleFieldChange, resetFields] = useFormField<
    Partial<AzureCloudCredentials>
  >(DEFAULT_FIELDS);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, validate] = useValidation<Partial<AzureCloudCredentials>>(
    validateAzureCloudCredentials,
    [fields]
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields]
  );

  const update = (params?: AzureCloudCredentials) =>
    updateAzureKey(activeAzureKey?.id, params);

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const action = isEdit ? update : createAzureKey;
      const { ok } = await action(fields as AzureCloudCredentials);
      if (ok) {
        onClose();
      }
    }
  };

  const handleDelete = async () => {
    const { ok } = await deleteAzureKey(activeAzureKey?.id);
    if (ok) {
      onClose();
    }
  };

  const intermediateState = !!(!isEdit && activeAzureKey);

  const controls = getDialogControls({
    dialogTitle: activeAzureKey ? "edit" : "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
    disableSubmit: intermediateState,
  });

  useEffect(() => {
    if (isEdit && activeAzureKey) {
      resetFields({
        client_secret: activeAzureKey.client_secret,
        subscription_id: activeAzureKey.subscription_id,
        tenant_id: activeAzureKey.tenant_id,
        client_id: activeAzureKey.client_id,
      });
    } else {
      resetFields(DEFAULT_FIELDS);
    }
  }, [isEdit]);

  const form = (
    <>
      {activeAzureKey && !isEdit && (
        <Actions onDelete={handleDelete} onEdit={() => setIsEdit(true)} />
      )}
      {(!activeAzureKey || isEdit) && (
        <>
          <div className={styles.fields}>
            <Input
              {...fieldProps("client_secret")}
              label="Secret"
              error={errors && errors["client_secret"]}
            />
            <Input
              {...fieldProps("subscription_id")}
              label="Subscription"
              error={errors && errors["subscription_id"]}
            />
            <Input
              {...fieldProps("tenant_id")}
              label="Tenant id"
              error={errors && errors["tenant_id"]}
            />
            <Input
              {...fieldProps("client_id")}
              label="Client id"
              error={errors && errors["client_id"]}
            />
          </div>
        </>
      )}
    </>
  );

  return { controls, form };
};
