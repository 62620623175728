import { FC, useEffect, useMemo, useState } from "react";
import AddButton from "../../../../components/common/buttons/AddButton";
import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../../contexts/UserContext";
import { useStaticRoutesServicesContext } from "../../StaticRoutesServicesContext";
import { DialogTitle } from "../../../../helpers/types";
import { StaticRoutesBodyType } from "../../../../helpers/api/apiTypes";
import { ActionFuncs } from "../../../../components/common/table/newTable/RowControls";
import ConfirmDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../../../helpers/getDialogControls";
import ServicesStaticRouteDialog from "../ServicesStaticRouteDialog";
import Table, {
  DEFAUTL_LIMIT,
  DEFAUTL_OFFSET,
} from "../../../../components/common/table/newTable/Table";
import { staticTableHeader } from "../staticTableHeader";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";

const StaticRoutesTable: FC = () => {
  const { user } = useUserContext();
  const {
    fetchStaticRoutesList,
    staticRoutesList,
    addStaticRouteStatus,
    staticRoutesStatus,
    staticRoutesCount,
    isUpdateStaticRoutesServicesList,
    setIsUpdateStaticRoutesServicesList,
    deleteStaticRoutes,
    enableStaticRoutes,
  } = useStaticRoutesServicesContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    fetchWrapper();
  }, [selectedTenant, isUpdateStaticRoutesServicesList]);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    if (selectedTenant) {
      fetchStaticRoutesList(selectedTenant, user.role, offset, limit);
      return;
    }
    if (isUpdateStaticRoutesServicesList && selectedTenant) {
      fetchStaticRoutesList(selectedTenant, user.role, offset, limit);
      setIsUpdateStaticRoutesServicesList(false);
      return;
    }
  };

  const [isRouteDialogShown, setIsRouteDialogShown] = useState<boolean>(false);
  const [dialogMode] = useState<DialogTitle>("add");
  const [chosenRouteItem, setChosenRouteItem] = useState<
    StaticRoutesBodyType | undefined
  >();
  const [isDeleteDialogShown, setIsDeleteDialogShown] = useState<boolean>(
    false
  );

  const handleDeleteRouteItem = async () => {
    if (!selectedTenant || !chosenRouteItem) {
      return;
    }
    await deleteStaticRoutes(selectedTenant, chosenRouteItem);
    setIsDeleteDialogShown(false);
  };

  const rowActions: ActionFuncs<any> = useMemo(
    () => ({
      onDelete: (rowOriginal) => {
        setIsDeleteDialogShown(true);
        setChosenRouteItem(rowOriginal);
      },
    }),
    []
  );

  return (
    <>
      {isDeleteDialogShown && (
        <ConfirmDialog
          icon={DeleteIcon}
          title="Delete Static Route"
          message={`Are you sure you want to delete route?`}
          controls={getDeleteDialogControls({
            onDelete: handleDeleteRouteItem,
            onCancel: () => setIsDeleteDialogShown(false),
          })}
        />
      )}
      {isRouteDialogShown && (
        <ServicesStaticRouteDialog
          tenant={selectedTenant}
          onClose={() => setIsRouteDialogShown(false)}
          dialogTitle={dialogMode}
          data={chosenRouteItem}
        />
      )}
      <TableWrapper
        titleProps={{
          title: `Static Routes`,
          children: (
            <AddButton
              onClick={() => setIsRouteDialogShown(true)}
              isLoading={addStaticRouteStatus?.state === "pending"}
            />
          ),
        }}
        dataStatus={staticRoutesStatus}
        tablePlaceholder={["Static Routes", "Static Route"]}
        isEmpty={!staticRoutesList?.length}
      >
        <Table
          fetchAction={fetchWrapper}
          data={staticRoutesList}
          count={staticRoutesCount}
          columns={staticTableHeader()}
          header={TableHeader}
          gridColumnTemplate={`70px 0.75fr 1fr 0.5fr repeat(2, 1fr) 0px`}
          rowActions={rowActions}
          clickFeatures={[
            {
              elementClass: "toggle",
              clb: (row) => enableStaticRoutes(selectedTenant, row),
            },
          ]}
        />
      </TableWrapper>
    </>
  );
};
export default StaticRoutesTable;
