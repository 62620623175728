import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./VRFStatisticsPage.module.scss";
import { PageProps, TrafficType } from "../../../../helpers/types";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { OneSystemLayout } from "../../../leftInfoBlock/LayoutTwoColumnFactory";
import { OneSystemTabs } from "../../../common/navigation/OneSystemTabs";
import ChartToppersTabs from "../../../common/ChartToppersTabs";
import GrafanaGraph from "../../../common/charts/GrafanaGraph";
import grafanaLinksFactory, {
  LinkFn,
} from "../../../common/charts/GraphanaLinksFactory";
import { useVRFSelectorContext } from "../../../../contexts/systemsContext/VRFSelectorContext";
import ChartsHeader from "../../../common/charts/ChartsHeader/ChartsHeader";
import { VirtualInterfaceApi } from "../../../../pages/VirtualInterfacePage/types";
import { useOneSystemContext } from "../../../../contexts/systemsContext/OneSystemContext";
import VIListContextContainer, {
  useVIListContext,
} from "../../../common/OneSystem/contexts/VIListContext";
import { BPS } from "../../../../helpers/common/constantsAlias";

const VRFStatisticsPage: React.FC = () => {
  const { timeRange } = useTimerangeContext();
  const [activeTraffic, setActiveTraffic] = useState<TrafficType>(BPS);

  const { selectedVRF: vrf, list: vrfList } = useVRFSelectorContext();
  const { system } = useOneSystemContext();
  const { fetchList, list } = useVIListContext();
  const selectedVRFData = useMemo(() => {
    return vrfList?.find((el) => el.name === vrf);
  }, [vrf]);

  useEffect(() => {
    if (selectedVRFData?.name) {
      fetchList(
        {
          vrf_type: selectedVRFData?.vrf_type,
          name: selectedVRFData?.name,
        },
        system
      );
    }
  }, [vrf]);

  const linkWithData = useCallback(
    (func: LinkFn) => {
      const data = {
        timeRange: timeRange.general,
        vrf: vrf,
      };
      return func(data);
    },
    [timeRange, vrf]
  );

  return (
    <OneSystemLayout>
      <div className={styles.contentWrapper}>
        <OneSystemTabs showTimeRange />
        <div className={styles.wrapper}>
          <div className={styles.graphWrap}>
            <ChartsHeader
              controls={
                <ChartToppersTabs
                  activeTab={activeTraffic}
                  setTabActive={setActiveTraffic}
                />
              }
              title="VRF Statistics"
            />
            <ChartsHeader title={"Total"} className={styles.header} />
            <GrafanaGraph
              link={linkWithData(
                activeTraffic === BPS
                  ? grafanaLinksFactory.dashboard_bps(timeRange.general)
                  : grafanaLinksFactory.dashboard_pps(timeRange.general)
              )}
            />
          </div>
          {list?.map((vi: VirtualInterfaceApi) => {
            const dataVI = {
              timeRange: timeRange.general,
              vrf: vrf,
              interfaceName: vi.name,
            };
            return (
              <div className={styles.graphWrap} key={vi.id}>
                <ChartsHeader title={vi.name} className={styles.header} />
                <GrafanaGraph
                  link={
                    activeTraffic === BPS
                      ? grafanaLinksFactory.dashboard_interface_bps(
                          timeRange.general
                        )(dataVI)
                      : grafanaLinksFactory.dashboard_interface_pps(
                          timeRange.general
                        )(dataVI)
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </OneSystemLayout>
  );
};

export default withLayout<PageProps>(
  withContexts(VRFStatisticsPage, [VIListContextContainer])
);
