/* eslint-disable css-modules/no-unused-class */
import React, { FC, ReactElement } from "react";
import { GREY_FONT, PURPLE } from "../../../../../helpers/common/colorAliases";
import { classNames } from "../../../../../helpers/common/classNames";
import { DropdownItem } from "../../../../../helpers/types";
import DropDownIcon from "../../../../icons/DropDownIcon";
import SearchIcon from "../../../../icons/SearchIcon";
import BlankButton from "../../../buttons/BlankButton";
import CloseIcon from "../../../../../components/icons/CloseIcon";

import styles from "./DropdownInput.module.scss";

type DropdownInputProps = {
  placeholder?: string;
  selected?: DropdownItem;
  isSmaller?: boolean;
  isMedium?: boolean;
  isNotFormElement?: boolean;
  icon?: ReactElement;
  isPseudoBtnMode?: boolean;
  isListShown: boolean;
  onClick: () => void;
  isAutocompleteField?: boolean;
  onChange?: (arg: any) => void;
  handleSelect?: (arg: any) => void;
  onKeyDown?: (arg: React.KeyboardEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  withClearIcon?: boolean;
  name?: string;
  value?: string | number;
  isError?: boolean;
  isPlaceholderAlwaysVisible?: boolean;
  isNotEditable?: boolean;
  isHideIcon?: boolean;
  id: string;
  forcedClear?: boolean;
};

const DropdownInput: FC<DropdownInputProps> = ({
  onClick,
  isSmaller,
  isMedium,
  isPseudoBtnMode,
  icon,
  isListShown,
  selected,
  placeholder,
  isAutocompleteField,
  onChange,
  handleSelect,
  onKeyDown,
  disabled,
  withClearIcon,
  name,
  value,
  isError,
  isNotEditable,
  isHideIcon,
  id,
  isPlaceholderAlwaysVisible,
  forcedClear,
}) => {
  const inputValue = selected?.value || selected?.key;
  const valueToClear = forcedClear ? inputValue : value;
  const getIcon = () => {
    if (isAutocompleteField && !isHideIcon) {
      return (
        <SearchIcon
          color={GREY_FONT}
          className={styles.autocompleteInputIcon}
        />
      );
    }
    if (forcedClear && !isHideIcon) return icon;
  };

  if (isAutocompleteField || forcedClear) {
    return (
      <div
        className={classNames(
          styles.dropDownContainer,
          isSmaller && styles.smaller,
          isMedium && styles.medium,
          isNotEditable && styles.notEditable,
          isError && styles.error,
          styles.autocompleteInputContainer
        )}
      >
        {getIcon()}
        <input
          type="text"
          disabled={disabled || isNotEditable}
          placeholder={placeholder}
          className={classNames(
            styles.autocompleteInputField,
            isHideIcon && styles.withoutIcon,
            isSmaller && styles.smaller,
            isMedium && styles.medium,
            forcedClear && styles.notEditable
          )}
          name={name}
          value={valueToClear}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={onClick}
        />
        {withClearIcon && (valueToClear || placeholder) && (
          <div className={styles.closeBtnWrapper}>
            <CloseIcon
              color={PURPLE}
              className={styles.closeIconClassProp}
              onClick={() => {
                onChange?.("");
                handleSelect?.({ value: { key: "" } });
              }}
            />
          </div>
        )}
      </div>
    );
  }
  return (
    <BlankButton
      id={id}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
      disabled={disabled || isNotEditable}
      className={classNames(
        styles.dropDownContainer,
        isError && styles.error,
        isSmaller && styles.smaller,
        isMedium && styles.medium,
        isNotEditable && styles.notEditable,
        isPseudoBtnMode && styles.btnStyles
      )}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      {placeholder && (!inputValue || isPlaceholderAlwaysVisible) && (
        <div className={styles.placeholder}>{placeholder}</div>
      )}
      {inputValue && <div className={styles.text}>{inputValue}</div>}
      {!disabled && withClearIcon && (valueToClear || placeholder) && (
        <div
          className={classNames(
            styles.closeBtnWrapper,
            styles.closeBtnWrapperWithMargin
          )}
        >
          <CloseIcon
            color={PURPLE}
            className={styles.closeIconClassProp}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onChange?.("");
              handleSelect?.({ value: { key: "" } });
            }}
          />
        </div>
      )}
      {!disabled && (
        <DropDownIcon
          className={classNames(
            styles.dropDownIcon,
            isListShown && styles.rotateIcon
          )}
        />
      )}
    </BlankButton>
  );
};

export default DropdownInput;
