import React, { useState } from "react";
import Input from "../../components/common/formComponents/Input";
import CaretOutline from "../../components/icons/CaretOutline";

// to show wrapped component only after validation in token field

type Props = {
  title: string;
};

export function withTokenInput<T>(
  WrappedComponent: React.ComponentType<Omit<T, "title">>
) {
  return function Component(props: T & Props) {
    const { title, ...restProps } = props;
    const [token, setToken] = useState<string>();
    const [showList, setShowList] = useState<boolean>(false);

    return (
      <>
        <Input
          value={token}
          onChange={(e) => setToken(e.target.value)}
          isFullValue
          medium
          error={""}
          label={title}
          placeholder="Enter the token"
          rigthButtonClick={() => setShowList(Boolean(token))}
          rigthButtonIcon={CaretOutline}
        />
        {showList && <WrappedComponent {...restProps} />}
      </>
    );
  };
}
