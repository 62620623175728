import Cookies from "js-cookie";
import { authApi } from "../helpers/api/AuthApi";
import { userApi } from "../helpers/api/UserApi";
import { getUserNames } from "../helpers/getUserNames";
import { UserRoles, UserSecondRoles } from "../helpers/rolesHelpers";
import { RequestStatus } from "../helpers/types";
import { AbstractContextProvider } from "./common/AbstractContext";
import { createContextAndUse } from "./common/AbstractCrudContext";
import { IUser } from "./UserContext";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { setEventsStartTime } from "../helpers/setEventsStartTime";

type RequestStatuses = {
  loginRes: RequestStatus;
  tokenRes: RequestStatus;
  // tokenValidationRes: "checking" | "invalid" | "ok";
};
type IState = {
  isAuthorized: boolean;
  username: string | null;
  user: IUser | null;
  isPasswordExpired?: boolean;
  ssoProviders: { [key: string]: string } | null;
  ssoError: boolean;
  ssoErrorMessage: string | undefined;
  // userParams: { isAdmin?: boolean; isConfigTenant: boolean };
} & RequestStatuses;

type IFunc = {
  login: (
    username: string,
    password: string,
    newPassword?: string
  ) => Promise<void>;
  logout: () => void;
  restoreUser: () => IUser | null;
  validateCookie: () => Promise<boolean>;
  resetIsPasswordExpired: () => void;
  getSSOProviders: () => void;
  getTokenSSO: (state: string, code: string, clb: () => void) => void;
  setSSOError: (message: string) => void;
  setSSOSource: (source: string) => void;
  getSSOSource: () => string | null;
};

export const USERNAME_KEY_NAME = "username";
export const USER_ROLE = "role";
export const USER_SECOND_NAME = "secondName";
export const USER_SECOND_ROLE = "secondRole";
export const EVENT_START_TIME = "eventStartTime";

export const AUTH_IS_ENABLED = process.env.REACT_APP_AUTH !== "DISABLE";

const [AuthContext, useContext] = createContextAndUse<IState, IFunc>();
export const useAuthContext = useContext;
const SSO_SOURCE_KEY = "SSO_SOURCE_KEY";

let CookieExpireTimer: NodeJS.Timeout | undefined;
class AuthContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = AuthContext;
  funcs: IFunc;
  api = authApi;

  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      isAuthorized: true,
      loginRes: { state: "ok" },
      username: null,
      user: null,
      tokenRes: { state: "pending" },
      ssoProviders: null,
      ssoError: false,
      ssoErrorMessage: undefined,
    };

    this.funcs = {
      login: this.login,
      logout: this.logout,
      restoreUser: this.restoreUser,
      validateCookie: this.validateCookie,
      resetIsPasswordExpired: this.resetIsPasswordExpired,
      getSSOProviders: this.getSSOProviders,
      getTokenSSO: this.getTokenSSO,
      setSSOError: this.setSSOError,
      setSSOSource: this.setSSOSource,
      getSSOSource: this.getSSOSource,
    };
  }

  componentDidMount(): void {
    this.setInitialAuthorizedStatus();
  }

  resetIsPasswordExpired = (): void => {
    this.setState({ isPasswordExpired: false });
  };

  setInitialAuthorizedStatus = async (): Promise<void> => {
    const isValid = await this.validateCookie();
    const initialAuthorized =
      Boolean(localStorage.getItem(USERNAME_KEY_NAME)) && isValid;
    if (this.state.isAuthorized !== initialAuthorized) {
      this.setState({ isAuthorized: initialAuthorized });
    }
  };

  componentWillUnmount(): void {
    this.clearTimeout();
  }

  validateCookie = async (): Promise<boolean> => {
    const cookie = AuthContextContainer.getAuthCookie();
    if (!cookie) {
      this.logout();
      return false;
    }
    const decoded: { exp: number } = jwt_decode(cookie);
    const exp = decoded.exp;
    const nowTime = moment().unix();
    if (nowTime >= exp) {
      this.logout();
      return false;
    }
    const res = await userApi.getCurrentUser();
    if (res.ok === false && res.error) {
      this.logout();
      return false;
    }
    this.setState({ isAuthorized: true });
    this.startLogoutTimer(exp, nowTime);
    return true;
  };

  clearTimeout = () => {
    if (CookieExpireTimer) {
      clearTimeout(CookieExpireTimer);
    }
    CookieExpireTimer = undefined;
  };

  startLogoutTimer = (exp: number, nowDate: number) => {
    CookieExpireTimer = setTimeout(() => {
      this.logout();
    }, (exp - nowDate) * 1000);
  };

  getSSOProviders = async (): Promise<void> => {
    const { ok, result } = await userApi.getSSOProviders();
    if (ok && result?.providers) {
      this.setState({ ssoProviders: result.providers });
    }
  };

  setSSOError = async (message: string): Promise<void> => {
    this.setState({ ssoError: true, ssoErrorMessage: message });
  };

  setSSOSource = (source: string): void => {
    localStorage.setItem(SSO_SOURCE_KEY, JSON.stringify(source));
  };

  getSSOSource = (): string | null => {
    return localStorage.getItem(SSO_SOURCE_KEY);
  };

  parseJwt = (token: string) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  getTokenSSO = async (
    state: string,
    code: string,
    clb: () => void
  ): Promise<void> => {
    const { ok, result } = await userApi.getTokenSSO(state, code);
    if (!ok && !result?.access_token) {
      this.setState({ ssoError: true });
      return;
    }

    const token = result.access_token;
    const claims = this.parseJwt(token);
    const { username, tenantName } = getUserNames(
      claims?.["preferred_username"]
    );
    const tenant =
      tenantName !== "global" ? claims?.["tenant_name"] : undefined;

    if (username) await this.auth(token, tenant, username);
    setTimeout(() => {
      clb();
    }, 1000);
  };

  login = async (
    loginField: string,
    password: string,
    newPassword?: string
  ): Promise<void> => {
    // Clear any leftover info
    localStorage.clear();
    // Provide auth
    const { username, tenantName } = getUserNames(loginField);
    const { ok, result, error } = await this._fetchWithStatus(
      () => this.api.getToken(username, password, tenantName, newPassword),
      "loginRes"
    );

    const loginRes: RequestStatus = {
      state: AUTH_IS_ENABLED ? "pending" : "ok",
      message: AUTH_IS_ENABLED ? error : "",
    };

    this.setState({
      loginRes,
    });
    if (loginRes.message === `"Password is expired"`) {
      this.setState({ isPasswordExpired: true });
    }
    if (!ok) return;

    this.auth(result?.access_token || "", tenantName, username);
  };

  auth = async (access_token: string, tenantName?: string, username = "") => {
    AuthContextContainer.setAuthCookie(access_token || "");

    const res = await userApi.getCurrentUser();
    if (!res.ok) return;

    const role = tenantName ? UserRoles.TENANT : UserRoles.ADMIN;
    const name = tenantName || username;
    const secondName = tenantName ? username : "";
    // specific role like editor, viewer, remote
    const secondRole = res.result.role;

    localStorage.setItem(USER_ROLE, process.env.REACT_APP_ROLE || role);
    localStorage.setItem(USERNAME_KEY_NAME, name);
    localStorage.setItem(USER_SECOND_NAME, secondName);
    localStorage.setItem(USER_SECOND_ROLE, secondRole);

    setEventsStartTime();
    this.setState({
      username,
      user: { role, name, secondName, secondRole },
    });
    this.validateCookie();
  };

  static setAuthCookie = (token: string): void => {
    // allow iframe
    Cookies.set("Authorization", token, { sameSite: "none", secure: true });
  };
  static getAuthCookie = (): string | undefined => {
    return Cookies.get("Authorization");
  };

  restoreUser = (): IUser | null => {
    const name = localStorage.getItem("username");
    const role = localStorage.getItem("role") as UserRoles;
    const secondName = localStorage.getItem(USER_SECOND_NAME) || undefined;
    const secondRole = localStorage.getItem(
      USER_SECOND_ROLE
    ) as UserSecondRoles;

    if (!name || !role || !secondRole) return null;

    return { name, role, secondName, secondRole };
  };

  logout = (): void => {
    //saved filters will be also cleared
    localStorage.clear();
    Cookies.remove("Authorization");
    this.setState({ isAuthorized: false, username: null, user: null });
    this.clearTimeout();
  };

  render(): JSX.Element {
    return (
      <AuthContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextContainer;
