import { createContext, PropsWithChildren, useContext, useState } from "react";

type TopologyPageContextValue = {
  connectionType: string;
  setConnectionType: (value: string) => void;
};

export const TopologyPageContext = createContext<TopologyPageContextValue>({
  connectionType: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setConnectionType: () => {},
});

export const TopologyPageProvider = ({ children }: PropsWithChildren<{}>) => {
  const [connectionType, setConnectionType] = useState("");
  return (
    <TopologyPageContext.Provider value={{ connectionType, setConnectionType }}>
      {children}
    </TopologyPageContext.Provider>
  );
};

export const useTopologyPageContext = () => {
  return useContext(TopologyPageContext);
};
