import React, { ReactElement } from "react";
import { IconProps } from "../../../../../../helpers/types";

function PortBaseIcon({ color, className, x, y }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      x={x && x + 3}
      y={y}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 18C7.94772 18 7.5 17.5523 7.5 17V13.5H5.5C4.94772 13.5 4.5 13.0523 4.5 12.5V7C4.5 6.44772 4.94772 6 5.5 6H18.5C19.0523 6 19.5 6.44771 19.5 7V12.5C19.5 13.0523 19.0523 13.5 18.5 13.5H16.5V17C16.5 17.5523 16.0523 18 15.5 18H8.5Z"
        fill={color || "#59536B"}
      />
    </svg>
  );
}

export default PortBaseIcon;
