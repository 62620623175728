import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function QINQWithStatusIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.58301 1.4C4.58301 0.6268 5.20981 0 5.98301 0H14.0163C14.7895 0 15.4163 0.626801 15.4163 1.4V9.43333C15.4163 10.2065 14.7895 10.8333 14.0163 10.8333H5.98301C5.20981 10.8333 4.58301 10.2065 4.58301 9.43333V1.4ZM10.931 4.63954C10.931 6.20613 10.5411 7.26208 9.76108 7.80739L11.0828 9.75065H9.26125L8.30602 8.21401L8.30231 8.20928V8.20455L8.29861 8.19982C7.44952 8.19982 6.79789 7.89565 6.34372 7.28729C5.89203 6.67894 5.66618 5.7932 5.66618 4.63008C5.66618 3.47957 5.89079 2.60171 6.34002 1.99651C6.79172 1.38816 7.44705 1.08398 8.30602 1.08398C9.16745 1.08398 9.82031 1.38501 10.2646 1.98706C10.7089 2.58911 10.931 3.47327 10.931 4.63954ZM7.20639 4.63954C7.20639 5.97603 7.57047 6.64427 8.29861 6.64427C8.66886 6.64427 8.94284 6.48194 9.12055 6.15727C9.30074 5.83261 9.39083 5.3267 9.39083 4.63954C9.39083 3.94923 9.2995 3.44017 9.11685 3.11235C8.93667 2.78138 8.66639 2.6159 8.30602 2.6159C7.57293 2.6159 7.20639 3.29045 7.20639 4.63954ZM10.5962 8.04284C11.0306 7.69237 11.3986 7.21008 11.6688 6.63756C11.9789 6.60548 12.2129 6.44516 12.3708 6.15657C12.551 5.83191 12.6411 5.326 12.6411 4.63884C12.6411 3.94854 12.5498 3.43947 12.3671 3.11166C12.2544 2.90449 12.1063 2.76216 11.9229 2.68467C11.7366 2.08118 11.4512 1.5461 11.0935 1.11553C11.2406 1.09403 11.3949 1.08329 11.5563 1.08329C12.4177 1.08329 13.0706 1.38431 13.5149 1.98636C13.9592 2.58841 14.1813 3.47257 14.1813 4.63884C14.1813 6.20543 13.7914 7.26138 13.0114 7.80669L14.3331 9.74995H12.5115L11.5563 8.21331L11.5526 8.20858V8.20386L11.5489 8.19913C11.1975 8.19913 10.8799 8.14703 10.5962 8.04284Z"
        fill="#59536B"
      />
      <rect
        x="0.833008"
        y="13.334"
        width="5"
        height="5"
        rx="1.4"
        fill="#59536B"
      />
      <rect
        x="14.166"
        y="13.334"
        width="5"
        height="5"
        rx="1.4"
        fill="#59536B"
      />
      <rect x="7.5" y="13.334" width="5" height="5" rx="1.4" fill="#59536B" />
      <circle cx="21" cy="3" r="3" fill={color} />
    </svg>
  );
}

export default QINQWithStatusIcon;
