import { FC, useState } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import styles from "./LeftBlock.module.scss";
import SystemIcon from "../../../../../../components/icons/SystemIcon";
import UnifiedTextPair from "../../../../../../components/common/UnifiedTextPair";
import WarningMessage from "../../../../../../components/common/WarningMessage";
import { GREY_FONT } from "../../../../../../helpers/common/colorAliases";
import { FieldsType } from "../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { Toggle } from "../../../../../../components/common/Toggle";
import TextWithIcon from "../../../../../../components/common/table/TextWithIcon";
import StatusIcon from "../../../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../../../helpers/common/colorHelpers";
import { useSystemConfigContext } from "../SystemConfigContext";
import BlankButton from "../../../../../../components/common/buttons/BlankButton";
import { haDomainApi } from "../../../../../../helpers/api/HADomainsApi";
import ConfirmDialog from "../../../../../../components/dialogs/common/confirmDialog/ConfirmDialog";

type Props = {
  className: string;
  fields: FieldsType;
  isNode?: boolean;
  onAction?: (value: any) => void;
  onClose?: () => void;
};
const LeftBlock = ({
  className,
  fields,
  isNode,
  onAction = () => undefined,
  onClose,
}: Props) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {isNode ? (
        <NodeFields fields={fields} onAction={onAction} onClose={onClose} />
      ) : (
        <SystemFields />
      )}
    </div>
  );
};
export default LeftBlock;

const SystemFields = () => {
  const { system } = useSystemConfigContext();
  const activeNode = system?.nodes?.find(
    (node) => node.name === system?.active_node
  );

  return (
    <>
      <div className={styles.title}>
        <SystemIcon color={GREY_FONT} className={styles.icon} />
        <span>{system?.name || "System"}</span>
      </div>
      <div className={styles.infoLines}>
        <UnifiedTextPair subtitle="Host IP" text={activeNode?.address || "-"} />
        <UnifiedTextPair subtitle="Version" text={activeNode?.version || "-"} />
      </div>
      <div className={styles.note}>
        <WarningMessage
          className={styles.note}
          message="Upon adding a new node to a system, its configuration will be reset and it will restart."
        />
      </div>
    </>
  );
};

const NodeFields = ({
  fields,
  onAction,
  onClose,
}: Pick<Props, "fields" | "onAction" | "onClose">) => {
  const [reconfigNode, setreconfigNode] = useState<boolean>(false);

  const reconfigureNode = async () => {
    const res = await haDomainApi.reconfigNode(fields.name, fields.address);
    if (res) {
      setreconfigNode(false);
      onClose?.();
    }
  };
  return (
    <>
      {reconfigNode && (
        <ConfirmDialog
          title={"Reconfigure node"}
          message={"Are you sure you want to reconfigure node?"}
          controls={{
            okText: "Reconfigure",
            onOk: reconfigureNode,
            onCancel: () => setreconfigNode(false),
          }}
        />
      )}
      <div className={styles.title}>
        <SystemIcon color={GREY_FONT} className={styles.icon} />
        <span>{fields?.name}</span>
      </div>
      <div className={styles.infoLines}>
        <UnifiedTextPair subtitle="System" text={fields?.system_name} />
        <UnifiedTextPair subtitle="Host IP" text={fields?.address} />
        <UnifiedTextPair subtitle="HA" text={fields?.ha_status} />
        <UnifiedTextPair
          subtitle="Status"
          text={
            <TextWithIcon
              icon={<StatusIcon color={getColorFromStatus(fields?.status)} />}
              text={fields?.status}
            />
          }
        />
        <UnifiedTextPair subtitle="HA Role" text={fields?.role} />
        <BlankButton
          id={"reconfigure_node"}
          className={styles.reconfigBtn}
          onClick={() => setreconfigNode(true)}
        >
          Reconfigure Node
        </BlankButton>
      </div>
      <div className={styles.note}>
        <Toggle
          id={"switchToActive"}
          onChange={() => onAction?.(fields)}
          label={"Switch to Active"}
          isChecked={fields.role === "active"}
          disabled={fields.role === "active"}
        />
      </div>
    </>
  );
};
