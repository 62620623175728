/* eslint-disable prettier/prettier */
import React, { FC, useEffect, useState } from "react";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../contexts/UserContext";
import { commandApi } from "../../../helpers/api/CommandApi";
import { systemApi } from "../../../helpers/api/SystemApi";
import { tenantApi } from "../../../helpers/api/TenantApi";
import { UUID } from "../../../helpers/api/types";
import { RequestStatus } from "../../../helpers/types";
import { LookingGlassFields } from "./types";
import { tenantVirtualInterfacesApi } from "../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import { VirtualInetrfaceGate } from "../../../helpers/api/TenantVirtualInterfaceApi/types";

export type SystemWithLocation = { location?: string; system?: string };

type IFunc = {
  runCommand: (params: LookingGlassFields) => void;
  setSelectedSystem: (system: SystemWithLocation) => void;
  selectedSystem: SystemWithLocation | null;
};

type IState = {
  commandOutput: string | null;
  commandExecStatus: RequestStatus;
  fetchAttempts: number;
  currentCommandUUID: UUID | null;
  userSystems: Array<SystemWithLocation>;
  diaExist: boolean;
};

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const [LookingGlassContext, useContext] = createContextAndUse<IState, IFunc>();
export const useLookingGlassContext = useContext;

export const MAX_FETCH_ATTEMPTS = 10;
const FETCH_DELAY = 2000;
const PING_COUNT = 3;
const DIA_VRF_NAME = "internet";

export const LookingGlassContextContainer: FC<Props> = ({ children }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { user } = useUserContext();
  const [commandOutput, setCommandOutput] = useState<string | null>(null);
  const [commandExecStatus, setCommandExecStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [currentCommandUUID, setCurrentCommandUUID] = useState<null | UUID>(
    null
  );
  const [userSystems, setUserSystems] = useState<Array<SystemWithLocation>>([]);
  const [
    selectedSystem,
    setSelectedSystem,
  ] = useState<SystemWithLocation | null>(null);
  const [gates, setGates] = useState<Array<VirtualInetrfaceGate>>();
  const [diaExist, setDiaExists] = useState(false);

  const tenant = selectedTenant || user.name;

  const [fetchAttempts, setFetchAttempts] = useState(0);

  const runCommand = async ({
    cmd,
    ip,
    protocol,
    max_mtu,
    min_mtu,
    isDia,
  }: LookingGlassFields) => {
    setCommandExecStatus({ state: "pending" });
    const { ok, result, error } = await commandApi.execute(cmd, {
      address: ip,
      system_name: selectedSystem?.system,
      protocol,
      vrf_name: isDia ? DIA_VRF_NAME : tenant,
      max_mtu: +max_mtu,
      min_mtu: +min_mtu,
    });

    if (!ok || !result) {
      return setCommandExecStatus({ state: "error", message: error });
    }

    setCurrentCommandUUID(result.message);
  };

  const getCommandResult = async (uuid: UUID) => {
    setCommandExecStatus({ state: "pending" });

    let fetchCounter = 0;
    const interval = setInterval(async () => {
      const { ok, result, error } = await commandApi.getCommandResult({
        system_name: selectedSystem?.system,
        uuid,
      });

      const isFailed =
        !ok ||
        !result ||
        (result.status === "error" && result.result !== "result not found");

      if (isFailed) {
        setCurrentCommandUUID(null);
        setFetchAttempts(0);
        clearInterval(interval);
        return setCommandExecStatus({
          state: "error",
          message: result?.result,
        });
      }

      const { status, result: output } = result;

      const isDone = status === "done";
      const stopInterval = isDone || fetchCounter >= MAX_FETCH_ATTEMPTS;

      if (stopInterval) {
        setCurrentCommandUUID(null);
        setFetchAttempts(0);
        clearInterval(interval);
        setCommandOutput(isDone ? output : null);
        return setCommandExecStatus(
          isDone ? { state: "ok" } : { state: "error", message: "Timeout" }
        );
      }

      setFetchAttempts((v) => v + 1);
      fetchCounter++;
    }, FETCH_DELAY * 5);
  };

  const fetchSystems = async () => {
    let systemList = [];
    const { result } = await tenantApi.getTenant(tenant);
    const { result: systems } = await systemApi.getSystemsList();

    const allSystems = systems || [];

    const tenantSystems = result?.systems;

    if (!tenantSystems) {
      return setUserSystems([]);
    }

    systemList = tenantSystems
      ? allSystems
          .filter((s) => tenantSystems.includes(s.name))
          .map((s) => ({ location: s.location, system: s.name }))
      : [];

    setUserSystems(systemList);
  };

  const fetchDiaGate = async () => {
    const gates = await tenantVirtualInterfacesApi.getVirtualInterfacesGate(
      tenant
    );
    setGates(gates.result?.items);
  };

  const selectDiaExist = () => {
    if (!selectedSystem || !gates?.length) {
      setDiaExists(false);
      return;
    }

    const diaGate = gates.find(
      (gate) => gate.system_name === selectedSystem.system && gate.is_dia
    );
    setDiaExists(!!diaGate);
  };

  useEffect(() => {
    if (!currentCommandUUID) {
      return;
    }

    getCommandResult(currentCommandUUID);
  }, [currentCommandUUID]);

  useEffect(() => {
    fetchSystems();
    fetchDiaGate();
  }, [tenant]);

  useEffect(() => {
    selectDiaExist();
  }, [gates, selectedSystem]);

  return (
    <LookingGlassContext.Provider
      value={{
        commandOutput,
        commandExecStatus,
        fetchAttempts,
        currentCommandUUID,
        userSystems,
        selectedSystem,
        runCommand,
        setSelectedSystem,
        diaExist,
      }}
    >
      {children}
    </LookingGlassContext.Provider>
  );
};
