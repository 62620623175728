import {
  PricingType,
  TermsAndConditions,
} from "../../components/dialogs/TermsAndPricingDialog";
import { Res } from "../../helpers/api/Api";
import { PaginateListResponse } from "../../helpers/types";
import { PRICING_PRODUCTS } from "./constants";

const getUsableIpCount = (mask: number): number => {
  return Math.pow(2, 32 - mask);
};

export const getTerms = (
  termsState: Res<PaginateListResponse<TermsAndConditions>>,
  product: string | undefined
) => {
  if (!termsState.result?.items.length || !product) return;

  return termsState.result?.items.find((item) =>
    item.related_services.includes(product)
  );
};

const transformPortPricingList = (
  list: Array<PricingType>,
  params: any | undefined
) => {
  const baseQuantity = (params?.bandwidth || 0) / 1000000;
  const location = params?.network_status ?? "";

  let result = list;

  if (baseQuantity) {
    result = result.filter((item) => item.base_quantity === baseQuantity);
  }

  if (location) {
    result = result.filter((item) => item.location === location);
  }

  return result;
};

const transformDiaPricingList = (
  list: Array<PricingType>,
  params: any | undefined
) => {
  const baseQuantity = +params?.ipv4_pool_size.slice(1);

  let result = list;

  result = result.filter(
    (item) => item.base_quantity === baseQuantity && item.base_unit === "subnet"
  );

  if (!result.length) {
    result = list
      .filter((item) => item.base_quantity === 1)
      .map((item) => ({
        ...item,
        monthly_recurring_charge: {
          ...item.monthly_recurring_charge,
          value:
            item.monthly_recurring_charge.value *
            getUsableIpCount(baseQuantity),
        },
      }));
  }

  return result;
};

const transformConnectionPricingList = (
  list: Array<PricingType>,
  params: any | undefined
) => {
  const [baseQuantity, baseUnit] = (params?.max_bandwidth ?? "").split(" ");

  let result = list;

  if (baseQuantity && baseUnit) {
    result = result.filter(
      (item) =>
        item.base_quantity === +baseQuantity && item.base_unit === baseUnit
    );
  }

  return result;
};

const transformTenantServicePackagePricingList = (
  list: Array<PricingType>,
  params: any | undefined
) => {
  const customerPackage = params?.package;

  let result = list;

  if (customerPackage) {
    result = result.filter((item) => item.customer_package === customerPackage);
  }

  return result;
};

export const getPricingList = (
  pricingState: Res<PaginateListResponse<PricingType>>,
  product: string | undefined,
  params: any | undefined
) => {
  if (!pricingState.result?.items.length || !product) return;

  const list = pricingState.result?.items.filter(
    (item) => item.product === product
  );

  let transformedList = list;

  switch (product) {
    case PRICING_PRODUCTS.CUSTOMER_PORT:
      transformedList = transformPortPricingList(list, params);
      break;
    case PRICING_PRODUCTS.DIA:
      transformedList = transformDiaPricingList(list, params);
      break;
    case PRICING_PRODUCTS.DIA_CONNECTION:
    case PRICING_PRODUCTS.DIRECT_CLOUD_LINK:
    case PRICING_PRODUCTS.CR_CLOUD_LINK:
    case PRICING_PRODUCTS.CLOUD_ROUTER:
    case PRICING_PRODUCTS.DIRECT_CONNECTION:
    case PRICING_PRODUCTS.DIRECT_ELAN_CONNECTION:
      transformedList = transformConnectionPricingList(list, params);
      break;
    case PRICING_PRODUCTS.TENANT_SERVICE_PACKAGE:
      transformedList = transformTenantServicePackagePricingList(list, params);
      break;
    default:
      break;
  }

  return [...transformedList].sort(
    (a, b) => a.contract_period - b.contract_period
  );
};
