import React, { FC, useEffect, useMemo, useState } from "react";

import styles from "./ZPort.module.scss";
import {
  ChangeConnectionsFields,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import {
  CLOUD_CONNECTIONS,
  CONNECTIONS_DEFAULTS_FIELDS,
  DIA_UNITED_CONNECTIONS,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/consts";
import { classNames } from "../../../../../helpers/common/classNames";
import { useConnectionConfiguratorContext } from "../../../../ZayoPages/Connections/ConnectionConfigurator/ConnectionConfiguratorContext";
import FormItemRouter from "../../../../ZayoPages/Connections/ConnectionConfigurator/components/ZPort/components/FormItemRouter";
import ConnectionControls from "../../../../ZayoPages/Connections/ConnectionConfigurator/components/ZPort/components/common/ConnectionControls";
import ZPortMenu from "./ZPortMenu/ZPortMenu";
import { TenantCustomerPort, TenantPort } from "../../types";
import { SiteRegion } from "../../../../../helpers/api/apiTypes";
import RegionDropdown from "./RegionDropdown";
import { CUSTOMER_PORTS } from "./consts";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { AZURE_LOWERCASE } from "../../../../../helpers/consts";

type Props = {
  className: string;
  fields: Array<FieldsType>;
  onChange: ChangeConnectionsFields;
  onDelete: (idx: number) => void;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
  withIP?: boolean;
  withoutType?: boolean;
  excluded?: Array<string>;
  ports?: Array<TenantCustomerPort>;
  parentPorts?: Array<TenantPort>;
  selectedPort?: TenantCustomerPort;
  selectPort?: (name: string) => void;
  noControls?: boolean;
  regions: Array<SiteRegion>;
  disabledProp?: boolean;
  onErrorChange?: any;
  addFrom?: string;
  commonFields?: any;
  addPortDisabled?: boolean;
  isZPort?: boolean;
};

const ZPort: FC<Props> = ({
  className,
  fields,
  onChange,
  onDelete,
  isEdit,
  errors,
  handleChangeBW,
  withIP,
  withoutType,
  excluded,
  ports,
  parentPorts,
  selectedPort,
  selectPort,
  noControls,
  regions,
  disabledProp,
  onErrorChange,
  addFrom,
  commonFields,
  addPortDisabled,
  isZPort,
}) => {
  const {
    portType,
    setIsPortTypeDisabled,
    selectedConnections = [],
    getAzureKeyData,
    azureKeyData,
  } = useConnectionConfiguratorContext();
  const { selectedTenant } = useGlobalFilterContext();

  const initialConnection = useMemo(() => {
    const lastInnerType = fields[fields.length - 1].innerType;
    return {
      ...CONNECTIONS_DEFAULTS_FIELDS[lastInnerType],
      isForCreate: true,
    };
  }, [fields]);

  const [portErrors, setPortErrors] = useState<Array<boolean>>(
    new Array(fields.length).fill(false)
  );

  const [isAzureKeyActive, setIsAzureKeyActive] = useState<boolean>(false);

  useEffect(() => {
    let isKeyActive = false;
    commonFields?.connections?.map((connection: any) => {
      if (connection.service_key !== undefined) {
        isKeyActive = true;
      }
    });
    setIsAzureKeyActive(azureKeyData && isKeyActive);
  }, [commonFields?.connections]);

  useEffect(() => {
    if (!azureKeyData) {
      return;
    }
    fields?.map((field, index) => {
      if (field.cloudType === AZURE_LOWERCASE) {
        onChange(index, {
          ...field,
          service_key: azureKeyData.name,
          location: azureKeyData.location,
          max_bandwidth: `${azureKeyData.bandwidth_in_mbps} Mbps`,
          azureConnectionType: "Azure Private",
          peeringLocation: azureKeyData.peering_location,
        });
      }
    });
  }, [azureKeyData]);

  const handlePortErrorChange = (idx: number, error: boolean) => {
    const newErrors = [...portErrors];
    newErrors[idx] = error;
    setPortErrors(newErrors);

    const hasAnyError = newErrors.some((err) => err === true);
    onErrorChange && onErrorChange(hasAnyError);
  };

  const handleChangeInnerType = (innerType: string): void => {
    const lastIdx = fields.length - 1;
    const currentFields = fields[lastIdx];
    const defaultFields = CONNECTIONS_DEFAULTS_FIELDS[innerType];

    const updatedFields = fields.map(() => ({
      ...defaultFields,
      innerType: innerType,
      name: defaultFields.name,
      max_bandwidth: isAzureKeyActive ? currentFields.max_bandwidth : undefined,
    }));

    updatedFields.forEach((field, index) => {
      onChange(index, field);
    });
  };

  const changeGCPRegionForPrimaryKey = (
    newFields: any,
    value: string
  ): FieldsType => {
    const returnedFields = newFields;
    if (typeof value === "string") {
      const match = value.match(/[^/]+\/([^/]+)\/\d+/);
      if (match && match[1]) {
        newFields.region = match[1];
      }
    }

    return returnedFields;
  };

  //Change connection's field
  const handleChangeField = (idx: number, field: string, value: any): void => {
    let newFields = { ...fields[idx], [field]: value } as FieldsType;

    if (field === "cloudType") {
      if (!isAzureKeyActive) {
        newFields.max_bandwidth = undefined;
      }
      newFields.service_key = undefined;
      newFields.peeringLocation = undefined;
      newFields.location = undefined;
      newFields.primary_pairing_key = undefined;
      newFields.secondary_pairing_key = undefined;
    }

    if (field === "primary_pairing_key") {
      newFields = changeGCPRegionForPrimaryKey(newFields, value);
      onChange(idx, newFields);
      return;
    }

    // fixed key length
    if (field === "service_key" && value.length === 36) {
      getAzureKeyData(selectedTenant || "", value);
    }
    onChange(idx, newFields);
  };

  // Add new connection
  const handleAdd = () => {
    setIsPortTypeDisabled(true);
    const lastIdx = fields.length;
    onChange(lastIdx, initialConnection);
  };

  const containerClasses = classNames(className, styles.wrapper);

  const disabled = useMemo(() => {
    return (
      isEdit &&
      (portType.key === "azPorts" ||
        fields.length === selectedConnections.length)
    );
  }, [portType.key, isEdit, fields]);

  return (
    <div className={containerClasses}>
      <ZPortMenu
        selected={fields[fields.length - 1].innerType}
        onSelect={(innerType: string) => handleChangeInnerType(innerType)}
        disabled={disabled || !!addFrom}
        excluded={excluded}
        isAPort={noControls}
      />
      {fields &&
        fields.map((connectionField: FieldsType, idx: number) => {
          let dropdown = undefined;
          if (connectionField.innerType == CLOUD_CONNECTIONS) {
            dropdown = (
              <RegionDropdown
                fields={connectionField}
                onChange={(field: string, value: any) => {
                  handleChangeField(idx, field, value);
                }}
                idx={isZPort ? idx + 1 : idx}
                regions={regions}
                disabled={
                  !!addFrom || connectionField.cloudType === AZURE_LOWERCASE
                }
              />
            );
          }
          return (
            <FormItemRouter
              key={idx + "connection"}
              type={connectionField.innerType}
              fields={connectionField}
              onChange={(field: string, value: any) =>
                handleChangeField(idx, field, value)
              }
              listLength={fields.length - 1}
              isExpandInitial={true}
              idx={isZPort ? idx + 1 : idx}
              onDelete={() => onDelete(idx)}
              isEdit={disabled || idx < selectedConnections.length}
              errors={errors}
              excluded={excluded}
              handleChangeBW={handleChangeBW}
              withIP={withIP}
              withoutType={withoutType}
              ports={
                ports?.filter((port) => port.request_status !== "pending") || []
              }
              parentPorts={parentPorts}
              selectedPort={selectedPort}
              selectPort={selectPort}
              regionDropdown={dropdown}
              addFrom={addFrom}
              disabled={disabledProp}
              onErrorChange={(error: any) => handlePortErrorChange(idx, error)}
              commonFields={commonFields}
              azureKeyData={azureKeyData}
              isAzureKeyActive={isAzureKeyActive}
            />
          );
        })}
      {!addPortDisabled &&
        !noControls &&
        fields[fields.length - 1].innerType === CUSTOMER_PORTS && (
          <ConnectionControls type={"elan"} onChange={handleAdd} />
        )}
    </div>
  );
};

export default ZPort;
