export enum LookingGlassCommands {
  TRACERT = "tracert",
  PING = "ping",
  MTU = "mtu",
}

export type LookingGlassFields = {
  ip: string;
  location: string;
  isDia: boolean;
  cmd: LookingGlassCommands;
  protocol: string;
  max_mtu: number;
  min_mtu: number;
};
