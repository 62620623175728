import { GBPS, KBPS, MBPS } from "./consts";

export function getCorrectBandwidthUnit(
  kbps: number,
  passKbps?: boolean
): string {
  if (kbps >= 1000000) {
    return GBPS;
  } else if (kbps >= 1000 || passKbps) {
    return MBPS;
  } else {
    return KBPS;
  }
}
