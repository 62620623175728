import React, { ReactElement } from "react";
import { IconProps } from "../../../../../../helpers/types";
import { ACCENT } from "../../../../../../helpers/common/colorAliases";

function CheckedIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      className={className}
    >
      <path
        d="M3.95814 6.94946L1.0339 4.04948L0 5.07475L3.95814 9L12 1.02528L10.9661 9.67947e-06L3.95814 6.94946Z"
        fill={color || ACCENT}
      />
    </svg>
  );
}

export default CheckedIcon;
