import React, { FC } from "react";
import GrafanaGraph from "../../components/common/charts/GrafanaGraph";
import LayoutComponent from "../../components/common/LayoutComponent";
import { SystemsTabs } from "../../components/common/navigation/SystemsTabs";

const SitesPage: FC<{ tabs: any }> = ({ tabs }) => {
  return (
    <LayoutComponent>
      <SystemsTabs />
      <GrafanaGraph
        link={`/broker/grafana/d-solo/-U-VYZMHk/service-dashboard?orgId=1&panelId=5&theme=light`}
      />
    </LayoutComponent>
  );
};

export default SitesPage;
