import React, { FC, useMemo } from "react";
import { useVRFContext } from "../../contexts/systemsContext/VRFContext";
import { getDialogControls } from "../../helpers/getDialogControls";
import { useFormField } from "../../helpers/hooks/useFormField";
import { VRFType } from "../../helpers/api/apiTypes";
import Input from "../common/formComponents/Input";
import SummaryInfoBlock from "../leftInfoBlock/SummaryInfoBlock";
import DialogTemplate from "./common/DialogTemplate";
import VRFIcon from "../icons/VRFIcon";
import TextWithIcon from "../common/table/TextWithIcon";
import StatusIcon from "../icons/StatusIcon";
import { capitalizeFirstLetter } from "../../helpers/common/stringHelpers";
import { useOneSystemContext } from "../../contexts/systemsContext/OneSystemContext";
import styles from "./VRFDialog.module.scss";
import DeleteButton from "../common/buttons/DeleteButton";
import { NewDialogProps } from "../../helpers/types";
import { isSame } from "../../helpers/common/submitHelper";
import { useValidation } from "../../helpers/validators/Validator";
import validateVRF from "../../helpers/validators/VRFValidator";
import { formFieldProps } from "../../helpers/dialogs/FieldProps";
import UnifiedTextPair from "../common/UnifiedTextPair";
import { mapStringToItem } from "../common/formComponents/DropDownInput";
import DropdownBasic from "../common/Dropdown/DropdownBasic";
import { Checkbox } from "../common/formComponents/Checkbox";

const DEFAULT_VRF_FIELDS = {
  name: "",
  description: "",
};

const VRF_TYPES = ["internet", "system"];

const DEFAULT_RATE = 10000;

const VRFDialog: FC<NewDialogProps<VRFType>> = ({
  type,
  data,
  onClose,
  onDelete = () => undefined,
}) => {
  const isEdit = type === "edit";
  const {
    add,
    addStatus,
    edit,
    editStatus,
    resetStatus,
    updateNetworkObservability,
    updateDNSObservability,
  } = useVRFContext();
  const [fields, handleFieldChange] = useFormField<any>(
    { ...data, rate: data?.sdr_rate } || DEFAULT_VRF_FIELDS
  );

  const [errors, validate] = useValidation<Partial<VRFType>>(validateVRF, [
    fields,
  ]);
  const disableSubmit = isEdit && isSame(data, fields);
  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const action = isEdit ? edit : add;
      const success = await action(fields, data?.name || "");
      Promise.all([
        updateNetworkObservability({
          ...fields,
          is_sdr: !fields.is_sdr,
          rate: +fields.rate || DEFAULT_RATE,
        }),
        updateDNSObservability(fields),
      ]);
      if (success) onClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  const controls = getDialogControls({
    dialogTitle: isEdit ? "save" : "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: handleClose,
    disableSubmit: disableSubmit,
  });

  const leftColumn = (
    <SummaryBlock
      onDelete={isEdit ? onDelete : undefined}
      title={data?.name || "VRF"}
    />
  );

  return (
    <DialogTemplate
      title={isEdit ? "Edit" : "Add"}
      onClose={onClose}
      controls={controls}
      errorDisplay={addStatus || editStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        {!isEdit && <Input {...fieldProps("name")} label="VRF Name" />}
        <Input
          {...fieldProps("description")}
          label="Description"
          isOptional
          isFullValue
        />
        <DropdownBasic
          id={"vrfDialogVrfType"}
          {...fieldProps("vrf_type")}
          label="VRF type"
          error=""
          itemsList={VRF_TYPES.map(mapStringToItem)}
          onChange={(v) => handleFieldChange("vrf_type", v.key)}
          selected={mapStringToItem(fields.vrf_type || "")}
          isMedium
        />
        {fields.vrf_type === "internet" && (
          <>
            <Checkbox
              isChecked={fields.is_sdr || false}
              label="Network Observability"
              onChange={() => handleFieldChange("is_sdr", !fields.is_sdr)}
            />
            <Input
              label={"Sampling rate"}
              name="sampling_rate"
              value={fields.rate}
              medium
              onChange={(e) => handleFieldChange("rate", e.target.value)}
              error={errors && errors["rate"]}
            />
            <Checkbox
              isChecked={fields.is_dns || false}
              label="DNS Observability"
              onChange={() => handleFieldChange("is_dns", !fields.is_dns)}
            />
          </>
        )}
      </div>
    </DialogTemplate>
  );
};

export default VRFDialog;

type SumProps = {
  title: string;
  onDelete?: () => void;
};

const SummaryBlock: FC<SumProps> = ({ title, onDelete }) => {
  const { system } = useOneSystemContext();
  return (
    <SummaryInfoBlock
      icon={<VRFIcon />}
      title={title}
      withFooter={!!onDelete}
      footer={
        onDelete && (
          <DeleteButton
            id={`vrfDialog-${title}`}
            onClick={onDelete}
            withLabel
          />
        )
      }
    >
      <UnifiedTextPair subtitle={"System"} text={system?.name} />
      <UnifiedTextPair
        subtitle={"Status"}
        text={
          <TextWithIcon
            icon={<StatusIcon />}
            text={capitalizeFirstLetter(system?.status)}
          />
        }
      />
    </SummaryInfoBlock>
  );
};
