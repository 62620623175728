import React, { FC, useEffect, useState } from "react";
import VRFContextContainer from "../../../../contexts/systemsContext/VRFContext";
import styles from "./HAHealthPage.module.scss";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { HAHealthLayout } from "../../../leftInfoBlock/LayoutTwoColumnFactory";
import { PageProps } from "../../../../helpers/types";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { useOneSystemContext } from "../../../../contexts/systemsContext/OneSystemContext";
import { OneSystemTabs } from "../../../common/navigation/OneSystemTabs";
import HAHealthContextContainer, {
  useHAHealthContext,
} from "../../../../contexts/systemsContext/HAHealthContext";
import OkCircleIcon from "../../../icons/OkCircleIcon";
import Input from "../../../common/formComponents/Input";
import TableWrapper from "../../../common/table/newTable/layout/TableWrapper";
import Table from "../../../common/table/newTable/Table";
import TableHeader from "../../../common/table/newTable/rows/TableHeader";
import { haLogsColumns } from "./HALogsColumns";
import LogsHeader from "./LogsHeader";
import { classNames } from "../../../../helpers/common/classNames";
import ActiveNodeIcon from "../../../icons/ActiveNodeIcon";
import TextWithIcon from "../../../common/table/TextWithIcon";
import StatusIcon from "../../../icons/StatusIcon";
import LastFailoverIcon from "../../../icons/LastFailoverIcon";
import StandbyNodeIcon from "../../../icons/StandbyNodeIcon";
import WarningCircleIcon from "../../../icons/WarningCircleIcon";
import ReloadSquareIcon from "../../../icons/ReloadSquareIcon";
import ApplySquareIcon from "../../../icons/ApplySquareIcon";
import {
  getTimeRangeByDiff,
  useTimerangeContext,
} from "../../../../contexts/TimerangeContext";
import { EVENT_START_TIME } from "../../../../contexts/AuthContext";
import { getHAIconFromValue } from "../../../../helpers/getIconFromValue";
import BlankButton from "../../../common/buttons/BlankButton";
import { GREEN } from "../../../../helpers/common/colorAliases";
import HALogs from "./HALogs";
import ConfirmDialog from "../../../dialogs/common/confirmDialog/ConfirmDialog";
import { PageLoader } from "../../../common/loadStates/LoaderIcon";

const HAHealthPage: FC<PageProps> = () => {
  const { node, system } = useOneSystemContext();
  const {
    fetchNodeInfo,
    fetchLogsInfo,
    fetchSystem,
    manualFailover,
    nodeStatusRequest,
    updateTrafficPorts,
    fetchTrafficPorts,
    trafficPorts,
    nodeInfo,
    activeNode,
    standbyNode,
    logsArray,
    logRequestStatus,
    systemFromHA,
    lastFailover,
    selectedLog,
    selectLog,
  } = useHAHealthContext();
  const { timeRange, setTimeRange, setActiveTitle } = useTimerangeContext();
  const vType = node?.type === "nsos-v";
  const sixThousandType = node?.type === "nsos-6000";
  useEffect(() => {
    const fetchData = async () => {
      if (node && system) {
        await fetchSystem(system.name);
        await fetchNodeInfo(node.name);
        fetchTrafficPorts(system.name);
      }
    };
    fetchData();
  }, [activeNode?.name, standbyNode?.name]);

  const searchParams = window.location.search;
  const [hoveredId, setHoveredId] = useState<number | undefined>(undefined);
  const [manualFailoverConfirm, setManualFailoverConfirm] = useState<boolean>(
    false
  );

  useEffect(() => {
    if (searchParams === "?fromIcon") {
      const to = new Date().getTime();
      const from = +(localStorage.getItem(EVENT_START_TIME) || to - 30_000);
      const diff = getTimeRangeByDiff(to - from);
      setTimeRange({
        ...timeRange,
        general: { from: diff[0], to: "now" },
      });
      setActiveTitle(diff[1]);
    }
  }, []);

  useEffect(() => {
    if (node) {
      fetchNodeInfo(node.name);
      fetchLogsInfo(node.name, standbyNode?.name, timeRange.general);
    }
  }, [timeRange.general]);

  const [trafficPortsUp, setTrafficPortsUp] = useState(trafficPorts);

  useEffect(() => {
    setTrafficPortsUp(trafficPorts);
  }, [trafficPorts]);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const handleChangeTrafficPorts = (event: any) => {
    setTrafficPortsUp(Number(event));
  };
  const handleClickRow = (id: number): void => {
    selectLog(selectedLog?.id === id ? undefined : id);
  };
  const nodeInfoData = {
    allTrafficPorts: 0,
    allBackplanePorts: 0,
  };
  if (
    nodeInfo?.traffic_ports_up !== undefined &&
    nodeInfo?.traffic_ports_down !== undefined
  ) {
    nodeInfoData.allTrafficPorts =
      (nodeInfo.traffic_ports_up || 0) + (nodeInfo.traffic_ports_down || 0);
  }

  if (
    nodeInfo?.backplane_ports_up !== undefined &&
    nodeInfo?.backplane_ports_down !== undefined
  ) {
    nodeInfoData.allBackplanePorts =
      (nodeInfo.backplane_ports_up || 0) + (nodeInfo.backplane_ports_down || 0);
  }

  function getHealthStatus(health_score?: number) {
    if (health_score && health_score > 90) {
      return (
        <TextWithIcon
          icon={<StatusIcon color={"var(--green)"} />}
          text={"Healthy"}
          className={styles.healthyColor}
        />
      );
    } else {
      return (
        <TextWithIcon
          icon={<StatusIcon color={"var(--orange)"} />}
          text={"Unhealthy"}
          className={styles.unHealthyColor}
        />
      );
    }
  }

  const handleFailover = async () => {
    if (systemFromHA && activeNode && standbyNode) {
      const res = await manualFailover(systemFromHA.name, standbyNode.name);
      if (res) {
        setManualFailoverConfirm(false);
      }
    }
  };

  if (nodeStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      <HAHealthLayout>
        <div className={styles.contentWrapper}>
          <OneSystemTabs disabledVrf />
          <div>
            <div className={styles.upperBox}>
              <div
                className={classNames(styles.oneUpBox, styles.clickable)}
                onClick={() => {
                  if (activeNode) fetchNodeInfo(activeNode.name);
                }}
              >
                <ActiveNodeIcon />
                <div className={styles.boxMainInfo}>
                  <span className={styles.boxTitle}>Active node</span>
                  <span className={styles.titleText}>
                    {activeNode?.name || "-"}
                  </span>
                  <span className={styles.healthStatus}>
                    {getHealthStatus(activeNode?.health_score)}
                  </span>
                </div>
                <div>{node?.health_score || "-+"}</div>
              </div>
              <div className={styles.oneUpBox}>
                <LastFailoverIcon />
                <div className={styles.boxMainInfo}>
                  <span className={styles.boxTitle}>manual failover</span>
                  <span className={classNames(styles.titleText)}>
                    {lastFailover || "There is no standby node"}
                  </span>
                </div>
                {standbyNode && (
                  <BlankButton
                    id={"failoverBtn"}
                    onClick={() => setManualFailoverConfirm(true)}
                    className={styles.switchBtn}
                    hint={"Switch node to active"}
                  >
                    <StatusIcon color={GREEN} />
                  </BlankButton>
                )}
                {manualFailoverConfirm && (
                  <ConfirmDialog
                    title={"Manual failover"}
                    message={"Confirm manual failover?"}
                    controls={{
                      okText: "Cancel",
                      onOk: () => setManualFailoverConfirm(false),
                      onCancel: handleFailover,
                      cancelText: "Failover",
                    }}
                  />
                )}
              </div>
              <div
                className={classNames(
                  styles.oneUpBox,
                  standbyNode && styles.clickable
                )}
                onClick={() => {
                  if (standbyNode) fetchNodeInfo(standbyNode.name);
                }}
              >
                <StandbyNodeIcon />
                <div className={styles.boxMainInfo}>
                  <span className={styles.boxTitle}>Standby node</span>
                  <span
                    className={classNames(
                      styles.titleText,
                      !standbyNode && styles.notAvailable
                    )}
                  >
                    {standbyNode
                      ? standbyNode.name
                      : "There is no standby node"}
                  </span>
                  <span className={styles.healthStatus}>
                    {standbyNode
                      ? getHealthStatus(standbyNode?.health_score)
                      : ""}
                  </span>
                </div>
                <div>{standbyNode ? standbyNode.health_score : ""}</div>
              </div>
            </div>
            <div className={styles.networkPart}>
              <div className={styles.title}>NETWORK</div>
              <div className={styles.headerNetwork}>
                <span>Metric</span>
                <span>Status</span>
                <span>Configuration</span>
                <ReloadSquareIcon
                  className={styles.clickable}
                  onClick={() => {
                    setTrafficPortsUp(trafficPorts);
                    if (nodeInfo) fetchNodeInfo(nodeInfo?.node_name);
                  }}
                />
                <ApplySquareIcon
                  className={styles.clickable}
                  onClick={() => {
                    if (system && trafficPortsUp) {
                      updateTrafficPorts(system.name, trafficPortsUp);
                    }
                  }}
                />
              </div>
              <div className={styles.oneLine}>
                <span>Traffic Ports up</span>
                <div className={styles.status}>
                  <span>
                    {nodeInfo?.traffic_ports_down === 0 ? (
                      <OkCircleIcon />
                    ) : (
                      <WarningCircleIcon color="var(--pink)" />
                    )}
                  </span>
                  <span>
                    {nodeInfo?.traffic_ports_up || "no data"}/
                    {nodeInfoData.allTrafficPorts || "no data"}
                  </span>
                </div>
                <div className={styles.configuration}>
                  Minimum:
                  <Input
                    key={"traffic_ports_up"}
                    onChange={(e) => handleChangeTrafficPorts(e.target.value)}
                    value={
                      trafficPortsUp === 999 ? "All" : trafficPortsUp || "All"
                    }
                    name={"trafficPortsUp"}
                    placeholder={"All"}
                    medium={true}
                    className={styles.inputAll}
                    type="NUMBER"
                  />
                </div>
              </div>

              {standbyNode?.name && (
                <div className={styles.oneLine}>
                  <span>HA Heartbeat</span>
                  <div>
                    <span className={styles.status}>
                      <OkCircleIcon color="var(--border-color)" />
                      <span>down</span>
                    </span>
                  </div>
                </div>
              )}
              <div className={styles.oneLine}>
                <span>NTP Time</span>
                <div>
                  <span className={styles.status}>
                    {getHAIconFromValue(nodeInfo?.ntp_server)}
                    <span>{nodeInfo?.ntp_server}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.subtitle}>NSOS</div>
            <div className={styles.nextPart}>
              <div className={styles.dataCell}>
                <span>NIRO heartbeat</span>
                <div className={styles.line}>
                  {nodeInfo?.niro_heartbeat && (
                    <>
                      {nodeInfo?.niroHeartbeatIcon === "green" ? (
                        <OkCircleIcon />
                      ) : (
                        <WarningCircleIcon />
                      )}
                      {nodeInfo?.niro_heartbeat}
                    </>
                  )}
                  {!nodeInfo?.niro_heartbeat && (
                    <div className={styles.line}>
                      <WarningCircleIcon /> no data
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.dataCell}>
                <span>Dataplane</span>
                <div className={styles.line}>
                  {getHAIconFromValue(nodeInfo?.dataplane_health)}
                  {nodeInfo?.dataplane_health || "no data"}
                </div>
              </div>
              <div className={styles.dataCell}>
                <span>Management software</span>
                <div className={styles.line}>
                  {nodeInfo?.niroHeartbeatIcon && (
                    <>
                      {nodeInfo?.niroHeartbeatIcon === "green" ? (
                        <>
                          <OkCircleIcon />
                          ok
                        </>
                      ) : (
                        <>
                          <WarningCircleIcon />
                          error
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className={styles.dataCell}>
                <span>Disk free</span>
                <div className={styles.line}>
                  {getHAIconFromValue(String(nodeInfo?.disk_free))}
                  {nodeInfo?.disk_free || "no data"}
                </div>
              </div>
              <div className={styles.dataCell}>
                <span>CPU load</span>
                <div className={styles.line}>
                  {getHAIconFromValue(String(nodeInfo?.cpu_load))}
                  {nodeInfo?.cpu_load || "no data"}
                </div>
              </div>
              <div className={styles.dataCell}></div>
            </div>
            {!vType && <div className={styles.subtitle}>HARDWARE</div>}
            {!vType && (
              <div className={styles.nextPart}>
                {sixThousandType && (
                  <div className={styles.dataCell}>
                    <span>Whitebox switch</span>
                    <div className={styles.line}>
                      {getHAIconFromValue(String(nodeInfo?.white_box))}
                      {nodeInfo?.white_box || "no data"}
                    </div>
                  </div>
                )}
                <div className={styles.dataCell}>
                  <span>Fan Redundancy</span>
                  <div className={styles.line}>
                    {getHAIconFromValue(String(nodeInfo?.fan_redundancy))}
                    {nodeInfo?.fan_redundancy || "no data"}
                  </div>
                </div>
                <div className={styles.dataCell}>
                  <span>Temperature (In)</span>
                  <div className={styles.line}>
                    {getHAIconFromValue(String(nodeInfo?.temp_in))}
                    {nodeInfo?.temp_in || "no data"}
                  </div>
                </div>
                <div className={styles.dataCell}>
                  <span>Temperature (Out)</span>
                  <div className={styles.line}>
                    {getHAIconFromValue(String(nodeInfo?.temp_out))}
                    {nodeInfo?.temp_out || "no data"}
                  </div>
                </div>
                <div className={styles.dataCell}>
                  <span>Power Supply Redundancy</span>
                  <div className={styles.line}>
                    {getHAIconFromValue(String(nodeInfo?.power_supply))}
                    {nodeInfo?.power_supply || "no data"}
                  </div>
                </div>
                <div className={styles.dataCell}>
                  <span>RAID</span>
                  <div className={styles.line}>
                    <OkCircleIcon />
                    {nodeInfo?.raid || "no data"}
                  </div>
                </div>
                {!sixThousandType && <div className={styles.dataCell}></div>}
              </div>
            )}
            <div
              className={classNames(
                styles.wrapper,
                selectedLog && styles.twoCols
              )}
            >
              <LogsHeader
                title={"Health Status Logs"}
                isCollapsible={!!isCollapsed}
                toggleCollapse={() => setIsCollapsed((prev) => !prev)}
                isCollapsed={isCollapsed}
                className={classNames(styles.tableHeader, styles.header)}
              />
              <TableWrapper
                dataStatus={logRequestStatus}
                tablePlaceholder={["Health Status Logs", "Log"]}
                className={(styles.tableWrapper, styles.table)}
              >
                <Table
                  columns={haLogsColumns(hoveredId, selectedLog?.id)}
                  header={TableHeader}
                  gridColumnTemplate={`180px 160px 120px 120px 120px  1fr 120px 40px`}
                  count={20}
                  withFullData
                  data={logsArray || []}
                  totalCount={(logsArray || []).length}
                  onClick={(row) => handleClickRow(row.id)}
                  onHover={(row) => setHoveredId(row?.id)}
                />
              </TableWrapper>
              {selectedLog && <HALogs className={styles.logs} />}
            </div>
          </div>
        </div>
      </HAHealthLayout>
      {!nodeInfo && <PageLoader />}
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(HAHealthPage, [VRFContextContainer, HAHealthContextContainer])
);
