import React, { FC, useEffect, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table, {
  DEFAUTL_OFFSET,
  DEFAUTL_LIMIT,
} from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { withContexts } from "../../helpers/hocs/withContexts";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { pbrTableHeader } from "./table/pbrTableHeader";
import TableControls from "../TenantsPage/table/TableControls";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import PBRConfig from "./PBRConfig/PBRConfig";
import { ADD, EDIT } from "../../helpers/common/constantsAlias";
import FirewallContextContainer, {
  useFirewallContext,
} from "../../contexts/servicesContext/FirewallContext";
import PBRContextContainer, {
  usePBRContext,
} from "../../contexts/servicesContext/PBRContext";
import { PBRType } from "../../helpers/api/apiTypes";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import { NETWORK_RESOURCES } from "../../helpers/navigation/entries";
import { useOneSystemContext } from "../../contexts/systemsContext/OneSystemContext";
import TenantVirtualInterfaceContextContainer, {
  useTenantVirtualInterfaceContext,
} from "../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import { useVRFSelectorContext } from "../../contexts/systemsContext/VRFSelectorContext";

const PBRTable: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchList,
    pbrList,
    pbrListStatus,
    count,
    remove,
    removeStatus,
    changePBREnable,
    fetchGlobalList,
    changePBREnableGlobal,
    globalDelete,
    selectPBR,
    selectedPBR,
    removeDeletedRule,
  } = usePBRContext();

  const { servicesStatusRequest } = useTenantContext();
  const { fetchMembersList } = useTenantVirtualInterfaceContext();
  const isGlobal = window.location.href.includes(NETWORK_RESOURCES().path);

  const { system } = isGlobal ? useOneSystemContext() : { system: undefined };
  const { selectedVRF: vrf } = isGlobal
    ? useVRFSelectorContext()
    : { selectedVRF: undefined };

  const { fetchUserGroups, userGroups } = useFirewallContext();

  const [showAdd, setShowAdd] = useState(false);

  useEffect(() => {
    fetchWrapper();
    if (!system) fetchMembersList(selectedTenant);
    selectedTenant &&
      !userGroups?.tenant.length &&
      fetchUserGroups(selectedTenant);
  }, [selectedTenant, vrf]);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    if (isGlobal) {
      fetchGlobalList(vrf);
    } else {
      selectedTenant && fetchList(selectedTenant, offset, limit);
    }
  };

  const handleClickRow = (pbr: any): void => {
    selectPBR(pbr);
  };

  const [tryDelete, selectedToDelete, sureDeleteProps] = useDeleteDialog<any>(
    isGlobal ? globalDelete : remove,
    removeStatus,
    () => {
      selectPBR(undefined);
      selectedPBR && removeDeletedRule(selectedPBR.name);
    }
  );

  const rowActions: ActionFuncs<PBRType> = useMemo(
    () => ({
      onEdit: handleClickRow,
      onDelete: tryDelete,
    }),
    [handleClickRow, tryDelete]
  );

  if (showAdd) {
    return (
      <PBRConfig
        type={ADD}
        onClose={() => setShowAdd(false)}
        isGlobal={isGlobal}
        system={system}
      />
    );
  }

  if (selectedPBR) {
    return (
      <PBRConfig
        type={EDIT}
        onClose={() => selectPBR(undefined)}
        data={selectedPBR}
        isGlobal={isGlobal}
        system={system}
      />
    );
  }

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  const template = "90px 250px auto repeat(2, 250px) 140px 80px";

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"PBR"}
        name={`${JSON.stringify(selectedToDelete?.name)}`}
      />
      <TableWrapper
        titleProps={{
          title: `Policy Based Routes`,
          children: <TableControls onAdd={() => setShowAdd(true)} noSearch />,
        }}
        dataStatus={pbrListStatus}
        tablePlaceholder={["PBR", "PBR"]}
        isEmpty={!pbrList?.length}
      >
        <Table
          fetchAction={fetchWrapper}
          data={pbrList || []}
          count={count}
          columns={pbrTableHeader()}
          header={TableHeader}
          gridColumnTemplate={template}
          rowActions={rowActions}
          onClick={selectPBR}
          totalCount={pbrList?.length || 0}
          clickFeatures={[
            {
              elementClass: "toggle",
              clb: isGlobal ? changePBREnableGlobal : changePBREnable,
            },
          ]}
        />
      </TableWrapper>
    </>
  );
};

export default withContexts<any>(PBRTable, [
  PBRContextContainer,
  FirewallContextContainer,
  TenantVirtualInterfaceContextContainer,
]);
