import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import Input from "../../../../../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../../../../../components/common/formComponents/RadioGroup";
import CloudSelector from "../../../../common/CloudSelector";
import BWDropdown from "../../../../common/formComponents/BWDropdown";
import EndpointDropdown from "../../../../common/formComponents/EndpointDropdown";
import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
import styles from "./CloudItem.module.scss";
import VPCList from "../../../../common/formComponents/VPCList/VPCList";
import IPAddress from "../../../../common/formComponents/IPAddress";
import { renderCustomerIdLabel } from "../../../../helpers/renderCustomerIdLabel";
import { getConnectionType } from "../../../../helpers/getConnectionType";
import { useUserContext } from "../../../../../../../../contexts/UserContext";
import { Checkbox } from "../../../../../../../../components/common/formComponents/Checkbox";
import { useConnectionsContext } from "../../../../../ConnectionContext";
import { useGlobalFilterContext } from "../../../../../../../../contexts/GlobalFilterContext";
import { useUnitedConnectionContext } from "../../../../../../../Network/UnitedConnections/UnitedConnectionContext";
import { CLOUD_TYPE_GROUP } from "../../../../consts";
import DropdownBasic from "../../../../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../../../../helpers/mapStringToItem";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
  NATIVE,
} from "../../../../../../../../helpers/consts";
import LoaderIcon from "../../../../../../../../components/common/loadStates/LoaderIcon";
import { DropdownItem } from "../../../../../../../../helpers/types";
import { formVlanData } from "../../../../../../../Network/UnitedConnections/components/Clouds/CloudsTabInfo";
import { classNames } from "../../../../../../../../helpers/common/classNames";
import { useConnectionConfiguratorContext } from "../../../../ConnectionConfiguratorContext";

const AZURE_CONNECTION_TYPES = ["Azure Private", "Azure Public", "Microsoft"];

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: () => void;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
  withIP?: boolean;
  withoutType?: boolean;
  regionDropdown?: React.ReactElement;
  addFrom?: string;
  azureKeyData?: any;
  onErrorChange?: any;
  disabled?: boolean;
  isAzureKeyActive?: boolean;
};

const CloudItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  handleChangeBW,
  withIP,
  withoutType,
  regionDropdown,
  addFrom,
  azureKeyData,
  onErrorChange,
  disabled,
  isAzureKeyActive,
}) => {
  const { isDemo } = useUserContext();
  const { fetchData } = useConnectionsContext();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    getAzurePeeringLocations,
    azurePeeringLocations,
  } = useConnectionConfiguratorContext();
  const {
    getRegions,
    regions = [],
    connections,
    fetchConnections,
  } = useUnitedConnectionContext();
  const [isServiceKeyEditable, setIsServiceKeyEditable] = useState(true);
  const [availableClouds, setAvailableClouds] = useState<
    Array<{ value: string; label: string; disabled?: boolean }>
  >(CLOUD_TYPE_GROUP);
  const [vlanList, setVlanList] = useState<Array<string>>([]);
  const [usedVlanListIsVisible, setUsedVlanListIsVisible] = useState<boolean>(
    false
  );
  const [vlanError, setVlanError] = useState<boolean>(false);
  const typingTimeout = useRef<NodeJS.Timeout | null>(null);
  const azureServiceKeyLength = 36; // fixed length for a key

  useEffect(() => {
    selectedTenant && fetchData(selectedTenant);
  }, [selectedTenant]);

  useEffect(() => {
    !regions.length && getRegions();
    !connections.length && fetchConnections();
    !azurePeeringLocations?.length && getAzurePeeringLocations();
  }, []);

  useEffect(() => {
    if (!fields.service_key) {
      setIsServiceKeyEditable(true);
      return;
    }
    if (typingTimeout.current) clearTimeout(typingTimeout.current);

    typingTimeout.current = setTimeout(() => {
      if (fields.service_key?.length === azureServiceKeyLength) {
        setIsServiceKeyEditable(false);
      }
    }, 1000);

    return () => {
      if (typingTimeout.current) clearTimeout(typingTimeout.current);
    };
  }, [fields.service_key]);

  useEffect(() => {
    const updatedClouds = CLOUD_TYPE_GROUP.map((cloud) => {
      const siteWithMatchingOperator = regions.some(
        (site) => site.operator === cloud.value
      );

      return {
        ...cloud,
        disabled: !siteWithMatchingOperator,
      };
    });
    setAvailableClouds(updatedClouds);
  }, [regions]);

  const filteredRegionNames = regions
    .filter((region) => region.cloud_region === fields.location)
    .map((region) => region.name);

  const fullRegionNames = regions.map((region) => region.name);

  const parseAllVlanIds = (
    connection: any,
    selectedPortName: string,
    isCtagInstead?: boolean
  ) => {
    const vlanIds: any = [];

    connection?.members?.forEach((item: any) => {
      if (
        ["port_vlan", "qinq_port_vlan"].includes(item.interface_type) &&
        item.interfaces.length
      ) {
        const vlanInterface = item.interfaces;

        vlanInterface.forEach((el: any) => {
          // if (el.customer_port_name !== selectedPortName) return;

          const vlanData = formVlanData(el);
          const vlanIdToCheck = isCtagInstead
            ? vlanData.vlan_ctag_id
            : vlanData.vlan_id;

          if (vlanIdToCheck && !vlanIds.includes(vlanIdToCheck)) {
            vlanIds.push(vlanIdToCheck);
          }
        });
      }
    });
    return vlanIds;
  };

  useEffect(() => {
    if (!fields.site_name && filteredRegionNames.length > 0) {
      onChange("site_name", filteredRegionNames[0]);
    }
  }, [filteredRegionNames, fields.site_name, onChange]);

  const filteredVlanList = useMemo(() => {
    const vlanList: Array<string> = [];
    const filtered = [...connections];

    filtered.forEach((connection) => {
      const vlanIds = parseAllVlanIds(connection, fields.name, true);
      vlanIds.forEach((vlanId: any) => {
        if (vlanId !== undefined && !vlanList.includes(vlanId)) {
          vlanList.push(String(vlanId));
        }
      });
    });
    return vlanList;
  }, [fields.name, connections.length]);

  const filteredConnections = useMemo(() => {
    const initialConnections = [...connections];
    const filteredConnections: Array<any> = [];

    initialConnections?.map((connection) => {
      connection?.members?.forEach((item: any) => {
        if (
          ["port_vlan", "qinq_port_vlan"].includes(item.interface_type) &&
          item.interfaces.length
        ) {
          const vlanInterface = item.interfaces;

          vlanInterface.forEach((el: any) => {
            if (el.customer_port_name === fields.azure_customer_port_name) {
              filteredConnections.push(connection);
            }
          });
        }
      });
    });
    return filteredConnections;
  }, [connections.length]);

  const azureAvailableConnectionTypes = useMemo(() => {
    let availableArray = [...AZURE_CONNECTION_TYPES];
    filteredConnections?.forEach((connection) => {
      if (!connection.labels?.azureConnectionType) return;
      availableArray = availableArray.filter(
        (item) => item !== connection.labels.azureConnectionType
      );
    });

    return availableArray;
  }, [filteredConnections]);

  const getVlanValue = () => {
    if ((isEdit && fields.isForCreate) || !isEdit) {
      return fields.vlan_id || "";
    }
    return fields.vlan_ctag_id || NATIVE;
  };

  const handleVlanId = (v: string | DropdownItem) => {
    const value = typeof v === "string" ? v : v.key;
    onChange("vlan_id", value);

    const hasError = Boolean(addFrom && filteredVlanList.includes(value));
    setVlanError(hasError);
    onErrorChange(hasError);
  };

  const getVlanDisabledState = () => {
    return isEdit;
  };

  const getAWSFields = () => {
    return (
      <>
        <div className={styles.fields}>
          {!withoutType && (
            <RadioGroup
              label="Connection Type"
              isOneRow
              options={getConnectionType(isDemo)}
              value={fields.Connection_type}
              setActiveValue={(val) => onChange("Connection_type", val)}
              isNotEditable={isEdit}
            />
          )}
          {regionDropdown || (
            <EndpointDropdown
              label={"Region"}
              field={fields.location}
              onChange={(e) => console.log(e, fields)}
              type={type}
              cloudType={fields.cloudType}
              isNotEditable={isEdit || !!addFrom}
              error={errors?.[idx + "location"]}
            />
          )}
          <DropdownBasic
            id={"dropdown-site_name"}
            onChange={(val) => onChange("site_name", val.key)}
            selected={fields.site_name}
            label={"Site Name"}
            itemsList={filteredRegionNames.map(mapStringToItem)}
            isMedium
            disabled={!!addFrom}
          />
          <BWDropdown
            field={fields.max_bandwidth}
            onChange={handleChangeBW}
            error={errors?.[idx + "max_bandwidth"]}
            idx={idx}
            disabled={!!addFrom || isAzureKeyActive}
          />
          {fields.Connection_type === "ipsec" &&
            fields.cloudType === AWS_LOWERCASE && (
              <VPCList
                onChange={onChange}
                externalFields={fields}
                error={errors?.[idx + "VPC_ID"]}
              />
            )}
          {fields.Connection_type === "directConnect" && (
            <>
              <Input
                label={renderCustomerIdLabel(fields.cloudType)}
                name="Customer_ID"
                placeholder=""
                value={fields.account_id}
                medium
                onChange={(e) => onChange("account_id", e.target.value)}
                isNotEditable={isEdit || !!addFrom}
                error={errors?.[idx + "account_id"]}
                errorWithTooltip
              />
            </>
          )}
          <IPAddress
            field={fields.ip_addresses}
            onChange={onChange}
            error={errors?.[idx + "ip_addresses"]}
            withIP={withIP}
          />
        </div>
      </>
    );
  };

  const getGCPFields = () => {
    if (addFrom) {
      return (
        <>
          <div className={styles.fields}>
            <Input
              id={"gcpportname"}
              onChange={(e) =>
                onChange("gcp_customer_port_name", e.target.value)
              }
              value={fields.gcp_customer_port_name}
              label="Port name"
              medium
              name={"Port name"}
              disabled
            />
            <BWDropdown
              field={fields.max_bandwidth}
              onChange={handleChangeBW}
              error={errors?.[idx + "max_bandwidth"]}
              idx={idx}
              disabled={isAzureKeyActive}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <div className={styles.fields}>
          <Input
            id={"pairingkey1"}
            onChange={(e) => onChange("primary_pairing_key", e.target.value)}
            value={fields.primary_pairing_key}
            label="Pairing key-1"
            medium
            name={"Pairing key-1"}
            disabled={!!addFrom}
          />
          <Input
            id={"pairingkey2"}
            onChange={(e) => onChange("secondary_pairing_key", e.target.value)}
            value={fields.secondary_pairing_key}
            label="Pairing key-2"
            isOptional
            medium
            name={"Pairing key-2"}
            disabled={!!addFrom}
          />
        </div>
        <div className={styles.fields}>
          {/* <DropdownBasic
            id={"dropdown-region"}
            onChange={(val) => onChange("region", val.key)}
            selected={fields.region}
            label={"Region"}
            itemsList={fullRegionNames.map(mapStringToItem)}
            isMedium
          /> */}
          <Input
            id={"inputGCPRegion"}
            onChange={(e) => onChange("region", e.target.value)}
            value={fields.region}
            label="Region"
            medium
            name={"Region"}
          />
          <BWDropdown
            field={fields.max_bandwidth}
            onChange={handleChangeBW}
            error={errors?.[idx + "max_bandwidth"]}
            idx={idx}
            disabled={isAzureKeyActive}
          />
        </div>
      </>
    );
  };

  const getVlanError = (idx: number) => {
    if (errors) {
      return errors?.[idx + "vlan_id"];
    }
    return vlanError ||
      (getVlanValue() &&
        (Number(getVlanValue()) < 2 || Number(getVlanValue()) > 4095))
      ? "The value is incorrect or already in use"
      : "";
  };

  const getAzureFields = () => {
    if (!isServiceKeyEditable && !azureKeyData) {
      return (
        <div className={styles.flexCentered}>
          <LoaderIcon />
        </div>
      );
    }
    return (
      <>
        <div className={styles.fields}>
          {!withoutType && (
            <RadioGroup
              label="Connection Type"
              isOneRow
              options={[
                { value: "directConnect", label: "ExpressRoute" },
                { value: "ipsec", label: "IPSEC", disabled: !isDemo },
              ]}
              value={fields.Connection_type}
              setActiveValue={(val) => onChange("Connection_type", val)}
              isNotEditable={isEdit}
            />
          )}
          {addFrom ? (
            <Input
              label={"Port Name"}
              name="Port Name"
              value={fields.azure_customer_port_name}
              medium
              isNotEditable={true}
            />
          ) : (
            <Input
              label={"Service Key"}
              name="Service Key"
              placeholder=""
              value={fields.service_key}
              medium
              onChange={(e) => onChange("service_key", e.target.value)}
              isNotEditable={!!addFrom || !isServiceKeyEditable}
              error={errors?.[idx + "service_key"]}
              errorWithTooltip
            />
          )}
          <BWDropdown
            field={fields.max_bandwidth}
            onChange={(val) => onChange("max_bandwidth", val)}
            error={errors?.[idx + "max_bandwidth"]}
            idx={idx}
            disabled={true}
          />
        </div>
        <div className={styles.fields}>
          <Input
            id={"dropdown-peerLoc"}
            onChange={(e) => onChange("peeringLocation", e.target.value)}
            handleFieldChange={onChange}
            value={fields.peeringLocation}
            label="Peering Location"
            medium
            disabled={true}
            error={
              fields.peeringLocation &&
              azurePeeringLocations?.every(
                (item) => item.peering_location !== fields.peeringLocation
              )
                ? "This location is currently not supported"
                : ""
            }
            errorWithTooltip
            name={"peeringLocation"}
          />
          <DropdownBasic
            id={"dropdown-conType"}
            onChange={(val) => onChange("azureConnectionType", val.key)}
            selected={fields.azureConnectionType}
            label={"Connection Type"}
            itemsList={azureAvailableConnectionTypes.map(mapStringToItem)}
            isMedium
          />
          <div
            className={classNames(
              styles.vlanWrapper,
              !vlanList.length && styles.empty
            )}
          >
            <Input
              id={"vlan_id"}
              onChange={(e) => handleVlanId(e.target.value)}
              handleFieldChange={onChange}
              value={getVlanValue()}
              placeholder="Choose the value in range: 2-4095"
              label="VLAN"
              medium
              disabled={getVlanDisabledState()}
              error={getVlanError(idx)}
              errorWithTooltip
              name={"vlan_id"}
              listWrapperClass={styles.vlanList}
              onClick={() => setUsedVlanListIsVisible(!usedVlanListIsVisible)}
              onBlur={() => setUsedVlanListIsVisible(false)}
            />
            {usedVlanListIsVisible && connections.length && addFrom ? (
              <div className={styles.usedVlan}>
                <div className={styles.title}>In use:</div>
                {filteredVlanList.map(
                  (vlan_id) =>
                    vlan_id !== undefined && <div key={vlan_id}>{vlan_id}</div>
                )}
              </div>
            ) : null}
          </div>
          <IPAddress
            field={fields.ip_addresses}
            onChange={onChange}
            error={errors?.[idx + "ip_addresses"]}
            withIP={withIP}
          />
        </div>
      </>
    );
  };

  const renderDataByCloudType = (type: string) => {
    switch (type) {
      case AWS_LOWERCASE:
        return getAWSFields();
      case AZURE_LOWERCASE:
        return getAzureFields();
      case GCP_LOWERCASE:
        return getGCPFields();
      default:
        return getAWSFields();
    }
  };

  return (
    <ItemContainer
      type={type}
      title={fields.cloudType}
      idx={idx}
      onDelete={onDelete}
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      <CloudSelector
        selected={fields.cloudType}
        onSelect={(val) => onChange("cloudType", val)}
        isOneRow
        disabled={isEdit || !!addFrom}
        providedClouds={availableClouds}
      />
      {renderDataByCloudType(fields.cloudType)}
    </ItemContainer>
  );
};

export default CloudItem;
