import { FC, useState } from "react";
import { SectionHeader } from "../../../../components/common/SectionHeader/SectionHeader";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { VirtualInterfaceApi } from "../../../VirtualInterfacePage/types";
import { getStatus } from "../../Connections/components/StateCell";
import { useNetworkContext } from "../../context/NetworkContext";
import { StatHealthcheck } from "./StatHealthcheck";
import styles from "./StatSection.module.scss";
import TotalTraffic from "../../../TopologyPage/components/rightPart/components/charts/TotalTraffic";
import InterfaceTraffic from "../../../TopologyPage/components/rightPart/components/charts/InterfaceTraffic";
import { ConnectionLabelType } from "../../types/connections";
import { getIconAndText } from "../../Connections/helpers/getConnectionType";

type InterfaceProps = {
  connection?: VirtualInterfaceApi;
  title?: string;
  type: "interface";
  name?: string;
  remote_type?: ConnectionLabelType;
};

type TotalProps = {
  connection?: never;
  title?: string;
  type: "total";
  name?: string;
  remote_type?: ConnectionLabelType;
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const getConnectionIconByType = (
  connection: VirtualInterfaceApi,
  remote_type?: ConnectionLabelType
) => {
  const [icon, _] = getIconAndText(
    connection.type,
    connection.virtual_interface_type,
    remote_type,
    connection.is_ipsec_service,
    connection.is_nat_service,
    connection.is_snat_service,
    undefined,
    undefined,
    connection.is_dia
  );
  return icon;
};

type StatSectionProps = InterfaceProps | TotalProps;

export const StatSection: FC<StatSectionProps> = ({
  connection,
  title,
  name,
  type,
  remote_type,
}) => {
  const [isVisible, toggle] = useState(true);
  const { selectedTenant } = useGlobalFilterContext();
  const { trafficType } = useNetworkContext();
  const isRemote = remote_type === "remote_users";

  return (
    <div className={styles.statSection}>
      {title && (
        <SectionHeader title={title} onClick={() => toggle(!isVisible)} />
      )}
      <div className={styles.statContent}>
        <div className={styles.statInfo}>
          <div className={styles.statInfoTitle}>
            {connection && getConnectionIconByType(connection, remote_type)}
            <span className={(isRemote && styles.remote) || ""}>
              {(isRemote && "Remote users") || name}
            </span>
          </div>
          {connection && (
            <StatHealthcheck
              admin={getStatus(connection.administrative_state)}
              oper={getStatus(connection.operational_state)}
            />
          )}
        </div>
        {type === "total" ? (
          <TotalTraffic
            traffic={trafficType}
            className={styles.grafana}
            tenantName={selectedTenant}
          />
        ) : (
          connection && (
            <InterfaceTraffic
              traffic={trafficType}
              interfaceName={(isRemote && "Remote users") || connection.name}
              isDia={connection.is_dia}
              tenantName={selectedTenant}
              className={styles.grafana}
            />
          )
        )}
      </div>
    </div>
  );
};

const StatGraph: FC = () => {
  return (
    <div className={styles.graphWrapper}>
      <img className={styles.statGraph} src="/interface-statistics.png" />
      <div className={styles.graphInfo}>
        {/* <StatValue label="Tunnel Peer" value="22.211.8.4" />
        <StatValue label="Tunnel Peer" value="22.211.8.4" />
        <StatValue label="Tunnel Peer" value="22.211.8.4" /> */}
      </div>
    </div>
  );
};

type StatValueProps = {
  label: string;
  value: string | number;
};

const StatValue: FC<StatValueProps> = ({ label, value }) => {
  return (
    <div className={styles.statValueWrapper}>
      <span className={styles.labelValue}>{label}:</span>
      <span className={styles.statValue}>{value}</span>
    </div>
  );
};
