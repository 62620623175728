import React, { FC } from "react";
import TenantsPageContent from "./TenantsPageContent";
import LayoutComponent from "../../components/common/LayoutComponent";
import { withContexts } from "../../helpers/hocs/withContexts";
import TenantsContext from "./TenantsContext";
import { TenantsTab } from "../../components/common/navigation/Tenants/TenantsTab";
import ConnectionsContext from "../WizardToolPage/ConnectionStep/ConnectionsContext";

const TenantsPage: FC = () => {
  return (
    <LayoutComponent>
      <TenantsTab />
      <TenantsPageContent />
    </LayoutComponent>
  );
};
export default withContexts(TenantsPage, [TenantsContext, ConnectionsContext]);
