import { VirtualInetrfaceDIA } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";

export const getGroupedPublicIPsList = (
  diaList: Array<VirtualInetrfaceDIA>
): { [key: string]: Array<VirtualInetrfaceDIA> } => {
  return diaList.reduce(
    (
      acc: { [key: string]: Array<VirtualInetrfaceDIA> },
      dia: VirtualInetrfaceDIA
    ) => {
      const { location } = dia;
      if (!acc[location]) {
        acc[location] = [];
      }
      acc[location].push(dia);
      return acc;
    },
    {}
  );
};
