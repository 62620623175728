import {
  SortType,
  SystemOverviewType,
  PaginateListResponse,
  OneSystemType,
} from "./apiTypes";
import Api, { PRes } from "./Api";
import getManualSortedData from "../getManualSortedData";
import { getSystemsConnectivity } from "../getSystemsConnectivity";
import { NIRO, NODES, SYSTEM, VERSION_PATH } from "./apiPaths";

import {
  System,
  SystemAPI,
  SystemNodeEdit,
} from "../../pages/Systems/Provisioning/types";
import {
  appendActiveNodeData,
  configAccessor,
  configSystemAccessor,
} from "../appendActiveNodeData";
import { UUID, UUIDMessage } from "./types";
import {
  AwsCloudCredentials,
  AzureCloudCredentials,
  GCPCloudCredentials,
} from "../../pages/CloudResourcesPage/types";

type Res<P> = {
  ok: boolean;
  error?: string;
  result?: P;
  count?: number;
  publicIp?: string;
  systemConfig?: string;
};
type PaginalRes<P> = Res<PaginateListResponse<P>>;

export class SystemApi extends Api {
  private _systemPath = `system`;
  constructor() {
    super();
  }

  //NIRO VERSION
  async getVersion(): Promise<any> {
    return await this.fetchBroker<any>(VERSION_PATH);
  }

  async getSystemsList(
    sortBy?: SortType,
    offset?: number,
    limit?: number
  ): Promise<Res<Array<System | SystemAPI>>> {
    const res = await this.fetchBroker<PaginateListResponse<SystemAPI>>(
      SYSTEM.LIST(),
      { query: { offset: offset, limit: limit } }
    );
    const fullData = await appendActiveNodeData(res.result?.items || []);
    const configIP = await configAccessor(res.result?.items || []);
    const configSystem = await configSystemAccessor(res.result?.items || []);

    const systemsToUse =
      fullData.length > 0
        ? getManualSortedData(fullData, sortBy)
        : res.result?.items || [];

    return {
      ...res,
      result: systemsToUse,
      count: res.result?.total_count || 0,
      publicIp: configIP,
      systemConfig: configSystem,
    };
  }

  async getSystemsAPIList(sortBy?: SortType): Promise<Res<Array<SystemAPI>>> {
    const res = await this.fetchBroker<PaginateListResponse<SystemAPI>>(
      SYSTEM.LIST()
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getSystemsOverviewList(
    sortBy: SortType
  ): Promise<PaginalRes<SystemOverviewType>> {
    const data = getSystemsConnectivity("");
    return {
      ok: true,
      result: {
        count: data.length,
        items: getManualSortedData(data, sortBy),
        total_count: data.length,
      },
    };
  }

  async getSystemById(id: string): Promise<Res<SystemAPI>> {
    return await this.fetchBroker<SystemAPI>(SYSTEM.GET(id));
  }

  async addSystem(params: Partial<OneSystemType>): Promise<any> {
    return await this.fetchBroker(SYSTEM.ADD(), {
      body: JSON.stringify(params),
    });
  }

  async updateSystem(name: string, params: any): Promise<any> {
    return await this.fetchBroker(SYSTEM.EDIT(name), {
      body: JSON.stringify(params),
    });
  }

  async updateNode(name: string, params: SystemNodeEdit): Promise<any> {
    return await this.fetchBroker(NODES.EDIT(name), {
      body: JSON.stringify(params),
    });
  }

  async getNonAssignedNodes(): Promise<any> {
    return await this.fetchBroker(NODES.GET_NON_ASSIGNED_NODES());
  }

  async systemFailover(
    name: string,
    params: {
      active_node: string;
    }
  ): Promise<any> {
    return await this.fetchBroker(SYSTEM.FAILOVER(name), {
      body: JSON.stringify(params),
    });
  }

  async deleteSystem(name: string): Promise<any> {
    return await this.fetchBroker(SYSTEM.DELETE(name));
  }

  async getSystemVersion(id: string): Promise<any> {
    return await this.fetchBroker<OneSystemType>(SYSTEM.VERSION(id));
  }

  async resetSystem(id: string): Promise<any> {
    return await this.fetchBroker<OneSystemType>(SYSTEM.RESET(id));
  }
  async factoryResetSystem(id: string): Promise<Res<string>> {
    return await this.fetchBroker<string>(SYSTEM.FACTORY_RESET(id));
  }

  async factoryResetNiro(): Promise<Res<string>> {
    return await this.fetchBroker<string>(NIRO.FACTORY_RESET());
  }
  async niroExport(): Promise<Res<UUIDMessage>> {
    return await this.fetchBroker(NIRO.EXPORT_CONFIG("zip"));
  }

  async downloadConfigByUUID(uuid: UUID): Promise<Response> {
    return await this.fetchBrokerFull(NIRO.DOWNLOAD_CONFIG(uuid), {
      responseType: "blob",
    });
  }

  async niroImport(file: string, type: string): Promise<Res<string>> {
    const formData = new FormData();
    formData.append("config", file);
    const res = await this.postFileToBroker(NIRO.IMPORT_CONFIG(type), {
      body: formData,
    });

    return res;
  }

  async getHaConf(id: string): Promise<any> {
    return await this.fetchBroker(SYSTEM.GET_HA(id));
  }

  async updateHaConf(name: string, params: any): Promise<any> {
    return await this.fetchBroker(SYSTEM.UPDATE_HA(name), {
      body: JSON.stringify(params),
    });
  }

  async getAwsKeys(): PRes<PaginateListResponse<AwsCloudCredentials>> {
    return await this.fetchBroker(NIRO.GET_AWS_KEYS());
  }

  async createAwsKey(params?: AwsCloudCredentials): PRes<any> {
    return await this.fetchBroker(NIRO.CREATE_AWS_KEY(), {
      body: JSON.stringify(params),
    });
  }

  async deleteAwsKey(id?: number): PRes<any> {
    return await this.fetchBroker(NIRO.DELETE_AWS_KEY(id));
  }

  async updateAwsKey(args?: {
    id?: number;
    params?: AwsCloudCredentials;
  }): PRes<any> {
    return await this.fetchBroker(NIRO.UPDATE_AWS_KEY(args?.id), {
      body: JSON.stringify(args?.params),
    });
  }

  async getAzureKeys(): PRes<PaginateListResponse<AzureCloudCredentials>> {
    return await this.fetchBroker(NIRO.GET_AZURE_KEYS());
  }

  async createAzureKey(params?: AzureCloudCredentials): PRes<any> {
    return await this.fetchBroker(NIRO.CREATE_AZURE_KEY(), {
      body: JSON.stringify(params),
    });
  }

  async deleteAzureKey(id?: number): PRes<any> {
    return await this.fetchBroker(NIRO.DELETE_AZURE_KEY(id));
  }

  async updateAzureKey(args?: {
    id?: number;
    params?: AzureCloudCredentials;
  }): PRes<any> {
    return await this.fetchBroker(NIRO.UPDATE_AZURE_KEY(args?.id), {
      body: JSON.stringify(args?.params),
    });
  }

  async getGCPKeys(): PRes<PaginateListResponse<GCPCloudCredentials>> {
    return await this.fetchBroker(NIRO.GET_GCP_KEYS());
  }

  async createGCPKey(params?: GCPCloudCredentials): PRes<any> {
    return await this.fetchBroker(NIRO.CREATE_GCP_KEY(), {
      body: JSON.stringify(params),
    });
  }

  async deleteGCPKey(id?: number): PRes<any> {
    return await this.fetchBroker(NIRO.DELETE_GCP_KEY(id));
  }

  async updateGCPKey(args?: {
    id?: number;
    params?: GCPCloudCredentials;
  }): PRes<any> {
    return await this.fetchBroker(NIRO.UPDATE_GCP_KEY(args?.id), {
      body: JSON.stringify(args?.params),
    });
  }
}

export const systemApi = new SystemApi();
