import { CellProps } from "react-table";
import { FWFilters, PBMType, PBRType } from "../../../helpers/api/apiTypes";
import styles from "./pbmTableHeader.module.scss";
import { Toggle } from "../../../components/common/Toggle";
import Label from "../../../components/common/table/Label";
import LabelOutline from "../../../components/common/table/LabelOutline";
import {
  getUnitTitle,
  PBM_UNIT,
  prepareUnit,
  tcpCheckboxes,
} from "../PBMConfig/PBMConfig";
import { parseFilters } from "./parseFilters";
import { getDst, getSrc } from "./commonFilterView";
import ErrorCircleIconFilled from "../../../components/icons/ErrorCircleIconFilled";
import { GREY_DISABLED } from "../../../helpers/common/colorAliases";
import OkCircleIconFilled from "../../../components/icons/OkCircleIconFilled";

export const pbmTableHeader = (): Array<any> => {
  return [
    {
      Header: "Enabled",
      accessor: "enable",
      Cell: ({ cell: { value } }: CellProps<PBRType>) => {
        return (
          <div className={styles.toggle}>
            <Toggle
              isChecked={value}
              onChange={() => {
                return;
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Meter",
      accessor: (row: PBMType) => [row.count, row.unit],
      Cell: ({ cell: { value } }: CellProps<PBRType>) => {
        const [meter, count] = prepareUnit(value[1], value[0]);
        const formatted =
          meter === PBM_UNIT.UNSPECIFIED
            ? meter.toUpperCase()
            : `${count} ${getUnitTitle(meter as string)}`;
        return <Label className={styles.noBack}>{formatted}</Label>;
      },
    },
    {
      Header: "Policy",
      accessor: "filters",
      Cell: ({ cell: { value } }: CellProps<PBRType>) => {
        const filters = value as Array<FWFilters>;
        const parsedFilters = parseFilters(filters);

        return (
          <div className={styles.filtersBlock}>
            <div className={styles.policyWrapper}>
              {getSrc(parsedFilters)}
              {getDst(parsedFilters)}
              {parsedFilters.fragmented && (
                <LabelOutline className={styles.subFilter} type="lightBlue">
                  FRAGMENTED
                </LabelOutline>
              )}
            </div>
            <div className={styles.policyWrapper}>
              {parsedFilters.hasFlags && (
                <LabelOutline type="lightBlue" className={styles.subFilter}>
                  {parsedFilters.flags?.map((flag, i) => {
                    const val = tcpCheckboxes[i].toUpperCase();
                    if (flag !== undefined) {
                      return (
                        <span key={val}>
                          {flag ? (
                            <OkCircleIconFilled color={GREY_DISABLED} />
                          ) : (
                            <ErrorCircleIconFilled color={GREY_DISABLED} />
                          )}
                          <span>{val}</span>
                        </span>
                      );
                    }
                  })}
                </LabelOutline>
              )}
              {parsedFilters.ttl && (
                <LabelOutline type="lightBlue" className={styles.subFilter}>
                  {`TTL: ${parsedFilters.ttl[0]}-${parsedFilters.ttl[1]}`}
                </LabelOutline>
              )}
              {parsedFilters.tos && (
                <LabelOutline type="lightBlue" className={styles.subFilter}>
                  {`DSCP: ${parsedFilters.dscp}, ECN: ${parsedFilters.esn}`}
                </LabelOutline>
              )}
              {parsedFilters.ingress_vi && (
                <LabelOutline type="font">
                  {"ingress: "}
                  {parsedFilters.ingress_vi}
                </LabelOutline>
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Priority",
      accessor: "priority",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof PBRType,
  }));
};
