import { FC, useEffect } from "react";
import styles from "./ServicesTabInfo.module.scss";
import { classNames } from "../../../../../helpers/common/classNames";
import {
  ServicesPreferencesContextContainer,
  useServicesPreferencesContext,
} from "../../../../ServicesPreferencesPage/ServicesPreferencesContext";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import TenantConcumersInfo from "../../../../ServicesPreferencesPage/components/TenantConsumersInfo";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import ServicesGroup from "../../../../ServicesPreferencesPage/components/ServicesGroup";
import { withContexts } from "../../../../../helpers/hocs/withContexts";
import { ServicesPackages } from "../../../../ServicesPreferencesPage/components/ServicesPackages";
import { usePackagesContext } from "../../../../../contexts/PackagesContext";

type Props = {
  noInfoIcon?: boolean;
};

const ServicesTabInfo: FC<Props> = ({ noInfoIcon }) => {
  const {
    fetchServices,
    fetchTenantConsumersCount,
    groupedList,
    fetchStatus,
    selectedService,
  } = useServicesPreferencesContext();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    packagesList,
    isPackagesVisible,
    packagesListState,
  } = usePackagesContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchServices(selectedTenant);
      fetchTenantConsumersCount(selectedTenant);
    }
  }, [selectedTenant]);

  return (
    <div
      className={classNames(styles.wrapper, selectedService && styles.twoCols)}
    >
      <div className={styles.tableWrapper}>
        <div className={styles.header}>
          {isPackagesVisible ? "Packages" : "Services"}
          {selectedTenant && <TenantConcumersInfo />}
        </div>
        {fetchStatus?.state === "pending" ||
        !selectedTenant ||
        packagesListState?.isLoading ? (
          <PageLoader />
        ) : isPackagesVisible ? (
          <ServicesPackages packages={packagesList} noInfoIcon={noInfoIcon} />
        ) : (
          <div className={styles.listWrapper}>
            {Object.keys(groupedList).map((g, i) => (
              <ServicesGroup
                key={g + i}
                title={g}
                services={groupedList[g]}
                className={styles.group}
                tenant={selectedTenant}
                noInfoIcon={noInfoIcon}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default withContexts<Props>(ServicesTabInfo, [
  ServicesPreferencesContextContainer,
]);
