import React from "react";
import styles from "./TableWrapper.module.scss";
import TitleBlock, { TitleProps } from "./TitleBlock";
import {
  OverlayedRightBlockType,
  RequestStatus,
} from "../../../../../helpers/types";
import DataPlaceHolders from "../../../loadStates/DataPlaceHolder";
import OverlayedRightBlock from "../../OverlayedRightBlock";
import { classNames } from "../../../../../helpers/common/classNames";
type Props = {
  titleProps?: TitleProps;
  dataStatus?: RequestStatus;
  tablePlaceholder?: [multi: string, single: string];
  isEmpty?: boolean;
  overlayedRightBlock?: OverlayedRightBlockType;
  className?: string;
};
const TableWrapper: React.FC<Props> = ({
  children,
  titleProps,
  dataStatus,
  tablePlaceholder,
  isEmpty,
  overlayedRightBlock,
  className,
}) => {
  // const showData = isOk(dataStatus) && !isEmpty;
  const showData = !isEmpty;

  return (
    <div className={classNames(styles.wrapper, className)}>
      {titleProps && !titleProps.noTitle && <TitleBlock {...titleProps} />}
      {showData ? (
        children
      ) : (
        <TablePlaceHolder
          dataStatus={dataStatus}
          tablePlaceholder={tablePlaceholder}
          isEmpty={isEmpty}
        />
      )}
      {overlayedRightBlock && (
        <OverlayedRightBlock blockProps={overlayedRightBlock} />
      )}
    </div>
  );
};

type PlaceHolderProps = {
  dataStatus?: RequestStatus;
  tablePlaceholder?: [multi: string, single: string];
  isEmpty?: boolean;
};
const TablePlaceHolder: React.FC<PlaceHolderProps> = ({
  dataStatus,
  tablePlaceholder,
  isEmpty,
}) => {
  return (
    <DataPlaceHolders
      isEmpty={isEmpty}
      status={dataStatus}
      emptyTitle={tablePlaceholder && `There are no ${tablePlaceholder[0]} yet`}
      emptySubTitle={
        tablePlaceholder &&
        `Use the Add button to add new ${tablePlaceholder[1]}`
      }
      errorTitle={`Get Data Error`}
    />
  );
};

export default TableWrapper;
