/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { ReactChild, ReactElement, useEffect } from "react";
import {
  Column,
  useRowSelect,
  useSortBy,
  useRowState,
  useTable,
  useExpanded,
  usePagination,
} from "react-table";
import { classNames } from "../../../helpers/common/classNames";
import { HIGHLIGHTED_ROW_ID } from "../../../helpers/common/constantsAlias";
import IndeterminateCheckbox from "./IndeterminateCheckbox";
import styles from "./Table.module.scss";
import Paginator from "./Paginator";

import TableColumnHeaders from "./TableColumnHeaders";
import TableRow from "./TableRow";
import OverlayedRightBlock from "./OverlayedRightBlock";
import { OverlayedRightBlockType, RequestStatus } from "../../../helpers/types";
import { isOk } from "../../../helpers/common/RequestStateHelpers";
import DataPlaceHolders from "../loadStates/DataPlaceHolder";
import { isNotEmpty } from "../../../helpers/common/arrayHelpers";

type Props<D extends object> = {
  data: Array<D>;
  columns: ReadonlyArray<Column<D>>;
  header: React.ReactElement | string;
  headerControls?: ReactChild;
  // fetchData: (page: number) => void,
  // loading: boolean,
  // pageCount: number,
  gridColumnTemplate?: string;
  parentGridColumnTemplate?: string;
  isRowClickable?: boolean;
  onClick?: (arg: any) => void;
  handleSelectRows?: (arg: string) => void;
  isTableWithCheckbox?: boolean;
  onSort?: (_: { id: string; desc: boolean } | undefined) => void;
  controls?: {
    id: string;
    Header: string;
    accessor: string;
    Cell: ({ row }: any) => JSX.Element | "";
  };
  withoutPagination?: boolean;
  onHover?: (id: number | undefined) => void;
  disabledRows?: Array<number | undefined>;
  isLastRowHighlighted?: boolean;
  isAlignTop?: boolean;
  rowsWithDisabledHover?: Array<string | number>;
  autoExpandedRows?: { [key: string]: boolean };
  updateData?: any;
  overlayedRightBlock?: OverlayedRightBlockType;
  tablePlaceholder?: [multi: string, single: string];
  dataStatus?: RequestStatus;
  isParentRowBasic?: boolean;
};

export const CHECKBOX_ID = "selection";

export default function Table<D extends object>(props: Props<D>): ReactElement {
  const {
    data,
    columns,
    header,
    headerControls,
    gridColumnTemplate,
    parentGridColumnTemplate,
    handleSelectRows,
    isTableWithCheckbox,
    controls,
    onSort,
    withoutPagination,
    disabledRows,
    rowsWithDisabledHover,
    autoExpandedRows,
    updateData,
    overlayedRightBlock,
    tablePlaceholder,
    dataStatus,
    isParentRowBasic,
    ...restProps
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    state: { sortBy, pageSize },
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      autoResetExpanded: false,
      autoResetPage: false,
      initialState: {
        expanded: autoExpandedRows || {},
        pageIndex: 0,
      },
      updateData,
    },
    useRowState,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      // Add extra cells to each rows here
      controls &&
        hooks.visibleColumns.push((columns) => {
          return [...columns, controls];
        });
      isTableWithCheckbox &&
        hooks.visibleColumns.push((columns) => [
          {
            id: CHECKBOX_ID,
            // eslint-disable-next-line react/prop-types
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }: { row: Row<D> }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
            disableSortBy: true,
          },
          ...columns,
        ]);
    }
  );

  useEffect(() => {
    if (isNotEmpty(selectedFlatRows)) {
      handleSelectRows(selectedFlatRows);
    }
  }, [selectedFlatRows, selectedFlatRows.length]);

  useEffect(() => {
    onSort && onSort(...sortBy);
  }, [sortBy]);

  const gridStyle = {
    gridTemplateColumns: gridColumnTemplate || `repeat(${columns.length}, 1fr)`,
  };

  const tableStyle = classNames(
    styles.table,
    rows.length < pageSize && !withoutPagination && styles.tableWithoutFooter
  );

  // console.log("TABLE OLD");
  return (
    <div {...getTableProps()} className={tableStyle}>
      <div className={styles.tableHeader}>
        {header}
        {headerControls}
      </div>

      {/*{...column.getHeaderProps(column.getSortByToggleProps())}*/}
      {headerGroups.map((headerGroup, i) => {
        return (
          <TableColumnHeaders
            key={i}
            headerGroup={headerGroup}
            grid={gridStyle}
            withSort={!!onSort}
          />
        );
      })}

      <div {...getTableBodyProps()} className={styles.tableBody}>
        {isOk(dataStatus) &&
          rows.map((row) => {
            const rowStyles = classNames(
              styles.row,
              rowsWithDisabledHover?.includes(row.original.id) &&
                styles.editableMode
            );
            return (
              <TableRow
                key={row.original.id || row.id}
                prepareRow={prepareRow}
                row={row}
                grid={{ gridStyle, parentGridColumnTemplate }}
                className={rowStyles}
                isRowDisabled={disabledRows?.includes(row.original.id)}
                isRowHighlighted={row.original.id === HIGHLIGHTED_ROW_ID}
                isParentRowBasic={isParentRowBasic}
                {...restProps}
              />
            );
          })}
        {(!isOk(dataStatus) || data.length === 0) && (
          <DataPlaceHolders
            isEmpty={data.length === 0}
            status={dataStatus}
            emptyTitle={
              tablePlaceholder && `There are no ${tablePlaceholder[0]} yet`
            }
            emptySubTitle={
              tablePlaceholder &&
              `Use the Add button to add new ${tablePlaceholder[1]}`
            }
            errorTitle={`Get Data Error`}
          />
        )}
      </div>
      {/* {(rows.length >= pageSize || withoutPagination) && ( */}
      {true && (
        <div className={styles.tableFooter}>
          {!withoutPagination && (
            <Paginator
              page={page}
              rowsAmount={rows.length}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          )}
        </div>
      )}
      {overlayedRightBlock && (
        <OverlayedRightBlock blockProps={overlayedRightBlock} />
      )}
    </div>
  );
}
