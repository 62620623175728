import React, { ChangeEvent, FC, useRef, useState } from "react";
import { useNiroContext } from "../../contexts/NiroContext";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import BlankButton from "../common/buttons/BlankButton";
import IconBlankButton from "../common/buttons/IconBlankButton";
import LabelOutline from "../common/table/LabelOutline";
import TitleWithIcon from "../common/TitleWithIcon";
import CloseIcon from "../icons/CloseIcon";
import ConfigRestoreIcon from "../icons/configIcons/ConfigRestoreIcon";
import FileIcon from "../icons/FileIcon";
import DialogTemplateOneColumn from "./common/DialogTemplateOneColumn";
import styles from "./UploadConfigDialog.module.scss";

type Props = {
  onClose: () => void;
};

const FORMATS_LIST = [".zip", ".gz", ".json"];

const UploadConfigDialog: FC<Props> = ({ onClose }) => {
  const { importConfig } = useNiroContext();

  const [file, setFile] = useState<Array<HTMLFormElement> | undefined>();
  const [type, setType] = useState<string>("zip");

  const onClick = async () => {
    if (!(file && file[0] && type)) return;

    const format = TYPE_MAP[type];
    const res = await importConfig(file[0], format);
    if (res.ok) {
      onClose();
    }
  };

  const controls = {
    okText: "Continue",
    onOk: onClick,
    cancelText: "Cancel",
    onCancel: onClose,
  };

  return (
    <DialogTemplateOneColumn
      header={
        <TitleWithIcon
          isSmaller
          icon={<ConfigRestoreIcon color={GREY_FONT} />}
          title={"Upload configuration"}
        />
      }
      controls={controls}
      onClose={onClose}
    >
      <div className={styles.wrapper}>
        <div className={styles.title}>
          Please select the config file to upload.
        </div>
        {file ? (
          <File file={file[0]} setFile={setFile} />
        ) : (
          <BrowseBtn setFile={setFile} setType={setType} />
        )}
      </div>
    </DialogTemplateOneColumn>
  );
};
export default UploadConfigDialog;

type BrowseBtnProps = {
  setFile: (arg: any) => void;
  setType: (val: string) => void;
};
const BrowseBtn: FC<BrowseBtnProps> = ({ setFile, setType }) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick: () => void = () => {
    hiddenFileInput.current && hiddenFileInput.current.click();
  };
  const handleChange: (e: ChangeEvent) => void = (e) => {
    const fileUploaded = (e.target as HTMLInputElement).files;
    if (fileUploaded) {
      setType(fileUploaded[0].type);
      setFile(fileUploaded);
    }
  };

  return (
    <>
      <BlankButton
        id={"uploadConfigBrowse"}
        onClick={handleClick}
        className={styles.btn}
      >
        Browse
      </BlankButton>
      <input
        type="file"
        ref={hiddenFileInput}
        style={{ display: "none" }}
        onChange={handleChange}
        accept={FORMATS_LIST.join(",")}
      />
      <div className={styles.hints}>
        {FORMATS_LIST.map((format) => (
          <LabelOutline type={"hint"} key={format}>
            {format}
          </LabelOutline>
        ))}
      </div>
    </>
  );
};

const File: FC<{ file: any; setFile: (file: any) => void }> = ({
  file,
  setFile,
}) => {
  return (
    <>
      <div className={styles.fileWrapper}>
        <FileIcon />
        <span>{file.name}</span>
      </div>
      <div className={styles.progressBar}>
        <div className={styles.bgBar}>
          <div className={styles.dynamicBar} />
        </div>
        <IconBlankButton
          id={"uploadConfigDialogClose"}
          icon={CloseIcon}
          onClick={() => setFile(undefined)}
        />
      </div>
    </>
  );
};

export const TYPE_MAP: { [key: string]: string } = {
  "application/x-gzip": "gz",
  "application/x-zip-compressed": "zip",
  "application/zip": "zip",
  "application/json": "json",
};
