import React, { useMemo } from "react";
import { ACCENT } from "../../../helpers/common/colorAliases";
import styles from "./AddButton.module.scss";
import Button, { ButtonProps } from "./Button";
import LoaderIcon from "../loadStates/LoaderIcon";
import KeyIcon from "../../icons/KeyIcon";

type KeyButtonType = {
  isIconOnly?: boolean;
  isTextOnly?: boolean;
  disabled?: boolean;
  label?: string;
  className?: string;
  isLoading?: boolean;
} & Omit<ButtonProps, "children">;

export const KeyButton = ({
  isIconOnly,
  isTextOnly,
  label,
  className,
  isLoading,
  ...buttonProps
}: KeyButtonType) => {
  const buttonContent = useMemo(() => {
    const content = [];
    if (isLoading) {
      return <LoaderIcon />;
    }

    if (!isTextOnly) {
      content.push(<KeyIcon color={ACCENT} />);
    }

    if (!isIconOnly) {
      content.push(<span className={styles.btnText}>{label}</span>);
    }

    return content;
  }, [isLoading, isTextOnly, isIconOnly, label]);

  return (
    <Button isBtnSmall={isIconOnly} className={className} {...buttonProps}>
      {buttonContent}
    </Button>
  );
};
