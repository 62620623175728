import {
  Layer3InterfaceNew,
  Layer3InterfaceApiNew,
} from "./../pages/Layer3Interface/types";
import { configApi } from "./api/ConfigApi";
import { VIRTUAL_INTERFACE_GATE } from "./consts";
import { flatMembersList } from "./flatMembersList";

export const getCombinedVirtualInterfacesNAT = async (
  list: Array<Layer3InterfaceApiNew> | undefined,
  tenant: string,
  system?: string
): Promise<Array<Layer3InterfaceNew>> => {
  if (!list || list.length === 0) return [];
  const tunnelsRes = await configApi.getWireGuardStatus(tenant, system);
  const newList: Array<Layer3InterfaceNew> = [];
  const systems: Array<string | null> = [];
  const flattedList = flatMembersList(list)?.map((vi) => {
    if (!systems.includes(vi.system_name)) systems.push(vi.system_name);
    return {
      ...vi,
      tunnelAmount: tunnelsRes.result?.items.filter(
        (tunnel: any) =>
          tunnel.status === "UP" && tunnel.system_name === vi.system_name
      ).length,
    };
  });

  systems.map((sys) => {
    const viListWithoutNat = flattedList.filter(
      (vi) => vi.system_name === sys && vi.type !== GATE_TYPE
    );
    const internetVI = getInternetVI(flattedList, sys);
    const isNatRules =
      viListWithoutNat.length <
      flattedList.filter((vi) => vi.system_name === sys).length;

    const sysList =
      internetVI && isNatRules
        ? [...viListWithoutNat, internetVI]
        : viListWithoutNat;
    newList.push(...sysList);
  });

  return newList;
};

const GATE_TYPE = VIRTUAL_INTERFACE_GATE;

const INTERNET_TYPE_BODY = {
  id: 1000000,
  name: "Internet",
  ip_addresses: [],
  mtu: 9000,
  administrative_state: "up",
  operational_state: null,
  description: "internet",
  members: [],
  system_name: null,
  network_segments: [],
  type: VIRTUAL_INTERFACE_GATE,
  labels: { remote_type: "internet" },
};

const getInternetVI = (
  list: Array<Layer3InterfaceNew>,
  system_name: string | null
): Layer3InterfaceNew | undefined => {
  const natList = [];
  const ip_addresses: Array<string> = [];
  list.forEach((item) => {
    if (item.type === GATE_TYPE) {
      natList.push(item);
      item?.ip_addresses?.forEach((ip) => {
        if (!ip_addresses.includes(ip)) ip_addresses.push(ip);
      });
    }
  });

  return natList.length > 0
    ? { ...INTERNET_TYPE_BODY, system_name, ip_addresses }
    : undefined;
};
