import React, { FC } from "react";
import styles from "./ServicesGroup.module.scss";
import { Service } from "../types";
import ServicesItem from "./ServiceItem";
import { classNames } from "../../../helpers/common/classNames";

type Props = {
  title: string;
  services: Array<Service>;
  tenant: string;
  className: string;
  noInfoIcon?: boolean;
};

const ServicesGroup: FC<Props> = ({
  title,
  services,
  tenant,
  className,
  noInfoIcon,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.title}>{title}</div>
      <div className={styles.group}>
        {services.map((s, i) => {
          if (s.type === "ids") return null;
          return (
            <ServicesItem
              key={s.type + i}
              service={s}
              className={styles.row}
              tenant={tenant}
              noInfoIcon={noInfoIcon}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ServicesGroup;
