import React, { FC } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import { CLOUD_TYPE_GROUP } from "../consts";
import styles from "./CloudSelector.module.scss";
import CloudProviderIcon from "../../../../../components/icons/CloudProviderIcon";

type Props = {
  selected: string;
  onSelect: (val: string) => void;
  isOneRow?: boolean;
  disabled?: boolean;
  providedClouds?: Array<{ value: string; label: string; disabled?: boolean }>;
};

const CloudSelector: FC<Props> = ({
  selected,
  onSelect,
  isOneRow,
  disabled,
  providedClouds,
}) => {
  const wrapperClasses = classNames(styles.wrapper, isOneRow && styles.oneRow);
  const clouds = providedClouds || CLOUD_TYPE_GROUP;
  return (
    <div className={wrapperClasses}>
      {clouds.map((cloud) => {
        const isActive: boolean = cloud.value === selected;
        return (
          <div
            key={cloud.value}
            onClick={() => !disabled && onSelect(cloud.value)}
            className={classNames(
              styles.item,
              isActive && styles.active,
              (disabled || cloud?.disabled) && styles.disabled
            )}
          >
            <CloudProviderIcon id={cloud.value} />
          </div>
        );
      })}
    </div>
  );
};

export default CloudSelector;
