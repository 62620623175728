import React, { FC } from "react";
import { getMenuIcon } from "../getMenuIcon";
import styles from "./ZPortMenu.module.scss";
import { classNames } from "../../../../../../helpers/common/classNames";
import TextWithIcon from "../../../../../../components/common/table/TextWithIcon";
import {
  ACCENT,
  GREY_DISABLED,
} from "../../../../../../helpers/common/colorAliases";
import { Z_PORT_MENU, A_PORT_MENU } from "../consts";

type Props = {
  selected: string;
  onSelect: (item: string) => void;
  disabled?: boolean;
  excluded?: Array<string>;
  isAPort?: boolean;
};

const ZPortMenu: FC<Props> = ({
  selected,
  onSelect,
  disabled,
  excluded,
  isAPort,
}) => {
  return (
    <ul className={styles.wrapper}>
      {(isAPort ? A_PORT_MENU : Z_PORT_MENU).map((item) => {
        if (excluded?.length && excluded.includes(item.key)) return null;
        const isActive = item.key === selected;
        const Icon = getMenuIcon(item.key);
        return (
          <li
            key={item.value}
            onClick={() => !disabled && onSelect(item.key)}
            className={classNames(
              styles.liItem,
              isActive && styles.active,
              disabled && styles.disabled
            )}
          >
            <TextWithIcon
              text={item.value}
              icon={<Icon color={isActive ? ACCENT : GREY_DISABLED} />}
            />
          </li>
        );
      })}
    </ul>
  );
};
export default ZPortMenu;
