/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { Res } from "../../helpers/api/Api";
import { configApi } from "../../helpers/api/ConfigApi";
import { RequestStatus } from "../../helpers/types";
import { CreateDIA, CreateIpam, PublicIpManagementRecord } from "./types";
import { DEFAUTL_OFFSET } from "../../components/common/table/newTable/Table";

type IFunc = {
  fetchIpamData: () => void;
  createIpam: (ipam: CreateIpam) => Promise<boolean>;
  createDIAIpRange: (ipam: CreateIpam) => Promise<boolean>;
  deleteIpam: (ipam: PublicIpManagementRecord) => Promise<Res<any>>;
};

type IState = {
  ipamData: Array<any>;
  ipamFetchStatus: RequestStatus;
  ipamCreateStatus: RequestStatus;
  ipamDeleteStatus: RequestStatus;
};

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const [IpamContext, useContext] = createContextAndUse<IState, IFunc>();
export const useIpamContext = useContext;

export const IpamContextContainer: FC<Props> = ({ children }) => {
  const [ipamData, setIpamData] = useState<Array<any>>([]);
  const [ipamFetchStatus, setIpamFetchStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [ipamCreateStatus, setIpamCreateStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [ipamDeleteStatus, setIpamDeleteStatus] = useState<RequestStatus>({
    state: "idle",
  });

  const deleteIpam = async (ipam: any) => {
    if (ipam.iconType === "naas") {
      const response = await configApi.deletePublicIpRange(ipam.name);
      if (!response.ok || response.error) {
        setIpamDeleteStatus({ state: "error", message: response.error });
        return response;
      }
      setIpamDeleteStatus({ state: "ok" });
      setIpamData((ipams) => ipams.filter((p) => p.name !== ipam.name));
      return response;
    } else {
      const response = await configApi.deleteIpam(ipam.id);
      if (!response.ok || response.error) {
        setIpamDeleteStatus({ state: "error", message: response.error });
        return response;
      }
      setIpamDeleteStatus({ state: "ok" });
      setIpamData((ipams) => ipams.filter((p) => p.id !== ipam.id));
      return response;
    }
  };

  const fetchIpamData = async () => {
    setIpamFetchStatus({ state: "pending" });
    let mapNaasList = [];
    let mapDiaList = [];

    // NAAS LIST
    const { ok, result, error } = await configApi.getPublicIpRange();
    if (result) {
      mapNaasList = result.items.map((naas: any) => ({
        ...naas,
        iconType: "naas",
        allocated: "32",
        is_ipsec: naas.ipsec_service,
        is_nat: naas.nat_service,
        is_snat: naas.snat_service,
        usage: "",
        tenants: "",
      }));

      // POOL USAGE
      const usageRes = await configApi.usageIpam(DEFAUTL_OFFSET, 0);
      if (usageRes.result?.items) {
        const filteredNoParantsUsage = usageRes.result.items.filter(
          (dia: any) => dia.parent_ipam_id === null
        );

        // DIA LIST
        const res = await configApi.getIpam();
        if (res.result.items) {
          const filteredNoParantsDia = res.result.items.filter(
            (dia: any) => dia.parent_ipam_id === null
          );
          mapDiaList = filteredNoParantsDia.map((dia: any) => ({
            ...dia,
            iconType: "dia",
            name: dia.labels.description ?? `Pool${dia.id}`,
            from_ip: dia.prefix_length
              ? dia.ip + "/" + dia.prefix_length
              : dia.ip,
            allocated: dia.min_allocated_pfl + "-" + dia.max_allocated_pfl,
            vrf_name: dia.vrf_name,
            is_ipsec: dia.ipsec_service,
            is_nat: dia.nat_service,
            is_snat: dia.snat_service,
            usage: filteredNoParantsUsage.find(
              (usage: any) => usage.id === dia.id
            ).prefixes,
            tenants: res.result.items.filter(
              (dia: any) => dia.parent_ipam_id === dia.id
            )?.count,
            shared: dia.shared,
          }));
        }
      }

      if (!ok || error) {
        setIpamFetchStatus({ state: "error", message: error });
        return;
      }

      const data = [...mapNaasList, ...mapDiaList];
      setIpamData(data || []);
      setIpamFetchStatus({ state: "ok" });
    }
  };

  const createIpam = async (ipam: CreateIpam): Promise<boolean> => {
    setIpamCreateStatus({ state: "pending" });
    const { ok, error } = await configApi.createPublicIpRange(ipam);
    if (!ok || error) {
      setIpamCreateStatus({ state: "error", message: error });
      return false;
    }
    setIpamCreateStatus({ state: "ok" });
    fetchIpamData();
    return true;
  };

  const createDIAIpRange = async (ipam: CreateIpam): Promise<boolean> => {
    setIpamCreateStatus({ state: "pending" });
    const ipParts = ipam.from_ip.split("/");
    const reqBody: CreateDIA = {
      ip: ipParts[0],
      prefix_length: Number(ipParts[1]),
      type: ipam.type,
      data: ipam.data,
      vrf_name: ipam.vrf_name,
      dia_service: true,
      ipsec_service: ipam.ipsec_service,
      nat_service: ipam.nat_service,
      snat_service: ipam.snat_service,
      shared: false,
      blocked: false,
      min_allocated_pfl: ipam.min_allocated_pfl ? +ipam.min_allocated_pfl : 0,
      max_allocated_pfl: ipam.max_allocated_pfl ? +ipam.max_allocated_pfl : 0,
      labels: {
        description: ipam.name,
      },
    };
    const { ok, error } = await configApi.createIpam(reqBody);
    if (!ok || error) {
      setIpamCreateStatus({ state: "error", message: error });
      return false;
    }
    setIpamCreateStatus({ state: "ok" });
    fetchIpamData();
    return true;
  };

  return (
    <IpamContext.Provider
      value={{
        ipamData,
        ipamFetchStatus,
        ipamCreateStatus,
        ipamDeleteStatus,
        fetchIpamData,
        createIpam,
        createDIAIpRange,
        deleteIpam,
      }}
    >
      {children}
    </IpamContext.Provider>
  );
};
