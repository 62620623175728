import React, { FC, useEffect, useMemo } from "react";
import { getDialogControls } from "../../helpers/getDialogControls";
import { useFormField } from "../../helpers/hooks/useFormField";
import { StaticRouteType } from "../../helpers/api/apiTypes";
import SummaryInfoBlock from "../leftInfoBlock/SummaryInfoBlock";
import DialogTemplate from "./common/DialogTemplate";
import Input from "../common/formComponents/Input";
import DropdownBasic from "../common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../helpers/mapStringToItem";
import { useValidation } from "../../helpers/validators/Validator";
import { NewDialogProps } from "../../helpers/types";
import { formFieldProps } from "../../helpers/dialogs/FieldProps";
import UnifiedTextPair from "../common/UnifiedTextPair";
import VLANIcon from "../icons/VLANIcon";

import styles from "./StaticRouteDialog.module.scss";
import validateStaticRoutes from "../../helpers/validators/StaticRoutesValidator";
import { useStaticRouteContext } from "../../contexts/systemsContext/StaticRouteContext";

type ExtendedCreateType = StaticRouteType & {
  vrf: string;
};

const DEFAULT_FIELDS: ExtendedCreateType = {
  vrf: "",
  destination: "",
  gateway_ip_address: "",
  distance: 1,
  description: "",
  enable: true,
};

const StaticRouteDialog: FC<NewDialogProps<StaticRouteType>> = ({
  onClose,
}) => {
  const {
    add,
    addStatus,
    resetStatus,
    vrfList,
    fetchVRFList,
  } = useStaticRouteContext();

  const [fields, handleFieldChange] = useFormField<ExtendedCreateType>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<ExtendedCreateType>(
    validateStaticRoutes,
    [fields]
  );

  useEffect(() => {
    fetchVRFList();
  }, []);

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const success = await add(fields, fields.vrf);
      if (success) onClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: handleClose,
  });

  const leftColumn = <SummaryBlock title={"Static Route"} data={fields} />;

  return (
    <DialogTemplate
      title={"Add Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={addStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <DropdownBasic
        id={"staticRouteDialogVRF"}
        onChange={(val) => handleFieldChange("vrf", val.key)}
        selected={mapStringToItem(fields.vrf)}
        label="VRF"
        itemsList={vrfList?.map((vrf) => mapStringToItem(vrf?.name))}
        isMedium
        error={errors && errors["vrf"]}
      />
      <Input
        {...fieldProps("destination")}
        label="Destination"
        placeholder="X.X.X.X/X"
        error={errors && errors["destination"]}
      />
      <Input
        {...fieldProps("gateway_ip_address")}
        label="Gateway"
        placeholder="X.X.X.X or X::X"
        error={errors && errors["gateway"]}
        onBlur={validate}
      />
      <Input
        {...fieldProps("distance")}
        label="Metric"
        error={errors && errors["distance"]}
      />
      <Input
        {...fieldProps("description")}
        label="Description"
        isFullValue
        error={errors && errors["description"]}
      />
    </DialogTemplate>
  );
};

export default StaticRouteDialog;

type SumProps = {
  title: string;
  data: ExtendedCreateType;
};

const SummaryBlock: FC<SumProps> = ({ title, data }) => {
  return (
    <SummaryInfoBlock icon={<VLANIcon />} title={title}>
      <UnifiedTextPair subtitle="Tenant" text={data?.vrf} />
      <UnifiedTextPair subtitle="Gateway" text={data.gateway_ip_address} />
    </SummaryInfoBlock>
  );
};
