import { useCallback, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../../helpers/navigation";
import { CONFIGURATION } from "../../../helpers/navigation/entries";
import { PageProps, PathEntry } from "../../../helpers/types";
import { Routes } from "../../common";
import { RouteEntry } from "../../types";
import ConnectionsContent from "./ConnectionsContent";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";

const UnitedConnections: React.FC<PageProps> = ({ tabs }: { tabs: any }) => {
  const path = useMatchPath();
  const {
    selectedTenant,
    setSelectedTenantFromStorage,
  } = useGlobalFilterContext();

  useEffect(() => {
    if (!selectedTenant) setSelectedTenantFromStorage();
  }, []);

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={fullPath(CONFIGURATION())} />
      </Route>
      <Routes
        routes={connectionsRoutes}
        tabs={tabs}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default UnitedConnections;

const ConnectionsPage: React.FC<PageProps> = () => {
  return <ConnectionsContent />;
};

const connectionsRoutes: Array<RouteEntry> = [
  {
    pathFunc: CONFIGURATION,
    component: ConnectionsPage,
  },
];
