import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { IUserContext, withUserContextProps } from "../../contexts/UserContext";
import { FWRuleType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { networkApi } from "../../helpers/api/networkApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { generatePriority } from "../Firewall/config/FirewallRuleConfig";

import { SegmentRule } from "./components/SegmentRulesTable/types";
import { CUSTOM_RULE_BODY, predefinedRulesList } from "./helpers/consts";
import { createAllowAllRule } from "./helpers/createAllowAllRule";
import { createFWRule } from "./helpers/createFWRule";
import { getSegmentsName } from "./helpers/getSegmentsNames";
import { getSegmentsRulesFromFirewall } from "./helpers/getSegmentsRulesFromFirewall";
import { isAllowAllExist } from "./helpers/isAllowAllExist";

export type Segment = {
  id: number;
  isProtected: boolean;
  isAllowed: boolean;
  name: string;
};
type RequestStatuses = {
  segmentsStatus?: RequestStatus;
  rulesStatus?: RequestStatus;
};

export type SegmentsRules = {
  allowAll: Array<SegmentRule>;
  predefined: Array<SegmentRule>;
  custom: Array<SegmentRule>;
};

type State = {
  segments: Array<Segment>;
  selectedSegment?: { x: number; y: number; id: number; status?: string };
  allSegmentsRules?: SegmentsRules;
  predefinedRules: Array<SegmentRule>;
  customRules: Array<SegmentRule>;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchSegments: (tenant: string) => Promise<void>;
  updateSegment: (
    id: number,
    propertyName: keyof Segment,
    newValue: any
  ) => void;
  setSegment: (
    arg: { x: number; y: number; id: number; status?: string } | undefined
  ) => void;

  fetchSegmentsData: (tenant: string) => Promise<void>;
  fetchSegmentsRules: (tenant: string) => Promise<void>;
  setPredefinedRules: (rules: Array<SegmentRule>) => void;
  addCustomRule: (priority?: number) => void;
  changeCustomRule: (id: number, field: string, value: string) => void;
  createFirewallRules: (
    srcSegment: string,
    dstSegment: string,
    tenant: string
  ) => Promise<void>;
  getPredefinedList: (selectedSegment: {
    x: number;
    y: number;
    id: number;
  }) => void;
  getCustomRules: (
    selectedSegment: { x: number; y: number; id: number },
    tenant: string
  ) => void;
  deleteAllRules: (
    selectedSegment: { x: number; y: number; id: number },
    tenant?: string
  ) => Promise<boolean>;
  createAllowAllRule: (
    selectedSegment: { x: number; y: number; id: number },
    tenant?: string
  ) => Promise<boolean>;
};

const [SegmentsRulesContext, useSegmentsRulesContext] = createContextAndUse<
  IState,
  IFunc
>();
export { useSegmentsRulesContext };

class SegmentsRulesContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IUserContext
> {
  Context = SegmentsRulesContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      segments: [],
      predefinedRules: [],
      customRules: [],
    };
  }

  fetchSegmentsData = async (tenant: string): Promise<void> => {
    this.fetchSegments(tenant);
    this.fetchSegmentsRules(tenant);
  };

  // Segments
  fetchSegments = async (tenant: string): Promise<void> => {
    this.setState({ segmentsStatus: setPending() });
    const { ok, result } = await networkApi.getSegmentations(tenant);
    if (ok && result) {
      this.setState({
        segments: result.items.map((el, id) => ({
          isProtected: false,
          name: el.name,
          isAllowed: false,
          id,
        })),
      });
    }
  };

  updateSegment = (id: number, property: keyof Segment, value: any): void => {
    this.setState({
      segments: this.state.segments.map((s) => ({
        ...s,
        [property]: s.id === id ? value : s[property],
      })),
    });
  };

  setSegment = (
    segment: { x: number; y: number; id: number; status?: string } | undefined
  ): void => {
    this.setState({
      predefinedRules: [],
      customRules: [],
      selectedSegment: segment,
    });
  };

  // Segmnets Rules
  fetchSegmentsRules = async (tenant: string): Promise<void> => {
    const { ok, result } = await configApi.getFWRules(tenant);
    if (ok && result) {
      const allSegmentsRules = getSegmentsRulesFromFirewall(result);
      this.setState({ allSegmentsRules });
    }
  };

  // Predefined rules
  getPredefinedList = (selectedSegment: {
    x: number;
    y: number;
    id: number;
  }): void => {
    const segments = getSegmentsName(this.state.segments, selectedSegment);
    if (!segments) return;
    const { srcSegment, dstSegment } = segments;

    const predefined = this.state.allSegmentsRules?.predefined;
    const isAllowAll = isAllowAllExist(
      srcSegment,
      dstSegment,
      this.state.allSegmentsRules?.allowAll
    );
    const predefinedRules = predefinedRulesList.map((rule) => {
      if (!isAllowAll && predefined) {
        const exsitingRule = predefined.find(
          (el) =>
            el.dstSegment === dstSegment &&
            el.srcSegment === srcSegment &&
            el.priority === rule.priority
        );
        rule.enabled = Boolean(exsitingRule);
      }
      return { ...rule, srcSegment, dstSegment };
    });
    this.setState({ predefinedRules });
  };

  setPredefinedRules = (predefinedRules: Array<SegmentRule>): void => {
    this.setState({ predefinedRules });
  };

  // Custom rules
  getCustomRules = (
    selectedSegment: {
      x: number;
      y: number;
      id: number;
    },
    tenant: string
  ): void => {
    const segments = getSegmentsName(this.state.segments, selectedSegment);
    if (!segments) return;
    const { srcSegment, dstSegment } = segments;

    const customRules = this.state.allSegmentsRules?.custom.filter(
      (el) => el.srcSegment === srcSegment && el.dstSegment === dstSegment
    );
    if (!customRules || customRules.length === 0) {
      this.addFirstCustomRule(tenant);
      return;
    }
    this.setState({ customRules });
  };

  addFirstCustomRule = async (tenant: string): Promise<void> => {
    const fwRules = await configApi.getFWRules(tenant);
    const priority = generatePriority(fwRules.result || []);
    const rule = { ...CUSTOM_RULE_BODY, priority };
    this.setState({ customRules: [rule] });
  };

  addCustomRule = (): void => {
    const currRules = this.state.customRules;
    const id = currRules.length;
    const allPriorities = currRules.map((el) => el.priority);
    const priority = Math.max(...allPriorities) + 1;
    const customRules = [...currRules, { ...CUSTOM_RULE_BODY, id, priority }];
    this.setState({ customRules });
  };

  changeCustomRule = (id: number, field: string, value: string): void => {
    const currRules = [...this.state.customRules];
    currRules[id][field] = value;
    this.setState({ customRules: currRules });
  };

  // Segments -> Firewall rules config
  createFirewallRules = async (
    srcSegment: string,
    dstSegment: string,
    tenant: string
  ): Promise<void> => {
    const isDeny = srcSegment !== dstSegment;
    const predefinedRules = isDeny
      ? []
      : this.state.predefinedRules?.filter((rule) => rule.enabled);
    const customRules = this.state.customRules?.filter(
      (rule) =>
        rule.name &&
        !this.state.allSegmentsRules?.custom.find((el) => el.name === rule.name)
    );
    const combinedRules = [...predefinedRules, ...customRules];

    if (combinedRules.length === 0) return;

    const newRules = combinedRules.map((rule) =>
      createFWRule(rule, srcSegment, dstSegment)
    );
    const res = await this.deleteFwRule(srcSegment, dstSegment, tenant);

    if (res) {
      const newRulesRes: Array<any> = [];
      await Promise.all(
        newRules.map(
          async (rule): Promise<any> => {
            const res = await configApi.addFWRule(tenant, rule);
            newRulesRes.push(res);
          }
        )
      );
      this.setState({ rulesStatus: resToState(newRulesRes[0]) });
    }
    this.fetchSegmentsData(tenant);
  };

  deleteFwRule = async (
    srcSegment: string,
    dstSegment: string,
    tenant: string
  ): Promise<boolean> => {
    const ruleName = this.state.allSegmentsRules?.allowAll.find(
      (el) => el.srcSegment === srcSegment && el.dstSegment === dstSegment
    )?.name;

    if (ruleName) {
      const res = await configApi.deleteFWRule(tenant, ruleName);
      if (!res.ok) this.setState({ rulesStatus: resToState(res) });
      return res.ok;
    }

    return true;
  };

  deleteAllRules = async (
    selectedSegment: { x: number; y: number; id: number },
    tenant?: string
  ): Promise<boolean> => {
    const segments = getSegmentsName(this.state.segments, selectedSegment);
    if (!segments || !tenant) return false;

    const { ok, result } = await configApi.getFWRules(tenant);
    if (!ok) return false;

    const { srcSegment, dstSegment } = segments;
    const filteredRules: Array<FWRuleType> = (result || []).filter((rule) => {
      const filters = rule.filters;
      const src = filters.find((el) => el.filter_type === "src_segment")
        ?.values;
      const dst = filters.find((el) => el.filter_type === "dst_segment")
        ?.values;
      return src === srcSegment && dst === dstSegment;
    });

    await Promise.all(
      filteredRules.map(
        async (rule): Promise<any> =>
          await configApi.deleteFWRule(tenant, rule.name)
      )
    );

    return true;
  };

  createAllowAllRule = async (
    selectedSegment: { x: number; y: number; id: number },
    tenant?: string
  ): Promise<boolean> => {
    const segments = getSegmentsName(this.state.segments, selectedSegment);
    if (!segments || !tenant) return false;

    const deleteRes = await this.deleteAllRules(selectedSegment, tenant);
    if (!deleteRes) return deleteRes;

    const rule = createAllowAllRule(segments.srcSegment, segments.dstSegment);
    const createRes = await configApi.addFWRule(tenant, rule);
    return createRes.ok;
  };

  funcs = {
    fetchSegments: this.fetchSegments,
    updateSegment: this.updateSegment,
    setSegment: this.setSegment,
    fetchSegmentsData: this.fetchSegmentsData,
    fetchSegmentsRules: this.fetchSegmentsRules,
    setPredefinedRules: this.setPredefinedRules,
    addCustomRule: this.addCustomRule,
    changeCustomRule: this.changeCustomRule,
    createFirewallRules: this.createFirewallRules,
    getPredefinedList: this.getPredefinedList,
    getCustomRules: this.getCustomRules,
    deleteAllRules: this.deleteAllRules,
    createAllowAllRule: this.createAllowAllRule,
  };
}

export default withUserContextProps<any>(SegmentsRulesContextContainer);
