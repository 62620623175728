import { ValidatorResponse } from "../validators/Validator";
import { capitalizeFirstLetter } from "../common/stringHelpers";

export type SetField<T = string> = (
  name: T,
  value: string | Array<string> | {} | undefined
) => void;

export type ResetField = (arg: { [key: string]: any }) => void;

export type PropsFormer<P, T = string> = (
  name: keyof P
) => {
  name: keyof P;
  value: any;
  error?: string;
  handleFieldChange: SetField<T>;
  medium: boolean;
  label: string;
};

export function formFieldProps<P extends { [key: string]: any }, T = string>(
  fields: P,
  onChange: SetField<T>,
  errors: ValidatorResponse["errors"] | undefined
): PropsFormer<P, T> {
  return (name: keyof P) => {
    return {
      name,
      value: fields[name],
      error: errors && errors[name as string],
      handleFieldChange: onChange,
      label: capitalizeFirstLetter(name as string),
      medium: true,
    };
  };
}
