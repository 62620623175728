import { classNames } from "../../../helpers/common/classNames";
import styles from "./WrappedText.module.scss";

type WrappedTextProps = {
  value: string;
  width: number | string;
  className?: string;
};

export const WrappedText = ({ value, width, className }: WrappedTextProps) => {
  return (
    <div
      className={classNames(styles.wrappedText, className)}
      style={{
        width: typeof width === "number" ? `${width}px` : width,
      }}
      title={value}
    >
      {value}
    </div>
  );
};
