import React, { FC } from "react";
import TextWithIcon from "../../../../../../../../components/common/table/TextWithIcon";
import { classNames } from "../../../../../../../../helpers/common/classNames";
import {
  ACCENT,
  GREY_DISABLED,
} from "../../../../../../../../helpers/common/colorAliases";
import { Z_PORT_MENU } from "../../../../consts";
import { getMenuIcon } from "../../../../helpers/getMenuIcon";
import styles from "./ZPortMenu.module.scss";

type Props = {
  selected: string;
  onSelect: (item: string) => void;
  disabled?: boolean;
};

const ZPortMenu: FC<Props> = ({ selected, onSelect, disabled }) => {
  return (
    <ul className={styles.wrapper}>
      {Z_PORT_MENU.map((item) => {
        const isActive = item.key === selected;
        const Icon = getMenuIcon(item.key);
        return (
          <li
            onClick={() => !disabled && onSelect(item.key)}
            className={classNames(
              styles.liItem,
              isActive && styles.active,
              disabled && styles.disabled
            )}
            key={item.key}
          >
            <TextWithIcon
              text={item.value}
              icon={<Icon color={isActive ? ACCENT : GREY_DISABLED} />}
            />
          </li>
        );
      })}
    </ul>
  );
};
export default ZPortMenu;
