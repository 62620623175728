import React, { FC } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import { CONNECTION_DEFAULT_BANDWIDTH_VALUES } from "../../../../../../helpers/consts";

type Props = {
  field: string;
  onChange: (idx: number, value: string) => void;
  error?: string;
  idx: number;
  disabled?: boolean;
};

const BWDropdown: FC<Props> = ({ field, onChange, error, idx, disabled }) => {
  return (
    <DropdownBasic
      id={"BW"}
      onChange={(val) => {
        onChange(idx, val.key);
      }}
      placeholder={"Select BW"}
      selected={mapStringToItem(field)}
      label="BW"
      itemsList={CONNECTION_DEFAULT_BANDWIDTH_VALUES.map(mapStringToItem)}
      isMedium
      disabled={disabled}
      error={error}
      errorWithTooltip
    />
  );
};
export default BWDropdown;
