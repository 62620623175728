import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function CloudPortIcon({ color, onClick }: IconProps): ReactElement {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_34919_44262)">
        <path
          d="M26.659 29.4567H31.0755C33.5701 29.4567 35.5995 27.2617 35.5996 24.5637C35.5996 22.653 34.5555 20.9115 32.9685 20.119C32.9399 17.9251 31.648 14.4406 27.3012 15.2309C26.6202 12.9975 23.4816 11.2793 21.2899 11.2793C17.9448 11.2793 15.2069 14.1455 15.0656 17.7264C14.001 18.0675 13.0426 18.7568 12.3368 19.6963C11.539 20.7584 11.0996 22.0982 11.0996 23.469C11.0996 26.7707 13.5831 29.4567 16.6359 29.4567H19.6925"
          stroke="#59536B"
          strokeWidth="2"
        />
      </g>
      <path
        d="M26.7231 34.2871C27.1596 34.2871 27.5135 33.9333 27.5135 33.4968V30.3687H29.4433C29.8798 30.3687 30.2337 30.0148 30.2337 29.5783V24.7395C30.2337 24.303 29.8798 23.9492 29.4433 23.9492L17.4231 23.9492C16.9867 23.9492 16.6328 24.303 16.6328 24.7395L16.6328 29.5783C16.6328 30.0148 16.9867 30.3687 17.4231 30.3687H19.3529L19.3529 33.4968C19.3529 33.9333 19.7068 34.2871 20.1433 34.2871L26.7231 34.2871Z"
        fill="#59536B"
      />
      <path d="M23.2559 18.3926V26.691" stroke="#59536B" strokeWidth="1.6" />
      <circle cx="23.2572" cy="17.3861" r="1.75912" fill="#59536B" />
      <path d="M20.0508 22.3457V25.8639" stroke="#59536B" strokeWidth="1.6" />
      <circle cx="20.0501" cy="20.9427" r="1.75912" fill="#59536B" />
      <path d="M26.4531 22.3457V25.8639" stroke="#59536B" strokeWidth="1.6" />
      <ellipse
        cx="26.4525"
        cy="20.9427"
        rx="1.75912"
        ry="1.75912"
        fill="#59536B"
      />
    </svg>
  );
}

export default CloudPortIcon;
