import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UserRoles, UserSecondRoles } from "../helpers/rolesHelpers";
import { systemApi } from "../helpers/api/SystemApi";
import { Version } from "../helpers/api/apiTypes";
import { useAuthContext, USER_ROLE } from "./AuthContext";
import { tenantApi } from "../helpers/api/TenantApi";
import { createContextUtils } from "./common/utils";
import { userApi } from "../helpers/api/UserApi";

export const NIRO_BRANDING = "niroBranding";

export type IUser = {
  name: string;
  secondName?: string;
  role: UserRoles;
  secondRole: UserSecondRoles;
};
export type TenantParams = {
  isAdminRole: boolean;
  isEmptyConfig: boolean;
};

type TIP = {
  element: string;
  intro: string;
  position: string;
};

type IState = {
  user: IUser;
  tips: any;
  version: Version;
  password: string;
  isTenantEmpty?: boolean;
  tenantParams?: TenantParams;
  isTenant: boolean;
  isZayo?: boolean;
  isDemo?: boolean;
  isAdmin?: boolean;
};

type IFunc = {
  fetchVersion: () => void;
  getUserRole: () => UserRoles;
  setPassword: (passwoАrd: string) => void;
  logoutUser: () => void;
  checkUserType: () => boolean | undefined;
  checkIsTenantEmpty: () => void;
  setIsTenantEmpty: (arg: boolean) => void;
  verifyUser: () => boolean;
  checkTenantParams: (user: IUser | null) => void;
};

export type IUserContext = IState & IFunc;
const [UserContext, useUserContext, withUserContextProps] = createContextUtils<
  IState,
  IFunc
>();

export { useUserContext, withUserContextProps };

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const ZAYO_ENABLED =
  process.env.REACT_APP_ZAYO === "ENABLED" ? true : undefined;

export default function UserContextContainer(props: Props): React.ReactElement {
  const [version, setVersion] = useState<IState["version"]>();
  const [password, setPassword] = useState("");

  const { isAuthorized, restoreUser } = useAuthContext();
  const [user, setUser] = useState<IState["user"] | null>(restoreUser());
  const [tips, setTips] = useState<Array<TIP>>([]);

  const [isTenantEmpty, setIsTenantEmpty] = useState<boolean | undefined>(
    undefined
  );

  const [isZayo, setIsZayo] = useState<boolean | undefined>(ZAYO_ENABLED);
  const [isDemo, setIsDemo] = useState<boolean | undefined>(undefined);

  const [tenantParams, setTenantParams] = useState<TenantParams | undefined>();

  useEffect(() => {
    getTips();
    getNiroBranding();
  }, []);

  const getNiroBranding = async (): Promise<void> => {
    const { ok, result } = await userApi.getNiroBranding();
    if (!ok) return setIsZayo(false);
    if (ok && result) {
      localStorage.setItem(NIRO_BRANDING, result.name);
      const isEnabled =
        process.env.NODE_ENV === "development"
          ? ZAYO_ENABLED
          : result?.name === "zayo" || result?.name === "zayo_demo";

      setIsZayo(Boolean(isEnabled));
      const isDemo =
        result?.name === "zayo_demo" || result?.name === "insidepacket_demo";

      // const isDemo = true;
      console.log("isDemo", isDemo);
      setIsDemo(isDemo);
    }
  };

  useEffect(() => {
    if (!isAuthorized) {
      return setUser(null);
    }
    return setUser(restoreUser());
  }, [isAuthorized]);

  const getTips = async () => {
    const tips = await userApi.getUserTEXTS();
    if (tips && tips.result) {
      setTips(tips.result);
    }
  };

  const fetchVersion = useCallback(async () => {
    const { result } = await systemApi?.getVersion();
    setVersion(result?.version);
  }, []);

  const getUserRole = (): UserRoles => {
    return localStorage.getItem(USER_ROLE) as UserRoles;
  };

  const logoutUser = () => {
    // todo: add api request
    setUser(null);
    localStorage.clear();
  };

  const verifyUser = (): boolean => {
    // todo: use api request in the future
    const username = localStorage.getItem("username");

    return Boolean(username);
  };

  const checkUserType = async () => {
    if (!user) return;
    // console.log("user: ", user); // TODO (logi)
    if (user?.role === UserRoles.TENANT) {
      // console.log("tenant: "); // TODO (logi)
      return checkIsTenantEmpty();
    }
    setIsTenantEmpty(false);
  };

  const checkIsTenantEmpty = async (): Promise<void> => {
    if (!user || user.role === UserRoles.ADMIN) return;
    const res = await checkIsEmptyConfig(user.name);
    setIsTenantEmpty(res);
  };

  const checkIsEmptyConfig = async (user: string): Promise<boolean> => {
    const { ok, result } = await tenantApi.getVirtualInterfacesAllTypes(user);
    return !ok || result?.length === 0;
  };

  const checkTenantParams = async (user: IUser) => {
    if (
      !user ||
      user.role === UserRoles.ADMIN ||
      user.role === UserRoles.ADMIN_FULL
    ) {
      return;
    }
    const isAdminRole = await userApi.getTenantUsers(user.name);

    // TODO remove false when be fix .28
    // const isEmptyConfig = await checkIsEmptyConfig(user.name);
    const isEmptyConfig = false;

    setTenantParams({ isAdminRole: isAdminRole.ok, isEmptyConfig });
  };

  const isTenant = useMemo(() => {
    return (
      user?.role === UserRoles.TENANT || user?.role === UserRoles.TENANT_ADMIN
    );
  }, [user]);

  const isAdmin = useMemo(() => {
    return (
      user?.role === UserRoles.ADMIN || user?.role === UserRoles.ADMIN_FULL
    );
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        version,
        fetchVersion,
        getUserRole,
        setPassword,
        password,
        logoutUser,
        checkUserType,
        isTenantEmpty,
        setIsTenantEmpty,
        checkIsTenantEmpty,
        verifyUser,
        tenantParams,
        checkTenantParams,
        tips,
        isTenant,
        isZayo,
        isDemo,
        isAdmin,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export function tmp_is_demo_onboarding(name: string): boolean {
  return name.toLowerCase() === "bmapp" || name.toLowerCase().includes("2");
}
