import React, { FC } from "react";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import styles from "./Preferences.module.scss";
import UsersIcon from "../../../../icons/topologyButtons/UsersIcon";
import { useHistory } from "react-router-dom";
import {
  NETWORK,
  REMOTE_USERS,
  TENANTS,
  USERS,
} from "../../../../../helpers/navigation/entries";
import { formPathFromArray } from "../../../../../helpers/navigation";
import { useUserContext } from "../../../../../contexts/UserContext";

type Props = {};

const Users: FC<Props> = () => {
  const { isTenant } = useUserContext();

  const path = isTenant
    ? formPathFromArray([NETWORK(), REMOTE_USERS()])
    : formPathFromArray([TENANTS(), USERS()]);

  const history = useHistory();
  return (
    <IconBlankButton
      id={"usersMenu"}
      icon={UsersIcon}
      label={"Users"}
      onClick={() => {
        history.replace(path);
      }}
      className={styles.btn}
    />
  );
};

export default Users;
