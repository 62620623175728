import React, { FC, useState } from "react";
import { NewDialogProps } from "../../../../helpers/types";
import DialogTemplateOneColumn from "../../../../components/dialogs/common/DialogTemplateOneColumn";
import CloudIcon from "../../../../components/icons/CloudIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import {
  AzureNNI,
  CreateAWSNNI,
  CreateAzureNNI,
  CreateGCPNNI,
  GCPNNI,
} from "../../types";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./AddNNIDialog.module.scss";
import { PRes } from "../../../../helpers/api/Api";
import { ProvidersTabs } from "../ProvidersTabs";
import { useAwsForm, useAzureForm, useGCPForm } from "./components";

type AddNNIDialogProps = {
  createAWSNNI: (params?: CreateAWSNNI) => PRes<any>;
  createAzureNNI: (params?: CreateAzureNNI) => PRes<any>;
  createGCPNNI: (params?: CreateGCPNNI) => PRes<any>;
  azureNNIOptions: Array<AzureNNI>;
  gcpNNIOptions: Array<GCPNNI>;
} & NewDialogProps<any>;

export const AddNNIDialog: FC<AddNNIDialogProps> = ({
  onClose,
  createAWSNNI,
  createAzureNNI,
  createGCPNNI,
  azureNNIOptions,
  gcpNNIOptions,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const { controls: awsControls, form: awsForm } = useAwsForm({
    createAWSNNI,
    onClose,
  });

  const { controls: azureControls, form: azureForm } = useAzureForm({
    createAzureNNI,
    onClose,
    azureNNIOptions,
  });

  const { controls: gcpControls, form: gcpForm } = useGCPForm({
    createGCPNNI,
    onClose,
    gcpNNIOptions,
  });

  const TAB_TO_FORM_MAP = {
    0: {
      controls: awsControls,
      form: awsForm,
    },
    1: {
      controls: azureControls,
      form: azureForm,
    },
    2: {
      controls: gcpControls,
      form: gcpForm,
    },
  };

  const { controls, form } =
    TAB_TO_FORM_MAP[activeTab as keyof typeof TAB_TO_FORM_MAP] ?? {};

  return (
    <DialogTemplateOneColumn
      header={
        <div className={styles.header}>
          <CloudIcon color={GREY_FONT} /> <span>Add Cloud NNI</span>
        </div>
      }
      onClose={onClose}
      controls={controls}
    >
      <ProvidersTabs activeTab={activeTab} onChangeTab={setActiveTab} />
      {form}
    </DialogTemplateOneColumn>
  );
};
