import React, { FC } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../../helpers/common/classNames";
import LoaderIcon from "../loadStates/LoaderIcon";
import styles from "./Card.module.scss";
import { CardElement } from "./cardTypes";
import { formatNumbers } from "../../../helpers/formatNumbers";

const Card: FC<CardElement> = ({
  data,
  icon,
  title,
  link,
  disabled,
  className,
}) => {
  const containerClasses = classNames(
    styles.container,
    disabled && styles.disabled,
    !data && styles.noData,
    className
  );
  return (
    <Link
      id={title}
      key={title}
      to={link || ""}
      className={containerClasses}
      data-tip
    >
      {!data || !data[title] ? (
        <LoaderIcon />
      ) : (
        <>
          <div className={styles.header}>
            {icon && <div className={styles.icon}>{icon}</div>}
            <span>{title}</span>
          </div>
          {data[title]?.map((el) => (
            <div className={styles.row} key={el.number}>
              <div className={styles.numbersItem}>
                {formatNumbers(el.number)}
              </div>
              <div className={styles.titlesItem}>{el.title}</div>
            </div>
          ))}
        </>
      )}
    </Link>
  );
};

export default Card;
