import React, { FC } from "react";
import { classNames } from "../../helpers/common/classNames";
import { TrafficType } from "../../helpers/types";
import BlankButton from "./buttons/BlankButton";
import styles from "./ChartToppersTabs.module.scss";
import { BPS, PPS } from "../../helpers/common/constantsAlias";

const TOPPERS_VALUES: Array<TrafficType> = [BPS, PPS];

type Props = {
  activeTab: string;
  setTabActive: (value: TrafficType) => void;
  tabs?: Array<TrafficType>;
  isLongTab?: boolean;
  className?: string;
};

const ChartToppersTabs: FC<Props> = ({
  activeTab,
  setTabActive,
  tabs = TOPPERS_VALUES,
  isLongTab,
  className,
}) => {
  return (
    <div className={styles.wrapper}>
      {tabs.map((tab: TrafficType) => {
        return (
          <BlankButton
            id={tab}
            key={tab}
            className={classNames(
              styles.tab,
              activeTab === tab && styles.active,
              isLongTab && styles.longTab,
              className
            )}
            onClick={() => setTabActive(tab)}
          >
            {isLongTab ? tab : tab.toUpperCase()}
          </BlankButton>
        );
      })}
    </div>
  );
};

export default ChartToppersTabs;
