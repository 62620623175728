import React, { FC } from "react";
import IconBlankButton from "./IconBlankButton";
import ReleaseIcon from "../../icons/ReleaseIcon";
import styles from "./ReleaseButton.module.scss";
import { classNames } from "../../../helpers/common/classNames";

type Props = {
  onClick: (arg?: any) => void;
  withLabel?: boolean;
  title?: string;
  disabled?: boolean;
  id: string;
  color?: string;
  className?: string;
  isLoading?: boolean;
};

const ReleaseButton: FC<Props> = ({
  onClick,
  withLabel,
  title,
  disabled,
  id,
  color,
  className,
  isLoading,
}) => {
  return (
    <IconBlankButton
      id={`download-${id}`}
      title={title}
      onClick={onClick}
      icon={() => (
        <ReleaseIcon
          className={isLoading ? styles.circularLoader : ""}
          color="#6B55F0"
        />
      )}
      label={withLabel ? "Release" : undefined}
      disabled={disabled}
      color={color}
      className={classNames(styles.btn, className)}
    />
  );
};
export default ReleaseButton;
