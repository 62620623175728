import { VTEPType } from "./../../pages/VTEPPage/types";
import Validator from "./Validator";

export default function validateVTEP(
  data: VTEPType,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkEmpty(data.local_ip, "local_ip");
  validator.checkEmpty(data.vrf?.name, "vrf");
  validator.checkEmpty(data.vtep_type, "vtep_type");
  validator.checkEmpty(data.description, "description");
  validator.checkIP(data.local_ip, "local_ip");
  return validator;
}
