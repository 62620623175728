import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function DIAIconLabel({ width, height, x, y }: IconProps): ReactElement {
  return (
    <svg
      width={height || 20}
      height={width || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4C0.895431 4 0 4.89543 0 6V14C0 15.1046 0.895431 16 2 16H18C19.1046 16 20 15.1046 20 14V6C20 4.89543 19.1046 4 18 4H2ZM15.8822 11.9936L16.3628 13.5714H17.8772L15.5285 6.91546H13.8101L11.4706 13.5714H12.9849L13.4656 11.9936H15.8822ZM14.794 8.38903C14.8515 8.57643 15.1023 9.385 15.5467 10.8147H13.8192C14.2907 9.28828 14.5764 8.3316 14.6761 7.94469C14.7003 8.05351 14.7396 8.20162 14.794 8.38903ZM7.85694 10.1936C7.85694 11.2848 7.54561 12.1205 6.92293 12.7009C6.30328 13.2813 5.40705 13.5714 4.23424 13.5714H2.35715V6.94267H4.43828C5.5204 6.94267 6.36071 7.22831 6.9592 7.7996C7.5577 8.37089 7.85694 9.16888 7.85694 10.1936ZM6.39698 10.2298C6.39698 8.80616 5.76826 8.09431 4.51082 8.09431H3.7627V12.4107H4.36573C5.7199 12.4107 6.39698 11.6838 6.39698 10.2298ZM9.23076 13.5714V6.94267H10.6363V13.5714H9.23076Z"
        fill="#59536B"
      />
    </svg>
  );
}

export default DIAIconLabel;
