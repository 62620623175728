export type InfoMessage = {
  message: string;
  id?: string;
  status?: boolean;
  createdAt?: number;
  e?: string;
};

export const INFO_MSG = "INFO_MSG";
export const InfoEvent = "info messages";

export const getMessages = (): Array<InfoMessage> => {
  return JSON.parse(sessionStorage.getItem(INFO_MSG) || "[]");
};

export class RequestMiddleware {
  public messages: Array<InfoMessage> = getMessages();
  private event = new Event(InfoEvent);
  private hasUpdate = false;

  private checkMessages = async (): Promise<void> => {
    if (this.hasUpdate) return;
    const now = Date.now();
    const relevantMessages = this.messages.filter(
      (message) =>
        !message.status || (message.createdAt && now - message.createdAt < 6000)
    );
    await this.setMessages(relevantMessages);
  };

  private writeMessagesToStorage = async (): Promise<void> => {
    await this.setItem(INFO_MSG, JSON.stringify(this.messages));
  };

  private setItem = async (key: string, value: string): Promise<void> => {
    return Promise.resolve().then(function () {
      sessionStorage.setItem(key, value);
    });
  };

  private setMessages = async (
    messages?: Array<InfoMessage>
  ): Promise<void> => {
    this.hasUpdate = true;
    if (messages) this.messages = messages;
    await this.writeMessagesToStorage();
    window.dispatchEvent(this.event);
    this.hasUpdate = false;
  };

  public updateMessageStatus = async (
    id: string,
    status: boolean,
    e?: string
  ): Promise<void> => {
    this.hasUpdate = true;
    const newMessages = this.messages.map((existingMessage) => {
      if (existingMessage.id === id) {
        existingMessage.status = status;
        existingMessage.createdAt = Date.now();
        existingMessage.e = e;
      }
      return existingMessage;
    });
    await this.setMessages(newMessages);
  };

  public getMessagesIds = (): Array<string> => {
    const messages = this.messages.filter((message) => Boolean(message.id));
    return messages.map((message) => message.id || "");
  };

  public removeMessage = async (created?: number): Promise<void> => {
    const relevantMessages = this.messages.filter(
      (message) => message.createdAt !== created
    );
    await this.setMessages(relevantMessages);
  };

  public removeAll = async (): Promise<void> => {
    await this.setMessages([]);
  };

  start = async (): Promise<void> => {
    setInterval(await this.checkMessages, 1000);
  };

  addMessage = async (message: InfoMessage): Promise<void> => {
    this.messages.push(message);
    await this.setMessages();
  };
}

export const requestMiddleware = new RequestMiddleware();
requestMiddleware.start();
