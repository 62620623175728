import React from "react";
import { PosProps } from "../../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/utils";
import CloudRouterIcon from "./CloudRouterIcon";

export default function CloudCenter({
  x,
  y,
  active,
  location,
  isZayo,
}: PosProps & {
  active?: boolean;
  location: string;
  isZayo?: boolean;
}): React.ReactElement {
  return (
    <>
      <text
        x={x}
        y={y + 55}
        textAnchor="middle"
        fill="var(--font)"
        fontSize="12"
      >
        {location}
      </text>
      <circle
        cx={x}
        cy={y}
        r="36"
        fill="rgb(255, 255, 255)"
        stroke="rgba(107, 85, 240, 0.60)"
        strokeWidth={active ? 2.5 : 1}
      />
      {!isZayo ? (
        <CloudRouterIcon x={x} y={y} />
      ) : (
        <image
          width="52"
          height="17"
          x={x - 25}
          y={y - 5}
          xlinkHref="/zayoGrafics/zayoLogoSmall.png"
        />
      )}
    </>
  );
}
