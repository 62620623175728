import { FC } from "react";
import Chart from "../Chart";
import styles from "./device.module.scss";

type Props = {
  id: string;
  hostName: string;
  interfaces: Array<string>;
};

const Device: FC<Props> = ({ id, hostName, interfaces = [] }) => {
  return (
    <div className={styles.device}>
      <h3>{hostName}</h3>

      <div className={styles.legends}>
        <div className={styles.legend}>
          <h5>Bandwidth:</h5>
          <h6>
            Tx <hr />
          </h6>
          <h6>
            Rx <hr />
          </h6>
        </div>

        <div className={styles.legend}>
          <h5>Firewall Denies:</h5>
          <h6>
            Tx <hr />
          </h6>
          <h6>
            Rx <hr />
          </h6>
        </div>
      </div>

      <div className={styles.charts}>
        {interfaces.map((item) => {
          return <Chart id={id} interfaceName={item} key={item} />;
        })}
      </div>
    </div>
  );
};

export default Device;
