import { GLOBAL_TENANT } from "../../contexts/UserListContext";
import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { configApi } from "../../helpers/api/ConfigApi";
import { userApi } from "../../helpers/api/UserApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import {
  RequestStatus,
  UpdateUserGroup,
  User,
  UserGroup,
} from "../../helpers/types";
import { GLOBAL_TENANT_NAME } from "./constants";

type RequestStatuses = {
  state: "pending" | "ok" | "error" | "idle";
  message?: string;
};
type State = {
  users: Array<User>;
  groups: any;
  groupStatus: RequestStatuses;
  selectedIp: Array<string>;
  groupsItems: Array<any> | undefined;
  deleteStatus: RequestStatus;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchUsers: (tenant: string) => Promise<void>;
  fetchOneGroup: (tenantName: string, groupName: string) => Promise<void>;
  fetchGroups: (tenant: string) => Promise<void>;
  setSelectedIp: (subnets: Array<string>) => void;
  createUsersGroup: (
    tenantName: string,
    bodyParams: UserGroup
  ) => Promise<boolean>;
  updateUsersGroup: (
    tenantName: string,
    groupName: string,
    bodyParams: UpdateUserGroup
  ) => Promise<void>;
  deleteUsersGroup: (tenantName: string, groupName: string) => Promise<boolean>;
};

const [UserGroupsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useUserGroupsContext = useContext;

export default class UserGroupsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = UserGroupsContext;

  constructor(props: Readonly<any>) {
    super(props);
  }
  fetchOneGroup = async (
    tenantName: string,
    groupName: string
  ): Promise<void> => {
    this.setState({ groupStatus: setPending() });
    await userApi.getOneUsersGroup(tenantName, groupName);
    this.setState({ groupStatus: { state: "ok" } });
  };

  fetchGroups = async (tenantName: string): Promise<void> => {
    this.setState({ groupStatus: setPending() });
    const { ok, result } =
      tenantName === GLOBAL_TENANT
        ? await userApi.getGlobalUsersGroupsList()
        : await userApi.getUsersGroupsList(tenantName);
    if (ok && result) {
      this.setState({
        groups: result,
        groupsItems: result.items,
        groupStatus: { state: "ok" },
      });
    }
  };

  fetchUsers = async (tenantName: string): Promise<void> => {
    this.setState({ groupStatus: setPending() });
    const { ok, result } =
      tenantName === GLOBAL_TENANT
        ? await userApi.getGlobalUsers()
        : await userApi.getTenantUsers(tenantName);
    if (ok && result) {
      this.setState({
        users: result,
        groupStatus: { state: "ok" },
      });
    }
  };

  setSelectedIp = (subnets: Array<string>): void => {
    this.setState({ selectedIp: subnets });
  };

  createUsersGroup = async (tenantName: string, bodyParams: UserGroup) => {
    this.setState({ groupStatus: setPending() });

    let data = undefined;

    if (tenantName === GLOBAL_TENANT_NAME) {
      data = await configApi.createGroup(tenantName, bodyParams.subnets);
    } else {
      data = await userApi.createUsersGroup(tenantName, bodyParams);
    }

    if (data?.ok && data?.result) {
      await this.fetchGroups(tenantName);
    }
    this.setState({ groupStatus: resToState(data) });
    return data?.ok;
  };

  deleteUsersGroup = async (tenantName: string, groupName: string) => {
    this.setState({ deleteStatus: setPending() });

    let data = undefined;

    if (tenantName === GLOBAL_TENANT_NAME) {
      data = await configApi.deleteGroup(groupName);
    } else {
      data = await userApi.deleteUsersGroup(tenantName, groupName);
    }
    if (data?.ok && data?.result) {
      await this.fetchGroups(tenantName);
    }
    this.setState({ deleteStatus: resToState(data) });
    return data?.ok;
  };

  updateUsersGroup = async (
    tenantName: string,
    groupName: string,
    bodyParams: UpdateUserGroup
  ): Promise<void> => {
    this.setState({ groupStatus: setPending() });

    let data = undefined;

    if (tenantName === GLOBAL_TENANT_NAME) {
      data = await configApi.updateGroup(groupName, bodyParams.subnets);
    } else {
      data = await userApi.updateUsersGroup(tenantName, groupName, bodyParams);
    }
    if (data?.ok && data?.result) {
      this.setState({ groupStatus: { state: "ok" } });
      await this.fetchGroups(tenantName);
    }
    this.setState({ groupStatus: { state: "error" } });
  };

  funcs = {
    fetchUsers: this.fetchUsers,
    fetchOneGroup: this.fetchOneGroup,
    fetchGroups: this.fetchGroups,
    setSelectedIp: this.setSelectedIp,
    updateUsersGroup: this.updateUsersGroup,
    createUsersGroup: this.createUsersGroup,
    deleteUsersGroup: this.deleteUsersGroup,
  };
}
