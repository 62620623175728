import React, { FC, useMemo } from "react";
import { TrafficType } from "../../../../../../helpers/types";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import grafanaLinksFactory from "../../../../../../components/common/charts/GraphanaLinksFactory";
import GrafanaGraph from "../../../../../../components/common/charts/GrafanaGraph";
import { BPS } from "../../../../../../helpers/common/constantsAlias";

type Props = {
  interfaceName: string;
  traffic: TrafficType;
  tenantName?: string;
  className?: string;
  isDia?: boolean;
};

const InterfaceTraffic: FC<Props> = ({
  interfaceName,
  traffic = BPS,
  tenantName,
  className,
  isDia,
}) => {
  const { timeRange } = useTimerangeContext();

  const graphLink = useMemo(() => {
    const data = {
      interfaceName,
      timeRange: timeRange.general,
      tenantName,
      is_dia: isDia,
    };

    if (isDia) {
      return traffic === BPS
        ? grafanaLinksFactory.dashboard_interface_bps_dia(timeRange.general)(
            data
          )
        : grafanaLinksFactory.dashboard_interface_pps_dia(timeRange.general)(
            data
          );
    }
    return traffic === BPS
      ? grafanaLinksFactory.dashboard_interface_bps(timeRange.general)(data)
      : grafanaLinksFactory.dashboard_interface_pps(timeRange.general)(data);
  }, [traffic, timeRange, tenantName, interfaceName]);

  return (
    <div className={className}>
      <GrafanaGraph link={graphLink} />
    </div>
  );
};

export default InterfaceTraffic;
