/* eslint-disable css-modules/no-unused-class */
import React, { FC, useState, useMemo } from "react";
import {
  getAllVlan,
  parseAllVLans,
  VlanData,
} from "../../Clouds/CloudsTabInfo";
import { getIconAndText } from "../../../../Connections/helpers/getConnectionType";
import styles from "./PortItem.module.scss";
import { GREY_DISABLED } from "../../../../../../helpers/common/colorAliases";
import { TenantCustomerPort, TenantPort } from "../../../types";
import { VirtualInterfaceApi } from "../../../../../VirtualInterfacePage/types";
import DataCenterNewIcon from "../../../../../../components/icons/DataCenterNewIcon";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
  NATIVE,
  PENDING_CAPITAL,
} from "../../../../../../helpers/consts";
import FiberIcon from "../../../../../../components/icons/FiberIcon";
import { classNames } from "../../../../../../helpers/common/classNames";
import AWSPortInfo from "./AWSPortInfo";
import CustomerPortInfo from "./CustomerPortInfo";
import AzurePortInfo from "./AzurePortInfo";
import GCPPortInfo from "./GCPPortInfo";

export function getPortConnections(
  connections: Array<VirtualInterfaceApi>,
  port: string
) {
  const filteredConnections: Array<VirtualInterfaceApi> = [];

  connections.forEach((connection) => {
    const vlanList = getAllVlan(connection);

    vlanList.forEach((vlan) => {
      if (vlan?.alias?.includes(port) || vlan?.name?.includes(port)) {
        const isDuplicate = filteredConnections.some(
          (existingConnection) => existingConnection.id === connection.id
        );

        if (!isDuplicate) {
          filteredConnections.push(connection);
        }
      }
    });
  });

  return filteredConnections;
}

type Props = {
  port: TenantCustomerPort;
  i: number;
  skipOpened: boolean;
  addConnectionCallback?: (val: string) => void;
  parents: Array<TenantPort>;
  connections: Array<VirtualInterfaceApi>;
  selectPortToDelete: (port: TenantCustomerPort) => void;
  l2Ports: Array<TenantPort>;
};

export const PortItem: FC<Props> = ({
  port,
  i,
  skipOpened,
  addConnectionCallback,
  parents = [],
  connections,
  selectPortToDelete,
}) => {
  const parentPort = parents.find(
    (parent) => parent.name === port.l2_parent_name
  );

  function getOperatorIconAndText() {
    let icon = <DataCenterNewIcon color={GREY_DISABLED} />;
    const operator = port.labels.operator;
    if (port.labels?.building_type !== "DC") {
      icon = <FiberIcon color={GREY_DISABLED} />;
    }
    return [icon, operator];
  }

  const filteredConnections = useMemo(
    () => getPortConnections(connections, port.name),
    [connections, port.name]
  );

  const isAzure = useMemo(() => port.type === AZURE_LOWERCASE, [port.type]);
  const isAWS = useMemo(() => port.type === AWS_LOWERCASE, [port.type]);
  const isGCP = useMemo(() => port.type === GCP_LOWERCASE, [port.type]);
  const [hidden, setHidden] = useState<boolean>(!!i || skipOpened);
  const [operatorIcon, operator] = getOperatorIconAndText();
  const strokeLength = 3.14 * 25 * 2;
  const usedBW = strokeLength * (port.used_bw / port.assigned_bw);
  const freeBW = strokeLength - usedBW;
  const pending = port.request_status === "pending";
  const hasFreeBw = port.used_bw < port.assigned_bw;

  const addConnectionBtn = (
    <div
      className={styles.addConnection}
      onClick={() => addConnectionCallback?.(port.name)}
    >
      Add connection
    </div>
  );

  function getConnectionsCount() {
    const count = filteredConnections.length;
    const text = pending
      ? PENDING_CAPITAL
      : count === 1
      ? "1 Connection"
      : `${count} Connections`;
    return (
      <div
        className={classNames(
          styles.addConnection,
          styles.addConnection__hidden,
          pending && styles.pending
        )}
      >
        {text}
      </div>
    );
  }

  function getConnectionDataLabel(vlanData: VlanData) {
    if (isAWS) {
      const awsConsoleURL = `https://us-east-1.console.aws.amazon.com/directconnect/v2/home?region=${port?.labels?.cloud_region}#/connections`;
      return (
        <>
          <div className={styles.connectionLabelWrapper}>
            <span className={styles.connectionLabel}>ID:</span>
            <span>
              {
                <a
                  href={awsConsoleURL}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  className={styles.cloudLinkStyle}
                >
                  {port?.labels?.connection_id}
                </a>
              }
            </span>
          </div>
          <div className={styles.connectionLabelWrapper}>
            <span className={styles.connectionLabel}>Status:</span>
            <span>{port?.request_status || "-"}</span>
          </div>
        </>
      );
    }
    if (isGCP) {
      return;
    }
    return (
      <div className={styles.connectionLabelWrapper}>
        <span className={styles.connectionLabel}>VLAN:</span>
        <span>{vlanData.vlan_ctag_id || NATIVE}</span>
      </div>
    );
  }

  function getConnectionDetail(connection: VirtualInterfaceApi) {
    const [icon] = getIconAndText(
      connection.type,
      connection.virtual_interface_type,
      connection?.labels?.remote_type,
      connection.is_ipsec_service,
      connection.is_nat_service,
      connection.is_snat_service,
      undefined,
      undefined,
      connection.is_dia
    );

    const vlanList = parseAllVLans(connection, false, port.name);

    return (
      <div className={styles.connection}>
        <div>{icon}</div>
        <div className={styles.connectionTitleWrapper}>
          <div className={styles.connectionTitle}>
            {connection.name}{" "}
            {connection?.labels?.azureConnectionType && (
              <div className={styles.azureConnectionType}>
                {connection?.labels?.azureConnectionType}
              </div>
            )}
          </div>
          {vlanList.length > 0 ? (
            vlanList.map((vlanData, index) => (
              <div
                key={index}
                className={classNames(
                  styles.connectionData,
                  (isAWS || isGCP) && styles.displayBlock
                )}
              >
                {getConnectionDataLabel(vlanData)}
                <div className={styles.connectionLabelWrapper}>
                  <span className={styles.connectionLabel}>BW:</span>
                  <span>{vlanData.max_bandwidth}</span>
                </div>
              </div>
            ))
          ) : (
            <div>No VLAN data available</div>
          )}
        </div>
      </div>
    );
  }

  if (isAzure) {
    return (
      <AzurePortInfo
        port={port}
        i={i}
        hidden={hidden}
        operatorIcon={operatorIcon}
        operator={operator}
        parentPort={parentPort}
        pending={pending}
        hasFreeBw={hasFreeBw}
        usedBW={usedBW}
        freeBW={freeBW}
        filteredConnections={filteredConnections}
        getConnectionDetail={getConnectionDetail}
        addConnectionBtn={addConnectionBtn}
        getConnectionsCount={getConnectionsCount}
        selectPortToDelete={selectPortToDelete}
        setHidden={setHidden}
      />
    );
  }

  if (isAWS) {
    return (
      <AWSPortInfo
        port={port}
        i={i}
        hidden={hidden}
        operatorIcon={operatorIcon}
        operator={operator}
        parentPort={parentPort}
        pending={pending}
        hasFreeBw={hasFreeBw}
        usedBW={usedBW}
        freeBW={freeBW}
        filteredConnections={filteredConnections}
        getConnectionDetail={getConnectionDetail}
        addConnectionBtn={addConnectionBtn}
        getConnectionsCount={getConnectionsCount}
        selectPortToDelete={selectPortToDelete}
        setHidden={setHidden}
      />
    );
  }

  if (isGCP) {
    return (
      <GCPPortInfo
        port={port}
        i={i}
        hidden={hidden}
        operatorIcon={operatorIcon}
        operator={operator}
        parentPort={parentPort}
        pending={pending}
        hasFreeBw={hasFreeBw}
        usedBW={usedBW}
        freeBW={freeBW}
        filteredConnections={filteredConnections}
        getConnectionDetail={getConnectionDetail}
        addConnectionBtn={addConnectionBtn}
        getConnectionsCount={getConnectionsCount}
        selectPortToDelete={selectPortToDelete}
        setHidden={setHidden}
      />
    );
  }

  return (
    <CustomerPortInfo
      port={port}
      i={i}
      hidden={hidden}
      operatorIcon={operatorIcon}
      operator={operator}
      parentPort={parentPort}
      pending={pending}
      hasFreeBw={hasFreeBw}
      usedBW={usedBW}
      freeBW={freeBW}
      filteredConnections={filteredConnections}
      getConnectionDetail={getConnectionDetail}
      addConnectionBtn={addConnectionBtn}
      getConnectionsCount={getConnectionsCount}
      selectPortToDelete={selectPortToDelete}
      setHidden={setHidden}
    />
  );
};
