import { FC, useEffect, useMemo, useState } from "react";
import ChartToppersTabs from "../../../components/common/ChartToppersTabs";
import { PageHeader } from "../../../components/common/PageHeader/PageHeader";
import { useNetworkContext } from "../context/NetworkContext";
import {
  combineState,
  convertToGroupRows,
  removeNATVis,
} from "../helpers/table";
import { StatGroup } from "./components/StatGroup";
import { StatSection } from "./components/StatSection";

import styles from "./InterfaceStatistics.module.scss";
import TabsBlock from "../UnitedConnections/components/common/TabsBlock";

const tabs = ["Cloud Router", "Direct"];

const InterfaceStatistics: FC = () => {
  const {
    direct,
    cloud,
    trafficType,
    setTrafficType,
    systems,
  } = useNetworkContext();

  const tab = useMemo(() => {
    if (cloud.length && !direct.length) return tabs[0];

    return tabs[1];
  }, [cloud, direct]);

  const [activeTab, setActiveTab] = useState<string>(tab);

  useEffect(() => setActiveTab(tab), [tab]);

  const viGroupsDirect = removeNATVis(
    convertToGroupRows(combineState(direct), systems)
  );
  const viGroupsCloud = removeNATVis(
    convertToGroupRows(combineState(cloud), systems)
  );

  const labels = {
    [tabs[0]]: (
      <>
        <StatSection type="total" title={"Total"} name="Total Traffic" />
        {viGroupsCloud.map((group) => (
          <StatGroup group={group} key={group.name.label} />
        ))}
      </>
    ),
    [tabs[1]]: (
      <>
        {viGroupsDirect.map((group) => (
          <StatGroup group={group} key={group.name.label} />
        ))}
      </>
    ),
  };

  return (
    <div className={styles.block}>
      <TabsBlock
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={styles.wrapper}>
        <PageHeader
          title="Interface Statistics"
          right={[
            // eslint-disable-next-line react/jsx-key
            <ChartToppersTabs
              activeTab={trafficType}
              setTabActive={setTrafficType}
            />,
          ]}
        />
        <div className={styles.inner}>{labels[activeTab]}</div>
      </div>
    </div>
  );
};

export default InterfaceStatistics;
