import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import React from "react";
import styles from "./renderSubrow.module.scss";
import { getAllVlan, parseVlan, VlanData } from "./CloudsTabInfo";
import PortVlanIcon from "../Ports/icons/PortVlanIcon";
import PortBaseIcon from "../Ports/icons/PortBaseIcon";
import AWSIcon from "../../../../../components/icons/cloudConnections/AWSIcon";
import AzureIcon from "../../../../../components/icons/cloudConnections/AzureIcon";
import DIAIcon from "../../../../../components/icons/DIAIcon";
import { CloudDiagram } from "./CloudDiagram";
import { getAPortAsPort } from "./DirectDiagramComponent";
import { TenantCustomerPort, TenantPort } from "../../types";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
  NATIVE,
} from "../../../../../helpers/consts";
import GCPIcon from "../../../../../components/icons/interfaceTypes/GCPIcon";

function getVlan(
  row: VirtualInterfaceApi,
  l2Ports: Array<TenantPort>
): React.ReactElement {
  const vlanList = getAllVlan(row, l2Ports);
  const vlan_id: VlanData | undefined = vlanList.find(
    (vlan) => !!vlan.vlan_ctag_id
  );
  return (
    <div className={styles.block}>
      <span className={styles.cloudSubTitleOpacity}>VLAN: </span>
      <span className={styles.cloudSubTitle}>
        {vlan_id?.vlan_ctag_id || NATIVE}
      </span>
    </div>
  );
}

function getCustomer(row: VirtualInterfaceApi): React.ReactElement {
  return (
    <div className={styles.block}>
      <span className={styles.cloudSubTitleOpacity}>Customer ID: </span>
      <span className={styles.cloudSubTitle}>
        {row.labels?.customer || "-"}
      </span>
    </div>
  );
}

function getRegion(row: VirtualInterfaceApi): React.ReactElement {
  return (
    <div className={styles.block}>
      <span className={styles.cloudSubTitleOpacity}>Region: </span>
      <span className={styles.cloudSubTitle}>
        {row.labels?.location || "-"}
      </span>
    </div>
  );
}

function getCloudSubRow(
  row: VirtualInterfaceApi,
  l2Ports: Array<TenantPort>
): React.ReactElement {
  const { max_bandwidth } = parseVlan(row);
  if (row?.labels?.remote_type === AZURE_LOWERCASE) {
    return (
      <div className={styles.content}>{getBW(max_bandwidth as string)}</div>
    );
  }
  return (
    <div className={styles.content}>
      {getBW(max_bandwidth as string)}
      {getRegion(row)}
      {getCustomer(row)}
    </div>
  );
}

function getPortSubRow(
  row: VirtualInterfaceApi,
  l2Ports: Array<TenantPort>
): React.ReactElement {
  const { name } = parseVlan(row);
  return (
    <div className={styles.content}>
      <div className={styles.block}>
        <span className={styles.cloudSubTitleOpacity}>Port: </span>
        <span className={styles.cloudSubTitle}>{name || "-"}</span>
      </div>
      {getVlan(row, l2Ports)}
    </div>
  );
}

export function getAPortRowInfo(
  row: VirtualInterfaceApi,
  l2Ports: Array<TenantPort>,
  customerPorts?: Array<TenantCustomerPort>
): React.ReactElement {
  const vlanList: Array<VlanData> = getAllVlan(row, l2Ports);
  const { vlanListUpdated, rowUpdated } = searchForClouds(
    vlanList,
    row,
    customerPorts
  );

  const remote = rowUpdated?.labels?.remote_type;
  const isCloud = [AWS_LOWERCASE, AZURE_LOWERCASE].includes(remote);

  if ([AWS_LOWERCASE, AZURE_LOWERCASE].includes(remote)) {
    return getCloudSubRow(rowUpdated, l2Ports);
  }
  return getPortSubRow(rowUpdated, l2Ports);
}

function getLabel(isA: boolean): React.ReactElement {
  return (
    <div className={styles.portLabel}>
      <div className={styles.label}>{`“${isA ? "A" : "Z"}” PORT`}</div>
    </div>
  );
}

function getBW(bw: string): React.ReactElement {
  return (
    <div className={styles.block}>
      <span className={styles.cloudSubTitleOpacity}>BW: </span>
      <span className={styles.cloudSubTitle}>{bw}</span>
    </div>
  );
}

const getPortIcon = (
  port: VlanData,
  customerPorts?: Array<TenantCustomerPort>
) => {
  const hasAzure = customerPorts?.some(
    (inventoryPort) =>
      inventoryPort.type === AZURE_LOWERCASE && port.name === inventoryPort.name
  );
  const hasGcp = customerPorts?.some(
    (inventoryPort) =>
      inventoryPort.type === GCP_LOWERCASE && port.name === inventoryPort.name
  );

  if (hasAzure) {
    return <AzureIcon />;
  }
  if (hasGcp) {
    return <GCPIcon />;
  }

  return port?.vlan_id ? <PortVlanIcon /> : <PortBaseIcon />;
};

function getPortRow(
  port: VlanData,
  isA = false,
  customerPorts?: Array<TenantCustomerPort>
): React.ReactElement {
  const icon = getPortIcon(port, customerPorts);

  return (
    <>
      {getLabel(isA)}
      <div className={styles.smartBlock}>
        <div>{icon}</div>
        <div>
          <div className={styles.cloudSubTitle}>{port.name}</div>
        </div>
      </div>
      {getBW(port.max_bandwidth as string)}
      <div className={styles.block}>
        <span className={styles.cloudSubTitleOpacity}>VLAN: </span>
        <span className={styles.cloudSubTitle}>
          {port.vlan_ctag_id || NATIVE}
        </span>
      </div>
    </>
  );
}

function getCloudRow(
  connection: VirtualInterfaceApi,
  isA = true,
  l2Ports: Array<TenantPort>
): React.ReactElement {
  const icon =
    connection.labels?.remote_type === AWS_LOWERCASE ? (
      <AWSIcon />
    ) : (
      <AzureIcon />
    );
  const { max_bandwidth } = parseVlan(connection);
  if (connection?.labels?.remote_type === AZURE_LOWERCASE) {
    return (
      <>
        {getLabel(isA)}
        <div className={styles.smartBlock}>
          <div>{icon}</div>
          <div>
            <div className={styles.cloudSubTitle}>Cloud</div>
          </div>
        </div>
        {getBW(max_bandwidth as string)}
      </>
    );
  }
  return (
    <>
      {getLabel(isA)}
      <div className={styles.smartBlock}>
        <div>{icon}</div>
        <div>
          <div className={styles.cloudSubTitle}>Cloud</div>
        </div>
      </div>
      {getBW(max_bandwidth as string)}
      {getRegion(connection)}
      {getCustomer(connection)}
    </>
  );
}

function getDiaRow(connection: VirtualInterfaceApi): React.ReactElement {
  const icon = <DIAIcon />;
  const { max_bandwidth } = parseVlan(connection);
  return (
    <>
      {getLabel(false)}
      <div className={styles.smartBlock}>
        <div>{icon}</div>
        <div>
          <div className={styles.cloudSubTitle}>Internet</div>
        </div>
      </div>
      {getBW(max_bandwidth as string)}
      <div className={styles.block}>
        <span className={styles.cloudSubTitleOpacity}>Subnet: </span>
        <span className={styles.cloudSubTitle}>
          {connection.ip_addresses?.[0] || "-"}
        </span>
      </div>
    </>
  );
}

function searchForAws(
  vlanList: Array<VlanData>,
  row: VirtualInterfaceApi,
  customerPorts?: Array<TenantCustomerPort>
): { vlanListUpdated: Array<VlanData>; rowUpdated: VirtualInterfaceApi } {
  let vlanListUpdated = [...vlanList];
  const rowUpdated = { ...row };

  if (!rowUpdated.labels) {
    rowUpdated.labels = {};
  }
  if (customerPorts) {
    vlanListUpdated = vlanListUpdated.filter((member) => {
      const matchingPort = customerPorts.find(
        (port) => port.name === member.alias
      );
      if (!member.name && matchingPort) {
        rowUpdated.labels!.remote_type = AWS_LOWERCASE; // non-null assertion
        rowUpdated.labels!.location = matchingPort.labels.cloud_region;
        rowUpdated.labels!.customer = matchingPort.labels.account_id;
        return false;
      }
      return true;
    });
  }

  return { vlanListUpdated, rowUpdated };
}

function searchForAzure(
  vlanList: Array<VlanData>,
  row: VirtualInterfaceApi,
  customerPorts?: Array<TenantCustomerPort>
): { vlanListUpdated: Array<VlanData>; rowUpdated: VirtualInterfaceApi } {
  let vlanListUpdated = [...vlanList];
  const rowUpdated = { ...row };

  if (!rowUpdated.labels) {
    rowUpdated.labels = {};
  }

  if (customerPorts) {
    vlanListUpdated = vlanListUpdated
      .map((member) => {
        const matchingPort = customerPorts.find(
          (port) => port.name === member.name && port.type === AZURE_LOWERCASE
        );

        if (matchingPort) {
          member.type = AZURE_LOWERCASE;
          if (!member.name) {
            rowUpdated.labels!.remote_type = AZURE_LOWERCASE;
            rowUpdated.labels!.location = matchingPort.labels.cloud_region;
            rowUpdated.labels!.customer = matchingPort.labels.account_id;
            return undefined;
          }
        }

        return member;
      })
      .filter((member): member is VlanData => member !== undefined);
  }

  return { vlanListUpdated, rowUpdated };
}

function searchForGcp(
  vlanList: Array<VlanData>,
  row: VirtualInterfaceApi,
  customerPorts?: Array<TenantCustomerPort>
): { vlanListUpdated: Array<VlanData>; rowUpdated: VirtualInterfaceApi } {
  let vlanListUpdated = [...vlanList];
  const rowUpdated = { ...row };

  if (!rowUpdated.labels) {
    rowUpdated.labels = {};
  }

  if (customerPorts) {
    vlanListUpdated = vlanListUpdated
      .map((member) => {
        const matchingPort = customerPorts.find(
          (port) => port.name === member.name && port.type === GCP_LOWERCASE
        );

        if (matchingPort) {
          member.type = GCP_LOWERCASE;
          if (!member.name) {
            rowUpdated.labels!.remote_type = GCP_LOWERCASE;
            rowUpdated.labels!.location = matchingPort.labels.cloud_region;
            rowUpdated.labels!.customer = matchingPort.labels.account_id;
            return undefined;
          }
        }

        return member;
      })
      .filter((member): member is VlanData => member !== undefined);
  }

  return { vlanListUpdated, rowUpdated };
}

function searchForClouds(
  vlanList: Array<VlanData>,
  row: VirtualInterfaceApi,
  customerPorts?: Array<TenantCustomerPort>
): { vlanListUpdated: Array<VlanData>; rowUpdated: VirtualInterfaceApi } {
  let vlanListUpdated = [...vlanList];
  let rowUpdated = { ...row };

  ({ vlanListUpdated, rowUpdated } = searchForAws(
    vlanListUpdated,
    rowUpdated,
    customerPorts
  ));

  ({ vlanListUpdated, rowUpdated } = searchForAzure(
    vlanListUpdated,
    rowUpdated,
    customerPorts
  ));

  ({ vlanListUpdated, rowUpdated } = searchForGcp(
    vlanListUpdated,
    rowUpdated,
    customerPorts
  ));

  return { vlanListUpdated, rowUpdated };
}

export function getAZPortRowInfo(
  row: VirtualInterfaceApi,
  l2Ports: Array<TenantPort>,
  onlyDiagram?: boolean,
  customerPorts?: Array<TenantCustomerPort>
): React.ReactElement {
  const vlanList: Array<VlanData> = getAllVlan(row, l2Ports);
  const { vlanListUpdated, rowUpdated } = searchForClouds(
    vlanList,
    row,
    customerPorts
  );

  const remote = rowUpdated?.labels?.remote_type;
  const isCloud = [AWS_LOWERCASE].includes(remote);

  if (onlyDiagram) {
    return (
      <div className={styles.azWrapper}>
        <CloudDiagram
          connections={rowUpdated.is_dia || isCloud ? [rowUpdated] : []}
          onClick={() => console.warn("click")}
          activeConnection=""
          isCloud={false}
          ports={vlanListUpdated}
          onlyDiagram
        />
      </div>
    );
  }
  return (
    <div className={styles.azWrapper}>
      {isCloud ? (
        <div className={styles.contentAZ}>
          {getCloudRow(rowUpdated, isCloud, l2Ports)}
        </div>
      ) : null}
      {vlanListUpdated.map((port, i) => {
        return (
          <div className={styles.contentAZ} key={port.name}>
            {getPortRow(port, !isCloud && !i, customerPorts)}
          </div>
        );
      })}
      {rowUpdated.is_dia ? (
        <div className={styles.contentAZ}>{getDiaRow(rowUpdated)}</div>
      ) : null}
      <CloudDiagram
        connections={rowUpdated.is_dia || isCloud ? [rowUpdated] : []}
        onClick={() => console.warn("click")}
        activeConnection=""
        isCloud={false}
        ports={vlanListUpdated}
      />
    </div>
  );
}
