import { FC } from "react";
import {
  ChangeField,
  FieldsType,
} from "../../../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import Section from "../../../common/Section";
import Input from "../../../common/formComponents/Input";
import { Checkbox } from "../../../common/formComponents/Checkbox";
import EnabledServiceSection from "./EnabledServiceSection";

type Props = {
  className: string;
  classnameServices: string;
  fields: FieldsType;
  onChange: ChangeField;
  errors?: { [key: string]: string };
};

const ServiceSection: FC<Props> = ({ className, fields, onChange, errors }) => {
  return (
    <Section title={"Enabled Services"} columnsCount={1} className={className}>
      <EnabledServiceSection title={"NETWORK"} columnsCount={2}>
        <Checkbox
          isChecked={fields.is_mesh_enabled || false}
          label="Cloud Router - Multiple locations"
          onChange={() => onChange("is_mesh_enabled", !fields.is_mesh_enabled)}
        />
        <Checkbox
          isChecked={fields.is_nat_service || false}
          label="Address Translation (NAT)"
          onChange={() => onChange("is_nat_service", !fields.is_nat_service)}
        />
        <Checkbox
          isChecked={fields.is_remote_user_service || false}
          label="Remote Users"
          onChange={() =>
            onChange("is_remote_user_service", !fields.is_remote_user_service)
          }
        />
        <Checkbox
          isChecked={fields.is_load_balancing_service || false}
          label="Policy Based Routing (PBR)"
          onChange={() =>
            onChange(
              "is_load_balancing_service",
              !fields.is_load_balancing_service
            )
          }
        />
      </EnabledServiceSection>
      <EnabledServiceSection title={"VISIBILITY"} columnsCount={2}>
        <Checkbox
          isChecked={fields.is_sdr_service || false}
          label="Network Observability"
          onChange={() => onChange("is_sdr_service", !fields.is_sdr_service)}
        />

        <Checkbox
          isChecked={fields.is_fqdn_service || false}
          label="Application Observability"
          onChange={() => {
            onChange("is_secure_web_gw_service", !fields.is_fqdn_service);
            onChange("is_fqdn_service", !fields.is_fqdn_service);
          }}
        />

        <Input
          label={"Sampling rate"}
          name="sampling_rate"
          value={fields.sdr_service_rate}
          medium
          onChange={(e) => onChange("sdr_service_rate", e.target.value)}
          error={errors && errors["sdr_service_rate"]}
        />
        <Checkbox
          isChecked={fields.is_dns_service || false}
          label="DNS"
          onChange={() => onChange("is_dns_service", !fields.is_dns_service)}
        />
      </EnabledServiceSection>
      <EnabledServiceSection title={"SECURITY"} columnsCount={2}>
        <Checkbox
          isChecked={fields.is_firewall_service || false}
          label="Firewall"
          onChange={() =>
            onChange("is_firewall_service", !fields.is_firewall_service)
          }
        />
        <Checkbox
          isChecked={fields.is_dia_fw_service || false}
          label="DIA Firewall"
          onChange={() =>
            onChange("is_dia_fw_service", !fields.is_dia_fw_service)
          }
        />
        <Checkbox
          isChecked={fields.is_secure_web_gw_service || false}
          label="Web Filter"
          onChange={() => {
            onChange(
              "is_secure_web_gw_service",
              !fields.is_secure_web_gw_service
            );
            onChange("is_fqdn_service", !fields.is_secure_web_gw_service);
          }}
        />
        <Checkbox
          isChecked={fields.is_ids_service || false}
          label="Cyber Threats"
          onChange={() => onChange("is_ids_service", !fields.is_ids_service)}
        />
        <Checkbox
          isChecked={fields.is_segments_service || false}
          label="Network Segmentation"
          onChange={() =>
            onChange("is_segments_service", !fields.is_segments_service)
          }
        />
      </EnabledServiceSection>
    </Section>
  );
};
export default ServiceSection;
