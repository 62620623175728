import { classNames } from "../../../../../../helpers/common/classNames";
import styles from "./ZoneSelect.module.scss";

type ZoneSelectProps = {
  onChange: (value: number) => void;
  value: number | undefined;
  hasErrors?: boolean;
};

const OPTIONS = [1, 2];

export const ZoneSelect = ({ value, onChange, hasErrors }: ZoneSelectProps) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        hasErrors && styles.wrapperWithErrors
      )}
    >
      {OPTIONS.map((option) => (
        <div
          role="button"
          key={option}
          className={classNames(
            styles.option,
            option === value && styles.optionActive
          )}
          onClick={() => onChange(option)}
        >
          {option}
        </div>
      ))}
    </div>
  );
};
