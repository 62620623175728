import { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";
import { Package, Service, ServiceAPI } from "../types";
import ShieldIcon from "../../../components/icons/ShieldIcon";
import ApplicationsIcon from "../../../components/icons/topologyButtons/ApplicationsIcon";
import CloudRouterIcon from "../../../components/icons/zayo/menu/CloudRouterIcon";
import FirewallIcon from "../../../components/icons/tabs/FirewallIcon";
import NATIcon from "../../../components/icons/NATIcon";
import NetworkObservabilityIcon from "../../../components/icons/NetworkObservabilityIcon";
import DNSIcon from "../../../components/icons/topologyButtons/DNSIcon";
import DIAIcon from "../../../components/icons/DIAIcon";
import groupBy from "lodash/groupBy";

export const getModifiedServicesList = (
  services: Array<ServiceAPI>
): { [key: string]: Array<Service> } => {
  const groupedList: any = {};

  services.map((s) => {
    if (SERVICES_MAP[s.type]) {
      const currService = { ...s, ...SERVICES_MAP[s.type] };
      groupedList[currService.group] = groupedList[currService.group]
        ? [...groupedList[currService.group], currService]
        : [currService];
      return groupedList;
    }
  }, {});
  return groupedList;
};

export const getFullServiceList = (
  services: Array<ServiceAPI>
): Array<Service> => {
  return services.map((s) => ({ ...s, ...SERVICES_MAP[s.type] }));
};

export const SERVICES_MAP: {
  [key: string]: {
    title: string;
    description: string;
    icon: (props: IconProps) => ReactElement;
    group: string;
    disabled?: true;
  };
} = {
  sdr: {
    title: "Network Observability",
    description:
      "With Network Observability you will be able to understand traffic inside and coming from or going to your network on session level with location, segment, protocol and other information.",
    icon: NetworkObservabilityIcon,
    group: "insights",
  },
  secure_web_gw: {
    icon: ApplicationsIcon,
    title: "Application  Observability",
    description:
      "Enabling Application Observability will inspect all web traffic for application and domain name information to be able to give insights about network usage and communication per segment.",
    group: "insights",
  },
  ids: {
    icon: ShieldIcon,
    title: "Cyber Threats",
    description:
      "Threat Protection will inspect all traffic for attempts to exploit known vulnerabilities and report on malicious targets in the internet. The threat database is updated on a regular basis to keep it up to date with latest threats.",
    group: "insights",
  },
  mesh: {
    icon: CloudRouterIcon,
    title: "Cloud Router",
    description:
      "Connect different branches, clouds and other connections via a virtual Router with BGP support.",
    group: "network",
    disabled: true,
  },
  fqdn: {
    icon: CloudRouterIcon,
    title: "Web Filtering",
    description: "",
    group: "services",
  },
  nat: {
    icon: NATIcon,
    title: "Address Translation (NAT)",
    description:
      "With NAT it is possible to translate IP addresses on the way to hide multiple hosts behind one shared (public) IP or to redirect users to a different server. Supported modes are SNAT, Port Forwarding and 1:1 NAT.",
    group: "network",
  },
  // segments: {
  //   icon: SegmentsIcon,
  //   title: "Network Segmentation",
  //   description:
  //     "When defining connections, adding a segment name to this connection will allow to control communication between segments and Network Observability data will be enriched with segment information.",
  //   group: "services",
  // },
  firewall: {
    icon: FirewallIcon,
    title: "Stateful Firewall",
    description:
      "A Stateful Firewall allows you to control all traffic flows inside your network and those entering or leaving it. A default deny rule prevents all traffic not explicitly allowed by you and because it is stateful, only the server ports need to be opened for desired communications.",
    group: "services",
  },
  dia_fw: {
    icon: DIAIcon,
    title: "DIA Firewall",
    description:
      "DIA Firewall allows you to control all traffic flows inside your network and those entering or leaving it. A default deny rule prevents all traffic not explicitly allowed by you and because it is stateful, only the server ports need to be opened for desired communications.",
    group: "services",
  },
  dns: {
    title: "DNS observability",
    description: "",
    icon: DNSIcon,
    group: "insights",
  },
};

export const getPackageServices = (packagesDefault: Array<Package>) => {
  const packages = packagesDefault.filter(({ is_visible }) => !!is_visible);
  const isOrdered = packages.every(
    (item) => item.labels.order !== null && item.labels.order !== undefined
  );

  if (isOrdered) {
    packages.sort((a, b) => a.labels.order! - b.labels.order!);
  }

  const groupedPackages = groupBy(
    packages,
    (servicePackage) => servicePackage.name
  );
  const names = Object.keys(groupedPackages);
  const services = Object.keys(SERVICES_MAP).map((key) => {
    const enabled = names.map((name) => ({
      name,
      enabled:
        !!groupedPackages[name][0][`is_${key}_service` as keyof Package] ||
        !!groupedPackages[name][0][`is_${key}_enabled` as keyof Package],
      packageName: name,
    }));

    return {
      type: key,
      enable: false,
      ...SERVICES_MAP[key],
      data: { enabled },
    };
  });

  return {
    services: getModifiedServicesList(services),
    packagesNames: packages.map((servicePackage) => ({
      presentationName: servicePackage.labels?.presentation_name,
      name: servicePackage.name,
    })),
  };
};
