import React, { FC, useEffect, useMemo, useState } from "react";
import { NewDialogProps } from "../../../helpers/types";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { isSame } from "../../../helpers/common/submitHelper";
import { formFieldProps } from "../../../helpers/dialogs/FieldProps";
import styles from "./CreateL2Dialog.module.scss";
import { useTenantVirtualInterfaceContext } from "../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import toNumber from "../../../helpers/toNumber";
import { removeUnusedIps } from "../../../components/dialogs/L2Meetering/utils";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import CommonFields from "../../../components/dialogs/L2Meetering/CommonFields";
import Input from "../../../components/common/formComponents/Input";
import L2TypeFields from "../../../components/dialogs/L2Meetering/L2TypeFields";
import SummaryBlock from "./SummaryBlock";
import RadioGroup from "../../../components/common/formComponents/RadioGroup";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { Checkbox } from "../../../components/common/formComponents/Checkbox";
import { BANDWIDTH_UNITS_LIST, KBPS, MBPS } from "../../../helpers/consts";
import { calcBandwidthWithUnits } from "../../../helpers/calcBandwidthWithUnits";
import { transferFromKbps } from "../../../helpers/transferFromKbps";
import { getCorrectBandwidthUnit } from "../../../helpers/getCorrectBandwidthUnit";
import { useValidation } from "../../../helpers/validators/Validator";
import validateMaxBandwidth from "../../../helpers/validators/MaxBandwidthValidator";
import validateL2Interface from "../../../helpers/validators/L2InterfaceValidator";
import { PortType } from "../../PortsPage/types";
import { getVlanOptions } from "./helpers";

export type L2Type = "port_vlan" | "vtep_vni" | "port_interface";

const TRUNK_DESCRIPTION = "Allow the customer to define inside VLANs";

const L2Types = [
  { value: "port_vlan", label: "VLAN" },
  // should be implement later after BE
  // { value: "vtep_vni", label: "VNI" },
  // { value: "port_interface", label: "Port" },
];

const DEFAULT_FIELDS: any = {
  port_vlan: {
    administrative_state: "up",
    mtu: 9000,
    max_bandwidth: 0,
    bw_unit: KBPS,
    tenant_name: "",
    labels: {},
    customer_port_name: "",
    physical_port_name: "",
    vlan_id: 0,
    trunk: true,
  },
  vtep_vni: {
    administrative_state: "up",
    mtu: 9000,
    max_bandwidth: 0,
    vni_id: 0,
    vtep_id: 0,
  },
  port_interface: {
    administrative_state: "up",
    mtu: 9000,
    max_bandwidth: 0,
    physical_port_name: "",
  },
};

type Props = NewDialogProps<Partial<any>> & {
  systemsList: Array<string>;
};

const CreateL2Dialog: FC<Props> = ({ onClose, data, type, systemsList }) => {
  const {
    editThreshold,
    editThresholdStatus,
    addInterfaceStatus,
    resetStatus,
    addInterface,
  } = useTenantVirtualInterfaceContext();

  const isEdit = type === "edit";
  const [system, setSystem] = useState<string>(
    data?.system_name || systemsList[0]
  );
  const [currentL2Type, setCurrentL2Type] = useState<L2Type | string>(
    data?.int_type || "port_vlan"
  );
  const [fields, handleFieldChange, resetFields] = useFormField<any>(
    data || DEFAULT_FIELDS[currentL2Type]
  );
  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields]
  );
  const [vlanRanges, setVlanRanges] = useState<PortType["free"]>();
  const [errors, validate] = useValidation<any>(validateL2Interface, [
    { ...fields, vlanOptions: vlanRanges },
  ]);

  const vlanOptions = useMemo(() => getVlanOptions(vlanRanges), [vlanRanges]);

  useEffect(() => {
    if (currentL2Type && !isEdit) {
      resetFields(DEFAULT_FIELDS[currentL2Type]);
    }
  }, [currentL2Type]);

  useEffect(() => {
    if (data?.requested_bw) {
      const value = data.requested_bw;
      const bandwidthUnit = getCorrectBandwidthUnit(value);
      handleFieldChange("bw_unit", bandwidthUnit);
      handleFieldChange(
        "max_bandwidth",
        transferFromKbps(value, bandwidthUnit)
      );
    }
  }, []);

  const disableSubmit = useMemo(() => isEdit && isSame(data, fields), [
    data,
    fields,
  ]);

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      removeUnusedIps(fields, currentL2Type);

      const calculatedFields = { ...fields };
      calculatedFields.vrf_name = data?.tenant_name;
      calculatedFields.tenant_name = data?.tenant_name;
      calculatedFields.customer_port_name = data?.name;
      calculatedFields.max_bandwidth = calcBandwidthWithUnits(
        fields.max_bandwidth,
        fields.bw_unit
      );
      const success = isEdit
        ? await editThreshold(currentL2Type, system, calculatedFields)
        : await addInterface(currentL2Type, system, calculatedFields);
      if (success) onClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: handleClose,
    disableSubmit: disableSubmit,
  });

  const leftColumn = (
    <SummaryBlock title={"L2 Interface"} data={fields} system={system} />
  );

  return (
    <DialogTemplate
      title={"Create"}
      onClose={onClose}
      controls={controls}
      leftColumn={leftColumn}
      className={styles.formWrapper}
      errorDisplay={isEdit ? editThresholdStatus : addInterfaceStatus}
    >
      {/*
      Temporarily hide until we have more L2 options 
      <div className={styles.radioGroupContainer}>
        <RadioGroup
          className={styles.radioGroup}
          isOneRow
          options={L2Types}
          value={currentL2Type}
          setActiveValue={setCurrentL2Type}
          isNotEditable={isEdit}
        />
      </div> */}
      <div className={styles.column}>
        <CommonFields
          systemsList={systemsList}
          system={system}
          changeSystem={setSystem}
          fieldProps={fieldProps}
          fields={fields}
          onChange={handleFieldChange}
          isEdit
          isOrderedPortsScreen
        />
        <div className={styles.bwInput}>
          <Input
            {...fieldProps("max_bandwidth")}
            label="Max Bandwidth"
            valuePreprocess={toNumber}
            error={errors?.["max_bandwidth"]}
            customErrorClassname={styles.customErrorClassname}
          />
          <DropdownBasic
            id={"l2MaxBandwidthUnit"}
            onChange={(val) => {
              handleFieldChange("bw_unit", val.key);
            }}
            selected={mapStringToItem(fields.bw_unit)}
            label="Units"
            itemsList={BANDWIDTH_UNITS_LIST?.map((key) => mapStringToItem(key))}
            isMedium
            className={styles.shrinkWidthField}
          />
        </div>
      </div>
      <div className={styles.column}>
        <L2TypeFields
          currentL2Type={currentL2Type}
          changeCurrentL2Type={setCurrentL2Type}
          system={system}
          fieldProps={fieldProps}
          fields={fields}
          onChange={handleFieldChange}
          isEdit={isEdit}
          error={errors?.["l2_type_field"]}
          errorVlanId={errors?.["vlan_id"]}
          vlanIdOptions={vlanOptions}
          setSelectedPort={(port) => {
            setVlanRanges(port?.free);
          }}
        />
        <div className={styles.checkboxWrapper}>
          <div className={styles.checkboxField}>
            <Checkbox
              isChecked={fields.trunk}
              label="Trunk Port"
              onChange={() => {
                handleFieldChange("trunk", !fields.trunk);
              }}
            />
          </div>
          <div className={styles.checkboxInfo}>{TRUNK_DESCRIPTION}</div>
        </div>
      </div>
    </DialogTemplate>
  );
};

export default CreateL2Dialog;
