import BGPIcon from "../../../../icons/BGPIcon";
import { CellProps } from "react-table";
import LabelOutline from "../../LabelOutline";
import { StatusNeighborsType } from "../../../../../helpers/api/apiTypes";
import { CellFactory } from "../../newTable/cells/CellsFactory";

const cellFactory = new CellFactory<StatusNeighborsType>({
  icon: BGPIcon,
});

export const bgpRoutesTableHeader = (): any =>
  [
    {
      Header: "Neighbor",
      accessor: "remote_router_ip",
      Cell: cellFactory.idCell(),
    },
    {
      Header: "Remote AS",
      accessor: "remoteAs",
      Cell: ({ row: { original } }: any) => <span>{original.remoteAs}</span>,
    },
    {
      Header: "Address Family",
      accessor: "idType",
      Cell: cellFactory.labelCell(),
    },
    {
      Header: "Status",
      accessor: "state",
      Cell: cellFactory.statusWithIconCell(),
    },
    {
      Header: "Prefixes",
      accessor: "prefixes",
      Cell: ({ row: { original } }: CellProps<StatusNeighborsType>) => {
        const leftPfx = original.pfxRcd;
        const rightPfx = original.pfxSnt;
        return (
          <LabelOutline type="lavenderBorder">
            {leftPfx}/{rightPfx}
          </LabelOutline>
        );
      },
    },
    {
      Header: "Up/Down",
      accessor: "peerUptime",
      Cell: ({ row: { original } }: any) => <span>{original.peerUptime}</span>,
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof StatusNeighborsType,
  }));
