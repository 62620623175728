import React, { CSSProperties, ReactChild, ReactElement } from "react";
import { GTimeRange } from "../components/common/charts/GraphanaLinksFactory";
import { UserRoles } from "./rolesHelpers";

export type Status = "active" | "inactive" | "unknown" | string;

export type RequestState = "pending" | "ok" | "error" | "idle";

export type RequestStatus = {
  state: "pending" | "ok" | "error" | "idle";
  message?: string;
};

export type IconProps = {
  color?: string;
  indicatorColor?: string;
  status?: Status;
  className?: string;
  onClick?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  opacity?: number;
  strokeWidth?: number;
  style?: CSSProperties;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
};

export type TabsProps = {
  disabledVrf?: boolean;
  hideVrf?: boolean;
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  rightComponent?: ReactElement;
  hideControls?: boolean;
  tabs?: Array<PathEntry>;
};

export type PageProps = {
  tabs: React.FC<TabsProps>;
  additionParameter?: string | number; // todo remove
  type?: any;
};

export type ComponentProps = {
  className?: string;
  style?: CSSProperties;
};

export type TimedDataArr = Array<{
  [key: string]: number;
  time: number;
}>;

export type ChartData = {
  color: string;
  data: TimedDataArr;
  valueKey?: string;
  isHidden?: boolean;
};

export type LegendMap = {
  [key: string]: {
    color: string;
    order?: number;
    isHidden?: boolean;
    text?: string;
  };
};

export type PathEntry = {
  content: string | ReactChild;
  path: string;
  directParent?: PathEntry;
};

export type PathArray = {};

export type ContentType = ReactElement | string;

export type InfoHeaderType = { icon?: ReactElement; title: string };
export type InfoBodyType = React.ReactChildren;
export type InfoBodyLineType = { content: ContentType; subtitle: string };

export type LoginData = {
  username: string;
  password: string;
};

export type PaginateListResponse<P> = {
  count: number;
  items: Array<P>;
};

export type DonutChartItem = {
  name: string;
  amount: number;
};

export type LabelOutlineTypes =
  | "green"
  | "greenBorder"
  | "blue"
  | "lightBlue"
  | "lavender"
  | "lavenderBorder"
  | "pink"
  | "critical"
  | "warning"
  | "warningBorder"
  | "font"
  | "hint"
  | "interface"
  | "accent"
  | "entity";

export type LoopbackType = {
  id: number;
  status: string;
  name: string;
  interfaceDescription: string;
  vrfMapping: string;
  ipv4: Array<string>;
  ipv6: Array<string>;
};

export type NatpoolType = {
  id: number;
  status: string;
  name: string;
  interfaceDescription: string;
  vrfMapping: string;
  ipv4: Array<string>;
  ipv6: Array<string>;
};

export type Characteristic = {
  v: number;
  as: number;
  msgRcvd: number;
  msgSent: number;
  tblVer: number;
  inQ: number;
  outQ: number;
  upDown: Date;
  statePfxRcd: string;
  pfxSnt: number;
};

export type Prefixes = {
  v4: number;
  v6: number;
};

export type StaticRoutesBodyType = {
  vrf: number | string;
  destination_prefix: string;
  gateway_ip_address: string;
  description: string;
  distance: number;
};

export type PortType = {
  id: number;
  status: string;
  name: string;
  description: string;
  lagId: undefined | number;
  vlans: Array<string | number>;
  speed: number;
  operationalState: string;
  adminState: string;
};

export type NewDialogProps<P> = {
  onClose: () => void;
  type?: "add" | "edit";
  data?: P | undefined;
  onDelete?: () => void;
};

export type TenantStepType = { short: string; full: string };
export type IdentityStepType = { short: string; full: string };

// todo remove
export type DialogProps<P> = {
  onClose: () => void;
  dialogTitle: DialogTitle;
  data?: P | undefined;
  tenant?: string;
  setIsDeleteDialogShown?: (arg: boolean) => void; // todo remove
  onDelete?: () => void;
};

export type SummaryBlockProps = {
  title: string;
  dialogTitle: DialogTitle;
  onClick: () => void;
};

export type DialogTitle = "add" | "edit" | "config" | "next"; // todo remove
export type DialogType = "add" | "edit";

export type DialogControlsType = {
  leftPart?: React.ReactNode;
  okText: string;
  onOk: () => void;
  cancelText?: string;
  onCancel?: () => void;
  disableSubmit?: boolean;
};

export enum SortingTypes {
  Asc,
  Desc,
}

export enum DialogEntity {
  systemLoopback,
  systemNatpool,
  systemBGP,
  systemStatic,
}

export type DataForScheme = {
  id: number;
  name: string;
  status: string;
  isActive: boolean;
};

export type SizeType = "small" | "medium" | "large";

export type BRANCH = "branch";
export type INTERNET = "internet";
export type CLOUD = "cloud";
export type REMOTE = "remote";
export type IPSEC = "ipsec";
export type EndpointType = BRANCH | INTERNET | CLOUD | REMOTE | IPSEC;

export type DropdownItem<D = any> = {
  value?: any;
  icon?: JSX.Element;
  key: string;
  data?: D;
  disabled?: boolean;
  isEditable?: boolean;
  subtitle?: string;
};

export type OverlayedRightBlockType = {
  title: string;
  body: ReactElement;
  footer: ReactElement;
  isVisible: boolean;
  toggleVisibility: (arg: boolean) => void;
};

export type TimerangeContextType = {
  setTimeRange: (timeRange: GTimeRange, isManual?: boolean) => void;
  isManual: boolean;
  timeRange: GTimeRange;
};

export type TrafficType = "bps" | "pps" | "ccn" | "cps";
export type LegendValue = { val: number | string; unit?: string };

// stats types
export type TimeRangeType = { from?: string; to?: string };

export type StatsOptions<T extends string = string> = {
  range?: TimeRangeType;
  count?: number;
  type: T;
};

export type StatsParams = {
  tenant: string;
};

export type StatsFetch = (params: StatsParams, options: StatsOptions) => void;

export type UserGroup = {
  id?: number;
  name: string;
  users: Array<string>;
  subnets: Array<string>;
  ip_addresses?: Array<string>;
};

export type UpdateUserGroup = {
  subnets: Array<string>;
  users: Array<string>;
};

export type User = {
  id: string;
  enabled: boolean;
  username: string;
  email: string;
  role: UserRoles | string;
  full_name: string;
  first_name: string;
  last_name: string;
  phone: string;
  description: string;
  remaining_failed_attempts: number;
  is_remote_user: boolean;
  password_is_expired?: boolean;
  password?: string;
  user_groups?: Array<string>;
  tunnel_status?: string;
};

export type RemoteUserStatus = {
  username: string;
  location: string;
  handshake: number;
  ip: string;
  status: string;
  city?: string;
  country_flag?: string;
};

export type ServiceType =
  | "sdr"
  // TODO: remove dpi when it's renamed to secure_web_gw
  | "dpi"
  | "ids"
  | "fqdn"
  | "nat"
  | "segments"
  | "firewall"
  | "dia_fw"
  | "dns"
  | "mesh"
  | "secure_web_gw";
