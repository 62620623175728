import React from "react";
import ReactTooltip from "react-tooltip";
import { VirtualInetrfaceDIA } from "../../helpers/api/TenantVirtualInterfaceApi/types";

type Props = {
  rowOriginal: VirtualInetrfaceDIA;
};

export const CellHoverTooltip = ({ rowOriginal }: Props) => {
  const tooltipId = rowOriginal.name;

  return (
    <ReactTooltip
      aria-haspopup="true"
      place="bottom"
      type="light"
      id={tooltipId}
    >
      {rowOriginal.name}
    </ReactTooltip>
  );
};
