import dayjs from "dayjs";

export const getDateWithTime = (value: Date): string => {
  return dayjs(value).format("DD/MM/YY HH:mm:ss");
};

export const getTime = (value: Date): string => {
  return dayjs(value).format("HH:mm");
};

// For Header
export const getDateWithTimeAndWeekday = (value: Date): string => {
  return dayjs(value).format("ddd, MMM D HH:mm");
};

// For Failover
export const getDateShortMonth = (value: Date): string => {
  return dayjs(value).format("MMM D HH:mm");
};

// h-d-m
export const getTimeFormMS = (ms: number): string => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));

  return `${days}d${hours}h${minutes}m`;
};
