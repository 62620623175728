import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import AWSLogo from "./zayo/clouds/AWSLogo";
import AzureLogo from "./zayo/clouds/AzureLogo";
import GCPLogo from "./zayo/clouds/GCPLogo";
import SalesforceLogo from "./zayo/clouds/SalesforceLogo";
import IBMCloudLogo from "./zayo/clouds/IBMCloudLogo";
import OracleLogo from "./zayo/clouds/OracleLogo";
import StatusIcon from "./StatusIcon";
import {
  AWS_LOWERCASE,
  AZURE_LOWERCASE,
  GCP_LOWERCASE,
} from "../../helpers/consts";

type IconType = (props: IconProps) => ReactElement;

export const getCloudIcon = (id: string): IconType => {
  // eslint-disable-next-line react/display-name
  if (!id) return () => <></>;

  switch (id) {
    case AWS_LOWERCASE:
      return AWSLogo;
    case AZURE_LOWERCASE:
      return AzureLogo;
    case GCP_LOWERCASE:
      return GCPLogo;
    case "salesforce":
      return SalesforceLogo;
    case "ibmcloud":
      return IBMCloudLogo;
    case "oracle":
      return OracleLogo;
    default:
      return StatusIcon;
  }
};

type CloudProviderIconProps = {
  id: string;
} & IconProps;

function CloudProviderIcon({
  id,
  ...iconProps
}: CloudProviderIconProps): ReactElement {
  const Icon = getCloudIcon(id);
  return <Icon {...iconProps} />;
}

export default CloudProviderIcon;
