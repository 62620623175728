import React, { FC } from "react";
import { Checkbox } from "../../../../../../components/common/formComponents/Checkbox";
import { classNames } from "../../../../../../helpers/common/classNames";
import styles from "./ServicesBlock.module.scss";
import TextWithIcon from "../../../../../../components/common/table/TextWithIcon";
import { ServiceAPI } from "../../../../../ServicesPreferencesPage/types";
import { ACCENT } from "../../../../../../helpers/common/colorAliases";
import { getServiceDetails } from "./getServicesDetails";
type Props = {
  className: string;
  services: Array<ServiceAPI>;
  setServices: (value: Array<ServiceAPI>) => void;
};
const ServicesBlock: FC<Props> = ({ className, services, setServices }) => {
  const handleClick = (i: number) => {
    const newValues = [...services];
    newValues[i].enable = !newValues[i].enable;
    setServices(newValues);
  };

  const containerClasses = classNames(styles.wrapper, className);
  return (
    <div className={containerClasses}>
      <div className={styles.title}>Services</div>
      <ul className={styles.listWrapper}>
        {services.map((service, i) => {
          const details = getServiceDetails(service.type);
          if (!details) return null;
          const Icon = details.icon;
          const title = details.title;
          return (
            <li key={title}>
              <Checkbox
                containerClassName={styles.item}
                isChecked={service.enable}
                onChange={() => {
                  handleClick(i);
                }}
                value={service.type}
                label={
                  <TextWithIcon icon={<Icon color={ACCENT} />} text={title} />
                }
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ServicesBlock;
