import { AzureNNI, RegionItem, TotalProviderInfo } from "../../types";
import Table from "../../../../components/common/table/newTable/Table";
import { azureNNITableHeader, azureRegionsTableHeader } from "./tableHeaders";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { TableWrapper } from "../TableWrapper";
import useDeleteDialog from "../../../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { RequestStatus } from "../../../../helpers/types";

type AzureTableProps = {
  azureNNIs: Array<AzureNNI> | undefined;
  combinedAzureInfo: {
    regions: Array<RegionItem>;
    total: TotalProviderInfo;
  };
  refetchAzureState: () => Promise<void>;
  isAzureRefetching: boolean;
  deleteNNI: (id?: string) => Promise<void>;
  deleteStatus: RequestStatus;
};

export const AzureTable = ({
  azureNNIs,
  combinedAzureInfo,
  refetchAzureState,
  isAzureRefetching,
  deleteNNI,
  deleteStatus,
}: AzureTableProps) => {
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<AzureNNI>((row) => deleteNNI(row.nni_id), deleteStatus);

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Azure NNI"}
        name={selectedToDelete?.nni_id}
      />
      <TableWrapper
        items={azureNNIs}
        nniTab={
          <Table
            columns={azureNNITableHeader()}
            header={TableHeader}
            data={azureNNIs || []}
            gridColumnTemplate={`110px 160px 310px 180px 180px 120px 160px 160px 110px 100px`}
            withoutPagination
            rowActions={{ onDelete: tryDelete }}
          />
        }
        regionTab={
          <Table
            columns={azureRegionsTableHeader()}
            header={TableHeader}
            data={combinedAzureInfo.regions || []}
            gridColumnTemplate={`110px 140px 160px 140px 180px 180px 110px`}
            withoutPagination
          />
        }
        refetch={refetchAzureState}
        isRefetching={isAzureRefetching}
      />
    </>
  );
};
