import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../../helpers/navigation";
import { LOOKING_GLASS } from "../../../helpers/navigation/entries";
import { Routes } from "../../../pages/common";
import LookingGlass from "../../../pages/Network/LookingGlass";
import { RouteEntry } from "../../../pages/types";

const routes: Array<RouteEntry> = [
  { pathFunc: LOOKING_GLASS, component: LookingGlass },
];

export default function LookingGlassRoutes(): ReactElement {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(LOOKING_GLASS(), path)} />
      </Route>
      <Routes
        routes={routes}
        tabs={(<></>) as any}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
}
