import { CellProps } from "react-table";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./tenantTableHeader.module.scss";
import { CellFactory } from "../../../components/common/table/newTable/cells/CellsFactory";
import { Tenant } from "../types";
import Label from "../../../components/common/table/Label";
import getIconFromValue from "../../../helpers/getIconFromValue";
import { capitalizeFirstLetter } from "../../../helpers/common/stringHelpers";
import { Checkbox } from "../../../components/common/formComponents/Checkbox";

const cellFactory = new CellFactory<Tenant>({});

export const tenantTableHeader = (
  onChangeService: (row: Tenant, id: string) => void,
  onClickPackage: (value: any, name: string) => void
): Array<any> => {
  return [
    {
      Header: "Tenant name",
      accessor: "name",
    },
    {
      Header: "Gateway performance",
      accessor: "max_bandwidth",
      Cell: ({ cell: { value } }: CellProps<Tenant>) => {
        return value ? `${value}Mbps` : "Unlimited";
      },
    },
    {
      Header: "Package",
      accessor: "quota_package_name",
      Cell: ({ row, cell: { value } }: CellProps<Tenant>) => {
        return (
          <Label
            onClick={() => onClickPackage(value, row.original.name)}
            className={styles[value.toLowerCase()]}
          >
            <div className={styles.labels}>
              <span className={styles.iconWrapper}>
                {getIconFromValue(value.toLowerCase())}
              </span>
              <span className={styles.value}>
                {capitalizeFirstLetter(value)}
              </span>
            </div>
          </Label>
        );
      },
    },
    {
      Header: "Interfaces",
      accessor: "interfaces",
      Cell: cellFactory.labelArrayCell("Interfaces", true),
    },
    {
      Header: "Systems",
      accessor: "systems",
      Cell: cellFactory.labelArrayCell("Systems", true),
    },
    {
      Header: "Firewall",
      accessor: "is_firewall_service",
      Cell: ({ row, cell: { value, column } }: CellProps<Tenant>) => (
        <Checkbox
          isChecked={value}
          onChange={() => {
            onChangeService(row.original, column.id);
          }}
        />
      ),
    },
    {
      Header: "DIA Firewall",
      accessor: "is_dia_fw_service",
      Cell: ({ row, cell: { value, column } }: CellProps<Tenant>) => (
        <Checkbox
          isChecked={value}
          onChange={() => {
            onChangeService(row.original, column.id);
          }}
        />
      ),
    },
    {
      Header: "Address Translation (NAT)",
      accessor: "is_nat_service",
      Cell: () => (
        <Checkbox isChecked={true} onChange={() => undefined} disabled />
      ),
    },
    {
      Header: "Cyber Threats",
      accessor: "is_ids_service",
      Cell: ({ row, cell: { value, column } }: CellProps<Tenant>) => (
        <Checkbox
          isChecked={value}
          onChange={() => {
            onChangeService(row.original, column.id);
          }}
        />
      ),
    },
    {
      Header: "Network Segmentation",
      accessor: "is_segments_service",
      Cell: ({ row, cell: { value, column } }: CellProps<Tenant>) => (
        <Checkbox
          isChecked={value}
          onChange={() => {
            onChangeService(row.original, column.id);
          }}
        />
      ),
    },
    {
      Header: "Load Balancer",
      accessor: "is_load_balancing_service",
      Cell: () => (
        <Checkbox isChecked={false} onChange={() => undefined} disabled />
      ),
    },
    {
      Header: "Web Filtering",
      accessor: "is_fqdn_service",
      Cell: ({ row, cell: { value, column } }: CellProps<Tenant>) => (
        <Checkbox
          isChecked={value}
          onChange={() => {
            onChangeService(row.original, column.id);
          }}
        />
      ),
    },
    {
      Header: "Application Observability",
      accessor: "is_secure_web_gw_service",
      Cell: ({ row, cell: { value, column } }: CellProps<Tenant>) => (
        <Checkbox
          isChecked={value}
          onChange={() => {
            onChangeService(row.original, column.id);
          }}
        />
      ),
    },
    {
      Header: "Multi System Tenant",
      accessor: "is_mesh_enabled",
      Cell: ({ row, cell: { value, column } }: CellProps<Tenant>) => (
        <Checkbox
          isChecked={value}
          onChange={() => {
            onChangeService(row.original, column.id);
          }}
        />
      ),
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof Tenant,
  }));
};
