import * as React from "react";
import { VirtualInterfaceApi } from "../../../../VirtualInterfacePage/types";
import {
  getLocationPosition,
  PosProps,
  STAR_WIDTH,
} from "../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/utils";
import CloudCircle from "./icons/CloudCircle";
import { getIconAndText } from "../../../Connections/helpers/getConnectionType";
import { parseVlan, VlanData } from "./CloudsTabInfo";
import { useUserContext } from "../../../../../contexts/UserContext";
import PortVlanIcon from "../Ports/icons/PortVlanIcon";
import PortBaseIcon from "../Ports/icons/PortBaseIcon";
import InterfaceConnector from "../../../../WizardToolPage/ConnectionStep/components/ConnectionsScheme/parts/InterfaceConnector";
import DirectCenter from "./icons/DirectCenter";
import styles from "./Diagram.module.scss";
import { parsePortVlan } from "./helpers";
import { AZURE_LOWERCASE, GCP_LOWERCASE } from "../../../../../helpers/consts";
import AzureIcon from "../../../../../components/icons/cloudConnections/AzureIcon";
import GCPIcon from "../../../../../components/icons/interfaceTypes/GCPIcon";

const center = {
  x: STAR_WIDTH / 4,
  y: 160,
};

function getIcon(item: any, isConnection = true, pos: PosProps) {
  const x = pos.x - 19;
  const y = pos.y - 19;
  if (isConnection) {
    return getIconAndText(
      item.type,
      item.virtual_interface_type,
      item?.labels?.remote_type,
      item.is_ipsec_service,
      item.is_nat_service,
      item.is_snat_service,
      x,
      y,
      item.is_dia
    )[0];
  } else {
    if (item?.type === AZURE_LOWERCASE) {
      return <AzureIcon x={x} y={y} />;
    }
    if (item?.type === GCP_LOWERCASE) {
      return <GCPIcon x={x} y={y} />;
    }
    return item?.vlan_id ? (
      <PortVlanIcon x={x} y={y} />
    ) : (
      <PortBaseIcon x={x} y={y} />
    );
  }
}

export function getAPortAsPort(ports: Array<VlanData>, isCloud = false) {
  let vlanA: number | undefined = 0;
  ports.forEach((vlan, i) => {
    const vlanName = vlan.name?.split("_");
    if (vlanName?.[vlanName?.length - 1] === "a") {
      vlanA = i;
      return;
    }
  });
  return vlanA === undefined ? undefined : ports[vlanA];
}

export default function Component(
  connections: Array<VirtualInterfaceApi>,
  onClick: (name: string) => void,
  activeConnection: string,
  ports: Array<VlanData>
) {
  const { isZayo } = useUserContext();
  const aPort: any = connections?.length
    ? connections[0]
    : getAPortAsPort(ports);
  const { max_bandwidth } = aPort?.max_bandwidth
    ? { max_bandwidth: aPort.max_bandwidth }
    : parseVlan(aPort);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      viewBox={`0 0 ${STAR_WIDTH} 320`}
      className={styles.wrapper}
    >
      {connections.map((connection, idx) => {
        const isA = aPort && aPort.name === connection.name;
        const position = getLocationPosition(
          connections.length + ports.length,
          idx,
          0.5,
          1,
          center,
          isA
        );
        const icon = getIcon(connection, true, position);
        const { vlan_id } = parseVlan(connection);
        return (
          <>
            <InterfaceConnector
              from={center}
              to={position}
              key={connection.name}
            />
            <CloudCircle
              {...position}
              key={connection.name}
              onClick={() => onClick(connection.name)}
              location={connection.name}
              icon={icon}
              active={activeConnection === connection.name}
              vlan={vlan_id}
            />
          </>
        );
      })}
      {ports.map((port, i) => {
        const isA = aPort && aPort.name === port.name;
        const position = getLocationPosition(
          connections.length + ports.length,
          i + connections.length,
          isA ? -1 : -3,
          isA ? 1 : 1.5,
          center,
          isA
        );
        if (!isA) {
          position.x += STAR_WIDTH / 2;
        }
        const icon = getIcon(port, false, position);
        const vlan = parsePortVlan(port);
        return (
          <>
            <InterfaceConnector from={center} to={position} key={port.name} />
            <CloudCircle
              {...position}
              key={port.name}
              location={port.name}
              icon={icon}
              vlan={vlan}
            />
          </>
        );
      })}
      <DirectCenter
        location={`BW ${max_bandwidth || "-"}`}
        x={STAR_WIDTH / 2}
        y={center.y}
        isZayo={isZayo}
        labelWidth={120}
        labelHeight={16}
      />
    </svg>
  );
}
