import { BRIDGED } from "../../../helpers/consts";
import {
  CONNECTIONS,
  LOCATIONS,
  SERVICES,
} from "../../../helpers/navigation/entries";

export const VI_BODY = {
  id: 0,
  name: "",
  ipv4_addresses: [],
  ipv6_addresses: [],
  virtual_interface_type: BRIDGED,
  mtu: 9000,
  administrative_state: "up",
  operational_state: "up",
  description: "",
  members: [],
};

export const STEPS_LINKS = [LOCATIONS(), CONNECTIONS(), SERVICES()];
export const STEPS_TITLES_MAP = [
  "Select your desired location",
  "Add Your Connections to Locations",
  "Define Services",
];

export const IPSEC_BODY = {
  name: "",
  remote_ip: "",
  remote_networks: [""],
  local_networks: ["0.0.0.0/0"],
  psk: "",
  local_id_type: "ipv4",
  local_id_data: "",
  remote_id_type: "ipv4",
  remote_id_data: "",
  virtual_interface_gate_name: "",
  network_segments: [""],
  labels: {},
};

export const IPSEC_SERVICE_BODY = {
  name: "",
  remote_ip: "",
  remote_networks: [""],
  local_networks: ["0.0.0.0/0"],
  dh_groups: [18],
  encryption: "aes256gcm",
  auth: "prfsha256",
  psk: "",
  passive: false,
  udp_encap: true,
  nat_traversal: true,
  local_id_type: "ipv4",
  local_id_data: "",
  remote_id_type: "ipv4",
  remote_id_data: "",
  description: "",
  administrative_state: "up",
  virtual_interface_gate_name: "",
  network_segments: [""],
  labels: {},
};

export const NAT_RULE_BODY = {
  name: "",
  rule_type: "SOURCE_NAT",
  inside_type: "segment",
  inside: "",
  outside: "",
  description: "",
};
