import React, { FC, useEffect, useState } from "react";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { CELL_STATUS_MAP } from "../../helpers/consts";
import { useSegmentsRulesContext } from "../../SegmentsRulesContext";
import CustomInput from "../CellDropdown/CustomInput";
import DenyAllDialog from "../DenyAllDialog/DenyAllDialog";
import styles from "./SegmentAccessCell.module.scss";
import PseudoDropdown from "../../../../components/common/PseudoDropdown/PseudoDropdown";

type Props = {
  initialStatus: string;
  cell: { x: number; y: number; id: number };
};

export const SegmentAccessCell: FC<Props> = ({ initialStatus, cell }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { setSegment, createAllowAllRule } = useSegmentsRulesContext();
  const [cellStatus, setCellStatus] = useState<string>();
  const [showDenyAllDialog, setShowDenyAllDialog] = useState<boolean>(false);

  // Set initial cellStatus
  useEffect(() => {
    setCellStatus(initialStatus);
  }, [initialStatus]);

  // Change cell status
  const handleChangeStatus = (val: string) => {
    switch (val) {
      case "custom":
        setCellStatus(val);
        setSegment({ ...cell, status: val });
        return;
      case "deny":
        if (initialStatus !== val) {
          setShowDenyAllDialog(true);
        }
        return;
      case "allow":
        setCellStatus(val);
        setSegment({ ...cell, status: val });
        if (initialStatus !== val) {
          createAllowAllRule(cell, selectedTenant);
        }
        return;
    }
  };

  return (
    <>
      {showDenyAllDialog && (
        <DenyAllDialog
          onClose={() => setShowDenyAllDialog(false)}
          cell={cell}
        />
      )}
      <div className={styles.cell}>
        <PseudoDropdown
          id={"segment"}
          customInput={CustomInput}
          value={cellStatus?.toLowerCase() || ""}
          list={CELL_STATUS_MAP}
          onChange={(val) => handleChangeStatus(val)}
        />
      </div>
    </>
  );
};
