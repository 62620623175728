/* eslint-disable css-modules/no-unused-class */
import React, {
  ChangeEvent,
  FC,
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  ReactChild,
  useCallback,
  useMemo,
  useState,
} from "react";

import styles from "./Input.module.scss";
import { classNames } from "../../../helpers/common/classNames";
import { OptionalLabel } from "../OptionalLabel";
import WarningIcon from "../../icons/WarningIcon";
import IconBlankButton from "../buttons/IconBlankButton";
import { GREY_FONT, PURPLE } from "../../../helpers/common/colorAliases";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside";
import EyeIcon from "../../icons/EyeIcon";
import CloseIcon from "../../icons/CloseIcon";

export const types = {
  INPUT: "input",
  PASSWORD: "password",
  NUMBER: "number",
};

export type InputProps = {
  value?: number | string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  className?: string;
  placeholder?: string;
  name?: string | number | symbol;
  disabled?: boolean;
  searchable?: boolean;
  error?: string;
  label?: string | ReactChild;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  medium?: boolean;
  inputExternalClass?: string;
  inputInternalClass?: string;
  onClick?: () => void;
  isPlaceholderAlwaysVisible?: boolean;
  isNotEditable?: boolean;
  isOptional?: boolean;
  handleFieldChange?: any;
  tipMessage?: string;
  min?: number;
  max?: number;
  rigthButtonClick?: () => void;
  rigthButtonIcon?: any;
  leftIcon?: React.ReactElement;
  valuesList?: Array<string | number>;
  isFullValue?: boolean;
  valuePreprocess?: (val: string) => string | number;
  togglePasswordVisibility?: boolean;
  withDeleteButton?: boolean;
  onDelete?: () => void;
  errorWithTooltip?: boolean;
  id?: string;
  listWrapperClass?: string;
  withClearIcon?: boolean;
  customErrorClassname?: string;
};
function InputComponent(
  props: InputProps,
  ref: ForwardedRef<HTMLInputElement>
): React.ReactElement {
  const {
    value,
    onChange,
    onClick,
    onBlur,
    type = types.INPUT,
    placeholder,
    className,
    disabled,
    error,
    onKeyDown,
    label,
    name,
    medium,
    inputExternalClass,
    inputInternalClass,
    isPlaceholderAlwaysVisible,
    isNotEditable,
    isOptional,
    handleFieldChange,
    tipMessage,
    min,
    max,
    rigthButtonClick,
    rigthButtonIcon,
    valuesList,
    isFullValue,
    valuePreprocess,
    togglePasswordVisibility,
    withDeleteButton,
    onDelete,
    errorWithTooltip,
    id,
    listWrapperClass,
    leftIcon,
    withClearIcon,
    customErrorClassname,
    ...restProps
  } = props;
  const [showList, setShowList] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const dropDownRef = useClickOutside(() => setShowList(false));

  const inputType = useMemo(() => {
    if (togglePasswordVisibility) {
      return showPassword ? "text" : "password";
    }

    return type;
  }, [togglePasswordVisibility, showPassword]);

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      const val = valuePreprocess ? valuePreprocess(value) : value;
      return handleFieldChange?.(name, val);
    },
    [handleFieldChange, valuePreprocess]
  );

  const handleChange = (val: any) => {
    onChange ? onChange(val) : handleInput(val);
  };

  const handleListClick = (value: string | number) => {
    setShowList(false);
    handleFieldChange(name, value);
  };

  const wrapperClassName = classNames(
    styles.inputWrapper,
    disabled && styles.disabled,
    error && styles.error,
    !Boolean(label) && className,
    medium && styles.medium,
    isNotEditable && styles.notEditable,
    inputInternalClass
  );

  const getFieldValue = () => {
    if (!value && disabled) {
      return (
        <div className={styles.valueWrapper}>
          <div className={styles.noValue}>{"-"}</div>
        </div>
      );
    }

    const handleEnter = (event: any) => {
      if (event.key === "Enter" && event.target.form) {
        const form = event.target.form;
        const index = [...form].indexOf(event.target);
        if (form.elements[index + 1] !== form.elements[form.elements.length]) {
          form.elements[index + 1].focus();
        }
        event.preventDefault();
      }
    };

    return (
      <div
        className={classNames(
          styles.valueWrapper,
          isPlaceholderAlwaysVisible && styles.withInnerLabel
        )}
      >
        {isPlaceholderAlwaysVisible &&
          !Boolean(rigthButtonClick || togglePasswordVisibility) && (
            <label
              htmlFor={`id-${id || (name as string)}`}
              className={styles.innerLabel}
            >
              {placeholder}
            </label>
          )}
        {leftIcon}
        <input
          id={`id-${id || (name as string)}`}
          type={inputType}
          min={min}
          max={max}
          disabled={disabled || isNotEditable}
          placeholder={isPlaceholderAlwaysVisible ? "" : placeholder}
          className={classNames(styles.value, inputExternalClass)}
          name={name as string}
          value={isFullValue ? value : value?.toString().trim()}
          onChange={handleChange}
          onKeyDown={onKeyDown || handleEnter}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick?.();
            setShowList((prev) => !prev);
          }}
          ref={ref}
          onBlur={onBlur}
        />
        {withClearIcon && value && (
          <div className={styles.closeBtnWrapper}>
            <CloseIcon
              color={PURPLE}
              className={styles.closeIconClassProp}
              onClick={() => {
                handleChange("");
              }}
            />
          </div>
        )}
        {isPlaceholderAlwaysVisible &&
          Boolean(rigthButtonClick || togglePasswordVisibility) && (
            <label
              htmlFor={`id-${id || (name as string)}`}
              className={styles.innerLabel}
            >
              {placeholder}
            </label>
          )}
        {(rigthButtonClick || togglePasswordVisibility) && (
          <IconBlankButton
            id={id || (name as string)}
            className={styles.rightButton}
            onClick={() =>
              togglePasswordVisibility
                ? setShowPassword((prev) => !prev)
                : rigthButtonClick && rigthButtonClick()
            }
            icon={togglePasswordVisibility ? EyeIcon : rigthButtonIcon}
            isOutlined
            color={GREY_FONT}
          />
        )}
      </div>
    );
  };

  return (
    <OptionalLabel
      isOptional={isOptional}
      tipMessage={tipMessage}
      className={classNames(
        className,
        styles.inputComponentLabel,
        medium && styles.medium
      )}
      isShown={Boolean(label)}
      text={label}
      medium={medium}
      withDelete={withDeleteButton}
      onDelete={onDelete}
      {...restProps}
    >
      <div
        className={wrapperClassName}
        data-cy="input-wrapper"
        ref={dropDownRef as React.RefObject<HTMLDivElement>}
      >
        {getFieldValue()}
        {valuesList && showList && (
          <AutocompleteList
            values={valuesList}
            medium={medium}
            onClick={handleListClick}
            listWrapperClass={listWrapperClass}
          />
        )}
        {!errorWithTooltip &&
          error &&
          !rigthButtonIcon &&
          !togglePasswordVisibility && <WarningIcon color="var(--orange)" />}
        {!errorWithTooltip && error && (
          <span
            className={classNames(customErrorClassname, styles.errorText)}
            title={error}
            data-cy="input-error"
          >
            {rigthButtonIcon && (
              <WarningIcon color="var(--orange)" className={styles.errorIcon} />
            )}
            {error}
          </span>
        )}
        {error && errorWithTooltip && (
          <IconBlankButton
            id={`error-${name as string}`}
            icon={WarningIcon}
            color="var(--orange)"
            hoverColor="var(--orange)"
            hint={error}
          />
        )}
      </div>
    </OptionalLabel>
  );
}

export default forwardRef(InputComponent);

type ListProps = {
  values: Array<string | number>;
  medium?: boolean;
  onClick: (val: string | number) => void;
  listWrapperClass?: string;
};

const AutocompleteList: FC<ListProps> = ({
  values,
  medium,
  onClick,
  listWrapperClass,
}) => {
  const wrapperClass = classNames(
    styles.listWrapper,
    listWrapperClass,
    medium && styles.medium
  );

  return (
    <div className={wrapperClass}>
      <div className={styles.list}>
        {values.map((el: string | number) => (
          <div
            key={el}
            className={styles.listItem}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick && onClick(el);
            }}
          >
            {el}
          </div>
        ))}
      </div>
    </div>
  );
};
