import React, { FC, useState } from "react";
import { CreateIdentity } from "./IdentityDialog";
import Section from "../../../../components/common/Section";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./AuthSection.module.scss";
import { ProviderData } from "../../../LoginPage/components/SSOProvidersComponent";
import GoogleIcon from "../../../../components/icons/GoogleIcon";
import AzureIDIcon from "../../../../components/icons/AzureIDIcon";
import SamlIcon from "../../../../components/icons/SamlIcon";
import { classNames } from "../../../../helpers/common/classNames";
import OktaAuthIcon from "../../../../components/icons/OktaAuthIcon";

const providers: Array<ProviderData> = [
  {
    title: "Google Workspace",
    icon: <GoogleIcon height={"2rem"} width={"2rem"} />,
    desc: "Authenticate via Google Workspace",
  },
  {
    title: "Microsoft Azure AD",
    icon: <AzureIDIcon />,
    desc: "Authenticate via Azure AD",
  },
  {
    title: "Okta",
    icon: <OktaAuthIcon />,
    desc: "Authenticate via Okta",
  },
  {
    title: "SAML 2.0 Identity Providers",
    icon: <SamlIcon />,
    desc:
      "Authenticate via another IdP using SAML 2.0 (OneLogin, JumpCloud, etc.)",
  },
];

type Props = {
  fields: CreateIdentity;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
  handleProvider: (hasProvider: boolean) => void;
};

const ProviderSection: FC<Props> = ({
  fields,
  onChange,
  className,
  handleProvider,
}) => {
  const [selected, setSelected] = useState<string>(fields.provider);
  const handleClick = (provider: ProviderData) => {
    const isCurrent = fields.provider === provider.title;
    const newProvider = isCurrent ? "" : provider.title;
    handleProvider(!isCurrent);
    setSelected(newProvider);
    onChange("provider", newProvider);
  };

  const getProviders = () => {
    return providers.map((provider) => {
      const providerStyle = classNames(
        styles.provider,
        selected === provider.title && styles.selected
      );
      return (
        <div
          className={providerStyle}
          onClick={() => handleClick(provider)}
          key={provider.title}
        >
          {provider.icon}
          <div className={styles.content}>
            <div className={styles.contentTitle}>{provider.title}</div>
            <div className={styles.contentDesc}>{provider.desc}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <Section
      title={"Choose your identity provider"}
      className={className}
      columnsCount={1}
    >
      <div className={styles.columnNoGap}>{getProviders()}</div>
    </Section>
  );
};
export default ProviderSection;
