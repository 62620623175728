import React, { FC, useEffect, useState } from "react";
import { SystemsTabs } from "../../../components/common/navigation/SystemsTabs";
import { withLayout } from "../../../helpers/hocs/withLayout";
import { PageProps } from "../../../helpers/types";
import styles from "./ProvisioningPage.module.scss";
import { SystemsViewMode } from "./types";
import TableViewContainer from "./components/TableViewContainer";
import MapViewContainer from "./components/MapViewContainer";
import ChartsContainer from "./components/ChartsContainer";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SystemProvisioningContextContainer, {
  useSystemProvisioningContext,
} from "./ProvisioningContextContainer";
import BlankButton from "../../../components/common/buttons/BlankButton";
import { classNames } from "../../../helpers/common/classNames";
import CaretDoubleIcon from "../../../components/icons/CaretDoubleIcon";
import { ORANGE_GRAIENT_FIRST } from "../../../helpers/common/colorAliases";
import { PageLoader } from "../../../components/common/loadStates/LoaderIcon";
import SystemConfig from "./components/SystemConfig";
import { isPending } from "../../../helpers/common/RequestStateHelpers";
import {
  DEFAUTL_LIMIT,
  DEFAUTL_OFFSET,
} from "../../../components/common/table/newTable/Table";

const REFETCH_INTERVAL = 10 * 1000;
let FetchInterval: NodeJS.Timeout | undefined;

let listStatusBeforePending: any = undefined;

const ProvisioningPage: FC = () => {
  const { fetchList, list, listStatus } = useSystemProvisioningContext();
  const [viewMode, setViewMode] = useState<SystemsViewMode>(
    SystemsViewMode.table
  );
  const [isChartsHidden, setIsChartsHidden] = useState<boolean>(true);
  const [showAdd, setShowAdd] = useState(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [offsetValue, setOffsetValue] = useState<number>(DEFAUTL_OFFSET);

  const fetchWrapper = (offset?: number, limit = DEFAUTL_LIMIT) => {
    const newOffset =
      offset && offset >= 0 && offset !== offsetValue ? offset : offsetValue;
    setOffsetValue(newOffset);
    fetchList(undefined, newOffset, limit);
  };

  useEffect(() => {
    fetchWrapper();
    return () => {
      FetchInterval && clearInterval(FetchInterval);
    };
  }, []);

  if (!isPending(listStatus)) {
    listStatusBeforePending = listStatus;
  }

  useEffect(() => {
    if (list && list.length === 0 && firstLoad) {
      setShowAdd(true);
    }
  }, [list, firstLoad]);

  let handledStatus = listStatus;
  if (isPending(listStatus) && listStatusBeforePending) {
    handledStatus = listStatusBeforePending;
  }

  useEffect(() => {
    const restartFetchInterval = () => {
      FetchInterval && clearInterval(FetchInterval);
      FetchInterval = setInterval(() => {
        fetchWrapper();
      }, REFETCH_INTERVAL);
    };
    restartFetchInterval();
  }, [offsetValue]);

  const handleClose = () => {
    setShowAdd(false);
    setFirstLoad(false);
  };

  if (!viewMode || !list) return <PageLoader />;

  if (showAdd) {
    return <SystemConfig onClose={handleClose} />;
  }

  return (
    <div className={styles.wrapper}>
      <SystemsTabs showTimeRange />
      <div
        className={classNames(
          styles.contentWrapper,
          isChartsHidden && styles.collapsed
        )}
      >
        <div className={styles.leftPart}>
          {viewMode === SystemsViewMode.table ? (
            <TableViewContainer
              className={styles.leftPartContent}
              viewMode={viewMode}
              onChange={setViewMode}
              expandCharts={() => setIsChartsHidden(false)}
              showAdd={showAdd}
              setShowAdd={setShowAdd}
              status={handledStatus}
              fetchAction={fetchWrapper}
            />
          ) : (
            <MapViewContainer
              className={styles.leftPartContent}
              viewMode={viewMode}
              onChange={setViewMode}
            />
          )}
          <BlankButton
            id={"system_provisioning"}
            onClick={() => setIsChartsHidden((prev) => !prev)}
            className={classNames(
              styles.collapsedButton,
              isChartsHidden && styles.expandedButton
            )}
          >
            <CaretDoubleIcon
              className={styles.expandedIcon}
              color={ORANGE_GRAIENT_FIRST}
            />
          </BlankButton>
        </div>
        <ChartsContainer
          className={styles.rightPart}
          isHidden={isChartsHidden}
        />
      </div>
    </div>
  );
};

export default withContexts<PageProps>(
  withLayout<PageProps>(ProvisioningPage),
  [SystemProvisioningContextContainer]
);
