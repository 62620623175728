import groupBy from "lodash/groupBy";
import get from "lodash/get";
import { OrderLogItem } from "../../../../WizardToolPage/types";
import { Tabs } from "./types";
import {
  CONNECTIONS_INTERFACE_NAME_MAP,
  GROUP_KEY,
  SERVICES_NAME_MAP,
  TYPE_TO_LABEL_MAP,
} from "./constants";
import PortIcon from "../../../../../components/icons/PortIcon";
import { Fragment, ReactNode } from "react";
import { ServiceIcon } from "../../../../../components/icons/ServiceIcon";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./OrderLog.module.scss";
import { classNames } from "../../../../../helpers/common/classNames";
import ReactTooltip from "react-tooltip";
import { getBandwidthStringForTable } from "../../../../../helpers/getBandwidthStringForTable";

const generateCombinedGroupKey = (list: Array<any>, keys: Array<string>) => {
  return list.map((el) => ({
    ...el,
    [GROUP_KEY]: keys
      .map((key) => {
        const value = get(el, key);
        return typeof value === "object" ? JSON.stringify(value) : value;
      })
      .join(" "),
  }));
};

export const getGroupedOrderLog = (
  orderLog: { [key: string]: Array<OrderLogItem> } | undefined,
  activeTab: Tabs
): {
  groupedOrderLog: { [key: string]: Array<OrderLogItem> };
  isGrouped: boolean;
  orderLogList: Array<OrderLogItem>;
} => {
  if (!orderLog) {
    return { groupedOrderLog: {}, isGrouped: false, orderLogList: [] };
  }

  const isGrouped = activeTab !== Tabs.ALL;
  let orderLogList: Array<OrderLogItem> = [];
  let groupKeys = [];

  switch (activeTab) {
    case Tabs.PORTS:
      groupKeys = ["payload.name"];
      orderLogList = generateCombinedGroupKey(orderLog.ports, groupKeys);
      break;
    case Tabs.CONNECTIONS:
      groupKeys = [
        "payload.name",
        "payload.virtual_interface_type",
        "extra.l2_data",
      ];
      orderLogList = generateCombinedGroupKey(orderLog.connections, groupKeys);
      break;
    case Tabs.SERVICES_PACKAGES:
      groupKeys = ["entity_type"];
      orderLogList = generateCombinedGroupKey(orderLog.services, groupKeys);
      break;
    case Tabs.PUBLIC_IPS:
      groupKeys = ["payload.name"];
      orderLogList = generateCombinedGroupKey(orderLog.public_ip, groupKeys);
      break;
    default:
      orderLogList = Object.entries(orderLog)
        .map(([key, value]) => value.map((el) => ({ ...el, order: key })))
        .flat();
  }

  let groupedOrderLog = {};
  if (isGrouped) {
    groupedOrderLog = groupBy(orderLogList, (orderLogItem) => {
      return get(orderLogItem, GROUP_KEY);
    });
  }

  return { groupedOrderLog, isGrouped, orderLogList };
};

export const getGroupedHeader = (activeTab: Tabs, value: string) => {
  const result: { row: Array<ReactNode>; className: string } = {
    row: [""],
    className: "",
  };

  switch (activeTab) {
    case Tabs.PORTS:
      const port = value;
      result.row = [
        <>
          <span className={styles.portIcon}>
            <PortIcon width={16} height={16} />
          </span>
          <span>{port}</span>
        </>,
      ];
      break;
    case Tabs.CONNECTIONS:
      const [name, type, ...connectionStrings] = value.split(" ");
      let ports = "";
      const connectionString = connectionStrings.join(" ");

      if (connectionString) {
        const connection = JSON.parse(connectionString) as Array<any>;

        if (connection.length) {
          ports = connection.map((cn) => cn.name).join(", ");
        }
      }

      result.row = [
        name,

        // eslint-disable-next-line react/jsx-key
        <span
          className={classNames(styles[`${type}Item`], styles.connectionItem)}
        >
          {
            CONNECTIONS_INTERFACE_NAME_MAP[
              type as keyof typeof CONNECTIONS_INTERFACE_NAME_MAP
            ]
          }
        </span>,
        ports ? (
          <>
            <span className={styles.portIcon}>
              <PortIcon width={16} height={16} />
            </span>
            <span>{ports}</span>
          </>
        ) : undefined,
      ];
      result.className = type;
      break;
    case Tabs.SERVICES_PACKAGES:
      let serviceName = value;
      serviceName = serviceName.toLowerCase();

      const serviceNameFull =
        SERVICES_NAME_MAP[serviceName as keyof typeof SERVICES_NAME_MAP] ??
        serviceName;
      result.row = [
        <>
          <span>
            <ServiceIcon
              serviceType={serviceName}
              iconProps={{ width: 16, height: 16 }}
            />
          </span>
          <span>{serviceNameFull}</span>
        </>,
      ];
      break;
    case Tabs.PUBLIC_IPS:
      result.row = [value];
      break;
  }

  return result;
};

export const getItemForAll = (type: string, value: any) => {
  const result = { BW: "", Location: "" };

  switch (type) {
    case "ports":
      result.BW = getBandwidthStringForTable(value.payload.requested_bw);
      result.Location = value.extra?.location ?? "";
      break;
    case "connections":
      let maxBW;

      if (value.extra?.l2_data?.length) {
        maxBW = Math.max(
          ...value.extra.l2_data.map((el: any) => el.max_bandwidth)
        );
      }

      result.BW = getBandwidthStringForTable(maxBW);
      break;
  }

  const hasAdditionalInfo = Object.values(result).filter(Boolean).length;

  return (
    <>
      <span
        data-tip
        data-for={`${value.created_at}-${type}`}
        className={styles.allItem}
      >
        {TYPE_TO_LABEL_MAP[type as keyof typeof TYPE_TO_LABEL_MAP]}
      </span>
      <ReactTooltip
        disable={!hasAdditionalInfo}
        id={`${value.created_at}-${type}`}
        aria-haspopup="true"
        place="bottom"
        type="light"
        className="tooltip"
      >
        <div className={styles.tooltip}>
          {Object.entries(result)
            .filter(([, value]) => Boolean(value))
            .map(([key, value]) => (
              <Fragment key={key}>
                <span className={styles.tooltipItemLabel}>{key}:</span>
                <span>{value}</span>
              </Fragment>
            ))}
        </div>
      </ReactTooltip>
    </>
  );
};
