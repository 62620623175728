import { GBPS, MBPS } from "./consts";

export function calcBandwidthWithUnits(
  maxBandwidth: number,
  bandwidthUnit: string
) {
  switch (bandwidthUnit) {
    case MBPS:
      return maxBandwidth * 10 ** 3;
    case GBPS:
      return maxBandwidth * 10 ** 6;
    default:
      return maxBandwidth;
  }
}
