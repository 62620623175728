import { CellProps } from "react-table";
import { PBRType } from "../../../helpers/api/apiTypes";
import styles from "./pbrTableHeader.module.scss";
import { Toggle } from "../../../components/common/Toggle";
import LabelOutline from "../../../components/common/table/LabelOutline";
import { parseFilters } from "../../PBMPage/table/parseFilters";
import {
  getDst,
  getDstVRF,
  getSourceVRF,
  getSrc,
} from "../../PBMPage/table/commonFilterView";
import Label from "../../../components/common/table/Label";

export const pbrTableHeader = (): Array<any> => {
  const headers = [
    {
      Header: "Enabled",
      accessor: "enable",
      Cell: ({ cell: { value } }: CellProps<PBRType>) => {
        return (
          <div className={styles.toggle}>
            <Toggle
              isChecked={value}
              onChange={() => {
                return;
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell: { value } }: CellProps<PBRType>) => {
        return <Label className={styles.name}>{value}</Label>;
      },
    },
    {
      Header: "Policy",
      accessor: (row: PBRType) => [row.filters, row.vrf_name],
      Cell: ({ cell: { value } }: CellProps<PBRType>) => {
        const [filters, vrf_name] = value;
        const parsedFilters = parseFilters(filters);

        return (
          <div className={styles.policyWrapper}>
            {getSrc(parsedFilters)}
            {getDst(parsedFilters)}
            {getSourceVRF(vrf_name, parsedFilters)}
          </div>
        );
      },
    },
    {
      Header: "Gateway",
      accessor: (row: PBRType) => [
        row.gateway_ip_address,
        row.gateway_interface,
        row.gateway_MAC,
        row.labels,
        row.gateway_vrf_name,
      ],
      Cell: ({ cell: { value } }: CellProps<PBRType>) => {
        const [ip, gateInterface, mac, labels, gateway_vrf_name] = value;
        const isNoGateway = !ip;
        const isTracked = labels?.tracked;
        return (
          <div className={styles.gatewayWrapper}>
            {!isNoGateway ? (
              <div className={styles.gateway}>
                <LabelOutline type={"green"} className={styles.green}>
                  <span className={styles.value}>{ip}</span>
                  {!isTracked && <div className={styles.noTracked} />}
                </LabelOutline>
              </div>
            ) : (
              <LabelOutline type="interface">NONE</LabelOutline>
            )}
            {getDstVRF(gateway_vrf_name)}
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Priority",
      accessor: "priority",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof PBRType,
  }));

  return headers;
};
