import { FC, useEffect } from "react";
import styles from "./BlockTitle.module.scss";
import AddButton from "../../../../../components/common/buttons/AddButton";

type Props = {
  title: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
  additionalContent?: any;
  additionalContentClassname?: string;
};

const BlockTitle: FC<Props> = ({
  title,
  label,
  onClick,
  disabled,
  additionalContent,
  additionalContentClassname,
}) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
          {additionalContent && (
            <div className={additionalContentClassname}>
              {additionalContent}
            </div>
          )}
          {label && (
            <AddButton onClick={onClick} label={label} disabled={disabled} />
          )}
        </div>
      </div>
    </>
  );
};

export default BlockTitle;
