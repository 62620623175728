import React, { FC } from "react";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import styles from "./Preferences.module.scss";
import ServicesIcon from "../../../../icons/tabs/ServicesIcon";
import { useHistory } from "react-router-dom";
import { SERVICES_PREFERENCES } from "../../../../../helpers/navigation/entries";
import { usePackagesContext } from "../../../../../contexts/PackagesContext";

type Props = {};

const Preferences: FC<Props> = () => {
  const history = useHistory();
  const { isPackagesVisible } = usePackagesContext();

  return (
    <IconBlankButton
      id={"preferencesServices"}
      icon={ServicesIcon}
      label={isPackagesVisible ? "Packages" : "Services"}
      onClick={() => history.push("/" + SERVICES_PREFERENCES().path)}
      className={styles.btn}
    />
  );
};

export default Preferences;
