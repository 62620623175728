import { HTMLAttributes } from "react";

type MoneyProps = {
  value: number;
  currency: string;
} & HTMLAttributes<HTMLSpanElement>;

const CURRENCY_MAP: Record<string, string> = {
  euro: "€",
};

export const Money = ({ value, currency, ...rest }: MoneyProps) => {
  const currencySymbol = CURRENCY_MAP[currency.toLowerCase()];

  return (
    <span {...rest}>
      {currencySymbol || ""}
      {value}
    </span>
  );
};
