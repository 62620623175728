import { FC, useEffect } from "react";
import LayoutComponent from "../../components/common/LayoutComponent";
import { PageProps } from "../../helpers/types";
import ProviderComponent from "./components/ProviderComponent";
import styles from "./SDWANProvidersContent.module.scss";
import { withContexts } from "../../helpers/hocs/withContexts";
import SDWANProvidersContext, {
  useSDWANProvidersContext,
} from "./SDWANProvidersContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useUserContext } from "../../contexts/UserContext";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";

const SDWANProvidersContent: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    fetchList,
    groupedList,
    resetTokens,
    tokens,
    requestToRemove,
    deleteAccessKey,
    setRequestToRemove,
  } = useSDWANProvidersContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { isDemo } = useUserContext();

  const isDisabled = (type: string) => {
    if (["aruba"].includes(type)) return false;
    else if (isDemo) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (selectedTenant) {
      fetchList(selectedTenant);
      if (tokens) resetTokens();
    }
  }, [selectedTenant]);

  const handleDeleteKey = () => {
    if (selectedTenant && requestToRemove) {
      deleteAccessKey(selectedTenant, requestToRemove);
      setRequestToRemove(undefined);
    }
  };

  const handleCancel = () => {
    setRequestToRemove(undefined);
  };

  return (
    <LayoutComponent>
      <Tabs hideControls />
      <ConfirmDeleteDialog
        deviceType={"Gateways"}
        name={`${requestToRemove?.toUpperCase()} gateway`}
        isShown={!!requestToRemove}
        onOk={handleDeleteKey}
        onCancel={handleCancel}
      />
      <div className={styles.wrapper}>
        {Object.keys(groupedList).map((type) => (
          <ProviderComponent
            key={type}
            type={type}
            connectedGW={groupedList[type]}
            disabled={isDisabled(type)}
          />
        ))}
      </div>
    </LayoutComponent>
  );
};
export default withContexts<PageProps>(SDWANProvidersContent, [
  SDWANProvidersContext,
]);
