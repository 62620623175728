import {
  VLAN_POOL_MAX,
  VLAN_POOL_MIN,
} from "../../pages/CloudResourcesPage/components/AddNNIDialog/components/AwsForm/constants";
import { CreateAWSNNI } from "../../pages/CloudResourcesPage/types";
import Validator from "./Validator";

export default function validateAwsNNI(
  data: Partial<CreateAWSNNI>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkNNIId(data.nni_id, "nni_id");
  validator.checkEmpty(data.system_name, "system_name");
  validator.checkEmpty(data.physical_port_name, "physical_port_name");
  validator.checkEmpty(data.site_name, "site_name");
  validator.checkEmpty(data.max_bandwidth, "max_bandwidth");
  validator.checkMinMax(
    data.vlan_pool?.from,
    [VLAN_POOL_MIN, VLAN_POOL_MAX],
    "vlan_pool_from"
  );
  validator.checkMinMax(
    data.vlan_pool?.to,
    [VLAN_POOL_MIN, VLAN_POOL_MAX],
    "vlan_pool_to"
  );

  return validator;
}
