import React from "react";
import { Filter } from "./filterTypes";
import styles from "./FilterList.module.scss";

interface FilterListProps {
  filters: Array<Filter>;
  onRemoveFilter: (index: number) => void;
}

const FilterList: React.FC<FilterListProps> = ({ filters, onRemoveFilter }) => {
  return (
    <div className={styles.filterListWrapper}>
      {filters.map((filter, index) => (
        <div key={index} className={styles.filterItem}>
          {filter.field} {filter.condition} {filter.value}
          <div
            onClick={() => onRemoveFilter(index)}
            className={styles.removeFilterIcon}
          >
            <span className={styles.removeFilterIcon}>&times;</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilterList;
