import React, { FC, useEffect, useMemo, useState } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { firewallTableHeader } from "../../components/common/table/TableHeaders/Services/firewallTableHeader";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import FirewallContextContainer, {
  useFirewallContext,
} from "../../contexts/servicesContext/FirewallContext";
import { FWDiaRuleType, FWRuleType } from "../../helpers/api/apiTypes";
import { ADD, EDIT } from "../../helpers/common/constantsAlias";
import { withContexts } from "../../helpers/hocs/withContexts";
import { withLayout } from "../../helpers/hocs/withLayout";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import { PageProps } from "../../helpers/types";
import DiaFirewallRuleConfig from "./config/DiaFirewallRuleConfig";
import styles from "./Firewall.module.scss";
import TableGrouped from "../../components/common/table/newTable/TableGrouped";
import { SERVICES_FW, SERVICES_FW_DIA } from "../../helpers/navigation/entries";
import { CellHoverTooltip } from "./CellHoverTooltip";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";
import ConnectionsContextContainer, {
  useConnectionsContext,
} from "../WizardToolPage/ConnectionStep/ConnectionsContext";
import { classNames } from "../../helpers/common/classNames";
import LocationIcon from "../../components/icons/LocationIcon";
import { GREY_DISABLED } from "../../helpers/common/colorAliases";

const FirewallDiaContent: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    fetchGroups,
    diaGroups,
    fwGroupsStatus,
    remove,
    removeStatus,
    selectedFwRule,
    removeDeletedRule,
    selectFWRule,
    changeFWRuleEnable,
    fetchUserGroups,
    userGroups,
    fetchFQDNData,
  } = useFirewallContext();
  const { fetchDIAList, diaList } = useConnectionsContext();
  const { selectedTenant } = useGlobalFilterContext();

  const [showAdd, setShowAdd] = useState<boolean>(false);
  const [dia, setDia] = useState<string>("");

  const [tryDelete, selectedToDelete, sureDeleteProps] = useDeleteDialog<any>(
    remove,
    removeStatus,
    () => {
      selectFWRule(undefined);
      selectedFwRule && removeDeletedRule(selectedFwRule.id);
    }
  );

  useEffect(() => {
    if (selectedTenant) {
      fetchGroups(selectedTenant, undefined, true);
      fetchDIAList(selectedTenant);
      !userGroups?.global.length &&
        !userGroups?.tenant.length &&
        fetchUserGroups(selectedTenant);
      fetchFQDNData(selectedTenant);
    }
  }, [selectedTenant]);

  const rowActions: ActionFuncs<FWRuleType> = useMemo(
    () => ({
      onEdit: selectFWRule,
      onDelete: (rowOriginal) => {
        tryDelete(rowOriginal, selectedTenant);
      },
      hoverTooltip: (row: FWRuleType) => <CellHoverTooltip rowOriginal={row} />,
    }),
    [selectedTenant, tryDelete]
  );

  function getTitleDetail(val: string): React.ReactElement {
    const location = diaList?.find((dia) => dia.name === val)?.location || "";
    if (!location) return <div />;
    return (
      <div className={styles.location}>
        <LocationIcon color={GREY_DISABLED} />
        {location}
      </div>
    );
  }

  if (showAdd) {
    return (
      <DiaFirewallRuleConfig
        type={ADD}
        onClose={() => setShowAdd(false)}
        data={selectedFwRule as FWDiaRuleType}
        gate_vi_name={dia}
      />
    );
  }

  if (selectedFwRule) {
    return (
      <DiaFirewallRuleConfig
        type={EDIT}
        onClose={() => selectFWRule(undefined)}
        data={selectedFwRule as FWDiaRuleType}
        gate_vi_name={dia}
      />
    );
  }

  const empty = !Object.keys(diaGroups || {}).length;

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Firewall Rule"}
        name={`${selectedToDelete?.name || JSON.stringify(selectedToDelete)}`}
      />
      <div className={styles.container}>
        <Tabs tabs={[SERVICES_FW(), SERVICES_FW_DIA()]} />
        <ServicesRedirectPlaceholder type="dia_fw">
          <TableWrapper
            titleProps={{
              title: <span className={styles.title}>Rules</span>,
            }}
            dataStatus={fwGroupsStatus}
            isEmpty={empty}
            tablePlaceholder={["rules", "rule"]}
            className={classNames(styles.noBorder, empty && styles.empty)}
          >
            <TableGrouped
              columns={firewallTableHeader(selectedTenant, true)}
              header={TableHeader}
              data={diaGroups || { "": [] }}
              gridColumnTemplate={`120px 1fr 200px repeat(4, 1fr) 140px 80px`}
              rowActions={rowActions}
              onClick={selectFWRule}
              extraTitleHeader={
                <AddButton
                  isIconOnly
                  className={styles.addBtn}
                  onClick={() => setShowAdd(true)}
                />
              }
              extraContentClb={setDia}
              collapsedExtra
              collapsed={diaGroups ? Object.keys(diaGroups).slice(1) : []}
              withoutInitialCollapsed={
                diaGroups ? Object.keys(diaGroups).slice(0, 1) : []
              }
              clickFeatures={[
                {
                  elementClass: "toggle",
                  clb: changeFWRuleEnable,
                },
              ]}
              gridTemplateRows={"auto max-content auto"}
              commonActions
              getExtraTitleElement={getTitleDetail}
            />
          </TableWrapper>
        </ServicesRedirectPlaceholder>
      </div>
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(FirewallDiaContent, [
    FirewallContextContainer,
    ConnectionsContextContainer,
  ])
);
